import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Redirect, Link } from 'react-router-dom'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'

import UserAccount from './UserAccount';
import ChangePassword from './ChangePassword';
import NewEnrollment from '../admin_admin/EnrollmentManager/NewEnrollment';
import EmployeeList from './employer/EmployeeList';
import EventCertList from './employer/EventCertList';

class UserDashboard extends Component {

    show = () => {
        document.getElementById("myModal").style.display = "block";
    }
    
    hide = () => {
        document.getElementById("myModal").style.display = "none";
    }

    render(){

        const { auth, profile, users, courses, employers, empEnrollments, eventsAttendees, events } = this.props;
        
        //console.log(eventsAttendees);
        const query = new URLSearchParams(this.props.location.search);

        let query_type = query.get('type');
        let query_tab = query.get('tab')

        if (!auth.uid) return <Redirect to="/" />

        return(

        <div className="dashboard">

            <div className="justify-content-center row col-md-12">
                <div className="col col-md-4">
                    <div className="dashboard-left middle">
                        <div className="user-dashboard-header text-center">
                            <i className="fas fa-user fa-3x text-green" style={{marginBottom:"10px"}}></i>
                            <h3>{profile.firstname}'s Dashboard </h3>  
                            <p>{profile.email}</p>
                        </div>
                            
                        <Link className={query_type === "myaccount" ? "col btn-active btn all-btn" : "col btn all-btn"} to="/userdashboard?type=myaccount&edit=false" style={{width:"100%"}}>My Profile</Link>
                        <Link className={query_type === "changepassword" ? "col btn-active btn all-btn" : "col btn all-btn"} to="/userdashboard?type=changepassword" style={{width:"100%"}}>Change Password</Link>
                        {employers && employers.map((emp,i) => {
                            if(emp.userId === auth.uid) {
                                return (
                                    <Link key={i} className={query_type === "enrollment" ? "col btn-active btn all-btn" : "col btn all-btn"} to="/userdashboard?type=enrollment&tab=new" style={{width:"100%"}}>Enrollment</Link>
                                )
                            } return null
                        })}
                        <Link className={query_type === "certList" ? "col btn-active btn all-btn" : "col btn all-btn"} to="/userdashboard?type=certList&tab=list" style={{width:"100%"}}>E-Cert</Link>
                    </div>
                </div>

                <div className="col">
                    <div className="dashboard-right">
                        { query_type === 'myaccount'? 
                            <UserAccount query={query} profile={profile} />
                        :query_type === 'changepassword'?
                            <ChangePassword />
                        : query_type === 'enrollment'?
                            <>
                                <div className="tab">
                                    <Link className={query_tab === 'new' ? "tablinks active" : "tablinks"} to="/userdashboard?type=enrollment&tab=new">New Enrollment</Link>
                                    <Link className={query_tab === 'list' ? "tablinks active" : "tablinks"} to="/userdashboard?type=enrollment&tab=list">Enrollment List</Link>
                                </div>
                
                                <div className="tabcontent">
                                    <div style={{margin:"20px"}}>
                                        { query_tab === 'new'?
                                            <div>
                                                <b><i className="fas fa-user text-darkgrey"></i>{'  '}New Enrollment</b>
                                                <div>Please enroll one time per person
                                                    <div className="row"><div className="title-border"></div></div>
                                                    <NewEnrollment empEnrollments={empEnrollments} employers={employers} emp_enroll={true} history={this.props.history} query={query} users={users} courses={courses} show={this.show} hide={this.hide} />
                                                </div>
                                            </div>
                                        : query_tab === 'list'?
                                            <div>
                                                <b><i className="fas fa-user text-darkgrey"></i>{'  '}Enrollment List</b>
                                                <div>Download E-Cert only available for those that have finished the course.
                                                    <div className="row"><div className="title-border"></div></div>
                                                    <EmployeeList empEnrollments={empEnrollments} employers={employers} courses={courses} users={users} eventsAttendees={eventsAttendees} events={events} />
                                                </div>
                                            </div>
                                        : null }
                                    </div>
                                </div>   
                            </>
                        : query_type === 'certList'?
                        <>
                            <div className="tab">
                                <Link to="/userdashboard?type=certList&tab=list">Cert List</Link>
                            </div>
            
                            <div className="tabcontent">
                                <div style={{margin:"20px"}}>
                                        <div>
                                            <b><i className="fas fa-user text-darkgrey"></i>{'  '}Cert List</b>
                                            <div>Download E-Cert only available for those that have been approved by the admin.
                                                <div className="row"><div className="title-border"></div></div>
                                                <EventCertList empEnrollments={empEnrollments} employers={employers} courses={courses} users={users} eventsAttendees={eventsAttendees} events={events} profile={profile}/>
                                            </div>
                                        </div>
                                </div>
                            </div>   
                        </>:'Choose option at your left first.'}
                    </div>
                </div>
            </div>

        </div>
        )
    }  
}

const mapStateToProps = (state, ownProps) => {
    return {
        profile: state.firebase.profile,
        auth: state.firebase.auth,

        users: state.firestore.ordered.users,
        courses: state.firestore.ordered.courses,
        employers: state.firestore.ordered.employers,
        empEnrollments: state.firestore.ordered.empEnrollments,
        eventsAttendees: state.firestore.ordered.eventsAttendees,
        events: state.firestore.ordered.events
    }
}
  
export default compose(
    connect(mapStateToProps),
    firestoreConnect(props => [
        { collection: 'users'},
        { collection: 'courses'},
        { collection: 'employers', where: ['userId', '==', props.auth.uid]},
        { collection: 'courseEnrollments', where: ['employerId', '==', props.auth.uid], storeAs: 'empEnrollments'},
        { collection: 'eventsAttendees' },
        { collection: 'events' },
    ])
)(UserDashboard);