import React, { Component } from 'react';
import { connect } from 'react-redux'
import { deleteHalalInfo } from '../../../stores/actions/infoAction';
import EditHalalInfo from './EditHalalInfo';

class DisplayHalalInfo extends Component{

    state = {
        info_id:'',
        displayEditCode:'', //ADD EXTRA EXPRESSION SBB NANTI DIA BACA RENDER EDITHELPINFO WTFFF

        pdfName: "",
        pdfUrl: null,
    
        isLoading: null,
        message: null,
    }
    
    componentDidUpdate = (prevProps, prevState) => { 
        const { deleteHalalError, deleteHalalSuccess } = this.props;

        if(prevProps.deleteHalalError !== deleteHalalError) {
            alert(deleteHalalError.message);
            this.setState({
                isLoading: false,
                message: {
                    msg: deleteHalalError.message,
                    type: deleteHalalError.type,
                }
            })
        }
        
        if(prevProps.deleteHalalSuccess !== deleteHalalSuccess) {
            alert(deleteHalalSuccess.message);
            this.setState({
                isLoading: false,
                message: {
                    msg: deleteHalalSuccess.message,
                    type: deleteHalalSuccess.type,
                }
            })
        }
    } 

    handleEdit = (id, code) => {
        this.props.show();
        this.setState({
            displayEditCode: code,
           info_id: id
        })
    }

    deleteHalalInfo = (infoId) => {

        var result = window.confirm("Confirm delete?");

        if (result) {
            this.props.deleteHalalInfo(infoId);
        }
    }
    
    hideAndResetState = () => {
        this.props.hide();
        this.setState({
           info_id: '',
           displayEditCode: ''
        })
    }

    displayModalHalalFile_Display = (name, url) => { //display file modal
        this.setState({
            pdfName: name,
            pdfUrl: url
        })
        document.getElementById("display_halal_file_display").style.display = "block";
    }

    hideModalHalalFile_Display = () => { //hide file modal
        document.getElementById("display_halal_file_display").style.display = "none";
    }



    render(){

        const { infos } = this.props;

        let hasData = [], isLoading= true;
        let no = 0;

        let infoList = infos && infos.map((info ,i) => {
            if(info.infoType === "HALAL INFO"){
                hasData.push(info)
                isLoading = false;
                no = no+1;
                return(
                    <tr key={i}>
                        <th scope="row">{no}</th>
                        <td className="text-left">{info.infoName}</td>
                        <td className="text-left">
                            { info.selectedHalalFile ?
                                info.selectedHalalFile.fileType === "pdf" ?
                                    <span onClick={this.displayModalHalalFile_Display.bind(this, info.selectedHalalFile.fileName, info.selectedHalalFile.fileUrl)}>{info.selectedHalalFile.fileName}</span>
                                : info.selectedHalalFile.fileType === "video" ?
                                    <video width="200" height="100" src={info.selectedHalalFile.fileUrl} type="video/mp4" controls />
                                : null 
                            : null }
                        </td>
              
                        <td>
                            <button type="button" className="m-1 btn btn-outline-black" onClick={this.handleEdit.bind(this, info.id, 'edithalalinfo')}><i className="fas fa-edit"></i></button>
                            <button type="button" className="m-1 btn btn-outline-red" onClick={this.deleteHalalInfo.bind(this, info.id)}><i className="fas fa-trash"></i></button>
                        </td>
                    </tr>
                )
            } else {
                isLoading = false;
            }
            return null
        });


        return(

        <div>

            <div id="display_halal_file_display" className="mymodal">
                <div className="mymodal-content col-md-6">
                    <span className="close" onClick={this.hideModalHalalFile_Display}>&times;</span>
                    <h3 className="middle">{this.state.pdfName}
                        <div className="row"><div className="title-border"></div></div>
                    </h3>
                    
                    <object aria-label="display-pdf" className="display-pdf" data={this.state.pdfUrl}></object>
                              
                </div>
            </div>

            {/* MODAL FOR UPDATE HALAL INFO */}
            <div id="myModal" className="mymodal">
                <div className="mymodal-content col-md-6">

                    <span className="close" onClick={this.hideAndResetState}>&times;</span>
                    
                    { this.state.displayEditCode==='edithalalinfo'?
                        <EditHalalInfo hideModalHalalFile_Display={this.hideModalHalalFile_Display} hideAndResetState={this.hideAndResetState} info_id={this.state.info_id}/>
                    :''}

                </div>
            </div>

            { isLoading === true ? 
                <p>Please wait... Data loading...</p> 
            : 
                hasData.length > 0 ? 
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">No.</th>
                                <th scope="col">Name</th>
                                <th scope="col">Attachment</th>
                                <th scope="col">Delete</th>
                            </tr>
                        </thead>

                        <tbody>
                            {infoList}
                        </tbody>
                    </table>
                : 
                    <p>No info available</p>
            }
        </div>

        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        deleteHalalError: state.infos.deleteHalalError,
        deleteHalalSuccess: state.infos.deleteHalalSuccess,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
      deleteHalalInfo: (infoId) => dispatch(deleteHalalInfo(infoId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DisplayHalalInfo)