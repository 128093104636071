import React, { Component } from 'react';
import { connect } from 'react-redux'
import { compose } from 'redux'
import { storage } from '../../../config/fbConfig'

import { updateCourse } from '../../../stores/actions/courseAction';

class EditCourse extends Component{

    constructor(props){
        super(props)
        this.state = {
            courseName: this.props.course.courseName,
            courseCode: this.props.course.courseCode,
            courseType: this.props.course.courseType,
            coursePeriod: this.props.course.coursePeriod,
            coursePrice: this.props.course.coursePrice,
            toyyibCatCode: this.props.course.toyyibCatCode,
            courseStatus: this.props.course.courseStatus,
            courseAttach: this.props.course.courseAttach,
            installmentAvailable: this.props.course.installmentAvailable,
            courseInfo: this.props.course.courseInfo,
            courseOverview: this.props.course.courseOverview,
            courseLearn: this.props.course.courseLearn,
            courseRequirement: this.props.course.courseRequirement,

            attachType: this.props.course.courseAttach ? this.props.course.courseAttach.fileType : '',
    
            getCourseInfo:'',
            getCourseOverview:'',
            getCourseLearn:'',
            getCourseRequirement:'',
    
            hasEcert: this.props.course.hasEcert,
            hasPhysicalClass: this.props.course.hasPhysicalClass,
            removeQuizBtn: this.props.course.removeQuizBtn,
            enableExam: this.props.course.enableExam,
    
            exam: this.props.course.exam,
            hasExam: this.props.course.exam.hasExam,
            examTimer: this.props.course.exam.examTimer,
            examMark: this.props.course.exam.examMark,
            examSuspendDay: this.props.course.exam.examSuspendDay,
            examSuspendNumber: this.props.course.exam.examSuspendNumber,
            selectedExamSet: this.props.course.exam.selectedExamSet,
            questionCount: this.props.course.exam.questionCount,

            extendPrice: this.props.course.extendPrice,
            extendPeriod: this.props.course.extendPeriod,
            toyyibCatCode_Extend: this.props.course.toyyibCatCode_Extend,
    
            selectedModules: this.props.course.selectedModules,
    
            selectedCourse: this.props.course.selectedCourse,
            selectedModule: this.props.course.selectedModule,
    
            attachNew: null,
            attachDisplay:null,
            progress:'',
            oldAttachHide:false,
            newAttachHide:true, //sbb dia ada placeholder. so kena manual hide
    
            searchModule:'',
            selectedModuleCode:'',
    
            isLoading: null,
            message: null,
        }
        this.baseState = this.state 
    }
    
    componentDidUpdate = (prevProps, prevState) => { 
        const { updateCourseError, updateCourseSuccess } = this.props;

        if(prevProps.updateCourseError !== updateCourseError) {
            this.setState({
                isLoading: false,
                message: {
                    msg: updateCourseError.message,
                    type: updateCourseError.type,
                }
            })
        }

        if(prevProps.updateCourseSuccess !== updateCourseSuccess) {
            this.setState({
                isLoading: false,
                message: {
                    msg: updateCourseSuccess.message,
                    type: updateCourseSuccess.type,
                }
            })
            setTimeout(() => { 
                this.setState(this.baseState)
                this.props.cancel();
            }, 2000);
        }
    } 

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.valueAsNumber || e.target.value
        })
    }

    selectedAttachType = (e) => {
        this.setState({
            attachType:  e.target[e.target.selectedIndex].id,
        })
    }

    attachSelectHandler = (e) => {
        if(e.target.files[0]) {
            this.setState({ 
                attachNew: e.target.files[0],
                attachDisplay: URL.createObjectURL(e.target.files[0]),
                oldAttachHide: true,
                newAttachHide: false
            })
        }
    }

    updateState = async () => {
        this.setState({
            exam:{
                hasExam: this.state.hasExam,
                selectedExamSet: this.state.selectedExamSet,
                examTimer: this.state.examTimer,
                examMark: this.state.examMark,
                examSuspendDay: this.state.examSuspendDay,
                examSuspendNumber: this.state.examSuspendNumber,
                questionCount: this.state.questionCount,
            },
        })
        return;
    }

    updateCourse = async (e) => {
                
        const { attachNew, attachType } = this.state;

        await this.updateState();

        var result = window.confirm("Confirm update?");
    
        if (result) {
            this.setState({
                isLoading: true
            })
            if (attachNew !== null) {

                let ref = null, ref2 = null;

                if(attachType === "image") {
                    ref = `images/${attachNew.name}`
                    ref2 = 'images'
                } else if (attachType === "video") {
                    ref = `videos/${attachNew.name}`
                    ref2 = 'videos'
                }
                const uploadTask = storage.ref(ref).put(attachNew);
        
                    uploadTask.on('state_changed', 
                        (snapshot) => {
                            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                            this.setState({
                                progress});
                        }, 
        
                        (error) => {
                            console.log(error);
                        }, 
        
                        () => {
                            storage.ref(ref2).child(attachNew.name).getDownloadURL().then(videoUrl => {
                                this.setState({
                                    courseAttach: {
                                        fileUrl: videoUrl,
                                        fileName: attachNew.name,
                                        fileType: attachType
                                    }
                                });
                                
                                this.props.updateCourse(this.state, this.props.course_id)
                            })
                        }
                    );
            } else {
                this.props.updateCourse(this.state, this.props.course_id)
            }
        }
    }

    selectedProfModule = (id, code) => {
        this.setState({
            selectedModules: [ 
                ...this.state.selectedModules, 
                id //get selected option ID
            ],
            selectedModuleCode: code
        });
    }

    removeSelectedModule = (id) => {
        let removeSelectedModule = this.state.selectedModules.filter(selected => {
          return selected !== id
        })
    
        this.setState({
            selectedModules: removeSelectedModule
        })
    }

    saveData = (saveType) => {
        if(saveType === 'info') {
            this.setState({
                courseInfo: [ 
                    ...this.state.courseInfo,
                    this.state.getCourseInfo, 
                ],
            });
            this.refs.courseInfo.value = '';
        } else if(saveType === 'overview') {
            this.setState({
                courseOverview: [ 
                    ...this.state.courseOverview,
                    this.state.getCourseOverview, 
                ],
            });
            this.refs.courseOverview.value = '';
        } else if(saveType === 'learn') {
            this.setState({
                courseLearn: [ 
                    ...this.state.courseLearn,
                    this.state.getCourseLearn, 
                ],
            });
            this.refs.courseLearn.value = '';
        }else if(saveType === 'requirement') {
            this.setState({
                courseRequirement: [ 
                    ...this.state.courseRequirement,
                    this.state.getCourseRequirement, 
                ],
            });
            this.refs.courseRequirement.value = '';
        }
    }

    removeData = (removeType, data) => {
        if(removeType === 'info') {
            let removed = this.state.courseInfo.filter(inf => {
                return inf !== data
            });
            this.setState({
                courseInfo: removed,
            })
        } else if(removeType === 'overview') {
            let removed = this.state.courseOverview.filter(overview => {
                return overview !== data
            });
            this.setState({
                courseOverview: removed,
            })
        } else if(removeType === 'learn') {
            let removed = this.state.courseLearn.filter(learn => {
                return learn !== data
            });
            this.setState({
                courseLearn: removed,
            })
        } else if(removeType === 'requirement') {
            let removed = this.state.courseRequirement.filter(requirement => {
                return requirement !== data
            });
            this.setState({
                courseRequirement: removed,
            })
        }
    }



    render(){

        const { courses, modules, exams, course_id } = this.props;
        const { message, isLoading } = this.state;

        let filteredModules = null;

        if(this.state.searchModule.length > 0) { //filter user: search user by email
            filteredModules = modules && modules.filter(module => {
                return module.moduleCode.toLowerCase().includes(this.state.searchModule.toLowerCase()) || 
                module.moduleName.toLowerCase().includes(this.state.searchModule.toLowerCase())
            })
        } else {
            filteredModules = modules;
        }
        
        return(

            <div>      
                <div className="row">   
                    <p className="col col-md-4">
                        <b>Course Type: </b>
                        <input type="text" id="courseType"defaultValue={this.state.courseType} disabled className="form-control"/>
                    </p> 
                </div>

                <div className="row"> 
                    <p className="col col-md-4">
                        <b>Code:</b>
                        <input type="text" id="courseCode" onChange={this.handleChange} defaultValue={this.state.courseCode} className="form-control"/>
                    </p>

                    <p className="col col-md-4">
                        <b>Name:</b>
                        <input type="text" id="courseName" onChange={this.handleChange} defaultValue={this.state.courseName} className="form-control"/>
                    </p>

                    <p className="col col-md-4">
                        <b>Status:</b>
                        <select id="courseStatus" onChange={this.handleChange} className="form-control">
                            <option defaultValue>{this.state.courseStatus}</option>
                            <option>Active</option>
                            <option>Not Active</option>
                        </select>
                    </p>
                </div>

                <div className="mt-4">
                    <b>Course Price:</b>
                    <div className="row">    
                        <p className="col col-md-4">
                            <small style={{fontWeight:'800'}}>Price:</small>
                            <input type="number" id="coursePrice" onChange={this.handleChange} defaultValue={this.state.coursePrice} className="form-control"/>
                        </p>

                        { this.state.courseType === "Professional" ?
                            <p className="col col-md-4">
                                <small style={{fontWeight:'800'}}>Period (days):</small>
                                <input type="number" id="coursePeriod" onChange={this.handleChange} defaultValue={this.state.coursePeriod} className="form-control"/>
                            </p> 
                        :''}

                        <p className="col col-md-4">
                            <small style={{fontWeight:'800'}}>ToyyibPay Category Code:</small>
                            <input type="text" id="toyyibCatCode" onChange={this.handleChange} className="form-control" defaultValue={this.state.toyyibCatCode} title="Login to ToyyibPay site > Category" />
                        </p> 
                    </div>
                </div>

                { this.state.courseType === "Professional" ?

                    <div>
                        <div className="mt-4">
                            <b>Course Expired Extension:</b>
                            <div className="row">    
                                <p className="col col-md-4">
                                    <small style={{fontWeight:'800'}}>Price (RM):</small>
                                    <input type="number" id="extendPrice" onChange={this.handleChange} className="form-control" defaultValue={this.state.extendPrice} />
                                </p>    
            
                                <p className="col col-md-4">
                                    <small style={{fontWeight:'800'}}>Period (Month):</small>
                                    <input type="number" id="extendPeriod" onChange={this.handleChange} className="form-control"  defaultValue={this.state.extendPeriod} />
                                </p>

                                <p className="col col-md-4">
                                    <small style={{fontWeight:'800'}}>ToyyibPay Category Code:</small>
                                    <input type="text" id="toyyibCatCode_Extend" onChange={this.handleChange} className="form-control" defaultValue={this.state.toyyibCatCode_Extend} title="Login to ToyyibPay site > Category" />
                                </p> 
                            </div>
                        </div>

                        <div className="mt-4">
                            <b>Modules: </b>
                            <div type="text" className="border-black">
                                { modules && modules.map(module => {
                                    return (
                                        this.state.selectedModules && this.state.selectedModules.map((selected, i) => { 
                                            if ( module.id === selected) {
                                                return (
                                                    <span className="badge bg-green text-white" key={i} id="selectedModules">
                                                        {module.moduleCode} {' '}
                                                            
                                                        <span onClick={this.removeSelectedModule.bind(this, selected)}>
                                                            <i className="fas fa-times"></i>
                                                        </span> 
                                                    </span>
                                                )
                                            }
                                            return null
                                        })
                                    )
                                })}
                            </div>
                            
                            <small>Choose module here:</small>

                            <br/>

                            <div className="row col-md-5 btn-group dropdown">
                                <button type="button" className="btn border-black dropdown-toggle" data-toggle="dropdown">
                                    { this.state.selectedModuleCode ? this.state.selectedModuleCode : 'Choose Module'}
                                </button>

                                <div className="dropdown-menu" >
                                    <span className="dropdown-item" onClick={this.selectedProfModule.bind(this, '', '')} disabled>MODULE</span>
                                    <span className="dropdown-item"><input placeholder="Search code..." className="form-control"  onChange={event => this.setState({searchModule: event.target.value})}/></span>
                                    { filteredModules && filteredModules.map((module,i) => {
                                        return (
                                            <span key={i} className="dropdown-item" onClick={this.selectedProfModule.bind(this, module.id, module.moduleCode)}>{module.moduleCode} - {module.moduleName}</span>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>

                        <div className="mt-4">
                            <b>Exam:</b>
                            <select className="form-control" onChange={event => this.setState({hasExam: event.target.value})}>
                                <option defaultValue>{this.state.hasExam ? this.state.hasExam : 'Select Exam Available'}</option>
                                <option>true</option>
                                <option>false</option>
                            </select>

                            { this.state.hasExam === "true" ?
                                <div>
                                    <select className="form-control" onChange={e => this.setState({selectedExamSet: e.target[e.target.selectedIndex].id})}>
                                        { this.state.selectedExamSet.length > 0 ?
                                            exams && exams.map(exam => {
                                                if (exam.id === this.state.selectedExamSet) {
                                                    return (
                                                        <option key={exam.id} defaultValue>{exam.examCode} - {exam.examName}</option>
                                                    )
                                                }
                                                return null
                                            })
                                        :
                                            <option defaultValue>Select Exam's Set</option>
                                        }

                                        { exams && exams.map(exam => { 
                                            return (
                                                <option key={exam.id} id={exam.id}>{exam.examCode} - {exam.examName}</option>
                                            )
                                        })}
                                    </select>
                                    <br/>
                                    <small>EXAM SETING</small>
                                    <br/>
                                    <div className="row">    
                                        <p className="col col-md-4">
                                            <small style={{fontWeight:'800'}}>Time (seconds):</small>
                                            <input type="number" id="examTimer" onChange={this.handleChange} className="form-control" defaultValue={this.state.examTimer}/>
                                        </p>    
                                        
                                        <p className="col col-md-4">
                                            <small style={{fontWeight:'800'}}>Q.Count:</small>
                                            <input type="number" id="questionCount" onChange={this.handleChange} className="form-control" defaultValue={this.state.questionCount}/>
                                        </p>     
                                        
                                        <p className="col col-md-4">
                                            <small style={{fontWeight:'800'}}>Pass Rate (%):</small>
                                            <input type="number" id="examMark" onChange={this.handleChange} className="form-control" defaultValue={this.state.examMark}/>
                                        </p> 
                                    </div>

                                    <div className="row">    
                                        <p className="col col-md-4">
                                            <small style={{fontWeight:'800'}}>S.Count:</small>
                                            <input type="number" id="examSuspendNumber" onChange={this.handleChange} className="form-control"  defaultValue={this.state.examSuspendNumber}/>
                                        </p>
                    
                                        <p className="col col-md-4">
                                            <small style={{fontWeight:'800'}}>S.Day (days):</small>
                                            <input type="number" id="examSuspendDay" onChange={this.handleChange} className="form-control"  defaultValue={this.state.examSuspendDay}/>
                                        </p>
                                    </div>
                                </div>
                            : null }
                        </div>

                        <div className="mt-4 row">
                            <p className="col col-md-4">
                                <b>Has Practical Session:</b>
                                <select className="form-control" onChange={event => this.setState({hasPhysicalClass: event.target.value})}>
                                    <option defaultValue>{this.state.hasPhysicalClass ? this.state.hasPhysicalClass : 'Select Physical Class Available'}</option>
                                    <option>true</option>
                                    <option>false</option>
                                </select>
                            </p>

                            <p className="col col-md-4">
                                <b>Has E-Cert:</b>
                                <select className="form-control" onChange={event => this.setState({hasEcert: event.target.value})}>
                                    <option defaultValue>{this.state.hasEcert ? this.state.hasEcert : 'Select E-Cert Available'}</option>
                                    <option>true</option>
                                    <option>false</option>
                                </select>
                            </p>

                            <p className="col col-md-4">
                                <b>Has Flexi:</b>
                                <select className="form-control" onChange={event => this.setState({installmentAvailable: event.target.value})}>
                                    <option defaultValue>{this.state.installmentAvailable ? this.state.installmentAvailable : 'Select Installment Available'}</option>
                                    <option>true</option>
                                    <option>false</option>
                                </select>
                            </p>
                        </div>

                        <div className="row">
                            <p className="col col-md-4">
                                <b>Enable Exam:</b>
                                <select className="form-control" onChange={event => this.setState({enableExam: event.target.value})}>
                                    <option defaultValue>{this.state.enableExam ? this.state.enableExam : 'Select Enable Exam'}</option>
                                    <option>true</option>
                                    <option>false</option>
                                </select>
                            </p>

                            <p className="col col-md-4">
                                <b>Remove Quiz Button:</b>
                                <select className="form-control" onChange={event => this.setState({removeQuizBtn: event.target.value})}>
                                    <option defaultValue>{this.state.removeQuizBtn ? this.state.removeQuizBtn : 'Select Remove Quiz'}</option>
                                    <option>true</option>
                                    <option>false</option>
                                </select>
                            </p>
                        </div>

                    </div>
                
                :

                    <div>
                        <b>Course: </b>
                        <select className="form-control" onChange={e => this.setState({selectedCourse: e.target[e.target.selectedIndex].id})}>

                            { this.state.selectedCourse.length > 0 ?
                                courses && courses.map((course,i) => {
                                    if (course.id === this.state.selectedCourse) {
                                        return (
                                            <option key={i} id={course.id} defaultValue>{course.courseName}</option>
                                        )
                                    }   
                                    return null
                                })
                            :
                                <option defaultValue>Select Course</option>
                            }

                            { courses && courses.map(course => {
                                if (course.courseType === "Professional") {
                                    return (
                                        <option  key={course.id} id={course.id} >{course.courseName} </option>
                                    )
                                }
                                return null
                            })}
                        </select>

                        <br/>

                        <b>Module: </b>
                        <select className="form-control" onChange={e => this.setState({selectedModule: e.target[e.target.selectedIndex].id})}>

                            { this.state.selectedModule.length > 0 ?
                                modules && modules.map((module,i) => {
                                    if (module.id === this.state.selectedModule){
                                        return (
                                            <option key={i} id={module.id} defaultValue>{module.moduleName}</option>
                                        )
                                    }
                                    return null
                                })
                            :
                                <option defaultValue>Select Module</option>
                            }

                            { courses && courses.map(course => {
                                if (course.id === this.state.selectedCourse) {
                                    return (
                                        modules && modules.map(module => {
                                            return (
                                               course.selectedModules && course.selectedModules.map(selected => {
                                                    if (module.id === selected) {
                                                        return (
                                                            <option key={module.id} id={module.id}>{module.moduleName} </option>
                                                        )
                                                    }
                                                    return null
                                                })
                                            )
                                        })
                                    )
                                }
                                return null
                            })}
                        </select>
                    </div> 
                }

                <div className="mt-4">
                    <b>Info:</b><br/>
                    <textarea type="text" className="form-control" ref="courseInfo" onChange={event => this.setState({getCourseInfo: event.target.value})}  placeholder="Course info"/>
                    <span className="badge bg-blue text-white" onClick={this.saveData.bind(this, 'info')}>Save and Add New</span>
                    <br/>
                    { this.state.courseInfo && this.state.courseInfo.map((info,i) => {
                        return (
                            <div key={i} style={{margin:"0px"}} className="listing"> 
                                <span onClick={this.removeData.bind(this, 'info', info)}> <i className="list-icon fas fa-trash text-red" /></span>
                                <label style={{margin:"0px"}}>{info}</label>
                            </div>
                        )
                    })}
                </div>

                <div className="mt-4">
                    <b>Overview:</b><br/>
                    <textarea type="text" className="form-control" ref="courseOverview" onChange={event => this.setState({getCourseOverview: event.target.value})}  placeholder="Course Overview"/>
                    <span className="badge bg-blue text-white" onClick={this.saveData.bind(this, 'overview')}>Save and Add New</span>
                    <br/>
                    { this.state.courseOverview && this.state.courseOverview.map((overview,i) => {
                        return (
                            <div key={i} style={{margin:"0px"}} className="listing"> 
                                <span onClick={this.removeData.bind(this, 'overview', overview)}> <i className="list-icon fas fa-trash text-red" /></span>
                                <label style={{margin:"0px"}}>{overview}</label>
                            </div>
                        )
                    })}
                </div>

                <div className="mt-4">
                    <b>What Will Learn:</b><br/>
                    <textarea type="text" className="form-control" ref="courseLearn" onChange={event => this.setState({getCourseLearn: event.target.value})}  placeholder="Course Learn"/>
                    <span className="badge bg-blue text-white" onClick={this.saveData.bind(this, 'learn')}>Save and Add New</span>
                    <br/>
                    { this.state.courseLearn && this.state.courseLearn.map((learn,i) => {
                        return (
                            <div key={i} style={{margin:"0px"}} className="listing"> 
                                <span onClick={this.removeData.bind(this, 'learn', learn)}> <i className="list-icon fas fa-trash text-red" /></span>
                                <label style={{margin:"0px"}}>{learn}</label>
                            </div>
                        )
                    })}
                </div>

                <div className="mt-4">
                    <b>Requirement:</b><br/>
                    <textarea type="text" className="form-control" ref="courseRequirement" onChange={event => this.setState({getCourseRequirement: event.target.value})}  placeholder="Course Requirement"/>
                    <span className="badge bg-blue text-white" onClick={this.saveData.bind(this, 'requirement')}>Save and Add New</span>
                    <br/>
                    { this.state.courseRequirement && this.state.courseRequirement.map((requirement,i) => {
                        return (
                            <div key={i} style={{margin:"0px"}} className="listing"> 
                                <span onClick={this.removeData.bind(this, 'requirement', requirement)}> <i className="list-icon fas fa-trash text-red" /></span>
                                <label style={{margin:"0px"}}>{requirement}</label>
                            </div>
                        )
                    })}
                </div>

                <div className="mt-4 mb-5">
                    <b>Attachment:</b>
                    <br />
                    <select className="mb-2 col col-md-6 form-control" onChange={this.selectedAttachType.bind(this)}>
                        <option defaultValue>{this.state.attachType ? this.state.attachType : 'Choose attachment type'}</option>
                        <option id="video" >Video</option>
                        <option id="image">Image</option>
                    </select>
                    <br />
                    <div hidden={this.state.oldAttachHide}>
                        {courses && courses.map((course,i) => {
                            if (course.id === course_id && this.state.courseAttach !== null) { //sbb nnti dia akan display je video even kalau data tu takde data video
                                return (
                                    <div key={i}>
                                        { this.state.attachType === "image" ?
                                            <div><img alt="" width="200"src={this.state.courseAttach.fileUrl} /></div>
                                        : this.state.attachType === "video" ?
                                            <div><video width="250" height="150" src={this.state.courseAttach.fileUrl} type="video/mp4" controls /></div>
                                        : null }
                                        <label>{this.state.courseAttach.fileName}</label>
                                    </div>
                                )
                            } else if (course.id === course_id && this.state.courseAttach === null) {
                                return (
                                    <em>No selected attachment.</em>
                                )
                            }
                            return null
                        })}
                    </div>

                    { this.state.attachType === "image" ?
                        <div><img alt="" hidden={this.state.newAttachHide} width="250" src={this.state.attachDisplay} /></div>
                    : this.state.attachType === "video" ?
                        <div><video width="300" height="100%" hidden={this.state.newAttachHide} src={this.state.attachDisplay} type="video/mp4" controls /></div>
                    : null }
                    <br />
                    { this.state.attachDisplay ?
                        <small className="text-red"><i>**Attachment preview not appear? Please choose correct attachment type</i></small>
                    : null }
                    <br/>
                    { this.state.attachType ?
                        <input type="file" onChange={this.attachSelectHandler.bind(this)} />
                    : null }
                    <br />
                    <progress value={this.state.progress} max="100" style={{width:"250px"}}/>
                </div>

                { isLoading === true ? 
                    <small>Loading...</small>
                : 
                    message  ?
                        <small className={message.type === 'updateCourseError' ? "text-red" : message.type === 'updateCourseSuccess'  ? "text-green" : null }>
                            {message.msg}
                        </small>
                    : null
                }

                <div className="mt-5 row justify-content-end">
                    <button type="button" className="btn m-2 btn-outline-black" onClick={this.updateCourse.bind(this)}><i className="fas fa-check-circle"/>{' '}update</button>
                    <button type="button" className="btn m-2 btn-outline-black" onClick={this.props.cancel.bind(this)}><i className="fas fa-times"/>{' '}cancel</button>
                </div>
            </div>

        )
    }
}

const mapStateToProps = (state) => {
  return {
      updateCourseError: state.courses.updateCourseError,
      updateCourseSuccess: state.courses.updateCourseSuccess,
  }
}

const mapDispatchToProps = (dispatch) => {
    return {
      updateCourse: (updatedCourse, course_id) => dispatch(updateCourse(updatedCourse, course_id)),
    }
}
  
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
)(EditCourse);