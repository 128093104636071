import React, { Component } from 'react';
import { connect } from 'react-redux'
import moment from 'moment';
import { classCustomEmail } from '../../../stores/actions/classAction';

class ClassCustomEmail extends Component {

    constructor(props){
        super(props)
        this.state = {
            to: [],
            subject: '',
            html: '',
            
            isLoading: null,
            message: null,
        }
        this.baseState = this.state 
    }

    componentDidUpdate = (prevProps, prevState) => { 
        const { classCustomEmailError, classCustomEmailSuccess } = this.props;

        if(prevProps.classCustomEmailError !== classCustomEmailError) {
            this.setState({
                isLoading: false,
                message: {
                    msg: classCustomEmailError.message,
                    type: classCustomEmailError.type,
                }
            })
        }

        if(prevProps.classCustomEmailSuccess !== classCustomEmailSuccess) {
            this.setState({
                isLoading: false,
                message: {
                    msg: classCustomEmailSuccess.message,
                    type: classCustomEmailSuccess.type,
                }
            })

            var result = window.confirm(classCustomEmailSuccess.message);
            if (result) {
                this.props.hideModalEmail();
                this.setState(this.baseState)
            }
        }
    } 

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    sendEmail = () => {
        let emails = [];

        this.state.to && this.state.to.map(to => {
            return emails.push(to.email)
        })

        let sendDate = moment().format('DD-MM-YYYY, h:mm a');

        let data = {
            from: this.state.from,
            to: emails,
            subject: this.state.subject,
            html: this.state.html,
            sendDate,
            selectedBatchId: this.props.selectedBatchId
        }

        var result = window.confirm("Confirm send email?");
        if (result) {
            this.setState({
                isLoading: true
            })
            this.props.classCustomEmail(data);
        }
    }

    handleSelectedParticipant = (e) => {
        this.setState({
            to: [ 
                ...this.state.to, 
                {
                    email: e.target[e.target.selectedIndex].id.split(',')[0],
                    name: e.target[e.target.selectedIndex].id.split(',')[1],
                }
            ],
        });
    }

    removeSelectedParticipant = (email) => {
        let removed = this.state.to.filter(selected => {
          return selected.email !== email
        })
    
        this.setState({
            to: removed
        })
    }

    render(){

        const { users, physicalClassBatches, selectedBatchId } = this.props;
        const { message, isLoading } = this.state;

        let participantList = physicalClassBatches && physicalClassBatches.map(batch => {
            return(
                batch.selectedParticipants && batch.selectedParticipants.map((selected,i) => {
                    if(batch.id === selectedBatchId) {
                        return (
                            users && users.map(user => {
                                if(user.userId === selected.userId) {
                                    return (
                                        <option key={i} id={['<' + user.email + '>', user.firstname + user.lastname]}>{user.firstname} {user.lastname} ({user.email})</option>
                                    )
                                }
                                return null
                            })
                        )
                    }
                    return null
                })
            )
        })

        return(

        <div>
            <h5 className="middle">CLASS CUSTOM EMAIL
                <div className="row"><div className="title-border"></div></div>
            </h5>

            <b>From:</b>
            <input type="text" id="from" disabled className="form-control" value='Halal Academy <info@halal-academy.com>' /> 
            <br/>

            <b>To:</b> {' '}
            <select onChange={this.handleSelectedParticipant.bind(this)} className="form-control">
                <option defaultValue>Choose Participant</option>
                {participantList}
            </select>
            <div style={{margin:"20px"}}>
                { this.state.to && this.state.to.map((to,i) => {
                    return <li key={i}>{to.name} {' '}{to.email} {' '} <span onClick={this.removeSelectedParticipant.bind(this, to.email)}><i className="fas fa-times"/></span></li>
                })}
            </div>
            <br/>

            <b>Subject:</b>
            <input type="text" id="subject" onChange={this.handleChange} className="form-control" value={this.state.subject} placeholder="Subject"/>
            <br/>

            <b>Content:</b>
            <textarea type="text" id="html" onChange={this.handleChange} className="form-control" value={this.state.html} placeholder="<p>text</p> <b>text-bold</b> <ul><li>point</li></ul>"/>

            <br/>

            { isLoading === true ? 
                <small>Loading...</small>
            : 
                message  ?
                    <small className={message.type === 'classCustomEmailError' ? "text-red" : message.type === 'classCustomEmailSuccess'  ? "text-green" : null }>
                        {message.msg}
                    </small>
                : null
            }

            <div className="mt-5 row justify-content-end">
                <button  className="btn m-2 btn-outline-black" onClick={this.sendEmail.bind(this)}><i className="fas fa-envelope"/>{' '}Send Email</button>
            </div>
        </div>
       
        )
    }
}

const mapStateToProps = (state) => {
    return {
        classCustomEmailError: state.classes.classCustomEmailError,
        classCustomEmailSuccess: state.classes.classCustomEmailSuccess,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
      classCustomEmail: (data) => dispatch(classCustomEmail(data)),
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(ClassCustomEmail);