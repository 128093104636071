import React, { Component } from 'react';
import { Link,Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'

import CourseProgress from './CourseProgress';
import EnrollmentManager from './EnrollmentManager/EnrollmentManager';
import PhysicalClass from './PhysicalClass/PhysicalClass';
import Events from './EventManager/Events';

class AdminManager extends Component{

    show = () => {
        document.getElementById("myModal").style.display = "block";
    }
    
    hide = () => {
        document.getElementById("myModal").style.display = "none";
    }

    render(){

        const { profile, auth, users, extraClassInfo, transcripts, classCustomEmail, physicalClassBatches, physicalClassParticipants, pcpGroup, courses, modules, events, eventsAttendees } = this.props;

        const query = new URLSearchParams(this.props.location.search);
        let query_topic = query.get('topic');

        if(profile && profile.userId === auth.uid){
            if(profile.type !== "admin") {
                return <Redirect to="/" />
            }
        }
        //console.log(events)
        return(
        <div className="dashboard" >
            <div className="justify-content-center row col-md-12">
                <div className="col-md-3" >
                    <div  className="dashboard-left middle">
                        <i style={{marginBottom:"10px"}} className="text-green fas fa-user-shield fa-3x"/>
                        <h3>admin manager </h3>  
                        <nav className="middle navbar navbar-expand-lg navbar-light bg-transparent">
                            <button className="btn navbar-toggler border-0 " type="button" data-toggle="collapse" data-target="#navbarSupportedContent2" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>

                            <div className="collapse navbar-collapse" id="navbarSupportedContent2">
                                <div>
                                    {/* <Link className={query_topic === 'user' ? "col btn all-btn btn-active" : "col btn all-btn"} to="/adminmanager?topic=user">User Data</Link> */}
                                    <Link className={query_topic === 'progress' ? "col btn all-btn btn-active" : "col btn all-btn"} to="/adminmanager?topic=progress">course progress</Link>
                                    <Link className={query_topic === 'enrollment' ? "col btn all-btn btn-active" : "col btn all-btn"} to="/adminmanager?topic=enrollment&filter=new-enrollment">enrollment</Link>
                                    <Link className={query_topic === 'class' ? "col btn all-btn btn-active" : "col btn all-btn"} to="/adminmanager?topic=class">Practical Session</Link>
                                    <Link className={query_topic === 'event' ? "col btn all-btn btn-active" : "col btn all-btn"} to="/adminmanager?topic=event">Event Session</Link>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>

                <div className="dashboard-right col">
                    { query_topic === 'progress'?
                    <CourseProgress history={this.props.history} query={query} physicalClassBatches={physicalClassBatches} modules={modules} courses={courses} />

                    : query_topic === 'class'?
                    <PhysicalClass extraClassInfo={extraClassInfo} transcripts={transcripts} pcpGroup={pcpGroup} query={query} classCustomEmail={classCustomEmail} physicalClassBatches={physicalClassBatches} users={users} courses={courses} physicalClassParticipants={physicalClassParticipants} />
   
                    : query_topic === 'enrollment'?
                    <EnrollmentManager history={this.props.history} query={query} users={users} courses={courses} modules={modules} show={this.show} hide={this.hide} />
                    
                    :query_topic === 'event'?
                    <Events extraClassInfo={extraClassInfo} transcripts={transcripts} pcpGroup={pcpGroup} query={query} classCustomEmail={classCustomEmail} physicalClassBatches={physicalClassBatches} users={users} courses={courses} physicalClassParticipants={physicalClassParticipants} events={events} eventsAttendees={eventsAttendees}/>
                    
                    :''}
                </div>
            </div>
        </div>

        
        )
    }
}

const mapStateToProps = (state) => {
    return {
        profile: state.firebase.profile,
        auth: state.firebase.auth,

        users: state.firestore.ordered.users,
        classCustomEmail: state.firestore.ordered.classCustomEmail,
        pcpGroup: state.firestore.ordered.pcpGroup,
        physicalClassParticipants: state.firestore.ordered.physicalClassParticipants,
        physicalClassBatches: state.firestore.ordered.physicalClassBatches,
        courses: state.firestore.ordered.courses,
        modules: state.firestore.ordered.modules,
        transcripts: state.firestore.ordered.transcripts,
        extraClassInfo: state.firestore.ordered.extraClassInfo,
        events: state.firestore.ordered.events,
        eventsAttendees: state.firestore.ordered.eventsAttendees
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect([
        { collection: 'users'},
        { collection: 'classCustomEmail'},
        { collection: 'pcpGroup', orderBy:'groupCode'},
        { collection: 'physicalClassParticipants', orderBy:'date'},
        { collection: 'physicalClassBatches', orderBy:'batchName'},
        { collection: 'courses', orderBy:'courseCode'},
        { collection: 'modules', orderBy:'moduleCode'},
        { collection: 'transcripts' },
        { collection: 'extraClassInfo' },
        { collection: 'events' },
        { collection: 'eventsAttendees' },
    ])
)(AdminManager);