import React, { Component } from 'react';
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import { deleteCourseEnrollment, deleteExamEnrollment, deleteModuleEnrollment, deleteQuizEnrollment } from '../../../stores/actions/enrollmentAction';

class DeleteEnrollment extends Component{
    
    constructor(props){
        super(props)
        this.state = {
            userId: '',
            enrollmentEmail:'',
            enrollmentType:'',
            enrollmentCourseName:'',
            enrollmentCourseId:'',
            enrollmentModuleId:'',
            enrollmentCustomLabel:'',
    
            hideDropdown: true,
            hideBtnCheckEmail: false,
        }
        this.baseState = this.state 
    }
    
    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    selectedCourse = (e) => {
        this.setState({
            enrollmentCourseId: e.target[e.target.selectedIndex].id,
            enrollmentCourseName: e.target.value
        })
    }

    handleEmail = (e) => {
        const { users } = this.props;

        let userId = '';
        let email = e.target.value;

        users && users.map(user => {
            if(user.email === email.toLowerCase()) {
                return userId =  user.userId
            }
            return null
        })

        this.props.history.push(`/adminmanager?topic=enrollment&filter=delete-enrollment&userId=${userId}`);
        this.setState({ enrollmentEmail: email })
    }

    checkEmail = (e) => {
        e.preventDefault();

        const { users } = this.props;
        let emailLists = null, email = null;

        emailLists = users && users.map(user => {
            return user.email
        })

        email = [...emailLists]

        if (email.indexOf(this.state.enrollmentEmail.toLowerCase()) >=0) { //cari persamaan email
            this.setState({
                hideDropdown: !this.state.hideDropdown,
                hideBtnCheckEmail: !this.state.hideBtnCheckEmail
            })
        } else {
            this.setState({enrollmentEmail: ''})
            alert('email not registered')
        }
    }

    deleteData = (dataType, dataId) => {
        
        var result = window.confirm("Confirm delete?");

        if (result) {

            if(dataType === "course") {
                this.props.deleteCourseEnrollment(dataId);
            } else if(dataType === "exam") {
                this.props.deleteExamEnrollment(dataId);
            } else if(dataType === "module") {
                this.props.deleteModuleEnrollment(dataId);
            } else if(dataType === "quiz") {
                this.props.deleteQuizEnrollment(dataId);
            } else return null

        }
    }

    render(){

        const { query, courses, modules, courseEnrollments, moduleEnrollments, examEnrollments, quizEnrollments } = this.props;
        const { enrollmentCourseId, enrollmentCourseName } = this.state;

        let userId = query.get('userId');

        let courseList =  courses && courses.map((course,i) => {
            if(course.courseType === "Professional") {
                return (
                    <option key={i} id={course.id}>{course.courseName}</option>
                )
            } return null
        })

        return(

        <div>

            <input type="email" id="enrollmentEmail" className="mt-3 form-control" onChange={this.handleEmail} value={this.state.enrollmentEmail} placeholder="Email"/>

            <div hidden={this.state.hideDropdown}>
                <select id="enrollmentCourseName" onChange={this.selectedCourse.bind(this)} value={enrollmentCourseName} className="form-control">
                    <option defaultValue>Course Name</option>
                    {courseList}
                </select>
            </div>

            { enrollmentCourseId ?
                <>
                    <div style={{marginTop:'2vh'}} className='alert alert-danger'>
                        Dear admin, please re-check if this user are already in the <b>Practical Session</b>. If yes, please remove the participant.
                    </div>

                    <table style={{marginTop:'2vh'}} className='table'>
                        <thead>
                            <tr>
                                <th className='text-left'>Table Name</th>
                                <th className='text-left'>Course/Module Name</th>
                                <th>Delete</th>
                            </tr>
                        </thead>

                        <tbody>
                            { courseEnrollments && courseEnrollments.map(ecourse => {
                                return (
                                    courses && courses.map(course => {
                                        if(ecourse.userId === userId && ecourse.courseId === enrollmentCourseId && course.id === ecourse.courseId) {
                                            return (
                                                <tr key={ecourse.id}>
                                                    <td className='text-left'>Course Enrollments</td>
                                                    <td className='text-left'>{course.courseName}</td>
                                                    <td><span className='badge bg-red text-white' onClick={this.deleteData.bind(this,'course', ecourse.id)}><i className='fas fa-trash'/></span></td>
                                                </tr>
                                            )
                                        } return null
                                    })
                                )
                            })} 

                            { examEnrollments && examEnrollments.map(eexam => {
                                return (
                                    courses && courses.map(course => {
                                        if(eexam.userId === userId && eexam.courseId === enrollmentCourseId && course.id === eexam.courseId) {
                                            return (
                                                <tr key={eexam.id}>
                                                    <td className='text-left'>Exam Enrollments</td>
                                                    <td className='text-left'>{course.courseName}</td>
                                                    <td><span className='badge bg-red text-white' onClick={this.deleteData.bind(this,'exam', eexam.id)}><i className='fas fa-trash'/></span></td>
                                                </tr>
                                            )
                                        } return null
                                    })
                                )
                            })} 
                            
                            { moduleEnrollments && moduleEnrollments.map(emodule => {
                                return (
                                    modules && modules.map(module => {
                                        if(emodule.userId === userId && emodule.courseId === enrollmentCourseId && module.id === emodule.moduleId) {
                                            return (
                                                <tr key={emodule.id}>
                                                    <td className='text-left'>Module Enrollments</td>
                                                    <td className='text-left'>{module.moduleName}</td>
                                                    <td><span className='badge bg-red text-white' onClick={this.deleteData.bind(this,'module', emodule.id)}><i className='fas fa-trash'/></span></td>
                                                </tr>
                                            )
                                        } return null
                                    })
                                )
                            })}
                            
                            { quizEnrollments && quizEnrollments.map(equiz => {
                                return (
                                    modules && modules.map(module => {
                                        if(equiz.userId === userId && equiz.courseId === enrollmentCourseId && module.id === equiz.moduleId) {
                                            return (
                                                <tr key={equiz.id}>
                                                    <td className='text-left'>Quiz Enrollments</td>
                                                    <td className='text-left'>{module.moduleName}</td>
                                                    <td><span className='badge bg-red text-white' onClick={this.deleteData.bind(this,'quiz', equiz.id)}><i className='fas fa-trash'/></span></td>
                                                </tr>
                                            )
                                        } return null
                                    })
                                )
                            })}

                        </tbody>
                    </table>
                </>
            : null }

            <div hidden={this.state.hideBtnCheckEmail} className="mt-5 row justify-content-end">
                <button className="btn m-2 btn-outline-black" onClick={this.checkEmail.bind(this)}><i className="fas fa-search"/>{' '}check email</button>
            </div>
        </div>

        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {

        modules: state.firestore.ordered.modules,
        courseEnrollments: state.firestore.ordered.courseEnrollments,
        moduleEnrollments: state.firestore.ordered.moduleEnrollments,
        examEnrollments: state.firestore.ordered.examEnrollments,
        quizEnrollments: state.firestore.ordered.quizEnrollments,

        userId: ownProps.query.get('userId'),
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
      deleteCourseEnrollment: (courseId) => dispatch(deleteCourseEnrollment(courseId)),
      deleteExamEnrollment: (examId) => dispatch(deleteExamEnrollment(examId)),
      deleteModuleEnrollment: (moduleId) => dispatch(deleteModuleEnrollment(moduleId)),
      deleteQuizEnrollment: (quizId) => dispatch(deleteQuizEnrollment(quizId)),
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect(props => [
        { collection: 'modules' },
        { collection: 'courseEnrollments', where: ['userId', '==', props.userId] },
        { collection: 'moduleEnrollments', orderBy:'indexKey', where: ['userId', '==', props.userId] },
        { collection: 'examEnrollments', where: ['userId', '==', props.userId] },
        { collection: 'quizEnrollments', where: ['userId', '==', props.userId] },
    ])
)(DeleteEnrollment)