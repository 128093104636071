import moment from 'moment'

export const newEnrollment = (enrollment) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => { 

        const firestore = getFirestore();

        firestore.collection('enrollments').add({
            userId: enrollment.userId,
            enrollmentEmail: enrollment.enrollmentEmail,
            enrollmentType: enrollment.enrollmentType,
            enrollmentCourseName: enrollment.enrollmentCourseName,
            enrollmentCourseId: enrollment.enrollmentCourseId, //spy sennag dlm function
            enrollmentModuleId: enrollment.enrollmentModuleId, //spy sennag dlm function
            enrollmentCustomLabel: enrollment.enrollmentCustomLabel,
            employerId: enrollment.employerId

        }).then(() => {
            dispatch({ type:'ADDED_NEW_ENROLLMENT', message: `Data processing. Enrollment will be done automatically. It may take some time.`});
        }).catch((err) => {
            dispatch({ type:'ADDED_NEW_ENROLLMENT_ERROR', err});
        })

    }
}

export const extendEnrollment = (extendCount, date, courseEnrolledId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => { 

        const firestore = getFirestore();

        firestore.collection('courseEnrollments').doc(courseEnrolledId).update({ 

            enrollExpired: date,
            extendCount: extendCount
            
        }).then(() => {
            dispatch({ type:'UPDATE_EXPIRED_DATE', message: `Enrollment has been extend successfully.`});
        }).catch((err) => {
            dispatch({ type:'UPDATE_EXPIRED_DATE_ERROR', err});
        })

    }
}

export const updateEnrolledModule_topics = (topics, enrolledCurrentModuleId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => { 

        const firestore = getFirestore();

        firestore.collection('moduleEnrollments').doc(enrolledCurrentModuleId).update({ 

            topics: topics
            
        }).then(() => {
            dispatch({ type:'UPDATE_TOPIC_STATUS', topics: topics});
        }).catch((err) => {
            dispatch({ type:'UPDATE_TOPIC_STATUS_ERROR', err});
        })

    }
}

export const updateEnrolledModule_isAvailable = (enrolledNextModuleId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => { 

        const firestore = getFirestore();

        firestore.collection('moduleEnrollments').doc(enrolledNextModuleId).update({ 

            isAvailable: true
            
        }).then(() => {
            dispatch({ type:'UPDATE_NEXT_MODULE_STATUS', enrolledNextModuleId: enrolledNextModuleId});
        }).catch((err) => {
            dispatch({ type:'UPDATE_NEXT_MODULE_STATUS_ERROR', err});
        })

    }
}

export const updateEnrolledModule_isDone = (enrolledCurrentModuleId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => { 

        const firestore = getFirestore();

        firestore.collection('moduleEnrollments').doc(enrolledCurrentModuleId).update({ 

            isDone: true
            
        }).then(() => {
            dispatch({ type:'UPDATE_CURRENT_MODULE_STATUS', enrolledCurrentModuleId: enrolledCurrentModuleId});
        }).catch((err) => {
            dispatch({ type:'UPDATE_CURRENT_MODULE_STATUS_ERROR', err});
        })

    }
}

export const updateEnrolledExam_isAvailable = (enrolledExamId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => { 

        const firestore = getFirestore();

        firestore.collection('examEnrollments').doc(enrolledExamId).update({ 

            isAvailable: true
            
        }).then(() => {
            dispatch({ type:'UPDATE_EXAM_ENROLLMENT_STATUS', enrolledExamId: enrolledExamId});
        }).catch((err) => {
            dispatch({ type:'UPDATE_EXAM_ENROLLMENT_STATUS_ERROR', err});
        })

    }
}

export const updateEnrolledExam_isDone = (enrolledExamId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => { 

        const firestore = getFirestore();

        firestore.collection('examEnrollments').doc(enrolledExamId).update({ 

            isDone: true,
            isDoneDate: moment().format('YYYY-MM-DD')
            
        }).then(() => {
            dispatch({ type:'UPDATE_EXAM_ISDONE', enrolledExamId: enrolledExamId});
        }).catch((err) => {
            dispatch({ type:'UPDATE_EXAM_ISDONE_ERROR', err});
        })

    }
}

export const updateEnrolledCourse_isDoneExam = (enrolledCourseId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => { 

        const firestore = getFirestore();

        firestore.collection('courseEnrollments').doc(enrolledCourseId).update({ 

            isDoneExam: true
            
        }).then(() => {
            dispatch({ type:'UPDATE_COURSE_ISDONEEXAM', enrolledCourseId: enrolledCourseId});
        }).catch((err) => {
            dispatch({ type:'UPDATE_COURSE_ISDONEEXAM_ERROR', err});
        })

    }
}

export const updateEnrolledQuiz_quizFailCounter = (enrolledQuizId, counter) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => { 

        const firestore = getFirestore();

        firestore.collection('quizEnrollments').doc(enrolledQuizId).update({ 

            quizFailCounter: counter
            
        }).then(() => {
            dispatch({ type:'UPDATE_QUIZ_FAIL_COUNTER', enrolledQuizId: enrolledQuizId});
        }).catch((err) => {
            dispatch({ type:'UPDATE_QUIZ_FAIL_COUNTER_ERROR', err});
        })
    }
}

export const updateEnrolledQuiz_quizScore = (enrolledQuizId, marks) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => { 

        const firestore = getFirestore();

        firestore.collection('quizEnrollments').doc(enrolledQuizId).update({ 

            quizScore: marks
            
        }).then(() => {
            dispatch({ type:'UPDATE_QUIZ_SCORE', enrolledQuizId: enrolledQuizId});
        }).catch((err) => {
            dispatch({ type:'UPDATE_QUIZ_SCORE_ERROR', err});
        })
    }
}

export const updateEnrolledQuiz_suspend = (enrolledQuizId, date, quizSuspendCount) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => { 

        const firestore = getFirestore();

        firestore.collection('quizEnrollments').doc(enrolledQuizId).update({ 

            quizAvailableDate: date,
            quizSuspendCounter: quizSuspendCount
            
        }).then(() => {
            dispatch({ type:'UPDATE_QUIZ_SUSPEND', enrolledQuizId: enrolledQuizId});
        }).catch((err) => {
            dispatch({ type:'UPDATE_QUIZ_SUSPEND_ERROR', err});
        })
    }
}

export const updateEnrolledQuiz_renewSuspend = (enrolledQuizId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => { 

        const firestore = getFirestore();

        firestore.collection('quizEnrollments').doc(enrolledQuizId).update({ 

            quizFailCounter: 0,
            quizAvailableDate: '',
            // quizScore: 0
            
        }).then(() => {
            dispatch({ type:'RENEW_QUIZ_SUSPEND', enrolledQuizId: enrolledQuizId});
        }).catch((err) => {
            dispatch({ type:'RENEW_QUIZ_SUSPEND_ERROR', err});
        })
    }
}

export const updateEnrolledExam_examScore = (enrolledExamId, marks) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => { 

        const firestore = getFirestore();

        firestore.collection('examEnrollments').doc(enrolledExamId).update({ 

            examScore: marks
            
        }).then(() => {
            dispatch({ type:'UPDATE_EXAM_SCORE', enrolledExamId: enrolledExamId});
        }).catch((err) => {
            dispatch({ type:'UPDATE_EXAM_SCORE_ERROR', err});
        })
    }
}

export const updateEnrolledExam_examFailCounter = (enrolledExamId, counter) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => { 

        const firestore = getFirestore();

        firestore.collection('examEnrollments').doc(enrolledExamId).update({ 

            examFailCounter: counter
            
        }).then(() => {
            dispatch({ type:'UPDATE_EXAM_FAIL_COUNTER', enrolledExamId: enrolledExamId});
        }).catch((err) => {
            dispatch({ type:'UPDATE_EXAM_FAIL_COUNTER_ERROR', err});
        })
    }
}

export const updateEnrolledExam_suspend = (enrolledExamId, date, examSuspendCount) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => { 

        const firestore = getFirestore();

        firestore.collection('examEnrollments').doc(enrolledExamId).update({ 

            examAvailableDate: date,
            examSuspendCounter: examSuspendCount
            
        }).then(() => {
            dispatch({ type:'UPDATE_EXAM_SUSPEND', enrolledExamId: enrolledExamId});
        }).catch((err) => {
            dispatch({ type:'UPDATE_EXAM_SUSPEND_ERROR', err});
        })
    }
}

export const updateEnrolledExam_renewSuspend = (enrolledExamId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => { 

        const firestore = getFirestore();

        firestore.collection('examEnrollments').doc(enrolledExamId).update({ 

            examFailCounter: 0,
            examAvailableDate: '',
            // examScore: 0
            
        }).then(() => {
            dispatch({ type:'RENEW_EXAM_SUSPEND', enrolledExamId: enrolledExamId});
        }).catch((err) => {
            dispatch({ type:'RENEW_EXAM_SUSPEND_ERROR', err});
        })
    }
}

export const updateEnrolledQuiz_quizAttend = (attended, enrolledQuizId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => { 

        const firestore = getFirestore();

        firestore.collection('quizEnrollments').doc(enrolledQuizId).update({ 

            quizAttend: attended.quizAttend,
            quizFailCounter: attended.quizFailCounter
            
        }).then(() => {
            dispatch({ type:'UPDATE_QUIZ_ATTEND', enrolledQuizId: enrolledQuizId});
        }).catch((err) => {
            dispatch({ type:'UPDATE_QUIZ_ATTEND_ERROR', err});
        })

    }
}

export const updateEnrolledExam_examAttend = (attended, enrolledExamId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => { 

        const firestore = getFirestore();

        firestore.collection('examEnrollments').doc(enrolledExamId).update({ 

            examAttend: attended.examAttend,
            examFailCounter: attended.examFailCounter
            
        }).then(() => {
            dispatch({ type:'UPDATE_EXAM_ATTEND', enrolledExamId: enrolledExamId});
        }).catch((err) => {
            dispatch({ type:'UPDATE_EXAM_ATTEND_ERROR', err});
        })
    }
}

export const module_startDate = (enrolledId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => { 

        const firestore = getFirestore();
        
        const now = new Date();
        const time = now.toLocaleTimeString();
        
        const year = now.getFullYear();
        const month = now.getMonth() + 1;
        const day = now.getDate();     

        firestore.collection('moduleEnrollments').doc(enrolledId).update({ 

            startDate: `${day}/${month}/${year} ${time}`
            
        }).then(() => {
            dispatch({ type:'UPDATE_MODULE_START_DATE', enrolledId: enrolledId});
        }).catch((err) => {
            dispatch({ type:'UPDATE_MODULE_START_DATE_ERROR', err});
        })
    }
}

export const module_finishDate = (enrolledId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => { 

        const firestore = getFirestore();
        
        const now = new Date();
        const time = now.toLocaleTimeString();
        
        const year = now.getFullYear();
        const month = now.getMonth() + 1;
        const day = now.getDate();     

        firestore.collection('moduleEnrollments').doc(enrolledId).update({ 

            finishDate: `${day}/${month}/${year} ${time}`
            
        }).then(() => {
            dispatch({ type:'UPDATE_MODULE_FINISH_DATE', enrolledId: enrolledId});
        }).catch((err) => {
            dispatch({ type:'UPDATE_MODULE_FINISH_DATE_ERROR', err});
        })
    }
}

export const exam_startDate = (enrolledId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => { 

        const firestore = getFirestore();
        
        const now = new Date();
        const time = now.toLocaleTimeString();
        
        const year = now.getFullYear();
        const month = now.getMonth() + 1;
        const day = now.getDate();     

        firestore.collection('examEnrollments').doc(enrolledId).update({ 

            startDate: `${day}/${month}/${year} ${time}`
            
        }).then(() => {
            dispatch({ type:'UPDATE_EXAM_START_DATE', enrolledId: enrolledId});
        }).catch((err) => {
            dispatch({ type:'UPDATE_EXAM_START_DATE_ERROR', err});
        })
    }
}

export const exam_finishDate = (enrolledId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => { 

        const firestore = getFirestore();
        
        const now = new Date();
        const time = now.toLocaleTimeString();
        
        const year = now.getFullYear();
        const month = now.getMonth() + 1;
        const day = now.getDate();     

        firestore.collection('examEnrollments').doc(enrolledId).update({ 

            finishDate: `${day}/${month}/${year} ${time}`
            
        }).then(() => {
            dispatch({ type:'UPDATE_EXAM_FINISH_DATE', enrolledId: enrolledId});
        }).catch((err) => {
            dispatch({ type:'UPDATE_EXAM_FINISH_DATE_ERROR', err});
        })
    }
}

export const deleteCourseEnrollment = (id) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {

        const firestore = getFirestore();

        firestore.collection("courseEnrollments").doc(id).delete()
        .then(() => {
            dispatch({ type:'DELETED_COURSE_ENROLLMENT', message: 'Course enrollments deleted successfully'});
        }).catch((err) => {
            dispatch({ type:'DELETED_COURSE_ENROLLMENT_ERROR', err});
        })
    }
}

export const deleteExamEnrollment = (id) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {

        const firestore = getFirestore();

        firestore.collection("examEnrollments").doc(id).delete()
        .then(() => {
            dispatch({ type:'DELETED_EXAM_ENROLLMENT', message: 'Exam enrollments deleted successfully'});
        }).catch((err) => {
            dispatch({ type:'DELETED_EXAM_ENROLLMENT_ERROR', err});
        })
    }
}

export const deleteModuleEnrollment = (id) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {

        const firestore = getFirestore();

        firestore.collection("moduleEnrollments").doc(id).delete()
        .then(() => {
            dispatch({ type:'DELETED_MODULE_ENROLLMENT', message: 'Module enrollments deleted successfully'});
        }).catch((err) => {
            dispatch({ type:'DELETED_MODULE_ENROLLMENT_ERROR', err});
        })
    }
}

export const deleteQuizEnrollment = (id) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {

        const firestore = getFirestore();

        firestore.collection("quizEnrollments").doc(id).delete()
        .then(() => {
            dispatch({ type:'DELETED_QUIZ_ENROLLMENT', message: 'Quiz enrollments deleted successfully'});
        }).catch((err) => {
            dispatch({ type:'DELETED_QUIZ_ENROLLMENT_ERROR', err});
        })
    }
}