import React, { Component } from 'react';
import { connect } from 'react-redux'
import { storage } from '../../../config/fbConfig'
import { addHalalInfo } from '../../../stores/actions/infoAction';

class HalalInfo extends Component{
    
    constructor(props){
        super(props)
        this.state = {
            infoType:'HALAL INFO',
            infoName:'',
            selectedFile:'',
    
            selectedHalalFile:'',
            halalFile: null,
            progress: '',
            videoDisplay:null,

            attachType:'',
            
            isLoading: null,
            message: null,
        }
        this.baseState = this.state 
    }
    
    componentDidUpdate = (prevProps, prevState) => { 
        const { addHalalError, addHalalSuccess } = this.props;

        if(prevProps.addHalalError !== addHalalError) {
            this.setState({
                isLoading: false,
                message: {
                    msg: addHalalError.message,
                    type: addHalalError.type,
                }
            })
        }

        if(prevProps.addHalalSuccess !== addHalalSuccess) {
            this.setState({
                isLoading: false,
                message: {
                    msg: addHalalSuccess.message,
                    type: addHalalSuccess.type,
                }
            })
            setTimeout(() => { 
                this.setState(this.baseState)
                this.props.hide();
            }, 2000);
        }
    } 

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    selectedAttachType = (e) => {
        this.setState({
            attachType:  e.target[e.target.selectedIndex].id,
        })
    }

    halalFile = (e) => {
        if(e.target.files[0]) {
            this.setState({
                halalFile: e.target.files[0],
            });
    
            if(this.state.attachType === "video") {
                this.setState({
                    videoDisplay: URL.createObjectURL(e.target.files[0]) //ubah imgUrl to pic
                });
            }
        }
    }

    addHalalInfo = () => {

        const { halalFile, attachType } = this.state;

        var result = window.confirm("Confirm add?");

        if (result) {
            this.setState({
                isLoading: true
            })
            if (halalFile !== null) {

                let ref = null, ref2 = null;

                if(attachType === "pdf") {
                    ref = `documents/${halalFile.name}`
                    ref2 = 'documents'
                } else if (attachType === "video") {
                    ref = `videos/${halalFile.name}`
                    ref2 = 'videos'
                }

                const uploadTask = storage.ref(ref).put(halalFile);
                uploadTask.on('state_changed', 
                    (snapshot) => {
                        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                        this.setState({
                            progress: progress
                        });
                    }, 
    
                    (error) => {
                        alert(error)
                    }, 
    
                    () => {
                        storage.ref(ref2).child(halalFile.name).getDownloadURL().then(fileUrl => {
                        
                            this.setState({
                                selectedHalalFile: {
                                    fileUrl: fileUrl,
                                    fileName: halalFile.name,
                                    fileType: attachType
                                }
                            });
                            this.props.addHalalInfo(this.state);
                        })
                    }
                );
            } else {
                this.props.addHalalInfo(this.state);
            }
        }
    }


    render(){
        
        const { message, isLoading } = this.state;

        return(

            <div>
                <b>TITLE:</b><br />
                <input type="text" id="infoName" onChange={this.handleChange.bind(this)} className="form-control" placeholder="Title"/>
                <br/>
                <b>ATTACHMENT:</b>
                <br />
                <select className="mb-2 col col-md-6 form-control" onChange={this.selectedAttachType.bind(this)}>
                    <option defaultValue>Choose attachment type</option>
                    <option id="video" >Video</option>
                    <option id="pdf">PDF</option>
                </select>
                <br />
                { this.state.halalFile && this.state.attachType === "video" ?
                    <div><video width="300" height="200" src={this.state.videoDisplay} type="video/mp4" controls /></div>
                : null }
                <input className="mt-2" type="file" onChange={this.halalFile.bind(this)} /> <br/>
                <progress value={this.state.progress} max="100" style={{width:"250px"}}/>

                <br/>
            
                { isLoading === true ? 
                    <small>Loading...</small>
                : 
                    message  ?
                        <small className={message.type === 'addHalalError' ? "text-red" : message.type === 'addHalalSuccess'  ? "text-green" : null }>
                            {message.msg}
                        </small>
                    : null
                }

                <div className="mt-5 row justify-content-end">
                    <button type="button"className="btn m-2 btn-outline-black" onClick={this.addHalalInfo.bind(this)}><i className="fas fa-plus-circle"/>{' '}add halal info</button>
                </div>
            </div>
        
        )
    }
}

const mapStateToProps = (state) => {
    return {
        addHalalError: state.infos.addHalalError,
        addHalalSuccess: state.infos.addHalalSuccess,
    }
}
  
const mapDispatchToProps = (dispatch) => {
    return {
      addHalalInfo: (halalInfo) => dispatch(addHalalInfo(halalInfo))
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(HalalInfo);