import React, { Component } from 'react';
import shortid from 'shortid';
import { connect } from 'react-redux'
import { updateQuestion } from '../../../stores/actions/questionAction';

class EditQuestion extends Component{

    constructor(props){
        super(props)
        this.state = {
            questionCode: this.props.question.questionCode,
            questionCourse: this.props.question.questionCourse,
            questionModule: this.props.question.questionModule,
            questionMark: this.props.question.questionMark,
            questionContent1: this.props.question.questionContent1,
            questionContent2: this.props.question.questionContent2,
            answers: this.props.question.answers,
    
            getAnswerContent: null,
            getAnswerStatus: null,
        
            isLoading: null,
            message: null,
        }
        this.baseState = this.state 
    }

    componentDidUpdate = (prevProps, prevState) => { 
        const { updateQuestionError, updateQuestionSuccess } = this.props;

        if(prevProps.updateQuestionError !== updateQuestionError) {
            this.setState({
                isLoading: false,
                message: {
                    msg: updateQuestionError.message,
                    type: updateQuestionError.type,
                }
            })
        }

        if(prevProps.updateQuestionSuccess !== updateQuestionSuccess) {
            this.setState({
                isLoading: false,
                message: {
                    msg: updateQuestionSuccess.message,
                    type: updateQuestionSuccess.type,
                }
            })
            setTimeout(() => { 
                this.setState(this.baseState)
                this.props.cancel();
            }, 2000);
        }
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.valueAsNumber || e.target.value
        })
    }

    selectedEditCourse = (e) => {
        this.setState({
            questionCourse: e.target[e.target.selectedIndex].id
        })
    }

    selectedEditModule = (e) => {
        this.setState({
            questionModule: e.target[e.target.selectedIndex].id
        })
    }

    saveAnswer = (e) => {

        const{ getAnswerContent, getAnswerStatus } = this.state;

        let answerId = shortid.generate();

        this.setState({
            answers: [ 
                ...this.state.answers,
                {
                id: answerId,
                answerContent: getAnswerContent, 
                answerStatus: getAnswerStatus === "true" ? true : getAnswerStatus === "false" ? false : null,
                },
            ],
        });
        this.refs.answerContent.value = '';
        this.refs.answerStatus.value = 'Status';
    }

    removeAnswer = (id) => {
        let removedAnswer = this.state.answers.filter(ans => {
            return ans.id !== id
        });

        this.setState({
            answers: removedAnswer,
        })
    }

    updateQuestion = (e) => {
        var result = window.confirm("Confirm update?");
    
        if (result) {
            this.setState({
                isLoading: true
            })
            this.props.updateQuestion(this.state, this.props.questionId);
        }
    }

    render(){

        const { modules, courses} = this.props;
        const { message, isLoading } = this.state;

        let defaultCourse =  courses && courses.map(course => {
            if(this.state.questionCourse === course.id) {
                return course.courseName
            } else return null
        })

        let defaultModule =  modules && modules.map(module => {
            if(this.state.questionModule === module.id) {
                return module.moduleName
            } else return null
        })

        let questionCourseList = courses && courses.map((course,i) => {
            if(course.courseType === "Professional") {
                return (
                    <option key={i} id={course.id}>{course.courseName}</option>
                )
            } else return null
        })

        let questionModuleList = courses && courses.map(course => {
            if(course.id === this.state.questionCourse) {
                return(
                    course.selectedModules && course.selectedModules.map(selected => {
                        return (
                            modules && modules.map((module,i) => {
                                if(selected === module.id) {
                                    return (
                                        <option key={i} id={module.id}>{module.moduleName}</option>
                                    )
                                }
                                return null
                            })
                        )
                    })
                )
            }
            return null
        })

        return(

            <div>
                <b className="uppercase middle">edit question
                    <div className="title-border"></div>
                </b>

                <p>
                    <b>Question Code: </b>
                    <input onChange={this.handleChange} defaultValue={this.state.questionCode} id="questionCode" type="text" className="col-md-4 form-control" placeholder="Code"/>
                </p>

                <div className="row">
                    <p className="col col-md-6">
                        <b>Course: </b>
                        <select onChange={this.selectedEditCourse.bind(this)} id="questionCourse" className="form-control">
                            <option defaultValue>{defaultCourse}</option>
                            {questionCourseList}
                        </select>
                    </p>

                    <p className="col col-md-6">
                        <b>Module: </b>
                        <select onChange={this.selectedEditModule.bind(this)} id="questionModule" className="form-control">
                            <option defaultValue>{defaultModule}</option>
                            {questionModuleList}
                        </select>
                    </p>
               </div>

               <div className="row">
                    <p className="col col-md-6">
                        <b>Mark: </b>
                        <input onChange={this.handleChange} id="questionMark" type="number" className="form-control" defaultValue={this.state.questionMark} placeholder="Mark"/>
                    </p>
                </div>

                <p>
                    <b>Question Content 1: </b>
                    <textarea onChange={this.handleChange} defaultValue={this.state.questionContent1} placeholder="Content 1" id="questionContent1" type="text" className="form-control" />
                </p>

                <p>
                    <b>Question Content 2: </b>
                    <textarea onChange={this.handleChange} defaultValue={this.state.questionContent2}  placeholder="Content 2" id="questionContent2" type="text" className="form-control" />
                </p>


                {/* ---------------------------------- ANSWER PART ----------------------------------  */}
                <div className="mt-4">
                    <b>Answers:</b>

                    <textarea type="text" className="form-control" ref="answerContent" value={this.state.answerContent} onChange={event => this.setState({getAnswerContent: event.target.value})}  placeholder="Answer"/>

                    <select className="col-md-4 form-control" ref="answerStatus" value={this.state.answerStatus} onChange={event => this.setState({getAnswerStatus: event.target.value})}>
                        <option defaultValue>Status</option>
                        <option>true</option>
                        <option>false</option> 
                    </select>

                    <span className="badge bg-blue text-white" onClick={this.saveAnswer.bind(this)}>Save and Add New</span>

                    <br/><br/>
                    
                    <b>Answer preview:</b>

                    { this.state.answers && this.state.answers.map((ans,i) => {
                        return (
                            // <li style={{margin:'20px'}}>
                            //     {ans.answerContent} / <i className={ans.answerStatus === false ? "text-red" : "text-green"}>{ans.answerStatus.toString()}</i> {' '}
                            //     <span onClick={this.removeAnswer.bind(this, ans.id)}> <i className="fas fa-trash text-red" /></span>
                            // </li>
                            <div style={{marginLeft:"30px"}}  key={i} className="mb-1 listing"> 
                                <div className="list-icon"><span onClick={this.removeAnswer.bind(this, ans.id)}> <i className="fas fa-trash text-red" /></span></div>
                                <span>
                                    { ans.answerContent} <b className={ans.answerStatus === false ? "text-red" : "text-green"}>{ans.answerStatus.toString()}</b>
                                </span>
                            </div>
                        )
                    })}
                </div>

                { isLoading === true ? 
                    <small>Loading...</small>
                : 
                    message  ?
                        <small className={message.type === 'updateQuestionError' ? "text-red" : message.type === 'updateQuestionSuccess'  ? "text-green" : null }>
                            {message.msg}
                        </small>
                    : null
                }

                <div className="mt-5 row justify-content-end">
                    <div><button onClick={this.updateQuestion.bind(this)} className="btn m-2 btn-outline-black"><i className="fas fa-check-circle"/>{' '}update</button></div>
                    <div><button onClick={this.props.cancel.bind(this)} className="btn m-2 btn-outline-black"><i className="fas fa-times"/>{' '}cancel</button></div>
                </div>
            </div>
    
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        updateQuestionError: state.questions.updateQuestionError,
        updateQuestionSuccess: state.questions.updateQuestionSuccess,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateQuestion: (updatedQuestion, questionId) => { dispatch(updateQuestion(updatedQuestion, questionId))}
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(EditQuestion);