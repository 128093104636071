export const addNewPurchase = (data) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => { 

        const firestore = getFirestore();

        firestore.collection('purchases').add({
            paymentType: data.paymentType,
            enrolledCourseId: data.enrolledCourseId,

            userId: data.userId,
            userName: data.userName,
            userEmail: data.userEmail,
            userPhone: data.userPhone,
            courseId: data.courseId,
            totalPrice: data.totalPrice,

            enrollmentCourseName: data.enrollmentCourseName,
            enrollmentCourseId: data.enrollmentCourseId,
            enrollmentModuleId: data.enrollmentModuleId,
            enrollmentType: data.enrollmentType,

            billCode: '',
            paymentStatus: "",
            orderId:"",
            transactionId:"",

        }).then(docRef => {
            window.location.href =`https://us-central1-halal-e-learning.cloudfunctions.net/newPurchase-newPurchase?paymentType=${data.paymentType}&courseid=${data.courseId}&id=${data.userId}&purchaseid=${docRef.id}&paxno=${data.emp_pax_no}`;
        }).then(() => {
            dispatch({ type:'ADD_NEW_PURCHASE', data: data})
        }).catch((err) => {
            dispatch({ type:'ADD_NEW_PURCHASE_ERROR', err}); 
        })

    }
}

export const updatePurchaseStatus = (data, purchaseId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {

        const firestore = getFirestore();

        firestore.collection('purchases').doc(purchaseId).update({

            paymentStatus: data.paymentStatus,
            orderId: data.orderId,
            transactionId: data.transactionId,

        }).then(() => {
            dispatch({ type:'UPDATE_PURCHASE_AFTER_PAYMENT', purchaseId: purchaseId});
        }).catch((err) => {
            dispatch({ type:'UPDATE_PURCHASE_AFTER_PAYMENT_ERROR', err});
        })

    }
}