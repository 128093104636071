import React, { Component } from 'react';
import { connect } from 'react-redux'
import { storage } from '../../../config/fbConfig'

import { updateModule } from '../../../stores/actions/courseAction';

class EditModule extends Component{

    constructor(props){
        super(props)
        this.state = {
            moduleName: this.props.module.moduleName,
            moduleCode: this.props.module.moduleCode,
            selectedTopics: this.props.module.selectedTopics,
    
            practicalAssessment: this.props.module.practicalAssessment,
            quiz: this.props.module.quiz,
    
            // selectedPracticalAssessment: this.props.module.selectedPracticalAssessment,
            // selectedQuizSet: this.props.module.selectedQuizSet,
            
            hasQuiz: this.props.module.quiz.hasQuiz,
            quizTimer: this.props.module.quiz.quizTimer,
            quizMark: this.props.module.quiz.quizMark,
            quizSuspendDay: this.props.module.quiz.quizSuspendDay,
            quizSuspendNumber: this.props.module.quiz.quizSuspendNumber,
            selectedQuizSet: this.props.module.quiz.selectedQuizSet,
            questionCount: this.props.module.quiz.questionCount,
    
            hasPracticalAssessment: this.props.module.practicalAssessment.hasPracticalAssessment,
    
            newNoteFile: null,
            progress:'',
    
            searchTopic:'',
            selectedTopicCode:'',
    
            isLoading: null,
            message: null,
        }
        this.baseState = this.state 
    }
    
    componentDidUpdate = (prevProps, prevState) => { 

        const { updateModuleError, updateModuleSuccess } = this.props;

        if(prevProps.updateModuleError !== updateModuleError) {
            this.setState({
                isLoading: false,
                message: {
                    msg: updateModuleError.message,
                    type: updateModuleError.type,
                }
            })
        }

        if(prevProps.updateModuleSuccess !== updateModuleSuccess) {
            this.setState({
                isLoading: false,
                message: {
                    msg: updateModuleSuccess.message,
                    type: updateModuleSuccess.type,
                }
            })
            setTimeout(() => { 
                //this.setState(this.baseState)
                this.props.cancel();
            }, 2000);
        }
    } 


    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.valueAsNumber || e.target.value
        })
    }

    updateState = async () => {
        this.setState({
            quiz:{
                hasQuiz: this.state.hasQuiz,
                selectedQuizSet: this.state.selectedQuizSet,
                quizTimer: this.state.quizTimer,
                quizMark: this.state.quizMark,
                quizSuspendDay: this.state.quizSuspendDay,
                quizSuspendNumber: this.state.quizSuspendNumber,
                questionCount: this.state.questionCount,
            },
            practicalAssessment: {
                hasPracticalAssessment: this.state.hasPracticalAssessment,
                fileUrl: this.state.practicalAssessment.fileUrl,
                fileName: this.state.practicalAssessment.fileName
            }
        })
        return;
    }

    newNoteFile = (e) => {
        if(e.target.files[0]) {
            this.setState({
                newNoteFile:  e.target.files[0]
            });
        }
    }

    updateModule = async (e) => {

        const { newNoteFile } = this.state;

        await this.updateState();

        var result = window.confirm("Confirm update?");
    
        if (result) {
            this.setState({
                isLoading: true
            })
            if (newNoteFile !== null) {
                const uploadTask = storage.ref(`documents/${newNoteFile.name}`).put(newNoteFile);
        
                    uploadTask.on('state_changed', 
                        (snapshot) => {
                            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                            this.setState({
                                progress});
                        }, 
        
                        (error) => {
                            console.log(error);
                        }, 
        
                        () => {
                            storage.ref('documents').child(newNoteFile.name).getDownloadURL().then(fileUrl => {
                                this.setState({
                                    practicalAssessment: {
                                        hasPracticalAssessment: this.state.hasPracticalAssessment,
                                        fileUrl: fileUrl,
                                        fileName: newNoteFile.name
                                    }
                                });
                                this.props.updateModule(this.state, this.props.module_id)
                            })
                        }
                    );
    
            } else {
                this.props.updateModule(this.state, this.props.module_id)
            }
        }
        
    }

    handleSelectedTopic(id, code) {
        this.setState({
            selectedTopics: [ 
                ...this.state.selectedTopics, 
                id
            ],
            selectedTopicCode: code,
        });
    }

    removeSelectedTopic = (id) => {
        let removeSelectedTopic = this.state.selectedTopics.filter(selected => {
          return selected !== id
        })
    
        this.setState({
            selectedTopics: removeSelectedTopic
        })
    }

    handleSelectedQuizSet(e) {
        this.setState({
            selectedQuizSet:  e.target[e.target.selectedIndex].id //get selected option ID
        });
    }

    displayModalAssessmentFile_Edit = () => { //display current file in popup
        document.getElementById("display_assessment_file_edit").style.display = "block";
    }

    hideModalAssessmentFile_Edit = () => { //hide current file in popup
        document.getElementById("display_assessment_file_edit").style.display = "none";
    }



    render(){

        const { quizzes, topics, modules, module_id } = this.props;
        const { message, isLoading } = this.state;

        let filteredTopics = null;

        if(this.state.searchTopic.length > 0) { //filter user: search user by email
            filteredTopics = topics && topics.filter(topic => {
                return topic.topicCode.toLowerCase().includes(this.state.searchTopic.toLowerCase()) || 
                topic.topicName.toLowerCase().includes(this.state.searchTopic.toLowerCase())
            })
        } else {
            filteredTopics = topics;
        }
        
        return(

            <div>      

                <div id="display_assessment_file_edit" className="mymodal">
                    <div className="mymodal-content col-md-6">
                        {modules && modules.map((module,i) => {
                            if (module.id === module_id && this.state.practicalAssessment !== null) {
                                return (
                                    <div key={i}>
                                        <span className="close" onClick={this.hideModalAssessmentFile_Edit}>&times;</span>
                                        <h3 className="middle">{this.state.practicalAssessment.fileName}
                                            <div className="row justify-content-center"><div className="title-border"></div></div>
                                        </h3>
                                        
                                        <object aria-label="display-pdf" className="display-pdf" data={this.state.practicalAssessment.fileUrl}></object>
                                    </div>
                                )
                            } else {}
                            return null
                        })}
                    </div>
                </div>

                <div className="row">
                    <p className="col col-md-3">
                        <b>Code:</b>
                        <input type="text" id="moduleCode" onChange={this.handleChange} defaultValue={this.state.moduleCode} className="form-control"/>
                    </p>
                    <p className="col">
                        <b>Name:</b>
                        <input type="text" id="moduleName" onChange={this.handleChange} defaultValue={this.state.moduleName} className="form-control"/>
                    </p>
                </div>

                <br/>
                
                <div>
                    <b>Topics: </b>
                    <div type="text" className="border-black">
                        { topics && topics.map((topic,i) => {
                            return (
                                this.state.selectedTopics && this.state.selectedTopics.map((selected, i) => { 
                                    if(topic.id === selected) {
                                        return (
                                            <span key={i} className="badge bg-green text-white">
                                                {topic.topicCode} {' '}
                                                    
                                                <span onClick={this.removeSelectedTopic.bind(this, selected)}>
                                                    <i className="fas fa-times"></i>
                                                </span>
                                            </span>
                                        )
                                    }
                                    return null
                                })
                            )
                        })}
                    </div>
                    
                    <small>Choose topics here:</small>

                    <br/>

                    <div className="row col-md-5 btn-group dropdown">
                        <button type="button" className="btn border-black dropdown-toggle" data-toggle="dropdown">
                            { this.state.selectedTopicCode ? this.state.selectedTopicCode : 'Choose Topic'}
                        </button>

                        <div className="dropdown-menu" >
                            <span className="dropdown-item" onClick={this.handleSelectedTopic.bind(this, '', '')} disabled>TOPIC</span>
                            <span className="dropdown-item"><input placeholder="Search code..." className="form-control"  onChange={event => this.setState({searchTopic: event.target.value})}/></span>
                            { filteredTopics && filteredTopics.map((topic,i) => {
                                return (
                                    <span key={i} className="dropdown-item" onClick={this.handleSelectedTopic.bind(this, topic.id, topic.topicCode)}>{topic.topicCode} - {topic.topicName}</span>
                                )
                            })}
                        </div>
                    </div>
                </div>

                 <br/>

                <div>
                    <b>Quiz:</b>
                    <select className="form-control" onChange={event => this.setState({hasQuiz: event.target.value})}>
                        <option defaultValue>{this.state.hasQuiz ? this.state.hasQuiz : 'Select Quiz Available'}</option>
                        <option>true</option>
                        <option>false</option>
                    </select>

                    { this.state.hasQuiz === "true" ?
                        <div>
                            <select className="form-control" onChange={this.handleSelectedQuizSet.bind(this)}>
                                
                                { this.state.selectedQuizSet.length > 0 ?
                                    quizzes && quizzes.map(quiz => {
                                        if (quiz.id === this.state.selectedQuizSet) {
                                            return (
                                                <option key={quiz.id} defaultValue>{quiz.quizCode} - {quiz.quizName}</option>
                                            )
                                        }
                                        return null
                                    })
                                :
                                    <option defaultValue>Select Quiz's Set</option>
                                }

                                { quizzes && quizzes.map(quiz => { 
                                    return (
                                        <option key={quiz.id} id={quiz.id}>{quiz.quizCode} - {quiz.quizName}</option>
                                    )
                                })}
                            </select>
                            <br/>
                            <small>QUIZ SETING</small>
                            <br/>
                            <div className="row">    
                                <p className="col col-md-4">
                                    <small style={{fontWeight:'800'}}>Timer (seconds):</small>
                                    <input type="number" id="quizTimer"  onChange={this.handleChange} defaultValue={this.state.quiz.quizTimer} className="form-control" placeholder="Timer"/>
                                </p>    
            
                                <p className="col col-md-4">
                                    <small style={{fontWeight:'800'}}>Q.Count:</small>
                                    <input type="number" id="questionCount"  onChange={this.handleChange} defaultValue={this.state.quiz.questionCount} className="form-control"  placeholder="Question Count"/>
                                </p>

                                <p className="col col-md-4">
                                    <small style={{fontWeight:'800'}}>Pass Rate (%):</small>
                                    <input type="number" id="quizMark"  onChange={this.handleChange} defaultValue={this.state.quiz.quizMark} className="form-control"  placeholder="Mark"/>
                                </p>
                            </div>

                            <div className="row">    
                                <p className="col col-md-4">
                                    <small style={{fontWeight:'800'}}>S.COUNT:</small>
                                    <input type="number" id="quizSuspendNumber"  onChange={this.handleChange} defaultValue={this.state.quiz.quizSuspendNumber} className="form-control"  placeholder="Suspend Count"/>
                                </p>
            
                                <p className="col col-md-4">
                                    <small style={{fontWeight:'800'}}>S.Day (days):</small>
                                    <input type="number" id="quizSuspendDay"  onChange={this.handleChange} defaultValue={this.state.quiz.quizSuspendDay} className="form-control"  placeholder="Suspend Day"/>
                                </p>
                            </div>
                        </div>
                    : null }
                </div>

                <br/>

                <div className=" mb-5">
                    <b>Practical Assessment:</b>
                    <select className="form-control" onChange={event => this.setState({hasPracticalAssessment: event.target.value})}>
                        <option defaultValue>{this.state.hasPracticalAssessment ? this.state.hasPracticalAssessment : 'Select PA Available'}</option>
                        <option>true</option>
                        <option>false</option>
                    </select>
                    <br />
                    { this.state.hasPracticalAssessment === "true" ?
                        <div>
                            <small style={{fontWeight:'800'}}>SELECTED PRACTICAL ASSESSMENT:</small><br />
                            {modules && modules.map((module,i) => {
                                if (module.id === module_id && this.state.practicalAssessment !== null) {
                                    return (
                                        <span key={i} onClick={this.displayModalAssessmentFile_Edit.bind(this)}>{this.state.practicalAssessment.fileName}</span>
                                    )
                                } else if (module.id === module_id && this.state.practicalAssessment === null) {
                                    return (
                                        <em>No selected assessment.</em>
                                    )
                                }
                                return null
                            })} 
                            
                            <br/>
            
                            <input className="mt-2" type="file" onChange={this.newNoteFile.bind(this)} /><br/>
                            <progress value={this.state.progress} max="100" style={{width:"250px"}}/>
                        </div>
                    : null }
                </div>

                { isLoading === true ? 
                    <small>Loading...</small>
                : 
                    message  ?
                        <small className={message.type === 'updateModuleError' ? "text-red" : message.type === 'updateModuleSuccess'  ? "text-green" : null }>
                            {message.msg}
                        </small>
                    : null
                }

                <div className="mt-5 row justify-content-end">
                    <button type="button" className="btn m-2 btn-outline-black" onClick={this.updateModule.bind(this)}><i className="fas fa-check-circle"/>{' '}update</button>
                    <button type="button" className="btn m-2 btn-outline-black" onClick={this.props.cancel.bind(this)}><i className="fas fa-times"/>{' '}cancel</button>
                </div>
            </div>

        )
    }
}

const mapStateToProps = (state) => {
  return {
      updateModuleError: state.courses.updateModuleError,
      updateModuleSuccess: state.courses.updateModuleSuccess,
  }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateModule: (updatedModule, module_id) => {dispatch(updateModule(updatedModule, module_id))}
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(EditModule);