import React, { Component } from 'react';
import { connect } from 'react-redux'

import { updateUserTranscript } from '../../../../stores/actions/userAction';
import { createTranscript, updateTranscript } from '../../../../stores/actions/classAction';

class CreateTranscript extends Component {

    constructor(props){
        super(props)
        this.state = {
            userId:'',
            batchId:'',
            courseId:'',
            tsId:'',

            fname:'',
            lname:'',
            ic:'',
            regno:'',
            pa1:'',
            pa2:'',
            pa3:'',
            attendance:'',
            status:'',

            oldStatus:'',

            isLoading:null,
            message:null
        }
        this.baseState = this.state 
    }

    UNSAFE_componentWillReceiveProps(props){
        this.setState({ 
            batchId: props.batchId ? props.batchId : '',
            courseId: props.courseId ? props.courseId : '', 
            userId: props.ts_passData ? props.ts_passData.userId : '', 
            tsId: props.tsId ? props.tsId : '', 

            fname: props.userData ? props.userData.firstname : '',
            lname: props.userData ? props.userData.lastname : '',
            ic: props.userData ? props.userData.identityCardNo : '',

            regno: props.tsData ? props.tsData.regno : '',
            pa1: props.tsData ? props.tsData.pa.pa1 : '',
            pa2: props.tsData ? props.tsData.pa.pa2 : '',
            pa3: props.tsData ? props.tsData.pa.pa3 : '',
            attendance: props.tsData ? props.tsData.attendance : '',
            oldStatus: props.tsData ? props.tsData.status : '',
        })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    componentDidUpdate = (prevProps, prevState) => { 
        const { createTranscriptError, createTranscriptSuccess, updateTranscriptError, updateTranscriptSuccess } = this.props;

        if(prevProps.createTranscriptError !== createTranscriptError) {
            this.setState({
                isLoading: false,
                message: {
                    msg: createTranscriptError.message,
                    type: createTranscriptError.type,
                }
            })
        }

        if(prevProps.createTranscriptSuccess !== createTranscriptSuccess) {
            this.setState({
                isLoading: false,
                message: {
                    msg: createTranscriptSuccess.message,
                    type: createTranscriptSuccess.type,
                }
            })
            setTimeout(() => { 
                this.props.hideTs();
                this.setState(this.baseState)
            }, 2000);
        }

        if(prevProps.updateTranscriptError !== updateTranscriptError) {
            this.setState({
                isLoading: false,
                message: {
                    msg: updateTranscriptError.message,
                    type: updateTranscriptError.type,
                }
            })
        }

        if(prevProps.updateTranscriptSuccess !== updateTranscriptSuccess) {
            this.setState({
                isLoading: false,
                message: {
                    msg: updateTranscriptSuccess.message,
                    type: updateTranscriptSuccess.type,
                }
            })
            setTimeout(() => { 
                this.props.hideTs();
                this.setState({
                    message:'',
                    isLoading:''
                })
            }, 2000);
        }
    }

    updateData = async (userId, status) => {
        this.props.updateUserTranscript(this.state, userId);
        this.setState({ status })
    }

    createTranscript = async (actionType, status) => {

        await this.updateData(this.state.userId, status);

        var result = window.confirm(`Confirm ${status}?`);

        if (result) {
            this.setState({
                isLoading: true
            })

            if(actionType === "new") {
                this.props.createTranscript(this.state);
            } else if (actionType === "edit") {
                this.props.updateTranscript(this.state, this.state.tsId);
            }
        }
    }

    render () {

        const { ts_passData } = this.props;
        const { isLoading, message } = this.state;

        return (

            <div className='text-left'>

                <b className="uppercase middle">
                    {ts_passData.modalType === "new" ? "Create" : "Edit"} Transcript
                    <div className="title-border"></div>
                </b>

                <div className="row">
                    <p className="col">
                        <b>Firstname</b>
                        <input id="fname" placeholder='Firstname' value={this.state.fname} onChange={this.handleChange} type="text" className="form-control" />
                    </p>
                    <p className="col">
                        <b>Lastname</b>
                        <input id="lname" placeholder='Lastname' value={this.state.lname} onChange={this.handleChange} type="text" className="form-control" />
                    </p>
                </div>

                <p>
                    <b>IC Number:</b>
                    <input id="ic" placeholder='IC number' value={this.state.ic} onChange={this.handleChange} type="text" className="form-control" />
                </p>

                <p>
                    <b>Registration Number:</b>
                    <input id="regno" placeholder='Registration number' value={this.state.regno} onChange={this.handleChange} type="text" className="form-control" />
                </p>
                
                <b>Performance Assessment:</b>
                <div className="row">
                    <p className="col">
                        C01, C02, C03:
                        <input id="pa1" placeholder='%' value={this.state.pa1} onChange={this.handleChange} type="text" className="form-control" />
                    </p>
                    <p className="col">
                        C04:
                        <input id="pa2" placeholder='%' value={this.state.pa2} onChange={this.handleChange} type="text" className="form-control" />
                    </p>
                    <p className="col">
                        C05:
                        <input id="pa3" placeholder='%' value={this.state.pa3} onChange={this.handleChange} type="text" className="form-control" />
                    </p>
                </div>

                <p>
                    <b>Attendance Score:</b>
                    <input id="attendance" placeholder='Attendance score' value={this.state.attendance} onChange={this.handleChange} type="text" className="form-control" />
                </p>

                <p className='mt-2'>
                    { isLoading === true ? 
                        <small>Loading...</small>
                    : 
                        message  ?
                            <small className={ message.type === 'createTranscriptError' || message.type === 'updateTranscriptError' ? "text-red" : "text-green"}>
                                {message.msg}
                            </small>
                        : null
                    }
                </p>

                { ts_passData.modalType === "new" ?
                    <div className='mt-5 float-right'>
                        <button onClick={this.createTranscript.bind(this, 'new', 'save')} className='btn btn-outline-black'>Save{' '}<i className="fas fa-check-circle" /></button>
                        <button onClick={this.createTranscript.bind(this, 'new', 'publish')} className='btn btn-outline-black'>Publish{' '}<i className="fas fa-arrow-circle-right" /></button>
                    </div>
                : ts_passData.modalType === "edit" ? 
                    //enable edit after publish, & not update transcript status
                    this.state.oldStatus === "publish" ?
                        <div className='mt-5 float-right'>
                            <button onClick={this.createTranscript.bind(this, 'edit', 'publish')} className='btn btn-outline-black'>Update{' '}<i className="fas fa-check-circle" /></button>
                        </div>
                    :
                        <div className='mt-5 float-right'>
                            <button onClick={this.createTranscript.bind(this, 'edit', 'save')} className='btn btn-outline-black'>Save{' '}<i className="fas fa-check-circle" /></button>
                            <button onClick={this.createTranscript.bind(this, 'edit', 'publish')} className='btn btn-outline-black'>Publish{' '}<i className="fas fa-arrow-circle-right" /></button>
                        </div>
                : null }

            </div>

        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const tsId = ownProps.ts_passData ? ownProps.ts_passData.tsId : null;
    const userId = ownProps.ts_passData ? ownProps.ts_passData.userId : null;

    const users = state.firestore.data.users;
    const userData = users ? users[userId] : null;

    const transcripts = state.firestore.data.transcripts;
    const tsData = transcripts ? transcripts[tsId] : null;

    return {
        tsId,
        tsData,
        userData,

        createTranscriptError: state.classes.createTranscriptError,
        createTranscriptSuccess: state.classes.createTranscriptSuccess,

        updateTranscriptError: state.classes.updateTranscriptError,
        updateTranscriptSuccess: state.classes.updateTranscriptSuccess,
    }
}
  
const mapDispatchToProps = (dispatch) => {
    return {
      createTranscript: (ts) => dispatch(createTranscript(ts)),
      updateUserTranscript: (data, userId) => dispatch(updateUserTranscript(data, userId)),
      updateTranscript: (ts, tsId) => dispatch(updateTranscript(ts, tsId)),
    }
}

export default  connect(mapStateToProps, mapDispatchToProps)(CreateTranscript);
