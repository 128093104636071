import React, { Component } from 'react';

class Career extends Component{

    render(){

        return(
            
        <div className="career min-height">

            <div className="middle page-header">
                {/* <div className="col col-md-4" style={{marginBottom:"30px"}}> 
                    <img src="../../../image/HA_LOGO.png" alt="" width="45%"/><br />
                </div> */}
                 
                 <div className='col-md-8'>
                    <h1>career
                        <div className="bg-white title-border"></div>
                    </h1>
                </div>  
            </div>

            <div className="page-content middle">
                <div className="col-md-8">
                    In collaboration with public universities and other government agencies, Halal Academy has been conducting various Halal 
                    Talent Development Program.
                    <br/><br/>
                    Halal Talent Development Program (HTDP) is a diverse customized program to develop new talent in Halal industry.
                    <br/><br/>
                    School leavers and graduates require preparation and grooming of Halal and other related knowledge in order to increase 
                    their marketability and employability. Therefore, we created HTDP to produce more talent pools that are competent with 
                    required halal industry knowledge and skills.

                    <br/><br/><br/>

                    <label>Halal Talent Development Program</label>
                    <ul>
                        <li>Halal Crew </li>
                        <li>Kitchen Operator</li>
                        <li>Be a certified Halal Executive</li>
                    </ul>

                    <br/>

                    <label>Career pathway program</label>
                    <ul>
                        <li>FB Live</li>
                        <li>Mini career matching event</li>
                    </ul>

                    <br/>

                    <label>The Halal Talent Directory portal</label>
                    <br/>
                    <p>
                        Halal Talent Directory is a portal initiated and developed by Halal Academy to assist and become the bridge between halal 
                        industry talent and the industry players. It is FREE to everyone. 
                        <br/><br/>
                        All candidates will be verified as to ensure that the candidate is suitable and has passion in venturing into the halal 
                        industry. 
                        <br/><br/>
                        Companies who are seeking candidates to be part of your team members, your participation is highly encouraged and 
                        appreciated. Get the access for FREE.
                    </p>

                    <a href="https://www.halaltalentdirectory.com/" target="_blank" rel="noopener noreferrer">Go to Halal Talent Directory portal {' '} <i className="fas fa-arrow-circle-right"/></a>
                </div>
            </div>

        </div>
        
        )
    }
}

export default Career;