import React, { Component } from 'react';
import '../App.css';
import { connect } from 'react-redux';
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'

import Carousel, { consts } from 'react-elastic-carousel';
import { Link } from 'react-router-dom';

class Main2 extends Component{

    state = {
        programId: '',
        showMore: false,
        imageLoaded: false
    }

    myArrow = ({ type, onClick, isEdge }) => {
        const pointer = type === consts.PREV ? <i className="fas fa-arrow-circle-left"></i> : <i className="fas fa-arrow-circle-right"></i>
        return (
          <span className="middle carousel-arrow"onClick={onClick} disabled={isEdge}>
            {pointer}
          </span>
        )
    }

    showMore = (id) => {
        this.setState({
            programId:id,
            showMore: true
        })
    }
    
    handleImageLoaded() {
        this.setState({ imageLoaded: true });
    }

    render(){

        const { programs } = this.props;
        let isLoading = true, programList = null, programLength = [];

        const breakPoints = [
            { width: 1, itemsToShow: 1 },
            { width: 550, itemsToShow: 2, itemsToScroll: 2 },
            { width: 768, itemsToShow: 3 },
        ];

        programList = programs && programs.map((program,i) => {
            if(program.programStatus === "Active") {
                isLoading = false;
                programLength.push(program)
                
                var str = program.programContent; 
                var res = str.substr(4, 110);

                return (
                    <div key={i} className="border-0 programs-card">
                        {/* <div className="middle carousel-img-holder"> */}
                            { program.programImage && program.programImage.fileUrl ? 
                                    <div className="middle" style={{width:"100%"}}>
                                        <img width="100%" onLoad={this.handleImageLoaded.bind(this)} src={program.programImage.fileUrl} alt="logo" /> 
                                    
                                        { this.state.imageLoaded === false ?
                                            <div className="loader"></div>
                                        : null }
                                    </div>
                            :
                                <div className="middle" style={{width:"100%"}}>
                                    <img className="text-center" width="100%" src="/image/default.png" alt="logo"/> 
                                </div>
                            }
                        {/* </div> */}

                       <div style={{padding:'20px'}}>
                            <p><b>{program.programName}</b></p>

                            <div className="text-left">
                                { this.state.programId === program.id ? 
                                
                                    <p  dangerouslySetInnerHTML={{__html: program.programContent}}></p>
                                : 
                                    <p id="contentCut" style={{display:"block"}}>{res}... <span onClick={this.showMore.bind(this, program.id)}>{' '}<i>(more)</i></span></p> 
                                }
                            </div>
                       </div>

                       <div className="bg-green programs-btn">
                            <Link to={"/program/" + program.id}>View Courses{' '}<i className="fas fa-arrow-circle-right"></i></Link>
                       </div>

                    </div>
                )
            }
            return null
        })

        return(

        <div className="p-0 m-0 main container col-md-12 justify-content-center middle min-height">

            <img src="/image/FRONTPAGE_BANNER.png" width="100%" alt="banner" />

            <h5 className="mt-5 mb-5" style={{margin:"0px 20px 0px 20px",textTransform:"none"}}>Explore our portal to find your perfect program</h5>

            <div className={ programLength.length < 2 ? 'col-md-5' : programLength.length < 3 ? 'col-md-8' : 'col-md-11' }>
                <Carousel breakPoints={breakPoints} renderArrow={this.myArrow.bind(this)}>
                    { isLoading === true ? <div className="loader"></div> : programList }
                </Carousel>
            </div>

            <h5 className="mt-5" style={{margin:"0px 20px 0px 20px",textTransform:"none"}}>We are registered training provider</h5>
            
            <div className="mt-3 mb-5 row col-md-12 justify-content-center">
                <img src="/image/hpb.png" width="9%" alt="one" />
                <img src="/image/miha.png" width="9%" alt="two" />
                <img src="/image/hrdc2.png" width="6%" alt="three" />
            </div>
        </div>
        
        )
    }
}

const mapStateToProps = (state) => {
    return {
        programs: state.firestore.ordered.programs,
    }
}
  
export default compose(
    connect(mapStateToProps),
    firestoreConnect([
        { collection: 'programs'},
    ])
)(Main2);