import React, { Component } from 'react';
import shortid from 'shortid';
import { connect } from 'react-redux'
import { storage } from '../../../config/fbConfig'

import { updateTopic } from '../../../stores/actions/courseAction';

class EditTopic extends Component{

    constructor(props){
        super(props)
        this.state = {
            topicName: this.props.topic.topicName,
            topicCode: this.props.topic.topicCode,
            subtopics: this.props.topic.subtopics,
            selectedNote: this.props.topic.selectedNote,
            selectedVideo: this.props.topic.selectedVideo,
    
            getSubtopic: '',
    
            oldVideoHide: false,
            newVideoHide: true,
    
            noteNew: null,
            videoNew: null,
    
            progressNote: '',
            progressVideo: '',
            videoDisplay: null,

            videoName: '',
            videoUrl: '',
            videoType: '',
    
            isLoading: null,
            message: null,
        }
        this.baseState = this.state 
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }
    
    componentDidUpdate = (prevProps, prevState) => {

        const { updateTopicError, updateTopicSuccess } = this.props;

        if(prevProps.updateTopicError !== updateTopicError) {
            this.setState({
                isLoading: false,
                message: {
                    msg: updateTopicError.message,
                    type: updateTopicError.type,
                }
            })
        }

        if(prevProps.updateTopicSuccess !== updateTopicSuccess) {
            this.setState({
                isLoading: false,
                message: {
                    msg: updateTopicSuccess.message,
                    type: updateTopicSuccess.type,
                }
            })
            setTimeout(() => { 
                this.props.cancel();
            }, 2000);
        }
    } 

    updateTopic = async () => {

        await this.updateUpdatedVideo();

        var result = window.confirm("Confirm update?");
    
        if (result) {
            this.setState({
                isLoading: true
            })
            this.props.updateTopic(this.state, this.props.topic_id)
        }
    }

    addSubtopic = (e) => {
        let id = shortid.generate();

        this.setState({
            subtopics: [ 
                ...this.state.subtopics,
                {
                id: id,
                subtopic: this.state.getSubtopic, 
                },
            ],
        });
        this.refs.subtopic.value = '';
    }

    removeSubtopic = (id) => {
        let removedSubtopic = this.state.subtopics.filter(subtopic => {
            return subtopic.id !== id
        });
        this.setState({
            subtopics: removedSubtopic,
        })
    }
    
    handleUpdateNote = (e) => {
        if(e.target.files[0]) {
            this.setState({
                noteNew: e.target.files[0],
                progressNote: ''
            });
        }
    }

    handleUpdateVideo = (e) => {
        if(e.target.files[0]) {
            this.setState({
                videoNew: e.target.files[0],
                videoDisplay: URL.createObjectURL(e.target.files[0]), //ubah imgUrl to pic
                progressVideo: '',
                
                oldVideoHide: true,
                newVideoHide: false
            });
        }
    }

    executeUpdateNote = () => {

        const { noteNew } = this.state;

        if (this.state.noteNew !== null ) {
            const uploadNoteNew = storage.ref(`documents/${noteNew.name}`).put(noteNew);

            uploadNoteNew.on('state_changed', 
                (snapshot) => {
                    const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    this.setState({
                        progressNote: progress
                    });
                }, 

                (error) => {
                    console.log(error);
                }, 

                () => {
                    storage.ref('documents').child(noteNew.name).getDownloadURL().then(fileUrl => {
                    
                        this.setState({
                            selectedNote: {
                                fileUrl: fileUrl,
                                fileName: noteNew.name
                            }
                        });
                    })
                }
            );
        } 
    }

    updateUpdatedVideo = async () => {
        let { videoType, videoName, videoUrl } = this.state;

        if(videoName === '' & videoUrl === '') {
            this.setState({
                selectedVideo: this.state.selectedVideo
            })
        } else {
            this.setState({
                selectedVideo: {
                    videoType,
                    fileName: videoType === "Youtube" ? "Youtube link" : videoName,
                    fileUrl: videoUrl,
                }
            })
        }
    }

    executeUpdateVideo = () => {

        const { videoNew } = this.state;

        if (this.state.videoNew !== null) {
            const uploadVideoNew = storage.ref(`videos/${videoNew.name}`).put(videoNew);

            uploadVideoNew.on('state_changed', 
                (snapshot) => {
                    const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    this.setState({
                        progressVideo: progress
                    });
                }, 

                (error) => {
                    console.log(error);
                }, 

                () => {
                    storage.ref('videos').child(videoNew.name).getDownloadURL().then(fileUrl => {
                        this.setState({  
                            videoName: videoNew.name,
                            videoUrl: fileUrl
                        });
                    })
                }
            );
        }
    }

    displayModalNoteFile_Edit = () => { //display file modal
        document.getElementById("display_note_file_edit").style.display = "block";
    }

    hideModalNoteFile_Edit = () => { //hide file modal
        document.getElementById("display_note_file_edit").style.display = "none";
    }


    render(){

        const { topic_id, topics } = this.props
        const { message, isLoading } = this.state;

        return(

            <div>

                <div id="display_note_file_edit" className="mymodal">
                    <div className="mymodal-content col-md-6">
                        <span className="close" onClick={this.hideModalNoteFile_Edit}>&times;</span>

                        { topics && topics.map((topic,i) => {
                            if (topic.id === topic_id && this.state.selectedNote !== null) {
                                return (
                                    <div key={i}>
                                        <h3 className="middle">{this.state.selectedNote.fileName}
                                            <div className="row"><div className="title-border"></div></div>
                                        </h3>
                                        
                                        <object aria-label="display-pdf" className="display-pdf" data={this.state.selectedNote.fileUrl}></object>
                                    </div>
                                )
                            } 
                            return null
                        })}
                    </div>
                </div>
                                
                <div className="row">
                    <div className="col col-md-3">
                        <b>Code:</b>
                        <input type="text" id="topicCode" onChange={this.handleChange} defaultValue={this.state.topicCode}className="form-control"/>
                    </div>
                    <div className="col">
                        <b>Name:</b>
                        <input type="text" id="topicName" onChange={this.handleChange} defaultValue={this.state.topicName} className="form-control"/>
                    </div>
                </div>

                <br/>
                
                <div>  
                    <b>Subtopics: </b>

                    <textarea type="text" className="form-control" ref="subtopic" value={this.state.subtopic} onChange={event => this.setState({getSubtopic: event.target.value})}  placeholder="Subtopic"/>
                    <span className="badge bg-blue text-white" onClick={this.addSubtopic.bind(this)}>Add Subtopic</span>

                    <br/><br/>

                    <b>Subtopic Preview:</b>

                    { this.state.subtopics && this.state.subtopics.map((subtopic,i) => {
                        return (
                            <li key={i} style={{marginLeft:'5%'}}>
                                {subtopic.subtopic} {' '}
                                <span onClick={this.removeSubtopic.bind(this, subtopic.id)}> <i className="fas fa-trash text-red" /></span>
                            </li>
                        )
                    })}
                </div>

                <br/><br/>

                <small className="alert alert-danger"><i className="fas fa-exclamation-triangle"/> {' '}After done upload for each below, please <b>wait for a few seconds</b> before proceed to "Update".</small>

                <br/><br/>

                <div className="mt-2">
                    <b>Note: </b><br />
                    <small><i>**PDF file only</i></small><br/>
                    {topics && topics.map((topic,i) => {
                        if (topic.id === topic_id && this.state.selectedNote !== null) {
                            return (
                                <span key={i} onClick={this.displayModalNoteFile_Edit.bind(this, topic_id)}> {this.state.selectedNote.fileName}</span>
                            )
                        } else if (topic.id === topic_id && this.state.selectedNote === null) {
                            return (
                                <em>No selected note.</em>
                            )
                        }
                        return null
                    })}
                    <br/>
                    <input className="mt-2" type="file" onChange={this.handleUpdateNote.bind(this)} />

                    { this.state.progressNote === 100 ?
                        <span className="badge text-white bg-green">Done Upload</span>
                    : this.state.progressNote > 1 && this.state.progressNote < 100 ?
                        <span className="badge text-white bg-yellow">Loading...</span>
                    :
                        <span className="badge text-white bg-blue"onClick={this.executeUpdateNote.bind(this)}>Upload Note</span>
                    }
                </div>

                <br/>

                <div className="mt-4 mb-5">
                    <b>Video: </b><br />

                    { this.state.selectedVideo.videoType ?
                        this.state.selectedVideo.videoType === "Upload" ?
                            <div hidden ={this.state.oldVideoHide}>
                                {topics && topics.map((topic,i) => {
                                    if (topic.id === topic_id && this.state.selectedVideo !== null) { //sbb nnti dia akan display je video even kalau data tu takde data video
                                        return (
                                            <div key={i}>
                                                <video width="250" height="150" src={this.state.selectedVideo.fileUrl} type="video/mp4" controls /> <br/>
                                                <label>{this.state.selectedVideo.fileName}</label>
                                            </div>
                                        )
                                    } else if (topic.id === topic_id && this.state.selectedVideo === null) {
                                        return (
                                            <em>No selected video.</em>
                                        )
                                    }
                                    return null
                                })}
                            </div>
                        : this.state.selectedVideo.videoType === "Youtube" ?
                            <p>{this.state.selectedVideo.fileUrl}</p>
                        : 'No assigned video.'
                    : "Null data." }

                    <p><b>Upload new video :</b></p>
                
                    <select id="videoType" onChange={this.handleChange} className="form-control">
                        <option defaultValue value="">Choose type</option>
                        <option>Youtube</option>
                        <option>Upload</option>
                    </select>
                   
                    { this.state.videoType && this.state.videoType === "Upload" ? 
                        <div>
                            <div><video hidden ={this.state.newVideoHide} width="250" height="150" src={this.state.videoDisplay} type="video/mp4" controls /></div>
                            <input type="file" onChange={this.handleUpdateVideo.bind(this)} />
                            
                            { this.state.progressVideo === 100 ?
                                <span className="badge text-white bg-green">Done Upload</span>
                            : this.state.progressVideo > 1 && this.state.progressVideo < 100 ?
                                <span className="badge text-white bg-yellow">Loading...</span>
                            :
                                <span className="badge text-white bg-blue"onClick={this.executeUpdateVideo.bind(this)}>Upload Video</span>
                            }
                        </div>
                    : this.state.videoType && this.state.videoType === "Youtube" ?
                        <div>
                            <input className="form-control" type="text" id="fileUrl" placeholder='Youtube URL' onChange={e => this.setState({videoUrl: e.target.value})} />
                        </div>
                    : null }
                </div>

                { isLoading === true ? 
                    <small>Loading...</small>
                : 
                    message  ?
                        <small className={message.type === 'updateTopicError' ? "text-red" : message.type === 'updateTopicSuccess'  ? "text-green" : null }>
                            {message.msg}
                        </small>
                    : null
                }

                <div className="mt-5 row justify-content-end">
                    <button type="button" className="btn m-2 btn-outline-black" onClick={this.updateTopic.bind(this)}><i className="fas fa-check-circle"/>{' '}update</button>
                    <button type="button" className="btn m-2 btn-outline-black" onClick={this.props.cancel.bind(this)}><i className="fas fa-times"/>{' '}cancel</button>
                </div>
            </div>
        
        )
    }
}

const mapStateToProps = (state) => {
  return {
      updateTopicError: state.courses.updateTopicError,
      updateTopicSuccess: state.courses.updateTopicSuccess,
  }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateTopic: (updatedTopic, topic_id) => { dispatch(updateTopic(updatedTopic, topic_id))}
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(EditTopic);