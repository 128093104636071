import React, { Component } from 'react';
import { connect } from 'react-redux'
import { updateHiw } from '../../../stores/actions/infoAction';

class EditHowItWork extends Component{

    constructor(props){
        super(props)
        this.state = {
            infoType: this.props.info.infoType,
            infoName: this.props.info.infoName,
            infoNumber: this.props.info.infoNumber,
            infoContent: this.props.info.infoContent,
        
            isLoading: null,
            message: null,
        }
        this.baseState = this.state 
    }

    componentDidUpdate = (prevProps, prevState) => { 
        const { updateHiwError, updateHiwSuccess } = this.props;

        if(prevProps.updateHiwError !== updateHiwError) {
            this.setState({
                isLoading: false,
                message: {
                    msg: updateHiwError.message,
                    type: updateHiwError.type,
                }
            })
        }

        if(prevProps.updateHiwSuccess !== updateHiwSuccess) {
            this.setState({
                isLoading: false,
                message: {
                    msg: updateHiwSuccess.message,
                    type: updateHiwSuccess.type,
                }
            })
            setTimeout(() => { 
                this.setState(this.baseState)
                this.props.hide();
            }, 2000);
        }
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]:  e.target.valueAsNumber || e.target.value
        })
    }

    updateHiw = (e) => {
        var result = window.confirm("Confirm update?");

        if (result) {
            this.setState({
                isLoading: true
            })
            this.props.updateHiw(this.state, this.props.infoId);
        }
    }

    render(){

        const { message, isLoading } = this.state;

        return(

        <div>
                    
            <b className="uppercase middle">edit refund policy
                <div className="title-border"></div>
            </b>

            <div className="row">
                <input type="number" id="infoNumber" onChange={this.handleChange.bind(this)} className="form-control" defaultValue={this.state.infoNumber} />
                <input type="text" id="infoName" onChange={this.handleChange.bind(this)} className="form-control" defaultValue={this.state.infoName} />
                <textarea type="text" id="infoContent" onChange={this.handleChange.bind(this)} className="form-control" defaultValue={this.state.infoContent} />
            </div>

            { isLoading === true ? 
                <small>Loading...</small>
            : 
                message  ?
                    <small className={message.type === 'updateHiwError' ? "text-red" : message.type === 'updateHiwSuccess'  ? "text-green" : null }>
                        {message.msg}
                    </small>
                : null
            }

            <div className="mt-5 row justify-content-end">
                <button className="btn m-2 btn-outline-black" onClick={this.updateHiw.bind(this)}><i className="fas fa-check-circle"/>{' '}update</button>
            </div>
        </div>
        
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const id = ownProps.info_id;
    const infosList = state.firestore.data.infos;
    const info = infosList ? infosList[id] : null
    return {
        infoId: id,
        infosList: infosList,
        info: info,

        updateHiwError: state.infos.updateHiwError,
        updateHiwSuccess: state.infos.updateHiwSuccess,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
      updateHiw: (refund, infoId) => dispatch(updateHiw(refund, infoId))
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(EditHowItWork)