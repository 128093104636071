import React, { Component } from 'react';
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'

class ParticipantDetails extends Component {

    render(){

        const { name, email, phone, company, modules, quizEnrollments, examEnrollments } = this.props;

        return(

        <div className="class-part-detail">
            <div className="middle"><b>Participant Detail</b>
                <div className="row"><div className="title-border"></div></div>
            </div>
            
            <small className="text-left">
                <i className="fas fa-user"/>{' '}{name} <br/>
                <i className="fas fa-envelope"/>{' '}{email} <br/>
                <i className="fas fa-phone"/>{' '}{phone ? phone : 'Not updated'} <br/>
                <i className="fas fa-briefcase"/>{' '}{company ? company : 'Not updated'}
            </small>

            <table className='mt-3 table'>
                <tbody>

                    { modules && modules.map((module,i) => {
                        return (
                            quizEnrollments && quizEnrollments.map(quiz => {
                                if(quiz.moduleId === module.id) {
                                    return (
                                        <tr key={quiz.id}>
                                            <td className='text-left'><small>{module.moduleName}</small></td>
                                            <th>{quiz.quizScore}%</th>
                                        </tr>
                                    )
                                } return null
                            }))
                    })}

                    {examEnrollments && examEnrollments.map((exam,i) => {
                        return (
                            <tr key={exam.id}>
                                <td className='text-left'><small>Examination</small></td>
                                <th>{exam.examScore}%</th>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
        
        )
    }
}
  
const mapStateToProps = (state, ownProps) => {

    return {
        userId: ownProps.userId,
        courseId: ownProps.selectedCourseId,
        
        modules: state.firestore.ordered.modules,
        quizEnrollments: state.firestore.ordered.quizEnrollments,
        examEnrollments: state.firestore.ordered.examEnrollments,
    }
}
  
export default compose(
    connect(mapStateToProps, null),
    firestoreConnect(props => [
        { collection: 'modules', orderBy:'moduleCode' },
        { collection: 'quizEnrollments', where: [ ['userId', '==', props.userId], ['courseId', '==', props.courseId] ] },
        { collection: 'examEnrollments', where: [ ['userId', '==', props.userId], ['courseId', '==', props.courseId] ] },
        
    ])
)(ParticipantDetails);