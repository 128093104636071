import React, { Component } from 'react';
import { connect } from 'react-redux'

import { addClassInfo } from '../../../../stores/actions/classAction';

class AddExtraInfo extends Component {

    constructor(props){
        super(props)
        this.state = {
            loc_link:'',
            loc_detail:[],
            extra_info: [],

            loc_detail_data:'',
            extra_info_data:'',

            isLoading:null,
            message:null
        }
        this.baseState = this.state 
    }
    
    componentDidUpdate = (prevProps, prevState) => {  

        const { addClassInfoError, addClassInfoSuccess } = this.props;

        if(prevProps.addClassInfoError !== addClassInfoError) {
            this.setState({
                isLoading: false,
                message: {
                    msg: addClassInfoError.message,
                    type: addClassInfoError.type,
                }
            })
        }

        if(prevProps.addClassInfoSuccess !== addClassInfoSuccess) {
            this.setState({
                isLoading: false,
                message: {
                    msg: addClassInfoSuccess.message,
                    type: addClassInfoSuccess.type,
                },
            }, () => {
                setTimeout(() => { 
                    this.props.hideModal();
                    setTimeout(() => { 
                        this.setState(this.baseState)
                    }, 3000);
                }, 2000);
            })
        }
    } 

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleData = (id, type) => {
        const { extra_info, loc_detail, loc_detail_data, extra_info_data } = this.state;

        //add
        if (type === "loc_detail" && loc_detail_data !== "") {
            this.setState({ loc_detail:[...loc_detail, loc_detail_data] })

            document.getElementById('loc_detail').value = "";
            this.setState({loc_detail_data: ''})

        } else if (type === "extra_info" && extra_info_data !== "") {
            this.setState({ extra_info:[...extra_info, extra_info_data] })

            document.getElementById('extra_info').value = "";
            this.setState({extra_info_data: ''})

        } else if (type === "") {

        //delete
        } else if (type === "loc-del" ) {
            let data = loc_detail && loc_detail.filter(d => { return d !== id; })
            this.setState({ loc_detail: data })

        } else if (type === "extra-del" ) {
            let data = extra_info && extra_info.filter(d => { return d !== id; })
            this.setState({ extra_info: data })

        } else return null

    }

    submit = () => {
        var result = window.confirm("Confirm add?");
    
        if (result) {
            this.setState({
                isLoading: true
            })

            this.props.addClassInfo(this.state, this.props.batchId)
            // console.log(this.state, this.props.batchId)
        }

    }

    render () {

        const { message, isLoading } = this.state;
        const { loc_detail, extra_info } = this.state;

        return (

            <div>

                <b className="uppercase middle">
                    Add Practical Session Extra Info
                    <div className="title-border"></div>
                </b>

                <div className='mb-4'>
                    <b>Location link:</b>
                    <input id="loc_link" placeholder='Location link' onChange={this.handleChange} type="text" className="form-control" />
                </div>

                <div>
                    <b>Location info:</b>
                    <div className='row pl-3 pr-3'>
                        <input id="loc_detail"  onChange={(e) => this.setState({loc_detail_data: e.target.value})} type="text" className='col m-0 form-control' placeholder="Location detail" /> 
                        <button 
                            onClick={this.handleData.bind(this, null, 'loc_detail')}
                            style={{borderRadius:"2px"}} 
                            className='btn btn-primary'>Add
                        </button>
                    </div>

                    <ul>
                        { loc_detail && loc_detail.map((d,i) => {
                            return (
                                <li key={i}>{d} {' '}
                                    <span onClick={this.handleData.bind(this, d, 'loc-del')}><i className='fas fa-times text-danger' /></span>
                                </li>
                            )
                        })}
                    </ul>
                </div>

                <div className='mt-2'>
                    <b>Extra info:</b>
                    <div className='row pl-3 pr-3'>
                        <input id="extra_info"  onChange={(e) => this.setState({extra_info_data: e.target.value})} type="text" className='col m-0 form-control' placeholder="Extra info" /> 
                        <span 
                            onClick={this.handleData.bind(this, null, 'extra_info')}
                            style={{borderRadius:"2px"}} 
                            className='btn btn-primary'>Add
                        </span>
                    </div>

                    <ul>
                        { extra_info && extra_info.map((d,i) => {
                            return (
                                <li key={i}>{d} {' '}
                                    <span onClick={this.handleData.bind(this, d, 'extra-del')}><i className='fas fa-times text-danger' /></span>
                                </li>
                            )
                        })}
                    </ul>

                </div>
                
                { isLoading === true ? 
                    <small>Loading...</small>
                : 
                    message  ?
                        <small className={message.type === 'addClassInfoError' ? "text-red" : message.type === 'addClassInfoSuccess'  ? "text-green" : null }>
                            {message.msg}
                        </small>
                    : null
                }

                <button className='m-1 mt-4 float-right text-white all-btn bg-success' onClick={this.submit.bind(this)}>Save {' '}<i className='fas fa-check-circle' /></button>

            </div>

        )
    }
}



const mapStateToProps = (state) => {
    return {
        addClassInfoError: state.classes.addClassInfoError,
        addClassInfoSuccess: state.classes.addClassInfoSuccess,
    }
  }
  
const mapDispatchToProps = (dispatch) => {
    return {
    addClassInfo: (data, batchId) => dispatch(addClassInfo(data, batchId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddExtraInfo);
