const initState = {
    enrollments: []
}

const enrollmentReducer = (state = initState, action ) => {

    switch (action.type) {
        case 'ADDED_NEW_ENROLLMENT':
            return {
                ...state,
                newEnrollmentSuccess: {
                    message: action.message,
                    type: 'newEnrollmentSuccess'
                }
            }
        case 'ADDED_NEW_ENROLLMENT_ERROR':
            return {
                ...state,
                newEnrollmentError: {
                    message: action.err.message,
                    type: 'newEnrollmentError'
                }
            }

        case 'UPDATE_EXPIRED_DATE':
            return {
                ...state,
                extendEnrollmentSuccess: {
                    message: action.message,
                    type: 'extendEnrollmentSuccess'
                }
            }
        case 'UPDATE_EXPIRED_DATE_ERROR':
            return {
                ...state,
                extendEnrollmentError: {
                    message: action.err.message,
                    type: 'extendEnrollmentError'
                }
            }

        case 'UPDATE_TOPIC_STATUS':
            return state;
        case 'UPDATE_TOPIC_STATUS_ERROR':
            return state;

        case 'UPDATE_CURRENT_MODULE_STATUS':
            return state;
        case 'UPDATE_CURRENT_MODULE_STATUS_ERROR':
            return state;

        case 'UPDATE_NEXT_MODULE_STATUS':
            return state;
        case 'UPDATE_NEXT_MODULE_STATUS_ERROR':
            return state;

        case 'UPDATE_EXAM_ENROLLMENT_STATUS':
            return state;
        case 'UPDATE_EXAM_ENROLLMENT_STATUS_ERROR':
            return state;

        case 'UPDATE_EXAM_ISDONE':
            return state;
        case 'UPDATE_EXAM_ISDONE_ERROR':
            return state;

        case 'UPDATE_COURSE_ISDONEEXAM':
            return state;
        case 'UPDATE_COURSE_ISDONEEXAM_ERROR':
            return state;

        case 'UPDATE_QUIZ_FAIL_COUNTER':
            return state;
        case 'UPDATE_QUIZ_FAIL_COUNTER_ERROR':
            return state;

        case 'UPDATE_QUIZ_SCORE':
            return state;
        case 'UPDATE_QUIZ_SCORE_ERROR':
            return state;

        case 'UPDATE_QUIZ_SUSPEND':
            return state;
        case 'UPDATE_QUIZ_SUSPEND_ERROR':
            return state;

        case 'RENEW_QUIZ_SUSPEND':
            return state;
        case 'RENEW_QUIZ_SUSPEND_ERROR':
            return state;

        case 'UPDATE_EXAM_SCORE':
            return state;
        case 'UPDATE_EXAM_SCORE_ERROR':
            return state;

        case 'UPDATE_EXAM_FAIL_COUNTER':
            return state;
        case 'UPDATE_EXAM_FAIL_COUNTER_ERROR':
            return state;

        case 'UPDATE_EXAM_SUSPEND':
            return state;
        case 'UPDATE_EXAM_SUSPEND_ERROR':
            return state;

        case 'RENEW_EXAM_SUSPEND':
            return state;
        case 'RENEW_EXAM_SUSPEND_ERROR':
            return state;

        case 'UPDATE_QUIZ_ATTEND':
            return state;
        case 'UPDATE_QUIZ_ATTEND_ERROR':
            return state;

         case 'UPDATE_EXAM_ATTEND':
            return state;
        case 'UPDATE_EXAM_ATTEND_ERROR':
            return state;

        case 'UPDATE_MODULE_START_DATE':
            return state;
        case 'UPDATE_MODULE_START_DATE_ERROR':
            return state;

        case 'UPDATE_MODULE_FINISH_DATE':
            return state;
        case 'UPDATE_MODULE_FINISH_DATE_ERROR':
            return state;

        case 'UPDATE_EXAM_START_DATE':
            return state;
        case 'UPDATE_EXAM_START_DATE_ERROR':
            return state;

        case 'UPDATE_EXAM_FINISH_DATE':
            return state;
        case 'UPDATE_EXAM_FINISH_DATE_ERROR':
            return state;

        case 'DELETED_COURSE_ENROLLMENT':
            return state;
        case 'DELETED_COURSE_ENROLLMENT_ERROR':
            return state;

        case 'DELETED_EXAM_ENROLLMENT':
            return state;
        case 'DELETED_EXAM_ENROLLMENT_ERROR':
            return state;

        case 'DELETED_MODULE_ENROLLMENT':
            return state;
        case 'DELETED_MODULE_ENROLLMENT_ERROR':
            return state;

        case 'DELETED_QUIZ_ENROLLMENT':
            return state;
        case 'DELETED_QUIZ_ENROLLMENT_ERROR':
            return state;

        default:
            return state;
    }
}

export default enrollmentReducer