import React, { Component } from 'react';

class AboutHalalAcademy extends Component{


    render(){


        return(
            
            <div className="min-height">
                <div className="middle page-header">
                    {/* <div className="col col-md-4" style={{marginBottom:"30px"}}> 
                        <img src="../../../image/HA_LOGO.png" alt="" width="45%"/><br />
                    </div> */}

                     <div className='col-md-8'>
                        <h1>about
                            <div className="bg-white title-border"></div>
                        </h1>
                    </div>
                </div>

                <div className="page-content middle">

                    <div className="col-md-8">
                        <p className="text-left">
                            In collaboration with JAKIM, through Malaysia Halal Council, Halal Academy (Managed by GAE Resources Sdn Bhd) has been appointed a registered Halal Training Provider under Halal Professional Board (HPB) and a strategic partner of Malaysia International Halal Academy (MIHA).
                            <br/><br/>
                            Halal Academy has been involved in various halal industry development initiatives including halal capacity building and halal entrepreneur’s development programs across industry categories.
                            <br/><br/>
                            Halal Academy is the one and only registered online learning platform providing Halal Professional training for the industry. Malaysia as the global leader in the Halal industry has been the referral point for the whole world in the Halal subject matter. This is an initiative by the government to bring Halal Education into the next level.
                        </p>

                        <h5 className="mt-5 middle">Our core values:
                            <div className="title-border"></div>
                        </h5>

                        <div className="mb-5 row about-circle justify-content-center">
                            <div className="about-box">
                                <li><i className="fas fa-check-circle"></i><br/>
                                Integrity</li>
                            </div>

                            <div className="about-box">
                                <li><i className="fas fa-hands-helping"></i><br/>
                                Accountability</li>
                            </div>

                            <div className="about-box">
                                <li><i className="fas fa-users"></i><br/>
                                Teamwork</li>
                            </div>

                            <div className="about-box">
                                <li><i className="fas fa-lightbulb"></i><br/>
                                Innovation</li>
                            </div>
                        </div>

                        <div className="about-detail">
                            <b className="col"><i>“Online learning is not the next big thing, it is the now big thing”</i></b>
                            <b className="col"><i>“Because learning can happen anytime, anywhere”</i></b>
                        </div>
                    </div>
                </div>
            </div>

        
        )
    }
        
}

export default AboutHalalAcademy;