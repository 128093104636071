import React, { Component } from 'react';
import { connect } from 'react-redux'
import { changePassword } from '../../stores/actions/userAction'
import { signOut } from '../../stores/actions/authAction'

class ChangePassword extends Component{

    state = {
        oldPassword:'',
        newPassword:'',
        confirm_newPassword:'',
        email:'',
    
        isLoading: null,
        message: null,
    }
    
    componentDidUpdate = (prevProps, prevState) => { 
        const { changePasswordError, changePasswordSuccess } = this.props;

        if(prevProps.changePasswordError !== changePasswordError) {
            this.setState({
                isLoading: false,
                message: {
                    msg: changePasswordError.message,
                    type: changePasswordError.type,
                }
            })
            setTimeout(() => { 
                this.setState({message:''})
            }, 4000);
        }

        if(prevProps.changePasswordSuccess !== changePasswordSuccess) {
            this.setState({
                isLoading: false,
            })

            var result = window.confirm(changePasswordSuccess.message);
            if (result) {
                this.props.signOut();
            }
        }
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    updatePassword = () => {

        if(this.state.newPassword !== this.state.confirm_newPassword) {
            this.setState({
                message: {
                    msg: 'Password did not match.',
                    type: 'changePasswordError',
                }
            })
        } else {
            var result = window.confirm("Confirm change password?");
            if (result) {
                this.setState({
                    isLoading: true
                })
                this.props.changePassword(this.state)
            }
        }
    }

    showPassword = (type) => {
        var x = type === "pass1" ? document.getElementById("oldPassword") :  type === "pass2" ? document.getElementById("newPassword") : document.getElementById("confirm_newPassword");
        var y = type === "pass1" ? document.getElementById("eye1") :  type === "pass2" ? document.getElementById("eye2") : document.getElementById("eye3")

        if (x.type === "password") {
            x.type = "text";
            y.className = "fas fa-eye-slash"
        } else {
            x.type = "password";
            y.className = "fas fa-eye"
        }
    }

    render(){
        
        const { isLoading, message, newPassword } = this.state;
    
        return(

            <div>
                <b><i className="fas fa-user text-darkgrey"></i>{'  '}Change Password</b>
                <div>Change user account password.
                    <div className="row"><div className="title-border"></div></div>
                </div>

                <div className="row justify-content-center account-box">
                    <form className="col form-group col-md-10">
                        <div className="input-group input-eye">
                            <input id="oldPassword" onChange={this.handleChange} type="password" className="form-control" placeholder="Old Password"/>
                            <div onClick={this.showPassword.bind(this, 'pass1')} className="icon-eye"><i id="eye1" className="fas fa-eye"></i></div>
                        </div>
                        <div className="mt-1 input-group input-eye">
                            <input id="newPassword" onChange={this.handleChange} type="password" className="form-control" placeholder="New Password"/>
                            <div onClick={this.showPassword.bind(this, 'pass2')} className="icon-eye"><i id="eye2" className="fas fa-eye"></i></div>
                        </div>
                        
                        { newPassword && newPassword.length > 0 && newPassword.length < 5 ?
                            <small className="text-red">Weak password</small>
                        : newPassword && newPassword.length > 4 && newPassword && newPassword.length < 8 ?
                            <small className="text-warning">Password OK</small>
                        : newPassword && newPassword.length > 7 ?
                            <small className="text-green">Strong password</small>
                        : null }

                        <div className="mt-1 input-group input-eye">
                            <input id="confirm_newPassword" onChange={this.handleChange} type="password" className="form-control" placeholder="Confirm New Password"/>
                            <div onClick={this.showPassword.bind(this, 'pass3')} className="icon-eye"><i id="eye3" className="fas fa-eye"></i></div>
                        </div>

                        { isLoading === true ? 
                            <small>Loading...</small>
                        : 
                            message  ?
                                <small className={message.type === 'changePasswordError' ? "text-red" : message.type === 'changePasswordSuccess'  ? "text-green" : null }>
                                    {message.msg}
                                </small>
                            : null
                        }

                        <br/>
                        
                        <div className="row justify-content-end">
                            <button type="button" className="mt-3 btn all-btn" onClick={this.updatePassword.bind(this)}><i className="fas fa-check-circle"/>{' '}Change Password</button>
                        </div>
                    </form>
                </div>
            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        changePasswordError: state.users.changePasswordError,
        changePasswordSuccess: state.users.changePasswordSuccess,
    }
  }

const mapDispatchToProps = (dispatch) => {
    return {
        changePassword: (data) => dispatch(changePassword(data)),
        signOut: () => dispatch(signOut())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);