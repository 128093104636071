import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';

import { deleteExam } from '../../../stores/actions/courseAction';

import DisplayQuestion from '../QuestionManager/DisplayQuestion';
import EditExam from './EditExam';

class DisplayExam extends Component{

    state = {
        hideDetails : false,
        hideEdit: true,
        id:'',

        clickedQuestionId:'',
    }
    
    componentDidUpdate = (prevProps, prevState) => { 

        const { deleteExamError, deleteExamSuccess } = this.props;

        if(prevProps.deleteExamError !== deleteExamError) {
            alert(deleteExamError.message);
        }
        
        if(prevProps.deleteExamSuccess !== deleteExamSuccess) {
            alert(deleteExamSuccess.message);
            document.getElementById("clickExam").click();
        }
    } 

    handleEdit = (examId) => {
        this.setState({
          hideDetails : true,
          hideEdit: false,
          id: examId
        })
    }

    cancel = (e) => {
        this.setState({
            id:''
        })
    }

    deleteExam = (examId) => {

        var result = window.confirm("Confirm delete?");
    
        if (result) {
            this.props.deleteExam(examId)
        }
    }

    showModalQuestion = (id) => {
        this.setState({
            clickedQuestionId: id
        })
        document.getElementById("modal-q-exam").style.display = "block";
    }

    hideModalQuestion = () => {
        this.setState({
            clickedQuestionId: ""
        })

        document.getElementById("modal-q-exam").style.display = "none";
    }

    render(){

        const { query, questions, modules, courses, exam, examId } = this.props;

        let query_id = query.get('id');
        let isLoading = true;

        const examDetails = exam ? (
            <div key={exam.id}>
                { isLoading = false }
                { this.state.id === examId ?
                    <EditExam cancel={this.cancel} exam={exam} examId={examId} questions={questions} />
                :
                    <div >
                        <p className="mt-2"><b>Code: </b>{exam.examCode}</p>
                        <p className="mt-2"><b>Name: </b>{exam.examName}</p>
                        
                        <p className="mt-2"><b>Questions: </b></p>
                        <div className="border-style">
                            {questions && questions.map(question => {
                                return(
                                    exam.selectedQuestions.map((selected, i) => { 
                                        if( question.id === selected) {
                                            return (
                                                <span onClick={this.showModalQuestion.bind(this, question.id)} className="badge text-white bg-green" key={i}>{question.questionCode}</span>
                                            )
                                        }
                                        return null
                                    })
                                )
                            })}
                        </div>
    
                        <div className="mt-5 row justify-content-end">
                            <button type="button" className="btn m-2 btn-outline-black" onClick={this.handleEdit.bind(this, examId)}><i className="fas fa-edit"></i></button>
                            <button typr="button" className="btn m-2 btn-outline-red" onClick={this.deleteExam.bind(this, examId)}><i className="fas fa-trash"></i></button>
                        </div>
                    </div>
                }
            </div>
        ) : (
            null
        )

        return(

            <div className="container col-md-12">
                <div id="modal-q-exam" className="mymodal">
                    <div className="mymodal-content col-md-6">
                        <span className="close" onClick={this.hideModalQuestion.bind(this)}>&times;</span>
                        <DisplayQuestion viewOnly={true} courses={courses} modules={modules} questionId={this.state.clickedQuestionId}/>
                    </div>
                </div>

                { query_id ?
                    isLoading === true ? <p>Data loading... Please wait...</p> : examDetails
                : <p>Exam data not available.</p> }

                <div hidden={true}>
                    <Link id="clickExam" to={`/systemmanager?topic=course&subtopic=exam`} />
                </div>
            </div>
        
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const id = ownProps.selectedExamId;
    const exams = state.firestore.data.exams;
    const exam = exams ? exams[id] : null
    return {
        exam: exam,
        examId: id,
        
        deleteExamError: state.courses.deleteExamError,
        deleteExamSuccess: state.courses.deleteExamSuccess,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
      deleteExam: (examId) => dispatch(deleteExam(examId))
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(DisplayExam)