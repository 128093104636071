import React, { Component } from 'react';
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'

class HowItWorks extends Component{

    render(){
        
        const { infos } = this.props;

        let separated = [], isLoading = true;
        
        infos && infos.map(info => {
            if(info.infoType === "HOW IT WORK") {
                separated.push(info)
            }
            return null
        })

        let sorted = separated && separated.sort(function (a,b) {
            return a.infoNumber - b.infoNumber
        })

        let hiwList = sorted && sorted.map((data, i) => {
            isLoading = false;
            return (
                <div key={i} className="text-left hiw-box" style={{margin:"40px 0px 0px 0px"}}>
                    <b className="title mb-2"><span>{data.infoNumber}.</span>{' '}{data.infoName}</b>
                        <div className="title-border"></div>
                    <p style={{textAlign:"justify"}} dangerouslySetInnerHTML={{__html: data.infoContent}}></p>
                </div>
            )
        })

        return(
            
            <div className="min-height">

                <div className="page-header middle">
                    {/* <div className="col col-md-4" style={{marginBottom:"30px"}}> 
                        <img src="../../../image/HA_LOGO.png" alt="" width="45%"/><br />
                    </div> */}

                    <div className='col-md-8'>
                        <h1>how it works
                            <div className="bg-white title-border"></div>
                        </h1>
                    </div>
                </div>
                
                <div className='page-content middle'>
                    <div className="col-md-8">
                        
                        { isLoading === true ? <div className="loader"></div> : hiwList }
                    </div>
                </div>
            </div>
        
        )
    }
}

const mapStateToProps = (state) => {
    return {
      infos: state.firestore.ordered.infos,
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect([
        { collection: 'infos'},
    ])
)(HowItWorks);