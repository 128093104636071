const initState = {
    classes: []
}

const classReducer = (state = initState, action ) => {

    switch (action.type) {
        case 'SUBMITTED_NEW_CLASS_REQ':
            return {
                ...state,
                submitFormClassSuccess: {
                    message: action.message,
                    type: 'submitFormClassSuccess'
                }
            }
        case 'SUBMITTED_NEW_CLASS_REQ_ERROR':
            return {
                ...state,
                submitFormClassError: {
                    message: action.err.message,
                    type: 'submitFormClassError'
                }
            }
            
        case 'CLASS_CUSTOM_EMAIL':
            return {
                ...state,
                classCustomEmailSuccess: {
                    message: action.message,
                    type: 'classCustomEmailSuccess'
                }
            }
        case 'CLASS_CUSTOM_EMAIL_ERROR':
            return {
                ...state,
                classCustomEmailError: {
                    message: action.err.message,
                    type: 'classCustomEmailError'
                }
            }

        case 'ADD_NEW_BATCH':
            return {
                ...state,
                addBatchSuccess: {
                    message: action.message,
                    type: 'addBatchSuccess'
                }
            }
        case 'ADD_NEW_BATCH_ERROR':
            return {
                ...state,
                addBatchError: {
                    message: action.err.message,
                    type: 'addBatchError'
                }
            }

        case 'UPDATE_PCB':
            return {
                ...state,
                updateBatchSuccess: {
                    message: action.message,
                    type: 'updateBatchSuccess'
                }
            }
        case 'UPDATE_PCB_ERROR':
            return {
                ...state,
                updateBatchError: {
                    message: action.err.message,
                    type: 'updateBatchError'
                }
            }

        case 'DELETE_PCB':
            return {
                ...state,
                deleteBatchSuccess: {
                    message: action.message,
                    type: 'deleteBatchSuccess'
                }
            }
        case 'DELETE_PCB_ERROR':
            return {
                ...state,
                deleteBatchError: {
                    message: action.err.message,
                    type: 'deleteBatchError'
                }
            }

        case 'DELETE_PCP':
            return {
                ...state,
                deletePcpSuccess: {
                    message: action.message,
                    type: 'deletePcpSuccess'
                }
            }
        case 'DELETE_PCP_ERROR':
            return {
                ...state,
                deletePcpError: {
                    message: action.err.message,
                    type: 'deletePcpError'
                }
            }

        case 'UPDATE_PCB_STATUS':
            return state;
        case 'UPDATE_PCB_STATUS_ERROR':
            return state;

        case 'UPDATE_PCB_NOTE':
            return {
                ...state,
                updateBatchNoteSuccess: {
                    message: action.message,
                    type: 'updateBatchNoteSuccess'
                }
            }
        case 'UPDATE_PCB_NOTE_ERROR':
            return {
                ...state,
                updateBatchNoteError: {
                    message: action.err.message,
                    type: 'updateBatchNoteError'
                }
            }

        case 'UPDATE_PCB_DATE':
            return {
                ...state,
                updateBatchDateSuccess: {
                    message: action.message,
                    type: 'updateBatchDateSuccess'
                }
            }
        case 'UPDATE_PCB_DATE_ERROR':
            return {
                ...state,
                updateBatchDateError: {
                    message: action.err.message,
                    type: 'updateBatchDateError'
                }
            }

        case 'UPDATE_PCB_SELECTEDPARTICIPANTS':
            return state;
        case 'UPDATE_PCB_SELECTEDPARTICIPANTS_ERROR':
            return state;


        case 'UPDATE_PCP_STATUS':
            return {
                ...state,
                updatePcpStatusSuccess: {
                    message: action.message,
                    type: 'updatePcpStatusSuccess'
                }
            }
        case 'UPDATE_PCP_STATUS_ERROR':
            return {
                ...state,
                updatePcpStatusError: {
                    message: action.err.message,
                    type: 'updatePcpStatusError'
                }
            }

        case 'UPDATE_PCP_STATUS_BATCHID':
            return {
                ...state,
                updatePcpStatusBatchIdSuccess: {
                    message: action.message,
                    type: 'updatePcpStatusBatchIdSuccess'
                }
            }
        case 'UPDATE_PCP_STATUS_BATCHID_ERROR':
            return {
                ...state,
                updatePcpStatusBatchIdError: {
                    message: action.err.message,
                    type: 'updatePcpStatusBatchIdError'
                }
            }

        case 'CREATE_GROUP':
            return {
                ...state,
                createGroupSuccess: {
                    message: action.message,
                    type: 'createGroupSuccess'
                }
            }
        case 'CREATE_GROUP_ERROR':
            return {
                ...state,
                createGroupError: {
                    message: action.err.message,
                    type: 'createGroupError'
                }
            }

        case 'UPDATE_GROUP':
            return {
                ...state,
                updateGroupSuccess: {
                    message: action.message,
                    type: 'updateGroupSuccess'
                }
            }
        case 'UPDATE_GROUP_ERROR':
            return {
                ...state,
                updateGroupError: {
                    message: action.err.message,
                    type: 'updateGroupError'
                }
            }

        case 'DELETE_GROUP':
            return {
                ...state,
                deleteGroupSuccess: {
                    message: action.message,
                    type: 'deleteGroupSuccess'
                }
            }
        case 'DELETE_GROUP_ERROR':
            return {
                ...state,
                deleteGroupError: {
                    message: action.err.message,
                    type: 'deleteGroupError'
                }
            }

        case 'CREATE_TRANSCRIPT':
            return {
                ...state,
                createTranscriptSuccess: {
                    message: action.message,
                    type: 'createTranscriptSuccess'
                }
            }
        case 'CREATE_TRANSCRIPT_ERROR':
            return {
                ...state,
                createTranscriptError: {
                    message: action.err.message,
                    type: 'createTranscriptError'
                }
            }

        case 'UPDATE_TRANSCRIPT':
            return {
                ...state,
                updateTranscriptSuccess: {
                    message: action.message,
                    type: 'updateTranscriptSuccess'
                }
            }
        case 'UPDATE_TRANSCRIPT_ERROR':
            return {
                ...state,
                updateTranscriptError: {
                    message: action.err.message,
                    type: 'updateTranscriptError'
                }
            }

        case 'DELETE_TRANSCRIPT':
            return {
                ...state,
                deleteTranscriptSuccess: {
                    message: action.message,
                    type: 'deleteTranscriptSuccess'
                }
            }
        case 'DELETE_TRANSCRIPT_ERROR':
            return {
                ...state,
                deleteTranscriptError: {
                    message: action.err.message,
                    type: 'deleteTranscriptError'
                }
            }

         case 'ADD_CLASS_INFO':
            return {
                ...state,
                addClassInfoSuccess: {
                    message: action.message,
                    type: 'addClassInfoSuccess'
                }
            }
        case 'ADD_CLASS_INFO_ERROR':
            return {
                ...state,
                addClassInfoError: {
                    message: action.err.message,
                    type: 'addClassInfoError'
                }
            }

        case 'UPDATE_CLASS_INFO':
            return {
                ...state,
                updateClassInfoSuccess: {
                    message: action.message,
                    type: 'updateClassInfoSuccess'
                }
            }
        case 'UPDATE_CLASS_INFO_ERROR':
            return {
                ...state,
                updateClassInfoError: {
                    message: action.err.message,
                    type: 'updateClassInfoError'
                }
            }

            

        // case 'UPDATE_PCP_SELECTEDBATCHID':
        //     console.log('pcp selectedBatchId updated', action.pcpId);
        //     return state;
        // case 'UPDATE_PCP_SELECTEDBATCHID_ERROR':
        //     console.log('pcp selectedBatchId updated error', action.err)
        //     return state;
    

        default:
            return state;
    }
}

export default classReducer