import React, { Component } from 'react';
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Navbar from './components/layout/Navbar';
import Footer from './components/layout/Footer';

// import MapEnrollmentData from './components/admin_test/map_data/MapEnrollmentData';
// import ReviewQuestions from './components/admin_test/ReviewQuestions';
import Test from './components/admin_test/Test';

import Main2 from './components/Main2';
import NoMatchPage from './components/NoMatchPage';

import CourseList from './components/CourseList';
import CourseList_Flexi from './components/CourseList_Flexi';
import CourseDetail from './components/CourseDetail';

//INFOS
import AboutHalalAcademy from './components/infos/AboutHalalAcademy';
import HalalInfo from './components/infos/HalalInfo';
import HalalIndustry from './components/infos/HalalIndustry';
import HowItWorks from './components/infos/HowItWorks';
import TermsAndConditions from './components/infos/TermsAndConditions';
import PrivacyPolicy from './components/infos/PrivacyPolicy';
import Help from './components/infos/Help';


//USER
import UserDashboard from './components/user/UserDashboard';

//E-LEARNING
import EnrollmentPage from './components/e-learning/EnrollmentPage';
import EnrollmentModule from './components/e-learning/EnrollmentModule';
import LearningPage from './components/e-learning/LearningPage';
import Quiz from './components/e-learning/Quiz';
import Exam from './components/e-learning/Exam';

//ADMIN
import SystemManager from './components/admin_system/SystemManager';
import AdminManager from './components/admin_admin/AdminManager';

//TOYYIBPAY - ONLINE PAYMENT
import ConfirmPurchase from './components/toyyibpay/ConfirmPurchase';
import ReturnPaymentStatus from './components/toyyibpay/ReturnPaymentStatus';

import EmailVerified from './components/EmailVerfied';

// import Email from './components/user/contactus/Email';
import Faq from './components/user/contactus/Faq';
import Career from './components/infos/Career';

import Transcript from './components/admin_admin/PhysicalClass/transcript/Transcript';
import BatchData from './components/admin_admin/PhysicalClass/batchData/BatchData';
import BatchData_Pdf from './components/admin_admin/PhysicalClass/batchData/BatchData_Pdf';

import CourseTimestamp from './components/admin_admin/CourseTimestamp';

import EventsList from './components/EventsList';
import ModuleResource from './components/e-learning/ModuleResource';

class App extends Component {

    render() {

        return (

        <BrowserRouter>
        <div style={{backgroundImage: `url(${"/image/bg.jpg"})`}}>
            <Navbar />
            <Faq />
            <Switch>
                <Route exact path="/test" component={Test}/>

                {/* <Route exact path="/map-enrollment-data" component={MapEnrollmentData}/>
                <Route exact path="/review-questions" component={ReviewQuestions}/> */}

                <Route exact path="/" component={Main2}/>
                <Route exact path="/abouthalalacademy" component={AboutHalalAcademy}/>

                <Route exact path="/career" component={Career}/>
                <Route exact path="/help" component={Help}/>
                <Route exact path="/halalindustry" component={HalalIndustry}/>
                <Route exact path="/howitworks" component={HowItWorks}/>
                <Route exact path="/termsandconditions" component={TermsAndConditions}/>
                <Route exact path="/privacypolicy" component={PrivacyPolicy}/>
                <Route exact path="/halalinfo" component={HalalInfo}/>

                <Route exact path="/program/:program_id" component={CourseList}/>
                <Route exact path="/program/:program_id/course/:course_id/flexi" component={CourseList_Flexi}/>
                <Route exact path="/program/:program_id/course/:course_id/detail" component={CourseDetail}/>

                <Route exact path="/userdashboard" component={UserDashboard}/>
                {/* <Route exact path="/useraccount" component={UserAccount}/> */}

                <Route exact path="/enrollment" component={EnrollmentPage}/>
                <Route exact path="/enrollment/module/:course_id" component={EnrollmentModule}/>
                {/* <Route exact path="/enrollment/:course_id/:module_index/:module_id/topic/:topicFirst" component={LearningPage}/> */}
                <Route exact path="/enrollment/learning" component={LearningPage}/>
                <Route exact path="/enrollment/quiz/:course_id/:module_index/:module_id/:enrolledQuiz_id" component={Quiz}/>
                <Route exact path="/enrollment/exam/:enrolledExam_id" component={Exam}/>
                <Route exact path="/enrollment/resource" component={ModuleResource}/>

                <Route exact path="/systemmanager" component={SystemManager}/>
                <Route exact path="/adminmanager" component={AdminManager}/>
                
                <Route exact path="/confirm-purchase/:type/:course_id" component={ConfirmPurchase}/>
                <Route exact path="/return-payment-status/:course_id" component={ReturnPaymentStatus}/>
                
                <Route exact path="/email-verification/:token/:activekey" component={EmailVerified}/>
                
                <Route exact path="/transcript/:user_id/:batch_id/:ts_id" component={Transcript}/>
                <Route exact path="/batch_data/:batch_id" component={BatchData}/>
                <Route exact path="/batch_data_pdf/:batch_id" component={BatchData_Pdf}/>

                <Route exact path="/user_timestamp" component={CourseTimestamp}/>

                <Route exact path="/events/:eventsList_id" component={EventsList}/>

                <Route component={NoMatchPage} />

            </Switch>
            <Footer />
        </div>
        </BrowserRouter>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        profile: state.firebase.profile,
        auth: state.firebase.auth,

        users: state.firestore.ordered.users,
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect([
        { collection: 'users'},
    ])
)(App);