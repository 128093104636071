import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import { updateEmailStatus } from '../../../stores/actions/formAction';

class ManageEmail extends Component{
    
    constructor(props){
        super(props)
        this.state = {
            clickedEmailId: '',
            modalType: '',
            updatedEmailStatus: '',
        
            isLoading: null,
            mesej: null,
        }
        this.baseState = this.state 
    }
    
    componentDidUpdate = (prevProps, prevState) => { 

        const { updateEmailStatusError, updateEmailStatusSuccess } = this.props;

        if(prevProps.updateEmailStatusError !== updateEmailStatusError) {
            this.setState({
                isLoading: false,
                mesej: {
                    msg: updateEmailStatusError.message,
                    type: updateEmailStatusError.type,
                }
            })
        }

        if(prevProps.updateEmailStatusSuccess !== updateEmailStatusSuccess) {
            this.setState({
                isLoading: false,
                mesej: {
                    msg: updateEmailStatusSuccess.message,
                    type: updateEmailStatusSuccess.type,
                }
            })
            setTimeout(() => { 
                this.closeModal();
                this.setState(this.baseState)
            }, 2000);
        }
    } 

    openModal = (id, type) => {
        document.getElementById("modal-email").style.display = "block";
        this.setState({
            clickedEmailId: id,
            modalType: type
        })
    }

    closeModal = () => {
        document.getElementById("modal-email").style.display = "none";
    }

    updateEmailStatus = () => {
        this.setState({
            isLoading:true
        })
        this.props.updateEmailStatus(this.state.updatedEmailStatus, this.state.clickedEmailId)
    }

    render(){

        const { query, users, supportEmail, type } = this.props;
        const { mesej, isLoading } = this.state;

        let hasData = [], isLoadingData = true;

        let query_filter = query.get('filter');

        let userEmail = null, clickedEmailStatus = null;

        let emailListing = supportEmail && supportEmail.map((emel,i) => {

            users && users.map((user,i) => {
                if (emel.userId === user.id) {
                    return userEmail = user.email
                }
                return null
            })

            let statusColor = emel.status === "Sent" ? "badge bg-yellow" 
            : emel.status === "Received" ? "badge bg-blue text-white"
            : emel.status === "Responded" ? "badge bg-purple text-white"
            : emel.status === "Solved" ? "badge bg-green text-white"
            : null

            if(query_filter) {
                if(query_filter === emel.status && emel.type === type){
                    hasData.push(emel)
                    isLoadingData = false;
                    return( 
                        <tr key={i}>
                            <td>{emel.date}<br/>{emel.time}</td>
                            <td className="text-left">{userEmail}</td>
                            <td className="text-left">{emel.subject}</td>
                            <td><span onClick={this.openModal.bind(this, emel.id, 'detail')}><i className="fas fa-eye"/></span></td>
                            <td><span className={statusColor}>{emel.status}</span></td>
                            <td><span onClick={this.openModal.bind(this, emel.id, 'update')}><i className="fas fa-edit"/></span></td>
                        </tr>
                    )
                } else {
                    isLoadingData = false;
                }
            } else {
                if(emel.type === type){
                    hasData.push(emel)
                    isLoadingData = false;
                    return( 
                        <tr key={i}>
                            <td>{emel.date}<br/>{emel.time}</td>
                            <td>{userEmail}</td>
                            <td>{emel.subject}</td>
                            <td><span onClick={this.openModal.bind(this, emel.id, 'detail')}><i className="fas fa-eye"/></span></td>
                            <td><span className={statusColor}>{emel.status}</span></td>
                            <td><span onClick={this.openModal.bind(this, emel.id, 'update')}><i className="fas fa-edit"/></span></td>
                        </tr>
                    )
                } else {
                    isLoadingData = false;
                }
            }
            return null
        });
       

        let emailDetail = supportEmail && supportEmail.map((emel,i) => {

            let userEmail = users && users.map((user,i) => {
                if (emel.userId === user.id) {
                    return user.email
                }
                return null
            })

            if(this.state.clickedEmailId === emel.id){
                clickedEmailStatus = emel.status
                return( 
                    <div key={i} className="text-left">
                        { emel.type === "qna" ?
                            <div className="row">
                                <div className="col col-md-4"><b>Course</b><br/>{emel.qnaCourse}</div>
                                <div className="col col-md-4"><b>Module</b><br/>{emel.qnaModule}</div>
                                <div className="col col-md-4"><b>Topic</b><br/>{emel.qnaTopic}</div>
                            </div>
                        : null }
                        <br/>
                        <div><b>Date / Time:{' '}</b>{emel.date} || {emel.time}</div>
                        <br/>
                        <div><b>Email:{' '}</b>{userEmail}</div>
                        <br/>
                        <div><b>Subject:{' '}</b>{emel.subject}</div>
                        <br/>
                        <div><b>Message:{' '}</b>{emel.message}</div>
                        <br/>
                        {emel.type === "ts" ?
                            <div><b>Attachment</b> <br/>
                                { emel.type === "ts" ?
                                    emel.attachment ?
                                        <img style={{paddingTop:"10px", paddingBottom:"10px"}} src={emel.attachment} alt="" width="20%"/>
                                    :
                                        <p><i>No attachment.</i></p>
                                : null }
                            </div>
                        : null }
                    </div>
                )
            }
            return null
        });

        return(

        <div className="admin">

            <div id="modal-email" className="normalmodal">
                <div className="normalmodal-content col-md-6">
                    <span className="close" onClick={this.closeModal.bind(this)}>&times;</span>

                    <br/><br/>

                    { this.state.modalType === "detail" ?
                        emailDetail
                    : 
                        <div className="text-left">
                            <b>Update Status:</b>
                            <select className="form-control" onChange={e => this.setState({updatedEmailStatus: e.target.value})}>
                                <option defaultValue>{clickedEmailStatus}</option>
                                <option value="Received">Received</option>
                                <option value="Responded">Responded</option>
                                <option value="Solved">Solved</option>
                            </select>

                            { isLoading === true ? 
                                <small>Loading...</small>
                            : 
                                mesej  ?
                                    <small className={mesej.type === 'updateEmailStatusError' ? "text-red" : mesej.type === 'updateEmailStatusSuccess'  ? "text-green" : null }>
                                        {mesej.msg}
                                    </small>
                                : null
                            }

                            <div className="mt-5 row justify-content-end">
                                <button onClick={this.updateEmailStatus.bind(this)} className="btn m-2 btn-outline-black"><i className="fas fa-check-circle"/>{' '}update status</button>
                            </div>
                        </div>
                    }

                </div>
            </div>

            <div className="tab">
                <Link className={query_filter === '' ? "tablinks active" : "tablinks"} to={`/systemmanager?topic=supportEmail&type=${type}&filter=`}>All</Link>
                <Link className={query_filter === 'Sent' ? "tablinks active" : "tablinks"} to={`/systemmanager?topic=supportEmail&type=${type}&filter=Sent`}>Sent</Link>
                <Link className={query_filter === 'Received' ? "tablinks active" : "tablinks"} to={`/systemmanager?topic=supportEmail&type=${type}&filter=Received`}>Received</Link>
                <Link className={query_filter === 'Responded' ? "tablinks active" : "tablinks"} to={`/systemmanager?topic=supportEmail&type=${type}&filter=Responded`}>Responded</Link>
                <Link className={query_filter === 'Solved' ? "tablinks active" : "tablinks"} to={`/systemmanager?topic=supportEmail&type=${type}&filter=Solved`}>Solved</Link>
            </div>

            <div className="tabcontent">
                <div style={{margin:"20px"}}>

                    { isLoadingData === true ? 
                        <p>Please wait... Data loading...</p> 
                    : 
                        hasData.length > 0 ? 
                            <table className="mt-2 table">
                                <thead>
                                    <tr>
                                        <th scope="col">Date</th>
                                        <th scope="col">User Email</th>
                                        <th scope="col">Subject</th>
                                        <th scope="col">Details</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
        
                                <tbody>
                                    {emailListing}
                                </tbody>
                            </table>
                        : 
                            <p>No email available</p>
                    }
                </div>
            </div> 
        </div>
        
        )
    }
}

const mapStateToProps = (state) => {
    return {
        updateEmailStatusError: state.forms.updateEmailStatusError,
        updateEmailStatusSuccess: state.forms.updateEmailStatusSuccess,
    }
  }

const mapDispatchToProps = (dispatch) => {
    return {
      updateEmailStatus: (status, emailId) => dispatch(updateEmailStatus(status, emailId)),
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(ManageEmail);