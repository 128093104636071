import React, { Component } from 'react';
import ReactPlayer from "react-player";
// import moment from 'moment';

class Video extends Component{

    state = {
        isPlay:true,
        // isFinish: false,
        // isFullscreen: false,
        // accelerateType: null,
        // accelerateTime:0.00, //forward/rewind
        // playedSeconds:0.00, //seconds yg bergerak
        // durationTime:0.00, //to display - full vid time
    }

    // //play, pause, fwd, rewind, fullscreen
    // controlVid = (type, value) => {

    //     if(type === "play" || type === "pause" || type === "replay") {
    //         this.setState({ isPlay: value })
    //     }

    //     if (type === "replay") {
    //         this.setState({ isFinish: false })
    //     }

    //     if(type === "fast") {
    //         let addAccelerateTime = this.state.playedSeconds + 5;
    //         this.setState({
    //             accelerateTime: addAccelerateTime,
    //             accelerateType: type
    //         })
    //     } else if (type === "rewind") {
    //         let minusAccelerateTime = this.state.playedSeconds - 5;
    //         this.setState({
    //             accelerateTime:minusAccelerateTime,
    //             accelerateType: type
    //         })
    //     }

    //     if(type === "fast" || type === "rewind") {
    //         setTimeout(() => { 
    //             this.setState({
    //                 accelerateType: ""
    //             })
    //         }, 2000);
    //     }
    

    //     if(type === "hideFullscreen") {
    //         this.setState({
    //             isFullscreen: false
    //         })
    
    //         if (document.exitFullscreen) {
    //             document.exitFullscreen();
    //         } else if (document.webkitExitFullscreen) { /* Safari */
    //             document.webkitExitFullscreen();
    //         } else if (document.msExitFullscreen) { /* IE11 */
    //             document.msExitFullscreen();
    //         }
    //     } else if (type === "showFullscreen") {
    //         this.setState({
    //             isFullscreen: true
    //         })
    //         var elem = document.getElementById("fullscreen-video");
    
    //         if (elem.requestFullscreen) {
    //             elem.requestFullscreen();
    //         } else if (elem.webkitRequestFullscreen) {
    //             elem.webkitRequestFullscreen();
    //         } else if (elem.msRequestFullscreen) {
    //             elem.msRequestFullscreen();
    //         }
    //     }
    // }

    // videoFinish = () => {
    //     this.setState({
    //         isFinish: true,
    //         isPlay: false
    //     })
    // }

    // onProgress = ({ played, playedSeconds, loaded, loadedSeconds }) => {
    //     this.setState({
    //         playedSeconds,
    //     })
    // }

    // onDuration = (duration) => {
    //     this.setState({
    //         durationTime: duration
    //     })
    // }
    
    render(){

        return(

            <div>
                {/* <div  className="col col-md-6"> */}
                    <div className="player-wrapper" id="fullscreen-video">
                        <div style={{width: "100%", height: "50%"}} className="middle yt-cover"></div>

                        <ReactPlayer 
                            className="react-player" 
                            width="100%" 
                            height="100%" 
                            controls={true}  
                            playing={this.state.isPlay} 
                            url={this.props.ytUrl}
                            // onEnded={this.videoFinish.bind()} 
                            // onProgress={this.onProgress.bind(this)}  
                            // onDuration={this.onDuration.bind(this)}
                        />

                        {/* 
                        <div className="control-style">
                            {this.state.isFinish === true && this.state.isPlay === false ?
                                <span onClick={this.controlVid.bind(this, 'replay', true,)} ><i className="fas fa-sync"/></span>
                            : 
                                this.state.isPlay === true ?
                                    <span onClick={this.controlVid.bind(this, 'pause', false,)} ><i className="fas fa-pause"/></span>
                                : 
                                    <span onClick={this.controlVid.bind(this, 'play', true)} ><i className="fas fa-play"/></span>
                            }

                            <span>{moment.unix(this.state.playedSeconds).utc().format('mm [:] ss')} / {moment.unix(this.state.durationTime).utc().format('mm [:] ss')}</span>

                            <span className="float-right">
                                <span title="Backward 5 seconds" onClick={this.controlVid.bind(this, 'rewind', null)} ><i className="fas fa-backward"/></span> 
                                <span title="Fast forward 5 seconds" onClick={this.controlVid.bind(this, 'fast', null)} ><i className="fas fa-forward"/></span>

                                { this.state.isFullscreen === true ?
                                    <span onClick={this.controlVid.bind(this, 'hideFullscreen', null)} ><i className="fas fa-compress"></i></span>
                                :
                                    <span onClick={this.controlVid.bind(this, "showFullscreen", null)} ><i className="fas fa-expand"></i></span>
                                }
                            </span>
                        </div> */}
                    </div>
                {/* </div> */}
            </div>
        
        )
    }
}

export default Video;