const initState = {
    contactUs: [],
    questionAndAnswer: [],
    technicalSupport: [],
}

const formReducer = (state = initState, action ) => {

    switch (action.type) {
        case 'ADDED_CONTACT_US':
            return {
                ...state,
                sendContactSuccess: {
                    message: action.message,
                    type: 'sendContactSuccess'
                }
            }
        case 'ADDED_CONTACT_US_ERROR':
            return {
                ...state,
                sendContactError: {
                    message: action.err.message,
                    type: 'sendContactError'
                }
            }


        case 'SEND_EMAIL':
            return {
                ...state,
                sendEmailSuccess: {
                    message: action.message,
                    type: 'sendEmailSuccess'
                }
            }
        case 'SEND_EMAIL_ERROR':
            return {
                ...state,
                sendEmailError: {
                    message: action.err.message,
                    type: 'sendEmailError'
                }
            }

        case 'UPDATED_EMAIL_STATUS':
            return {
                ...state,
                updateEmailStatusSuccess: {
                    message: action.message,
                    type: 'updateEmailStatusSuccess'
                }
            }
        case 'UPDATED_EMAIL_STATUS_ERROR':
            return {
                ...state,
                updateEmailStatusError: {
                    message: action.err.message,
                    type: 'updateEmailStatusError'
                }
            }

        case 'UPDATED_TECHNICAL_SUPPORT':
            return state;
        case 'UPDATED_TECHNICAL_SUPPORT_ERROR':
            return state;

        case 'UPDATED_QNA':
            return state;
        case 'UPDATED_QNA_ERROR':
            return state;

        default:
            return state;
    }
}

export default formReducer