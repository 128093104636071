import React, { Component } from 'react';
import { connect } from 'react-redux'

import { updatePCB_note } from '../../../../stores/actions/classAction';

class BatchNote extends Component {

    constructor(props){
        super(props)
        this.state = {
            editNote: false,
            batchNote:[],
            getBatchNote: '',
    
            selectedBatchId: '',
        
            isLoading: null,
            message: null,
        }
        this.baseState = this.state 
    }

    componentDidUpdate = (prevProps, prevState) => { 
        const { updateBatchNoteSuccess, updateBatchNoteError } = this.props;
        
        if(prevProps.updateBatchNoteError !== updateBatchNoteError) {
            this.setState({
                isLoading: false,
                message: {
                    msg: updateBatchNoteError.message,
                    type: updateBatchNoteError.type,
                }
            })
        }

        if(prevProps.updateBatchNoteSuccess !== updateBatchNoteSuccess) {
            this.setState({
                isLoading: false,
                message: {
                    msg: updateBatchNoteSuccess.message,
                    type: updateBatchNoteSuccess.type,
                }
            })
            setTimeout(() => { 
                this.setState({
                    editNote: false,
                    message: null
                })
            }, 2000);
        }
    } 

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleNote = (type, note, pcbId) => {
        if(type === "edit") {
            this.setState({ 
                editNote: true, 
                batchNote: note 
            })
        } else if (type === "cancel") {
            this.setState({
                editNote: false,
            })
        } else if (type === "save") {
            this.setState({
                batchNote: [ 
                    ...this.state.batchNote,
                    this.state.getBatchNote, 
                ],
            });
            this.refs.batchNote.value = '';
        } else if (type === "remove") {
            let removed = this.state.batchNote.filter(data => {
                return data !== note
            });
            this.setState({
                batchNote: removed,
            })
        } else if (type === "update") {
            var result = window.confirm("Confirm?");
            if (result) {
                this.setState({
                    isLoading: true
                })
                this.props.updatePCB_note(this.state.batchNote, pcbId);
            }
        } else return null
    }

    render(){

        const { batchNote, batchId } = this.props;
        const { message, isLoading } = this.state;

        return(

            <div className="mt-5"><b>ADMIN NOTE: </b> {' '}<span onClick={this.handleNote.bind(this, 'edit', batchNote, null)}><i><small>**Click to edit</small></i></span><br/>
                { this.state.editNote === false ?
                    batchNote && batchNote.map((note, i) => { 
                        return (<li  style={{marginLeft:"20px"}}key={i}>{note}</li>)
                    })
                :
                    <div className="col-md-12" style={{padding:'10px'}}>
                        <div className='row col-md-12'>
                            <div className='col col-md-11'>
                                <input type="text" className="form-control" ref="batchNote" onChange={event => this.setState({getBatchNote: event.target.value})}  placeholder="Note"/>
                            </div>
                            <div className='col col-md-1 middle'>
                                <span className="badge bg-blue text-white" onClick={this.handleNote.bind(this, 'save', null, null)}><i className='fas fa-plus'/></span>
                            </div>
                        </div>
                        { this.state.batchNote && this.state.batchNote.map((note,i) => {
                            return (
                                <li key={i} style={{margin:'10px 0px 10px 40px'}}>
                                    {note}{' '}
                                    <span onClick={this.handleNote.bind(this, 'remove', note, null)}> <i className="fas fa-trash text-red" /></span>
                                </li>
                            )
                        })} 

                        <br/>
                        
                        { isLoading === true ? 
                            <small>Loading...</small>
                        : 
                            message  ?
                                <small className={message.type === 'updateBatchNoteError' ? "text-red" : message.type === 'updateBatchNoteSuccess'  ? "text-green" : null }>
                                    {message.msg}
                                </small>
                            : null
                        }

                        <div className="row col-md-12 justify-content-end ">
                            <span className="badge bg-purple text-white" onClick={this.handleNote.bind(this, 'update', null, batchId)} ><i className="fas fa-check-circle"/>{' '}Update Note</span>
                            <span className="badge bg-red text-white" onClick={this.handleNote.bind(this, 'cancel', null, null)}> <i className="fas fa-times"/>{' '}Cancel</span>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        updateBatchNoteError: state.classes.updateBatchNoteError,
        updateBatchNoteSuccess: state.classes.updateBatchNoteSuccess,
    }
}
  
const mapDispatchToProps = (dispatch) => {
    return {
        updatePCB_note: (data, pcbId) => dispatch(updatePCB_note(data, pcbId)),
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(BatchNote);