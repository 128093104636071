export const submitFormReqClass = (participant) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => { //return function sbb guna THUNK

        //make asynchronous call to database
        const firestore = getFirestore();

        if(!participant.phone) {
            dispatch({ type:'SUBMITTED_NEW_CLASS_REQ_ERROR', err: { message: 'Input field required.' }});
        } else {
            if(participant.isChange_batchId !== "") {
                firestore.collection('physicalClassParticipants').doc(participant.oldPcpId).update({
                    selectedBatchId: participant.newBatchId,
                }).then(() => { 
                    dispatch({ type:'SUBMITTED_NEW_CLASS_REQ', message: 'Class request has been sent. Please alert for any status update.'}); 
                }).catch((err) => {
                    dispatch({ type:'SUBMITTED_NEW_CLASS_REQ_ERROR', err}); 
                })
            } else {
                firestore.collection('physicalClassParticipants').add({
                    name: participant.name,
                    email: participant.email,
                    phone: participant.phone,
                    date: participant.date,
                    courseId: participant.courseId,
                    userId: participant.userId,
                    status:'requested',
                    selectedBatchId: participant.selectedBatchId,
        
                }).then(() => { 
                    dispatch({ type:'SUBMITTED_NEW_CLASS_REQ', message: 'Class request has been sent. Please alert for any status update.'}); //dah dispatch, g code kat reducer lak
                }).catch((err) => {
                    dispatch({ type:'SUBMITTED_NEW_CLASS_REQ_ERROR', err}); 
                })
            }
        }
    }
}

export const classCustomEmail = (data) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => { 

        const firestore = getFirestore();
        const { to, subject, html} = data;
        
        if(to.length < 1 || !subject || !html) {
            dispatch({ type:'CLASS_CUSTOM_EMAIL_ERROR', err: { message: 'Input field required.' }});
        } else {
            firestore.collection('classCustomEmail').add({
                from: 'Halal Academy <info@halal-academy.com>',
                to: data.to,
                subject: data.subject,
                html: data.html,
                sendDate: data.sendDate,
                selectedBatchId: data.selectedBatchId,
    
            }).then(() => {
                dispatch({ type:'CLASS_CUSTOM_EMAIL', message: `Data processing. Your email will be sent automatically. It may take some time.`}); 
            }).catch((err) => {
                dispatch({ type:'CLASS_CUSTOM_EMAIL_ERROR', err});
            })
        }
    }
}

export const addNewBatch = (batch) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {

        const firestore = getFirestore();
        const { batchCode, batchName, batchAmount, date} = batch;

        if(!batchCode || !batchName || !batchAmount || !date.day1 || !date.day2 || !date.day3 || !date.day4) {
            dispatch({ type:'ADD_NEW_BATCH_ERROR', err: { message: 'Input field required.' }});
        } else {
            firestore.collection('physicalClassBatches').add({
                batchCode: batch.batchCode,
                batchName: batch.batchName,
                batchAmount: batch.batchAmount,
                batchNote: [],
                selectedParticipants: [],
                courseId: batch.selectedCourseId_NewBatch,
                status:'',
                date: batch.date,
                isHide: batch.isHide
    
            }).then(() => {
                dispatch({ type:'ADD_NEW_BATCH', message: 'Batch added successfully.'});
            }).catch((err) => {
                dispatch({ type:'ADD_NEW_BATCH_ERROR', err});
            })
        }
    }
}

export const updatePCB = (batch, pcbId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {

        const firestore = getFirestore();
        const { batchCode, batchName, batchAmount, date} = batch;

        if(!batchCode || !batchName || !batchAmount || !date.day1 || !date.day2 || !date.day3 || !date.day4) {
            dispatch({ type:'UPDATE_PCB_ERROR', err: { message: 'Input field required.' }});
        } else {
            firestore.collection('physicalClassBatches').doc(pcbId).update({
    
                batchCode: batch.batchCode,
                batchName: batch.batchName,
                batchAmount: batch.batchAmount,
                date: batch.date,
    
            }).then(() => {
                dispatch({ type:'UPDATE_PCB', message: 'Batch updated successfully.'});
            }).catch((err) => {
                dispatch({ type:'UPDATE_PCB_ERROR', err});
            })
        }
    }
}

export const isHideBatch = (isHide, pcbId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {

        const firestore = getFirestore();

        firestore.collection('physicalClassBatches').doc(pcbId).update({
            isHide: isHide

        }).then(() => {
            dispatch({ type:'UPDATE_PCB_ISHIDE', message: 'isHide updated successfully.'});
        }).catch((err) => {
            dispatch({ type:'UPDATE_PCB__ISHIDE_ERROR', err});
        })
    }
}

export const updatePCB_status = (data, pcbId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {

        const firestore = getFirestore();

        firestore.collection('physicalClassBatches').doc(pcbId).update({

            status: data.status

        }).then(() => {
            dispatch({ type:'UPDATE_PCB_STATUS', message: `Batch status updated to ${data.status}`});
        }).catch((err) => {
            dispatch({ type:'UPDATE_PCB_STATUS_ERROR', err});
        })

    }
}

export const updatePCB_note = (note, pcbId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {

        const firestore = getFirestore();

        firestore.collection('physicalClassBatches').doc(pcbId).update({

            batchNote: note

        }).then(() => {
            dispatch({ type:'UPDATE_PCB_NOTE', message: `Batch note updated successfully.`});
        }).catch((err) => {
            dispatch({ type:'UPDATE_PCB_NOTE_ERROR', err});
        })

    }
}
export const updatePCB_date = (date, pcbId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {

        const firestore = getFirestore();

        firestore.collection('physicalClassBatches').doc(pcbId).update({

            date: date

        }).then(() => {
            dispatch({ type:'UPDATE_PCB_DATE', message: `Batch date updated successfully.`});
        }).catch((err) => {
            dispatch({ type:'UPDATE_PCB_DATE_ERROR', err});
        })

    }
}

export const updatePCB_selectedParticipants = (data, pcbId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {

        const firestore = getFirestore();

        firestore.collection('physicalClassBatches').doc(pcbId).update({

            selectedParticipants: data.selectedParticipants,

        }).then(() => {
            dispatch({ type:'UPDATE_PCB_SELECTEDPARTICIPANTS', pcbId: pcbId});
        }).catch((err) => {
            dispatch({ type:'UPDATE_PCB_SELECTEDPARTICIPANTS_ERROR', err});
        })

    }
}

export const deletePCB = (batchId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {

        const firestore = getFirestore();

        firestore.collection('physicalClassBatches').doc(batchId).delete()
        .then(() => {
            dispatch({ type:'DELETE_PCB', message: `Batch deleted successfully.`});
        }).catch((err) => {
            dispatch({ type:'DELETE_PCB_ERROR', err});
        })
    }
}

export const deletePCP = (pcpId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {

        const firestore = getFirestore();

        firestore.collection('physicalClassParticipants').doc(pcpId).delete()
        .then(() => {
            dispatch({ type:'DELETE_PCP', message: `Participant deleted successfully.`});
        }).catch((err) => {
            dispatch({ type:'DELETE_PCP_ERROR', err});
        })
    }
}

export const updatePCP_status = (data, pcpId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {

        const firestore = getFirestore();

        firestore.collection('physicalClassParticipants').doc(pcpId).update({

            status: data.status,

        }).then(() => {
            dispatch({ type:'UPDATE_PCP_STATUS', message: 'Status updated successfully.'});
        }).catch((err) => {
            dispatch({ type:'UPDATE_PCP_STATUS_ERROR', err});
        })

    }
}

// export const updatePCP_selectedBatchId = (data, pcpId) => {
//     return (dispatch, getState, {getFirebase, getFirestore}) => {

//         const firestore = getFirestore();

//         firestore.collection('physicalClassParticipants').doc(pcpId).update({

//             selectedBatchId: data.selectedBatchId

//         }).then(() => {
//             dispatch({ type:'UPDATE_PCP_SELECTEDBATCHID', pcpId: pcpId});
//         }).catch((err) => {
//             dispatch({ type:'UPDATE_PCP_SELECTEDBATCHID_ERROR', err});
//         })

//     }
// }

export const updatePCP_status_batchId = (data, pcpId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {

        const firestore = getFirestore();

        firestore.collection('physicalClassParticipants').doc(pcpId).update({

            status: data.status,
            selectedBatchId: data.selectedBatchId,

        }).then(() => {
            dispatch({ type:'UPDATE_PCP_STATUS_BATCHID', message: 'Status, batchId, updated successfully.'});
        }).catch((err) => {
            dispatch({ type:'UPDATE_PCP_STATUS_BATCHID_ERROR', err});
        })

    }
}

export const createGroup = (group) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {

        const firestore = getFirestore();
        const { groupCode, groupName, newAdded, batchId } = group;

        if(!groupCode || !groupName) {
            dispatch({ type:'CREATE_GROUP_ERROR', err: { message: 'Input field required.' }});
        } else {
            firestore.collection('pcpGroup').add({
                groupCode, groupName, addedPcp : newAdded, batchId
            }).then(() => {
                dispatch({ type:'CREATE_GROUP', message: 'Group created successfully.'});
            }).catch((err) => {
                dispatch({ type:'CREATE_GROUP_ERROR', err});
            })
        }
    }
}

export const updateGroup = (group, groupId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {

        const firestore = getFirestore();
        const { groupCode, groupName, newAdded } = group;

        if(!groupCode || !groupName) {
            dispatch({ type:'UPDATE_GROUP_ERROR', err: { message: 'Input field required.' }});
        } else {

            firestore.collection('pcpGroup').doc(groupId).update({
                groupCode, groupName, addedPcp : newAdded
            }).then(() => {
                dispatch({ type:'UPDATE_GROUP', message: 'Group updated successfully.'});
            }).catch((err) => {
                dispatch({ type:'UPDATE_GROUP_ERROR', err});
            })
        }
    }
}

export const deleteGroup = (groupId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {

        const firestore = getFirestore();

        firestore.collection('pcpGroup').doc(groupId).delete()
        .then(() => {
            dispatch({ type:'DELETE_GROUP', message: `Group deleted successfully.`});
        }).catch((err) => {
            dispatch({ type:'DELETE_GROUP_ERROR', err});
        })
    }
}

export const createTranscript = (ts) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {

        const firestore = getFirestore();
        const { userId, batchId, courseId, fname, lname, ic, regno, pa1, pa2, pa3, attendance, status } = ts;

        if (status && status === "save") {

            firestore.collection('transcripts').add({
                userId,
                batchId,
                courseId,
                regno,
                pa: { pa1,pa2,pa3 },
                attendance,
                status
            }).then(() => {
                dispatch({ type:'CREATE_TRANSCRIPT', message: 'Transcript created successfully.'});
            }).catch((err) => {
                dispatch({ type:'CREATE_TRANSCRIPT_ERROR', err});
            })

        } else if (status && status === "publish") {

            if(!fname || !lname || !ic ||!regno || !pa1 || !pa2 || !pa3 || !attendance) {
                dispatch({ type:'CREATE_TRANSCRIPT_ERROR', err: { message: 'Input field required.' }});
            } else {
                firestore.collection('transcripts').add({
                    userId,
                    batchId,
                    courseId,
                    regno,
                    pa: { pa1,pa2,pa3 },
                    attendance,
                    status
                }).then(() => {
                    dispatch({ type:'CREATE_TRANSCRIPT', message: 'Transcript created successfully.'});
                }).catch((err) => {
                    dispatch({ type:'CREATE_TRANSCRIPT_ERROR', err});
                })
            }
        } else {
            dispatch({ type:'CREATE_TRANSCRIPT_ERROR', error: {message: 'Theres and issue, please contact system admin.'}}); 
        }
    }
}

export const updateTranscript = (ts, tsId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {

        const firestore = getFirestore();
        const { fname, lname, ic, regno, pa1, pa2, pa3, attendance, status } = ts;

        if (status && status === "save") {

            firestore.collection('transcripts').doc(tsId).update({
                regno,
                pa: { pa1, pa2, pa3 },
                attendance,
                status
            }).then(() => {
                dispatch({ type:'UPDATE_TRANSCRIPT', message: 'Transcript updated successfully.'});
            }).catch((err) => {
                dispatch({ type:'UPDATE_TRANSCRIPT_ERROR', err});
            })

        } else if (status && status === "publish") {

            if(!fname || !lname || !ic ||!regno || !pa1 || !pa2 || !pa3 || !attendance) {
                dispatch({ type:'UPDATE_TRANSCRIPT_ERROR', err: { message: 'Input field required.' }});
            } else {
                firestore.collection('transcripts').doc(tsId).update({
                    regno,
                    pa: { pa1, pa2, pa3 },
                    attendance,
                    status
                }).then(() => {
                    dispatch({ type:'UPDATE_TRANSCRIPT', message: 'Transcript updated successfully.'});
                }).catch((err) => {
                    dispatch({ type:'UPDATE_TRANSCRIPT_ERROR', err});
                })
            }

        } else {
            dispatch({ type:'UPDATE_TRANSCRIPT_ERROR', error: {message: 'Theres and issue, please contact system admin.'}}); 
        }
    }
}


export const deleteTranscript = (tsId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {

        const firestore = getFirestore();

        firestore.collection('transcripts').doc(tsId).delete()
        .then(() => {
            dispatch({ type:'DELETE_TRANSCRIPT', message: `Transcript deleted successfully.`});
        }).catch((err) => {
            dispatch({ type:'DELETE_TRANSCRIPT_ERROR', err});
        })
    }
}


export const addClassInfo = (data, batchId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {

        const firestore = getFirestore();
        const { loc_link, loc_detail, extra_info } = data;
       
        firestore.collection('extraClassInfo').add({
            loc_link, loc_detail, extra_info, batchId
        }).then(() => {
            dispatch({ type:'ADD_CLASS_INFO', message: 'Class info added successfully.'});
        }).catch((err) => {
            dispatch({ type:'ADD_CLASS_INFO_ERROR', err});
        })
    }
}

export const updateClassInfo = (data, infoId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {

        const firestore = getFirestore();
        const { loc_link, loc_detail, extra_info } = data;

        firestore.collection('extraClassInfo').doc(infoId).update({
            loc_link, loc_detail, extra_info
        }).then(() => {
            dispatch({ type:'UPDATE_CLASS_INFO', message: 'Class info updated successfully.'});
        }).catch((err) => {
            dispatch({ type:'UPDATE_CLASS_INFO_ERROR', err});
        })
    }
}

