import React, { Component } from 'react';
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'

class PrivacyPolicy extends Component{

    render(){

        const { infos } = this.props;

        let isLoading = true;
        let no = 0 ;

        let privacyList = infos && infos.map((info ,i) => {
            if(info.infoType === "PRIVACY POLICY") {
                no = no + 1;
                isLoading = false;
                return (
                    <div key={i} className="hiw-box" style={{margin:"40px 0px 0px 0px"}}>
                        <b className="title">{no}.{' '}{info.infoName}</b>
                        <div className="title-border"></div>
                        <p style={{textAlign:"justify"}} dangerouslySetInnerHTML={{__html: info.infoContent}}></p>
                    </div>
                )
            }
            return null
        });


        return(

        <div className="container col-md-12 min-height justify-content-center middle">
            <div className="col-md-9 text-left page-bgstyle">
                <div className="middle">
                    <div className="col col-md-4" style={{marginBottom:"30px"}}> 
                        <img src="../../../image/HA_LOGO.png" alt="" width="45%"/><br />
                    </div>
                     
                     <h4>POLICIES
                         <div className="title-border"></div>
                     </h4>
                </div>

                {/* <p className="mt-3" style={{textAlign:"justify", marginBottom:"50px"}}>
                    Privacy Policy Halal Academy operates the www.halal-academy.com website, which provides the halal online learning.
                    <br /><br />
                    This page is used to inform website visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service. 
                    <br /> <br />
                    If you choose to use our Service, then you agree to the collection and use of information in relation with this policy. The Personal Information that we collect are used for 
                    providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy. 
                </p>
                
                <div className="divider"></div> */}

                { isLoading === true ? <div className="middle"><div className="loader"></div></div>: privacyList }
            </div>
        </div>
        
        )
    }
}

const mapStateToProps = (state) => {
    return {
      infos: state.firestore.ordered.infos,
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect([
        { collection: 'infos'},
    ])
)(PrivacyPolicy);