import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';

import { deleteQuiz } from '../../../stores/actions/courseAction';

import DisplayQuestion from '../QuestionManager/DisplayQuestion';
import EditQuiz from './EditQuiz'

class DisplayQuiz extends Component{

    state = {
        hideDetails : false,
        hideEdit: true,
        id:'',

        clickedQuestionId:'',
    }
    
    componentDidUpdate = (prevProps, prevState) => { 

        const { deleteQuizError, deleteQuizSuccess } = this.props;

        if(prevProps.deleteQuizError !== deleteQuizError) {
            alert(deleteQuizError.message);
        }

        if(prevProps.deleteQuizSuccess !== deleteQuizSuccess) {
            alert(deleteQuizSuccess.message);
            document.getElementById("clickQuiz").click();
        }
    }

    handleEdit = (quizId) => {
        this.setState({
          hideDetails : true,
          hideEdit: false,
          id: quizId
        })
    }

    cancel = (e) => {
        this.setState({
            id:''
        })
    }

    deleteQuiz = (quizId) => {

        var result = window.confirm("Confirm delete?");
    
        if (result) {
            this.props.deleteQuiz(quizId)
        }
    }

    showModalQuestion = (id) => {
        this.setState({
            clickedQuestionId: id
        })
        document.getElementById("modal-q-quiz").style.display = "block";
    }

    hideModalQuestion = () => {
        this.setState({
            clickedQuestionId: ""
        })

        document.getElementById("modal-q-quiz").style.display = "none";
    }

    render(){

        const { query, questions, modules, courses, quiz, quizId } = this.props;
        
        let query_id = query.get('id');
        let isLoading = true;

        const quizDetails = quiz ? (
            <div key={quiz.id}>
                { isLoading = false }
                { this.state.id === quizId ?
                    <EditQuiz cancel={this.cancel} quiz={quiz} quizId={quizId} questions={questions} />
                :
                    <div>
                        <p className="mt-2"><b>Code: </b>{quiz.quizCode}</p>
                        <p className="mt-2"><b>Name: </b>{quiz.quizName}</p>
                        
                        <p className="mt-2"><b>Questions: </b></p>
                        <div className="border-style">

                            { questions && questions.map(question => {
                                return (
                                    quiz.selectedQuestions && quiz.selectedQuestions.map((selected, i) => { 
                                        if (question.id === selected) {
                                            return (
                                                <span onClick={this.showModalQuestion.bind(this, question.id)} className="badge text-white bg-green" key={i}>{question.questionCode}</span>
                                            )
                                        }
                                        return null
                                    })
                                )
                            })}
                        </div>

                        <div className="mt-5 row justify-content-end">
                            <button type="button" className="btn m-2 btn-outline-black" onClick={this.handleEdit.bind(this, quizId)}><i className="fas fa-edit"></i></button>
                            <button type="button" className="btn m-2 btn-outline-red" onClick={this.deleteQuiz.bind(this, quizId)}><i className="fas fa-trash"></i></button>
                        </div>
                    </div>
                }
            </div>

        ) : (
            null
        )

        return(

            <div className="container col-md-12">

                <div id="modal-q-quiz" className="mymodal">
                    <div className="mymodal-content col-md-6">
                        <span className="close" onClick={this.hideModalQuestion.bind(this)}>&times;</span>
                        <DisplayQuestion viewOnly={true} courses={courses} modules={modules} questionId={this.state.clickedQuestionId}/>
                    </div>
                </div>

                { query_id ?
                    isLoading === true ? <p>Data loading... Please wait...</p> : quizDetails
                : <p>Quiz data not available.</p> }

                <div hidden={true}>
                    <Link id="clickQuiz" to={`/systemmanager?topic=course&subtopic=quiz`} />
                </div>
            </div>
        
        )
    } 
}

const mapStateToProps = (state, ownProps) => {
    const id = ownProps.selectedQuizId;
    const quizzes = state.firestore.data.quizzes;
    const quiz = quizzes ? quizzes[id] : null
    return {
        quiz: quiz,
        quizId: id,
        
        deleteQuizError: state.courses.deleteQuizError,
        deleteQuizSuccess: state.courses.deleteQuizSuccess,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
      deleteQuiz: (quizId) => dispatch(deleteQuiz(quizId))
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(DisplayQuiz)