import React, { Component } from 'react';
import { connect } from 'react-redux'

import { createGroup, updateGroup } from '../../../../stores/actions/classAction';

class CreateGroup extends Component {

    constructor(props){
        super(props)
        this.state = {
            batchId:'',
            newAdded: [],
            pcpList: [],
            groupedPcp: [],

            updateEdit_newAdded: true,
    
            groupCode:'',
            groupName:'',
        
            isLoading: null,
            message: null,
        }
        this.baseState = this.state 
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    UNSAFE_componentWillReceiveProps(props){

        this.setState({ 
            batchId: props.batchId ? props.batchId : '',
            pcpList: props.pcpList ? props.pcpList : [], 
            groupedPcp: props.groupedPcp ? props.groupedPcp : [], 

            groupCode: props.groupData ? props.groupData.groupCode : '',
            groupName: props.groupData ? props.groupData.groupName : '',
        })

        //buat mcm ni sbb nanti dia ada refresh props (amik props lama), admin akan confuse on newAdded listing
        if (props.actionType === "edit" && this.state.updateEdit_newAdded === true) {
            this.setState({ 
                newAdded: props.groupData ? props.groupData.addedPcp : [],
            })
        }
    }
    
    componentDidUpdate = (prevProps, prevState) => { 
        const { createGroupError, createGroupSuccess, updateGroupError, updateGroupSuccess } = this.props;

        if(prevProps.createGroupError !== createGroupError) {
            this.setState({
                isLoading: false,
                message: {
                    msg: createGroupError.message,
                    type: createGroupError.type,
                }
            })
        }

        if(prevProps.createGroupSuccess !== createGroupSuccess) {
            this.setState({
                isLoading: false,
                message: {
                    msg: createGroupSuccess.message,
                    type: createGroupSuccess.type,
                }
            })
            setTimeout(() => { 
                this.setState({
                    groupCode:'',
                    groupName:'',
                    newAdded:[],
                    message:'',
                    isLoading:''
                })
            }, 2000);
        }

        if(prevProps.updateGroupError !== updateGroupError) {
            this.setState({
                isLoading: false,
                message: {
                    msg: updateGroupError.message,
                    type: updateGroupError.type,
                }
            })
        }

        if(prevProps.updateGroupSuccess !== updateGroupSuccess) {
            this.setState({
                isLoading: false,
                message: {
                    msg: updateGroupSuccess.message,
                    type: updateGroupSuccess.type,
                }
            })
            setTimeout(() => { 
                this.setState({
                    isLoading: null,
                    message: null,
                })
            }, 2000);
        }
    } 

    groupAction = (type, data) => {
        
        const { newAdded } = this.state;

        let array = null, groupedPcp = this.state.groupedPcp, pcpList = this.state.pcpList;

        if(type === 'add') {

            //add newAdded data into array
            array = [...newAdded, data];

            //remove  pcpList data from array
            pcpList = pcpList.filter(item => { return item.userId !== data.userId })
            
        } else {

            //remove newAdded data from array
            array = newAdded.filter(item => { return item.userId !== data.userId })

            //add pcpList data into array
            //add into array ONLYIF not same w current data
            let hasData = pcpList && pcpList.find(elem => elem.userId === data.userId);
            if(!hasData) { pcpList = [...pcpList, data] }

            //update changes on grouped pcp (sbb data tak update on db lagi, so kena update on state)
            groupedPcp = groupedPcp.filter(item => { return item.userId !== data.userId })
        }

        this.setState({
            newAdded: array,
            pcpList,
            groupedPcp
        })
    }

    createGroup = () => {
        var result = window.confirm("Confirm create?");
        if (result) {
            this.setState({
                isLoading: true
            })
            this.props.createGroup(this.state);
        }
    }

    updateGroup = () => {
        var result = window.confirm("Confirm update?");
        if (result) {
            this.setState({
                isLoading: true,
                updateEdit_newAdded: false
            })
            this.props.updateGroup(this.state, this.props.selectedGroupId);
        }
    }

    render () {

        const { actionType } = this.props;
        const { message, isLoading, groupCode, groupName, newAdded, pcpList, groupedPcp } = this.state;

        let addedUid =  [];

        groupedPcp && groupedPcp.map(added => {
            return addedUid.push(added.userId)
        })

        //get ungrouped user
        let dispToSelect = pcpList && pcpList
            .filter(d => !addedUid.includes(d.userId))
            .map((data,i) => {
                let no = i+1;
                return (
                    <tr key={i}>
                        <td>{no}</td>
                        <td className='text-left'>{data.fname}</td>
                        <td className='text-left'>{data.email}</td>
                        <td className='text-left'>{data.company}</td>
                        <td>
                            <span onClick={this.groupAction.bind(this, 'add', data)}>
                                <i className='fas fa-plus text-green' />
                            </span>
                        </td>
                    </tr>
                )
            });

        return (

            <div>

                 <b className="uppercase middle">
                     
                    { actionType && actionType === "edit" ? 'Update Group' : 'Create Group' }
                    <div className="title-border"></div>
                </b>
                
                <div className="mt-2 row">
                    <p className="col col-md-2">
                        <b>Code:</b>
                        { actionType === "edit" ?
                            <input id="groupCode" placeholder='Group code' defaultValue={groupCode} onChange={this.handleChange} type="text" className="form-control" />
                        : 
                            <input id="groupCode" placeholder='Group code' value={groupCode}  onChange={this.handleChange} type="text" className="form-control" />
                        }
                    </p>

                    <p className="col">
                        <b>Name:</b>
                        { actionType === "edit" ?
                            <input id="groupName" placeholder='Group name' defaultValue={groupName} onChange={this.handleChange} type="text" className="form-control" />
                        : 
                            <input id="groupName" placeholder='Group name' value={groupName} onChange={this.handleChange} type="text" className="form-control" />
                        }
                    </p>
                </div>

                <div style={{marginTop:'30px'}}>
                    <b>Batch's Participants:</b>
                    <table className="table">
                        <thead>
                            <tr className='bg-red border-0 text-white'>
                                <th>No.</th>
                                <th className="text-left">Name</th>
                                <th className="text-left">Email</th>
                                <th className="text-left">Company</th>
                                <th>Action</th>
                            </tr>
                        </thead>

                        <tbody>
                            {dispToSelect}
                        </tbody>
                    </table>
                </div>

                <div style={{marginTop:'30px'}}>
                    <b>Added Participants:</b>
                    <table className="table">
                        <thead>
                            <tr className='bg-green border-0 text-white'>
                                <th>No.</th>
                                <th className="text-left">Name</th>
                                <th className="text-left">Email</th>
                                <th className="text-left">Company</th>
                                <th>Action</th>
                            </tr>
                        </thead>

                        <tbody>
                            { newAdded && newAdded.map((data,i) => {
                                let no = 1;
                                no = no+i;
                                return (
                                    <tr key={i}>
                                        <td>{no}</td>
                                        <td className="text-left">{data.fname} {data.lname}</td>
                                        <td className="text-left">{data.email}</td>
                                        <td className="text-left">{data.company}</td>
                                        <td>
                                            <span onClick={this.groupAction.bind(this, 'remove', data)}>
                                                <i className='fas fa-times text-red' />
                                            </span>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>

                { isLoading === true ? 
                    <small>Loading...</small>
                : 
                    message  ?
                        <small className=
                            {message.type === 'createGroupError' || message.type === 'updateGroupError'? 
                                "text-red" 
                            : message.type === 'createGroupSuccess' || message.type === 'updateGroupSuccess'  ? 
                                "text-green" 
                            : null }
                        >

                            {message.msg}
                        </small>
                    : null
                }

                { actionType && actionType === "edit" ?
                    <button onClick={this.updateGroup.bind(this)} className='mt-5 float-right btn all-btn'><i className="fas fa-check-circle" />{' '}Update</button>
                :
                    <button onClick={this.createGroup.bind(this)} className='mt-5 float-right btn all-btn'><i className="fas fa-check-circle" />{' '}Create</button>
                }
            </div>

        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        createGroupError: state.classes.createGroupError,
        createGroupSuccess: state.classes.createGroupSuccess,

        updateGroupError: state.classes.updateGroupError,
        updateGroupSuccess: state.classes.updateGroupSuccess,
    }
}
  
const mapDispatchToProps = (dispatch) => {
    return {
      createGroup: (group) => dispatch(createGroup(group)),
      updateGroup: (group, groupId) => dispatch(updateGroup(group, groupId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateGroup);