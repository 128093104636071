import React, { Component } from 'react';
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import { NavLink } from 'react-router-dom';

class Footer extends Component{

    render(){

        return(

        <div className="footer">

            <div className="text-center" >
                <NavLink to="/termsandconditions"><b>Terms & Conditions</b></NavLink> {' '}|{' '}
                <NavLink to="/privacypolicy"><b>Policies</b></NavLink>
                <br/>
                <label>info@halal-academy.com | 03-5523 4554 </label>
               <div><i className="far fa-copyright"></i> 2021 GAE Resources Sdn Bhd (333176U)</div>
            </div>
        </div>

        
        )
    }
        
}


const mapStateToProps = (state) => {
    return {
      infos: state.firestore.ordered.infos,
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect([
        { collection: 'infos'},
    ])
)(Footer);