import React, { Component } from 'react';
import { connect } from 'react-redux'
import { addHiw } from '../../../stores/actions/infoAction';

class HowItWork extends Component{
    
    constructor(props){
        super(props)
        this.state = {
            infoType:'HOW IT WORK',
            infoName:'',
            infoNumber:'',
            infoContent:'',
    
            isLoading: null,
            message: null,
        }
        this.baseState = this.state 
    }
    
    componentDidUpdate = (prevProps, prevState) => { 
        const { addHiwError, addHiwSuccess } = this.props;

        if(prevProps.addHiwError !== addHiwError) {
            this.setState({
                isLoading: false,
                message: {
                    msg: addHiwError.message,
                    type: addHiwError.type,
                }
            })
        }

        if(prevProps.addHiwSuccess !== addHiwSuccess) {
            this.setState({
                isLoading: false,
                message: {
                    msg: addHiwSuccess.message,
                    type: addHiwSuccess.type,
                }
            })
            setTimeout(() => { 
                this.setState(this.baseState)
                this.props.hide();
            }, 2000);
        }
    } 

    handleChange = (e) => {
        this.setState({
            [e.target.id]:  e.target.valueAsNumber || e.target.value
        })
    }

    addHiw = () => {
        var result = window.confirm("Confirm add?");

        if (result) {
            this.setState({
                isLoading: true
            })
            this.props.addHiw(this.state);
        }
    }


    render(){
        
        const { message, isLoading } = this.state;

        return(

        <div>

            <input type="number" id="infoNumber" onChange={this.handleChange.bind(this)} className="col-md-3 form-control" value={this.state.infoNumber} placeholder="Step Number"/>
            <input type="text" id="infoName" onChange={this.handleChange.bind(this)} className="form-control" value={this.state.infoName} placeholder="Name"/>
            <textarea type="text" id="infoContent" onChange={this.handleChange.bind(this)} className="form-control" value={this.state.infoContent} placeholder="Content"/>
           
            { isLoading === true ? 
                <small>Loading...</small>
            : 
                message  ?
                    <small className={message.type === 'addHiwError' ? "text-red" : message.type === 'addHiwSuccess'  ? "text-green" : null }>
                        {message.msg}
                    </small>
                : null
            }

            <div className="mt-5 row justify-content-end">
                <button className="btn m-2 btn-outline-black" onClick={this.addHiw.bind(this)}><i className="fas fa-plus-circle"/>{' '}add how it work</button>
            </div>
        </div>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        addHiwError: state.infos.addHiwError,
        addHiwSuccess: state.infos.addHiwSuccess,
    }
}
  
const mapDispatchToProps = (dispatch) => {
    return {
      addHiw: (privacy) => dispatch(addHiw(privacy))
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(HowItWork);