import React, { Component } from 'react';
import { connect } from 'react-redux'
import { compose } from 'redux'
import { storage } from '../../../config/fbConfig'

import { updateProgram } from '../../../stores/actions/courseAction';

class EditProgram extends Component{

    constructor(props){
        super(props)
        this.state = {
            programName: this.props.program.programName,
            programCode: this.props.program.programCode,
            programStatus: this.props.program.programStatus,
            programContent: this.props.program.programContent,
            programImage: this.props.program.programImage,
    
            selectedCourses: this.props.program.selectedCourses,
    
            imageNew:null,
            progress:'',
            imageDisplay: null,
            oldImageHide: false,
            newImageHide: true,
    
            searchCourse:'',
            selectedCourseCode:'',
    
            isLoading: null,
            message: null,
        }
        this.baseState = this.state 
    }
    
    componentDidUpdate = (prevProps, prevState) => { 

        const { updateProgramError, updateProgramSuccess } = this.props;

        if(prevProps.updateProgramError !== updateProgramError) {
            this.setState({
                isLoading: false,
                message: {
                    msg: updateProgramError.message,
                    type: updateProgramError.type,
                }
            })
        }

        if(prevProps.updateProgramSuccess !== updateProgramSuccess) {
            this.setState({
                isLoading: false,
                message: {
                    msg: updateProgramSuccess.message,
                    type: updateProgramSuccess.type,
                }
            })
            setTimeout(() => { 
                this.props.cancel();
            }, 2000);
        }
    } 

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    updateProgram = async (e) => {

        var result = window.confirm("Confirm update?");
    
        if (result) {
            this.setState({
                isLoading: true
            })
            if (this.state.imageNew !== null) {
                const { imageNew } = this.state;
                const uploadTask = storage.ref(`images/${imageNew.name}`).put(imageNew);
        
                    uploadTask.on('state_changed', 
                        (snapshot) => {
                            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                            this.setState({
                                progress});
                        }, 
        
                        (error) => {
                            console.log(error);
                        }, 
        
                        () => {
                            storage.ref('images').child(imageNew.name).getDownloadURL().then(imageUrl => {
                                this.setState({
                                    programImage: {
                                        fileUrl: imageUrl,
                                        fileName: imageNew.name
                                    }
                                });
                                
                                this.props.updateProgram(this.state, this.props.program_id)
                            })
                        }
                    );
            //save vid lama je
            } else {
                this.props.updateProgram(this.state, this.props.program_id)
            }
        }
    }

    selectCourse = (id, code) => {
        this.setState({
            selectedCourses: [ 
                ...this.state.selectedCourses, 
                id //get selected option ID
            ],
            selectedCourseCode: code
        });
    }

    removeCourse = (id) => {
        let removed = this.state.selectedCourses.filter(selected => {
          return selected !== id
        })
    
        this.setState({
            selectedCourses: removed
        })
    }

    imageSelectHandler = event => {
        this.setState({ 
            imageNew: event.target.files[0],
            imageDisplay: URL.createObjectURL(event.target.files[0]),
            oldImageHide: true,
            newImageHide: false
        })
    }



    render(){

        const { courses, program } = this.props;
        const { message, isLoading } = this.state;

        let filteredCourses = null;

        if(this.state.searchCourse.length > 0) { //filter user: search user by email
            filteredCourses = courses && courses.filter(course => {
                return course.courseCode.toLowerCase().includes(this.state.searchCourse.toLowerCase()) || 
                course.courseName.toLowerCase().includes(this.state.searchCourse.toLowerCase())
            })
        } else {
            filteredCourses = courses;
        }
        
        return(

            <div>      

                <div className="row"> 
                    <p className="col col-md-4">
                        <b>Code:</b>
                        <input type="text" id="programCode" onChange={this.handleChange} defaultValue={this.state.programCode} className="form-control"/>
                    </p>

                    <p className="col col-md-4">
                        <b>Name:</b>
                        <input type="text" id="programName" onChange={this.handleChange} defaultValue={this.state.programName} className="form-control"/>
                    </p>

                    <p className="col col-md-4">
                        <b>Status:</b>
                        <select id="programStatus" onChange={this.handleChange} className="form-control">
                            <option defaultValue>{this.state.programStatus}</option>
                            <option>Active</option>
                            <option>Not Active</option>
                        </select>
                    </p>
                </div>

                <div className="mt-4">
                    <b>Courses: </b>
                    <div type="text" className="border-black">
                        { courses && courses.map(course => {
                            return (
                                this.state.selectedCourses && this.state.selectedCourses.map((selected, i) => { 
                                    if (course.id === selected) {
                                        return (
                                            <span className="badge bg-green text-white" key={i}>
                                                {course.courseCode} {' '}
                                                    
                                                <span onClick={this.removeCourse.bind(this, selected)} >
                                                    <i className="fas fa-times"></i>
                                                </span> 
                                            </span>
                                        )
                                    }
                                    return null
                                })
                            )
                        })}
                    </div>
                    
                    <small>Choose course here:</small>

                    <br/>

                    <div className="row col-md-5 btn-group dropdown">
                        <button type="button" className="btn border-black dropdown-toggle" data-toggle="dropdown">
                            { this.state.selectedCourseCode ? this.state.selectedCourseCode : 'Choose Course'}
                        </button>

                        <div className="dropdown-menu" >
                            <span className="dropdown-item" onClick={this.selectCourse.bind(this, '', '')} disabled>COURSE</span>
                            <span className="dropdown-item"><input placeholder="Search code..." className="form-control"  onChange={event => this.setState({searchCourse: event.target.value})}/></span>
                            { filteredCourses && filteredCourses.map((course,i) => {
                                if (course.courseType === "Professional") {
                                    return (
                                        <span key={i} className="dropdown-item" onClick={this.selectCourse.bind(this, course.id, course.courseCode)}>{course.courseCode} - {course.courseName}</span>
                                    )
                                }
                                return null
                            })}
                        </div>
                    </div>
                </div>

                <p>
                    <b>Content:</b>
                    <textarea id="programContent" type="text" className="form-control"  onChange={this.handleChange} defaultValue={this.state.programContent} />
                </p>

                <div className="mt-4 mb-5">
                    <b>Featured Image:</b><br />
                    <div hidden={this.state.oldImageHide}>
                        { program.programImage ?
                            <div>
                                <img src={program.programImage.fileUrl} alt="program" width="30%" className="text-center"/><br/>
                                <label>{program.programImage.fileName}</label>
                            </div>
                        : 
                            'No selected image.'
                        }
                    </div>

                    <img hidden={this.state.newImageHide} src={this.state.imageDisplay} alt="program" width="30%" className="text-center"/><br/>

                    <input className="mt-2" type="file"  onChange={this.imageSelectHandler.bind(this)}/><br />
                    <progress value={this.state.progress} max="100" style={{width:"250px"}}/>
                </div>

                { isLoading === true ? 
                    <small>Loading...</small>
                : 
                    message  ?
                        <small className={message.type === 'updateProgramError' ? "text-red" : message.type === 'updateProgramSuccess'  ? "text-green" : null }>
                            {message.msg}
                        </small>
                    : null
                }

                <div className="mt-5 row justify-content-end">
                    <button type="button" className="btn m-2 btn-outline-black" onClick={this.updateProgram.bind(this)}><i className="fas fa-check-circle"/>{' '}update</button>
                    <button type="button" className="btn m-2 btn-outline-black" onClick={this.props.cancel.bind(this)}><i className="fas fa-times"/>{' '}cancel</button>
                </div>
            </div>

        )
    }
}

const mapStateToProps = (state) => {
  return {
      updateProgramError: state.courses.updateProgramError,
      updateProgramSuccess: state.courses.updateProgramSuccess,
  }
}

const mapDispatchToProps = (dispatch) => {
    return {
      updateProgram: (updatedProgram, program_id) => dispatch(updateProgram(updatedProgram, program_id)),
    }
}
  
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
)(EditProgram);