import React, { Component } from 'react';
import {Link } from 'react-router-dom'
import { connect } from 'react-redux';
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'

class CourseDetail extends Component{

    state = {
        selectedCourseId:'',
        selectedModuleId:[],
        selectedTopicId:[],
        selectedSubtopicId:'',

        topicId: [],

        clickedModuleId:'',
        hideModule: false,

        hideOverview: false,
        hideRequirement: false,
        hideContent: false,
        hideOverview_course: false,
        hideOverview_learn: false
    }

    showModule = (courseId) => {
        this.setState({
            clickedModuleId: courseId,
            hideModule: !this.state.hideModule
        })
    }

    openDetail = (type) => {

        if(type === 'overview') {
            this.setState({hideOverview: false})
        } else if(type === 'content') {
            this.setState({hideContent: false})
        } else if(type === 'requirement') {
            this.setState({hideRequirement: false})
        } else if(type === 'overview-course') {
            this.setState({hideOverview_course: false})
        } else if(type === 'overview-learn') {
            this.setState({hideOverview_learn: false})
        }
    }

    closeDetail = (type, courseId) => {
        if(type === 'overview') {
            this.setState({hideOverview: true})
        } else if(type === 'content') {
            this.setState({hideContent: true})
        } else if(type === 'requirement') {
            this.setState({hideRequirement: true})
        } else if(type === 'overview-course') {
            this.setState({hideOverview_course: true})
        } else if(type === 'overview-learn') {
            this.setState({hideOverview_learn: true})
        }
    }


    render(){

        const { auth, course, courses, modules, topics, programs } = this.props;
        const { hideOverview, hideContent, hideRequirement, hideModule, hideOverview_course, hideOverview_learn, clickedModuleId } = this.state;
        
        const query = new URLSearchParams(this.props.location.search);

        const program_id =  this.props.match.params.program_id;
        let course_id =  this.props.match.params.course_id;

        let query_isEnrolledProf, query_isDoneEnrolledFlexi, query_isEnrolledType; //query professional list page
        let query_isEnrolledModule, query_isAvailableNextModule, query_isLockedNextModule; //query flexi list page

        if(course && course.courseType === "Professional") {
            query_isEnrolledProf = query.get('isEnrolledProf');
            query_isDoneEnrolledFlexi = query.get('isDoneEnrolledFlexi');
            query_isEnrolledType = query.get('isEnrolledType');
        } else if(course && course.courseType === "Flexi") {
            query_isEnrolledModule = query.get('isEnrolledModule');
            query_isAvailableNextModule = query.get('isAvailableNextModule');
            query_isLockedNextModule = query.get('isLockedNextModule');
        }

        //utk ubah enroll button if dia dah enroll prof/flexi
        let enrollLink = null, enrollName = null;

        //knp "null" is string sbb from query, query akan convert any data type to string
        // === NULL sbb dia xde enrollment data (belum enrolled course ni)
        if(query_isEnrolledType === "null") {
            enrollLink = "/confirm-purchase/new/" + course_id;
            enrollName = "Enroll Now";
        } else {
            if (query_isEnrolledType === "Professional" && query_isEnrolledProf === "false") {
                enrollLink = "/confirm-purchase/new/" + course_id;
                enrollName = "Enroll Now";
            } else if (query_isEnrolledType === "Flexi" && query_isDoneEnrolledFlexi === "false") {
                enrollLink = `/program/${program_id}/course/${course_id}/flexi`;
                enrollName = "Enroll Now - Flexi";
            }
        }


        let isLoading = true;

        const courseDetails = course ? (
            <div className="course-detail middle">
                { isLoading = false }
                <div className="row col-md-12">

                    <div className="col middle">
                        { course.courseAttach ?
                            course.courseAttach.fileType === "image" ?
                                <img alt="" height="160px" src={course.courseAttach.fileUrl} />
                            : course.courseAttach.fileType === "video" ?
                                <video src={course.courseAttach.fileUrl} width="100%" type="video/mp4" controls />
                            : 
                                <img alt="" height="160px" src="/image/LOGO2.png" />
                        : 
                            <img alt="" height="160px" src="/image/LOGO2.png" />
                        }
                    </div>

                    <div className="text-left middle-not-center col col-md-6">

                        { programs && programs.map((program,i) => {
                            if(program.id === program_id) {
                                return <b key={i} style={{width:"fit-content", margin:"0px 0px 10px 0px"}} className="badge bg-green text-white">{program.programName}</b>
                            }
                            return null
                        })}

                        { courses && courses.map((cs,i) => {
                            if(cs.id === course_id) {
                                return <h5 key={i}>{cs.courseName}</h5>
                            }
                            return null
                        })}

                        <b>{course.enrollCount ? `${course.enrollCount} enrollments` : null}</b>
                    </div>

                    <div className="col middle">
                            <h1>RM{course.coursePrice}</h1>

                            { !auth.uid ? 
                                <button className="btn btn-enroll-disable"><i className="fas fa-sign-in-alt"></i>{' '}Login to Enroll</button> 
                            :
                                course && course.courseType === "Professional" ? 
                                    query_isDoneEnrolledFlexi === "true" || query_isEnrolledProf === "true" ?
                                        <button className="btn btn-enroll-disable"><i className="fas fa-check-circle"></i>{' '}Enrolled</button> 
                                    :
                                        <Link to={enrollLink}>
                                            <button className="pushable">
                                                <span className="shadow"></span>
                                                <span className="edge"></span>
                                                <span className="front">
                                                {enrollName} {' '}<i className="fas fa-shopping-cart"></i>
                                                </span>
                                            </button>
                                        </Link>
                                : 
                                    query_isEnrolledModule === "true" ?
                                        <button className="btn btn-enroll-disable"><i className="fas fa-check-circle"></i>{' '}Enrolled</button> 
                                    :
                                       query_isAvailableNextModule === "true" ?
                                            <Link to={"/confirm-purchase/new/" + course_id}>
                                                <button className="pushable">
                                                    <span className="shadow"></span>
                                                    <span className="edge"></span>
                                                    <span className="front">
                                                    Enroll Now {' '}<i className="fas fa-shopping-cart"></i>
                                                    </span>
                                                </button>
                                            </Link>
                                        :
                                            query_isLockedNextModule === "true" ?
                                                <button className="btn btn-enroll-disable"><i className="fas fa-times-circle"></i> {' '}Enroll previous first</button>
                                            :
                                                <Link to={"/confirm-purchase/new/" + course_id}>
                                                    <button className="pushable  col-md-12">
                                                        <span className="shadow"></span>
                                                        <span className="edge"></span>
                                                        <span className="front">
                                                            Enroll Now{' '}<i className="fas fa-shopping-cart"></i>
                                                        </span>
                                                    </button>
                                                </Link>
                            }
                    </div>
                </div>

                <div className="row col-md-12">
                    {/* <span className="detail-divider"></span> */}
                    <div className="detail-btn">
                        <label>Overview</label>
                        <span 
                            onClick={hideOverview === true ? this.openDetail.bind(this, 'overview') : this.closeDetail.bind(this, 'overview')}>
                            <i className={hideOverview === true ? "fas fa-angle-down" : "fas fa-minus"} />
                        </span>
                        <div className="content" hidden={hideOverview}>
                            <div className="detail-btn">
                                <label>Course Overview</label>
                                <span 
                                    onClick={hideOverview_course === true ? this.openDetail.bind(this, 'overview-course') : this.closeDetail.bind(this, 'overview-course')}>
                                    <i className={hideOverview_course === true ? "fas fa-angle-down" : "fas fa-minus"} />
                                </span>
                                <div className="content" hidden={hideOverview_course}>
                                    {course.courseOverview && course.courseOverview.map((overview,i) => {
                                        return (
                                            <div key={i} className="listing"> 
                                                <div className="list-icon"><li/></div>
                                                <p dangerouslySetInnerHTML={{__html: overview}} style={{margin:"0px"}}></p>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className="detail-btn">
                                <label>What You Will Learn</label>
                                <span 
                                    onClick={hideOverview_learn === true ? this.openDetail.bind(this, 'overview-learn') : this.closeDetail.bind(this, 'overview-learn')}>
                                    <i className={hideOverview_learn === true ? "fas fa-angle-down" : "fas fa-minus"} />
                                </span>
                                <div className="content" hidden={hideOverview_learn}>
                                    {course.courseLearn && course.courseLearn.map((learn,i) => {
                                        return (
                                            <div key={i} className="listing"> 
                                                <div className="list-icon"><li/></div>
                                                <p dangerouslySetInnerHTML={{__html: learn}} style={{margin:"0px"}}></p>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="detail-btn">
                        <label>Course Content</label>
                        <span 
                            onClick={hideContent === true ? this.openDetail.bind(this, 'content') : this.closeDetail.bind(this, 'content')}>
                            <i className={hideContent === true ? "fas fa-angle-down" : "fas fa-minus"} />
                        </span>
                        <div className="content" hidden={hideContent}>
                            { course.courseType === "Professional" ?
                                course.selectedModules && course.selectedModules.map((selected,i) => {
                                    return (
                                        modules && modules.map(module => {
                                            if(selected === module.id) {
                                                return (
                                                    <div key={i} className="detail-btn">
                                                        <label>{module.moduleName}</label>
                                                        <span 
                                                            onClick={this.showModule.bind(this, module.id)}>
                                                            <i className={hideModule === false && module.id === clickedModuleId ? "fas fa-minus" : "fas fa-angle-down"} />
                                                        </span>
                                                        {module.id === clickedModuleId ? 
                                                            <div className="content" hidden={hideModule === false ? false : true}>
                                                                { module && module.selectedTopics.map((selectedTopic,i) => {
                                                                    return (
                                                                        topics && topics.map(topic => {
                                                                            if (topic.id === selectedTopic) {
                                                                                return (
                                                                                    <div key={i} className="listing"> 
                                                                                        <div className="list-icon"><li/></div>
                                                                                        <p style={{margin:"0px"}}>{topic.topicName}</p>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                            return null
                                                                        })
                                                                    )
                                                                })}
                                                            </div>
                                                        :''}
                                                    </div>
                                                )
                                            }
                                            return null
                                        })
                                    )
                                })
                            : course.courseType === "Flexi" ?
                                modules && modules.map((module,i) => {
                                    if(course.selectedModule === module.id) {
                                        return (
                                            <div key={i} className="detail-btn">
                                                <label>{module.moduleName}</label>
                                                <span 
                                                    onClick={this.showModule.bind(this, module.id)}>
                                                    <i className={hideModule === false && module.id === clickedModuleId ? "fas fa-minus" : "fas fa-angle-down"} />
                                                </span>
                                                {module.id === this.state.clickedModuleId ? 
                                                    <div className="content" hidden={hideModule}>
                                                        { module && module.selectedTopics.map((selectedTopic,i) => {
                                                            return (
                                                                topics && topics.map(topic => {
                                                                    if (topic.id === selectedTopic) {
                                                                        return (
                                                                            <div key={i} className="listing"> 
                                                                                <div className="list-icon"><li/></div>
                                                                                <p style={{margin:"0px"}}>{topic.topicName}</p>
                                                                            </div>
                                                                        )
                                                                    }
                                                                    return null
                                                                })
                                                            )
                                                        })}
                                                    </div>
                                                :''}
                                            </div>
                                        )
                                    }
                                    return null
                                })
                            : null }
                        </div>
                    </div>
                    <div className="detail-btn">
                        <label>Requirements</label>
                        <span 
                            onClick={hideRequirement === true ? this.openDetail.bind(this, 'requirement') : this.closeDetail.bind(this, 'requirement')}>
                            <i className={hideRequirement === true ? "fas fa-angle-down" : "fas fa-minus"} />
                        </span>
                        <div className="content" hidden={hideRequirement}>
                            {course.courseRequirement && course.courseRequirement.map((requirement,i) => {
                                return (
                                    <div key={i} className="listing"> 
                                        <div className="list-icon"><li/></div>
                                        <p dangerouslySetInnerHTML={{__html: requirement}} style={{margin:"0px"}}></p>
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                    { !auth.uid ? 
                        <button className="btn btn-enroll-disable"><i className="fas fa-sign-in-alt"></i>{' '}Login to enroll</button> 
                    :
                        course && course.courseType === "Professional" ? 
                            query_isDoneEnrolledFlexi === "true" || query_isEnrolledProf === "true" ?
                                <button className="btn btn-enroll-disable"><i className="fas fa-check-circle"></i>{' '}Enrolled</button> 
                            :
                                <Link to={enrollLink}>
                                    <button className="pushable">
                                        <span className="shadow"></span>
                                        <span className="edge"></span>
                                        <span className="front">
                                        {enrollName} {' '}<i className="fas fa-shopping-cart"></i>
                                        </span>
                                    </button>
                                </Link>
                        : 
                            query_isEnrolledModule === "true" ?
                                <button className="btn btn-enroll-disable"><i className="fas fa-check-circle"></i>{' '}Enrolled</button> 
                            :
                                query_isAvailableNextModule === "true" ?
                                    <Link to={"/confirm-purchase/new/" + course_id}>
                                        <button className="pushable">
                                            <span className="shadow"></span>
                                            <span className="edge"></span>
                                            <span className="front">
                                            Enroll Now {' '}<i className="fas fa-shopping-cart"></i>
                                            </span>
                                        </button>
                                    </Link>
                                :
                                    query_isLockedNextModule === "true" ?
                                        <button className="btn btn-enroll-disable"><i className="fas fa-times-circle"></i> {' '}Enroll previous module</button>
                                    :
                                        <Link to={"/confirm-purchase/new/" + course_id}>
                                            <button className="pushable  col-md-12">
                                                <span className="shadow"></span>
                                                <span className="edge"></span>
                                                <span className="front">
                                                    Enroll Now{' '}<i className="fas fa-shopping-cart"></i>
                                                </span>
                                            </button>
                                        </Link>
                    }

                </div>
            </div>
        ) : (
            <div className="middle min-height">Course not available.</div>
        )
            
        
        return(
            
            <div className="container col-md-12 justify-content-center middle min-height">
                <div className="page-bgstyle col-md-10">

                    { course_id ?
                        isLoading === true ? <div className="loader" /> : courseDetails
                    : <p>Course detail not available.</p> }
                </div>
            </div>
        
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const id = ownProps.match.params.course_id;
    const courses = state.firestore.data.courses;
    const course = courses ? courses[id] : null

    return {
        auth: state.firebase.auth,

        course: course,
        course_id: id,
        courses: state.firestore.ordered.courses,
        modules: state.firestore.ordered.modules,
        topics: state.firestore.ordered.topics,
        programs: state.firestore.ordered.programs,
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect(props => [
        { collection: 'courses' },
        { collection: 'modules' },
        { collection: 'topics' },
        { collection: 'programs' },
    ])
)(CourseDetail)