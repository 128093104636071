import React, { Component } from 'react';
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase';
import shortid from 'shortid';

import Video from '../e-learning/Video';

class Test extends Component{

    state = {
        questionCode:'',
        questionProgram:'',
        questionModule:'',
        questionContent1:'',
        questionContent2:'',
        answers: [],

        getAnswerContent: '',
        getAnswerStatus: '',

        //for emial veirifed
        userId: '',

        youtubeUrl:'',

        haha: null,

        wsMessage: '',
        wsNo:'',
    }

    handleAddQuestion = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    saveAnswer = (e) => {
        
        let answerId = shortid.generate();

        this.setState({
            answers: [ 
                ...this.state.answers,
                {
                answerId: answerId,
                answerContent: this.state.getAnswerContent, 
                answerStatus: this.state.getAnswerStatus,
                },
            ],
        });
        
        this.refs.answerContent.value = '';
        this.refs.answerStatus.value = 'Status';
    }

    removeAnswer = (id) => {
        let removedAnswer = this.state.answers.filter(ans => {
            return ans.answerId !== id
        });
        this.setState({
            answers: removedAnswer,
        })
    }

    addQuestion = () => {
        console.log(this.state)
    }

    getExamId = () => {
        
        const { moduleEnrollments, examEnrollments } = this.props;

        let haha = [];

        examEnrollments && examEnrollments.map(exam => {
            return (
                moduleEnrollments && moduleEnrollments.map(module => {
                    if(exam.userId === module.userId && exam.courseId === "6qVCSxIVWfzK99ngT9xR" && module.courseId === "6qVCSxIVWfzK99ngT9xR"  && module.moduleId === "lQGu9I6pqDW1qfGZ5aHd" && module.isDone === true && exam.isAvailable === false) {
                        haha.push(exam.userId)
                    }
                    return null
                })
            )
        })

        this.setState({
            haha
        })
    }

    getClassDataDiff = () => {
        const { physicalClassBatches, physicalClassParticipants } = this.props;

        let batch_partId = [], req_partId = [];

        physicalClassBatches && physicalClassBatches.map(batch => {
            return batch.selectedParticipants && batch.selectedParticipants.map(selected => {
                return batch_partId.push(selected.userId)
            })
        })
        
        physicalClassParticipants && physicalClassParticipants.map(part => {
            return req_partId.push(part.userId)
        })

        let difference = req_partId.filter(x => !batch_partId.includes(x));

        console.log(difference)
        console.log(batch_partId.length)
        console.log(req_partId.length)

    }

    render(){

        const { wsMessage, wsNo } = this.state;

        var strMsg = wsMessage, strNo =  wsNo;

        var replacedMsg = strMsg.split(' ').join('%20');
        var replacedNo = strNo.split('-').join('');

        return(

        <div  style={{padding:"50px"}} className="test min-height">

            <div>
                <button className="btn all-btn" onClick={this.getExamId.bind(this)}>Get userId - done all quiz, but exam not available</button>

                { this.state.haha &&  this.state.haha.map(id => {
                    return (
                        <p>{id}</p>
                    )
                })}
            </div>

            <div className="mt-2">
                <button className="btn all-btn" onClick={this.getClassDataDiff.bind(this)}>Get class data differences userId (physicalClassBatches & physicalClassParticipants)</button>
            </div>

            {/* <div className="mt-3">
                <a 
                    href="https://us-central1-halal-dev-academy.cloudfunctions.net/accExpiredNotification-accExpiredNotification" 
                    className="all-btn">Trigger function accExpiredNotification
                </a>
            </div> */}

            <div className="mt-5">
                <h5>send whatsapp message
                    <div className="row"><div className="title-border"></div></div>
                </h5>

                <textarea type="text" className="col-md-6 form-control" placeholder="WhatsApp message" onChange={event => this.setState({wsMessage: event.target.value})} />
                <input type="text" className="col-md-6 form-control" placeholder="WhatsApp phone number" onChange={event => this.setState({wsNo: event.target.value})} />

                { replacedMsg && replacedNo ? 
                    <a className="all-btn btn" href={`https://wa.me/6${replacedNo}?${replacedMsg}`} target="_blank" rel="noopener noreferrer"><i className="fab fa-whatsapp"></i>{' '}Send WhatsApp</a>
                : null }
            </div>

            <div style={{margin:"50px 0px 50px 0px"}}>
                <h5>TEST EMBED YOUTUBE
                    <div className="row"><div className="title-border"></div></div>
                </h5>
                <input type="text" className="col-md-6 form-control" placeholder="Input Youtube URL here..." onChange={event => this.setState({youtubeUrl: event.target.value})}/>

                { this.state.youtubeUrl ?
                    <Video ytUrl={this.state.youtubeUrl} />
                : null }
            </div>

            <div>
                <h5>Set user email verified to true
                    <div className="row"><div className="title-border"></div></div>
                </h5>
                <input type="text" className="form-control col-md-6" placeholder="Enter user ID" onChange={event => this.setState({userId: event.target.value})} />
                { this.state.userId ?
                    <div>
                        <a className="btn all-btn"
                            href={`https://us-central1-halal-dev-academy.cloudfunctions.net/setEmailVerified-setEmailVerified?userId=${this.state.userId}`}>
                            Set email verified to true
                        </a>
                     </div>
                   
                : null}
            </div>

            <div style={{marginTop:"50px"}}>
                <h5>add new question
                    <div className="row"><div className="title-border"></div></div>
                </h5>

                <input onChange={this.handleAddQuestion} id="questionCode" type="text" className="col-md-4 form-control" placeholder="Code"/>

                <div>
                    <select onChange={this.handleAddQuestion} id="questionProgram" className="form-control">
                        <option defaultValue>Choose Program</option>
                        <option>Halal Executive</option>
                        <option>Halal Industry</option> 
                        <option>Halal Awareness</option> 
                    </select>
                </div>

                <div>
                    <select onChange={this.handleAddQuestion} id="questionModule" className="form-control">
                        <option defaultValue>Choose Module</option>
                        <option>Halal Internal Audit Facilitation</option>
                        <option>Halal Manual Records and Administration</option> 
                        <option>Halal Quality Assurance</option> 
                    </select>
                </div>

                <textarea onChange={this.handleAddQuestion} id="questionContent1" type="text" className="form-control" placeholder="Content 1"/>

                <textarea onChange={this.handleAddQuestion} id="questionContent2" type="text" className="form-control" placeholder="Content 2: <ul><span>I</span><br/></ul/>" />

                {/* ---------------------------------- ADD ANSWER PART ----------------------------------  */}

                <br/><br/>

                <b>Add Answers:</b>

                <textarea type="text" className="form-control" ref="answerContent" value={this.state.answerContent} onChange={event => this.setState({getAnswerContent: event.target.value})}  placeholder="Answer"/>

                <select className="col-md-4 form-control" ref="answerStatus" value={this.state.answerStatus} onChange={event => this.setState({getAnswerStatus: event.target.value})}>
                    <option defaultValue>Status</option>
                    <option>true</option>
                    <option>false</option> 
                </select>

                <span className="badge bg-blue text-white" onClick={this.saveAnswer.bind(this)}>Save and Add New</span>

                <br/><br/>

                <b>Answer preview:</b>

                { this.state.answers && this.state.answers.map((ans,i) => {
                    return (
                        <li style={{margin:'20px'}}>
                            {ans.answerContent} / <b>{ans.answerStatus}</b> {' '}
                            <span onClick={this.removeAnswer.bind(this, ans.answerId)}> <i className="fas fa-trash text-red" /></span>
                        </li>
                    )
                })}

                <div className="mt-5 row justify-content-end">
                    <button className="btn m-2 btn-outline-black" onClick={this.addQuestion.bind(this)}>add question</button>
                </div>
            </div>

        </div>
        
        )
    }
}



const mapStateToProps = (state) => {
    return {

        moduleEnrollments: state.firestore.ordered.moduleEnrollments,
        examEnrollments: state.firestore.ordered.examEnrollments,
        physicalClassBatches: state.firestore.ordered.physicalClassBatches,
        physicalClassParticipants: state.firestore.ordered.physicalClassParticipants,
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect([
        { collection: 'moduleEnrollments'},
        { collection: 'examEnrollments'},
        { collection: 'physicalClassBatches'},
        { collection: 'physicalClassParticipants'},
    ])
)(Test);
