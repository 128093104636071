import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';

import { isHideBatch, deleteTranscript, deletePCB, updatePCB, updatePCP_status, updatePCB_status, updatePCB_selectedParticipants, updatePCP_status_batchId } from '../../../../stores/actions/classAction';

import BatchNote from './BatchNote';

import DisplayEmailHistory from '../DisplayEmailHistory';
import ClassCustomEmail from '../ClassCustomEmail';
import ParticipantDetails from '../ParticipantDetails';
import CreateGroup from '../group/CreateGroup';
import CreatedGroup from '../group/CreatedGroup';
import MoveToBatch from './MoveToBatch';
import CreateTranscript from './CreateTranscript';
import AddExtraInfo from '../extraInfo/AddExtraInfo';
import EditExtraInfo from '../extraInfo/EditExtraInfo';

class Batch extends Component {

    constructor(props){
        super(props)
        this.state = {
            move_userId: '',
            changeState:'',
            isHide: false,
            
            selectedUserId: '',
            name:'', //display participant detail
            email: '',
            phone: '',
            company: '',
            courseName:'',
    
            batchId: '',
            batchName:'',  //utk edit batch
            batchCode:'', 
            batchAmount:'',
            batchCourseId:'',
            selectedParticipants:'',
            date: {},
            day1:'',
            day2:'',
            day3:'',
            day4:'',
    
            selectedBatchId: '',

            tsData: {},
        
            isLoading: null,
            message: null,
        }
        this.baseState = this.state 
    }

    componentDidUpdate = (prevProps, prevState) => { 
        const {updateBatchError, updateBatchSuccess, deleteBatchSuccess } = this.props;

        if(prevProps.updateBatchError !== updateBatchError) {
            this.setState({
                isLoading: false,
                message: {
                    msg: updateBatchError.message,
                    type: updateBatchError.type,
                }
            })
        }

        if(prevProps.updateBatchSuccess !== updateBatchSuccess) {
            this.setState({
                isLoading: false,
                message: {
                    msg: updateBatchSuccess.message,
                    type: updateBatchSuccess.type,
                }
            })
            setTimeout(() => { 
                this.hideModal('modal-edit-listed');
                setTimeout(() => { 
                    this.setState({
                        message: null,
                    })
                }, 2000);
            }, 2000);
        }

        if(prevProps.deleteBatchSuccess !== deleteBatchSuccess) {
            this.setState({
                isLoading: false,
            })
            alert(deleteBatchSuccess.message)
        }
    } 

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    //popup user detail when click name
    showDetailListed = (selectedUserId) => {
        const { users } = this.props;
        let name = '', email = '', phone = '', company = '';

        document.getElementById("modal-detail-listed").style.display = "block";

        users && users.map(user => {
            if(user.userId === selectedUserId) {
                return (
                    name = user.firstname + ' ' + user.lastname, 
                    email = user.email, 
                    phone = user.contactNo ? user.contactNo : 'Not updated',
                    company = user.company ? user.company : 'Not updated'
                )
            }
            return null
        })
        
        this.setState({
            name, email, phone, company, selectedUserId
        })
    }
    
    hideDetailListed = () => {
        document.getElementById("modal-detail-listed").style.display = "none";
    }

    updateDate = async () => {
        this.setState({
            date: {
                day1: this.state.day1,
                day2: this.state.day2,
                day3: this.state.day3,
                day4: this.state.day4,
            }
        })
        return;
    }

    updateBatch = async () => {
        await this.updateDate();

        var result = window.confirm("Confirm update?");
    
        if (result) {
            this.setState({
                isLoading: true
            })
    
            this.props.updatePCB(this.state, this.state.selectedBatchId);
        } else return null
    }

    updateStatus = (pcbId) => {
        const { batchStatus, physicalClassBatches } = this.props;

        let newStatus = "";

        if(batchStatus === "") {
            newStatus = "active"
        } else if (batchStatus === "active") {
            newStatus = "submitted"
        } else if (batchStatus === "submitted") {
            newStatus = "completed"
        } else return null

        let data = {
            status: newStatus
        }

        var result = window.confirm("Are you sure?");

        if (result) {
            physicalClassBatches && physicalClassBatches.map(batch => {
                if (batch.id === pcbId) {
                    batch.selectedParticipants && batch.selectedParticipants.map(selected => {
                        return this.props.updatePCP_status(data, selected.pcpId);
                    })
                }
                return null
            })
            this.props.updatePCB_status(data, pcbId); //update to true
        }
    }

    openDetail = async (pcbId) => {
        
        const { physicalClassBatches } = this.props;

        let isHide = false, selectedBatchId ='', batchName='', batchCode='', batchAmount=null, batchCourseId='', selectedParticipants='', day1 = null, day2 = null, day3 = null, day4 = null ;

        physicalClassBatches && physicalClassBatches.map(batch => {
            if(batch.id === pcbId) {
                return (
                    isHide = batch.isHide, 
                    batchName = batch.batchName, 
                    batchCode = batch.batchCode, 
                    batchAmount = batch.batchAmount, 
                    batchCourseId = batch.courseId, 
                    selectedParticipants = batch.selectedParticipants,
                    selectedBatchId = batch.id,
                    day1 =  batch.date.day1,
                    day2 =  batch.date.day2,
                    day3 =  batch.date.day3,
                    day4 =  batch.date.day4
                )
            }
            return null
        })
        
        this.setState({
            isHide, selectedBatchId, batchName, batchCode, batchAmount, batchCourseId, selectedParticipants, day1, day2, day3, day4
        })
    }

    closeDetail = () => {
        this.setState({
            selectedBatchId:'',
        })
    }

    getPcpDataForAction = async (userId, batch_courseId) => {

        const { physicalClassParticipants, courseEnrollments } = this.props;

        let data = {}

        physicalClassParticipants && physicalClassParticipants.map(part => {
            if (userId === part.userId &&  part.courseId === batch_courseId) {
                return (
                    courseEnrollments && courseEnrollments.map(enrolled => {
                        if(userId === enrolled.userId && part.userId === enrolled.userId && part.courseId === enrolled.courseId) {
                            return (
                                data = {
                                    pcpId: part.id, 
                                    enrolledCourseId: enrolled.id,
                                }
                            )
                        }
                        return null
                    })
                )
            }
            return null
        })

        return data
    }

    actionButton = async (type, userId) => {
        const { selectedBatchId, selectedParticipants, batchCourseId } = this.state;

        let getData = await this.getPcpDataForAction(userId, batchCourseId);
        let pcpId = getData.pcpId, enrolledCourseId = getData.enrolledCourseId

        var result = window.confirm("Are you sure?");

        if (result) {
            let pcb_data = null, pcp_data = null;

            let removed = selectedParticipants.filter(selected => {
                return selected.userId !== userId
            })

            if (type === "approve") {
                pcb_data = {
                    selectedParticipants: [
                        ...removed,
                        {
                            userId,
                            pcpId,
                            enrolledCourseId
                        }
                    ]
                }
    
                pcp_data = {
                    status: 'listed',
                    selectedBatchId: selectedBatchId
                }

            } else if (type === "reject") {
                pcb_data = {
                    selectedParticipants: [
                        ...removed,
                    ]
                }
    
                pcp_data = {
                    status: 'requested',
                    selectedBatchId: ''
                }

            }

            if(pcb_data) {
                this.setState({
                    selectedParticipants: pcb_data.selectedParticipants
                })
            }

            if(pcp_data) {
                this.props.updatePCP_status_batchId(pcp_data, pcpId);
            }

            this.props.updatePCB_selectedParticipants(pcb_data, this.state.selectedBatchId)
        }
    }

    getBatchSelectedParticipants = async (batchId) => {
        const { physicalClassBatches } = this.props;
        let selectedParticipantId = [];

        physicalClassBatches && physicalClassBatches.map(batch => {
            if(batch.id === batchId) {
                return (
                    batch.selectedParticipants && batch.selectedParticipants.map(selected => {
                        selectedParticipantId.push(selected.pcpId)
                        return null
                    })
                )
            }
            return null
        })
        return selectedParticipantId;
    }

    deleteBatch = async (batchId) => {

        this.setState({
            isLoading: false,
        })
        
        //to get and update PCP status (after deleted)
        let ids = await this.getBatchSelectedParticipants(batchId);

        var result = window.confirm("Confirm delete?");

        let data = { 
            status: 'requested',
            selectedBatchId: ''
        };

        if (result) {
            ids && ids.map(id => {
                return this.props.updatePCP_status_batchId(data, id);
            })
            this.props.deletePCB(batchId); 
        }
    }

    showModal = async (type, userId, batchId) => {
        document.getElementById(`${type}`).style.display = "block";

        if(type === "modal-extra-info") {
            this.setState({ selectedBatchId: batchId })
        }

        if(type === "modal-edit-info") {
            this.setState({ extraInfoData: batchId })
        }

        if (type === "modal-choose-batch") {
            this.setState({
                move_userId: userId
            })
        }

        if (type === "modal-edit-listed") {
            await this.openDetail(batchId)
        }
    }

    hideModal = (type) => {
        document.getElementById(`${type}`).style.display = "none";
    }

    showTs = (modalType, userId, tsId, courseId) => {
        this.setState({ tsData: { userId, modalType, tsId, courseId } })
        document.getElementById("modal-transcript").style.display = "block";
    }
    
    hideTs = () => {
        document.getElementById("modal-transcript").style.display = "none";
    }

    deleteTs = (tsId) => { 
        var result = window.confirm("Confirm delete?");
    
        if (result) {
            this.props.deleteTranscript(tsId);
        } else return null
    }

    isHideBatch = () => {
        let isHide = !this.state.isHide;
        this.setState({ isHide })
        this.props.isHideBatch(isHide, this.state.selectedBatchId)
    }

    render(){

        const { batchStatus, pcpGroup, extraClassInfo, physicalClassBatches, physicalClassParticipants, courseEnrollments, selectedCourseId, users, transcripts, courses, classCustomEmail } = this.props;
        const { message, isLoading } = this.state;

        let isLoadingBatch = true;
        let newBatch = []; //to display if no current  batch
        let pcpList = [];
        let groupedPcp = [];


        //compile selectedBATCHID user that has been added into group
        if(this.state.selectedBatchId) {
            pcpGroup && pcpGroup.map(group => {
                if(this.state.selectedBatchId === group.batchId) {
                    return groupedPcp.push(...group.addedPcp)
                } return null
            })
        }

        let displayBatch = physicalClassBatches && physicalClassBatches.map((batch,i) => {
            if(batch.status === batchStatus && batch.courseId === selectedCourseId && batch.id === this.state.selectedBatchId) {
                isLoadingBatch = false;
                return (
                    <div key={i} className="batch">

                        <div>
                            <input checked={this.state.isHide} onChange={this.isHideBatch.bind(this)} type="checkbox" id="hide" value="hide"/>
                            {' '}<label htmlFor="hide">Hide from user</label> 
                        </div>
                            
                       <div className="mt-4 row">
                            <p className="col col-md-6"><b>BATCH NO:</b> {batch.batchCode}</p>
                            <p className="col col-md-6"><b>NAME:</b> {batch.batchName}</p>
                       </div>

                       <div className="mt-2 row">
                            <p className="col col-md-6">
                                <b>COURSE:</b> {' '}
                                { courses && courses.map(course => {
                                    if(course.id === batch.courseId) {
                                        return (
                                            course.courseName
                                        )
                                    } else return null
                                })}
                            </p>

                            <p className="col col-md-6"><b>MAX PARTICIPANT:</b> {batch.batchAmount}</p>
                       </div>

                        <div className="mt-4">
                            <b>DATE:</b> 
                            <div style={{marginLeft:"20px"}}>
                                <li><b>Day 1: </b>{' '}{batch.date.day1 ? batch.date.day1 : 'No selected date'}</li>
                                <li><b>Day 2: </b>{' '}{batch.date.day2 ? batch.date.day2 : 'No selected date'}</li>
                                <li><b>Day 3: </b>{' '}{batch.date.day3 ? batch.date.day3 : 'No selected date'}</li>
                                <li><b>Day 4: </b>{' '}{batch.date.day4 ? batch.date.day4 : 'No selected date'}</li>
                            </div>
                        </div>

                        <div className="mt-5">
                            <b>PARTICIPANTS:</b> 
                            <span  onClick={this.showModal.bind(this, 'modal-group', null, batch.id)} className='badge bg-green text-white'><i className="fas fa-users"/>{' '}Create group</span>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th className="text-left">Detail</th>
                                        <th>Action</th>
                                        {/* <th>G.Status</th> */}
                                        { batchStatus === "submitted" || batchStatus === "completed" ?
                                            <th>Transcript</th>
                                        : null }
                                    </tr>
                                </thead>
                                <tbody>
                                { batch.selectedParticipants && batch.selectedParticipants.map((selected,i) => {

                                    let hasTs = [];

                                    transcripts && transcripts.map(ts => {
                                        if(ts.userId === selected.userId && ts.batchId === this.state.selectedBatchId && ts.courseId === selectedCourseId) {
                                            return hasTs.push({userId: ts.userId, tsId: ts.id, status:ts.status})
                                        } return null
                                    })

                                    return (
                                        users && users.map(user => {
                                            if (selected.userId === user.userId) {

                                                pcpList = [
                                                    ...pcpList,
                                                    {
                                                        userId: selected.userId,
                                                        fname:user.firstname,
                                                        lname:user.lastname,
                                                        email: user.email,
                                                        company: user.company ? user.company : ''
                                                    }
                                                ]

                                                let no = i+1;

                                                // let isAdded = [];
                                                // groupedPcp && groupedPcp.map((data,i) => {
                                                //     if(data.userId === selected.userId) {
                                                //         return isAdded.push(data.userId)
                                                //     } return null
                                                // })

                                                return (
                                                    <tr key={i}>
                                                        <td>{no}.</td>

                                                        <td className="text-left">
                                                            <span onClick={this.showDetailListed.bind(this, selected.userId)}>{user.firstname} {user.lastname}</span>
                                                            <br/>{user.email}
                                                        </td>

                                                        <td>
                                                            { selected.pcpId === "" && selected.enrolledCourseId === "" ?
                                                                <span onClick={this.actionButton.bind(this, 'approve', selected.userId, null)} className="badge bg-green" title="Approve"><i className="text-white fas fa-check"/></span>
                                                            :
                                                                <span onClick={this.showModal.bind(this, 'modal-choose-batch', selected.userId, null)} className="badge bg-blue" title="Move to another batch"><i className="text-white fas fa-arrow-right"/></span>
                                                            }
                                                            <span onClick={this.actionButton.bind(this, 'reject', selected.userId, null)} className="badge bg-red" title="Reject & move to 'Requested'"><i className="text-white fas fa-times"/></span>
                                                        </td>

                                                        {/* <td>
                                                            { isAdded.length >= 1 ? 
                                                                <i key={i} className="text-green fas fa-circle m-1" title="Group status"></i>
                                                            : 
                                                                <i key={i} className="text-yellow fas fa-circle m-1" title="Group status"></i> 
                                                            }
                                                        </td> */}

                                                        { batchStatus === "submitted" || batchStatus === "completed" ?
                                                            <td>
                                                                { hasTs.length >= 1 ?
                                                                    hasTs && hasTs.map((ts,i) => {
                                                                        return (
                                                                            <div key={i}>
                                                                                <span onClick={this.showTs.bind(this, 'edit', selected.userId, ts.tsId, selectedCourseId)} className='m-1 badge bg-black text-white' title="Update transcript"><i className='fas fa-edit'/></span> 
                                                                                <span  className={ts.status === "save" ? "m-1 badge bg-orange" : ts.status === "publish" ? "m-1 badge bg-green" : null}  title="View transcript"><Link target="_blank" rel='noopener noreferrer' to={`/transcript/${selected.userId}/${this.state.selectedBatchId}/${ts.tsId}`}><i className='fas fa-eye text-white'/></Link></span>
                                                                                <span onClick={this.deleteTs.bind(this, ts.tsId)} className='m-1 badge bg-red text-white' title="Delete transcript"><i className='fas fa-trash'/></span> 
                                                                            </div>
                                                                        )
                                                                    })
                                                                : 
                                                                    <span onClick={this.showTs.bind(this, 'new', selected.userId, null, selectedCourseId)} className='m-1 badge bg-blue text-white' title="Create transcript"><i className='fas fa-plus'/>{' '}Create transcript</span> 
                                                                }
                                                            </td>
                                                        : null }

                                                    </tr>
                                                )
                                            } else return null
                                        })
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>

                        <CreatedGroup pcpGroup={pcpGroup} groupedPcp={groupedPcp} pcpList={pcpList} selectedBatchId={this.state.selectedBatchId} />
                        
                        <BatchNote batchNote={batch.batchNote} batchId={batch.id} />

                        <DisplayEmailHistory selectedBatchId={this.state.selectedBatchId} classCustomEmail={classCustomEmail} />

                        <div className="mt-5 row justify-content-end">
                            { batchStatus && batchStatus === "completed" ?
                                null
                            :
                                <button className="btn m-2 all-btn" onClick={this.updateStatus.bind(this, batch.id)}><i className="fas fa-check-circle"/>{' '}{batchStatus === "" ? "Activate" : batchStatus === "active" ? "Submitted" : batchStatus === "submitted" ? "Completed" : null }</button>
                            }
                            <button className="btn m-2 btn-outline-black" onClick={this.showModal.bind(this, 'modal-send-email', null, batch.id)}><i className="fas fa-envelope"/>{' '}Custom Email </button>
                        </div>
                    </div>
                )
            } else {
                isLoadingBatch = false 
            }
            return null
        })

        return(

        <div>

            {/* modal add/edit transcript */}
            <div id="modal-transcript" className="mymodal">
                <div className="mymodal-content col-md-8">
                    <span className="close" onClick={this.hideModal.bind(this, 'modal-transcript')}>&times;</span>
                    <CreateTranscript hideTs={this.hideTs.bind(this)} ts_passData={this.state.tsData} batchId={this.state.selectedBatchId} courseId={selectedCourseId} />
                </div>
            </div>

            {/* modal create new group */}
            <div id="modal-group" className="mymodal">
                <div className="mymodal-content col-md-12">
                    <span className="close" onClick={this.hideModal.bind(this, 'modal-group')}>&times;</span>
                    <CreateGroup hideModal={this.hideModal.bind(this, 'modal-group')} actionType='create' groupedPcp={groupedPcp} pcpList={pcpList} batchId={this.state.selectedBatchId} />
                </div>
            </div>

            {/* modal partcipant detail */}
            <div id="modal-detail-listed" className="normalmodal">
                <div className="normalmodal-content col-md-4">
                    <span className="close" onClick={this.hideDetailListed.bind(this)}>&times;</span>
                   <ParticipantDetails selectedCourseId={selectedCourseId} userId={this.state.selectedUserId} name={this.state.name} email={this.state.email} phone={this.state.phone} company={this.state.company} />
                </div>
            </div>

            {/* modal extra info */}
            <div id="modal-extra-info" className="mymodal">
                <div className="normalmodal-content col-md-6">
                    <span className="close" onClick={this.hideModal.bind(this, 'modal-extra-info')}>&times;</span>
                   <AddExtraInfo batchId={this.state.selectedBatchId} hideModal={this.hideModal.bind(this, 'modal-extra-info')} />
                </div>
            </div>

            {/* modal EDIT extra info */ }
            <div id="modal-edit-info" className="mymodal">
                <div className="normalmodal-content col-md-6">
                    <span className="close" onClick={this.hideModal.bind(this, 'modal-edit-info')}>&times;</span>
                <EditExtraInfo extraInfoData={this.state.extraInfoData} hideModal={this.hideModal.bind(this, 'modal-edit-info')} />
                </div>
            </div>

            <div id="modal-send-email" className="mymodal">
                <div className="mymodal-content col-md-6">
                    <span className="close" onClick={this.hideModal.bind(this, 'modal-send-email')}>&times;</span>
                   <ClassCustomEmail hideModalEmail={this.hideModal.bind(this, 'modal-send-email')} users={users} physicalClassBatches={physicalClassBatches} selectedBatchId={this.state.selectedBatchId} />
                </div>
            </div>

            <div id="modal-choose-batch" className="mymodal">
                <div className="mymodal-content col-md-6">
                    <span className="close" onClick={this.hideModal.bind(this, 'modal-choose-batch')}>&times;</span>
                    
                    <MoveToBatch hideModal={this.hideModal.bind()} physicalClassParticipants={physicalClassParticipants} physicalClassBatches={physicalClassBatches} courseEnrollments={courseEnrollments} userId={this.state.move_userId} selectedParticipants={this.state.selectedParticipants} selectedCourseId={selectedCourseId} selectedBatchId={this.state.selectedBatchId} />
                </div>
            </div>

            {/* modal edit batch */}
            <div id="modal-edit-listed" className="mymodal">
                <div className="mymodal-content col-md-6">
                    <span className="close" onClick={this.hideModal.bind(this, 'modal-edit-listed')}>&times;</span>
                    <h5 className="middle text-black">edit batch
                        <div className="row"><div className="title-border"></div></div>
                    </h5>

                    <div className='row mt-5'>
                        <p className="col col-md-6">
                            <b>COURSE:</b>

                            {courses && courses.map((course,i) => {
                                if(this.state.batchCourseId === course.id) {
                                    return (
                                        <input key={i} disabled type="text" className="form-control" value={course.courseName} />
                                    )
                                }
                                return null
                            })} 
                        </p>
                    </div>

                    <div className="mt-2 row">
                        <p className="col">
                            <b>BATCH NO:<small>**Transcript</small></b>
                            <input id="batchCode" onChange={this.handleChange} type="text" className="form-control" value={this.state.batchCode} />
                        </p>
                        <p className="col">
                            <b>NAME:</b>
                            <input id="batchName" onChange={this.handleChange} type="text" className="form-control" value={this.state.batchName} />
                        </p>
                    </div>

                    <b>DATE:</b>
                    <div className="middle-not-center">
                        <div className="row">
                            <label className="col middle col-md-2">Day 1:</label> <input onChange={e => this.setState({day1: e.target.value})}  value={this.state.day1} className="col col-md-8 form-control" type="date" />
                        </div>
                        <div className="row">
                            <label className="col middle col-md-2">Day 2:</label> <input onChange={e => this.setState({day2: e.target.value})}  value={this.state.day2} className="col col-md-8 form-control" type="date" />
                        </div>
                        <div className="row">
                            <label className="col middle col-md-2">Day 3:</label> <input onChange={e => this.setState({day3: e.target.value})}  value={this.state.day3} className="col col-md-8 form-control" type="date" />
                        </div>
                        <div className="row">
                            <label className="col middle col-md-2">Day 4:</label> <input onChange={e => this.setState({day4: e.target.value})}  value={this.state.day4} className="col col-md-8 form-control" type="date" />
                        </div>
                    </div>

                    <br/>

                    <b>MAX PARTICIPANT:</b>
                    <input id="batchAmount" onChange={this.handleChange} type="number" className="form-control"  value={this.state.batchAmount}/>

                    <br/>

                    { isLoading === true ? 
                        <small>Loading...</small>
                    : 
                        message  ?
                            <small className={message.type === 'updateBatchError' ? "text-red" : message.type === 'updateBatchSuccess'  ? "text-green" : null }>
                                {message.msg}
                            </small>
                        : null
                    }
                    
                    <div className="mt-3 row justify-content-end">
                        <button 
                            className="btn m-2 btn-outline-black" onClick={this.updateBatch.bind(this)}><i className="fas fa-check-circle"/>{' '}update batch
                        </button>
                    </div>
                </div>
            </div>

            { physicalClassBatches !== null ?
                physicalClassBatches && physicalClassBatches.map((batch,i) => {
                    if (batch.status === batchStatus && batch.courseId === selectedCourseId) {
                        newBatch.push(batch)

                        let hasInfo = [];
                        extraClassInfo && extraClassInfo.map(extra => {
                            if(extra.batchId === batch.id) {
                                hasInfo.push(extra)
                            } return null
                        })

                        return (
                            <div key={i} className="detail-btn">
                                <label>{batch.batchName}</label>

                                <div className='float-right'>

                                    { hasInfo.length >= 1 ?
                                        <span className='badge bg-blue text-white' onClick={this.showModal.bind(this, 'modal-edit-info', null, hasInfo[0])}><i className="fas fa-edit"/>{' '}Extra class info </span>
                                    :
                                        <span className='badge bg-red text-white' onClick={this.showModal.bind(this, 'modal-extra-info', null, batch.id)}><i className="fas fa-plus"/>{' '}Extra class info </span>
                                    }

                                    <Link title="Download batch data or participants timestamp" target="_blank" rel='noopener noreferrer' to={`/batch_data/${batch.id}`} >
                                        <i className="m-1 fas fa-download" />
                                    </Link>

                                    <span onClick={this.showModal.bind(this, 'modal-edit-listed', null, batch.id)}><i className="m-1 ml-2 fas fa-edit"/> </span>
                                    <span onClick={this.deleteBatch.bind(this, batch.id)}><i className="m-1 fas fa-trash text-red"></i></span>
                                    
                                    <span 
                                        onClick={!this.state.selectedBatchId ? this.openDetail.bind(this, batch.id) : this.closeDetail.bind(this)}>
                                        <i className={batch.id !== this.state.selectedBatchId ? "m-2 fas fa-angle-down" : "m-2 fas fa-minus"} />
                                    </span>
                                </div>

                                {batch.id === this.state.selectedBatchId ? 
                                    <div className="content">
                                        {displayBatch}
                                    </div>
                                :''}
                            </div>
                        )
                    }
                    return null
                })
            : null }

            { isLoadingBatch === true ? 
                <p>Please wait... Data loading...</p> 
            : 
                newBatch.length < 1 ?
                    <p>Currently no 
                        { batchStatus === "" ? 
                            ' new ' 
                        : batchStatus === "active" ? 
                            ' active ' 
                        : batchStatus === "submitted" ? 
                            ' submitted ' 
                        : batchStatus === "completed" ? 
                            ' completed ' 
                        : null } 
                    batch.</p>
                : null
            }

        </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        updateBatchError: state.classes.updateBatchError,
        updateBatchSuccess: state.classes.updateBatchSuccess,
        deleteBatchSuccess: state.classes.deleteBatchSuccess,
    }
}
  
const mapDispatchToProps = (dispatch) => {
    return {
        deletePCB: (pcbId) => dispatch(deletePCB(pcbId)),
        isHideBatch: (data, pcbId) => dispatch(isHideBatch(data, pcbId)),
        updatePCP_status: (data, pcpId) => dispatch(updatePCP_status(data, pcpId)),
        updatePCB: (batch, pcbId) => dispatch(updatePCB(batch, pcbId)),
        updatePCB_status: (data, pcbId) => dispatch(updatePCB_status(data, pcbId)),
        updatePCB_selectedParticipants: (data, pcbId) => dispatch(updatePCB_selectedParticipants(data, pcbId)),
        updatePCP_status_batchId: (data, pcpId) => dispatch(updatePCP_status_batchId(data, pcpId)),
        deleteTranscript: (tsId) => dispatch(deleteTranscript(tsId)),
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Batch);