import React, { Component } from 'react';

import SignUp from '../layout/SignUp';
import SignIn from '../layout/SignIn';

class SignedOutLinks extends Component {


    show = (codeShow) => {
        if (codeShow === "showSignIn") {
            document.getElementById("modalSignIn").style.display = "block";
        } else if (codeShow === "showSignUp") {
            document.getElementById("modalSignUp").style.display = "block";
        }else{}
    }
    
    hide = (codeHide) => {
        if (codeHide === "hideSignIn") {
            document.getElementById("modalSignIn").style.display = "none";
        } else if (codeHide === "hideSignUp") {
            document.getElementById("modalSignUp").style.display = "none";
        }else{}
    }



    render() {
        return(

        <ul className="navbar-nav mr-auto">
            <div id="modalSignIn" className="normalmodal">
                <div className="normalmodal-content col-md-4">
                    <span className="close" onClick={this.hide.bind(this, 'hideSignIn')}>&times;</span>
                    <SignIn hide={this.hide} />
                </div>
            </div>

            <div id="modalSignUp" className="normalmodal">
                <div className="normalmodal-content col-md-6">
                    <span className="close" onClick={this.hide.bind(this, 'hideSignUp')}>&times;</span>
                    <SignUp hide={this.hide} />
                </div>
            </div>

            <li className="nav-item">
                <button style={{margin: '5px'}} className="btn all-btn bg-transparent border-0" onClick={this.show.bind(this, 'showSignIn')}>
                    <i className="fas fa-sign-in-alt"></i>{'  '}Login
                </button>
            </li>

            <li className="nav-item">
                <button style={{margin: '5px'}} className="text-white btn all-btn " onClick={this.show.bind(this, 'showSignUp')}>
                    <i className="fas fa-user-plus"></i>{'  '}Register
                </button>
            </li>
        </ul>

        )
    }
  }

export default SignedOutLinks;