import React, { Component } from 'react';
import { connect } from 'react-redux'
import { deleteHelpInfo } from '../../../stores/actions/infoAction';

import EditHelpInfo from './EditHelpInfo';

class DisplayHelpInfo extends Component{

    state = {
        info_id:'',
        displayEditCode:'', //ADD EXTRA EXPRESSION SBB NANTI DIA BACA RENDER EDITHELPINFO WTFFF
        
        pdfName:null,
        pdfUrl:null,
    
        isLoading: null,
        message: null,
    }
    
    componentDidUpdate = (prevProps, prevState) => { 
        const { deleteHelpError, deleteHelpSuccess } = this.props;

        if(prevProps.deleteHelpError !== deleteHelpError) {
            alert(deleteHelpError.message);
            this.setState({
                isLoading: false,
                message: {
                    msg: deleteHelpError.message,
                    type: deleteHelpError.type,
                }
            })
        }
        
        if(prevProps.deleteHelpSuccess !== deleteHelpSuccess) {
            alert(deleteHelpSuccess.message);
            this.setState({
                isLoading: false,
                message: {
                    msg: deleteHelpSuccess.message,
                    type: deleteHelpSuccess.type,
                }
            })
        }
    } 

    handleEdit = (id, code) => {
        this.props.show();
        this.setState({
            displayEditCode: code,
           info_id: id
        })
    }

    deleteHelpInfo = (infoId) => {

        var result = window.confirm("Confirm delete?");

        if (result) {
            this.props.deleteHelpInfo(infoId);
        }
        
    }

    hideAndResetState = () => { //RESET ID SUPAYA DIA TAK BACA SAME ID UTK NEXT BTN CLICK
        this.props.hide();
        this.setState({
           info_id: '',
           displayEditCode: ''
        })
    }

    displayModalHelpFile_Display = (name, url) => { //display file modal
        this.setState({
            pdfName: name,
            pdfUrl: url
        })
        document.getElementById("display_help_file_display").style.display = "block";
    }

    hideModalHelpFile_Display = () => { //hide file modal
        document.getElementById("display_help_file_display").style.display = "none";
    }



    render(){

        const { infos } = this.props;

        let hasData = [], isLoading= true;
        let no = 0;

        let infoList = infos && infos.map((info ,i) => {
            if(info.infoType === "HELP INFO"){
                hasData.push(info)
                isLoading = false;
                no = no+1;
                return(
                    <tr key={i}>
                        <th scope="row">{no}</th>
                        <td className="text-left">{info.infoName}</td>
                        <td key={i} className="text-left"> 
                            { info.selectedHelpFile ?
                                info.selectedHelpFile.fileType === "pdf" ?
                                    <span onClick={this.displayModalHelpFile_Display.bind(this, info.selectedHelpFile.fileName, info.selectedHelpFile.fileUrl)}>{info.selectedHelpFile.fileName}</span>
                                : info.selectedHelpFile.fileType === "video" ?
                                    <video width="200" height="100" src={info.selectedHelpFile.fileUrl} type="video/mp4" controls />
                                : null 
                            : null }
                        </td>
                        
                        <td>
                            <button className="m-1 btn btn-outline-black" onClick={this.handleEdit.bind(this, info.id, 'edithelpinfo')}><i className="fas fa-edit"></i></button>
                            <button type="button" className="m-1 btn btn-outline-red" onClick={this.deleteHelpInfo.bind(this, info.id)}><i className="fas fa-trash"></i></button>
                        </td>
                    </tr>
                )
            } else {
                isLoading = false;
            }
            return null
        });


        return(

        <div>

            <div id="display_help_file_display" className="mymodal">
                <div className="mymodal-content col-md-6">
                    <span className="close" onClick={this.hideModalHelpFile_Display}>&times;</span>
                        <h3 className="middle">{this.state.pdfName}
                            <div className="row"><div className="title-border"></div></div>
                        </h3>
                        
                        <object aria-label="display-pdf" className="display-pdf" data={this.state.pdfUrl}></object>
                </div>
            </div>

            {/* MODAL FOR UPDATE HELP INFO */}
            <div id="myModal" className="mymodal">
                <div className="mymodal-content col-md-6">

                    <span className="close" onClick={this.hideAndResetState}>&times;</span>
                    
                    { this.state.displayEditCode==='edithelpinfo'?
                        <EditHelpInfo hideModalHelpFile_Display={this.hideModalHelpFile_Display} hideAndResetState={this.hideAndResetState} info_id={this.state.info_id}/>
                    :''}

                </div>
            </div>

            { isLoading === true ? 
                <p>Please wait... Data loading...</p> 
            : 
                hasData.length > 0 ? 
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">No.</th>
                                <th scope="col">Name</th>
                                <th scope="col">Attachment</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {infoList}
                        </tbody>
                    </table>
                : 
                    <p>No info available</p>
            }

        </div>

        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        deleteHelpError: state.infos.deleteHelpError,
        deleteHelpSuccess: state.infos.deleteHelpSuccess,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
      deleteHelpInfo: (infoId) => dispatch(deleteHelpInfo(infoId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DisplayHelpInfo)