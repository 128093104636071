import React, { Component } from 'react';

class NoMatchPage extends Component {

    render(){

        return(
            
            <div className="container col-md-12 justify-content-center middle min-height" >  
                    <h1>PAGE NOT FOUND</h1>
            </div>
        
        )
    }
}

export default NoMatchPage;