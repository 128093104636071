
const moment = require('moment');
const jwt = require('jsonwebtoken');

export const emailVerified = (newUser) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {

        const firebase = getFirebase();
        const firestore = getFirestore();

        firebase.auth().createUserWithEmailAndPassword(
            newUser.email,
            newUser.password
        ).then((resp) => {
            return firestore.collection('users').doc(resp.user.uid).set({
                fullname: newUser.fullname,
                firstname: newUser.firstname,
                lastname: newUser.lastname,
                email: newUser.email,
                identityCardNo: newUser.ic,
                dob: newUser.dob,
                company: newUser.company,
                contactNo: '',
                address1: '',
                address2: '',
                postcode: '',
                city: '',
                state: '',
                country: '',
                lastLogin: moment().format("MMM Do YY"),
                userId: resp.user.uid,
                supportTicket: 10,
                qnaTicket: 10,
            }).then(() => {
                dispatch({ type: 'NEW_USER_ADDED_SUCCESS', message:'New user registered.'})

                //AUTO ENROLL PREVIEW - FREE COURSE
                // firestore.collection('enrollments').add({
                //     userId: resp.user.uid,
                //     enrollmentEmail: newUser.email,
                //     enrollmentType:'Professional',
                //     enrollmentCourseName: "Preview",
                //     enrollmentCourseId: "bXbFftarPOYQe40WjQN7", 
                //     enrollmentModuleId: "", 
                // }).then(() => {
                //     dispatch({ type:'ADDED_NEW_ENROLLMENT', message: `Data processing. Enrollment will done automatically. It may take some time.`});
                // }).catch((err) => {
                //     dispatch({ type:'ADDED_NEW_ENROLLMENT_ERROR', err});
                // })

            }).catch(err => {
                dispatch({ type:'NEW_USER_ADDED_ERROR', err})
            })
        }).then(() => {
            dispatch({ type: 'VERIFIED_SUCCESS', message:'Account verified.'})
        }).catch(err => {
            dispatch({ type:'VERIFIED_ERROR', err})
        })
    }
}

//sent email verify acc
export const signUp = (newUser) => {

    const { email, password, fullname, firstname, lastname, identityCardNo, dob, company } = newUser;
    var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        const firestore = getFirestore();

        if(!email || !password || !fullname || !firstname || !lastname || !identityCardNo || !dob) {
            dispatch({ type:'SIGNUP_ERROR', err: {message: 'Input data is required.'}})
        } else if (password.length < 7) {
            dispatch({ type:'SIGNUP_ERROR', err: {message: 'Password should be at least 6 characters.'}})
        } else if (!(email.match(mailformat))) { 
            dispatch({ type:'SIGNUP_ERROR', err: {message: 'Email format is not valid.'}})
        } else {
            try {
                let snapshot = await firestore.collection('users').where('email', '==', email).get();
                if(snapshot.empty) {
                    try {
                        let hashedPass = jwt.sign(password, 'hashedmypassword');
                        let hashedIc = jwt.sign(identityCardNo, 'hashedmyic');

                        window.location.href =`https://us-central1-halal-e-learning.cloudfunctions.net/newRegistration-newRegistration?wn=${fullname}&fn=${firstname}&ln=${lastname}&em=${email}&id=${hashedPass}&ic=${hashedIc}&dob=${dob}&cpny=${company}`;

                        dispatch({ type:'SIGNUP_SUCCESS', message: 'Please check your email or spam for account verification'});
                    }catch(err) {
                        console.log(err)
                    }
                } else {
                    dispatch({ type:'SIGNUP_ERROR', err: {message: 'Your old data still exists or the email address is already in use by another account.'}})
                }
            } catch (err) {
                dispatch({ type:'SIGNUP_ERROR', err})
            }
        }
    }
}

export const signIn = (creds) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firebase = getFirebase();
        const firestore = getFirestore();

        firebase.auth().signInWithEmailAndPassword(
            creds.email,
            creds.password
        ).then((resp) => {

            firestore.collection('users').doc(resp.user.uid).update({
                lastLogin: moment().format("MMM Do YY")   
            }).catch(err => {
                console.log(err)
            })

            dispatch({ type:'LOGIN_SUCCESS', message:'Login success.'});
        }).catch((err) => {
            dispatch({ type: 'LOGIN_ERROR', err});
        });
    }
}

export const signOut = () => {
    return (dispatch, getState, {getFirebase}) => {
        const firebase = getFirebase();

        firebase.auth().signOut().then(() => {
            dispatch({ type: 'SIGNOUT_SUCCESS', message:'OK' });
        }).catch((err) => {
            dispatch({ type: 'SIGNOUT_ERROR', err});
        });
    }
}

export const forgotPassword = (email) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => { 

        const firebase = getFirebase();

        var auth = firebase.auth();
          
        auth.sendPasswordResetEmail(email)
        .then(() => {
            dispatch({ type:'RESET_PASSWORD_SUCCESS', message: 'Reset password email has been sent. Please check your email to proceed.'})
        }).catch((err) => {
            dispatch({ type:'RESET_PASSWORD_ERROR', err})
        });
    }
}