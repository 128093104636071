import React, { Component } from 'react';
import { connect } from 'react-redux'
import { signOut } from '../../stores/actions/authAction'
import { NavLink } from 'react-router-dom';

class SignedInLinks extends Component {

    componentDidUpdate = (prevProps, prevState) => {  

        const { logoutError, logoutSuccess } = this.props;

        if(prevProps.logoutError !== logoutError) {
            console.log(logoutError.message)
        }

        if(prevProps.logoutSuccess !== logoutSuccess) {
            window.location.href = '/';
        }
    } 

    signOut = () => {
        this.props.signOut();
    }

    render() {

        const { profile } = this.props;

        return(

        <ul className="navbar-nav mr-auto" style={{textTransform:"capitalize"}}>

            { profile.type === "admin" ?
                <li className="nav-item dropdown">
                    <span className="btn dropdown-toggle"role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="fas fa-robot"/>{'  '}Manager
                    </span>

                    <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <NavLink className="dropdown-item"  to="/systemmanager?topic=info&subtopic=help"><i className="fas fa-user-cog"/>{'  '}System Manager</NavLink>
                        <NavLink className="dropdown-item" to="/adminmanager?topic=enrollment&filter=new-enrollment"><i className="fas fa-user-shield"/>{'  '}Admin Manager</NavLink>
                    </div>
                </li>
            : null }

            <li className="nav-item dropdown">
                <span style={{fontWeight:"unset", textTransform:'capitalize', fontSize:"13px", borderRadius:"5px"}} className="btn bg-green text-white dropdown-toggle" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className="fas fa-user"/>{'  '}
                    {profile.isLoaded === true && profile.firstname && profile.lastname ?
                            profile.firstname + ' ' + profile.lastname
                    : 
                        'my account'
                    }
                </span>

                <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <NavLink className="dropdown-item" to="/userdashboard?type=myaccount&edit=false"><i className="far fa-address-card"></i>{'  '}My Account</NavLink>
                    <NavLink className="dropdown-item" to="/enrollment"><i className="fas fa-laptop"></i>{'  '}My Enrollments</NavLink>
                </div>
            </li>

            <li className="nav-item">
                <button onClick={this.signOut.bind(this)} style={{margin: '5px'}} className="btn" data-toggle="tooltip" title="Log Out">
                    <i className="fas fa-sign-out-alt"></i>
                </button>
            </li>

        </ul>

        )
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        profile: state.firebase.profile,
        logoutError: state.auth.logoutError,
        logoutSuccess: state.auth.logoutSuccess,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignedInLinks)