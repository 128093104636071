import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { storage } from '../../../config/fbConfig'

import { addProgram } from '../../../stores/actions/courseAction';

class Program extends Component{
    
    constructor(props){
        super(props)
        this.state = {
            programName:'',
            programStatus:'',
            programCode:'',
            programContent: '',
    
            selectedCourses:'',
            
            programImage: null,
            imageDisplay:'',
            imageProgress:'',
    
            searchCourse:'',
            selectedCourseCode:'',
    
            isLoading: null,
            message: null,
            addedProgramId: null
        }
        this.baseState = this.state 
    }
    
    componentDidUpdate = (prevProps, prevState) => { 
        const { addProgramError, addProgramSuccess } = this.props;

        if(prevProps.addProgramError !== addProgramError) {
            this.setState({
                isLoading: false,
                message: {
                    msg: addProgramError.message,
                    type: addProgramError.type,
                }
            })
        }

        if(prevProps.addProgramSuccess !== addProgramSuccess) {
            this.setState({
                isLoading: false,
                message: {
                    msg: addProgramSuccess.message,
                    type: addProgramSuccess.type,
                },
                addedProgramId: addProgramSuccess.id
            }, () => {
                setTimeout(() => { 
                    this.props.hide();
                    document.getElementById("clickAddProgram").click();
                    setTimeout(() => { 
                        this.setState(this.baseState)
                    }, 3000);
                }, 2000);
            })
        }
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    imageSelectHandler = event => {
        this.setState({ 
            programImage: event.target.files[0],
            imageDisplay: URL.createObjectURL(event.target.files[0]) //ubah imgUrl to pic
        });
    }

    selectCourse = (id, code) => {
        this.setState({
            selectedCourses: [ 
                ...this.state.selectedCourses, 
                id //get selected option ID
            ],
            selectedCourseCode: code
        });
    }

    removeCourse = (id) => {
        let removeCourse = this.state.selectedCourses.filter(selected => {
          return selected !== id
        })
    
        this.setState({
            selectedCourses: removeCourse
        })
    }

    addProgram = async (e) => {

        const { programImage } = this.state;

        var result = window.confirm("Confirm add?");
    
        if (result) {
            this.setState({
                isLoading: true
            })
            if ( programImage !== null ) {
                const uploadTask = storage.ref(`images/${programImage.name}`).put(programImage);

                uploadTask.on('state_changed', 
                    (snapshot) => {
                        const imageProgress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                        this.setState({
                            imageProgress: imageProgress
                        });
                    }, 

                    (error) => {
                        alert(error)
                    }, 

                    () => {
                        storage.ref('images').child(programImage.name).getDownloadURL().then(imgUrl => {
                        
                            this.setState({
                                programImage: {
                                    fileUrl: imgUrl,
                                    fileName: programImage.name
                                }
                            });
                            this.props.addProgram(this.state)
                        })
                    }
                );
            } else {
                this.props.addProgram(this.state)
            }
        }
    }

    render(){

        const { courses } = this.props;
        const { message, isLoading } = this.state;

        let filteredCourses = null;

        if(this.state.searchCourse.length > 0) { //filter user: search user by email
            filteredCourses = courses && courses.filter(course => {
                return course.courseCode.toLowerCase().includes(this.state.searchCourse.toLowerCase()) || 
                course.courseName.toLowerCase().includes(this.state.searchCourse.toLowerCase())
            })
        } else {
            filteredCourses = courses;
        }
        
        return(

            <div>          
                <div className="row">        
                    <p className="col col-md-4">
                        <b>Code:</b>
                        <input type="text" id="programCode" onChange={this.handleChange} className="form-control" value={this.state.programCode} placeholder="Code" />
                    </p>

                    <p className="col col-md-4">
                        <b>Name:</b>
                        <input type="text" id="programName" onChange={this.handleChange} className="form-control" value={this.state.programName}  placeholder="Name" />
                    </p>

                    <p className="col col-md-4">
                        <b>Status: </b>
                        <select id="programStatus" onChange={this.handleChange} className="form-control">
                            <option defaultValue>Choose status</option>
                            <option>Active</option>
                            <option>Not Active</option>
                        </select>
                    </p>
                </div>

                <div>
                    <div className="mt-4">
                        <b>Course: </b>
                        <div type="text" className="border-black">
                            { courses && courses.map(course => {
                                return (
                                    this.state.selectedCourses && this.state.selectedCourses.map((selected, i) => {
                                        if (course.id === selected) {
                                            return (
                                                <span className="badge bg-green text-white" key={i}>
                                                    {course.courseCode} {' '}
                                                    <span onClick={this.removeCourse.bind(this, selected)}>
                                                        <i className="fas fa-times"></i>
                                                    </span>
                                                </span>
                                            )
                                        }
                                        return null
                                    })
                                )
                            })}
                        </div>
                        
                        <small>Choose course here:</small>

                        <br/>

                        <div className="row col-md-5 btn-group dropdown">
                            <button type="button" className="btn border-black dropdown-toggle" data-toggle="dropdown">
                                { this.state.selectedCourseCode ? this.state.selectedCourseCode : 'Choose Course'}
                            </button>

                            <div className="dropdown-menu" >
                                <span className="dropdown-item" onClick={this.selectCourse.bind(this, '', '')} disabled>COURSE</span>
                                <span className="dropdown-item"><input placeholder="Search code..." className="form-control"  onChange={event => this.setState({searchCourse: event.target.value})}/></span>
                                { filteredCourses && filteredCourses.map((course,i) => {
                                    if (course.courseType === "Professional") {
                                        return (
                                            <span key={i} className="dropdown-item" onClick={this.selectCourse.bind(this, course.id, course.courseCode)}>{course.courseCode} - {course.courseName}</span>
                                        )
                                    }
                                    return null
                                })}
                            </div>
                        </div>

                        {/* <select className="form-control" onChange={this.selectCourse.bind(this)}>
                            <option defaultValue>Select Course</option>
                            {courses && courses.map(course => {
                                if (course.courseType === "Professional") {
                                    return (
                                        <option 
                                            key={course.id}
                                            id={course.id}
                                            > {course.courseCode} - {course.courseName}
                                        </option>
                                    )
                                }
                            })}
                        </select> */}
                    </div>   
                </div>

                <p>
                    <b>Content:</b>
                    <textarea id="programContent" type="text" className="form-control"  onChange={this.handleChange} value={this.state.programContent}  placeholder="Content"  />
                </p>

                <div className="mt-4 mb-5">
                    <b>Featured Image:</b><br />
                    { this.state.imageDisplay ?
                        <div><img alt="program2" src={this.state.imageDisplay} width="30%" className="text-center"/></div>
                    : 
                        null
                    }
                    <input className="mt-2" type="file"  onChange={this.imageSelectHandler.bind(this)}/><br />
                    <progress value={this.state.imageProgress} max="100" style={{width:"250px"}}/>
                </div>

                { isLoading === true ? 
                    <small>Loading...</small>
                : 
                    message  ?
                        <small className={message.type === 'addProgramError' ? "text-red" : message.type === 'addProgramSuccess'  ? "text-green" : null }>
                            {message.msg}
                        </small>
                    : null
                }

                <div className="mt-5 row justify-content-end">
                    <button type="button"
                        className="btn m-2 btn-outline-black" onClick={this.addProgram.bind(this)}><i className="fas fa-plus-circle"/>{' '}add program
                    </button>
                </div>

                <div hidden={true}>
                    <Link id="clickAddProgram" to={`/systemmanager?topic=course&subtopic=program&id=${this.state.addedProgramId}&code=${this.state.programCode}`} />
                </div>
            </div>

        )
    }
}

const mapStateToProps = (state) => {
  return {
      addProgramError: state.courses.addProgramError,
      addProgramSuccess: state.courses.addProgramSuccess,
  }
}
  
const mapDispatchToProps = (dispatch) => {
    return {
      addProgram: (program) => dispatch(addProgram(program))
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Program);