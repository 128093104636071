const initState = {
    users: []
}

const userReducer = (state = initState, action ) => {

    switch (action.type) {

        case 'UPDATED_USER':
            return {
                ...state,
                updateAccountSuccess: {
                    message: action.message,
                    type: 'updateAccountSuccess'
                }
            }
        case 'UPDATED_USER_ERROR':
            return {
                ...state,
                updateAccountError: {
                    message: action.message,
                    type: 'updateAccountError'
                }
            }

        case 'UPDATED_USER_CONTACT_NO':
            return state;
        case 'UPDATED_USER_CONTACT_NO_ERROR':
            return state;

         case 'CHANGE_PASSWORD_SUCCESS':
            return {
                ...state,
                changePasswordSuccess: {
                    message: action.message,
                    type: 'changePasswordSuccess'
                }
            }
        case 'CHANGE_PASSWORD_ERROR':
            return {
                ...state,
                changePasswordError: {
                    message: action.err.message,
                    type: 'changePasswordError'
                }
            }

        default:
            return state;
    }
}

export default userReducer