import React, { Component } from 'react';

class HalalIndustry extends Component{

    render(){

        return(
            
            <div className="mhc min-height">

                <div className="middle page-header">
                    {/* <div className="col col-md-4" style={{marginBottom:"30px"}}> 
                        <img src="../../../image/HA_LOGO.png" alt="" width="45%"/><br />
                    </div> */}

                    <div className='col-md-8'>
                        <h1>halal industry
                            <div className="bg-white title-border"></div>
                        </h1>
                    </div>
                </div>

                <div className="page-content middle">
                    <div className="col-md-8">
                        <label>The halal industry scenario:</label>
                        <br/>
                        The halal industry has now expanded beyond the food sector to include pharmaceuticals, cosmetics, health products, 
                        toiletries and medical devices as well as service sector components such as logistics, marketing, print and electronic 
                        media, packaging, branding, and financing. In addition, the halal food marketplace is emerging as one of the most 
                        profitable and influential market arenas in the world food business today.
                        <br/><br/>
                        The worldwide Market for Halal is valued at 1294.5 million USD in 2020 is expected to reach 1911.3 million USD by 
                        the end of 2026, growing at a CAGR of 5.7% during 2021-2026.

                        <br/><br/><br/>

                        <label>Malaysia Halal Certification:</label>
                        <br/>
                        The halal certification is related to Halalan Toyyiban, which goes beyond an absence of pork and alcohol, 
                        and covers other aspects such as slaughtering, cleanliness and safety.
                        <br/><br/>
                        The Department of Islamic Development Malaysia (JAKIM) is the competent authority responsible for halal certification 
                        in Malaysia. Applicants who are eligible to apply for the Halal Confirmation Certificate are categorized as follows:
                        <br/>
                        <ul>
                            <li>Food Product/ Beverages/ Food Supplement</li>
                            <li>Food Premise</li>
                            <li>Consumer Good</li>
                            <li>Cosmetic and Personal Care</li>
                            <li>Pharmaceuticals</li>
                            <li>Logistics</li>
                            <li>Slaughterhouse</li>
                            <li>OEM</li>
                            <li>Medical devices</li>
                        </ul> 
                        
                        <br/>

                        <label>Why should you obtain halal certification?</label>
                        <br/>
                        <ul>
                            <li>To know that the products are completely halal as verified by an expert.</li>

                            <li>Halal certification is significant in businesses as it can be a marketing tool to gain trust from customers. 
                            It is also an act of fardu kifayah.</li>

                            <li>Having a halal certificate is a great marketing tool to help entrepreneurs market their products well. 
                            The products can penetrate the local market comprising Aeon, Giant and Mydin.</li>


                            <li>Since the halal certification by JAKIM is well known worldwide, it will be easily accepted by all countries. 
                            According to a study in 2015, Islam has 1.8 billion adherents around the world with a market spending estimate 
                            of about US$2,300 billion and the number grows by six per cent every year.</li>

                            <li>With this in mind, it shows that the international market is vast and sales will likewise increase. 
                            A wise entrepreneur will choose to be halal certified.</li>

                            <li>Gaining the trust of customers is integral to the success of any business. Users will have more confidence 
                            if the product is certified by authorities, especially the government. The added value is a guarantee for 
                            consumers that the products are of high quality because the halal certificate is the ultimate proof of meeting 
                            those standards.</li>
                        </ul>

                        <br/>

                        <i className='text-left'>
                            <b>Sources:</b>
                            <br/>
                            MIFB <br/>
                            NST <br/>
                            Market Watch
                        </i>
                    </div>
                 </div>
            </div>

        )
    }
}

export default HalalIndustry;