export const addAd = (data) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {

        const firestore = getFirestore();

        firestore.collection('ads').add({
            fileName: data.fileName,
            fileUrl: data.fileUrl
        }).then(() => { 
            dispatch({ type:'ADDED_AD', message: 'Ad added successfully.'}); 
        }).catch((err) => {
            dispatch({ type:'ADDED_AD_ERROR', err});
        })
    }
}

export const deleteAd = (adId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {

        const firestore = getFirestore();

        firestore.collection("ads").doc(adId).delete()
        .then(() => {
            dispatch({ type:'DELETED_AD', message: 'Ad deleted successfully.'});
        }).catch((err) => {
            dispatch({ type:'DELETED_AD_ERROR', err});
        })
    }
}