import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';

import { deleteTopic } from '../../../stores/actions/courseAction';

import EditTopic from './EditTopic'

class DisplayTopic extends Component {

    state = {
        hideDetails : false,
        hideEdit: true,
        id:'',

        selectedNote_id: '',
    }
    
    componentDidUpdate = (prevProps, prevState) => {

        const { deleteTopicError, deleteTopicSuccess } = this.props;

        if(prevProps.deleteTopicError !== deleteTopicError) {
            alert(deleteTopicError.message);
        }
        
        if(prevProps.deleteTopicSuccess !== deleteTopicSuccess) {
            alert(deleteTopicSuccess.message);
            document.getElementById("clickTopic").click();
        }
    } 

    handleEdit = (topic_id) => {
        this.setState({
          hideDetails : true,
          hideEdit: false,
          id: topic_id
        })
    }

    cancel = () => {
        this.setState({
            id:''
        })
    }

    deleteTopic = (topic_id) => {

        var result = window.confirm("Confirm delete?");
    
        if (result) {
            this.props.deleteTopic(topic_id)
        }
    }

    displayModalNoteFile_Display = (id) => { //display file modal
        this.setState({
            selectedNote_id: id,
        })
        document.getElementById("display_note_file_display").style.display = "block";
    }

    hideModalNoteFile_Display = () => { //hide file modal
        document.getElementById("display_note_file_display").style.display = "none";
    }

    render(){

        const { query, topics, subtopics, topic, topic_id } = this.props;
        
        let query_id = query.get('id');
        let isLoading = true;

        const topicDetails = topic ? (
            <div key={topic_id}>
                { isLoading = false }
                { this.state.id === topic_id ?
                    <EditTopic topics={topics} subtopics={subtopics} cancel={this.cancel} topic={topic} topic_id={topic_id} />
                :
                    <div>
                        <div className="mt-2"><b>Code: </b>{topic.topicCode}</div>
                        <div className="mt-2"><b>Name: </b>{topic.topicName}</div>
                        
                        <div className="mt-2"><b>Subtopics: </b></div>
                        <div style={{marginLeft:"5%"}}>
                            { topic.subtopics && topic.subtopics.map((subtopic,i) => {
                                return (
                                    <li key={i}>{subtopic.subtopic}</li>
                                )
                            })}
                        </div>

                        <br/>

                        <div className="mt-2"><b>Note: </b> <br/>
                            {topics && topics.map((topic,i) => {
                                if (topic.id === topic_id && topic.selectedNote !== null) {
                                    return (
                                        <span key={i} onClick={this.displayModalNoteFile_Display.bind(this, topic_id)}> {topic.selectedNote.fileName}</span>
                                    )
                                } else if (topic.id === topic_id && topic.selectedNote === null) {
                                    return (
                                        <em key={i}>No selected note.</em>
                                    )
                                }
                                return null
                            })}
                        </div>

                        <div className="mt-2"><b>Video: </b> <br/>
                            {topics && topics.map((topic,i) => {
                                if (topic.id === topic_id && topic.selectedVideo !== null) {
                                    if(topic.selectedVideo.videoType === "Upload") {
                                        return (
                                            <div key={i}>
                                                <video width="300" height="200" src={topic.selectedVideo.fileUrl} type="video/mp4" controls /> <br/>
                                                <label>{topic.selectedVideo.fileName}</label>
                                            </div>
                                        )
                                    } else if (topic.selectedVideo.videoType === "Youtube") {
                                        return (
                                            <p key={i}>{topic.selectedVideo.fileUrl}</p>
                                        )
                                    }
                                } else if (topic.id === topic_id && topic.selectedVideo === null) {
                                    return (
                                        <em key={i}>No selected video.</em>
                                    )
                                }
                                return null
                            })}
                        </div>

                        <div className="mt-5 row justify-content-end">
                            <button type="button" className="btn m-2 btn-outline-black" onClick={this.handleEdit.bind(this, topic_id)}><i className="fas fa-edit"></i></button>
                            <button type="button" className="btn m-2 btn-outline-red" onClick={this.deleteTopic.bind(this, topic_id)}><i className="fas fa-trash"></i></button>
                        </div>
                    </div>
                }
            </div>

        ) : (
            null
        )

        return(

            <div className="container col-md-12">

                <div id="display_note_file_display" className="mymodal">
                    <div className="mymodal-content col-md-6">
                        <span className="close" onClick={this.hideModalNoteFile_Display}>&times;</span>

                        {/* takleh nka display direct guna props */}

                        {topics && topics.map((topic,i) => {
                            if(this.state.selectedNote_id === topic.id){
                                return (
                                    <div key={i}>
                                        <h3 className="middle">{topic.selectedNote.fileName}
                                            <div className="row"><div className="title-border"></div></div>
                                        </h3>
                                        
                                        <object aria-label="display-pdf" className="display-pdf" data={topic.selectedNote.fileUrl}></object>
                                    </div>
                                )
                            }
                            return null
                        })}
                    </div>
                </div>

                { query_id ?
                    isLoading === true ? <div>Data loading... Please wait...</div> : topicDetails
                : <div>Topic data not available.</div> }

                <div hidden={true}>
                    <Link id="clickTopic" to={`/systemmanager?topic=course&subtopic=topic`} />
                </div>
            </div>
        
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const id = ownProps.selectedTopicId;
    const topics = state.firestore.data.topics;
    const topic = topics ? topics[id] : null

    return {
        topic: topic,
        topic_id: id,
        
        deleteTopicError: state.courses.deleteTopicError,
        deleteTopicSuccess: state.courses.deleteTopicSuccess,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
      deleteTopic: (topic_id) => dispatch(deleteTopic(topic_id))
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(DisplayTopic)