import React, { Component } from 'react';
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'

import { Redirect } from 'react-router-dom'
import { compose } from 'redux'
import moment from 'moment'
import ReactCountdownClock from 'react-countdown-clock';

import { exam_startDate, exam_finishDate, updateEnrolledCourse_isDoneExam, updateEnrolledExam_isDone, updateEnrolledExam_examScore, updateEnrolledExam_examFailCounter, updateEnrolledExam_suspend, updateEnrolledExam_examAttend } from '../../stores/actions/enrollmentAction';

class Exam extends Component {

    state = {
        hasClass: "",

        showStartBtn: false,
        startBtnCounter: null,

        isPaused: true,
        hideBtnStart: false,
        hideTimer: true,
        hideMarks: true,
        hideAfterSubmit: false,

        questions: [],
        questionIds: [],
        questionCount: 0,
        idQuestionIndex0: '',
        answerStatus: '',
        currentQuestionId:'',
        nextQuestionId:'',
        nextQuestionIndex:0,

        enrolledCourseId:'',

        examSuspendNumber: 0, 
        examSuspendDay: 0, 
        examTimer: 0, 
        examMark: 0, 

        examFailCounter: 0,
        examSuspendCounter: 0,
        examScore: 0,
        updateExamFailCounter: 0,

        tempAnswerStatus:'', //to validate user pick jwpn bfr next

        marks: 0,
        totalMark: 0,
        totalTrueMark: 0
    }

    componentDidMount = () => {
        this.loadStartBtn(10);
    }

    loadStartBtn = (seconds) => {
        
        let counter = seconds;

        const interval = setInterval(async () => {
            counter--;
            this.setState({ startBtnCounter: counter })
            if (counter === 0 ) {
                this.setState({ showStartBtn: true })
                clearInterval(interval);
            }
        }, 1000);
    }

    setExamData = async () => {
        const { enrolledExam } = this.props;

        this.setState({
            examFailCounter: enrolledExam.examFailCounter,
            examSuspendCounter: enrolledExam.examSuspendCounter,
            examScore: enrolledExam.examScore,
        })
    }

    startQuestion = async () => {

        const { auth, enrolledExam_id, enrolledExam, courseEnrollments, examEnrollments, courses, exams, questions} = this.props;

        await this.setExamData();

        //supposed defined dlm componentDidMoount, tapi dia tak load all props (sbb CDM load after render), tak sempat nak render all props, dia dah baca CDM :(
        let quests = [], questionCount = 0, enrolledCourseId = '',
        examSuspendNumber = 0, examSuspendDay = 0, examTimer= 0, examMark = 0,
        hasClass = "";

        let examStartDate = "";

        examEnrollments && examEnrollments.map(enrolled => {
            if (auth.uid === enrolled.userId && enrolled.id === enrolledExam_id) {

                examStartDate = enrolled.startDate;

                courses && courses.map(course => {
                    if (enrolled.courseId === course.id) {
                        
                        examSuspendDay = course.exam.examSuspendDay;
                        examSuspendNumber = course.exam.examSuspendNumber;
                        examTimer = course.exam.examTimer;
                        examMark = course.exam.examMark;

                        hasClass = course.hasPhysicalClass;

                        return (
                            exams && exams.map(exam => {
                                if (course.exam.selectedExamSet === exam.id) {

                                    questionCount = course.exam.questionCount;

                                    exam.selectedQuestions && exam.selectedQuestions.map(selectedQuest => {
                                        return (
                                            questions && questions.map(question => {
                                                if (selectedQuest === question.id) {
                                                    quests.push(question);
                                                }
                                                return null
                                            })
                                        )
                                    })
                                }
                                return null
                            })
                        )
                    }
                    return null
                })
            }
            return null
        })

        courseEnrollments && courseEnrollments.map(enrolled => { //utk update isDoneExam dlm courseEnrollments
            if (auth.uid === enrolled.userId && enrolled.courseId === enrolledExam.courseId) {
                enrolledCourseId =  enrolled.id
            }
            return null
        })

        //shuffle soalan---------------------------------------------------------

        let shuffledQuestions = [];
        let shuffledIds = [];
        let totalMark = 0;

        const arr = this.generateShuffledArray(quests.length);
        let randomIndex = arr.splice(0, questionCount);

        for(let x=0; x<questionCount; x++){
            if(quests[randomIndex[x]] === undefined){
                  continue;
             } else {
                shuffledQuestions.push(quests[randomIndex[x]]);
             }
        }

        shuffledQuestions && shuffledQuestions.map(shuffled => { //get ids for shuffled questions
            shuffledIds.push(shuffled.id);
            totalMark = totalMark + shuffled.questionMark //get TOTAL MARK

            return null
        })
        
        let idQuestionIndex0 = shuffledIds[0];

        let attended = {
            examAttend: true,
            examFailCounter:  this.state.examFailCounter + 1
        }

        //check if valid to be suspend---------------------------------------------------------
        let examSuspendDate = moment().format('YYYY-MM-DD');
        let suspendDayCount = moment(examSuspendDate).add(examSuspendDay, 'days');
        const examAvailableDate = moment(suspendDayCount).format('YYYY-MM-DD');

        let examSuspendCount =  this.state.examSuspendCounter + 1;

        if (attended.examFailCounter === examSuspendNumber) { //suspend
            this.props.updateEnrolledExam_suspend(this.props.enrolledExam_id, examAvailableDate, examSuspendCount);
        }

        this.props.updateEnrolledExam_examAttend(attended, this.props.enrolledExam_id) //update attended and failCounter+1

        this.setState({
            hasClass, 
            idQuestionIndex0: idQuestionIndex0,
            isPaused: false,
            hideBtnStart: true,
            hideTimer: false,
            updateExamFailCounter: attended.examFailCounter,
            
            questions: shuffledQuestions,
            questionIds: shuffledIds,
            questionCount: questionCount,

            examSuspendNumber: examSuspendNumber,
            examSuspendDay: examSuspendDay,
            examTimer: examTimer,
            examMark: examMark,

            enrolledCourseId: enrolledCourseId,

            totalMark
        })

        if(examStartDate === "") {
            this.props.exam_startDate(enrolledExam_id);
        } else return null

    }

    generateShuffledArray(n){
        return this.shuffleArray(this.generateArray(n))
    }

    shuffleArray(arr){
        let temp = null;
        const arrLength = arr.length
        for (let i = 0; i < arrLength - 1; i++){
            let j = this.getRandomInt(i, arrLength)
            temp = arr[i]
            arr[i] = arr[j]
            arr[j] = temp
        }
        return arr
    }

    getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min)) + min;
    }

    generateArray(n) {
        let arr = []
        for (let i = 0; i < n; i++){
            arr.push(i)
        }
        return arr
    }

    selectAnswer = (index, answerStatus) => { //try delete index, tapi nanti dia baca second question answerStatus value as index, bukan true/false
        this.setState({
            answerStatus: answerStatus,
            tempAnswerStatus: answerStatus
        })
    }

    nextQuestion = (index, questionId, questionMark) => {
        let nextQuestionIndex =  index + 1;
        let questionIds = this.state.questionIds;
        let nextQuestionId = questionIds[nextQuestionIndex];

        let answerStatus = this.state.answerStatus;
        let tempAnswerStatus = this.state.tempAnswerStatus;

        if (tempAnswerStatus === "" || null) {
            alert("choose answer!")
        } else {
            if(answerStatus === "true" || answerStatus === true) {
                this.setState({
                    totalTrueMark: this.state.totalTrueMark + questionMark
                })
            }
            this.setState({
                tempAnswerStatus: "", //utk validate user pick jawapan
                currentQuestionId: questionId,
                nextQuestionId: nextQuestionId,
                nextQuestionIndex: nextQuestionIndex
            })
        }
    }

    finishQuestion = (questionMark, timeout) => {
        let answerStatus = this.state.answerStatus;
        let tempAnswerStatus = this.state.tempAnswerStatus;

        if (timeout === true) {
            document.getElementById("title-modal").innerHTML = "Time Out!";
            document.getElementById("modal-submit").style.display = "block";
        } else {
            if (tempAnswerStatus === "" || null) {
                alert("choose answer!")
            } else {
                if(answerStatus === "true" || answerStatus === true) {
                    this.setState({
                        totalTrueMark: this.state.totalTrueMark + questionMark
                    })
                }
                this.setState({
                    tempAnswerStatus: "", //utk validate user pick jawapan
                    isPaused: true,
                })
                document.getElementById("modal-submit").style.display = "block";
            }
        }
    }

    submit = () => {

        let updateExamFailCounter = 0;

        let oldScore = this.props.enrolledExam && this.props.enrolledExam.examScore;
        let marks = Math.round((this.state.totalTrueMark/this.state.totalMark)*100);

        if (marks >= this.state.examMark) { //lulus

            let deleteFailed = {
                examAttend: true,
                examFailCounter: this.state.updateExamFailCounter - 1
            }

            this.props.updateEnrolledExam_isDone(this.props.enrolledExam_id); //exam isDone === true
            this.props.updateEnrolledCourse_isDoneExam(this.state.enrolledCourseId); //exam isDone === true

            if (this.state.updateExamFailCounter === this.state.examSuspendNumber) {
                this.props.updateEnrolledExam_suspend(this.props.enrolledExam_id, "", this.state.examSuspendCounter); //-1 suspendCounter @ startBtn
                this.props.updateEnrolledExam_examAttend(deleteFailed, this.props.enrolledExam_id) //-1 back failCounter @ startBtn
            }

            this.props.exam_finishDate(this.props.enrolledExam_id);

        } else { //gagal

            updateExamFailCounter = this.state.updateExamFailCounter - 1 + 1; //-1 from added at START tadi, and +1 sbb fail
            this.props.updateEnrolledExam_examFailCounter(this.props.enrolledExam_id, updateExamFailCounter); //update fail counter kalau fail only

            //cek if valid to be suspended
            let examSuspendNumber = this.state.examSuspendNumber;
            let examSuspendDay = this.state.examSuspendDay;

            let examSuspendDate = moment().format('YYYY-MM-DD');
            let suspendDayCount = moment(examSuspendDate).add(examSuspendDay, 'days');
            const examAvailableDate = moment(suspendDayCount).format('YYYY-MM-DD');

            let examSuspendCount =  this.state.examSuspendCounter + 1;

            if (updateExamFailCounter === examSuspendNumber) { //suspend
                this.props.updateEnrolledExam_suspend(this.props.enrolledExam_id, examAvailableDate, examSuspendCount);
            }
        }

        if(oldScore <= marks) {
            this.props.updateEnrolledExam_examScore(this.props.enrolledExam_id, marks); //update markah dlm db
        }

        this.setState({
            updateExamFailCounter: updateExamFailCounter,
            marks,
            hideMarks: false,
            hideAfterSubmit: true
        })
    }

    hideModal = () => {
        document.getElementById("modal-submit").style.display = "none";
        this.props.history.push('/enrollment');
    }

    render(){

        const { enrolledExam, auth, examEnrollments, courses, enrolledExam_id } = this.props;

        if (!auth.uid) return <Redirect to="/" />

        let examMark = 0, examTimer = 0, examQCount = 0, examSuspendDay = 0, examSuspendNumber = 0;

        let questionCount = this.state.questionCount - 1;
        let questions = this.state.questions;

        let question = questions && questions.map((question,i) => {
            let no = i + 1;

            if(question.id === this.state.idQuestionIndex0) {

                return (
                    <div id="question1" key={question.id}>
                        <div className="listing ka-question"> 
                            <div className="list-icon">{no}.</div>
                            <label>
                                {question.questionContent1}
                            </label>
                        </div>
                        {/* <p className="ka-question">{no}. {'  '} {question.questionContent1}</p>  */}
                        <p className="ka-option" dangerouslySetInnerHTML={{__html: question.questionContent2}}></p>
                        <div className="ka-answer">
                            <ul>
                            {question.answers && question.answers.map((answer,index) => {
                                return (
                                    <div key={index} className="listing"> 
                                        <div className="list-icon"><input id={answer.id} onChange={this.selectAnswer.bind(this, index, answer.answerStatus)} type="radio" name="options"/></div>
                                        <label htmlFor={answer.id}>
                                            {answer.answerContent} 
                                            {/* {' '} <label className={answer.answerStatus === true ? "text-green" : "text-red"}>{answer.answerStatus.toString()}</label> */}
                                        </label>
                                    </div>
                                )
                            })}
                            </ul>
                        </div>
                        <div className="col text-right">
                            <button className="justify-content-center btn all-btn" onClick={this.nextQuestion.bind(this, i, question.id, question.questionMark)}>
                                Next Questions {'  '}<i className="fas fa-arrow-alt-circle-right"></i>
                            </button>
                        </div>
                    </div>

            )} else if (question.id === this.state.nextQuestionId) {

                document.getElementById('question1').style.display = "none";

                return (
                    <div key={question.id}>
                         <div className="listing ka-question"> 
                            <div className="list-icon">{no}.</div>
                            <label>
                                {question.questionContent1}
                            </label>
                        </div>
                        {/* <p className="ka-question">{no}. {'  '} {question.questionContent1}</p>  */}
                        <p className="ka-option" dangerouslySetInnerHTML={{__html: question.questionContent2}}></p>
                        <div className="ka-answer">
                            <ul>
                            {question.answers && question.answers.map((answer,index) => {
                                return (
                                    <div key={index} className="listing"> 
                                        <div className="list-icon"><input id={answer.id} onChange={this.selectAnswer.bind(this, index, answer.answerStatus)} type="radio" name="options"/></div>
                                        <label htmlFor={answer.id}>
                                            {answer.answerContent} {' '}
                                            {/* <label className={answer.answerStatus === true ? "text-green" : "text-red"}>{answer.answerStatus.toString()}</label> */}
                                        </label>
                                    </div>
                                )
                            })}
                            </ul>
                        </div>
                        <div className="col text-right">
                        
                        { this.state.nextQuestionIndex === questionCount ?
                            <button onClick={this.finishQuestion.bind(this, question.questionMark, null)} className="justify-content-center btn all-btn"> 
                                Finish{'  '}<i className="fas fa-check-circle"></i>
                            </button>
                        :
                            <button className="justify-content-center btn all-btn" onClick={this.nextQuestion.bind(this, i, question.id, question.questionMark)}>
                                Next Question {'  '}<i className="fas fa-arrow-alt-circle-right"></i>
                            </button>
                        }
                        </div>
                    </div>
                )
            }
            return null
        })

        examEnrollments && examEnrollments.map(enrolled => {
            return (
                courses && courses.map(course => {
                    if(auth.uid === enrolled.userId && enrolled.id === enrolledExam_id && enrolled.courseId === course.id) {
                        examMark = course.exam.examMark
                        examQCount = course.exam.questionCount
                        examTimer = course.exam.examTimer
                        examSuspendDay = course.exam.examSuspendDay
                        examSuspendNumber = course.exam.examSuspendNumber
                    }
                    return null
                })
            )
        })

        let courseName = "";

        if(enrolledExam) {
            courses && courses.map(course => {
                if(enrolledExam.courseId === course.id) {
                    courseName = course.courseName;
                }
                return null
            })
        }

        return(

        <div className="page-learning learning min-height">
            
            <div id="modal-submit" className="middle normalmodal">
                <div className="normalmodal-content col-md-4">
                <span className="close" onClick={this.hideModal}>&times;</span>

                    <div hidden={this.state.hideAfterSubmit}>
                        <h5 id="title-modal">You're finished!</h5>
                        <p>Click submit to get your marks.</p>
                    </div>

                    { this.state.marks >= this.state.examMark ?
                       <div className="middle" hidden={this.state.hideMarks}>

                            <body className='notranslate'>
                                <p className="marks-success middle"> {this.state.marks}% </p>
                            </body>

                            <h5>Congratulations!</h5> 
                            <small>You have successfully finished this course.</small>
                            { this.state.hasClass === "true" || this.state.hasClass === true ?
                                <div className="whats-next">
                                    <h1>What to do next?</h1>
                                    <label className="text-left">
                                        Please proceed to request for the 4-days practical session on the <b>My Enrollments</b> page. <br/><br/>
                                        <b>Note: </b> Request only if you have not attend the 4 days practical session with Halal Academy, else your request will be declined.
                                    </label>
                                </div>
                            : null }
                        </div>
                    :
                        <div className="middle" hidden={this.state.hideMarks}>
                            
                            <body className='notranslate'>
                                <p className="marks-failed middle"> {this.state.marks}% </p>
                            </body>

                            {this.state.examSuspendNumber === this.state.updateExamFailCounter ?
                                <h5>You have been suspended!</h5>
                            :
                                <h5>Try harder!</h5>
                            }
                        </div>
                    }

                    <button hidden={this.state.hideAfterSubmit} className="justify-content-center btn all-btn" onClick={this.submit.bind(this)}>
                        Submit Answers
                    </button>
                </div>
            </div>

            <div className="page-header middle">
                <b>Exam</b>
                <p>{courseName} </p>
                <div className="title-border"></div>
            </div>
            
            <div className='page-content'>
                <div className='middle'>
                    <div className='text-center col-md-8'>
                        <div hidden={this.state.hideBtnStart}>

                            <div className="alert alert-warning text-left" style={{margin:"0px 0px 40px 0px"}}>
                                <div className="listing"> 
                                    <div className="list-icon"><li></li></div>
                                    <span>Consists <b>{examQCount}</b> questions for <b>{examTimer/60}</b> minutes duration.</span>
                                </div>
                                <div className="listing"> 
                                    <div className="list-icon"><li></li></div>
                                    <span>Pass rate is <b>{examMark}%</b></span>
                                </div>
                                <div className="listing"> 
                                    <div className="list-icon"><li></li></div>
                                    <span>You will be suspended for <b>{examSuspendDay} day(s)</b> if you failed <b>{examSuspendNumber} consecutive attempts</b>.</span>
                                </div>
                                <div className="listing"> 
                                    <div className="list-icon"><li></li></div>
                                    <span>You will be <b>counted as failed 1 time</b> even if you are just <b>entering the exam without answering</b>.</span>
                                </div>
                            </div>

                            { this.state.showStartBtn === true ?
                                enrolledExam && enrolledExam.examAvailableDate === "" ?
                                    <button className="btn all-btn" onClick={this.startQuestion.bind(this)}> Start Exam {'  '}<i className="fas fa-arrow-alt-circle-right"></i> </button>
                                : 
                                    <button className="btn btn-disable"> Start Exam {'  '}<i className="fas fa-arrow-alt-circle-right"></i> </button>
                            :
                                <p>Please wait for <b>{this.state.startBtnCounter}</b> second(s)</p>
                            } 
                        </div>
                        
                        <div className="row justify-content-center col-md-12">
                            <div  className="timer"hidden={this.state.hideTimer}>
                                <ReactCountdownClock 
                                    paused={this.state.isPaused}
                                    seconds={examTimer}
                                    color="#ffcb63"
                                    alpha={0.9} 
                                    size={120}
                                    onComplete={this.finishQuestion.bind(this, null, true)}
                                />  
                            </div>
                        </div>

                        <div style={{marginTop:"50px"}} className="container col-md-10 text-left border-0">
                            {question}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        )
    }
}


const mapStateToProps = (state, ownProps) => {

    const enrolledExam_id = ownProps.match.params.enrolledExam_id;

    const examEnrollments = state.firestore.data.examEnrollments;
    const enrolledExam = examEnrollments ? examEnrollments[enrolledExam_id] : null

    return {
        profile: state.firebase.profile,
        auth: state.firebase.auth,

        enrolledExam_id: enrolledExam_id,
        enrolledExam: enrolledExam,

        courses: state.firestore.ordered.courses,
        exams: state.firestore.ordered.exams,
        questions: state.firestore.ordered.questions,
        examEnrollments: state.firestore.ordered.examEnrollments,
        courseEnrollments: state.firestore.ordered.courseEnrollments,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
      updateEnrolledExam_isDone: (enrolledExamId) => dispatch(updateEnrolledExam_isDone(enrolledExamId)),
      updateEnrolledCourse_isDoneExam: (enrolledCourseId) => dispatch(updateEnrolledCourse_isDoneExam(enrolledCourseId)),
      updateEnrolledExam_examScore: (enrolledExamId, marks) => dispatch(updateEnrolledExam_examScore(enrolledExamId, marks)),
      updateEnrolledExam_examFailCounter: (enrolledExamId, counter) => dispatch(updateEnrolledExam_examFailCounter(enrolledExamId, counter)),
      updateEnrolledExam_suspend: (enrolledExamId, date, examSuspendCount) => dispatch(updateEnrolledExam_suspend(enrolledExamId, date, examSuspendCount)),
      updateEnrolledExam_examAttend: (attended, enrolledExamId) => dispatch(updateEnrolledExam_examAttend(attended, enrolledExamId)),
      
      exam_startDate: (enrolledExamId) => dispatch(exam_startDate(enrolledExamId)),
      exam_finishDate: (enrolledExamId) => dispatch(exam_finishDate(enrolledExamId)),
      
    }
}
  
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect( props => [
        { collection: 'courses'},
        { collection: 'exams'},
        { collection: 'questions'},
        { collection: 'examEnrollments', where: props.auth.uid ? ['userId', '==', props.auth.uid] : null },
        { collection: 'courseEnrollments', where: props.auth.uid ? ['userId', '==', props.auth.uid] : null },
    ])
)(Exam);