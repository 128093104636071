import React, { Component } from 'react';
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase';
import { Link, Redirect } from 'react-router-dom'

import CourseManager from './CourseManager/CourseManager';
import QuestionManager from './QuestionManager/QuestionManager';
import InfoManager from './InfoManager/InfoManager';
import EmailManager from './EmailManager/EmailManager';
import FaqManager from './FaqManager';
import AdsManager from './AdsManager';

import EventsManager from './EventsManager/EventsManager';

class SystemManager extends Component{

    render(){

        const { profile, auth, ads, users, supportEmail, faqs, programs, courses, infos, questions, topics, quizzes, modules, exams, events, eventsList, eventsAttendees } = this.props;
        
        const query = new URLSearchParams(this.props.location.search);
        let query_topic = query.get('topic');

        if(profile && profile.userId === auth.uid){
            if(profile.type !== "admin") {
                return <Redirect to="/" />
            }
        }

        return(
        <div className="dashboard" >
            <div className="justify-content-center row col-md-12">
                <div className="col-md-3" >
                    <div className="dashboard-left middle">
                        <i style={{marginBottom:"10px"}} className="text-green fas fa-user-cog fa-3x"/>
                        <h3>system manager </h3>  
                        <nav className="middle navbar navbar-expand-lg navbar-light bg-transparent">
                            <button className="btn navbar-toggler border-0 " type="button" data-toggle="collapse" data-target="#navbarSupportedContent2" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>

                            <div className="collapse navbar-collapse" id="navbarSupportedContent2">
                                <div>
                                    <Link className={query_topic === 'info' ? "col btn all-btn btn-active" : "col btn all-btn"} to="/systemmanager?topic=info&subtopic=help">info management</Link>
                                    <Link className={query_topic === 'course' ? "col btn all-btn btn-active" : "col btn all-btn"} to="/systemmanager?topic=course">course management</Link>
                                    <Link className={query_topic === 'question' ? "col btn all-btn btn-active" : "col btn all-btn"} to="/systemmanager?topic=question">question management</Link> 
                                    <Link className={query_topic === 'supportEmail' ? "col btn all-btn btn-active" : "col btn all-btn"} to="/systemmanager?topic=supportEmail&type=ts&filter=">Support management</Link>
                                    <Link className={query_topic === 'faq' ? "col btn all-btn btn-active" : "col btn all-btn"} to="/systemmanager?topic=faq&filter=">FAQ management</Link>
                                    <Link className={query_topic === 'ad' ? "col btn all-btn btn-active" : "col btn all-btn"} to="/systemmanager?topic=ad">ad management</Link>
                                    <Link className={query_topic === 'events' ? "col btn all-btn btn-active" : "col btn all-btn"} to="/systemmanager?topic=events">events management</Link>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>

                <div className="col dashboard-right">
                    { query_topic === 'course'?
                    <CourseManager query={query} topics={topics} quizzes={quizzes} modules={modules} exams={exams} programs={programs} courses={courses} questions={questions}/>

                    :query_topic === 'question'?
                    <QuestionManager query={query} questions={questions} courses={courses} modules={modules}/>

                    :query_topic === 'info'?
                    <InfoManager query={query} infos={infos} />

                    : query_topic === 'supportEmail'?
                    <EmailManager query={query} users={users} supportEmail={supportEmail} />

                    : query_topic === 'faq'?
                    <FaqManager query={query} faqs={faqs} />

                    : query_topic === 'ad'?
                    <AdsManager ads={ads}  />

                    : query_topic === 'events'?
                    <EventsManager query={query} events={events} eventsList={eventsList} programs={programs} eventsAttendees={eventsAttendees} />

                    :''}
                </div>
            </div>
        </div>

        
        )
    }
}

const mapStateToProps = (state) => {
    return {
        profile: state.firebase.profile,
        auth: state.firebase.auth,

        users: state.firestore.ordered.users,
        questions: state.firestore.ordered.questions,
        topics: state.firestore.ordered.topics,
        quizzes: state.firestore.ordered.quizzes,
        modules: state.firestore.ordered.modules,
        exams: state.firestore.ordered.exams,
        courses: state.firestore.ordered.courses,
        programs: state.firestore.ordered.programs,
        infos: state.firestore.ordered.infos,
        supportEmail: state.firestore.ordered.supportEmail,
        faqs: state.firestore.ordered.faqs,
        ads: state.firestore.ordered.ads,
        events: state.firestore.ordered.events,
        eventsList: state.firestore.ordered.eventsList,
        eventsAttendees: state.firestore.ordered.eventsAttendees,
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect([
        { collection: 'users'},
        { collection: 'questions', orderBy:'questionCode'},
        { collection: 'topics', orderBy:'topicCode'},
        { collection: 'quizzes', orderBy:'quizCode'},
        { collection: 'modules', orderBy:'moduleCode'},
        { collection: 'exams'},
        { collection: 'courses', orderBy:'courseCode'},
        { collection: 'programs'},
        { collection: 'infos', orderBy:'infoType'},
        { collection: 'supportEmail', orderBy: ['date', 'desc']},
        { collection: 'faqs'},
        { collection: 'ads'},
        { collection: 'events'},
        { collection: 'eventsList'},
        { collection: 'eventsAttendees'}
    ])
)(SystemManager);