import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'

import { addNewBatch, updatePCP_status } from '../../../stores/actions/classAction';

import Requested from './Requested';
import Batch from './batch/Batch';
// import NewBatch from './NewBatch';
// import SubmittedBatch from './SubmittedBatch';

class PhysicalClass extends Component{
    
    constructor(props){
        super(props)
        this.state = {
            batchCode:'',
            batchName:'',
            batchAmount: '',
            selectedParticipants:[],
            selectedCourseId_NewBatch:'',
    
            day1: "",
            day2: "",
            day3: "",
            day4: "",
    
            date:{},
    
            isLoading: null,
            message: null,

            isHide: false
        }
        this.baseState = this.state 
    }
    
    componentDidUpdate = (prevProps, prevState) => { 
        const { addBatchError, addBatchSuccess } = this.props;

        if(prevProps.addBatchError !== addBatchError) {
            this.setState({
                isLoading: false,
                message: {
                    msg: addBatchError.message,
                    type: addBatchError.type,
                }
            })
        }

        if(prevProps.addBatchSuccess !== addBatchSuccess) {
            this.setState({
                isLoading: false,
                message: {
                    msg: addBatchSuccess.message,
                    type: addBatchSuccess.type,
                }
            })
            setTimeout(() => { 
                document.getElementById("modal-new-batch").style.display = "none";
                setTimeout(() => { 
                    this.setState({
                        message: null
                    })
                }, 2000);
            }, 2000);
        }
    } 

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.valueAsNumber || e.target.value
        })
    }

    updateDate = async () => {
        this.setState({
            date: {
                day1: this.state.day1,
                day2: this.state.day2,
                day3: this.state.day3,
                day4: this.state.day4,
            }
        })
        return;
    }

    addNewBatch = async () => {
        await this.updateDate();

        var result = window.confirm("Confirm add?");
        if (result) {
            this.setState({
                isLoading: true
            })
            this.props.addNewBatch(this.state);
        }
    }

    showModalNewClass = () => {
        document.getElementById("modal-new-batch").style.display = "block";
    }
    
    hideModalNewClass = () => {
        document.getElementById("modal-new-batch").style.display = "none";
    }

    render(){

        const { query, users, extraClassInfo, physicalClassParticipants, pcpGroup, transcripts, courses, courseEnrollments, physicalClassBatches, classCustomEmail } = this.props; //from AdminManager.jsx
        const { message, isLoading } = this.state;

        let query_courseId = query.get('courseId')
        let query_courseName = query.get('courseName')
        let query_filter = query.get('filter')

        return(

        <div className="physical-class">

            <div id="modal-new-batch" className="mymodal">
                <div className="mymodal-content col-md-6">
                    <span className="close" onClick={this.hideModalNewClass.bind(this)}>&times;</span>
                    
                    <b className="uppercase middle">Add new batch
                        <div className="title-border"></div>
                    </b>

                    <div >
                        <input checked={this.state.isHide} onChange={e => this.setState(initialState => ({isHide: !initialState.isHide}))} type="checkbox" id="hide" value="hide"/>
                        {' '}<label htmlFor="hide">Hide from user</label> 
                    </div>

                    <div className="row mt-5">
                        <p className="col col-md-6">
                            <b>COURSE:</b>
                            <select onChange={e => this.setState({selectedCourseId_NewBatch: e.target[e.target.selectedIndex].id})} className="col form-control">
                                <option defaultValue>Choose Course</option>
                                {courses && courses.map((course,i) => {
                                    if(course.courseType === "Professional") {
                                        return (
                                            <option key={i} id={course.id}>{course.courseName}</option>
                                        )
                                    }
                                    return null
                                })} 
                            </select>
                        </p>
                    </div>

                    <div className="row">
                        <p className="col">
                            <b>BATCH NO: <small>**Transcript</small></b>
                            <input placeholder="Batch number" id="batchCode" onChange={this.handleChange} type="text" value={this.state.batchCode} className="form-control"/>
                        </p>
                        <p className="col">
                            <b>NAME:</b>
                            <input placeholder="Batch name" id="batchName" onChange={this.handleChange} type="text" value={this.state.batchName} className="form-control"/>
                        </p>
                    </div>

                    <b>DATE:</b>
                    <div className="middle-not-center">
                        <div className="row">
                            <label className="col middle col-md-2">Day 1:</label> <input onChange={e => this.setState({day1: e.target.value})} value={this.state.day1} className="col col-md-8 form-control" type="date" />
                        </div>
                        <div className="row">
                            <label className="col middle col-md-2">Day 2:</label> <input onChange={e => this.setState({day2: e.target.value})} value={this.state.day2} className="col col-md-8 form-control" type="date" />
                        </div>
                        <div className="row">
                            <label className="col middle col-md-2">Day 3:</label> <input onChange={e => this.setState({day3: e.target.value})} value={this.state.day3} className="col col-md-8 form-control" type="date" />
                        </div>
                        <div className="row">
                            <label className="col middle col-md-2">Day 4:</label> <input onChange={e => this.setState({day4: e.target.value})} value={this.state.day4} className="col col-md-8 form-control" type="date" />
                        </div>
                    </div>

                    <br/>

                    <b>MAX PARTICIPANT:</b>
                    <input placeholder="Max. amount" id="batchAmount" onChange={this.handleChange} type="number" value={this.state.batchAmount} className="form-control"/>

                    <br/>
                    <br/>

                    { isLoading === true ? 
                        <small>Loading...</small>
                    : 
                        message  ?
                            <small className={message.type === 'addBatchError' ? "text-red" : message.type === 'addBatchSuccess'  ? "text-green" : null }>
                                {message.msg}
                            </small>
                        : null
                    }

                    <div className="mt-2 row justify-content-end">
                        <button 
                            className="btn m-2 btn-outline-black" onClick={this.addNewBatch.bind(this)}><i className="fas fa-plus-circle"/>{' '}add batch
                        </button>
                    </div>
                </div>
            </div>

            <h3 className="text-left">Physical Class
                <div className="title-border"></div>
            </h3>

            <div className="row col-md-12">
                <div className="btn-group dropdown">
                    <button type="button" className="btn border-black dropdown-toggle" data-toggle="dropdown">
                        { query_courseName ? query_courseName  : 'Choose Course' }
                    </button>

                    <div className="dropdown-menu" >
                        <b style={{marginLeft:"20px"}}>MODULE</b>
                        { courses && courses.map((course,i) => {
                            if(course.courseType === "Professional") {
                                return (
                                    <span key={i}
                                        className={query_courseId ? query_courseId === course.id ? "bg-white dropdown-item" : 'dropdown-item' : 'dropdown-item'}>
                                        <Link to={`/adminmanager?topic=class&courseId=${course.id}&courseName=${course.courseName}&filter=requested`}>
                                            {course.courseName}
                                        </Link>
                                    </span>
                                )
                            }
                            return null
                        })}
                    </div>
                </div>

                <button className="btn btn-outline-black" onClick={this.showModalNewClass.bind(this)} style={{marginTop:"2px"}}><i className="fas fa-plus-circle"></i>{'  '}Batch</button>
            </div>

            {/* ----------------------------------------------------------TRY TAB---------------------------------------------------------- */}
            { query_courseId ?
                <div>
                    <div className="tab">
                        <Link className={query_filter === 'requested' ? "tablinks active" : "tablinks"} to={`/adminmanager?topic=class&courseId=${query_courseId}&courseName=${query_courseName}&filter=requested`}>Requested</Link>
                        <Link className={query_filter === '' ? "tablinks active" : "tablinks"} to={`/adminmanager?topic=class&courseId=${query_courseId}&courseName=${query_courseName}&filter=`}>New</Link>
                        <Link className={query_filter === 'active' ? "tablinks active" : "tablinks"} to={`/adminmanager?topic=class&courseId=${query_courseId}&courseName=${query_courseName}&filter=active`}>Active</Link>
                        <Link className={query_filter === 'submitted' ? "tablinks active" : "tablinks"} to={`/adminmanager?topic=class&courseId=${query_courseId}&courseName=${query_courseName}&filter=submitted`}>Submitted</Link>
                        <Link className={query_filter === 'completed' ? "tablinks active" : "tablinks"} to={`/adminmanager?topic=class&courseId=${query_courseId}&courseName=${query_courseName}&filter=completed`}>Completed</Link>
                    </div>

                    <div className="tabcontent" id={query_filter}>
                        <div style={{margin:"20px"}}>
                            
                            { query_filter === "requested" ? 
                                <Requested selectedCourseId={query_courseId} physicalClassParticipants={physicalClassParticipants} courseEnrollments={courseEnrollments} physicalClassBatches={physicalClassBatches} />         
                            :
                                <Batch extraClassInfo={extraClassInfo} transcripts={transcripts} pcpGroup={pcpGroup} batchStatus={query_filter} classCustomEmail={classCustomEmail} courseEnrollments={courseEnrollments} courses={courses} physicalClassParticipants={physicalClassParticipants} users={users} selectedCourseId={query_courseId} physicalClassBatches={physicalClassBatches} />
                            }
                        </div>
                    </div>   
                </div>
            : 
                <p className="mt-5">Please choose course.</p> }   
        </div>
        
        )
    }
}

const mapStateToProps = (state) => {
    return {
        addBatchError: state.classes.addBatchError,
        addBatchSuccess: state.classes.addBatchSuccess,
        
        courseEnrollments: state.firestore.ordered.courseEnrollments,
    }
}
  
const mapDispatchToProps = (dispatch) => {
    return {
      addNewBatch: (batch) => dispatch(addNewBatch(batch)),
      updatePCP_status: (data, pcpId) => dispatch(updatePCP_status(data, pcpId)),
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect([
        { collection: 'courseEnrollments'},
    ])
)(PhysicalClass);