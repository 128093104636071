import React, { Component } from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { deleteQuestion } from '../../../stores/actions/questionAction';

import EditQuestion from './EditQuestion';

class DisplayQuestion extends Component{

    state = {
        hideDetails : false,
        hideEdit: true,
        questionId: '',
    }
    
    componentDidUpdate = (prevProps, prevState) => { 
        const { deleteQuestionError, deleteQuestionSuccess } = this.props;

        if(prevProps.deleteQuestionError !== deleteQuestionError) {
            alert(deleteQuestionError.message);
            this.setState({
                isLoading: false,
                message: {
                    msg: deleteQuestionError.message,
                    type: deleteQuestionError.type,
                }
            })
        }
        
        if(prevProps.deleteQuestionSuccess !== deleteQuestionSuccess) {
            alert(deleteQuestionSuccess.message);
            this.props.hideModal();
            this.setState({
                isLoading: false,
                message: {
                    msg: deleteQuestionSuccess.message,
                    type: deleteQuestionSuccess.type,
                }
            })
        }
    }

    handleEdit = (id) => {
        this.setState({
          hideDetails : true,
          hideEdit: false,
          questionId: id
        })
    }

    cancel = () => {
        this.setState({
            questionId:''
        })
    }

    deleteQuestion = () => {
        var result = window.confirm("Confirm delete?");
        if (result) {
            this.props.deleteQuestion(this.props.questionId);
        }
    }

    render(){

        const { courses, modules, question, questionId, viewOnly } = this.props;

        let courseName = question && courses && courses.map(course => {
            if(course.id === question.questionCourse) {
                return course.courseName
            }
            return null
        })

        let moduleName = question && modules && modules.map(module => {
            if(module.id === question.questionModule) {
                return module.moduleName
            }
            return null
        })

        const questionDetails = question ? (
            <div key={questionId}>
                <div>
                    { this.state.questionId === questionId ?
                        <EditQuestion cancel={this.cancel} question={question} questionId={questionId} courses={courses} modules={modules} />
                    :
                        <div>
                            <p>Code: <b>{question.questionCode}</b></p>
                            <p>Course: <b>{courseName}</b></p>
                            <p>Module: <b>{moduleName}</b></p>

                            <div className="title-border"></div>

                            <p>
                                <b>Mark: </b><br/>
                                {question.questionMark}
                            </p>
                            
                            <p>
                                <b>Question Content 1: </b><br/>
                                {question.questionContent1 ? question.questionContent1 : 'No content 1'}
                            </p>

                            <div className="mt-4">
                                <b>Question Content 2: </b><br/>
                                { question.questionContent2 ?
                                    <p dangerouslySetInnerHTML={{__html: question.questionContent2}}></p>
                                : 
                                    'No content 2'
                                }
                            </div>

                            <div className="mt-4">
                                <b>Answers:</b>
                                { question.answers && question.answers.map((answer, i) => { 
                                    return (
                                        // <li style={{marginLeft:"20px"}} key={i}>{answer.answerContent} <i className={answer.answerStatus === false ? "text-red" : "text-green"}>{answer.answerStatus.toString()}</i></li>
                                        <div style={{marginLeft:"30px"}}  key={i} className="mb-1 listing"> 
                                            <div className="list-icon"><i className="fas fa-angle-right"/></div>
                                            <span>
                                                { answer.answerContent} <b className={answer.answerStatus === false ? "text-red" : "text-green"}>{answer.answerStatus.toString()}</b>
                                            </span>
                                        </div>
                                    )
                                })}
                            </div>

                            { viewOnly === true ?
                                null
                            :
                                <div className="mt-5 row justify-content-end">
                                    <button onClick={this.handleEdit.bind(this, questionId)} className="btn m-2 btn-outline-black"><i className="fas fa-edit"></i></button>
                                    <button className="btn m-2 btn-outline-red" onClick={this.deleteQuestion}><i className="fas fa-trash"></i></button>
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>

        ) : (
            <p className="center">Question not available.</p>
        )


        return(

            <div>
                {questionDetails}
            </div>
        
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const id = ownProps.questionId;
    const questions = state.firestore.data.questions;
    const question = questions ? questions[id] : null
    return {
        question: question,
        questionId: id,

        deleteQuestionError: state.questions.deleteQuestionError,
        deleteQuestionSuccess: state.questions.deleteQuestionSuccess,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
      deleteQuestion: (question_id) => dispatch(deleteQuestion(question_id)),
    }
}
  
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect([
        { collection: 'questions', orderBy: 'questionCode' }
    ])
)(DisplayQuestion)