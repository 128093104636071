import React, { Component } from 'react';
import shortid from 'shortid';
import { connect } from 'react-redux'
import { addQuestion } from '../../../stores/actions/questionAction';

class NewQuestion extends Component{
    
    constructor(props){
        super(props)
        this.state = {
            questionCode:'',
            questionCourse:'',
            questionModule:'',
            questionMark: '',
            questionContent1:'',
            questionContent2:'',
            answers: [],
    
            getAnswerContent: null,
            getAnswerStatus: null,
    
            hideQuestion: true,
    
            isLoading: null,
            message: null,
        }
        this.baseState = this.state 
    }
    
    componentDidUpdate = (prevProps, prevState) => { 
        const { addQuestionError, addQuestionSuccess } = this.props;

        if(prevProps.addQuestionError !== addQuestionError) {
            this.setState({
                isLoading: false,
                message: {
                    msg: addQuestionError.message,
                    type: addQuestionError.type,
                }
            })
        }

        if(prevProps.addQuestionSuccess !== addQuestionSuccess) {
            this.setState({
                isLoading: false,
                message: {
                    msg: addQuestionSuccess.message,
                    type: addQuestionSuccess.type,
                }
            })
            setTimeout(() => { 
                this.setState(this.baseState)
                this.props.hideModal_newQuestion();
            }, 2000);
        }
    } 

    handleAddQuestion = (e) => {
        this.setState({
            [e.target.id]: e.target.valueAsNumber || e.target.value
        })
    }

    saveAnswer = (e) => {

        const{ getAnswerContent, getAnswerStatus } = this.state;

        let answerId = shortid.generate();

        this.setState({
            answers: [ 
                ...this.state.answers,
                {
                id: answerId,
                answerContent: getAnswerContent, 
                answerStatus: getAnswerStatus === "true" ? true : getAnswerStatus === "false" ? false : null,
                },
            ],
        });
        this.refs.answerContent.value = '';
        this.refs.answerStatus.value = 'Status';
    }

    removeAnswer = (id) => {
        let removedAnswer = this.state.answers.filter(ans => {
            return ans.id !== id
        });
        this.setState({
            answers: removedAnswer,
        })
    }

    addQuestion = (e) => {
        var result = window.confirm("Confirm add?");
    
        if (result) {
            this.setState({
                isLoading: true
            })
           this.props.addQuestion(this.state);
        }
    }

    render(){

        const { message, isLoading } = this.state;
        const { courses, modules } = this.props;

        let questionCourseList = courses && courses.map((course,i) => {
            if(course.courseType === "Professional") {
                return (
                    <option key={i} id={course.id}>{course.courseName}</option>
                )
            }
            return null
        })

        let questionModuleList = courses && courses.map((course,i) => {
            if(course.id === this.state.questionCourse) {
                return(
                    course.selectedModules && course.selectedModules.map(selected => {
                        return (
                            modules && modules.map(module => {
                                if(selected === module.id) {
                                    return (
                                        <option key={i} id={module.id}>{module.moduleName}</option>
                                    )
                                }
                                return null
                            })
                        )
                    })
                )
            }
            return null
        })

        return(

            <div>
                <b className="uppercase middle">add new question
                    <div className="title-border"></div>
                </b>

                <p>
                    <b>Question Code: </b>
                    <input onChange={this.handleAddQuestion} id="questionCode" type="text" className="col-md-4 form-control" value={this.state.questionCode} placeholder="Code"/>
                </p>

               <div className="row">
                    <p className="col col-md-6">
                        <b>Course: </b>
                        <select id="questionCourse" onChange={e => this.setState({questionCourse:  e.target[e.target.selectedIndex].id})} value={this.state.questionCourse} className="form-control">
                            <option defaultValue>Choose Course</option>
                            {questionCourseList}
                        </select>
                    </p>

                    { this.state.questionCourse ?
                        <p className="col col-md-6">
                            <b>Module: </b>
                            <select id="questionModule" onChange={e => this.setState({questionModule:  e.target[e.target.selectedIndex].id})} className="form-control">
                                <option defaultValue>Choose Module</option>
                                {questionModuleList}
                            </select>
                        </p>
                    : null }
               </div>

               <div className="row">
                    <p className="col col-md-6">
                        <b>Mark: </b>
                        <input onChange={this.handleAddQuestion} id="questionMark" type="number" className="form-control" value={this.state.questionMark} placeholder="Mark"/>
                    </p>
               </div>

                <p>
                    <b>Question Content 1: </b>
                    <textarea onChange={this.handleAddQuestion} id="questionContent1" type="text" className="form-control" value={this.state.questionContent1} placeholder="Content 1"/>
                </p>

                <p>
                    <b>Question Content 2: </b>
                    <textarea onChange={this.handleAddQuestion} id="questionContent2" type="text" className="form-control" value={this.state.questionContent2} placeholder="Content 2: <ul><span>I</span><br/></ul/>" />
                </p>

                {/* ---------------------------------- ADD ANSWER PART ----------------------------------  */}
                
                <div className="mt-4">
                    <b>Answers:</b>

                    <textarea type="text" className="form-control" ref="answerContent" value={this.state.answerContent} onChange={event => this.setState({getAnswerContent: event.target.value})}  placeholder="Answer"/>

                    <select className="col-md-4 form-control" ref="answerStatus" value={this.state.answerStatus} onChange={event => this.setState({getAnswerStatus: event.target.value})}>
                        <option defaultValue>Status</option>
                        <option>true</option>
                        <option>false</option> 
                    </select>

                    <span className="badge bg-blue text-white" onClick={this.saveAnswer.bind(this)}>Save and Add New</span>

                    <br/><br/>

                    <b>Answer Preview:</b>

                    { this.state.answers && this.state.answers.map((ans,i) => {
                        return (
                            // <li style={{margin:'20px'}}>
                            //     {ans.answerContent} / <i className={ans.answerStatus === false ? "text-red" : "text-green"}>{ans.answerStatus.toString()}</i> {' '}
                            //     <span onClick={this.removeAnswer.bind(this, ans.id)}> <i className="fas fa-trash text-red" /></span>
                            // </li>
                            <div style={{marginLeft:"30px"}}  key={i} className="mb-1 listing"> 
                                <div className="list-icon"><span onClick={this.removeAnswer.bind(this, ans.id)}> <i className="fas fa-trash text-red" /></span></div>
                                <span>
                                    { ans.answerContent} <b className={ans.answerStatus === false ? "text-red" : "text-green"}>{ans.answerStatus.toString()}</b>
                                </span>
                            </div>
                        )
                    })}
                </div>

                { isLoading === true ? 
                    <small>Loading...</small>
                : 
                    message  ?
                        <small className={message.type === 'addQuestionError' ? "text-red" : message.type === 'addQuestionSuccess'  ? "text-green" : null }>
                            {message.msg}
                        </small>
                    : null
                }

                <div className="mt-5 row justify-content-end">
                    <button className="btn m-2 btn-outline-black" onClick={this.addQuestion.bind(this)}><i className="fas fa-plus-circle"/>{' '}add question</button>
                </div>
            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        addQuestionError: state.questions.addQuestionError,
        addQuestionSuccess: state.questions.addQuestionSuccess,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
      addQuestion: (question) => dispatch(addQuestion(question)),
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(NewQuestion);