const initState = {
    subtopics: [
        {id:1, subtopicName:'Hehe', subtopicCode:'HE'},
        {id:2, subtopicName:'Haha', subtopicCode:'HA'},
        {id:3, subtopicName:'Huhu', subtopicCode:'HU'},
    ]
}

const questionReducer = (state = initState, action ) => {

    switch (action.type) {
        
        case 'ADDED_QUESTION':
            return {
                ...state,
                addQuestionSuccess: {
                    message: action.message,
                    type: 'addQuestionSuccess'
                }
            }
        case 'ADDED_QUESTION_ERROR':
            return {
                ...state,
                addQuestionError: {
                    message: action.err.message,
                    type: 'addQuestionError'
                }
            }

        case 'DELETED_QUESTION':
            return {
                ...state,
                deleteQuestionSuccess: {
                    message: action.message,
                    type: 'deleteQuestionSuccess'
                }
            }
        case 'DELETED_QUESTION_ERROR':
            return {
                ...state,
                deleteQuestionError: {
                    message: action.err.message,
                    type: 'deleteQuestionError'
                }
            }

        case 'UPDATED_QUESTION':
            return {
                ...state,
                updateQuestionSuccess: {
                    message: action.message,
                    type: 'updateQuestionSuccess'
                }
            }
        case 'UPDATED_QUESTION_ERROR':
            return {
                ...state,
                updateQuestionError: {
                    message: action.err.message,
                    type: 'updateQuestionError'
                }
            }  

        default:
            return state;
    }
}

export default questionReducer