import React, { Component } from 'react';
import { connect } from 'react-redux'

import { updateQuiz } from '../../../stores/actions/courseAction';

class EditQuiz extends Component{
    
    constructor(props){
        super(props)
        this.state = {
            quizName: this.props.quiz.quizName,
            quizCode: this.props.quiz.quizCode,
            selectedQuestions: this.props.quiz.selectedQuestions,
    
            searchQuiz_Question: '',
            selectedQuestionCode: '',
    
            isLoading: null,
            message: null,
        }
        this.baseState = this.state 
    }
    
    componentDidUpdate = (prevProps, prevState) => { 

        const { updateQuizError, updateQuizSuccess } = this.props;

        if(prevProps.updateQuizError !== updateQuizError) {
            this.setState({
                isLoading: false,
                message: {
                    msg: updateQuizError.message,
                    type: updateQuizError.type,
                }
            })
        }

        if(prevProps.updateQuizSuccess !== updateQuizSuccess) {
            this.setState({
                isLoading: false,
                message: {
                    msg: updateQuizSuccess.message,
                    type: updateQuizSuccess.type,
                }
            })
            setTimeout(() => { 
                this.props.cancel();
            }, 2000);
        }
    } 

    handleSelectedQuestion(id, code) {
        this.setState({
            selectedQuestions: [ 
                ...this.state.selectedQuestions,
               id
            ],
            selectedQuestionCode: code
        });
    }

    removeSelectedQuestion = (id, e) => {

        e.preventDefault();
        let removeSelectedQuestion = this.state.selectedQuestions.filter(selected => {
          return selected !== id
        })
    
        this.setState({
            selectedQuestions: removeSelectedQuestion
        })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    updateQuiz = (e) => {

        var result = window.confirm("Confirm update?");
    
        if (result) {
            this.setState({
                isLoading: true
            })
            this.props.updateQuiz(this.state, this.props.quizId);
        }
    }

    render(){

        const { questions } = this.props;
        const { message, isLoading } = this.state;

        let filteredQuestions = null;

        if(this.state.searchQuiz_Question.length > 0) {
            filteredQuestions = questions && questions.filter(question => {
                return question.questionCode.toLowerCase().includes(this.state.searchQuiz_Question.toLowerCase())
            })
        } else {
            filteredQuestions = questions;
        }
        
        return(

            <div>
                                
                <form className="text-left">
                    <div className="row">       
                        <p className="col col-md-3">
                            <b>Code:</b>
                            <input type="text" id="quizCode" onChange={this.handleChange} defaultValue={this.state.quizCode} className="form-control"/>
                        </p>
                        <p className="col">
                            <b>Name:</b>
                            <input type="text" id="quizName" onChange={this.handleChange} defaultValue={this.state.quizName} className="form-control"/>
                        </p>
                    </div>
                
                    <div className=" mb-5">
                        <b>Questions: </b>
                        <div type="text" className="border-black">

                            { questions && questions.map((question,i) => {
                                return (
                                    this.state.selectedQuestions && this.state.selectedQuestions.map((selected, i) => { 
                                        if (question.id === selected) {
                                            return (
                                                <span key={i} className="text-white badge bg-green">
                                                    {question.questionCode} {' '}
                                                    <span onClick={this.removeSelectedQuestion.bind(this, selected)}>
                                                        <i className="fas fa-times"></i>
                                                    </span>
                                                </span>
                                            )
                                        }
                                        return null
                                    })
                                )
                            })}
                        </div>

                        <br/>
                    
                        <small>Choose question here:</small>

                        <br/>

                        <div className="row col-md-5 btn-group dropdown">
                            <button type="button" className="text-left btn border-black dropdown-toggle" data-toggle="dropdown">
                                { this.state.selectedQuestionCode ? this.state.selectedQuestionCode : 'Choose Question'}
                            </button>

                            <div className="dropdown-menu" >
                                <span className="dropdown-item" onClick={this.handleSelectedQuestion.bind(this, '', '')} disabled>QUESTIONS</span>
                                <span className="dropdown-item"><input placeholder="Search code..." className="form-control"  onChange={event => this.setState({searchQuiz_Question: event.target.value})}/></span>
                                { filteredQuestions && filteredQuestions.map((question,i) => {
                                    return (
                                        <span key={i} className="dropdown-item" onClick={this.handleSelectedQuestion.bind(this, question.id, question.questionCode)}>{question.questionCode}</span>
                                    )
                                })}
                            </div>
                        </div>
                    </div>

                    { isLoading === true ? 
                        <small>Loading...</small>
                    : 
                        message  ?
                            <small className={message.type === 'updateQuizError' ? "text-red" : message.type === 'updateQuizSuccess'  ? "text-green" : null }>
                                {message.msg}
                            </small>
                        : null
                    }
                    
                    <div className="mt-5 row justify-content-end">
                        <button type="button" className="btn m-2 btn-outline-black" onClick={this.updateQuiz}><i className="fas fa-check-circle"/>{' '}update</button>
                        <button type="button" className="btn m-2 btn-outline-black" onClick={this.props.cancel}><i className="fas fa-times"/>{' '}cancel</button>
                    </div>
                </form>
            </div>
        
        )
    }
}

const mapStateToProps = (state) => {
  return {
      updateQuizError: state.courses.updateQuizError,
      updateQuizSuccess: state.courses.updateQuizSuccess,
  }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateQuiz: (updatedQuiz, quizId) => { dispatch(updateQuiz(updatedQuiz, quizId))}
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(EditQuiz);