import React, { Component } from 'react';
import { connect } from 'react-redux'
import { deleteTerm } from '../../../stores/actions/infoAction';
import EditTermAndCondition from './EditTermAndCondition';

class DisplayTermAndCondition extends Component{

    state = {
        info_id:'',
        displayEditCode:'', //ADD EXTRA EXPRESSION SBB NANTI DIA BACA RENDER EDITHELPINFO WTFFF
    
        isLoading: null,
        message: null,
    }
    
    componentDidUpdate = (prevProps, prevState) => { 
        const { deleteTermError, deleteTermSuccess } = this.props;

        if(prevProps.deleteTermError !== deleteTermError) {
            alert(deleteTermError.message);
            this.setState({
                isLoading: false,
                message: {
                    msg: deleteTermError.message,
                    type: deleteTermError.type,
                }
            })
        }
        
        if(prevProps.deleteTermSuccess !== deleteTermSuccess) {
            alert(deleteTermSuccess.message);
            this.setState({
                isLoading: false,
                message: {
                    msg: deleteTermSuccess.message,
                    type: deleteTermSuccess.type,
                }
            })
        }
    }

    handleEdit = (id, code) => {
        this.props.show();
        this.setState({
            displayEditCode: code,
            info_id: id
        })
    }

    deleteTerm = (infoId) => {
        var result = window.confirm("Confirm delete?");

        if (result) {
            this.props.deleteTerm(infoId);
        }
    }

    hideAndResetState = () => {
        this.props.hide();
        this.setState({
           info_id: '',
           displayEditCode: ''
        })
    }

    render(){

        const { infos } = this.props;

        let hasData = [], isLoading= true;
        let no = 0;

        let infoList = infos && infos.map((info ,i) => {
            if(info.infoType === "TERM AND CONDITION"){
                hasData.push(info)
                isLoading = false;
                no = no+1;
                return(
                    <tr key={i}>
                        <th>{no}</th>
                        <td>{info.infoName}</td>
                        <td className="text-left">{info.infoContent}</td>
                        <td>
                            <button className="m-1 btn btn-outline-black" onClick={this.handleEdit.bind(this, info.id, 'editterm')}><i className="fas fa-edit"></i></button>
                            <button className="m-1 btn btn-outline-red" onClick={this.deleteTerm.bind(this, info.id)}><i className="fas fa-trash"></i></button>
                        </td>
                    </tr>
                )
            } else {
                isLoading = false;
            }
            return null
        });


        return(

        <div>

            {/* MODAL FOR UPDATE HELP INFO */}
            <div id="myModal" className="mymodal">
                <div className="mymodal-content col-md-6">

                    <span className="close" onClick={this.hideAndResetState}>&times;</span>
                    
                    { this.state.displayEditCode==='editterm'?
                        <EditTermAndCondition hideAndResetState={this.hideAndResetState} info_id={this.state.info_id}/>
                    :''}

                </div>
            </div>

            { isLoading === true ? 
                <p>Please wait... Data loading...</p> 
            : 
                hasData.length > 0 ? 
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">No</th>
                                <th scope="col">Name</th>
                                <th scope="col">Content</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                            
                        <tbody>
                            {infoList}
                        </tbody>
                    </table>
                : 
                    <p>No info available</p>
            }
        </div>

        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        deleteTermError: state.infos.deleteTermError,
        deleteTermSuccess: state.infos.deleteTermSuccess,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
      deleteTerm: (infoId) => dispatch(deleteTerm(infoId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DisplayTermAndCondition)