import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';

import { deleteModule } from '../../../stores/actions/courseAction';

import EditModule from './EditModule';

class DisplayModule extends Component{

    state = {
        hideDetails : false,
        hideEdit: true,
        id:'',
        selectedAssessment_id:'',
    }
    
    componentDidUpdate = (prevProps, prevState) => { 

        const { deleteModuleError, deleteModuleSuccess } = this.props;

        if(prevProps.deleteModuleError !== deleteModuleError) {
            alert(deleteModuleError.message);
        }
        
        if(prevProps.deleteModuleSuccess !== deleteModuleSuccess) {
            alert(deleteModuleSuccess.message);
            document.getElementById("clickModule").click();
        }
    } 

    handleEdit = (moduleId) => {
        this.setState({
          hideDetails : true,
          hideEdit: false,
          id: moduleId
        })
    }

    cancel = () => {
        this.setState({
            id:''
        })
    }

    deleteModule = (module_id) => {

        var result = window.confirm("Confirm delete?");
    
        if (result) {
            this.props.deleteModule(module_id)
        }
    }

    displayModalAssessmentFile_Display = (id) => { //display current file in popup
        this.setState({
            selectedAssessment_id: id,
        })
        document.getElementById("display_assessment_file_display").style.display = "block";
    }

    hideModalAssessmentFile_Display = () => { //hide current file in popup
        document.getElementById("display_assessment_file_display").style.display = "none";
    }

    render(){

        const { query, quizzes, topics, module, module_id, modules} = this.props;

        let query_id = query.get('id');
        let isLoading = true;

        const moduleDetails = module ? (
            <div key={module.id}>
                { isLoading = false }
                { this.state.id === module_id ?
                    <EditModule cancel={this.cancel} module={module} quizzes={quizzes} topics={topics} module_id={module_id} modules={modules} />
                :
                    <div>
                        <p className="mt-2"><b>Code: </b>{module.moduleCode}</p>
                        <p className="mt-2"><b>Name: </b>{module.moduleName}</p>
                    
                        <div className="mt-2"><b>Topics: </b>
                            <div  style={{marginLeft:"20px"}}>
                                { topics && topics.map(topic => {
                                    return (
                                        module.selectedTopics && module.selectedTopics.map((selected, i) => { 
                                            if(topic.id === selected) {
                                                return (
                                                    <li key={i}>{topic.topicCode} - {topic.topicName}</li>
                                                )
                                            }
                                            return null
                                        })
                                    )
                                })}
                            </div>
                        </div>

                        <br/>

                        <div>
                            <b>Quiz: </b>
                            <div  style={{marginLeft:"20px"}}>
                                    <li><b>Has Quiz:</b> {module.quiz.hasQuiz}</li>
                                    { module.quiz && module.quiz.hasQuiz === "true" ?
                                        <div>
                                            {quizzes && quizzes.map((quiz,i) => {
                                                if (quiz.id === module.quiz.selectedQuizSet) {
                                                    return (
                                                        <li key={i}><b>Selected Quiz Set:</b> {quiz.quizCode} - {quiz.quizName}</li>
                                                    )
                                                }
                                                return null
                                            })}
                                            <br/>
                                            <small>QUIZ SETING</small>
                                            <div className="row">   
                                                <p className="col col-md-4">    
                                                    <small style={{fontWeight:'800'}}>Timer (seconds):</small>
                                                    {module.quiz.quizTimer} second(s)
                                                </p>

                                                <p className="col col-md-4">
                                                    <small style={{fontWeight:'800'}}>Question Count:</small>
                                                    {module.quiz.questionCount}
                                                </p>  

                                                <p className="col col-md-4">
                                                    <small style={{fontWeight:'800'}}>Mark:</small>
                                                    {module.quiz.quizMark}%
                                                </p>  
                                            </div>

                                            <div className="row">   
                                                <p className="col col-md-4">
                                                    <small style={{fontWeight:'800'}}>Suspend Count:</small>
                                                    {module.quiz.quizSuspendNumber}
                                                </p>  

                                                <p className="col col-md-4">
                                                    <small style={{fontWeight:'800'}}>Suspend Day:</small>
                                                    {module.quiz.quizSuspendDay} day(s)
                                                </p>
                                            </div>
                                        </div>
                                    : null }
                            </div>
                        </div>

                        <br/>

                        <div>
                            <b>Practical Assessment: </b>
                            <div style={{marginLeft:"20px"}}>
                                <li><b>Has Practical Assessment:</b> {module.practicalAssessment.hasPracticalAssessment}</li>
                                {module.practicalAssessment && module.practicalAssessment.hasPracticalAssessment === "true" ?
                                    <div>
                                         {modules && modules.map((module,i) => {
                                            if (module.id === module_id && module.practicalAssessment !== null) {
                                                return (
                                                    <li><b>Selected Practical Assessment:</b> {' '}
                                                        <span key={i} onClick={this.displayModalAssessmentFile_Display.bind(this, module_id)}>{module.practicalAssessment.fileName}</span>
                                                    </li>
                                                    
                                                )
                                            } else if (module.id === module_id && module.practicalAssessment === null) {
                                                return (
                                                    <li><b>Selected Practical Assessment: </b> No selected assessment.</li>
                                                )
                                            }
                                            return null
                                        })}
                                    </div>
                                : null }
                            </div>
                        </div>

                        <div className="mt-5 row justify-content-end">
                            <button type="button" className="btn m-2 btn-outline-black" onClick={this.handleEdit.bind(this, module_id)}><i className="fas fa-edit"></i></button>
                            <button type="button" className="btn m-2 btn-outline-red" onClick={this.deleteModule.bind(this, module_id)}><i className="fas fa-trash"></i></button>
                        </div>
                    </div>
                }
            </div>
        ) : (
            null
        )


        return(

            <div className="container col-md-12">

                <div id="display_assessment_file_display" className="mymodal">
                    <div className="mymodal-content col-md-6">
                        <span className="close" onClick={this.hideModalAssessmentFile_Display}>&times;</span>

                        { modules && modules.map((module,i) => {
                            if(this.state.selectedAssessment_id === module.id){
                                return (
                                    <div key={i}>
                                        <h3 className="middle">{module.practicalAssessment.fileName}
                                            <div className="row"><div className="title-border"></div></div>
                                        </h3>
                                        
                                        <object aria-label="display-pdf" className="display-pdf" data={module.practicalAssessment.fileUrl}></object>
                                    </div>
                                )
                            }  else return null
                        })}
                                    
                    </div>
                </div>

                { query_id ?
                    isLoading === true ? <p>Data loading... Please wait...</p> : moduleDetails
                : <p>Module data not available.</p> }

                <div hidden={true}>
                    <Link id="clickModule" to={`/systemmanager?topic=course&subtopic=module`} />
                </div>
                
            </div>
        
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const id = ownProps.selectedModuleId;
    const modules = state.firestore.data.modules;
    const module = modules ? modules[id] : null
    return {
        module: module,
        module_id: id,
        
        deleteModuleError: state.courses.deleteModuleError,
        deleteModuleSuccess: state.courses.deleteModuleSuccess,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
      deleteModule: (module_id) => dispatch(deleteModule(module_id))
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(DisplayModule)