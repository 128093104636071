import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import Carousel, { consts } from "react-elastic-carousel";

import { requestCert } from "../stores/actions/eventsAction.js";

import { Link } from "react-router-dom";

class EventsList extends Component {
  state = {
    attachLoaded: false,
    selectedCourseId: "",
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { requestCertError, requestCertSuccess } = this.props;

    if (prevProps.requestCertError !== requestCertError) {
      alert(requestCertError.message);
    }

    if (prevProps.requestCertSuccess !== requestCertSuccess) {
      alert(requestCertSuccess.message);
      document.getElementById("clickEvents").click();
    }
  };

  handleAttachLoaded() {
    this.setState({ attachLoaded: true });
  }

  requestCert = (events_id, userId) => {
    var result = window.confirm("Confirm Request Cert?");

    if (result) {
      let data = {
        eventsAttendeesStatus: "Requested",
        eventsId: events_id,
        userId: userId,
        requestedDate: new Date(),
      };
      this.props.requestCert(data);
    }
  };

  myArrow = ({ type, onClick, isEdge }) => {
    const pointer =
      type === consts.PREV ? (
        <i className="fas fa-arrow-circle-left"></i>
      ) : (
        <i className="fas fa-arrow-circle-right"></i>
      );
    return (
      <span
        className="middle carousel-arrow"
        onClick={onClick}
        disabled={isEdge}
      >
        {pointer}
      </span>
    );
  };

  render() {
    const { auth, profile, events, eventsList, eventsAttendees } = this.props;

    const eventsList_id = this.props.match.params.eventsList_id;

    let isLoading = true,
      professional = null,
      activeCourse = [];
    // let programName = '';

    const breakPoints = [
      { width: 1, itemsToShow: 1 },
      { width: 550, itemsToShow: 2, itemsToScroll: 2 },
      { width: 1200, itemsToShow: 3 },
      { width: 1400, itemsToShow: 4 },
    ];

    professional =
      eventsList &&
      eventsList.map((data, i) => {
        if (eventsList_id === data.id) {
          return (
            events &&
            events.map((dataEvents, i) => {
              if(data.id === dataEvents.eventsListId && dataEvents.eventsStatus === "Active"){
                let isEventsAttendees = false;

                eventsAttendees &&
                  eventsAttendees.map((dataAttendees) => {
                    if (auth.uid === dataAttendees.userId && dataAttendees.eventsId === dataEvents.id) {
                      isEventsAttendees = true;
                    }
  
                    return null;
                  });
                isLoading = false;
                activeCourse.push(dataEvents.id);
  
                return (
                  <div key={i}>
                    <div className="text-left courses-card">
                      {/* <div className="text-center carousel-img-holder"> */}
                      {dataEvents.eventsImage ? (
                        <div className="middle" style={{ width: "100%" }}>
                          {
                            <img
                              alt=""
                              onLoad={this.handleAttachLoaded.bind(this)}
                              width="100%"
                              src={dataEvents.eventsImage.fileUrl}
                            />
                          }
  
                          {this.state.attachLoaded === false ? (
                            <div className="loader"></div>
                          ) : null}
                        </div>
                      ) : (
                        <div className="middle" style={{ width: "100%" }}>
                          <img
                            alt=""
                            className="text-center"
                            width="100%"
                            src="/image/default.png"
                          />
                        </div>
                      )}
                      {/* </div> */}
  
                      <div style={{ padding: "20px" }}>
                        <h1>{dataEvents.eventsName}</h1>
                        <h3>{dataEvents.eventsTopic}</h3>
                        <div className="row justify-content-center">
                          <div className="title-border"></div>{" "}
                        </div>
  
                        {/* <li>
                          <b>
                            {course.enrollCount
                              ? `${course.enrollCount} enrollments`
                              : null}
                          </b>
                        </li> */}
  
                        <div style={{ padding: "20px" }}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: dataEvents.eventsContent,
                            }}
                            style={{ margin: "0px" }}
                          ></div>
                        </div>
                        {
                          !auth.uid ? (
                            <button className="btn btn-enroll-disable">
                              <i className="fas fa-sign-in-alt"></i> Login to get
                              cert
                            </button>
                          ) : isEventsAttendees === true ? (
                            <>
                              <button className="btn btn-enroll-disable mb-0">
                                <i className="fas fa-check-circle"></i> Attended
                              </button>
                            </>
                          ) : (
                            // <Link to={"/program/" + program_id + "/course/" + id + "/flexi"} className="btn all-btn">Enroll Now - Flexi</Link>
                            <>
                              <div className="middle">
                                <button
                                  className="pushable  col-md-12"
                                  onClick={this.requestCert.bind(
                                    this,
                                    dataEvents.id,
                                    profile.userId
                                  )}
                                >
                                  <span className="shadow"></span>
                                  <span className="edge"></span>
                                  <span className="front">
                                    Request Certificate{" "}
                                    <i className="fas fa-certificate"></i>
                                  </span>
                                </button>
                              </div>
                            </>
                          )
  
                          // <Link to={"/confirm-purchase/" + id} className="btn all-btn">Enroll Now</Link>
                        }
                      </div>
  
                      <div className="bg-green programs-btn"></div>
                    </div>
                  </div>
                );
              }
              return null;
            })
          );
        }
        return null;
      });

    return (
      <div className="courses container col-md-12 justify-content-center middle min-height">
        {/* <div className="product-header">{programName} - Courses</div> */}
        <div
          style={{ margin: "5em 0em 5em 0em" }}
          className={
            activeCourse.length < 2
              ? "col-md-6"
              : activeCourse.length < 3
              ? "col-md-8"
              : "col-md-11"
          }
        >
          <Carousel
            breakPoints={breakPoints}
            itemsToShow={3}
            renderArrow={this.myArrow}
          >
            {isLoading === true ? <div className="loader"></div> : professional}
          </Carousel>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,

    events: state.firestore.ordered.events,
    eventsList: state.firestore.ordered.eventsList,
    eventsAttendees: state.firestore.ordered.eventsAttendees,

    requestCertError: state.events.requestCertError,
    requestCertSuccess: state.events.requestCertSuccess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    requestCert: (events_id, userId) =>
      dispatch(requestCert(events_id, userId)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => [
    { collection: "events" },
    {
      collection: "eventsAttendees",
      where: props.auth.uid ? ["userId", "==", props.auth.uid] : null,
    },
  ])
)(EventsList);
