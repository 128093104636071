import React, { Component } from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";

import { PDFDownloadLink } from "@react-pdf/renderer";
import { EcertPdf } from "../../e-learning/ecert/EcertGenerator";

class EmployeeList extends Component {
  state = {
    searchUser: "",

    selectedCourseId: "",
    selectedCourseName: "",

    firstIndexData: 0,
    lastIndexData: 100,
  };

  loadMore = () => {
    this.setState({
      lastIndexData: this.state.lastIndexData + 100,
    });
  };

  selectedCourse = (e) => {
    this.setState({
      selectedCourseId: e.target[e.target.selectedIndex].id,
      selectedCourseName: e.target.value,
    });
  };

  render() {
    const {
      employers,
      empEnrollments,
      courseEnrollments,
      courses,
      users,
      examEnrollments,
      moduleEnrollments,
    } = this.props;
    const { selectedCourseId, selectedCourseName } = this.state;

    let no = 0,
      courseList = null,
      enrollmentList = null;
    let filteredUsers = null,
      filteredEnrollments = [];
    let noSearchData = false;

    // 1ST: filter user and get USERID
    if (this.state.searchUser.length > 0) {
      filteredUsers =
        users &&
        users.filter((user) => {
          let username = user.firstname + " " + user.lastname;

          return (
            user.email &&
            (user.email
              .toLowerCase()
              .includes(this.state.searchUser.toLowerCase()) ||
              username
                .toLowerCase()
                .includes(this.state.searchUser.toLowerCase()))
          );
        });
    } else {
      filteredUsers = users;
    }

    courseEnrollments &&
      courseEnrollments.map((enrolled) => {
        if (enrolled.courseId === selectedCourseId) {
          return (
            filteredUsers &&
            filteredUsers.map((user) => {
              if (enrolled.userId === user.id) {
                filteredEnrollments.push(enrolled);
              } else {
                noSearchData = true;
              }
              return null;
            })
          );
        } else return null;
      });

    courseList =
      courses &&
      courses.map((course, i) => {
        return (
          employers &&
          employers.map((emp) => {
            if (course.id === emp.courseId) {
              return (
                <option key={i} id={course.id}>
                  {course.courseName}
                </option>
              );
            }
            return null;
          })
        );
      });

    let hasData = [],
      isLoadingData = true;

    let sliced =
      filteredEnrollments &&
      filteredEnrollments.slice(
        this.state.firstIndexData,
        this.state.lastIndexData
      );

    enrollmentList =
      sliced &&
      sliced.map((enrolled, i) => {
        let userName = "",
          userEmail = "",
          userPhone = "",
          firstname = "",
          lastname = "",
          userIc = "",
          userId = "";

        users &&
          users.map((user) => {
            if (user.id === enrolled.userId) {
              userName = user.firstname + " " + user.lastname;
              firstname = user.firstname;
              lastname = user.lastname;
              userEmail = user.email;
              userPhone = user.contactNo;
              userIc = user.identityCardNo;
              userId = user.id;
            }
            return null;
          });

        var examEnrolled = userId
          ? examEnrollments.filter(
              (exam) =>
                exam.userId === userId && exam.courseId === selectedCourseId
            )
          : null;

        let profile = {
          firstname: firstname,
          lastname: lastname,
          icNo: userIc,
        };

        if (enrolled.courseId === this.state.selectedCourseId) {
          hasData.push(enrolled);
          isLoadingData = false;

          no = no + 1;
          return (
            <tr key={i}>
              <th scope="row">{no}.</th>
              <td className="text-left">{userName}</td>
              <td className="text-left">
                {userEmail} <br /> {userPhone}
              </td>
              <td>
                {profile && examEnrolled
                  ? examEnrolled &&
                    examEnrolled.map((exam, i) => {
                      // console.log(moduleEnrollments)

                      let currentTopic = moduleEnrollments && moduleEnrollments.map(moduleEnrollment => {
                        if(moduleEnrollment.courseId === exam.courseId && moduleEnrollment.userId === exam.userId){
                          let topic = moduleEnrollment.topics.filter(t => t.isAvailable)
                          return topic.length
                        }
                      })

                      if (exam.isDone === true) {
                        return (
                          <span>
                            {profile.fullname ? (
                              <PDFDownloadLink
                                key={i}
                                document={
                                  <EcertPdf
                                    courseId={selectedCourseId}
                                    doneDate={exam.isDoneDate}
                                    profile={profile}
                                    courseName={selectedCourseName}
                                  />
                                }
                                fileName={`${
                                  profile.firstname + " - " + selectedCourseName
                                }.pdf`}
                              >
                                {({ blob, url, loading, error }) => (
                                  <button
                                    className={
                                      loading
                                        ? "btn btn-disable"
                                        : "btn all-btn"
                                    }
                                  >
                                    {loading ? (
                                      "Loading E-Cert..."
                                    ) : (
                                      <span>
                                        {" "}
                                        <i className="fas fa-download" /> E-Cert
                                      </span>
                                    )}
                                  </button>
                                )}
                              </PDFDownloadLink>
                            ) : (
                              <small className="text-red">
                                PLEASE UPDATE USER FULLNAME
                              </small>
                            )}
                          </span>
                        );
                      } else {
                        return (
                          <div key={i} >
                          {/* <button key={i} className="btn btn-disable">
                            <i className="fas fa-download" /> E-Cert
                          </button>
                          <br/> */}
                          <small className='text-primary'>Current topic {currentTopic}</small>
                          </div>
                          
                        );
                      }
                    })
                  : null}
              </td>
            </tr>
          );
        } else {
          isLoadingData = false;
        }
        return null;
      });

    let emp_nopax = 0,
      empEnrollments_count = [];

    empEnrollments &&
      empEnrollments.map((emp) => {
        if (emp.courseId === selectedCourseId) {
          return empEnrollments_count.push(emp);
        }
        return null;
      });

    employers &&
      employers.map((emp) => {
        return emp.courseId === selectedCourseId
          ? (emp_nopax = emp.noPax)
          : null;
      });

    return (
      <div className="emp-list">
        <ul>
          <li>
            <b>Total:</b>{" "}
            {selectedCourseId ? emp_nopax : <i>Choose course first...</i>}
          </li>
          <li>
            <b>Balance:</b>{" "}
            {selectedCourseId ? (
              parseInt(emp_nopax) - parseInt(empEnrollments_count.length)
            ) : (
              <i>Choose course first...</i>
            )}
          </li>
        </ul>

        <select
          id="selectedCourseName"
          onChange={this.selectedCourse.bind(this)}
          value={selectedCourseName}
          className="form-control"
        >
          <option defaultValue>Course List</option>
          {courseList}
        </select>

        <div>
          <input
            placeholder="Search user by email or name"
            className="mb-3 col form-control"
            onChange={(event) =>
              this.setState({ searchUser: event.target.value })
            }
          />

          {!selectedCourseId ? (
            <p className="mt-5">Please choose course...</p>
          ) : isLoadingData === true ? (
            noSearchData === true ? (
              <p className="mt-5">No search data</p>
            ) : (
              <p className="mt-5">Please wait... Data loading...</p>
            )
          ) : hasData.length > 0 ? (
            <table className="mt-5 table">
              <thead>
                <tr>
                  <th scope="col">No.</th>
                  <th className="text-left" scope="col">
                    Name
                  </th>
                  <th scope="col">Email/Phone</th>
                  <th scope="col">E-Cert</th>
                </tr>
              </thead>

              <tbody>{enrollmentList}</tbody>
            </table>
          ) : (
            <p>No data available</p>
          )}
        </div>

        {filteredEnrollments.length > 0 ? (
          this.state.lastIndexData >= filteredEnrollments.length ? (
            <button
              className="btn btn-disable"
              onClick={this.loadMore.bind(this)}
            >
              Load More
            </button>
          ) : (
            <button className="btn all-btn" onClick={this.loadMore.bind(this)}>
              Load More
            </button>
          )
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.firebase.auth,
    courseEnrollments: state.firestore.ordered.courseEnrollments,
    examEnrollments: state.firestore.ordered.examEnrollments,
    moduleEnrollments: state.firestore.ordered.moduleEnrollments,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => [
    {
      collection: "courseEnrollments",
      where: ["employerId", "==", props.auth.uid],
    },
    { collection: "examEnrollments" },
    { collection: "moduleEnrollments" },
  ])
)(EmployeeList);
