import React from "react";
import { Document, Page, Text, View, Image, StyleSheet, Font  } from "@react-pdf/renderer";
import logo from './ecert2.png';
import font from './Roboto-Medium.ttf';
import moment from 'moment';

Font.register({ family: 'Roboto-Medium', src: font });

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Roboto-Medium',
    },

    backgroundImage: {
        position: 'relative',
        height: '100%',
        width: '100%',
    },

    name: {
        position: 'absolute',
        textAlign:'center',
        top:'35vh',
        width:'100%',
        fontSize:'18px',
       
    },

    ic: {
        position: 'absolute',
        textAlign:'center',
        top:'38vh',
        width:'100%',
        fontSize:'18px',
    },

    courseName: {
        position: 'absolute',
        textAlign:'center',
        top:'46vh',
        width:'100%',
        fontSize:'18px',
    },

    date: {
        position: 'absolute',
        textAlign:'center',
        top:'58vh',
        width:'100%',
        fontSize:'18px',
    }
});

export function EcertPdf(props) {
    let IC = props.profile.identityCardNo;
    var regExp = /[a-zA-Z]/;
    const charToInsert = '-';
    const indexToInsertAt = [6, 8];

    function FilterIC(){
        if (IC.includes("-") || IC.match(regExp)) {
            return IC
        } else {
            return IC.substring(0, indexToInsertAt[0]) + charToInsert + IC.substring(indexToInsertAt[0], indexToInsertAt[1]) + charToInsert + IC.substring(indexToInsertAt[1]);
        }
    }

        return (
            <Document>
                <Page orientation="potrait" style={styles.page}>

                    <View style={styles.backgroundImage}> 
                        <Image src={logo}/>
                        <Text style={styles.name}>{props.profile.fullname}</Text> 
                        <Text style={styles.ic}>{FilterIC()}</Text> 
                        { props.courseId === "6qVCSxIVWfzK99ngT9xR" ?
                            <Text style={styles.courseName}>Halal Awareness</Text> 
                        :
                            <Text style={styles.courseName}>{props.courseName}</Text>  
                        }
                        <Text style={styles.date}>{moment(new Date(props.doneDate)).format('DD/MM/YYYY')}</Text> 
                    </View>
                </Page>
            </Document>
        );
    }