import React, { Component } from 'react';
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'

class Help extends Component{

    render(){

        const { infos } = this.props;

        let helpFile = null, helpVideo = null, isLoading = true;

        helpFile = infos && infos.map((info ,i) => {
            if(info.infoType === "HELP INFO" && info.selectedHelpFile.fileType === "pdf"){
                isLoading = false;
                return(
                    <span key={i} className="col col-md-6"> 
                        <div className="card border-0">
                            <a href={info.selectedHelpFile.fileUrl} target="_blank" rel="noopener noreferrer" download>
                                <i className="fas fa-info-circle"/>
                                {info.infoName}
                            </a>
                        </div>
                    </span>
                )
            }
            return null
        });

        helpVideo = infos && infos.map((info ,i) => {
            if(info.infoType === "HELP INFO" && info.selectedHelpFile.fileType === "video"){
                isLoading = false;
                return(
                    <div key={i} className="col col-md-4">
                        <video width="100%" src={info.selectedHelpFile.fileUrl} type="video/mp4" controls /><br/>
                        <label className="badge bg-lightgrey">{info.infoName}</label>
                    </div>
                )
            }
            return null
        });

        return(
            
        <div className="help min-height">

            <div className="middle page-header">
                {/* <div className="col col-md-4" style={{marginBottom:"30px"}}> 
                    <img src="../../../image/HA_LOGO.png" alt="" width="45%"/><br />
                </div> */}
                 
                 <div className='col-md-8'>
                    <h1>user manual
                        <div className="bg-white title-border"></div>
                    </h1>
                </div> 
            </div>
            
            <div className="page-content middle">

                { isLoading === true ? <div className="loader"></div> : helpVideo }

                <div className="col-md-8">
                    { isLoading === false ?
                        <div className="text-left">
                            <i className="mb-0">Click any of the below to view or download the pdf file.</i>
                            <div className="title-border"></div>

                            <div className="row col-md-12 justify-content-left">
                                {helpFile}
                            </div>
                        </div>
                    : null }
                </div>
            </div>

        </div>

        )
    }
        
}

const mapStateToProps = (state) => {
    return {
      infos: state.firestore.ordered.infos,
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect([
        { collection: 'infos'},
    ])
)(Help);