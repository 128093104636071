import React, { Component } from 'react';
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'

import { PDFViewer, PDFDownloadLink } from "@react-pdf/renderer";
import { TranscriptPdf } from "./TranscriptGenerator";

class Transcript extends Component {

    render(){

        const { userData, batchData, tsData, quizEnrollments, examEnrollments, selectedModules } = this.props;

        let quizArr = [], examArr = [];

        if(tsData) {
            quizEnrollments && quizEnrollments.map(quiz => {
                if(quiz.courseId === tsData.courseId) {
                    quizArr.push(quiz)
                } return null
            })

            examEnrollments && examEnrollments.map(exam => {
                if(exam.courseId === tsData.courseId) {
                    examArr.push(exam)
                } return null
            })
        }

        return (

            <div className='min-height'>
                { userData && batchData && tsData && (quizArr.length !== 0) && (examArr.length !== 0) && selectedModules ?
                    <div>
                        <PDFDownloadLink 
                            document={ userData && batchData && tsData && (quizArr.length !== 0) && (examArr.length !== 0) && selectedModules ?
                                <TranscriptPdf selectedModules={selectedModules} userData={userData} batchData={batchData} tsData={tsData} quizArr={quizArr} examArr={examArr} />
                            : null }
                            fileName={`transcript.pdf`}>
                            {({ blob, url, loading, error }) => (loading ? <button className='btn btn-disable'>Loading transcript...</button> : <button className='btn all-btn'>Download transcript</button>)}
                        </PDFDownloadLink>

                        <PDFViewer style={{height:"100vh"}} width="100%">
                            { userData && batchData && tsData && (quizArr.length !== 0) && (examArr.length !== 0) && selectedModules ?
                                <TranscriptPdf selectedModules={selectedModules} userData={userData} batchData={batchData} tsData={tsData} quizArr={quizArr} examArr={examArr} />
                            : null }
                        </PDFViewer>
                    </div>
                : 
                    <p className='m-2'>Loading transcript...</p> 
                }
            </div>
        
        )
    }
}

const mapStateToProps = (state, ownProps) => {

    const user_id = ownProps.match.params.user_id;
    const batch_id = ownProps.match.params.batch_id;
    const ts_id = ownProps.match.params.ts_id;

    const users = state.firestore.data.users;
    const userData = users ? users[user_id] : null;

    const batchs = state.firestore.data.physicalClassBatches;
    const batchData = batchs ? batchs[batch_id] : null;

    const transcripts = state.firestore.data.transcripts;
    const tsData = transcripts ? transcripts[ts_id] : null;

    let courses, selectedModules;

    if(tsData) {
        let courseId = tsData.courseId;

        courses = state.firestore.data.courses;
        selectedModules = courses ? courses[courseId].selectedModules : null;
    }

    return {
        
        userId: user_id,
        userData,
        batchData,
        tsData,
        selectedModules,

        quizEnrollments : state.firestore.ordered.quizEnrollments,
        examEnrollments : state.firestore.ordered.examEnrollments,

    }
}
  
export default compose(
    connect(mapStateToProps, null),
    firestoreConnect(props => [
        { collection: 'physicalClassBatches' },
        { collection: 'transcripts' },
        { collection: 'quizEnrollments', where: props.userId ? ['userId', '==', props.userId] : null },
        { collection: 'examEnrollments', where: props.userId ? ['userId', '==', props.userId] : null },

        { collection: 'courses' },
    ])
)(Transcript);