const initState = {
    chatMessage: []
}

const chatReducer = (state = initState, action ) => {

    switch (action.type) {

        case 'NEW_MESSAGE':
            return state;
        case 'NEW_MESSAGE_ERROR':
            return state;

        case 'NEW_MESSAGE2':
        return state;
        case 'NEW_MESSAGE_ERROR2':
            return state;

        default:
            return state;
    }
}

export default chatReducer