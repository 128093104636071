import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { addFaq, updateFaq, deleteFaq } from '../../stores/actions/faqAction';

class FaqManager extends Component{
    
    constructor(props){
        super(props)
        this.state = {
            modalType:'',

            faqType: '',
            faqQuestion: '',
            faqAnswer: '',
            faqLink: null,

            faqLink_link: '',
            faqLink_name:'',
            
            isLoading: false,
            message: ''
        }
        this.baseState = this.state 
    }
    
    componentDidUpdate = (prevProps, prevState) => { 
        const { addFaqError, addFaqSuccess, updateFaqError, updateFaqSuccess, deleteFaqSuccess, deleteFaqError } = this.props;

        if(prevProps.addFaqError !== addFaqError) {
            this.setState({
                isLoading: false,
                message: {
                    msg: addFaqError.message,
                    type: addFaqError.type,
                }
            })
        }

        if(prevProps.addFaqSuccess !== addFaqSuccess) {
            this.setState({
                isLoading: false,
                message: {
                    msg: addFaqSuccess.message,
                    type: addFaqSuccess.type,
                }
            })
            setTimeout(() => { 
                this.setState(this.baseState)
                this.hideModalFaq();
            }, 2000);
        }

        if(prevProps.updateFaqError !== updateFaqError) {
            this.setState({
                isLoading: false,
                message: {
                    msg: updateFaqError.message,
                    type: updateFaqError.type,
                }
            })
        }

        if(prevProps.updateFaqSuccess !== updateFaqSuccess) {
            this.setState({
                isLoading: false,
                message: {
                    msg: updateFaqSuccess.message,
                    type: updateFaqSuccess.type,
                }
            })
            setTimeout(() => { 
                this.setState(this.baseState)
                this.hideModalFaq();
            }, 2000);
        }
        
        if(prevProps.deleteFaqSuccess !== deleteFaqSuccess) {
            alert(deleteFaqSuccess.message)
            setTimeout(() => { 
                this.setState(this.baseState)
            }, 2000);
        }
        
        if(prevProps.deleteFaqError !== deleteFaqError) {
            alert(deleteFaqError.message)
        }
    } 

    setFaqData = async (id) => {

        const { faqs } = this.props;

        faqs && faqs.map(faq => {
            if(faq.id === id) {
                this.setState({
                    faqType: faq.faqType,
                    faqQuestion: faq.faqQuestion,
                    faqAnswer: faq.faqAnswer,
                })

                if(faq.faqLink) {
                    this.setState({
                        faqLink_link: faq.faqLink.faqLink_link,
                        faqLink_name: faq.faqLink.faqLink_name
                    })
                }
            }
            return null
        })
    }

    showModalFaq = async (type, id) => {
        this.setState({
            modalType: type,
            faqId: id
        })

        if(type === "new") {
            document.getElementById("modal-faq").style.display = "block";
        } else if (type === "edit") {
            await this.setFaqData(id);
            document.getElementById("modal-faq").style.display = "block";
        }
    }

    hideModalFaq = () => {
        document.getElementById("modal-faq").style.display = "none";
        this.setState(this.baseState)
    }

    setLink = async () => {
        this.setState({
            faqLink: {
                faqLink_link: this.state.faqLink_link,
                faqLink_name: this.state.faqLink_name
            }
        })
    }

    faqAction = async (type, id) => {
        var result = window.confirm("Confirm?");
        await this.setLink();
    
        if (result) {
            this.setState({
                isLoading: true
            })
            if(type === "add") {
                this.props.addFaq(this.state);
            } else if(type === "update") {
                this.props.updateFaq(this.state, this.state.faqId);
            } else if(type === "delete") {
                this.props.deleteFaq(id);
            }
        }
    }

    render(){

        const { query, faqs } = this.props;
        const { message, isLoading } = this.state;

        let hasData = [], isLoadingData= true;

        let query_filter = query.get('filter');

        let faqList =  faqs && faqs.map((faq,i) => {

            let typee = faq.faqType === "ts" ? 
                "uppercase badge bg-blue text-white" 
            : faq.faqType === "qna" ? 
                "uppercase badge bg-purple text-white"
            : null

            if(query_filter) {
                if(query_filter === faq.faqType) {
                    hasData.push(faq)
                    isLoadingData = false;
                    return (
                        <tr key={i}>
                            <td><span className={typee}>{faq.faqType}</span></td>
                            <th className="text-left">{faq.faqQuestion}</th>
                            <td className="text-left">
                                {faq.faqAnswer}
                                <br/>
                                { faq.faqLink.faqLink_link ?
                                    <a target="_blank" rel="noopener noreferrer" href={faq.faqLink.faqLink_link}>
                                        <i><i className="fas fa-link"/>{' '}{faq.faqLink.faqLink_name}</i>
                                    </a>
                                : null }
                            </td>
                            <td>
                                <div className="row col-md-12 justify-content-center">
                                    <span onClick={this.showModalFaq.bind(this, 'edit', faq.id)} ><i className="fas fa-edit"/></span>
                                    <span onClick={this.faqAction.bind(this, 'delete', faq.id)} className="text-red"><i className="fas fa-trash"/></span>
                                </div>
                            </td>
                        </tr>
                    )
                } else {
                    isLoadingData = false;
                }
            } else {
                hasData.push(faq)
                isLoadingData = false;
                return (
                    <tr key={i}>
                        <td><span className={typee}>{faq.faqType}</span></td>
                        <th className="text-left" scope="col">{faq.faqQuestion}</th>
                        <td className="text-left">
                            {faq.faqAnswer}
                            <br/>
                            { faq.faqLink.faqLink_link ?
                                <a target="_blank" rel="noopener noreferrer" href={faq.faqLink.faqLink_link}>
                                    <i><i className="fas fa-link"/>{' '}{faq.faqLink.faqLink_name}</i>
                                </a>
                            : null }
                        </td>
                        <td>
                            <div className="row col-md-12 justify-content-center">
                                <span onClick={this.showModalFaq.bind(this, 'edit', faq.id)}><i className="fas fa-edit"/></span>
                                <span onClick={this.faqAction.bind(this, 'delete', faq.id)} className="text-red"><i className="fas fa-trash"/></span>
                            </div>
                        </td>
                    </tr>
                )
            }
            return null
        })

        return(

        <div>
           
           <div id="modal-faq" className="mymodal">
               <div className="mymodal-content col-md-6">
                   <span className="close" onClick={this.hideModalFaq.bind(this)}>&times;</span>

                    {/* -----------------------NEW FAQ------------------------------ */}
                    { this.state.modalType === "new" ?

                        <div>
                            <h5 className="middle">add new FAQ
                                <div className="row"><div className="title-border"></div></div>
                            </h5>

                            <p className="">
                                <b>FAQ Type: </b>
                                <select id="faqType" onChange={e => this.setState({faqType:  e.target.value})} value={this.state.faqType} className="col-md-6 form-control">
                                    <option defaultValue>Choose Type</option>
                                    <option value="ts">Technical Support</option>
                                    <option value="qna">Question and Answer</option>
                                </select>
                            </p>

                            <p>
                                <b>Question: </b>
                                <textarea onChange={e => this.setState({faqQuestion:  e.target.value})} value={this.state.faqQuestion} type="text" className="form-control" placeholder="Question"/>
                            </p>

                            <p>
                                <b>Answer:</b>
                                <textarea onChange={e => this.setState({faqAnswer:  e.target.value})} value={this.state.faqAnswer} type="text" className="form-control" placeholder="Answer" />
                            </p>

                            <p>
                                <b>Link Attachment:</b>
                                <input onChange={e => this.setState({faqLink_name:  e.target.value})} value={this.state.faqLink_name} type="text" className="form-control" placeholder="Link name" />
                                <input onChange={e => this.setState({faqLink_link:  e.target.value})} value={this.state.faqLink_link} type="text" className="form-control" placeholder="Link url" />
                            </p>

                            { isLoading === true ? 
                                <small>Loading...</small>
                            : 
                                message  ?
                                    <small className={message.type === 'addFaqError' ? "text-red" : message.type === 'addFaqSuccess'  ? "text-green" : null }>
                                        {message.msg}
                                    </small>
                                : null
                            }

                            <div className="mt-5 row justify-content-end">
                                <button className="btn m-2 btn-outline-black" onClick={this.faqAction.bind(this, 'add', null)}><i className="fas fa-plus-circle"/>{' '}add faq</button>
                            </div>
                        </div>

                    // -----------------------UPDATE FAQ------------------------------
                    : this.state.modalType === "edit" ?

                    <div>
                        <h5 className="middle">edit FAQ
                            <div className="row"><div className="title-border"></div></div>
                        </h5>

                        <p className="">
                            <b>FAQ Type: </b>
                            <input disabled value={this.state.faqType} type="text" className="form-control"/>
                        </p>

                        <p>
                            <b>Question: </b>
                            <textarea onChange={e => this.setState({faqQuestion:  e.target.value})} value={this.state.faqQuestion} type="text" className="form-control" placeholder="Question"/>
                        </p>

                        <p>
                            <b>Answer:</b>
                            <textarea onChange={e => this.setState({faqAnswer:  e.target.value})} value={this.state.faqAnswer} type="text" className="form-control" placeholder="Answer" />
                        </p>

                        <p>
                            <b>Link Attachment:</b>
                            <input onChange={e => this.setState({faqLink_name:  e.target.value})} value={this.state.faqLink_name} type="text" className="form-control" placeholder="Link name" />
                            <input onChange={e => this.setState({faqLink_link:  e.target.value})} value={this.state.faqLink_link} type="text" className="form-control" placeholder="Link url" />
                        </p>

                        { isLoading === true ? 
                            <small>Loading...</small>
                        : 
                            message  ?
                                <small className={message.type === 'updateFaqError' ? "text-red" : message.type === 'updateFaqSuccess'  ? "text-green" : null }>
                                    {message.msg}
                                </small>
                            : null
                        }

                        <div className="mt-5 row justify-content-end">
                            <button className="btn m-2 btn-outline-black"  onClick={this.faqAction.bind(this, 'update', null)}><i className="fas fa-plus-circle"/>{' '}update faq</button>
                        </div>
                    </div>

                    : null }

               </div>
           </div>

           {/* -----------------------DISPLAY FAQ ------------------------------ */}
            <h3  className="text-left">FAQ Manager
                <div className="title-border"></div>
            </h3>

            <button className="btn btn-outline-black" onClick={this.showModalFaq.bind(this, 'new', null)}>
                <i className="fas fa-plus-circle"/>{' '}Faq
            </button>

            <div className="tab">
                <Link className={query_filter === '' ? "tablinks active" : "tablinks"} to="/systemmanager?topic=faq&filter=">All</Link>
                <Link className={query_filter === 'ts' ? "tablinks active" : "tablinks"} to="/systemmanager?topic=faq&filter=ts">Technical Support</Link>
                <Link className={query_filter === 'qna' ? "tablinks active" : "tablinks"} to="/systemmanager?topic=faq&filter=qna">Q&A</Link>
            </div>

            <div className="tabcontent">
                <div style={{margin:"20px"}}>

                    { isLoadingData === true ? 
                        <p>Please wait... Data loading...</p> 
                    : 
                        hasData.length > 0 ? 
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Type</th>
                                        <th scope="col">Question</th>
                                        <th scope="col">Answer</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {faqList}
                                </tbody>
                            </table> 
                        : 
                            <p>No faq available</p>
                    }
                </div>
            </div>   
        </div>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        addFaqError: state.faqs.addFaqError,
        addFaqSuccess: state.faqs.addFaqSuccess,
        updateFaqError: state.faqs.updateFaqError,
        updateFaqSuccess: state.faqs.updateFaqSuccess,
        deleteFaqSuccess: state.faqs.deleteFaqSuccess,
        deleteFaqError: state.faqs.deleteFaqError,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
      addFaq: (faq) => dispatch(addFaq(faq)),
      updateFaq: (faq, faqId) => dispatch(updateFaq(faq, faqId)),
      deleteFaq: (faqId) => dispatch(deleteFaq(faqId)),
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(FaqManager);