export const addSubtopic = (subtopic) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => { //return function sbb guna THUNK

        //make asynchronous call to database
        const firestore = getFirestore();

        firestore.collection('subtopics').add({
            subtopicName: subtopic.subtopicName,
            subtopicCode: subtopic.subtopicCode,

        }).then(() => { //ni namanya callback function
            dispatch({ type:'ADDED_SUBTOPIC', subtopic: subtopic}); //dah dispatch, g code kat reducer lak
        }).catch((err) => {
            dispatch({ type:'ADDED_SUBTOPIC_ERROR', err}); //dispatch ke reducer kalau catch error
        })
    }
}

export const addTopic = (topic) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {

        const firestore = getFirestore();
        
        if(!topic.topicName || !topic.topicCode || !topic.subtopics) {
            dispatch({ type:'ADDED_TOPIC_ERROR', err: { message: 'Input field required.' }});
        } else {
            firestore.collection('topics').add({
                topicName: topic.topicName,
                topicCode: topic.topicCode,
                subtopics: topic.subtopics,
                selectedNote: topic.selectedNote,
                selectedVideo: topic.selectedVideo
            }).then((resp) => { 
                dispatch({ type:'ADDED_TOPIC', message: 'Topic added successfully.', id: resp.id}); 
            }).catch((err) => {
                dispatch({ type:'ADDED_TOPIC_ERROR', err});
            })
        }
    }
}

export const addQuiz = (quiz) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {

        const firestore = getFirestore();

        if(!quiz.quizName || !quiz.quizCode || quiz.selectedQuestions.length < 1) {
            dispatch({ type:'ADDED_QUIZ_ERROR', err: { message: 'Input field required.' }});
        } else {
            firestore.collection('quizzes').add({
                quizName: quiz.quizName,
                quizCode: quiz.quizCode,
                selectedQuestions: quiz.selectedQuestions,
            }).then((resp) => { 
                dispatch({ type:'ADDED_QUIZ', message: 'Quiz Set added successfully.', id: resp.id}); 
            }).catch((err) => {
                dispatch({ type:'ADDED_QUIZ_ERROR', err});
            })
        }
    }
}

export const addModule = (module) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {

        const firestore = getFirestore();
        const { moduleName, moduleCode, selectedTopics, practicalAssessment, quiz } = module;
        let pa = practicalAssessment;

        let proceedAdd =  false;

        if(!moduleName || !moduleCode || selectedTopics.length < 1) {
            dispatch({ type:'ADDED_MODULE_ERROR', err: { message: 'Input field required.' }});
        } else {
            if(pa.hasPracticalAssessment === "true") {
                if(!pa.fileName || !pa.fileUrl) {
                    dispatch({ type:'ADDED_MODULE_ERROR', err: { message: 'Input field required.' }});
                } else {
                    //check quiz
                    if(quiz.hasQuiz === "true") {
                        if(!quiz.questionCount || !quiz.quizMark || quiz.quizSuspendDay === null || quiz.quizSuspendNumber === null || !quiz.quizTimer || !quiz.selectedQuizSet) {
                            dispatch({ type:'ADDED_MODULE_ERROR', err: { message: 'Input field required.' }});
                        } else {
                            proceedAdd = true
                        }
                    } else {
                        proceedAdd = true
                    }
                }
            } else {
                //check quiz
                if(quiz.hasQuiz === "true") {
                    if(!quiz.questionCount || !quiz.quizMark || quiz.quizSuspendDay === null || quiz.quizSuspendNumber === null || !quiz.quizTimer || !quiz.selectedQuizSet) {
                        dispatch({ type:'ADDED_MODULE_ERROR', err: { message: 'Input field required.' }});
                    } else {
                        proceedAdd = true
                    }
                } else {
                    proceedAdd = true
                }
            }
        }

        if (proceedAdd === true) {
            firestore.collection('modules').add({
                moduleName: module.moduleName,
                moduleCode: module.moduleCode,
                selectedTopics:  module.selectedTopics,
                practicalAssessment:  module.practicalAssessment,
                quiz: module.quiz,
            }).then((resp) => { 
                dispatch({ type:'ADDED_MODULE', message: 'Module added successfully.', id: resp.id}); 
            }).catch((err) => {
                dispatch({ type:'ADDED_MODULE_ERROR', err});
            })
        }
    }
}

export const addExam = (exam) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {

        const firestore = getFirestore();

        if(!exam.examName || !exam.examCode || exam.selectedQuestions.length < 1) {
            dispatch({ type:'ADDED_EXAM_ERROR', err: { message: 'Input field required.' }});
        } else {
            firestore.collection('exams').add({
                examName: exam.examName,
                examCode: exam.examCode,
                selectedQuestions:  exam.selectedQuestions,
            }).then((resp) => { 
                dispatch({ type:'ADDED_EXAM', message: 'Exam Set added successfully.', id: resp.id}); 
            }).catch((err) => {
                dispatch({ type:'ADDED_EXAM_ERROR', err});
            })
        }
    }
}

export const addCourse = (course) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {

        const firestore = getFirestore();

        let exam = course.exam;
        let proceedAdd = false;

        if(course.courseType === "Professional") {
            if(course.coursePrice === null || !course.coursePeriod || !course.courseCode || !course.courseName || !course.courseStatus || course.selectedModules.length < 1 || !course.hasPhysicalClass || !course.removeQuizBtn  || !course.enableExam || !course.installmentAvailable || !course.hasEcert || !course.extendPeriod || course.extendPrice === null || !course.toyyibCatCode || !course.toyyibCatCode_Extend) {
                dispatch({ type:'ADDED_COURSE_ERROR', err: { message: 'Input field required.' }});
            } else {
                if(exam.hasExam === "true") {
                    if(!exam.selectedExamSet || !exam.examTimer || !exam.examMark || exam.examSuspendDay === null || exam.examSuspendNumber === null || !exam.questionCount) {
                        dispatch({ type:'ADDED_COURSE_ERROR', err: { message: 'Input field required.' }});
                    } else {
                        proceedAdd = true;
                    }
                } else {
                        proceedAdd = true;
                    }
            }
        } else if (course.courseType === "Flexi") {
            if(course.coursePrice === null || !course.courseCode || !course.courseName || !course.courseStatus || !course.selectedCourse || !course.selectedModule || !course.toyyibCatCode) {
                dispatch({ type:'ADDED_COURSE_ERROR', err: { message: 'Input field required.' }});
            } else {
                proceedAdd = true;
            }
        }

        if(proceedAdd === true) {
            firestore.collection('courses').add({
                courseName: course.courseName,
                courseCode: course.courseCode,
                courseType: course.courseType,
                coursePeriod: course.coursePeriod,
                coursePrice: course.coursePrice,
                toyyibCatCode: course.toyyibCatCode,
                courseStatus: course.courseStatus,
                courseAttach: course.courseAttach,
                courseInfo: course.courseInfo,
                courseLearn: course.courseLearn,
                courseOverview: course.courseOverview,
                courseRequirement: course.courseRequirement,

                extendPeriod: course.extendPeriod,
                extendPrice: course.extendPrice,
                toyyibCatCode_Extend: course.toyyibCatCode_Extend,
    
                installmentAvailable: course.installmentAvailable,
                hasPhysicalClass: course.hasPhysicalClass,
                hasEcert: course.hasEcert,
                removeQuizBtn:  course.removeQuizBtn,
                enableExam:  course.enableExam,
    
                selectedModules: course.selectedModules,
                exam: course.exam,
        
                selectedCourse: course.selectedCourse,
                selectedModule: course.selectedModule,
            }).then((resp) => { 
                dispatch({ type:'ADDED_COURSE', message: 'Course added successfully.', id: resp.id}); 
            }).catch((err) => {
                dispatch({ type:'ADDED_COURSE_ERROR', err});
            })
        }
    }
}

export const addProgram = (program) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {

        const firestore = getFirestore();

        if(!program.programCode || !program.programName || !program.programStatus || program.selectedCourses.length < 1 || !program.programContent) {
            dispatch({ type:'ADDED_PROGRAM_ERROR', err: { message: 'Input field required.' }});
        } else {
            firestore.collection('programs').add({
                programStatus: program.programStatus,
                programName: program.programName,
                programCode: program.programCode,
                programContent: program.programContent,
                programImage: program.programImage,
    
                selectedCourses:  program.selectedCourses,
            }).then((resp) => { 
                dispatch({ type:'ADDED_PROGRAM', message: 'Program added successfully.', id: resp.id}); 
            }).catch((err) => {
                dispatch({ type:'ADDED_PROGRAM_ERROR', err});
            })
        }
    }
}

export const updateSubtopic = (updatedSubtopic, subtopicId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => { 

        const firestore = getFirestore();

        firestore.collection("subtopics").doc(subtopicId).update({
            subtopicName: updatedSubtopic.subtopicName,
            subtopicCode: updatedSubtopic.subtopicCode,
        })
        .then(() => {
            dispatch({ type:'UPDATED_SUBTOPIC', updatedSubtopic: updatedSubtopic});
        }).catch((err) => {
            dispatch({ type:'UPDATED_SUBTOPIC_ERROR', err});
        })
    }
}

export const updateTopic = (updatedTopic, topicId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => { 

        const firestore = getFirestore();
        
        if(!updatedTopic.topicName || !updatedTopic.topicCode || !updatedTopic.subtopics) {
            dispatch({ type:'UPDATED_TOPIC_ERROR', err: { message: 'Input field required.' }});
        } else {
            firestore.collection("topics").doc(topicId).update({
                topicName: updatedTopic.topicName,
                topicCode: updatedTopic.topicCode,
                subtopics: updatedTopic.subtopics,
                selectedVideo: updatedTopic.selectedVideo,
                selectedNote: updatedTopic.selectedNote,
            })
            .then(() => {
                dispatch({ type:'UPDATED_TOPIC', message: 'Topic updated successfully.'});
            }).catch((err) => {
                dispatch({ type:'UPDATED_TOPIC_ERROR', err});
            })
        }
    }
}

export const updateQuiz = (updatedQuiz, quizId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => { 

        const firestore = getFirestore();

        if(!updatedQuiz.quizName || !updatedQuiz.quizCode || updatedQuiz.selectedQuestions.length < 1) {
            dispatch({ type:'UPDATED_QUIZ_ERROR', err: { message: 'Input field required.' }});
        } else {
            firestore.collection("quizzes").doc(quizId).update({
                quizName: updatedQuiz.quizName,
                quizCode: updatedQuiz.quizCode,
                selectedQuestions: updatedQuiz.selectedQuestions,
            })
            .then(() => {
                dispatch({ type:'UPDATED_QUIZ', message: 'Quiz Set updated successfully.'});
            }).catch((err) => {
                dispatch({ type:'UPDATED_QUIZ_ERROR', err});
            })
        }
    }
}

export const updateModule = (updatedModule, moduleId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => { 

        const firestore = getFirestore();
        const { moduleName, moduleCode, selectedTopics, practicalAssessment, quiz } = updatedModule;

        let pa = practicalAssessment;

        let proceedUpdate = false;

        if(!moduleName || !moduleCode || selectedTopics.length < 1) {
            dispatch({ type:'UPDATED_MODULE_ERROR', err: { message: 'Input field required.' }});
        } else {
            if(pa.hasPracticalAssessment === "true") {
                if(!pa.fileName || !pa.fileUrl) {
                    dispatch({ type:'UPDATED_MODULE_ERROR', err: { message: 'Input field required.' }});
                } else {
                    //check quiz
                    if(quiz.hasQuiz === "true") {
                        if(!quiz.questionCount || !quiz.quizMark || quiz.quizSuspendDay === null || quiz.quizSuspendNumber === null || !quiz.quizTimer || !quiz.selectedQuizSet) {
                            dispatch({ type:'UPDATED_MODULE_ERROR', err: { message: 'Input field required.' }});
                        } else {
                            proceedUpdate = true
                        }
                    } else {
                        proceedUpdate = true
                    }
                }
            } else {
                //check quiz
                if(quiz.hasQuiz === "true") {
                    if(!quiz.questionCount || !quiz.quizMark || quiz.quizSuspendDay === null || quiz.quizSuspendNumber === null || !quiz.quizTimer || !quiz.selectedQuizSet) {
                        dispatch({ type:'UPDATED_MODULE_ERROR', err: { message: 'Input field required.' }});
                    } else {
                        proceedUpdate = true
                    }
                } else {
                    proceedUpdate = true
                }
            }
        }

        if (proceedUpdate === true) {
            firestore.collection("modules").doc(moduleId).update({
                moduleName: updatedModule.moduleName,
                moduleCode: updatedModule.moduleCode,
                selectedTopics: updatedModule.selectedTopics,
                practicalAssessment: updatedModule.practicalAssessment,
                quiz: updatedModule.quiz,
            })
            .then(() => {
                dispatch({ type:'UPDATED_MODULE', message: 'Module updated successfully.'});
            }).catch((err) => {
                dispatch({ type:'UPDATED_MODULE_ERROR', err});
            })
        }
    }
}

export const updateExam = (updatedExam, examId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => { 

        const firestore = getFirestore();

        if(!updatedExam.examName || !updatedExam.examCode || updatedExam.selectedQuestions.length < 1) {
            dispatch({ type:'UPDATED_EXAM_ERROR', err: { message: 'Input field required.' }});
        } else {
            firestore.collection("exams").doc(examId).update({
                examName: updatedExam.examName,
                examCode: updatedExam.examCode,
                selectedQuestions: updatedExam.selectedQuestions,
            })
            .then(() => {
                dispatch({ type:'UPDATED_EXAM', message: 'Exam Set updated successfully.'});
            }).catch((err) => {
                dispatch({ type:'UPDATED_EXAM_ERROR', err});
            })
        }
    }
}

export const updateCourse = (updatedCourse, courseId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => { 

        const firestore = getFirestore();
        let exam = updatedCourse.exam
        let proceedUpdate = false;

        if(updatedCourse.courseType === "Professional") {
            if(updatedCourse.coursePrice === null || !updatedCourse.coursePeriod || !updatedCourse.courseCode || !updatedCourse.courseName || !updatedCourse.courseStatus || updatedCourse.selectedModules.length < 1 || !updatedCourse.hasPhysicalClass || !updatedCourse.removeQuizBtn || !updatedCourse.enableExam || !updatedCourse.installmentAvailable || !updatedCourse.hasEcert || !updatedCourse.extendPeriod || updatedCourse.extendPrice === null || !updatedCourse.toyyibCatCode || !updatedCourse.toyyibCatCode_Extend) {
                dispatch({ type:'UPDATED_COURSE_ERROR', err: { message: 'Input field required.' }});
            } else {
                if(exam.hasExam === "true") {
                    if(!exam.selectedExamSet || !exam.examTimer || !exam.examMark || exam.examSuspendDay === null || exam.examSuspendNumber === null || !exam.questionCount) {
                        dispatch({ type:'UPDATED_COURSE_ERROR', err: { message: 'Input field required.' }});
                    } else {
                        proceedUpdate = true;
                    }
                } else {
                        proceedUpdate = true;
                    }
            }
        } else if (updatedCourse.courseType === "Flexi") {
            if(updatedCourse.coursePrice === null || !updatedCourse.courseCode || !updatedCourse.courseName || !updatedCourse.courseStatus || !updatedCourse.selectedCourse || !updatedCourse.selectedModule || !updatedCourse.toyyibCatCode) {
                dispatch({ type:'UPDATED_COURSE_ERROR', err: { message: 'Input field required.' }});
            } else {
                proceedUpdate = true;
            }
        }

        if(proceedUpdate === true) {
            firestore.collection("courses").doc(courseId).update({
                courseName: updatedCourse.courseName,
                courseCode: updatedCourse.courseCode,
                courseType: updatedCourse.courseType,
                coursePeriod: updatedCourse.coursePeriod,
                coursePrice: updatedCourse.coursePrice,
                toyyibCatCode: updatedCourse.toyyibCatCode,
                courseStatus: updatedCourse.courseStatus,
                courseAttach: updatedCourse.courseAttach,
                installmentAvailable: updatedCourse.installmentAvailable,
                courseInfo: updatedCourse.courseInfo,
                courseOverview: updatedCourse.courseOverview,
                courseLearn: updatedCourse.courseLearn,
                courseRequirement: updatedCourse.courseRequirement,
                
                extendPeriod: updatedCourse.extendPeriod,
                extendPrice: updatedCourse.extendPrice,
                toyyibCatCode_Extend: updatedCourse.toyyibCatCode_Extend,

                hasEcert: updatedCourse.hasEcert,
                hasPhysicalClass: updatedCourse.hasPhysicalClass,
                removeQuizBtn:  updatedCourse.removeQuizBtn,
                enableExam:  updatedCourse.enableExam,

                exam: updatedCourse.exam,
                selectedModules: updatedCourse.selectedModules,

                selectedCourse: updatedCourse.selectedCourse,
                selectedModule: updatedCourse.selectedModule,
            })
            .then(() => {
                dispatch({ type:'UPDATED_COURSE', message: 'Course updated successfully.'});
            }).catch((err) => {
                dispatch({ type:'UPDATED_COURSE_ERROR', err});
            })
        }
    }
}

export const updateProgram = (updatedProgram, programId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => { 

        const firestore = getFirestore();

        if(!updatedProgram.programCode || !updatedProgram.programName || !updatedProgram.programStatus || updatedProgram.selectedCourses.length < 1 || !updatedProgram.programContent) {
            dispatch({ type:'UPDATED_PROGRAM_ERROR', err: { message: 'Input field required.' }});
        } else {
            firestore.collection("programs").doc(programId).update({
                programName: updatedProgram.programName,
                programCode: updatedProgram.programCode,
                programContent: updatedProgram.programContent,
                programStatus: updatedProgram.programStatus,
                programImage: updatedProgram.programImage,
                selectedCourses: updatedProgram.selectedCourses,
            })
            .then(() => {
                dispatch({ type:'UPDATED_PROGRAM', message: 'Program updated successfully.'});
            }).catch((err) => {
                dispatch({ type:'UPDATED_PROGRAM_ERROR', err});
            })
        }
    }
}

// export const updateProgramFlexi = (updatedProgram, programId) => {
//     return (dispatch, getState, {getFirebase, getFirestore}) => { 

//         const firestore = getFirestore();

//         firestore.collection("programs").doc(programId).update({
//             programName: updatedProgram.programName,
//             programCode: updatedProgram.programCode,
//             programType: updatedProgram.programType,

//             programPrice: updatedProgram.programPrice,
//             programContent: updatedProgram.programContent,
//             programStatus: updatedProgram.programStatus,
//             programVideo: updatedProgram.programVideo,

//             selectedProgram: updatedProgram.selectedProgram,
//             selectedModule: updatedProgram.selectedModule,
//         })
//         .then(() => {
//             dispatch({ type:'UPDATED_PROGRAM_FLEXI', updatedProgram: updatedProgram});
//         }).catch((err) => {
//             dispatch({ type:'UPDATED_PROGRAM_FLEXI_ERROR', err});
//         })
//     }
// }

export const deleteSubtopic = (subtopicId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {

        const firestore = getFirestore();

        firestore.collection("subtopics").doc(subtopicId).delete()
        .then(() => {
            dispatch({ type:'DELETED_SUBTOPIC', subtopicId: subtopicId});
        }).catch((err) => {
            dispatch({ type:'DELETED_SUBTOPIC_ERROR', err});
        })
    }
}

export const deleteTopic = (topicId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {

        const firestore = getFirestore();

        firestore.collection("topics").doc(topicId).delete()
        .then(() => {
            dispatch({ type:'DELETED_TOPIC', message: 'Topic deleted successfully.'});
        }).catch((err) => {
            dispatch({ type:'DELETED_TOPIC_ERROR', err});
        })
    }
}

export const deleteQuiz = (quizId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {

        const firestore = getFirestore();

        firestore.collection("quizzes").doc(quizId).delete()
        .then(() => {
            dispatch({ type:'DELETED_QUIZ', message: 'Quiz Set deleted successfully.'});
        }).catch((err) => {
            dispatch({ type:'DELETED_QUIZ_ERROR', err});
        })
    }
}

export const deleteModule = (moduleId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {

        const firestore = getFirestore();

        firestore.collection("modules").doc(moduleId).delete()
        .then(() => {
            dispatch({ type:'DELETED_MODULE', message: 'Module deleted successfully.'});
        }).catch((err) => {
            dispatch({ type:'DELETED_MODULE_ERROR', err});
        })
    }
}

export const deleteExam = (examId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {

        const firestore = getFirestore();

        firestore.collection("exams").doc(examId).delete()
        .then(() => {
            dispatch({ type:'DELETED_EXAM', message: 'Exam Set deleted successfully.'});
        }).catch((err) => {
            dispatch({ type:'DELETED_EXAM_ERROR', err});
        })
    }
}

export const deleteCourse = (courseId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {

        const firestore = getFirestore();

        firestore.collection("courses").doc(courseId).delete()
        .then(() => {
            dispatch({ type:'DELETED_COURSE', message: 'Course deleted successfully.'});
        }).catch((err) => {
            dispatch({ type:'DELETED_COURSE_ERROR', err});
        })
    }
}

export const deleteProgram = (programId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {

        const firestore = getFirestore();

        firestore.collection("programs").doc(programId).delete()
        .then(() => {
            dispatch({ type:'DELETED_PROGRAM', message: 'Program deleted successfully.'});
        }).catch((err) => {
            dispatch({ type:'DELETED_PROGRAM_ERROR', err});
        })
    }
}

export const employerMode = (isEmployer, courseId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {

        const firestore = getFirestore();

        firestore.collection('courses').doc(courseId).update({
            employerMode: isEmployer

        }).then(() => {
            dispatch({ type:'UPDATE_EMPLOYER_MODE', message: 'isEmployer mode updated successfully.'});
        }).catch((err) => {
            dispatch({ type:'UPDATE_EMPLOYER_MODE_ERROR', err});
        })
    }
}




