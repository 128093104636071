import React, { Component } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import EditUserAccount from "./EditUserAccount";

class UserAccount extends Component {
  render() {
    const { query, profile } = this.props;

    let query_edit = query.get("edit");

    if (profile.isLoaded === true) {
      return (
        <div>
          <b>
            <i className="fas fa-user text-darkgrey"></i>
            {"  "}My Profile
          </b>
          <div>
            Please ensure all information are correct.
            <div className="row">
              <div className="title-border"></div>
            </div>
          </div>

          {query_edit === "false" ? (
            // ------------------------ DISPLAY PROFILE ------------------------
            <div
              className="row justify-content-center"
              style={{ marginTop: "20px" }}
            >
              <form className="col form-group col-md-10">
                <div className="row">
                  <div className="col">
                    <b>Full Name:</b>
                    <div>
                      {profile.fullname ? (
                        profile.fullname
                      ) : (
                        <i>Please update.</i>
                      )}
                    </div>
                  </div>
                </div>

                <br />
                
                <div className="row">
                  <div className="col">
                    <b>First Name:</b>
                    <div>
                      {profile.firstname ? (
                        profile.firstname
                      ) : (
                        <i>Please update.</i>
                      )}
                    </div>
                  </div>

                  <div className="col">
                    <b>Last Name:</b>
                    <div>
                      {profile.lastname ? (
                        profile.lastname
                      ) : (
                        <i>Please update.</i>
                      )}
                    </div>
                  </div>
                </div>

                <br />

                <div className="row">
                  <div className="col">
                    <b>IC Number:</b>
                    <div>
                      {profile.identityCardNo ? (
                        profile.identityCardNo
                      ) : (
                        <i>Please update.</i>
                      )}
                    </div>
                  </div>

                  <div className="col">
                    <b>Phone Number:</b>
                    <div>
                      {profile.contactNo ? (
                        profile.contactNo
                      ) : (
                        <i>Please update.</i>
                      )}
                    </div>
                  </div>
                </div>

                <br />

                <div className="row">
                  <div className="col">
                    <b>Email:</b>
                    <div>
                      {profile.email ? profile.email : <i>Please update.</i>}
                    </div>
                  </div>

                  <div className="col">
                    <b>Date of Birth:</b>
                    <div>
                      {profile.dob ? (
                        moment(profile.dob).format("DD/MM/YYYY")
                      ) : (
                        <i>Please update.</i>
                      )}
                    </div>
                  </div>
                </div>

                <br />

                <div className="row">
                  <div className="col">
                    <b>Company Name / Organization:</b>
                    <div>
                      {profile.company ? (
                        profile.company
                      ) : (
                        <i>Please update.</i>
                      )}
                    </div>
                  </div>
                </div>

                <br />

                <b>Address:</b>
                <div>
                  <div className="row col-md-12">
                    <b className="col col-md-3">
                      <li>Line 1</li>{" "}
                    </b>
                    <div className="col">
                      {profile.address1 ? (
                        profile.address1
                      ) : (
                        <i>Please update.</i>
                      )}
                    </div>
                  </div>
                  <div className="row col-md-12">
                    <b className="col col-md-3">
                      <li>Line 2</li>{" "}
                    </b>
                    <div className="col">
                      {profile.address2 ? (
                        profile.address2
                      ) : (
                        <i>Please update.</i>
                      )}
                    </div>
                  </div>
                  <div className="row col-md-12">
                    <b className="col col-md-3">
                      <li>Postal Code</li>{" "}
                    </b>
                    <div className="col">
                      {profile.postcode ? (
                        profile.postcode
                      ) : (
                        <i>Please update.</i>
                      )}
                    </div>
                  </div>
                  <div className="row col-md-12">
                    <b className="col col-md-3">
                      <li>City</li>{" "}
                    </b>
                    <div className="col">
                      {profile.city ? profile.city : <i>Please update.</i>}
                    </div>
                  </div>
                  <div className="row col-md-12">
                    <b className="col col-md-3">
                      <li>State</li>{" "}
                    </b>
                    <div className="col">
                      {profile.state ? profile.state : <i>Please update.</i>}
                    </div>
                  </div>
                  <div className="row col-md-12">
                    <b className="col col-md-3">
                      <li>Country</li>{" "}
                    </b>
                    <div className="col">
                      {profile.country ? (
                        profile.country
                      ) : (
                        <i>Please update.</i>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row justify-content-end">
                  <Link
                    to="/userdashboard?type=myaccount&edit=true"
                    className="mt-3 btn all-btn"
                  >
                    <i className="fas fa-edit" /> Edit
                  </Link>
                </div>
              </form>
            </div>
          ) : (
            <EditUserAccount query={query} profile={profile} />
          )}
        </div>
      );
    } else {
      return <div>Loading...</div>;
    }
  }
}

export default UserAccount;
