const initState = {
    infos: []
}

const infoReducer = (state = initState, action ) => {

    switch (action.type) {
        case 'ADDED_HELPINFO':
            return {
                ...state,
                addHelpSuccess: {
                    message: action.message,
                    type: 'addHelpSuccess'
                }
            }
        case 'ADDED_HELPINFO_ERROR':
            return {
                ...state,
                addHelpError: {
                    message: action.err.message,
                    type: 'addHelpError'
                }
            }

        case 'UPDATED_HELPINFO':
            return {
                ...state,
                updateHelpSuccess: {
                    message: action.message,
                    type: 'updateHelpSuccess'
                }
            }
        case 'UPDATED_HELPINFO_ERROR':
            return {
                ...state,
                updateHelpError: {
                    message: action.err.message,
                    type: 'updateHelpError'
                }
            }

        case 'DELETED_HELPINFO':
            return {
                ...state,
                deleteHelpSuccess: {
                    message: action.message,
                    type: 'deleteHelpSuccess'
                }
            }
        case 'DELETED_HELPINFO_ERROR':
            return {
                ...state,
                deleteHelpError: {
                    message: action.err.message,
                    type: 'deleteHelpError'
                }
            }


        case 'ADDED_HALALINFO':
            return {
                ...state,
                addHalalSuccess: {
                    message: action.message,
                    type: 'addHalalSuccess'
                }
            }
        case 'ADDED_HALALINFO_ERROR':
            return {
                ...state,
                addHalalError: {
                    message: action.err.message,
                    type: 'addHalalError'
                }
            }

        case 'UPDATED_HALALINFO':
            return {
                ...state,
                updateHalalSuccess: {
                    message: action.message,
                    type: 'updateHalalSuccess'
                }
            }
        case 'UPDATED_HALALINFO_ERROR':
            return {
                ...state,
                updateHalalError: {
                    message: action.err.message,
                    type: 'updateHalalError'
                }
            }

        case 'DELETED_HALALINFO':
            return {
                ...state,
                deleteHalalSuccess: {
                    message: action.message,
                    type: 'deleteHalalSuccess'
                }
            }
        case 'DELETED_HALALINFO_ERROR':
            return {
                ...state,
                deleteHalalSuccess: {
                    message: action.err.message,
                    type: 'deleteHalalSuccess'
                }
            }


        case 'ADDED_TERM':
            return {
                ...state,
                addTermSuccess: {
                    message: action.message,
                    type: 'addTermSuccess'
                }
            }
        case 'ADDED_TERM_ERROR':
            return {
                ...state,
                addTermError: {
                    message: action.err.message,
                    type: 'addTermError'
                }
            }

        case 'UPDATED_TERM':
            return {
                ...state,
                updateTermSuccess: {
                    message: action.message,
                    type: 'updateTermSuccess'
                }
            }
        case 'UPDATED_TERM_ERROR':
            return {
                ...state,
                updateTermError: {
                    message: action.err.message,
                    type: 'updateTermError'
                }
            }

        case 'DELETED_TERM':
            return {
                ...state,
                deleteTermSuccess: {
                    message: action.message,
                    type: 'deleteTermSuccess'
                }
            }
        case 'DELETED_TERM_ERROR':
            return {
                ...state,
                deleteTermError: {
                    message: action.err.message,
                    type: 'deleteTermError'
                }
            }


        case 'ADDED_PRIVACY':
            return {
                ...state,
                addPrivacySuccess: {
                    message: action.message,
                    type: 'addPrivacySuccess'
                }
            }
        case 'ADDED_PRIVACY_ERROR':
            return {
                ...state,
                addPrivacyError: {
                    message: action.err.message,
                    type: 'addPrivacyError'
                }
            }

        case 'UPDATED_PRIVACY':
            return {
                ...state,
                updatePrivacySuccess: {
                    message: action.message,
                    type: 'updatePrivacySuccess'
                }
            }
        case 'UPDATED_PRIVACY_ERROR':
            return {
                ...state,
                updatePrivacyError: {
                    message: action.err.message,
                    type: 'updatePrivacyError'
                }
            }
        case 'DELETED_PRIVACY':
            return {
                ...state,
                deletePrivacySuccess: {
                    message: action.message,
                    type: 'deletePrivacySuccess'
                }
            }
        case 'DELETED_PRIVACY_ERROR':
            return {
                ...state,
                deletePrivacyError: {
                    message: action.err.message,
                    type: 'deletePrivacyError'
                }
            }



        case 'ADDED_HIW':
            return {
                ...state,
                addHiwSuccess: {
                    message: action.message,
                    type: 'addHiwSuccess'
                }
            }
        case 'ADDED_HIW_ERROR':
            return {
                ...state,
                addHiwError: {
                    message: action.err.message,
                    type: 'addHiwError'
                }
            }


        case 'UPDATED_HIW':
            return {
                ...state,
                updateHiwSuccess: {
                    message: action.message,
                    type: 'updateHiwSuccess'
                }
            }
        case 'UPDATED_HIW_ERROR':
            return {
                ...state,
                updateHiwError: {
                    message: action.err.message,
                    type: 'updateHiwError'
                }
            }

        case 'DELETED_HIW':
            return {
                ...state,
                deleteHiwSuccess: {
                    message: action.message,
                    type: 'deleteHiwSuccess'
                }
            }
        case 'DELETED_HIW_ERROR':
            return {
                ...state,
                deleteHiwError: {
                    message: action.err.message,
                    type: 'deleteHiwError'
                }
            }

        default:
            return state;
    }
}

export default infoReducer