import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import { addExam } from '../../../stores/actions/courseAction';

class Exam extends Component{
    
    constructor(props){
        super(props)
        this.state = {
            examName: '',
            examCode:'',
            selectedQuestions: [],
            searchExam_Question: '',
            selectedQuestionCode: '',
    
            isLoading: null,
            message: null,
            addedExamId: ''
        }
        this.baseState = this.state 
    }
    
    componentDidUpdate = (prevProps, prevState) => { 
        const { addExamError, addExamSuccess } = this.props;

        if(prevProps.addExamError !== addExamError) {
            this.setState({
                isLoading: false,
                message: {
                    msg: addExamError.message,
                    type: addExamError.type,
                }
            })
        }

        if(prevProps.addExamSuccess !== addExamSuccess) {
            this.setState({
                isLoading: false,
                message: {
                    msg: addExamSuccess.message,
                    type: addExamSuccess.type,
                },
                addedExamId: addExamSuccess.id
            }, () => {
                setTimeout(() => { 
                    this.props.hide();
                    document.getElementById("clickAddExam").click();
                    setTimeout(() => { 
                        this.setState(this.baseState)
                    }, 3000);
                }, 2000);
            })
        }
    }

    handleSelectedQuestion(id, code) {
        this.setState({
            selectedQuestions: [ 
                ...this.state.selectedQuestions, 
                id //get selected option ID
            ],
            selectedQuestionCode: code
        });
    }

    removeSelectedQuestion = (id) => {
        let removeSelectedQuestion = this.state.selectedQuestions.filter(selected => {
          return selected !== id
        })
    
        this.setState({
            selectedQuestions: removeSelectedQuestion
        })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    addExam = (e) => {

        var result = window.confirm("Confirm add?");

        if (result) {
            this.setState({
                isLoading: true
            })
            this.props.addExam(this.state);
        }
    }

    render(){

        const { questions } = this.props;
        const { message, isLoading } = this.state;

        let filteredQuestions = null;

        if(this.state.searchExam_Question.length > 0) {
            filteredQuestions = questions && questions.filter(question => {
                return question.questionCode.toLowerCase().includes(this.state.searchExam_Question.toLowerCase())
            })
        } else {
            filteredQuestions = questions;
        }
        
        return(

            <div>            
                <form className="text-left">
                    <div className="row">
                        <p className="col col-md-3">
                            <b>Code:</b>
                            <input type="text" id="examCode" onChange={this.handleChange} value={this.state.examCode} className="form-control" placeholder="Code"/>
                        </p>
                        <p className="col">
                            <b>Name:</b>
                            <input type="text" id="examName" onChange={this.handleChange} value={this.state.examName} className="form-control" placeholder="Name"/>
                        </p>
                    </div>

                    <div  className="mt-4 mb-5">
                        <b>Questions: </b>
                        <div type="text" className="border-black">
                            { questions && questions.map((question,i) => {
                                return (
                                    this.state.selectedQuestions && this.state.selectedQuestions.map((selected, i) => {
                                        if(question.id === selected) {
                                            return (
                                                <span key={i} className="text-white badge bg-green">
                                                    {question.questionCode} {' '}
                                                    <span onClick={this.removeSelectedQuestion.bind(this, selected)}>
                                                        <i className="fas fa-times"></i>
                                                    </span>
                                                </span>
                                            )
                                        } else return null
                                    })
                                )
                            })}
                        </div>
                    
                        <small>Choose question here:</small>

                        <br/>

                        <div className="row col-md-5 btn-group dropdown">
                            <button type="button" className="text-left btn border-black dropdown-toggle" data-toggle="dropdown">
                                { this.state.selectedQuestionCode ? this.state.selectedQuestionCode : 'Choose Question'}
                            </button>

                            <div className="dropdown-menu" >
                                <span className="dropdown-item" onClick={this.handleSelectedQuestion.bind(this, '', '')} disabled>Questions</span>
                                <span className="dropdown-item"><input placeholder="Search code..." className="form-control"  onChange={event => this.setState({searchExam_Question: event.target.value})}/></span>
                                { filteredQuestions && filteredQuestions.map((question,i) => {
                                    return (
                                        <span key={i} className="dropdown-item" onClick={this.handleSelectedQuestion.bind(this, question.id, question.questionCode)}>{question.questionCode}</span>
                                    )
                                })}
                            </div>
                        </div>
                    </div>

                    { isLoading === true ? 
                        <small>Loading...</small>
                    : 
                        message  ?
                            <small className={message.type === 'addExamError' ? "text-red" : message.type === 'addExamSuccess'  ? "text-green" : null }>
                                {message.msg}
                            </small>
                        : null
                    }

                    <div className="mt-5 row justify-content-end">
                        <button type="button"
                            className="btn m-2 btn-outline-black"
                            onClick={this.addExam.bind(this)}><i className="fas fa-plus-circle"/>{' '}add exam
                        </button>
                    </div>
                </form>

                <div hidden={true}>
                    <Link id="clickAddExam" to={`/systemmanager?topic=course&subtopic=exam&id=${this.state.addedExamId}&code=${this.state.examCode}`} />
                </div>
            </div>
        
        )
    }
}

const mapStateToProps = (state) => {
  return {
      addExamError: state.courses.addExamError,
      addExamSuccess: state.courses.addExamSuccess,
  }
}

const mapDispatchToProps = (dispatch) => {
    return {
      addExam: (exam) => dispatch(addExam(exam))
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Exam);