import React, { Component } from 'react';
import { connect } from 'react-redux'
import { addPrivacy } from '../../../stores/actions/infoAction';

class PrivacyPolicy extends Component{
    
    constructor(props){
        super(props)
        this.state = {
            infoType:'PRIVACY POLICY',
            infoName:'',
            infoContent:'',
    
            isLoading: null,
            message: null,
        }
        this.baseState = this.state 
    }
    
    componentDidUpdate = (prevProps, prevState) => { 
        const { addPrivacyError, addPrivacySuccess } = this.props;

        if(prevProps.addPrivacyError !== addPrivacyError) {
            this.setState({
                isLoading: false,
                message: {
                    msg: addPrivacyError.message,
                    type: addPrivacyError.type,
                }
            })
        }

        if(prevProps.addPrivacySuccess !== addPrivacySuccess) {
            this.setState({
                isLoading: false,
                message: {
                    msg: addPrivacySuccess.message,
                    type: addPrivacySuccess.type,
                }
            })
            setTimeout(() => { 
                this.setState(this.baseState)
                this.props.hide();
            }, 2000);
        }
    } 

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    addPrivacy = () => {
        var result = window.confirm("Confirm add?");

        if (result) {
            this.setState({
                isLoading: true
            })
            this.props.addPrivacy(this.state);
        }
    }


    render(){
        
        const { message, isLoading } = this.state;

        return(

        <div>

            <input type="text" id="infoName" onChange={this.handleChange.bind(this)} className="form-control" value={this.state.infoName} placeholder="Name"/>
        
            <textarea type="text" id="infoContent" onChange={this.handleChange.bind(this)} className="form-control" value={this.state.infoContent} placeholder="Content"/>
           
            { isLoading === true ? 
                <small>Loading...</small>
            : 
                message  ?
                    <small className={message.type === 'addPrivacyError' ? "text-red" : message.type === 'addPrivacySuccess'  ? "text-green" : null }>
                        {message.msg}
                    </small>
                : null
            }

            <div className="mt-5 row justify-content-end">
                <button className="btn m-2 btn-outline-black" onClick={this.addPrivacy.bind(this)}><i className="fas fa-plus-circle"/>{' '}add privacy policy</button>
            </div>
        </div>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        addPrivacyError: state.infos.addPrivacyError,
        addPrivacySuccess: state.infos.addPrivacySuccess,
    }
}
  
const mapDispatchToProps = (dispatch) => {
    return {
      addPrivacy: (privacy) => dispatch(addPrivacy(privacy))
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy);