import React, { Component } from 'react';
import { Link } from 'react-router-dom'

import HelpInfo from './HelpInfo';
import HalalInfo from './HalalInfo';
import PrivacyPolicy from './PrivacyPolicy';
import TermAndCondition from './TermAndCondition';
import HowItWork from './HowItWork';

import DisplayHelpInfo from './DisplayHelpInfo';
import DisplayHalalInfo from './DisplayHalalInfo';
import DisplayTermAndCondition from './DisplayTermAndCondition';
import DisplayPrivacyPolicy from './DisplayPrivacyPolicy';
import DisplayHowItWork from './DisplayHowItWork';

class InfoManager extends Component {

    state = {
        infoCode:'help',
        infoAddCode:'',

        hideAvailablity: false
    }

    selectedInfoType = (e) => {
        this.setState({
            infoAddCode:  e.target[e.target.selectedIndex].id,
        })
    }

    hideModalAdd = () => {//dia guna id lain sbb dia akan clash. sbb dia apply dlm handleChange
        document.getElementById("modal-add-infos").style.display = "none";
    }

    showModalAdd = () => {//dia guna id lain sbb dia akan clash. sbb dia apply dlm handleChange
        document.getElementById("modal-add-infos").style.display = "block";
    }

    hide = () => { //utk child page InfoManager
        document.getElementById("myModal").style.display = "none";
    }

    show = () => {//utk child page InfoManager
        document.getElementById("myModal").style.display = "block";
    }


    render(){

        const { infos, query } = this.props;
        const { infoAddCode } = this.state;
        
        let query_subtopic = query.get('subtopic');

        return(

        <div>

            <div id="modal-add-infos" className="mymodal">
                <div className="mymodal-content col-md-6">
                    <span className="close" onClick={this.hideModalAdd.bind(this)}>&times;</span>
                    
                    <select className="mb-5 col col-md-6 form-control" onChange={this.selectedInfoType.bind(this)}>
                        <option defaultValue>Choose Info Type</option>
                        <option id="help" >Help Info</option>
                        <option id="halal">Halal Info</option>
                        <option id="term">Term and Condition</option>
                        <option id="privacy">Policies</option>
                        <option id="hiw">How It Work</option>
                    </select>

                    { infoAddCode === 'help' ?
                        <b className="uppercase">help Info
                            <div className="title-border"></div>
                        </b>
                    : infoAddCode === 'halal' ?
                        <b className="uppercase">Halal Info
                            <div className="title-border"></div>
                        </b>
                    : infoAddCode === 'term' ?
                        <b className="uppercase">term and condition
                            <div className="title-border"></div>
                        </b>
                    : infoAddCode === 'privacy' ?
                        <b className="uppercase">policies
                            <div className="title-border"></div>
                        </b>
                    : infoAddCode === 'hiw' ?
                        <b className="uppercase">how it work
                            <div className="title-border"></div>
                        </b>
                    : null }

                    { infoAddCode === 'help' ?
                        <HelpInfo hide={this.hideModalAdd} />
                    : infoAddCode === 'halal' ?
                        <HalalInfo hide={this.hideModalAdd} />
                    : infoAddCode === 'term' ?
                        <TermAndCondition hide={this.hideModalAdd} />  
                    : infoAddCode === 'privacy' ?
                        <PrivacyPolicy  hide={this.hideModalAdd} />
                    : infoAddCode === 'hiw' ?
                        <HowItWork  hide={this.hideModalAdd} />
                    :
                        <p>Please choose info type first.</p>
                    }
                </div>
            </div>

            <h3 className="text-left">Info Manager
                <div className="title-border"></div>
            </h3>

            <button className="btn btn-outline-black" onClick={this.showModalAdd.bind(this)}>
                <i className="fas fa-plus-circle"/>{' '}Info
            </button>

            <div className="tab">
                <Link className={query_subtopic === 'help' ? "tablinks active" : "tablinks"} to="/systemmanager?topic=info&subtopic=help">Help Info</Link>
                <Link className={query_subtopic === 'halal' ? "tablinks active" : "tablinks"} to="/systemmanager?topic=info&subtopic=halal">Halal Info</Link>
                <Link className={query_subtopic === 'term' ? "tablinks active" : "tablinks"} to="/systemmanager?topic=info&subtopic=term">T&C</Link>
                <Link className={query_subtopic === 'privacy' ? "tablinks active" : "tablinks"} to="/systemmanager?topic=info&subtopic=privacy">Policies</Link>
                <Link className={query_subtopic === 'hiw' ? "tablinks active" : "tablinks"} to="/systemmanager?topic=info&subtopic=hiw">How It Work</Link>
            </div>

            <div className="tabcontent">
                <div style={{margin:"20px"}}>
                    { query_subtopic === 'help'? 
                        <DisplayHelpInfo hide={this.hide} show={this.show} infos={infos} />
                    : query_subtopic === 'halal'?
                        <DisplayHalalInfo hide={this.hide} show={this.show} infos={infos} />
                    : query_subtopic === 'term'?
                        <DisplayTermAndCondition hide={this.hide} show={this.show} infos={infos} />
                    : query_subtopic === 'privacy'?
                        <DisplayPrivacyPolicy hide={this.hide} show={this.show} infos={infos} />
                    : query_subtopic === 'hiw'?
                        <DisplayHowItWork hide={this.hide} show={this.show} infos={infos}  />
                    :
                        <span hidden={this.state.hideAvailablity}>Info details not available.</span>
                    }
                </div>
            </div>  

        </div>
        
        )
    }
}

  
export default InfoManager;
  