import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { addQuestion } from '../../../stores/actions/questionAction';

import DisplayQuestion from './DisplayQuestion'
import NewQuestion from './NewQuestion'

class QuestionManager extends Component{

    state = {
        selectedQuestionId: null,
        clickedQuestionId: null,

        searchQuestion:''
    }

    showModal = (id) => {
        this.setState({
            clickedQuestionId: id
        })
        document.getElementById("myModal").style.display = "block";
    }

    hideModal = () => {
        this.setState({
            clickedQuestionId: ""
        })

        document.getElementById("myModal").style.display = "none";
    }

    showModal_newQuestion = () => {
        document.getElementById("modal-new-question").style.display = "block";
    }

    hideModal_newQuestion = () => {
        document.getElementById("modal-new-question").style.display = "none";
    }

    clearFilter = () => {
        document.getElementById("clearFilterQuestion").click();
        this.setState({
            searchQuestion:''
        })
    }

    render(){

        const { query, questions, courses, modules } = this.props;

        let query_courseName = query.get('courseName');
        let query_courseId = query.get('courseId');
        let query_moduleName = query.get('moduleName');
        let query_moduleId = query.get('moduleId');

        let filteredQuestions = null;

        let courseList = courses && courses.map(course => {
            if(course.courseType === "Professional") {
                return (
                    <span key={course.id}
                        className={query_courseId ? query_courseId === course.id ? "bg-white dropdown-item" : 'dropdown-item' : 'dropdown-item'}>
                        <Link to={`/systemmanager?topic=question&courseId=${course.id}&courseName=${course.courseName}`}>
                            {course.courseName}
                        </Link>
                    </span>
                )
            } else return null
        })

        let moduleList = courses && courses.map(course => {
            if(course.id === query_courseId) {
                return(
                    course.selectedModules && course.selectedModules.map(selected => {
                        return (
                            modules && modules.map(module => {
                                if(selected === module.id) {
                                    return (
                                        <span key={module.id}
                                            className={query_moduleId ? query_moduleId === module.id ? "bg-white dropdown-item" : 'dropdown-item' : 'dropdown-item'}>
                                            <Link to={`/systemmanager?topic=question&courseId=${course.id}&courseName=${course.courseName}&moduleId=${module.id}&moduleName=${module.moduleName}`}>
                                                {module.moduleName}
                                            </Link>
                                        </span>
                                    )
                                } else return null
                            })
                        )
                    })
                )
            } else return null
        })

        let hasQuestionData = [], isLoading= true;

        if(this.state.searchQuestion.length > 0) { //filter user: search user by email
            isLoading = false;
            filteredQuestions = questions && questions.filter(question => {
                return question.questionCode.toLowerCase().includes(this.state.searchQuestion.toLowerCase())
            })
        } else {
            filteredQuestions = questions;
        }

        let questionList = filteredQuestions && filteredQuestions.map(question => { 
            if(query_courseId && !query_moduleId) {
                if(query_courseId === question.questionCourse) {
                    hasQuestionData.push(question)
                    isLoading = false;
                    return (
                        <span key={question.id} onClick={this.showModal.bind(this, question.id)} className="badge bg-green text-white">{question.questionCode}</span>
                    )
                } else {
                    isLoading = false;
                }
            } else if(query_courseId && query_moduleId) {
                if(query_courseId === question.questionCourse && query_moduleId === question.questionModule) {
                    hasQuestionData.push(question)
                    isLoading = false;
                    return (
                        <span key={question.id} onClick={this.showModal.bind(this, question.id)} className="badge bg-green text-white">{question.questionCode}</span>
                    )
                }  else {
                    isLoading = false;
                }
            } else if (!query_courseId && !query_moduleId) {
                hasQuestionData.push(question)
                isLoading = false;
                return (
                    <span key={question.id} onClick={this.showModal.bind(this, question.id)} className="badge bg-green text-white">{question.questionCode}</span>
                )
            } else {
                isLoading = false;
            }

            return null
        })

        return(

        <div className="question-manager">

            <div id="myModal" className="mymodal">
                <div className="mymodal-content col-md-8">
                    <span className="close" onClick={this.hideModal}>&times;</span>
                    <DisplayQuestion hideModal={this.hideModal} courses={courses} modules={modules} questionId={this.state.clickedQuestionId}/>
                </div>
            </div>
           
           <div id="modal-new-question" className="mymodal">
               <div className="mymodal-content col-md-8">
                   <span className="close" onClick={this.hideModal_newQuestion.bind(this)}>&times;</span>
                   <NewQuestion hideModal_newQuestion={this.hideModal_newQuestion} courses={courses} modules={modules} />
               </div>
           </div>

            <h3  className="text-left">Question Manager
                <div className="title-border"></div>
            </h3>

            <div className="row col-md-12">
                <button className="btn bg-transparent" onClick={this.showModal_newQuestion.bind(this)}>
                    <i className="fas fa-plus-circle"></i>
                </button>

                {/* choose course */}
                <div className="btn-group dropdown">
                    <button type="button" className="btn border-black dropdown-toggle" data-toggle="dropdown">
                        { query_courseName ? query_courseName  : 'Choose Course' }
                    </button>

                    <div className="dropdown-menu" >
                        <b style={{marginLeft:"20px"}}>COURSE</b>
                        {courseList}
                    </div>
                </div>

                {/* choose module */}
                { query_courseId ?
                    <div className="btn-group dropdown">
                        <button type="button" className="btn border-black dropdown-toggle" data-toggle="dropdown">
                            { query_moduleName ? query_moduleName  : 'Choose Module' }
                        </button>

                        <div className="dropdown-menu" >
                            <b style={{marginLeft:"20px"}}>MODULE</b>
                            {moduleList}
                        </div>
                    </div>
                : null }

                <input placeholder="Search code..." className="col col-md-2 form-control"  value={this.state.searchQuestion} onChange={event => this.setState({searchQuestion: event.target.value})}/>
                { query_courseId || query_moduleId || this.state.searchQuestion ?
                    <div className="middle">
                        <span className="badge bg-blue text-white" onClick={this.clearFilter.bind(this)}><i className="fas fa-times"></i>{'  '}Clear</span>
                    </div>
                : null }
            </div>
            
            <div className="page-bgstyle2 text-left">
                <b className="uppercase">inserted question
                    <div className="title-border"></div>
                </b>

                { isLoading === true ? 
                    <p>Please wait... Data loading...</p> 
                : 
                    hasQuestionData.length > 0 ? 
                        questionList 
                    : 
                        <p>No question available</p>
                }

            </div>
            
            <div hidden={true}>
                <Link id="clearFilterQuestion" to={`/systemmanager?topic=question`} />
            </div>
        </div>

        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
      addQuestion: (question) => dispatch(addQuestion(question)),
    }
}
  
export default connect(null, mapDispatchToProps)(QuestionManager);