import React, { Component } from 'react';
import { connect } from 'react-redux';
import { contactUs } from '../../../stores/actions/formAction';

class ContactUsForm extends Component {

    constructor(props){
        super(props)
        this.state = {
            isLoading: null,
            mesej: null,

            name: '',
            email: '',
            contactNo: '',
            message: '',
        }
        this.baseState = this.state 
    }

    sendEmail = () => {
        var result = window.confirm("Confirm send?");
    
        if (result) {
            this.setState({
                isLoading: true
            })

            this.props.contactUs(this.state);
        }
    }
    
    componentDidUpdate = (prevProps, prevState) => { 

        const { sendContactError, sendContactSuccess } = this.props;

        if(prevProps.sendContactError !== sendContactError) {
            this.setState({
                isLoading: false,
                mesej: {
                    msg: sendContactError.message,
                    type: sendContactError.type,
                }
            })

            setTimeout(() => { 
                this.setState({mesej: ""})
            }, 2000);
        }

        if(prevProps.sendContactSuccess !== sendContactSuccess) {
            this.setState({
                isLoading: false,
                mesej: {
                    msg: sendContactSuccess.message,
                    type: sendContactSuccess.type,
                }
            })

            setTimeout(() => { 
                this.setState(this.baseState)
            }, 4000);
        }
    } 

    render () {

        const { mesej, isLoading } = this.state;

        return(

            <div className="contact-us container col-md-12">

                <div className="column">
                    <i className="fas fa-phone"/>{' '}Contact 017-3301261 or 03-55234554 for further assistance
                </div>

                <div className="column">
                    <i className="fas fa-globe"/>{' '}Click <span className="badge"><a target="_blank" rel="noopener noreferrer" href="https://www.halal-academy.com/index.php/reach-us/">HERE</a></span>to reach us.
                </div>

                {/* <h1>OR</h1> */}
                
                <div className="column">
                    <div className="bg-white">
                        <input id="name" type="text" className="form-control" placeholder="Name" value={this.state.name} onChange={e => this.setState({name: e.target.value})}/>
                        <input id="email" type="email" className="form-control" placeholder="Email" value={this.state.email} onChange={e => this.setState({email: e.target.value})}/>
                        <input id="contactNo" type="text" className="form-control" placeholder="Phone number" value={this.state.contactNo} onChange={e => this.setState({contactNo: e.target.value})}/>
                        <textarea id="message" type="text" className="form-control" placeholder="Message..." value={this.state.message} onChange={e => this.setState({message: e.target.value})}/>
                        
                        <div className="mt-2 text-dark text-left">
                            { isLoading === true ? 
                                <small>Loading...</small>
                            : 
                                mesej  ?
                                    <small className={mesej.type === 'sendContactError' ? "text-red" : mesej.type === 'sendContactSuccess'  ? "text-green" : null }>
                                        {mesej.msg}
                                    </small>
                                : null
                            }
                        </div>
                        <div className="row col-md-12 justify-content-end mt-5 p-0">
                            <button onClick={this.sendEmail.bind(this)} className="btn all-btn"><i className="fas fa-envelope"/>{' '}Send message</button>
                        </div>
                    </div>
                </div>
            </div>
        
        )
    }   
}



const mapStateToProps = (state) => {
    return {
        sendContactError: state.forms.sendContactError,
        sendContactSuccess: state.forms.sendContactSuccess,
    }
  }

const mapDispatchToProps = (dispatch) => {
    return {
      contactUs: (data) => dispatch(contactUs(data)),
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(ContactUsForm);