const initState = {}

const mapReducer = (state = initState, action) => {
    switch(action.type) {
         case 'ADDED_USER_SUCCESS':
            return {
                ...state,
                addUserSuccess: {
                    message: action.message,
                    type: 'addUserSuccess'
                }
            }
        case 'ADDED_USER_ERROR':
            return {
                ...state,
                addUserError: {
                    message: action.err.message,
                    type: 'addUserError'
                }
            }

        case 'ADDED_COURSE_ENROLLMENT_SUCCESS':
            return {
                ...state,
                addCourseEnrollmentSuccess: {
                    message: action.message,
                    type: 'addCourseEnrollmentSuccess'
                }
            }
        case 'ADDED_COURSE_ENROLLMENT_ERROR':
            return {
                ...state,
                addCourseEnrollmentError: {
                    message: action.err.message,
                    type: 'addCourseEnrollmentError'
                }
            }

        case 'ADDED_MODULE_ENROLLMENT_SUCCESS':
            return {
                ...state,
                addModuleEnrollmentSuccess: {
                    message: action.message,
                    type: 'addModuleEnrollmentSuccess'
                }
            }
        case 'ADDED_MODULE_ENROLLMENT_ERROR':
            return {
                ...state,
                addModuleEnrollmentError: {
                    message: action.err.message,
                    type: 'addModuleEnrollmentError'
                }
            }
        
        case 'ADDED_QUIZ_ENROLLMENT_SUCCESS':
            return {
                ...state,
                addQuizEnrollmentSuccess: {
                    message: action.message,
                    type: 'addQuizEnrollmentSuccess'
                }
            }
        case 'ADDED_QUIZ_ENROLLMENT_ERROR':
            return {
                ...state,
                addQuizEnrollmentError: {
                    message: action.err.message,
                    type: 'addQuizEnrollmentError'
                }
            }
        
        case 'ADDED_EXAM_ENROLLMENT_SUCCESS':
            return {
                ...state,
                addExamEnrollmentSuccess: {
                    message: action.message,
                    type: 'addExamEnrollmentSuccess'
                }
            }
        case 'ADDED_EXAM_ENROLLMENT_ERROR':
            return {
                ...state,
                addExamEnrollmentError: {
                    message: action.err.message,
                    type: 'addExamEnrollmentError'
                }
            }

        case 'ADDED_QUESTION_SUCCESS':
            return {
                ...state,
                addQuestionSuccess: {
                    message: action.message,
                    type: 'addQuestionSuccess'
                }
            }
        case 'ADDED_QUESTION_ERROR':
            return {
                ...state,
                addQuestionError: {
                    message: action.err.message,
                    type: 'addQuestionError'
                }
            }

        default:
            return state
    }
}

export default mapReducer