import React, { Component } from "react";
import { Link } from "react-router-dom";

import NewEvents from "./NewEvents";

import DisplayEvents from "./DisplayEvents";

class EventsManager extends Component {
  state = {
    changeState: "",
    searchEvents: "",
  };

  showModal = (getData) => {
    this.setState({
      changeState: getData,
    });
    document.getElementById("modal-tab-events").style.display = "block";
  };

  hideModal = () => {
    document.getElementById("modal-tab-events").style.display = "none";
  };

  render() {
    const { query, events, eventsList, eventsAttendees } = this.props;

    // console.log(eventsList)

    let query_subtopic = query.get("subtopic");
    let query_id = query.get("id");
    let query_code = query.get("code");

    let filteredEvents = null;

    if (this.state.searchEvents.length > 0) {
      //filter user: search user by email
      filteredEvents =
        events &&
        events.filter((data) => {
          return (
            data.eventsCode
              .toLowerCase()
              .includes(this.state.searchEvents.toLowerCase()) ||
              data.eventsName
              .toLowerCase()
              .includes(this.state.searchEvents.toLowerCase())
          );
        });
    } else {
      filteredEvents = events;
    }

    return (
      <div className="events-manager">
        <div id="modal-tab-events" className="mymodal">
          <div className="mymodal-content col-md-8">
            <span className="close" onClick={this.hideModal}>
              &times;
            </span>

            <b className="uppercase middle">
              {" "}
              Add new{" "}
              {this.state.changeState === "btnEvents" ? (
                "event"
              ) : this.state.changeState === "btnReadMe" ? ( //READ ME OPTION
                <img
                  src="../image/flow_program.png"
                  alt="flexi"
                  width="100%"
                  className="text-center"
                />
              ) : null}
              <div className="title-border"></div>
            </b>

            {this.state.changeState === "btnEvents" ? (
              <NewEvents
                query={query}
                eventsList={eventsList}
                hide={this.hideModal}
              />
            ) : this.state.changeState === "btnReadMe" ? (
              <img
                src="../image/flow_program.png"
                alt="flexi"
                width="100%"
                className="text-center"
              />
            ) : null}
          </div>
        </div>

        <h3 className="text-left">
          Event Manager
          <div className="mb-0 title-border"></div>
        </h3>

        {/* <small className="text-red" style={{ textTransform: "unset" }}>
          <i className="fas fa-info-circle" /> Create new event data if
          creating custom courses.
        </small> */}

        <div className="mt-5 row col-md-12 justify-content-start ">
          <div className="row col-md-4">
            <div className="btn-group col dropdown">
              <button
                type="button"
                className="btn border-black dropdown-toggle"
                data-toggle="dropdown"
              >
                {query_subtopic
                  ? query_subtopic === "module"
                    ? query_code
                    : "Choose Event"
                  : "Choose Event"}
              </button>

              <div className="dropdown-menu">
                <b style={{ marginLeft: "20px" }}>Event</b>
                <span className="dropdown-item">
                  <input
                    placeholder="Search code..."
                    className="form-control"
                    onChange={(event) =>
                      this.setState({ searchEvents: event.target.value })
                    }
                  />
                </span>
                {filteredEvents &&
                  filteredEvents.map((data, i) => {
                    return (
                      <span
                        key={i}
                        className={
                          query_subtopic
                            ? query_code === data.eventsCode
                              ? "bg-white dropdown-item"
                              : "dropdown-item"
                            : "dropdown-item"
                        }
                      >
                        <Link
                          to={`/systemmanager?topic=events&subtopic=module&id=${data.id}&code=${data.eventsCode}`}
                        >
                          {data.eventsCode} - {data.eventsName}
                        </Link>
                      </span>
                    );
                  })}
              </div>

              <button
                className="btn middle dropdown-toggle-split"
                onClick={this.showModal.bind(this, "btnEvents")}
              >
                <i className="fas fa-plus-circle"></i>
              </button>
            </div>
          </div>
        </div>

        <div className="page-bgstyle2 text-left">
          {query_subtopic === "module" ? (
            <div className="col" hidden={this.state.hideModule}>
              <b className="uppercase">
                event
                <div className="title-border"></div>
              </b>
              <DisplayEvents
                query={query}
                events={events}
                eventsList={eventsList}
                selectedEventsId={query_id}
                eventsAttendees={eventsAttendees}
              />
            </div>
          ) : (
            <p>Event details not available.</p>
          )}
        </div>
      </div>
    );
  }
}

export default EventsManager;
