import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import Carousel, { consts } from 'react-elastic-carousel';

import { Link } from 'react-router-dom';

class CourseList extends Component{

    state = {
        attachLoaded: false,
        selectedCourseId:'',
    }
    
    handleAttachLoaded() {
        this.setState({ attachLoaded: true });
    }

    myArrow = ({ type, onClick, isEdge }) => {
        const pointer = type === consts.PREV ? <i className="fas fa-arrow-circle-left"></i> : <i className="fas fa-arrow-circle-right"></i>
        return (
            <span className="middle carousel-arrow"onClick={onClick} disabled={isEdge}>
                {pointer}
            </span>
        )
    }

    render(){

        const { auth, courses, programs, courseEnrollments, moduleEnrollments } = this.props;

        const program_id = this.props.match.params.program_id;

        let isLoading = true, professional = null, activeCourse = [];
        // let programName = '';

        const breakPoints = [
            { width: 1, itemsToShow: 1 },
            { width: 550, itemsToShow: 2, itemsToScroll: 2 },
            { width: 1200, itemsToShow: 3 },
            { width: 1400, itemsToShow: 4 }
        ];

        professional =  programs && programs.map((program,i) => {
            if(program_id === program.id) {
                // programName = program.programName;
                return (
                   courses && courses.map(course => {
                        return (
                            program.selectedCourses && program.selectedCourses.map(id => {
                                if(id === course.id && course.courseStatus === "Active") {

                                    let isEnrolledType = null, isEnrolledProfessional = false, isEnrolledFlexi = false, isDoneEnrolledFlexi = false;
                                    
                                    courseEnrollments && courseEnrollments.map(enrolled => {
                                        if(auth.uid === enrolled.userId && enrolled.courseId === id && enrolled.type === "Professional"){
                                            isEnrolledType = enrolled.type
                                            isEnrolledProfessional = true
                                        } else if(auth.uid === enrolled.userId && enrolled.courseId === id && enrolled.type === "Flexi"){
                                            isEnrolledType = enrolled.type
                                            isEnrolledFlexi = true
                                        }

                                        return null
                                    })

                                    let selectedModulesCount = course.selectedModules.length - 1;

                                    moduleEnrollments && moduleEnrollments.map(moduleE => {
                                        if(moduleE.type === "Flexi" && auth.uid === moduleE.userId && parseInt(moduleE.indexKey) === selectedModulesCount && moduleE.courseId === course.id && moduleE.courseId === id) {
                                            isDoneEnrolledFlexi = true
                                        }
                                        return null
                                    })

                                    isLoading = false;
                                    activeCourse.push(id);

                                    return (
                                        <div key={i}>
                                            <div className="text-left courses-card">
                                                {/* <div className="text-center carousel-img-holder"> */}
                                                    { course.courseAttach ?
                                                        <div className="middle" style={{width:"100%"}}>
                                                            { course.courseAttach.fileType === "image" ?
                                                                <img alt="" onLoad={this.handleAttachLoaded.bind(this)} width="100%" src={course.courseAttach.fileUrl} />
                                                            : course.courseAttach.fileType === "video" ?
                                                                <video width="100%" onLoad={this.handleAttachLoaded.bind(this)} src={course.courseAttach.fileUrl} type="video/mp4" controls />
                                                            : null }

                                                            { this.state.attachLoaded === false ?
                                                                <div className="loader"></div>
                                                            : null }
                                                        </div>
                                                    : 
                                                        <div className="middle" style={{width:"100%"}}>
                                                            <img alt="" className="text-center" width="100%" src="/image/default.png" /> 
                                                        </div>
                                                    }
                                                {/* </div> */}

                                                <div style={{padding:'20px'}}>
                                                    <h1>RM {course.coursePrice}.00</h1>
                                                    <h3>{course.courseName}</h3>
                                                    <div className="row justify-content-center"><div className="title-border"></div> </div>

                                                    <li><b>{course.enrollCount ? `${course.enrollCount} enrollments` : null}</b></li>
                                                    { course.installmentAvailable === "true" ? 
                                                            <li>Installment available 
                                                                <Link to={"/program/" + program_id + "/course/" + id + "/flexi"} className="badge bg-yellow">Details & Pricing</Link>
                                                            </li>
                                                    : null }
                                                    
                                                    { course.courseInfo && course.courseInfo.map((info,i) => {
                                                        return (
                                                            <div key={i} style={{margin:"0px"}} className="listing"> 
                                                                <div className="list-icon"><li/></div>
                                                                <label dangerouslySetInnerHTML={{__html: info}} style={{margin:"0px"}}></label>
                                                            </div>
                                                        )
                                                    })}
                                                    { !auth.uid ? 
                                                        <button className="btn btn-enroll-disable"><i className="fas fa-sign-in-alt"></i>{' '}Login to enroll</button> 
                                                    :
                                                        isEnrolledProfessional === true ?
                                                            <>
                                                                <button className="btn btn-enroll-disable mb-0"><i className="fas fa-check-circle"></i>{' '}Enrolled</button> 

                                                                { course.employerMode && course.employerMode === true ?
                                                                    <Link className="middle" to={"/confirm-purchase/new-emp/" + id}>
                                                                        <button className="pushable  col-md-12">
                                                                            <span className="shadow"></span>
                                                                            <span className="edge"></span>
                                                                            <span className="front">
                                                                                Enroll as Employer{' '}<i className="fas fa-shopping-cart"></i>
                                                                            </span>
                                                                        </button>
                                                                    </Link>
                                                                : null }
                                                            </>
                                                        : isEnrolledFlexi === true ?
                                                            isDoneEnrolledFlexi === true ?
                                                                <button className="btn btn-enroll-disable"><i className="fas fa-check-circle"></i>{' '}Enrolled</button> 
                                                            :
                                                                <Link className="middle" to={"/program/" + program_id + "/course/" + id + "/flexi"}>
                                                                    <button className="pushable  col-md-12">
                                                                        <span className="shadow"></span>
                                                                        <span className="edge"></span>
                                                                        <span className="front">
                                                                            Enroll Now - Flexi {' '}<i className="fas fa-shopping-cart"></i>
                                                                        </span>
                                                                    </button>
                                                                </Link>
                                                                // <Link to={"/program/" + program_id + "/course/" + id + "/flexi"} className="btn all-btn">Enroll Now - Flexi</Link>
                                                        : 
                                                            <>

                                                                { course.employerMode && course.employerMode === true ?
                                                                    <Link className="middle" to={"/confirm-purchase/new-emp/" + id}>
                                                                        <button className="pushable  col-md-12">
                                                                            <span className="shadow"></span>
                                                                            <span className="edge"></span>
                                                                            <span className="front">
                                                                                Enroll as Employer{' '}<i className="fas fa-shopping-cart"></i>
                                                                            </span>
                                                                        </button>
                                                                    </Link>
                                                                : <Link className="middle" to={"/confirm-purchase/new/" + id}>
                                                                <button className="pushable  col-md-12">
                                                                    <span className="shadow"></span>
                                                                    <span className="edge"></span>
                                                                    <span className="front">
                                                                        Enroll Now{' '}<i className="fas fa-shopping-cart"></i>
                                                                    </span>
                                                                </button>
                                                            </Link> }
                                                            </>

                                                            // <Link to={"/confirm-purchase/" + id} className="btn all-btn">Enroll Now</Link>
                                                    }
                                                    <br/>
                                                    <small><b>This course include:</b></small><br/>
                                                    { course.courseOverview && course.courseOverview.map((overview,i) => {
                                                        return (
                                                            <div key={i} className="listing"> 
                                                                <div className="list-icon"><li/></div>
                                                                <label dangerouslySetInnerHTML={{__html: overview}} style={{margin:"0px"}}></label>
                                                            </div>
                                                        )
                                                    })}
                                                </div>

                                                 <div className="bg-green programs-btn">
                                                        <Link to={`/program/${program_id}/course/${id}/detail?isEnrolledType=${isEnrolledType}&isEnrolledProf=${isEnrolledProfessional}&isDoneEnrolledFlexi=${isDoneEnrolledFlexi}`}>
                                                            Read More{' '}<i className="fas fa-arrow-circle-right"></i>
                                                        </Link>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    )
                                }
                                return null
                            })
                        )
                   })
                )
            }
            return null
        })

        return(

            <div className="courses container col-md-12 justify-content-center middle min-height">
                {/* <div className="product-header">{programName} - Courses</div> */}
               <div style={{margin:"5em 0em 5em 0em"}} className={ activeCourse.length < 2 ? 'col-md-6' : activeCourse.length < 3 ? 'col-md-8' : 'col-md-11' }>
                    <Carousel breakPoints={breakPoints} itemsToShow={3} renderArrow={this.myArrow}>
                        { isLoading === true ? <div className="loader"></div> : professional }
                    </Carousel>
               </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        courses: state.firestore.ordered.courses,
        programs: state.firestore.ordered.programs,
        courseEnrollments: state.firestore.ordered.courseEnrollments,
        moduleEnrollments: state.firestore.ordered.moduleEnrollments,
    }
}
  
export default compose(
connect(mapStateToProps),
firestoreConnect(props => [
    { collection: 'courses'},
    { collection: 'programs'},
    { collection: 'courseEnrollments', where: props.auth.uid ? ['userId', '==', props.auth.uid] : null},
    { collection: 'moduleEnrollments', where: props.auth.uid ? ['userId', '==', props.auth.uid] : null}
])
)(CourseList);