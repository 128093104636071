import React, { Component } from 'react';
import { Link } from 'react-router-dom'

import Topic from './Topic';
import Quiz from './Quiz';
import Module from './Module';
import Exam from './Exam';
import Course from './Course';
import Program from './Program';

import DisplayTopic from './DisplayTopic';
import DisplayQuiz from './DisplayQuiz';
import DisplayModule from './DisplayModule';
import DisplayExam from './DisplayExam';
import DisplayCourse from './DisplayCourse';
import DisplayProgram from './DisplayProgram';

class CourseManager extends Component {

    state = {
        changeState:'',

        searchTopic:'',
        searchQuiz: '',
        searchModule: '',
        searchExam: '',
        searchCourse: '',
        searchProgram:'',
    }

    showModal = (getData) => {
        this.setState({
            changeState: getData
        })
        document.getElementById("modal-tab-course").style.display = "block";
    }
    
    hideModal = () => {
        document.getElementById("modal-tab-course").style.display = "none";
    }
 
    render(){

        const { query, topics, quizzes, questions, modules, exams, programs, courses } = this.props;

        let query_subtopic = query.get('subtopic');
        let query_id = query.get('id');
        let query_code = query.get('code');

        let filteredTopic = null, filteredQuiz = null, filteredModule = null, filteredExam = null, filteredCourse = null, filteredProgram = null;

        if(this.state.searchTopic.length > 0) { //filter user: search user by email
            filteredTopic = topics && topics.filter(topic => {
                return topic.topicCode.toLowerCase().includes(this.state.searchTopic.toLowerCase()) || 
                topic.topicName.toLowerCase().includes(this.state.searchTopic.toLowerCase())
            })
        } else {
            filteredTopic = topics;
        }

        if(this.state.searchQuiz.length > 0) { //filter user: search user by email
            filteredQuiz = quizzes && quizzes.filter(quiz => {
                return quiz.quizCode.toLowerCase().includes(this.state.searchQuiz.toLowerCase())|| 
                quiz.quizName.toLowerCase().includes(this.state.searchQuiz.toLowerCase())
            })
        } else {
            filteredQuiz = quizzes;
        }

        if(this.state.searchModule.length > 0) { //filter user: search user by email
            filteredModule = modules && modules.filter(module => {
                return module.moduleCode.toLowerCase().includes(this.state.searchModule.toLowerCase()) ||
                module.moduleName.toLowerCase().includes(this.state.searchModule.toLowerCase())
            })
        } else {
            filteredModule = modules;
        }

        if(this.state.searchExam.length > 0) { //filter user: search user by email
            filteredExam = exams && exams.filter(exam => {
                return exam.examCode.toLowerCase().includes(this.state.searchExam.toLowerCase()) ||
                exam.examName.toLowerCase().includes(this.state.searchExam.toLowerCase())
            })
        } else {
            filteredExam = exams;
        }

        if(this.state.searchCourse.length > 0) { //filter user: search user by email
            filteredCourse = courses && courses.filter(course => {
                return course.courseCode.toLowerCase().includes(this.state.searchCourse.toLowerCase()) ||
                course.courseName.toLowerCase().includes(this.state.searchCourse.toLowerCase())
            })
        } else {
            filteredCourse = courses;
        }

        if(this.state.searchProgram.length > 0) { //filter user: search user by email
            filteredProgram = programs && programs.filter(program => {
                return program.programCode.toLowerCase().includes(this.state.searchProgram.toLowerCase()) ||
                program.programName.toLowerCase().includes(this.state.searchProgram.toLowerCase())
            })
        } else {
            filteredProgram = programs;
        }

        return(

        <div className="course-manager">

            <div id="modal-tab-course" className="mymodal">
                <div className="mymodal-content col-md-8">
                    <span className="close" onClick={this.hideModal}>&times;</span>

                    <b className="uppercase middle"> Add new {' '}
                        {this.state.changeState === 'btnTopic' ?
                            'topic'
                        :this.state.changeState === 'btnQuiz' ?
                            'quiz set' 
                        :this.state.changeState === 'btnModule' ?
                            'module'
                        :this.state.changeState === 'btnExam' ?
                            'exam set'
                        :this.state.changeState === 'btnCourse' ?
                            'course'
                        :this.state.changeState === 'btnProgram' ?
                            'program'
                        :this.state.changeState === 'btnReadMe' ? //READ ME OPTION
                            <img src="../image/flow_program.png" alt="flexi" width="100%" className="text-center"/>
                        : null  }
                        <div className="title-border"></div>
                    </b>

                    {this.state.changeState === 'btnTopic' ?
                        <Topic query={query} hide={this.hideModal} />
                    :this.state.changeState === 'btnQuiz' ?
                        <Quiz query={query} hide={this.hideModal} questions={questions} />  
                    :this.state.changeState === 'btnModule' ?
                        <Module query={query} hide={this.hideModal} quizzes={quizzes} topics={topics}/>
                    :this.state.changeState === 'btnExam' ?
                        <Exam query={query} hide={this.hideModal} questions={questions}/>
                    :this.state.changeState === 'btnCourse' ?
                        <Course query={query} courses={courses} hide={this.hideModal} modules={modules} exams={exams} />
                    :this.state.changeState === 'btnProgram' ?
                        <Program query={query} courses={courses} hide={this.hideModal} />
                    :this.state.changeState === 'btnReadMe' ?
                        <img src="../image/flow_program.png" alt="flexi" width="100%" className="text-center"/>
                    : null  }
                </div>
            </div>

            <h3 className="text-left">Course Manager
                <div className="mb-0 title-border"></div>
            </h3>
            
            <small className="text-red" style={{textTransform:"unset"}}><i className="fas fa-info-circle"/>{' '}Create new course data if creating custom courses.</small>

            <div className="mt-5 row col-md-12 justify-content-center ">

                <div className="row col-md-4">
                    <div className="btn-group col dropdown">
                        <button type="button" className="btn border-black dropdown-toggle" data-toggle="dropdown">
                            { query_subtopic ? query_subtopic === "topic" ? query_code : 'Choose Topic' : 'Choose Topic' }
                        </button>

                        <div className="dropdown-menu" >
                            <b style={{marginLeft:"20px"}}>TOPIC</b>
                            <span className="dropdown-item" ><input placeholder="Search code..." className="form-control"  onChange={event => this.setState({searchTopic: event.target.value})}/></span>
                            { filteredTopic && filteredTopic.map((topic,i) => {
                                return (
                                    <span key={i} 
                                        className={query_subtopic ? query_code === topic.topicCode ? "bg-white dropdown-item" : 'dropdown-item' : 'dropdown-item'}>
                                        <Link to={`/systemmanager?topic=course&subtopic=topic&id=${topic.id}&code=${topic.topicCode}`}>
                                            {topic.topicCode} - {topic.topicName}
                                        </Link>
                                    </span>
                                )
                            })}
                        </div>

                        <button 
                            className="btn middle dropdown-toggle-split"
                            onClick={this.showModal.bind(this, 'btnTopic')}>
                            <i className="fas fa-plus-circle"></i>
                        </button>
                    </div>
                </div>

                <div className="row col-md-4">
                    <div className="btn-group col dropdown">
                        <button type="button" className="btn border-black dropdown-toggle" data-toggle="dropdown">
                            { query_subtopic ? query_subtopic === "quiz" ? query_code : 'Choose Quiz' : 'Choose Quiz' }
                        </button>

                        <div className="dropdown-menu" >
                            <b style={{marginLeft:"20px"}}>QUIZ</b>
                            <span className="dropdown-item"><input placeholder="Search code..." className="form-control"  onChange={event => this.setState({searchQuiz: event.target.value})}/></span>
                            { filteredQuiz && filteredQuiz.map((quiz,i) => {
                                return (
                                    <span key={i} 
                                        className={query_subtopic ? query_code === quiz.quizCode ? "bg-white dropdown-item" : 'dropdown-item' : 'dropdown-item'}>
                                        <Link to={`/systemmanager?topic=course&subtopic=quiz&id=${quiz.id}&code=${quiz.quizCode}`}>
                                            {quiz.quizCode} - {quiz.quizName}
                                        </Link>
                                    </span>
                                )
                            })}
                        </div>

                        <button 
                            className="btn middle dropdown-toggle-split"
                            onClick={this.showModal.bind(this, 'btnQuiz')}>
                            <i className="fas fa-plus-circle"></i>
                        </button>
                    </div>
                </div>

                <div className="row col-md-4">
                    <div className="btn-group col dropdown">
                        <button type="button" className="btn border-black dropdown-toggle" data-toggle="dropdown">
                            { query_subtopic ? query_subtopic === "exam" ? query_code : 'Choose Exam' : 'Choose Exam' }
                        </button>

                        <div className="dropdown-menu" >
                            <b style={{marginLeft:"20px"}}>EXAM</b>
                            <span className="dropdown-item"><input placeholder="Search code..." className="form-control"  onChange={event => this.setState({searchExam: event.target.value})}/></span>
                            { filteredExam && filteredExam.map((exam,i) => {
                                return (
                                    <span key={i} 
                                        className={query_subtopic ? query_code === exam.examCode ? "bg-white dropdown-item" : 'dropdown-item' : 'dropdown-item'}>
                                        <Link to={`/systemmanager?topic=course&subtopic=exam&id=${exam.id}&code=${exam.examCode}`}>
                                            {exam.examCode} - {exam.examName}
                                        </Link>
                                    </span>
                                )
                            })}
                        </div>

                        <button 
                            className="btn middle dropdown-toggle-split"
                            onClick={this.showModal.bind(this, 'btnExam')}>
                            <i className="fas fa-plus-circle"></i>
                        </button>
                    </div>
                </div>

                <div className="row col-md-4">
                    <div className="btn-group col dropdown">
                        <button type="button" className="btn border-black dropdown-toggle" data-toggle="dropdown">
                            { query_subtopic ? query_subtopic === "module" ? query_code : 'Choose Module' : 'Choose Module' }
                        </button>

                        <div className="dropdown-menu" >
                            <b style={{marginLeft:"20px"}}>MODULE</b>
                            <span className="dropdown-item"><input placeholder="Search code..." className="form-control"  onChange={event => this.setState({searchModule: event.target.value})}/></span>
                            { filteredModule && filteredModule.map((module,i) => {
                                return (
                                    <span key={i} 
                                        className={query_subtopic ? query_code === module.moduleCode ? "bg-white dropdown-item" : 'dropdown-item' : 'dropdown-item'}>
                                        <Link to={`/systemmanager?topic=course&subtopic=module&id=${module.id}&code=${module.moduleCode}`}>
                                            {module.moduleCode} - {module.moduleName}
                                        </Link>
                                    </span>
                                )
                            })}
                        </div>

                        <button 
                            className="btn middle dropdown-toggle-split"
                            onClick={this.showModal.bind(this, 'btnModule')}>
                            <i className="fas fa-plus-circle"></i>
                        </button>
                    </div>
                </div>

                <div className="row col-md-4">
                    <div className="btn-group col dropdown">
                        <button type="button" className="btn border-black dropdown-toggle" data-toggle="dropdown">
                            { query_subtopic ? query_subtopic === "course" ? query_code : 'Choose Course' : 'Choose Course' }
                        </button>

                        <div className="dropdown-menu" >
                            <b style={{marginLeft:"20px"}}>COURSE</b>
                            <span className="dropdown-item"><input placeholder="Search code..." className="form-control"  onChange={event => this.setState({searchCourse: event.target.value})}/></span>
                            { filteredCourse && filteredCourse.map((course,i) => {
                                return (
                                    <span key={i} 
                                        className={query_subtopic ? query_code === course.courseCode ? "bg-white dropdown-item" : 'dropdown-item' : 'dropdown-item'}>
                                        <Link to={`/systemmanager?topic=course&subtopic=course&id=${course.id}&code=${course.courseCode}`}>
                                            {course.courseCode} - {course.courseName}
                                        </Link>
                                    </span>
                                )
                            })}
                        </div>

                        <button 
                            className="btn middle dropdown-toggle-split"
                            onClick={this.showModal.bind(this, 'btnCourse')}>
                            <i className="fas fa-plus-circle"></i>
                        </button>
                    </div>
                </div>

                <div className="row col-md-4">
                    <div className="btn-group col dropdown">
                        <button type="button" className="btn border-black dropdown-toggle" data-toggle="dropdown">
                            { query_subtopic ? query_subtopic === "program" ? query_code : 'Choose Program' : 'Choose Program' }
                        </button>

                        <div className="dropdown-menu" >
                            <b style={{marginLeft:"20px"}}>PROGRAM</b>
                            <span className="dropdown-item"><input placeholder="Search code..." className="form-control"  onChange={event => this.setState({searchProgram: event.target.value})}/></span>
                            { filteredProgram && filteredProgram.map((program,i) => {
                                return (
                                    <span key={i} 
                                        className={query_subtopic ? query_code === program.programCode ? "bg-white dropdown-item" : 'dropdown-item' : 'dropdown-item'}>
                                        <Link to={`/systemmanager?topic=course&subtopic=program&id=${program.id}&code=${program.programCode}`}>
                                            {program.programCode} - {program.programName}
                                        </Link>
                                    </span>
                                )
                            })}
                        </div>

                        <button 
                            className="btn middle dropdown-toggle-split"
                            onClick={this.showModal.bind(this, 'btnProgram')}>
                            <i className="fas fa-plus-circle"></i>
                        </button>
                    </div>
                </div>
            </div>
                    
            <div className="page-bgstyle2 text-left">
                { query_subtopic === "topic" ?

                    <div className="col">
                        <b className="uppercase">topic
                            <div className="title-border"></div>
                        </b>
                        <DisplayTopic query={query}  topics={topics} selectedTopicId={query_id} />
                    </div> 
                
                : query_subtopic === "quiz" ?

                    <div className="col">
                        <b className="uppercase">quiz
                            <div className="title-border"></div>
                        </b>
                        <DisplayQuiz query={query}  modules={modules} courses={courses} questions={questions} selectedQuizId={query_id} />
                    </div>
                
                : query_subtopic === "module" ?

                    <div className="col" hidden={this.state.hideModule}>
                        <b className="uppercase">module
                            <div className="title-border"></div>
                        </b>
                        <DisplayModule query={query}  modules={modules} quizzes={quizzes} topics={topics} selectedModuleId={query_id} />
                    </div>
                
                : query_subtopic === "exam" ?

                    <div className="col" hidden={this.state.hideExam}>
                        <b className="uppercase">exam
                            <div className="title-border"></div>
                        </b>
                        <DisplayExam query={query}  modules={modules} courses={courses} questions={questions} selectedExamId={query_id} />
                    </div>
                
                : query_subtopic === "course" ?

                    <div className="col" hidden={this.state.hideCourse}>
                        <b className="uppercase">course
                            <div className="title-border"></div>
                        </b>
                        <DisplayCourse query={query} modules={modules} exams={exams} courses={courses} selectedCourseId={query_id} />
                    </div>
                
                : query_subtopic === "program" ?

                    <div className="col" hidden={this.state.hideProgram}>
                        <b className="uppercase">program
                            <div className="title-border"></div>
                        </b>
                        <DisplayProgram query={query}  courses={courses} selectedProgramId={query_id} />
                    </div>
                
                : 
                    <p >Course details not available.</p> 
                }
            </div>
                
        </div>

        
        )
    }
        
}

  
export default (CourseManager);
  