import React, { Component } from 'react';
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'

import { PDFDownloadLink } from "@react-pdf/renderer";
import { EventEcertPdf } from '../../e-learning/ecert/EventEcertGenerator';
import { EcertPdf } from '../../e-learning/ecert/EcertGenerator';

class EventCertList extends Component {

    state = {
        searchUser: '',

        selectedCourseId: '',
        selectedCourseName: '',

        firstIndexData: 0,
        lastIndexData: 100
    }

    loadMore = () => {
        this.setState({
            lastIndexData: this.state.lastIndexData + 100
        })
    }

    selectedCourse = (e) => {
        this.setState({
            selectedCourseId: e.target[e.target.selectedIndex].id,
            selectedCourseName: e.target.value
        })
    }
    testingParam = (ea, u, ev) => {
        const usersData = {}, eventsData = {};

        let eventsAttendeesData = [];//dump semua data dari eventAttendees
        if (ea)
            for (const doc of ea) {
                let eventAttendee = doc;
                if (eventAttendee.userId) {

                    if (usersData[eventAttendee.userId]) {
                        eventAttendee.user = usersData[eventAttendee.userId];
                    } else {

                        let filteredUser = u.filter((selected) => {
                            return selected.id === eventAttendee.userId;
                        });

                        eventAttendee.user = filteredUser[0];

                        usersData[eventAttendee.userId] = filteredUser[0];
                    }
                }

                if (eventAttendee.eventsId) {

                    if (eventsData[eventAttendee.eventsId]) {
                        eventAttendee.events = eventsData[eventAttendee.eventsId];
                    } else {

                        let filteredEvent = ev.filter((selected) => {
                            return selected.id === eventAttendee.eventsId;
                        });

                        eventAttendee.events = filteredEvent[0];

                        eventsData[eventAttendee.eventsId] = filteredEvent[0];
                    }
                }
                eventsAttendeesData.push(eventAttendee);
            }

        return eventsAttendeesData;
    };

    render() {

        const { users, eventsAttendees, events, profile } = this.props;
        
        let testParam = null;
        //console.log(events,eventsAttendees)
        let no = 0;
        let filteredAttendee = [], filteredEnrollments = [];


        eventsAttendees && eventsAttendees.map(attendee => {
            if (attendee.userId === profile.userId) {
                filteredAttendee.push(attendee)
            } else
                return null
        })
        // console.log(filteredAttendee)

        testParam = this.testingParam(filteredAttendee, users, events);


        let hasData = [], isLoadingData = true;

        let displayNotListed =
            testParam &&
            testParam.map((data, i) => {
                
                isLoadingData = false;
                hasData.push(data);
                no = no + 1;
                    return (
                        <tr key={i}>
                            <th scope="row">{no}.</th>
                            <td className="text-left"><div>{data.events.eventsName}</div><div>{data.events.eventsTopic}</div></td>
                            <td className="text-left">{data.events.eventsDate}</td>
                            <td>
                            { data.eventsAttendeesStatus === 'Approved'? 
                                <span>{ profile.fullname?
                                    <PDFDownloadLink key={i} document={<EventEcertPdf courseId={data.events.id} doneDate={data.events.eventsDate} profile={profile} eventsTopic={data.events.eventsTopic} courseName={data.events.eventsName}/>} fileName={`${profile.firstname + ' - ' + data.events.eventsName}.pdf`}>
                                            {({ blob, url, loading, error }) => (
                                                <button 
                                                    className={loading ? "btn btn-disable" :  "btn all-btn" }>
                                                    { loading ? 'Loading E-Cert...' : <span> <i className="fas fa-download"/>{' '}E-Cert</span> }
                                                </button> 
                                            )}
                                        </PDFDownloadLink>
                                        : <small className="text-red">PLEASE UPDATE YOUR FULLNAME</small>
                                }</span> 
                            : data.eventsAttendeesStatus === "Rejected" ?
                                <span className='text-danger'>{data.eventsAttendeesStatus }</span>
                            : <span className='text-primary'>{data.eventsAttendeesStatus }</span>
                            
                            }
                        </td>
                        </tr>
                    )
                
            });

        return (

            <div className="emp-list">
                <div>
                    <table className="mt-5 table">
                        <thead>
                            <tr>
                                <th scope="col">No.</th>
                                <th className="text-left" scope="col">Event Name</th>
                                <th scope="col">Event Date</th>
                                <th scope="col">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {displayNotListed}
                        </tbody>
                    </table>
                </div>


                {filteredEnrollments.length > 0 ?
                    this.state.lastIndexData >= filteredEnrollments.length ?
                        <button className="btn btn-disable" onClick={this.loadMore.bind(this)}>Load More</button>
                        :
                        <button className="btn all-btn" onClick={this.loadMore.bind(this)}>Load More</button>
                    : null}

            </div>

        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.firebase.auth,
        courseEnrollments: state.firestore.ordered.courseEnrollments,
        examEnrollments: state.firestore.ordered.examEnrollments,
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect(props => [
        { collection: 'courseEnrollments', where: ['employerId', '==', props.auth.uid] },
        { collection: 'examEnrollments' },
    ])
)(EventCertList);