import React, { Component } from 'react';
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import moment from 'moment'
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom'
import Carousel, { consts } from 'react-elastic-carousel';

import { updateEnrolledExam_renewSuspend } from '../../stores/actions/enrollmentAction';
import { submitFormReqClass } from '../../stores/actions/classAction';

import { PDFDownloadLink  } from "@react-pdf/renderer";
import { EcertPdf } from "./ecert/EcertGenerator";

import RequestClass from './RequestClass';

import { HalalInsightEcertPdf } from './ecert/HalalInsightEcertGenerator';

class EnrollmentPage extends Component {

    state = {
        req_date: '',
        req_type:'',
        req_selectedBatchId: '',

        modalType:'',
        modal_programId:'',
        modal_courseId: '',
        modal_userId: '',

        isPaymentComplete: false,
        hasEnrolledModuleId: [],
        notEnrolledModuleId: [],
        kosId:"",

        zoomImgSrc: null,
    }

    myArrow = ({ type, onClick, isEdge }) => {
        const pointer = type === consts.PREV ? <i className="fas fa-arrow-circle-left"></i> : <i className="fas fa-arrow-circle-right"></i>
        return (
            <span className="middle carousel-arrow"onClick={onClick} disabled={isEdge}>
                {pointer}
            </span>
        )
    }

    getCourseInfo = async (courseId) => {

        const { auth, courses, moduleEnrollments } = this.props;

        let isPaymentComplete = false, getSelectedModules = [], hasEnrolledModuleId = [], notEnrolledModuleId = [];

        courses && courses.map(course => {
            if(course.id === courseId) {
                return (
                    course.selectedModules && course.selectedModules.map(selected => {
                        return getSelectedModules.push(selected)
                    })
                )
            }
            return null
        })

        moduleEnrollments && moduleEnrollments.map(moduled => {
            if(courseId === moduled.courseId && auth.uid === moduled.userId) { //get user module enrolled
                hasEnrolledModuleId.push(moduled.moduleId)
            }
            return null
        })

        notEnrolledModuleId = getSelectedModules.filter(val => !hasEnrolledModuleId.includes(val));

        if(getSelectedModules.length === hasEnrolledModuleId.length) {
            isPaymentComplete = true
        }

        this.setState({
            kosId: courseId,
            hasEnrolledModuleId,
            notEnrolledModuleId,
            isPaymentComplete
        })
    }

    showModal = async (type, programId, courseId, userId, req_type, selectedBatchId) => {
        if(type === "info") {
            await this.getCourseInfo(courseId)
        }

        document.getElementById("modalEnrollmentPage").style.display = "block";

        let dateToday = moment().format('YYYY-MM-DD');

        let req_selectedBatchId = "";

        if(req_type === "change") {
            req_selectedBatchId = selectedBatchId
        }

        this.setState({
            modalType: type,
            modal_programId: programId,
            modal_courseId: courseId,
            modal_userId: userId,
            req_date: dateToday,
            req_selectedBatchId
        })
    }

    hideModal = () => {
        document.getElementById("modalEnrollmentPage").style.display = "none";
    }

    zoomAds = (type, src) => {
        if(type === "open") {
            document.getElementById("modal-ads").style.display = "block";

            this.setState({
                zoomImgSrc: src
            })
        } else if(type === "close") {
            document.getElementById("modal-ads").style.display = "none";
        }
    }


    render(){

        const { auth, profile, extraClassInfo, transcripts, ads, courses, modules, courseEnrollments, quizEnrollments, examEnrollments, programs, physicalClassParticipants, physicalClassBatches } = this.props;
        const { modal_courseId, modal_programId, isPaymentComplete, hasEnrolledModuleId, notEnrolledModuleId } = this.state;

        if (!auth.uid) return <Redirect to="/" />

        let enrollment = null, enrolledLength = [], isEnrolled = null, isLoading = true, isPassed = false;

        enrollment = courses && courses.map(course => {
            return (
                examEnrollments && examEnrollments.map(exam => {
                    if (auth.uid === exam.userId && course.id === exam.courseId) {

                        enrolledLength.push(exam)
                       
                        let suspendExpired = exam.examAvailableDate;

                        let suspendExpiredSame = moment(new Date()).isSame(new Date(suspendExpired));
                        let suspendExpiredAfter = moment(new Date()).isAfter(new Date(suspendExpired));

                        if(exam.examAvailableDate !== "") { //finish suspend
                            if (suspendExpiredSame === true || suspendExpiredAfter === true) {
                                this.props.updateEnrolledExam_renewSuspend(exam.id)
                            }
                        }

                        return (
                            courseEnrollments && courseEnrollments.map((enrolled,i) => {

                                if (course.id === enrolled.courseId && enrolled.courseId === exam.courseId && enrolled.userId === exam.userId) {

                                    let enrollExpired = enrolled.enrollExpired;
                                    
                                    let enrollExpiredSame = moment(new Date()).isSame(new Date(enrollExpired));
                                    let enrollExpiredAfter = moment(new Date()).isAfter(new Date(enrollExpired));

                                    let programName = '', programId = '';

                                    // let classStatus = "";

                                    programs && programs.map(program => {
                                        return (
                                            program.selectedCourses && program.selectedCourses.map(cs => {
                                                if(cs === enrolled.courseId) {
                                                    programName = program.programName
                                                    programId = program.id
                                                }
                                                return null
                                            })
                                        )
                                    })

                                    let statusOnline = 
                                    enrolled.isOnGoing === true && exam.isAvailable === false ?
                                        <span className="badge bg-yellow">On Going</span>
                                    : enrolled.isOnGoing === true && exam.isAvailable === true ?
                                        exam.examAttend === true ?
                                            exam.examScore >= 70 ?
                                                <span className="text-white badge bg-green">Done Exam - {exam.examScore}%</span>
                                            :
                                                <span className="text-white badge bg-red">Exam Failed - {exam.examScore}%</span>
                                            :
                                            <span className="badge bg-yellow">On Going</span>
                                            : ''

                                    let statusPractical = physicalClassParticipants && physicalClassParticipants.map((part,i) => {
                                        if(part.userId === auth.uid && part.userId === enrolled.userId && part.courseId === enrolled.courseId) {
                                            // classStatus = part.status;
                                            if(part.status === "listed") {
                                                return <span key={i} className="text-white badge bg-blue">Listed</span>
                                            } else if(part.status === "active") {
                                                return <span key={i} className="badge bg-yellow">Active</span>
                                            }else if(part.status === "submitted") {
                                                return <span key={i} className="text-white badge bg-green">Submitted</span>
                                            } else if(part.status === "completed") {
                                                return <span key={i} className="text-white badge bg-black">Completed</span>
                                            } else if(part.status === "requested") {
                                                return <span key={i} className="text-white badge bg-purple">Requested</span>
                                            } else return 'Not available'
                                        } else return null
                                    })

                                    if(course.id === enrolled.courseId && auth.uid === enrolled.userId) {

                                        let haveSubmitClass = [];

                                        physicalClassParticipants && physicalClassParticipants.map(part => {
                                            if(part.userId === enrolled.userId && part.courseId === enrolled.courseId) {
                                                haveSubmitClass.push(part)
                                            }
                                            return null
                                        })

                                        
                                        let btnRequestClass = haveSubmitClass && haveSubmitClass.map((submitted,i) => {
                                            if(submitted.userId === enrolled.userId) {
                                                if(submitted.selectedBatchId !== "") {
                                                    let hasData = [];
                                                    return (
                                                        <div className="mt-2" key={i}>

                                                            { submitted.status === "requested" ?
                                                                <div>
                                                                    <label><i className="fas fa-clock"></i>{'  '}Your <b>requested</b> practical session dates:</label> {' '}
                                                                    <span className="badge bg-green-dark text-white"  onClick={this.showModal.bind(this, 'class', programId, enrolled.courseId, enrolled.userId, 'change', submitted.selectedBatchId)}><i className="fas fa-sync-alt"></i>{' '}Change date</span>
                                                                </div>
                                                            :
                                                                <label><i className="fas fa-check-circle"></i>{'  '}Your <b>confirmed</b> practical session dates:</label>
                                                            }

                                                            { physicalClassBatches && physicalClassBatches.map(batch => {
                                                                return (
                                                                    batch.selectedParticipants && batch.selectedParticipants.map((part,i) => {
                                                                        if(part.userId === auth.uid && part.userId === enrolled.userId && batch.courseId === enrolled.courseId) {
                                                                            hasData.push(part.userId);
                                                                            return (
                                                                                <ul key={i}>
                                                                                    <li>{batch.date.day1}</li>
                                                                                    <li>{batch.date.day2}</li>
                                                                                    <li>{batch.date.day3}</li>
                                                                                    <li>{batch.date.day4}</li>
                                                                                </ul>
                                                                            )
                                                                        } return null
                                                                    })
                                                                )
                                                            })}

                                                            { hasData.length <= 0 ?
                                                                <p className="mt-2 text-red"><i className="fas fa-exclamation-triangle"></i>{' '}There is an issue with your recent practical session request. Please contact 017-3301261.</p>
                                                            : null }

                                                            { extraClassInfo && extraClassInfo.map(info => {
                                                                if(info.batchId === submitted.selectedBatchId && submitted.status !== "requested") {
                                                                    return (
                                                                        <div>
                                                                            <label><i className="fas fa-info-circle"></i>{' '}<b>Practical session details:</b></label>
                                                                            <div className='ml-4'>
                                                                                <p>1. Location link : <a href="loc_link" target="_blank" className='badge bg-green text-white'>Click here</a></p>
                                                                                2. Location detail :
                                                                                <ul>
                                                                                        { info.loc_detail && info.loc_detail.map(dt => {
                                                                                            return (
                                                                                                <li>{dt}</li>
                                                                                            )
                                                                                        })}
                                                                                </ul>
                                                                                3. Extra info :
                                                                                <ul>
                                                                                        { info.extra_info && info.extra_info.map(ext => {
                                                                                            return (
                                                                                                <li>{ext}</li>
                                                                                            )
                                                                                        })}
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                } return null
                                                            })}

                                                        </div>
                                                    )
                                                } else {
                                                    return (
                                                        <p key={i} className="mt-2 text-red"><i className="fas fa-exclamation-triangle"></i>{' '}There is an issue with your recent practical session request. Please contact 017-3301261.</p>
                                                    )
                                                }
                                            }
                                            return null
                                        })

                                        isLoading = false;
                                        
                                        return (  
                                            <div key={i} className="enroll-card mb-5 ">
                                                <div className="enroll-title">
                                                    <small>{programName}</small><br/>
                                                    <b>{course.courseName}
                                                        {enrolled.customLabel ? 
                                                            <small style={{fontSize:"10px"}} className="uppercase ml-2 text-darkgrey">{enrolled.customLabel}</small>
                                                        : null }
                                                    </b>
                                                    <Link title="How it works?" className="ml-2 float-right" to="/howitworks" target="_blank" rel="noopener noreferrer"><i style={{fontSize:"25px"}} className="text-green fas fa-question-circle"/></Link>
                                                    <span  onClick={this.showModal.bind(this, 'info', programId, enrolled.courseId, enrolled.userId, null, null)} title="Course progress" className="float-right">
                                                        <i style={{fontSize:"25px"}} className="text-green fas fa-info-circle"/>
                                                    </span> {' '}
                                                </div>
                                                <div className="col-md-12 enroll-body">
                                                    { enrollExpiredSame === false && enrollExpiredAfter === false ?
                                                        exam.examAvailableDate === "" || suspendExpiredSame === true || suspendExpiredAfter === true ?
                                                            <div>
                                                                { exam.isAvailable === true && exam.isDone === true ?
                                                                    <label className="text-green"><i className="fas fa-check-circle"></i>{'  '}Congratulations! You have successfully completed this online course/session.</label>
                                                                :
                                                                    <label><i className="fas fa-info-circle"></i>{'  '}Please note that this course will expire on {moment(enrollExpired).format('DD-MM-YYYY')}.</label>
                                                                }
                                                                <br/>
                                                                { course.hasPhysicalClass === "true" ?
                                                                    haveSubmitClass.length < 1 && exam.isDone === true ? //cek dah pernah submit class/belum
                                                                        <span className="request-btn" onClick={this.showModal.bind(this, 'class', programId, enrolled.courseId, enrolled.userId, 'new', null)}>
                                                                            <i className="fas fa-pencil-alt"></i>{' '}Request practical session
                                                                        </span>
                                                                    :
                                                                        <div>
                                                                            {btnRequestClass}
                                                                        </div>
                                                                : null }

                                                                <br/>

                                                                <div className="text-right">
                                                                
                                                                    { transcripts && transcripts.map((ts,i) => {
                                                                        if(auth.uid === ts.userId && ts.courseId === course.id && ts.status === "publish") {
                                                                            return (
                                                                                <Link key={i} target="_blank" rel='noopener noreferrer' title="Open transcript" className='btn all-btn' to={`/transcript/${ts.userId}/${ts.batchId}/${ts.id}`}><i className="fas fa-download"/>{' '}Transcript</Link>
                                                                            )
                                                                        } return null
                                                                    })}

                                                                    { course.exam.hasExam === "true" && exam.isDone === true && course.hasEcert === "true" ?
                                                                        profile.isLoaded === true && (profile.identityCardNo === "" || profile.firstname === "" || profile.lastname === "" || !profile.fullname) ? 
                                                                            <span style={{textTransform:"none"}} className="btn btn-disable"><i className="fas fa-download"/>{' '}Update profile to download E-Cert</span>
                                                                        : profile.isLoaded === true && profile.identityCardNo !== "" ? 
                                                                            <PDFDownloadLink document={<EcertPdf courseId={course.id} doneDate={exam.isDoneDate} profile={profile} courseName={course.courseName}/>} fileName={`${profile.firstname + ' - ' + course.courseName}.pdf`}>
                                                                                {({ blob, url, loading, error }) => (
                                                                                    <button 
                                                                                        className={loading ? "btn btn-disable" :  "btn all-btn" }>
                                                                                        { loading ? 'Loading E-Cert...' : <span> <i className="fas fa-download"/>{' '}E-Cert</span> }
                                                                                    </button> 
                                                                                )}
                                                                            </PDFDownloadLink>
                                                                        : 
                                                                            null
                                                                    : null }

                                                                    { course.courseName.toLowerCase().includes('halal insightx') && course.exam.hasExam === "false" && enrolled.isDoneExam === true && course.hasEcert === "true" ?
                                                                        profile.isLoaded === true && (profile.identityCardNo === "" || profile.firstname === "" || profile.lastname === "" || !profile.fullname) ? 
                                                                            <span style={{textTransform:"none"}} className="btn btn-disable"><i className="fas fa-download"/>{' '}Update profile to download E-Cert</span>
                                                                        : profile.isLoaded === true && profile.identityCardNo !== "" ? 
                                                                            <PDFDownloadLink document={<HalalInsightEcertPdf courseId={course.id} doneDate={exam.isDoneDate} profile={profile} courseName={course.courseName}/>} fileName={`${profile.firstname + ' - ' + course.courseName}.pdf`}>
                                                                                {({ blob, url, loading, error }) => (
                                                                                    <button 
                                                                                        className={loading ? "btn btn-disable" :  "btn all-btn" }>
                                                                                        { loading ? 'Loading E-Cert...' : <span> <i className="fas fa-download"/>{' '}E-Cert</span> }
                                                                                    </button> 
                                                                                )}
                                                                            </PDFDownloadLink>
                                                                        : 
                                                                            null
                                                                    : null }

                                                                    { course.exam.hasExam === "true" ?
                                                                        (exam.isAvailable === true && exam.isDone === false) || exam.courseId === "CI96HblSSUjozwiBWmqo" ?
                                                                            <Link to={"/enrollment/exam/" + exam.id}>
                                                                                <button className="btn all-btn">exam</button>
                                                                            </Link>
                                                                        : 
                                                                            <button className="btn btn-disable ">exam</button>
                                                                    : null }

                                                                    <Link to={"/enrollment/module/" + enrolled.courseId}>
                                                                        <button className="btn all-btn">Start here {' '}<i className="fas fa-arrow-right"></i></button>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        :
                                                            <div>
                                                                <label className="text-red"><i className="fas fa-info-circle "></i>{'  '}Oops! You have lost {course.exam.examSuspendNumber} chances. Exam will be available again on {exam.examAvailableDate}</label>
                                                                <br/>
                                                                { course.hasPhysicalClass === "true" ? //cek dah pernah submit class/belum
                                                                    haveSubmitClass.length < 1 && exam.isDone === true ?
                                                                        <span className="request-btn" onClick={this.showModal.bind(this, 'class', programId, enrolled.courseId, enrolled.userId, 'new', null)}>
                                                                            <i className="fas fa-pencil-alt"/>{' '}Request practical session
                                                                        </span>
                                                                    :
                                                                        <div>
                                                                            {btnRequestClass}
                                                                        </div>
                                                                : null }  

                                                                <br/>
                                                                <div className="text-right">
                                                                    <button className="btn btn-disable">exam</button>

                                                                    <Link to={"/enrollment/module/" + enrolled.courseId}>
                                                                        <button className="btn all-btn">Start here {' '}<i className="fas fa-arrow-right"></i></button>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                    :
                                                        <div>
                                                            <p className="text-red">
                                                                <i className="fas fa-info-circle "></i>{'  '}Your access to this course has expired.
                                                            
                                                                { course.coursePrice > 0 ?
                                                                    enrolled.extendCount && enrolled.extendCount > 0 ? 
                                                                       <div style={{marginLeft:'1vh'}} className='container'>
                                                                            <small className='text-dark'>
                                                                                You can only extend the course enrollment once. Additional extensions are not permitted.
                                                                                Please contact 03-5523 4554 to repurchase.
                                                                            </small> 
                                                                        </div>
                                                                    :
                                                                        <Link style={{textTransform:"unset"}} to={"/confirm-purchase/renew/" + enrolled.courseId} className="btn all-btn bg-red"><i className="fas fa-sync"/>{' '}Renew course</Link>
                                                                : 
                                                                    <button style={{textTransform:"unset"}} className="btn btn-disable"><i className="fas fa-sync"/>{' '}Course renewal are not available for this course.</button>
                                                                }
                                                            </p>

                                                            { course.hasPhysicalClass === "true" ?
                                                                haveSubmitClass.length < 1 && exam.isDone === true ? //cek dah pernah submit class/belum
                                                                    <span className="request-btn" onClick={this.showModal.bind(this, 'class', programId, enrolled.courseId, enrolled.userId, 'new', null)}>
                                                                        <i className="fas fa-pencil-alt"></i>{' '}Request practical session
                                                                    </span>
                                                                :
                                                                    <div>
                                                                        {btnRequestClass}
                                                                    </div>
                                                            : null }

                                                            <div className='mt-3 text-right'>
                                                                { transcripts && transcripts.map((ts,i) => {
                                                                    if(auth.uid === ts.userId && ts.courseId === course.id && ts.status === "publish") {
                                                                        return (
                                                                            <Link key={i} target="_blank" rel='noopener noreferrer' title="Open transcript" className='btn all-btn' to={`/transcript/${ts.userId}/${ts.batchId}/${ts.id}`}><i className="fas fa-download"/>{' '}Transcript</Link>
                                                                        )
                                                                    } return null
                                                                })}

                                                                { course.exam.hasExam === "true" && exam.isDone === true && course.hasEcert === "true" ?
                                                                    profile.isLoaded === true && (profile.identityCardNo === "" || profile.firstname === "" || profile.lastname === "") ? 
                                                                        <span style={{textTransform:"none"}} className="btn btn-disable"><i className="fas fa-download"/>{' '}Update profile to download E-Cert</span>
                                                                    : profile.isLoaded === true && profile.identityCardNo !== "" ? 
                                                                        <PDFDownloadLink document={<EcertPdf courseId={course.id} doneDate={exam.isDoneDate} profile={profile} courseName={course.courseName}/>} fileName={`${profile.firstname + '- E-Certification'}.pdf`}>
                                                                            {({ blob, url, loading, error }) => (
                                                                                <button 
                                                                                    className={loading ? "btn btn-disable" :  "btn all-btn" }>
                                                                                    { loading ? 'Loading E-Cert...' : <span> <i className="fas fa-download"/>{' '}E-Cert</span> }
                                                                                </button> 
                                                                            )}
                                                                        </PDFDownloadLink>
                                                                    : 
                                                                        null
                                                                : null }

                                                                <button className="btn btn-disable">exam</button>

                                                                <Link to={"/enrollment/module/" + enrolled.courseId}>
                                                                    <button className="btn all-btn">Start here {' '}<i className="fas fa-arrow-right"></i></button>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>

                                                <div className="enroll-status">
                                                    <b>Online Class</b> - {statusOnline} | {' '} 
                                                    { course.hasPhysicalClass === null || course.hasPhysicalClass === "false" ?
                                                        ''
                                                    :
                                                        <label><b>Practical Session</b> - {statusPractical}</label>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    }
                                } 
                                return null
                            })
                        )
                    } else {
                        isLoading = false;
                        isPassed = true;
                    }
                    return null
                })
            )
        })

        if(isPassed === true) {
            if(enrolledLength.length < 1 ) {
                isEnrolled = false
            } else {
                isEnrolled = true
            }
        }

        //kalau user belum enroll = sbb dah filter by userId @ firebaseConnect
        if(examEnrollments && examEnrollments.length === 0) {
            isLoading = false
            isEnrolled = false
        }

        //GET COURSE INFO DATA
        let isLoadingInfo = true;

        if(hasEnrolledModuleId.length > 0 || notEnrolledModuleId.length > 0) {
            isLoadingInfo = false
        }
        
        let courseInfo_done = modules && modules.map(module => {
            return (
                hasEnrolledModuleId && hasEnrolledModuleId.map(has => {
                   return (
                        quizEnrollments && quizEnrollments.map((quiz, i) => {
                            if(quiz.courseId === this.state.kosId && quiz.moduleId === has && auth.uid === quiz.userId && module.id === has) {
                                return (
                                    <tr key={i}>
                                        <td className="text-left">{module.moduleName}</td>
                                        <td><i className="text-green fas fa-check"/></td>
                                        <td>
                                            { module.quiz.hasQuiz === true || module.quiz.hasQuiz === "true" ?
                                                quiz.quizScore >= module.quiz.quizMark ?
                                                    <i className="text-green fas fa-check"/>
                                                :
                                                    <i className="text-red fas fa-times"/>
                                            : null }
                                        </td>
                                    </tr>
                                )
                            }
                            return null
                        })
                   )
                })
            )
        })

        let courseInfo_not = modules && modules.map(module => {
            return (
                notEnrolledModuleId && notEnrolledModuleId.map((not, i) => {
                    if(module.id === not) {
                        return (
                            <tr key={i}>
                                <td className="text-left">{module.moduleName}</td>
                                <td><i className="text-red fas fa-times"/></td>
                                <td><i className="text-red fas fa-times"/></td>
                            </tr>
                        )
                    }
                    return null
                })
            )
        })

        let adsImg = ads && ads.map((ad,i) => {
            return (
                <img alt="" style={{cursor:"pointer"}} onClick={this.zoomAds.bind(this, 'open', ad.fileUrl)} key={i} className="middle" width="100%" height="100%" src={ad.fileUrl} />
            )
        })

        return(

        <div className="learning min-height">

            <div id="modal-ads" className="p-5 mymodal middle">
                <img src={this.state.zoomImgSrc} alt="" height="100%"/>
                <span style={{position:"absolute", top:"10", marginLeft:"10px"}}>
                    <i onClick={this.zoomAds.bind(this, 'close', null)} className="fas fa-times fa-3x"/>
                </span>
            </div>
            
            {/* MODAL FOR Request Physical Class */}
            <div id="modalEnrollmentPage" className="mymodal">
                <div className="mymodal-content col-md-6 text-left">
                    <span className="close" onClick={this.hideModal.bind(this)}>&times;</span>

                    { this.state.modalType === "class" ? 
                        <RequestClass hideModalReqClass={this.hideModal} req_selectedBatchId={this.state.req_selectedBatchId} physicalClassBatches={physicalClassBatches} physicalClassParticipants={physicalClassParticipants} profile={profile} courses={courses} reqClass_userId={this.state.modal_userId} reqClass_courseId={this.state.modal_courseId} req_date={this.state.req_date}  req_type={this.state.req_type}/>
                    :  this.state.modalType === "info" ?
                        <div className="middle mt-5">
                            <b className="middle">Course Progress 
                                <div className="row"><div className="title-border"></div></div>
                            </b>

                            { isLoadingInfo === true ?
                                <div className="loader"></div>
                            : 
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Module Name</th>
                                            <th scope="col">Purchased Module</th>
                                            <th scope="col">Knowledge Assessment</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {courseInfo_done}
                                        {courseInfo_not}
                                    </tbody>
                                </table>
                            }

                            { isPaymentComplete === false ?
                                <div style={{marginTop:"20px"}} className="p-0 row col-md-12 justify-content-end">
                                    <Link to={"/program/" + modal_programId + "/course/" + modal_courseId + "/flexi"} className="m-0 btn all-btn">Enroll Now - Flexi</Link>
                                </div>
                            : null }

                        </div>
                    : '' }

                </div>
            </div>

            <div className="page-header middle">
                <b>Courses list</b>
                <p>My Enrollments</p>
                <div className="title-border"></div>
            </div>

            <div className="page-content">
                    
                { isLoading === true ?
                        <div className="middle loader"></div>
                : null }

                <div className='middle'>
                    <div className='col-md-10'>
                        { isEnrolled === false ?
                            <label className="text-red" style={{marginBottom:"20px"}}>
                                <i className="fas fa-exclamation-circle text-red"></i>{'  '}Currently you are not enrolled in any training course.{'  '}
                                <Link to={"/"}><button className="btn all-btn">Enroll Here</button></Link>
                            </label>
                        : null }

                        { isEnrolled === true ?
                            <div className="course row justify-content-center">
                                <div className="card bg-transparent border-0 col-md-8">
                                    { enrollment ? enrollment : null }
                                </div>
                                
                                <div style={{marginLeft:"20px", marginRight:"20px"}} className="text-center bg-transparent border-0 col carousel-enroll">
                                    <b className="middle" style={{marginBottom:"10px"}}>What's going on
                                        <div className="title-border"></div>
                                    </b>

                                    <Carousel itemsToShow={1} renderArrow={this.myArrow.bind(this)}>
                                        {adsImg}
                                    </Carousel>

                                    { adsImg ?
                                        <a href="https://wa.me/60109294976" target="_blank" rel="noopener noreferrer" className="col-md-6 btn all-btn"><i className="fab fa-whatsapp"></i>{' '}Register Now !</a>
                                    : null }

                                </div>
                            </div> 
                        : null }

                    </div>
                </div>

                {/* <div className="row col-md-12 justify-content-center">
                    { isLoading === true ?
                            <div className="middle loader"></div>
                    : null }
                </div>

                 <div className="col-md-8">
                    { isEnrolled === false ?
                        <label className="text-red" style={{marginBottom:"20px"}}>
                            <i className="fas fa-exclamation-circle text-red"></i>{'  '}Currently you are not enrolled in any training course.{'  '}
                            <Link to={"/"}><button className="btn all-btn">Enroll Here</button></Link>
                        </label>
                    : null }
                </div>  */}
               
            </div>
        </div>
       

        
        )
    }
        
}

const mapStateToProps = (state, ownProps) => {
    return {
        profile: state.firebase.profile,
        auth: state.firebase.auth,

        courses: state.firestore.ordered.courses,
        modules: state.firestore.ordered.modules,
        courseEnrollments: state.firestore.ordered.courseEnrollments,
        moduleEnrollments: state.firestore.ordered.moduleEnrollments,
        quizEnrollments: state.firestore.ordered.quizEnrollments,
        examEnrollments: state.firestore.ordered.examEnrollments,
        programs: state.firestore.ordered.programs,
        physicalClassParticipants: state.firestore.ordered.physicalClassParticipants,
        physicalClassBatches: state.firestore.ordered.physicalClassBatches,
        ads: state.firestore.ordered.ads,
        transcripts: state.firestore.ordered.transcripts,
        extraClassInfo: state.firestore.ordered.extraClassInfo,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
      updateEnrolledExam_renewSuspend: (enrolledExamId) => dispatch(updateEnrolledExam_renewSuspend(enrolledExamId)),
      submitFormReqClass: (participant) => dispatch(submitFormReqClass(participant)),
    }
}
  
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect(props => [
        { collection: 'courses' },
        { collection: 'modules', orderBy:"moduleCode"},
        { collection: 'ads'},
        { collection: 'programs'},
        { collection: 'physicalClassBatches', orderBy:"batchName" },
        { collection: 'physicalClassParticipants', where: props.auth.uid ? ['userId', '==', props.auth.uid] : null },
        { collection: 'courseEnrollments', where: props.auth.uid ? ['userId', '==', props.auth.uid] : null },
        { collection: 'moduleEnrollments', where: props.auth.uid ? ['userId', '==', props.auth.uid] : null },
        { collection: 'quizEnrollments', where: props.auth.uid ? ['userId', '==', props.auth.uid] : null },
        { collection: 'examEnrollments', where: props.auth.uid ? ['userId', '==', props.auth.uid] : null },
        { collection: 'transcripts', where: props.auth.uid ? ['userId', '==', props.auth.uid] : null },
        { collection: 'extraClassInfo' },
    ])
)(EnrollmentPage);