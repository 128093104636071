import React, { Component } from 'react';
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'

import { PDFViewer } from "@react-pdf/renderer";
import { BatchPdf } from "./BatchGenerator";

class BatchData_Pdf extends Component {

    render(){

        const { users, batchData, batchId, pcpGroup } = this.props;

        return (

            <PDFViewer style={{height:"100vh"}} width="100%">
                 { users && pcpGroup && batchData && batchId ?
                    <BatchPdf users={users} pcpGroup={pcpGroup} batchData={batchData} batchId={batchId} />
                : null }
            </PDFViewer>

        )
    }
}

const mapStateToProps = (state, ownProps) => {

    const batch_id = ownProps.match.params.batch_id;

    const batchs = state.firestore.data.physicalClassBatches;
    const batchData = batchs ? batchs[batch_id] : null;

    return {
        
        batchId: batch_id,
        batchData,

        users: state.firestore.ordered.users,
        pcpGroup: state.firestore.ordered.pcpGroup,
        physicalClassBatches: state.firestore.ordered.physicalClassBatches,
    }
}
  
export default compose(
    connect(mapStateToProps, null),
    firestoreConnect(props => [
        { collection: 'users' },
        { collection: 'pcpGroup' },
        { collection: 'physicalClassBatches' },
    ])
)(BatchData_Pdf);