import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
// import Carousel, { consts } from 'react-elastic-carousel';

import { Link } from 'react-router-dom';

class CourseList_Flexi extends Component{

    state = {
        attachLoaded: false
    }
    
    handleAttachLoaded () {
        this.setState({ attachLoaded: true });
    }

    // myArrow = ({ type, onClick, isEdge }) => {
    //     const pointer = type === consts.PREV ? <i className="fas fa-arrow-circle-left"></i> : <i className="fas fa-arrow-circle-right"></i>
    //     return (
    //         <span className="middle carousel-arrow"onClick={onClick} disabled={isEdge}>
    //             {pointer}
    //         </span>
    //     )
    //   }


    render(){

        const { auth, courses, moduleEnrollments } = this.props;

        const program_id = this.props.match.params.program_id;
        const course_id = this.props.match.params.course_id;

        let isLoading = true, flexi = null, activeCourse = []; 
        // let courseName = '', programName = '';

        //to get next module btn availble
        let selectedModules = [];

        // const breakPoints = [
        //     { width: 1, itemsToShow: 1 },
        //     { width: 550, itemsToShow: 2, itemsToScroll: 2 },
        //     { width: 1200, itemsToShow: 3 },
        //     { width: 1400, itemsToShow: 4 }
        // ];

        // courseName = courses && courses.map(course => {
        //     if(course.id === course_id) { 
        //         return course.courseName; 
        //     }
        //     return null
        // })

        // programName = programs && programs.map(program => {
        //     if (program.id === program_id) {
        //         return program.programName;
        //     }
        //     return null
        // })

        flexi = courses && courses.map((course ,i) => {

            //get all selectedModules
            if(course.id === course_id && course.courseType === "Professional") {
                course.selectedModules && course.selectedModules.map((id, index) => {
                    return selectedModules.push({indexKey: index, moduleId: id})
                })
            }

            if(course.courseType === "Flexi" && course.courseStatus === "Active" && course_id === course.selectedCourse){

                let hasEnrolledFlexi = [], isEnrolledModule = false, isAvailableNextModule = false, isLockedNextModule = false, enrolledModule_index = [], nextAvailableModule = '', nextLockedModule = [], nextAvailableKey = 0;

                moduleEnrollments && moduleEnrollments.map(enrolled => {
                    //to get current done enrolled module
                    if(auth.uid === enrolled.userId && enrolled.courseId === course_id){

                        hasEnrolledFlexi.push(enrolled.is)
                        enrolledModule_index.push(parseInt(enrolled.indexKey));

                        //find next key to enable
                        nextAvailableKey = enrolledModule_index.length;

                        selectedModules && selectedModules.map(selected => {
                            if(selected.indexKey === nextAvailableKey) {
                                nextAvailableModule = selected.moduleId
                            } else {
                                nextLockedModule.push(selected.moduleId)
                            }
                            return null
                        })
                    }
                    
                    if(auth.uid === enrolled.userId && enrolled.courseId === course_id && enrolled.moduleId === course.selectedModule){
                        isEnrolledModule = true;
                    }
                    return null
                })

                //first time enroll flexi
                if(hasEnrolledFlexi.length < 1) {
                    selectedModules && selectedModules.map(selected => {
                        if(selected.indexKey === 0) {
                            nextAvailableModule = selected.moduleId
                        } else {
                            nextLockedModule.push(selected.moduleId)
                        }
                        return null
                    })
                }

                // to get NEXT MODULE TO ENROLL
                if(nextAvailableModule === course.selectedModule) {
                    isAvailableNextModule = true
                }

                // to get NEXT MODULE TO LOCKED
                nextLockedModule && nextLockedModule.map(id => {
                    if(id === course.selectedModule) {
                        isLockedNextModule = true
                    }
                    return null
                })
                activeCourse.push(course.id)
                isLoading = false;

                return(
                    <div className="bg-transparent border-0 card col-md-4 border-0" key={i}>
                    {/* <div key={i}></div> -----kalau pakai caoursell */}
                        {/* <div className="text-left courses-card"> -----kalau pakai caoursell*/}
                        <div style={{margin:"10px 10px 30px 10px"}} className="text-left courses-card">
                            {/* <div className="text-center carousel-img-holder"> */}
                                { course.courseAttach ?
                                    <div className="middle" style={{width:"100%"}}>
                                        { course.courseAttach.fileType === "image" ?
                                            <img alt="" onLoad={this.handleAttachLoaded.bind(this)} width="100%" src={course.courseAttach.fileUrl} />
                                        : course.courseAttach.fileType === "video" ?
                                            <video onLoad={this.handleAttachLoaded.bind(this)} width="100%" src={course.courseAttach.fileUrl} type="video/mp4" controls />
                                        : null }

                                        { this.state.attachLoaded === false ?
                                            <div className="loader"></div>
                                        : null }
                                    </div>
                                : 
                                    <div className="middle" style={{width:"100%"}}>
                                        <img alt="" className="text-center" width="100%" src="/image/default.png"  /> 
                                    </div>
                                }
                            {/* </div> */}
                            
                           <div style={{padding:"20px"}}>
                                <h1>RM {course.coursePrice}.00</h1>
                                    <h3>{course.courseName}</h3>
                                    <div className="row justify-content-center"><div className="title-border"></div> </div>
                                    
                                    {course.courseInfo && course.courseInfo.map((info,i) => {
                                        return (
                                            <div key={i} className="listing"> 
                                                <div className="list-icon"><li/></div>
                                                <label dangerouslySetInnerHTML={{__html: info}} style={{margin:"0px"}}></label>
                                            </div>
                                        )
                                    })}
                                    { !auth.uid ? 
                                        <button className="btn btn-enroll-disable"><i className="fas fa-sign-in-alt"></i>{' '}Login to enroll</button> 
                                    :
                                        isEnrolledModule === true ?
                                            <button className="btn btn-enroll-disable"><i className="fas fa-check-circle"></i>{' '}Enrolled</button> 
                                        :
                                            isAvailableNextModule === true ?
                                                <Link className="middle" to={"/confirm-purchase/new/" + course.id}>
                                                    <button className="pushable  col-md-12">
                                                        <span className="shadow"></span>
                                                        <span className="edge"></span>
                                                        <span className="front">
                                                            Enroll Now{' '}<i className="fas fa-shopping-cart"></i>
                                                        </span>
                                                    </button>
                                                </Link>
                                            :
                                                isLockedNextModule === true ?
                                                    <button className="btn btn-enroll-disable"><i className="fas fa-times-circle"></i> {' '}Enroll previous module</button>
                                                :
                                                    <Link className="middle" to={"/confirm-purchase/new/" + course.id}>
                                                        <button className="pushable  col-md-12">
                                                            <span className="shadow"></span>
                                                            <span className="edge"></span>
                                                            <span className="front">
                                                                Enroll Now{' '}<i className="fas fa-shopping-cart"></i>
                                                            </span>
                                                        </button>
                                                    </Link>
                                    }
                                    <br/>
                                    <small><b>This course include:</b></small><br/>
                                    { course.courseOverview && course.courseOverview.map((overview,i) => {
                                        return (
                                            <div key={i} className="listing"> 
                                                <div className="list-icon"><li/></div>
                                                <label dangerouslySetInnerHTML={{__html: overview}} style={{margin:"0px"}}></label>
                                            </div>
                                        )
                                    })}
                           </div>

                           <div className="bg-green programs-btn">
                                <Link to={`/program/${program_id}/course/${course.id}/detail?isEnrolledType=FLexi&isEnrolledModule=${isEnrolledModule}&isAvailableNextModule=${isAvailableNextModule}&isLockedNextModule=${isLockedNextModule}`}>
                                    Read More{' '}<i className="fas fa-arrow-circle-right"></i>
                                </Link>
                            </div>
                            {/* <Link to={`/program/${program_id}/course/${course.id}/detail?isEnrolledType=FLexi&isEnrolledModule=${isEnrolledModule}&isAvailableNextModule=${isAvailableNextModule}&isLockedNextModule=${isLockedNextModule}`} className="btn all-btn">
                                Read More{' '}<i className="fas fa-arrow-circle-right"></i>
                            </Link> */}
                        </div>
                    </div>
                )
            }
            return null
        });

        return(

            <div className="courses container col-md-12 justify-content-center middle min-height">
                {/* <div className="product-header" style={{marginLeft:"4%"}}>{programName} - {courseName} - Flexi/Installment</div> */}

                <div style={{fontSize:"16px", margin:"4em 0em 0em 0em"}}>
                    Kindly enroll module according to sequences
                    <div className="title-border"></div>
                </div>

                {/* <div style={{marginBottom:"30px"}} className={ activeCourse.length < 2 ? 'col-md-6' : activeCourse.length < 3 ? 'col-md-8' : 'col-md-11' }>
                    <Carousel breakPoints={breakPoints} renderArrow={this.myArrow}>
                        { isLoading === true ? <div className="loader"></div> : flexi }
                    </Carousel>
               </div> */}
               
               <div style={{margin:"1em 0em 5em 0em"}}  className="container col-md-12 middle">
                    { isLoading === true ? 
                        <div className="middle">
                            <div className="loader"></div> 
                        </div> 
                    : 
                        <div className="row col-md-11 justify-content-left">
                            {flexi}
                        </div> 
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        courses: state.firestore.ordered.courses,
        programs: state.firestore.ordered.programs,
        moduleEnrollments: state.firestore.ordered.moduleEnrollments,
    }
}
  
export default compose(
connect(mapStateToProps),
firestoreConnect(props => [
    { collection: 'courses', orderBy:'courseCode'},
    { collection: 'programs'},
    { collection: 'moduleEnrollments', where: props.auth.uid ? ['userId', '==', props.auth.uid] : null},
])
)(CourseList_Flexi);