import React, { Component } from 'react';
import { connect } from 'react-redux'
import { storage } from '../../../config/fbConfig'
import { updateHelpInfo } from '../../../stores/actions/infoAction';

class EditHelpInfo extends Component{

    constructor(props){
        super(props)
        this.state = {
            infoName: this.props.info.infoName,
            infoType: this.props.info.infoType,
            selectedHelpFile: this.props.info.selectedHelpFile,
            attachType: this.props.info.selectedHelpFile.fileType,
    
            newHelpFile: null,
            progress: '',
            videoDisplay: null,

        
            isLoading: null,
            message: null,
        }
        this.baseState = this.state 
    }
    
    componentDidUpdate = (prevProps, prevState) => { 
        const { updateHelpError, updateHelpSuccess } = this.props;

        if(prevProps.updateHelpError !== updateHelpError) {
            this.setState({
                isLoading: false,
                message: {
                    msg: updateHelpError.message,
                    type: updateHelpError.type,
                }
            })
        }

        if(prevProps.updateHelpSuccess !== updateHelpSuccess) {
            this.setState({
                isLoading: false,
                message: {
                    msg: updateHelpSuccess.message,
                    type: updateHelpSuccess.type,
                }
            })
            setTimeout(() => { 
                this.setState(this.baseState)
                this.props.hideAndResetState();
            }, 2000);
        }
    } 

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    selectedAttachType = (e) => {
        this.setState({
            attachType:  e.target[e.target.selectedIndex].id,
        })
    }

    newHelpFile = (e) => {

        if(e.target.files[0]) {
            this.setState({
                newHelpFile: e.target.files[0]
            });
    
            if(this.state.attachType === "video") {
                this.setState({
                    videoDisplay: URL.createObjectURL(e.target.files[0]) //ubah imgUrl to pic
                });
            }
        }
    }

    updateHelpInfo = (e) => {

        const { newHelpFile, attachType } = this.state;

        var result = window.confirm("Confirm update?");

        if (result) {
            this.setState({
                isLoading: true
            })
            if (newHelpFile !== null) {

                let ref = null, ref2 = null;

                if(attachType === "pdf") {
                    ref = `documents/${newHelpFile.name}`
                    ref2 = 'documents'
                } else if (attachType === "video") {
                    ref = `videos/${newHelpFile.name}`
                    ref2 = 'videos'
                }
                const uploadTask = storage.ref(ref).put(newHelpFile);
        
                    uploadTask.on('state_changed', 
                        (snapshot) => {
                            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                            this.setState({
                                progress});
                        }, 
        
                        (error) => {
                            alert(error)
                        }, 
        
                        () => {
                            storage.ref(ref2).child(newHelpFile.name).getDownloadURL().then(fileUrl => {
                                this.setState({
                                    selectedHelpFile: {
                                        fileUrl: fileUrl,
                                        fileName: newHelpFile.name,
                                        fileType: attachType
                                        
                                    }
                                });
                                
                                this.props.updateHelpInfo(this.state, this.props.info_id);
                            })
                        }
                    );

            } else {
                this.props.updateHelpInfo(this.state, this.props.info_id);
            }
        }
    }

    displayModalHelpFile_Edit = () => { //display current file in popup
        document.getElementById("display_help_file_edit").style.display = "block";
        this.props.hideModalHelpFile_Display()
    }

    hideModalHelpFile_Edit = () => { //hide current file in popup
        document.getElementById("display_help_file_edit").style.display = "none";
    }


    render(){

        const { message, isLoading } = this.state;

        return(

        <div >

            <div id="display_help_file_edit" className="mymodal">
                <div className="mymodal-content col-md-6">
                    <span className="close" onClick={this.hideModalHelpFile_Edit}>&times;</span>

                    <h3 className="middle">{this.state.selectedHelpFile.fileName}
                        <div className="row"><div className="title-border"></div></div>
                    </h3>
                    
                    <object aria-label="display-pdf" className="display-pdf" data={this.state.selectedHelpFile.fileUrl} type="application/pdf"></object>
                  
                </div>
            </div>
                    
            <b className="uppercase middle">edit {this.props.info.infoName}
                <div className="title-border"></div>
            </b>

            <b>TITLE:</b><br />
            <input type="text" id="infoName" onChange={this.handleChange.bind(this)} className="form-control" defaultValue={this.state.infoName} />
            <br/>
            <b>ATTACHMENT:</b>
            <br />
            <select className="mb-2 col col-md-6 form-control" onChange={this.selectedAttachType.bind(this)}>
                <option defaultValue>{this.state.attachType ? this.state.attachType : 'Choose attachment type'}</option>
                <option id="video" >Video</option>
                <option id="pdf">PDF</option>
            </select>
            <br />
            { this.state.newHelpFile ?
                this.state.attachType === "video" ?
                    <video width="300" height="200" src={this.state.videoDisplay} type="video/mp4" controls />
                : null 
            : 
                this.state.selectedHelpFile ?
                    this.state.attachType === "video" ?
                        <video width="300" height="200" src={this.state.selectedHelpFile.fileUrl} type="video/mp4" controls />
                    : this.state.attachType === "pdf" ?
                        <span onClick={this.displayModalHelpFile_Edit}>{this.state.selectedHelpFile.fileName}</span>
                    : null
                : null
            }

            <br/>

            <input className="mt-2" type="file" onChange={this.newHelpFile.bind(this)} /><br/>

            <progress value={this.state.progress} max="100" style={{width:"250px"}}/>

            <br/>

            { isLoading === true ? 
                <small>Loading...</small>
            : 
                message  ?
                    <small className={message.type === 'updateHelpError' ? "text-red" : message.type === 'updateHelpSuccess'  ? "text-green" : null }>
                        {message.msg}
                    </small>
                : null
            }

            <div className="mt-5 row justify-content-end">
                <button type="button" className="btn m-2 btn-outline-black" onClick={this.updateHelpInfo.bind(this)}><i className="fas fa-check-circle"/>{' '}update</button>
            </div>
        </div>

        
        )
    }
}


const mapStateToProps = (state, ownProps) => {
    const id = ownProps.info_id;
    const infosList = state.firestore.data.infos;
    const info = infosList ? infosList[id] : null
    return {
        info_id: id,
        infosList: infosList,
        info: info,

        updateHelpError: state.infos.updateHelpError,
        updateHelpSuccess: state.infos.updateHelpSuccess,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
      updateHelpInfo: (helpInfo, info_id) => dispatch(updateHelpInfo(helpInfo, info_id))
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(EditHelpInfo)