import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { updateUser } from "../../stores/actions/userAction";

class Edit_UserAccount extends Component {
  state = {
    fullName: this.props.profile.fullname,
    firstName: this.props.profile.firstname,
    lastName: this.props.profile.lastname,
    email: this.props.profile.email,
    identityCardNo: this.props.profile.identityCardNo,
    dob: this.props.profile.dob,
    contactNo: this.props.profile.contactNo,
    company: this.props.profile.company,
    address1: this.props.profile.address1,
    address2: this.props.profile.address2,
    postcode: this.props.profile.postcode,
    city: this.props.profile.city,
    state: this.props.profile.state,
    country: this.props.profile.country,

    readOnlyFullName: this.props.profile.fullname ? true : false,
    enableEdit: false,

    isLoading: null,
    message: null,
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { updateAccountError, updateAccountSuccess } = this.props;

    if (prevProps.updateAccountError !== updateAccountError) {
        console.log(updateAccountError)
      this.setState({
        isLoading: false,
        message: {
          msg: updateAccountError.message,
          type: updateAccountError.type,
        },
      });
    }

    if (prevProps.updateAccountSuccess !== updateAccountSuccess) {
      this.setState({
        isLoading: false,
        enableEdit: false,
        message: {
          msg: updateAccountSuccess.message,
          type: updateAccountSuccess.type,
        },
      });

      window.location.href = "/userdashboard?type=myaccount&edit=false";

      var result = window.confirm(updateAccountSuccess.message);
      if (result) {
        this.setState({ message: "" });
      }
    }
  };

  handleChange = (e) => {
    if (e.target.id === "fullName") {
      this.setState({
        [e.target.id]: e.target.value.toUpperCase(),
      });
    } else {
      this.setState({
        [e.target.id]: e.target.value,
      });
    }
  };

  formattedDate = async () => {
    let formatted = moment(this.state.dob).format("YYYY-MM-DD");

    this.setState({
      dob: formatted,
    });
  };

  updateUser = async (userId, readOnlyFullName) => {
    let result;

    if (!readOnlyFullName) {
      result = window.confirm(
        "Confirm update? Full name cannot be edited again."
      );
    } else {
      result = window.confirm("Confirm update?");
    }

    await this.formattedDate();

    if (result) {
      this.setState({
        isLoading: true,
      });
      this.props.updateUser(this.state, userId);
    }
  };

  render() {
    const { profile } = this.props;
    const {
      isLoading,
      message,
      fullName,
      firstName,
      lastName,
      email,
      identityCardNo,
      dob,
      company,
      contactNo,
      address1,
      address2,
      postcode,
      city,
      state,
      country,
      readOnlyFullName,
    } = this.state;

    if (profile.isLoaded === true) {
      return (
        <div
          className="row justify-content-center"
          style={{ marginTop: "20px" }}
        >
          <form className="col form-group col-md-10">
            <div className="row">
              <div className="col">
                <b>Full Name:</b> <br />
                <small className="text-red">
                  Please input name as per IC/Passport (Full name is for certification purposes)
                </small>
                <input
                  readOnly={readOnlyFullName}
                  id="fullName"
                  type="text"
                  className="form-control"
                  onChange={this.handleChange}
                  placeholder="ALI BIN ABU"
                  defaultValue={fullName}
                />
              </div>
            </div>

            <br />

            <div className="row">
              <div className="col">
                <b>First Name:</b>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  defaultValue={firstName}
                />
              </div>

              <div className="col">
                <b>Last Name:</b>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  defaultValue={lastName}
                />
              </div>
            </div>

            <br />

            <div className="row">
              <div className="col">
                <b>IC Number:</b> <br />
                <small className="text-red">Format: XXXXXX-XX-XXXX</small>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="IC Number"
                  defaultValue={identityCardNo}
                />
              </div>

              <div className="col">
                <b>Phone Number:</b> <br />{" "}
                <small className="text-red">Format: XXX-XXXXXXX</small>
                <input
                  type="text"
                  id="contactNo"
                  className="form-control"
                  onChange={this.handleChange}
                  placeholder="Phone Number"
                  defaultValue={contactNo}
                />
              </div>
            </div>

            <br />

            <div className="row">
              <div className="col">
                <b>Email:</b>
                <input
                  readOnly
                  type="text"
                  id="email"
                  className="form-control"
                  defaultValue={email}
                />
              </div>

              <div className="col">
                <b>Date of Birth:</b>
                <input
                  type="date"
                  id="dob"
                  className="form-control"
                  onChange={this.handleChange}
                  defaultValue={dob}
                />
              </div>
            </div>

            <br />

            <div className="row">
              <div className="col">
                <b>Company Name / Organization:</b>
                <input
                  type="text"
                  id="company"
                  className="form-control"
                  onChange={this.handleChange}
                  defaultValue={company}
                />
              </div>
            </div>

            <br />

            <div className="row">
              <div className="col">
                <b>Address:</b>
                <div className="row col-md-12">
                  <label
                    htmlFor="address1"
                    className="col-form-label col col-md-3"
                  >
                    <li>Line 1</li>
                  </label>
                  <input
                    type="text"
                    id="address1"
                    className="col form-control"
                    onChange={this.handleChange}
                    placeholder="Address Line 1"
                    defaultValue={address1}
                  />
                </div>

                <div className="row col-md-12">
                  <label
                    htmlFor="address2"
                    className="col-form-label col col-md-3"
                  >
                    <li>Line 2</li>
                  </label>
                  <input
                    type="text"
                    id="address2"
                    className="col form-control"
                    onChange={this.handleChange}
                    placeholder="Address Line 2"
                    defaultValue={address2}
                  />
                </div>

                <div className="row col-md-12">
                  <label
                    htmlFor="postcode"
                    className="col-form-label col col-md-3"
                  >
                    <li>Postal Code</li>
                  </label>
                  <input
                    type="text"
                    id="postcode"
                    className="col form-control"
                    onChange={this.handleChange}
                    placeholder="Postcode"
                    defaultValue={postcode}
                  />
                </div>

                <div className="row col-md-12">
                  <label htmlFor="city" className="col-form-label col col-md-3">
                    <li>City</li>
                  </label>
                  <input
                    type="text"
                    id="city"
                    className="col form-control"
                    onChange={this.handleChange}
                    placeholder="City"
                    defaultValue={city}
                  />
                </div>

                <div className="row col-md-12">
                  <label
                    htmlFor="state"
                    className="col-form-label col col-md-3"
                  >
                    <li>State</li>
                  </label>
                  <input
                    type="text"
                    id="state"
                    className="col form-control"
                    onChange={this.handleChange}
                    placeholder="State"
                    defaultValue={state}
                  />
                </div>

                <div className="row col-md-12">
                  <label
                    htmlFor="country"
                    className="col-form-label col col-md-3"
                  >
                    <li>Country</li>
                  </label>
                  <input
                    type="text"
                    id="country"
                    className="col form-control"
                    onChange={this.handleChange}
                    placeholder="Country"
                    defaultValue={country}
                  />
                </div>
              </div>
            </div>
            <br />

            <div>
              {isLoading === true ? (
                <small>Loading...</small>
              ) : message ? (
                <small
                  className={
                    message.type === "updateAccountError"
                      ? "text-red"
                      : message.type === "updateAccountSuccess"
                      ? "text-green"
                      : null
                  }
                >
                  {message.msg}
                </small>
              ) : null}
            </div>

            <div
              style={{ marginTop: "20px" }}
              className="row justify-content-end"
            >
              <button
                type="button"
                onClick={this.updateUser.bind(
                  this,
                  profile.userId,
                  readOnlyFullName
                )}
                className="mt-3 btn all-btn"
              >
                <i className="fas fa-check-circle" /> Update Details
              </button>
              <Link
                to="/userdashboard?type=myaccount&edit=false"
                className="mt-3 btn all-btn bg-yellow"
              >
                <i className="fas fa-times" /> Cancel
              </Link>
            </div>
          </form>
        </div>
      );
    } else {
      return <p>Loading...</p>;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    updateAccountError: state.users.updateAccountError,
    updateAccountSuccess: state.users.updateAccountSuccess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUser: (updatedUser, userId) => {
      dispatch(updateUser(updatedUser, userId));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit_UserAccount);
