import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Redirect } from 'react-router-dom'

import { updateEnrolledModule_topics } from '../../stores/actions/enrollmentAction';
import { module_startDate } from '../../stores/actions/enrollmentAction';

import Video from './Video';

class LearningPage extends Component{

    state = {
        id:'',
        indexKey: '',
        isAvailable:'',

        moduleName: '',

        topicNextIndex:0,
        topicNextId:'',

        enrolledCurrentModuleId: '',
        enrolledTopics: '',
        enrolledTopicsId: '',
    }

    componentDidMount = () => {

        const { auth, moduleEnrollments } = this.props;
        
        const query = new URLSearchParams(this.props.location.search);
        let query_courseId = query.get('courseId');
        let query_moduleId = query.get('moduleId');

        moduleEnrollments && moduleEnrollments.map(enrolled => {
            if (auth.uid === enrolled.userId && enrolled.moduleId === query_moduleId && enrolled.courseId === query_courseId && enrolled.startDate === "") {
                this.props.module_startDate(enrolled.id);
            } return null
        })
    }

    getTopicData = async () => {
        
        const { auth, moduleEnrollments } = this.props;

        const query = new URLSearchParams(this.props.location.search);
        
        let query_courseId = query.get('courseId');
        let query_moduleId = query.get('moduleId');
        
        let enrolledCurrentModuleId = '', enrolledTopics = [], enrolledTopicsId = [];

        moduleEnrollments && moduleEnrollments.map(enrolled => {
            if (auth.uid === enrolled.userId && enrolled.courseId === query_courseId) {
                if (enrolled.moduleId === query_moduleId) { //cari same module id

                    enrolledCurrentModuleId = enrolled.id; //get moduleEnrolment id  - assing declared enrolledCurrentModuleId with value
                
                    enrolled.topics && enrolled.topics.map(topic => {
                        enrolledTopics.push(topic); //assogn enrolledTopics array with topics list
                        enrolledTopicsId.push(topic.id)
                        return null
                    })
                }
            }
            return null
        })

        this.setState({
            enrolledCurrentModuleId: enrolledCurrentModuleId,
            enrolledTopics : enrolledTopics,
            enrolledTopicsId : enrolledTopicsId,
        })
    }

    nextTopic = async () => { //update topicStatus + auto next topic

        const query = new URLSearchParams(this.props.location.search);
        
        let query_topicIndex = query.get('topicIndex');

        await this.getTopicData();
      
        let topicNextIndex = parseInt(query_topicIndex)+1;

        let topics = this.state.enrolledTopics;
        let enrolledCurrentModuleId = this.state.enrolledCurrentModuleId;

        const updatedTopicStatus = {
            id: topics[topicNextIndex].id,
            indexKey: topics[topicNextIndex].indexKey,
            isAvailable: true
        };

        //update topic w given index only
        topics[topicNextIndex] = updatedTopicStatus;

        this.setState({
            topicNextIndex: topicNextIndex,
            topicNextId: topics[topicNextIndex].id,
        }, () => {
            document.getElementById('nextTopic').click()
        })

        //overwrite all topic data
        this.props.updateEnrolledModule_topics(topics, enrolledCurrentModuleId);

        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    finishModule = () => {
        const query = new URLSearchParams(this.props.location.search);
        let query_courseId = query.get('courseId');

        this.props.history.push('/enrollment/module/' + query_courseId);
    }


    render(){

        const { auth, courses, modules, moduleEnrollments, topics } = this.props;
        
        const query = new URLSearchParams(this.props.location.search);
        let query_courseId = query.get('courseId');
        let query_moduleId = query.get('moduleId');
        let query_moduleIndex = query.get('moduleIndex');
        let query_topicId = query.get('topicId');
        let query_topicIndex = query.get('topicIndex');
        
        let topicCount = 0, isLoadingTopic = true;
        
        if (!auth.uid) return <Redirect to="/" />

        let moduleName = modules && modules.map(module => {
            if(query_moduleId === module.id) {
                return module.moduleName;
            }
            return null
        })

        let topicsList = moduleEnrollments && moduleEnrollments.map(enrolled => {
            if (auth.uid === enrolled.userId && enrolled.moduleId === query_moduleId && enrolled.courseId === query_courseId) {
                topicCount = enrolled.topics.length - 1;
                return (
                    topics && topics.map(topic => {
                        return (
                            enrolled.topics && enrolled.topics.map((enrolled,i) => {
                                if (enrolled.id === topic.id) {
                                    isLoadingTopic = false;
                                    if ( enrolled.isAvailable === true) {
                                        return (
                                            <div key={i} className={query_topicId === topic.id ? "bg-green-dark listing" : "bg-green listing"}> 
                                                <div className="list-icon"><i className="fas fa-check"/></div>
                                                {/* <span className={query_topicId === topic.id ? "text-bold list-topic" : "list-topic"} onClick={this.handleChange.bind(this, enrolled.id, enrolled.indexKey)}>{topic.topicName}</span> */}
                                                <Link  
                                                    to={`/enrollment/learning?courseId=${query_courseId}&moduleIndex=${query_moduleIndex}&moduleId=${query_moduleId}&topicId=${enrolled.id}&topicIndex=${enrolled.indexKey}`}>{topic.topicName}
                                                </Link>
                                            </div>
                                        )
                                    } else if ( enrolled.isAvailable === false) {
                                        return (
                                            <div key={i} className="bg-lightgrey listing"> 
                                                <div className="text-grey  list-icon"><i className="fas fa-lock"/></div>
                                                <span className="text-grey">{topic.topicName}</span>
                                            </div>
                                        )
                                    }
                                }
                                return null
                            })
                        )
                    })
                )
            }
            return null
        })

        let displayVideo = topics && topics.map((topic,i) => {
            if (query_topicId === topic.id) {
                return (
                    <div key={i}>
                        <div className="col-style">
                            <b>{topic.topicName}</b>
                            <div className="ml-2 row"><div className="title-border"></div></div>
                            <div>
                                {topic.selectedVideo && topic.selectedVideo.fileUrl ?
                                    topic.selectedVideo.videoType === "Youtube" ?
                                        <Video ytUrl={topic.selectedVideo.fileUrl} />
                                    :  
                                        <video id="myvideo" width="100%" height="100%" src={topic.selectedVideo.fileUrl} type="video/mp4" controls />
                                : 
                                    <div style={{height:"300px"}} className="text-left col bg-lightgrey middle">
                                        Video is unavailable due to content update. Sorry for the inconvenience. You can proceed to download or view
                                        the notes at the bottom of this side. Thank you.
                                    </div> 
                                }
                            </div>  
                            
                            <div className="mt-5">
                                <b>Subtopic Covered</b>
                                <div className="ml-2 row"><div className="title-border"></div></div>
                                <div className="text-left">
                                    { topic.subtopics && topic.subtopics.length > 0 ?
                                        topic.subtopics.map((subtopic,i) => {
                                            return (
                                                <div style={{color:"#000"}} key={i} className="listing"> 
                                                    <div className="list-icon"><i className="fas fa-check"/></div>
                                                    <span style={{cursor:'unset'}}>{subtopic.subtopic}</span>
                                                </div>
                                            )
                                        })
                                    : <p>Subtopic is unavailable.</p>}
                                </div>
                            </div>
                                    
                            <div className="mt-5">
                                <b>Downloadable Notes</b>
                                <div className="ml-2 row"><div className="title-border"></div></div>
                                { topic.selectedNote ?
                                    <a key={i} className="badge bg-green text-white" href={topic.selectedNote.fileUrl} target="_blank" rel="noopener noreferrer" download><i className="fas fa-download"/>{'  '}Click to download</a>
                                : 
                                    <small><i>Not available</i></small>
                                }
                            </div>

                            <div className="mt-5 col text-right">
                                { parseInt(query_topicIndex) === topicCount ?
                                    <button onClick={this.finishModule.bind(this)} className="justify-content-center btn all-btn">
                                        Finish All Modules {'  '}<i className="fas fa-check-circle"></i>
                                    </button>
                                : this.state.topicNextIndex === topicCount ?
                                    <button onClick={this.finishModule.bind(this)} className="justify-content-center btn all-btn">
                                        Finish All Modules {'  '}<i className="fas fa-check-circle"></i>
                                    </button>
                                : this.state.topicNextIndex ||  parseInt(query_topicIndex) !== topicCount ?
                                    <button onClick={this.nextTopic.bind()} className="justify-content-center btn all-btn">Next Topic {'  '}<i className="fas fa-arrow-alt-circle-right"></i> </button>
                                : '' }
                            </div>

                            <div hidden={true}>
                                <Link id="nextTopic" to={`/enrollment/learning?courseId=${query_courseId}&moduleIndex=${query_moduleIndex}&moduleId=${query_moduleId}&topicId=${this.state.topicNextId}&topicIndex=${this.state.topicNextIndex}`}></Link>
                            </div>
                        </div>
                    </div>
                )
            }
            return null
        })

        // let displaySubtopic = topics && topics.map((topic,i) => {
        //     if (query_topicId === topic.id) {
        //         return (
        //             <div key={i} >
        //                 <b>Subtopic Covered</b>
        //                 <div className="row"><div className="title-border"></div></div>
        //                 <div className="text-left">
        //                     { topic.subtopics && topic.subtopics.length > 0 ?
        //                         topic.subtopics.map((subtopic,i) => {
        //                             return (
        //                                 <div key={i} className="bg-green listing"> 
        //                                     <div className="list-icon"><i className="fas fa-check"/></div>
        //                                     <span style={{cursor:'unset'}}>{subtopic.subtopic}</span>
        //                                 </div>
        //                             )
        //                         })
        //                     : <p>Subtopic is unavailable.</p>}
        //                 </div>
        //             </div>
        //         )
        //     }
        //     return null
        // })

        // let displayNote = topics && topics.map((topic,i) => {
        //     if (query_topicId === topic.id) {
        //         return (
        //             <div className="mt-5" key={i} >
        //                 <b>Downloadable Notes</b>
        //                 <div className="row"><div className="title-border"></div></div>
        //                 { topic.selectedNote ?
        //                     <a key={i} className="badge bg-green text-white" href={topic.selectedNote.fileUrl} target="_blank" rel="noopener noreferrer" download><i className="fas fa-download"/>{'  '}Click to download</a>
        //                 : 
        //                     <small><i>Not available</i></small>
        //                 }
        //             </div>
        //         )
        //     }
        //     return null
        // })


        let courseName;

        courses && courses.map(course => {
            if(course.id === query_courseId) {
                courseName = course.courseName
            }
            return null
        })

        return(

        <div className="learning min-height">

            <div className="page-header middle">
                <b>Learning Page</b>
                <p>{courseName} - {moduleName}</p>
                <div className="title-border"></div>
            </div>

            <div className="page-content">

                { isLoadingTopic === true ?
                        <div className="loader"></div>
                : null }

                <div className='middle'>
                    <div className="elearning row col-md-11 justify-content-center text-left">
                            <div className="card bg-transparent border-0 col-md-3 elearning-topic">
                                <nav className="navbar navbar-expand-lg navbar-light">
                                    <button className="navbar-toggler border-0 " type="button" data-toggle="collapse" data-target="#navbarSupportedContent2" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="navbar-toggler-icon"></span>
                                        <label>Click here for topic(s)</label>
                                    </button>
                                    <br/>

                                    <div className="collapse navbar-collapse" id="navbarSupportedContent2">
                                        { isLoadingTopic === false ?
                                            <div className="mt-2 col-style">
                                                <b>Topics</b>
                                                <div className="ml-2 row"><div className="title-border"></div></div>
                                                {topicsList}
                                            </div>
                                        : null }
                                    </div>
                                </nav>
                            </div>

                        <div className="card bg-transparent border-0 col-md-9 elearning-video">
                            { query_topicId ? displayVideo : 'Please click the first topic on the left side.' }
                        </div>
                    </div>
                </div>

            </div>
        </div>
        )
    }   
}

const mapStateToProps = (state, ownProps) => {

    return {
        profile: state.firebase.profile,
        auth: state.firebase.auth,

        modules: state.firestore.ordered.modules,
        courses: state.firestore.ordered.courses,
        topics: state.firestore.ordered.topics,
        moduleEnrollments: state.firestore.ordered.moduleEnrollments,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateEnrolledModule_topics: (topic, enrolledCurrentModuleId) => dispatch(updateEnrolledModule_topics(topic, enrolledCurrentModuleId)),
        module_startDate: (enrolledId) => dispatch(module_startDate(enrolledId))
    }
}
  
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect(props => [
        { collection: 'modules'},
        { collection: 'courses'},
        { collection: 'topics', orderBy: ['topicCode','asc'] },
        { collection: 'moduleEnrollments', where: props.auth.uid ? ['userId', '==', props.auth.uid] : null, orderBy: ['indexKey','asc']},
    ])
)(LearningPage);