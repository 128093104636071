import React, { Component } from 'react';

class DisplayEmailHistory extends Component {

    state = {
        clickedEmailId: ''
    }

    openEmailDetail = (id) => {
        this.setState({
            clickedEmailId: id
        })
        document.getElementById("modal-email-detail").style.display = "block";
    }
    
    hideEmailDetail = () => {
        document.getElementById("modal-email-detail").style.display = "none";
    }

    render(){

        const { classCustomEmail, selectedBatchId } = this.props;

        let hasEmail = [], emailHistory = null;

        classCustomEmail && classCustomEmail.map((email,i) => {
            if(email.selectedBatchId === selectedBatchId) {
                hasEmail.push(email.id)
            } return null
        })

        emailHistory = classCustomEmail && classCustomEmail.map((email,i) => {
            return (
                hasEmail && hasEmail.map(em => {
                    if(em === email.id) {
                        return (
                            <tr className="text-left" key={i}>
                                <td>{email.sendDate}</td>
                                <td><span onClick={this.openEmailDetail.bind(this, email.id)}>{email.subject}</span></td>
                                <td>{email.to && email.to.map((to,i) => { return <li key={i}>{to}</li>})}</td>
                            </tr>
                        )
                    } return null
                })
            )
        })

        return(
           <div style={{marginTop:"50px"}}>
               
                <div id="modal-email-detail" className="normalmodal">
                    <div className="normalmodal-content col-md-6">
                        <span className="close" onClick={this.hideEmailDetail.bind(this)}>&times;</span>

                        { classCustomEmail && classCustomEmail.map((email, i) => {
                            if(email.id === this.state.clickedEmailId) {
                                return (
                                    <div key={i}>
                                        <p><b>Subject:</b><br/>{email.subject}</p>
                                        <b>Content:</b><br/><p dangerouslySetInnerHTML={{__html: email.html}}></p>
                                    </div>
                                )
                            }
                            return null
                        })}
                    </div>
                </div>

                { hasEmail && hasEmail.length >= 1 ?
                    <div>
                        <b>EMAIL HISTORY:</b>
                        <table className="text-left mt-2 table">
                            <thead>
                                <tr>
                                    <th scope="col">Send Date</th>
                                    <th scope="col">Email Subject</th>
                                    <th scope="col">Send To</th>
                                </tr>
                            </thead>

                            <tbody className="bg-white">
                                {emailHistory}
                            </tbody>
                        </table>
                    </div>
                : null }
           </div>
        )
    }
}
  
export default DisplayEmailHistory;