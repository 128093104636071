import React, { Component } from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

import { PDFDownloadLink } from "@react-pdf/renderer";
import { BatchPdf } from "./BatchGenerator";

class BatchData extends Component {
  state = {
    actionType: "",
  };

  handleBtn = (type) => {
    this.setState({ actionType: type });
  };

  render() {
    const { users, batchData, batchId, pcpGroup } = this.props;
    const { modules, moduleEnrollments, examEnrollments, quizEnrollments, courses } =
      this.props;

    var batchName = batchData && batchData.batchName;
    var batchCode = batchData && batchData.batchCode;
    var participants = batchData && batchData.selectedParticipants;

    var groups = pcpGroup && pcpGroup;

    let fullPcpData = [];

    users &&
      users.map((user) => {
        return (
          participants &&
          participants.map((part) => {
            if (user.userId === part.userId) {
              fullPcpData.push({
                name: user.firstname + " " + user.lastname,
                email: user.email,
                ic: user.identityCardNo ? user.identityCardNo : "Not updated",
                phone: user.contactNo ? user.contactNo : "Not updated",
                company: user.company ? user.company : "Not updated",
                userId: user.id,
              });
            }
            return null;
          })
        );
      });

    let moduleTimestamp = [], examTimestamp = [], testingstamp = [], fullTestingstamp = [], modulestamp = [];

    fullPcpData.length > 0 &&
      fullPcpData.map((data) => {
        return (
          moduleEnrollments &&
          moduleEnrollments.map((enrolled) => {
            if (
              batchData.courseId === enrolled.courseId &&
              data.userId === enrolled.userId
            ) {
              moduleTimestamp.push({
                name: data.name,
                email: data.email,
                loginTimestamp: enrolled.startDate,
                logoutTimestamp: enrolled.finishDate,
                type: "module",
                moduleId: enrolled.moduleId,
                indexKey: enrolled.indexKey,
              });
            }
            return null;
          })
        );
      });

    const groupedModule =
      moduleTimestamp.length > 0 &&
      moduleTimestamp.reduce((acc, obj) => {
        const key = obj.moduleId;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
      }, {});

    const mappedModule = Object.entries(groupedModule).map(([key, value]) => {
      return {
        category: key,
        items: value,
      };
    });

    fullPcpData.length > 0 &&
      fullPcpData.map((data) => {
        return (
          examEnrollments &&
          examEnrollments.map((enrolled) => {
            if (
              batchData.courseId === enrolled.courseId &&
              data.userId === enrolled.userId
            ) {
              examTimestamp.push({
                name: data.name,
                email: data.email,
                loginTimestamp: enrolled.startDate,
                logoutTimestamp: enrolled.finishDate,
                type: "exam",
              });
            }
            return null;
          })
        );
      });

    fullPcpData.length > 0 && fullPcpData.map((data) => {
        return (
          examEnrollments && examEnrollments.map((enrolled) => {
            if (
              batchData.courseId === enrolled.courseId &&
              data.userId === enrolled.userId
            ) {
              testingstamp.push({
                name: data.name,
                email: data.email,
                ic: data.ic,
                phone: data.phone,
                company: data.company,
                userId: data.userId,
                examScore: enrolled.examScore,
                type: "testingExam",
              });
            }
            return null;
          })
        );
      });

      courses && courses.map((data) => {
        if(batchData)
        if ( batchData.courseId === data.id ) {
          modulestamp = data.selectedModules;
        }
        return null;
      });

    testingstamp.length > 0 && testingstamp.map((data) => {
        let tstamp = [];
        return (
          quizEnrollments && quizEnrollments.map((enrolled) => {
            if ( batchData.courseId === enrolled.courseId )
            return (
                modulestamp && modulestamp.map((module, i) => {
                if (
                  batchData.courseId === enrolled.courseId &&
                  data.userId === enrolled.userId &&
                  module === enrolled.moduleId
                ) {
                    tstamp.push({
                    score: enrolled.quizScore,
                    indexKey: i,
                  });
                }

                return null;
              })
            ); // return dalam

            else return null
          }),
          fullTestingstamp.push({
            name: data.name,
            email: data.email,
            ic: data.ic,
            phone: data.phone,
            company: data.company,
            userId: data.userId,
            examScore: data.examScore,
            module: tstamp.sort((a, b) => (a.indexKey > b.indexKey) ? 1 : -1),
            type: "testing",
          })
        ); // return satu lagi
      });

    return (
      <div className="min-height m-5">
        {users && batchData && batchId ? (
          <div>
            <b className="text-left">Batch : {batchCode}</b>

            <div className="mt-3">
              <ReactHTMLTableToExcel
                className="btn all-btn"
                table="batch"
                filename={batchName ? batchName : "Batch data"}
                sheet="Sheet"
                buttonText="Export batch data as Excel"
              />

              <PDFDownloadLink
                document={
                  users && pcpGroup && batchData && batchId ? (
                    <BatchPdf
                      type="batch-data"
                      users={users}
                      pcpGroup={pcpGroup}
                      batchData={batchData}
                      batchId={batchId}
                      fullTestingstamp={fullTestingstamp}
                    />
                  ) : null
                }
                fileName={`${batchName}.pdf`}
              >
                {({ blob, url, loading, error }) =>
                  loading ? (
                    <button className="btn btn-disable">Loading PDF...</button>
                  ) : (
                    <button className="btn all-btn">
                      Export batch data as PDF
                    </button>
                  )
                }
              </PDFDownloadLink>

              {this.state.actionType === "login-data" ? (
                <PDFDownloadLink
                  document={
                    users && pcpGroup && batchData && batchId ? (
                      <BatchPdf
                        type="login-data"
                        modules={modules}
                        mappedModule={mappedModule}
                        examTimestamp={examTimestamp}
                      />
                    ) : null
                  }
                  fileName={`${batchName}_Timestamp.pdf`}
                >
                  {({ blob, url, loading, error }) =>
                    loading ? (
                      <button className="btn btn-disable">
                        Loading PDF...
                      </button>
                    ) : (
                      <button className="btn all-btn bg-primary">
                        <i className="fas fa-download" /> Participants Timestamp
                        as PDF
                      </button>
                    )
                  }
                </PDFDownloadLink>
              ) : (
                <button
                  onClick={this.handleBtn.bind(this, "login-data")}
                  className="btn all-btn bg-primary"
                >
                  <i className="fas fa-eye" /> Participants Timestamp
                </button>
              )}

              {this.state.actionType === "login-data" ? (
                <span
                  className="ml-2 badge bg-danger text-white"
                  onClick={this.handleBtn.bind(this, "")}
                >
                  <i className="fas fa-times" /> Close
                </span>
              ) : null}
            </div>

            {this.state.actionType === "login-data" ? (
              <div className="mt-5">
                {mappedModule &&
                  mappedModule.map((mapped) => {
                    return (
                      modules &&
                      modules.map((module) => {
                        if (module.id === mapped.category) {
                          return (
                            <>
                              <label>
                                <b>Topic :</b> {module.moduleName}
                              </label>
                              <br />
                              <label>
                                <b>Start Date :</b>{" "}
                              </label>
                              <br />
                              <label>
                                <b>End Date :</b>{" "}
                              </label>
                              <br />

                              <table className="bg-white table">
                                <thead className="bg-white">
                                  <tr>
                                    <th>No.</th>
                                    <th className="text-left">Name</th>
                                    <th className="text-left">Email</th>
                                    <th>Log In</th>
                                    <th>Log Out</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {mapped.items &&
                                    mapped.items.map((item, key) => {
                                      return (
                                        <tr key={key}>
                                          <td>{key + 1}</td>
                                          <td className="text-left">
                                            {item.name}
                                          </td>
                                          <td className="text-left">
                                            {item.email}
                                          </td>
                                          <td>{item.loginTimestamp}</td>
                                          <td>{item.logoutTimestamp}</td>
                                        </tr>
                                      );
                                    })}
                                </tbody>
                              </table>
                              <br />
                              <br />
                              <br />
                            </>
                          );
                        }
                        return null;
                      })
                    );
                  })}

                <>
                  <label>
                    <b>Topic :</b> Examination
                  </label>
                  <br />
                  <label>
                    <b>Start Date :</b>{" "}
                  </label>
                  <br />
                  <label>
                    <b>End Date :</b>{" "}
                  </label>
                  <br />

                  <table className="bg-white table">
                    <thead className="bg-white">
                      <tr>
                        <th>No.</th>
                        <th className="text-left">Name</th>
                        <th className="text-left">Email</th>
                        <th>Log In</th>
                        <th>Log Out</th>
                      </tr>
                    </thead>
                    <tbody>
                      {examTimestamp.length > 0 &&
                        examTimestamp.map((exam, key) => {
                          return (
                            <tr key={key}>
                              <td>{key + 1}</td>
                              <td className="text-left">{exam.name}</td>
                              <td className="text-left">{exam.email}</td>
                              <td>{exam.loginTimestamp}</td>
                              <td>{exam.logoutTimestamp}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  <br />
                  <br />
                  <br />
                </>
              </div>
            ) : (
              <table id="batch" className="mt-4 table">
                <thead>
                  <tr className="text-left">
                    <th className="text-center">No.</th>
                    <th>Name</th>
                    <th>IC</th>
                    <th>Company</th>
                    <th>Email</th>
                    <th>Contact</th>
                    <th>Exam</th>
                    <th>Test 1</th>
                    <th>Test 2</th>
                    <th>Test 3</th>
                    <th>Test 4</th>
                    <th>Test 5</th>
                    <th>Test 6</th>
                  </tr>
                </thead>

                <tbody>
                  {/* {fullPcpData &&
                    fullPcpData.map((data, i) => {
                      let no = i + 1;
                      return (
                        <tr key={no} className="text-left">
                          <td className="text-center">{no}.</td>
                          <td>{data.name}</td>
                          <td>{data.ic}</td>
                          <td>{data.company}</td>
                          <td>{data.email}</td>
                          <td>{data.phone}</td>
                        </tr>
                      );
                    })} */}
                    {fullTestingstamp &&
                    fullTestingstamp.map((data, i) => {
                      let no = i + 1;
                      return (
                        <tr key={no} className="text-left">
                          <td className="text-center">{no}.</td>
                          <td>{data.name}</td>
                          <td>{data.ic}</td>
                          <td>{data.company}</td>
                          <td>{data.email}</td>
                          <td>{data.phone}</td>
                          <td>{data.examScore}</td>
                          <td>{data.module[0] ? data.module[0].score: "-"}</td>
                          <td>{data.module[1] ? data.module[1].score: "-"}</td>
                          <td>{data.module[2] ? data.module[2].score: "-"}</td>
                          <td>{data.module[3] ? data.module[3].score: "-"}</td>
                          <td>{data.module[4] ? data.module[4].score: "-"}</td>
                          <td>{data.module[5] ? data.module[5].score: "-"}</td>
                        </tr>
                      );
                    })}
                </tbody>

                {groups &&
                  groups.map((group, i) => {
                    if (group.batchId === batchId) {
                      return (
                        <tbody key={i}>
                          <tr>
                            <td colSpan="6"></td>
                          </tr>
                          <tr>
                            <td></td>
                            <th className="text-left">{group.groupName}</th>
                          </tr>

                          {group.addedPcp &&
                            group.addedPcp.map((pcp, i) => {
                              let no = i + 1;
                              return (
                                fullTestingstamp &&
                                fullTestingstamp.map((data) => {
                                  if (data.userId === pcp.userId) {
                                    return (
                                      <tr key={no} className="text-left">
                                        <td className="text-center">{no}.</td>
                                        <td>{data.name}</td>
                                        <td>{data.ic}</td>
                                        <td>{data.company}</td>
                                        <td>{data.email}</td>
                                        <td>{data.phone}</td>
                          <td>{data.examScore}</td>
                          <td>{data.module[0] ? data.module[0].score: "-"}</td>
                          <td>{data.module[1] ? data.module[1].score: "-"}</td>
                          <td>{data.module[2] ? data.module[2].score: "-"}</td>
                          <td>{data.module[3] ? data.module[3].score: "-"}</td>
                          <td>{data.module[4] ? data.module[4].score: "-"}</td>
                          <td>{data.module[5] ? data.module[5].score: "-"}</td>
                                      </tr>
                                    );
                                  }
                                  return null;
                                })
                              );
                            })}
                        </tbody>
                      );
                    }
                    return null;
                  })}
              </table>
            )}
          </div>
        ) : (
          <p className="mt-3">Loading data...</p>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const batch_id = ownProps.match.params.batch_id;

  const batchs = state.firestore.data.physicalClassBatches;
  const batchData = batchs ? batchs[batch_id] : null;

  return {
    batchId: batch_id,
    batchData,

    users: state.firestore.ordered.users,
    pcpGroup: state.firestore.ordered.pcpGroup,
    physicalClassBatches: state.firestore.ordered.physicalClassBatches,

    modules: state.firestore.ordered.modules,
    moduleEnrollments: state.firestore.ordered.moduleEnrollments,
    examEnrollments: state.firestore.ordered.examEnrollments,
    quizEnrollments: state.firestore.ordered.quizEnrollments,
    courses: state.firestore.ordered.courses,
  };
};

export default compose(
  connect(mapStateToProps, null),
  firestoreConnect((props) => [
    { collection: "users" },
    { collection: "pcpGroup", orderBy: "groupCode" },
    { collection: "physicalClassBatches", orderBy: "batchName" },

    { collection: "modules" },
    { collection: "moduleEnrollments", orderBy: "indexKey" },
    { collection: "examEnrollments" },
    { collection: "quizEnrollments", orderBy: "moduleId" },
    { collection: "courses" },
  ])
)(BatchData);
