import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import { addQuiz } from '../../../stores/actions/courseAction';

class Quiz extends Component{

    constructor(props){
        super(props)
        this.state = {
            quizName: '',
            quizCode:'',
            selectedQuestions: [],
            searchQuiz_Question: '',
            selectedQuestionCode:'',
    
            isLoading: null,
            message: null,
            addedQuizId: ''
        }
        this.baseState = this.state 
    }
    
    componentDidUpdate = (prevProps, prevState) => { 

        const { addQuizError, addQuizSuccess } = this.props;

        if(prevProps.addQuizError !== addQuizError) {
            this.setState({
                isLoading: false,
                message: {
                    msg: addQuizError.message,
                    type: addQuizError.type,
                }
            })
        }

        if(prevProps.addQuizSuccess !== addQuizSuccess) {
            this.setState({
                isLoading: false,
                message: {
                    msg: addQuizSuccess.message,
                    type: addQuizSuccess.type,
                },
                addedQuizId: addQuizSuccess.id
            }, () => {
                setTimeout(() => { 
                    this.props.hide();
                    document.getElementById("clickAddQuiz").click();
                    setTimeout(() => { 
                        this.setState(this.baseState)
                    }, 3000);
                }, 2000);
            })
        }
    } 

    handleSelectedQuestion(id, code) {
        this.setState({
            selectedQuestions: [ 
                ...this.state.selectedQuestions,
               id
            ],
            selectedQuestionCode: code
        });
    }

    removeSelectedQuestion = (id) => {
        let removeSelectedQuestion = this.state.selectedQuestions.filter(selected => {
          return selected !== id
        })
    
        this.setState({
            selectedQuestions: removeSelectedQuestion
        })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    addQuiz = (e) => {

        var result = window.confirm("Confirm add?");

        if (result) {
            this.setState({
                isLoading: true
            })
            this.props.addQuiz(this.state);
        }
    }

    render(){

        const { questions } = this.props;
        const { message, isLoading } = this.state;

        let filteredQuestions = null;

        if(this.state.searchQuiz_Question.length > 0) {
            filteredQuestions = questions && questions.filter(question => {
                return question.questionCode.toLowerCase().includes(this.state.searchQuiz_Question.toLowerCase())
            })
        } else {
            filteredQuestions = questions;
        }
        
        return(

            <div>
                <form className="text-left">
                    <div className="row">    
                        <p className="col col-md-3">
                            <b>Code:</b>
                            <input type="text" id="quizCode" onChange={this.handleChange} className="form-control" value={this.state.quizCode} placeholder="Code" />
                        </p>
                        <p className="col">
                            <b>Name:</b>
                            <input type="text" id="quizName" onChange={this.handleChange} className="form-control" value={this.state.quizName} placeholder="Name"/>
                        </p>
                    </div>

                    <br/>

                    <div className=" mb-5">
                        <b>Questions: </b>
                        <div type="text" className="border-black">

                            { questions && questions.map((question,i) => {
                                return (
                                    this.state.selectedQuestions && this.state.selectedQuestions.map((selected, i) => { 
                                        if (question.id === selected) {
                                            return (
                                                <span key={i} className="text-white badge bg-green">
                                                    {question.questionCode} {' '}
                                                    <span onClick={this.removeSelectedQuestion.bind(this, selected)}>
                                                        <i className="fas fa-times"></i>
                                                    </span>
                                                </span>
                                            )
                                        } else return null
                                    })
                                )
                            })}
                        </div>
                    
                        <small>Choose question here:</small>
                        <br/>
                        <div className="row col-md-5 btn-group dropdown">
                            <button type="button" className="text-left btn border-black dropdown-toggle" data-toggle="dropdown">
                                { this.state.selectedQuestionCode ? this.state.selectedQuestionCode : 'Choose Question'}
                            </button>

                            <div className="dropdown-menu" >
                                <span className="dropdown-item" onClick={this.handleSelectedQuestion.bind(this, '', '')} disabled>QUESTIONS</span>
                                <span className="dropdown-item"><input placeholder="Search code..." className="form-control"  onChange={event => this.setState({searchQuiz_Question: event.target.value})}/></span>
                                { filteredQuestions && filteredQuestions.map((question,i) => {
                                    return (
                                        <span key={i} className="dropdown-item" onClick={this.handleSelectedQuestion.bind(this, question.id, question.questionCode)}>{question.questionCode}</span>
                                    )
                                })}
                            </div>
                        </div>
                    </div>

                    { isLoading === true ? 
                        <small>Loading...</small>
                    : 
                        message  ?
                            <small className={message.type === 'addQuizError' ? "text-red" : message.type === 'addQuizSuccess'  ? "text-green" : null }>
                                {message.msg}
                            </small>
                        : null
                    }

                    <div className="mt-5 row justify-content-end">
                        <button type="button"
                            className="btn m-2 btn-outline-black"
                            onClick={this.addQuiz.bind(this)}><i className="fas fa-plus-circle"/>{' '}add quiz
                        </button>
                    </div>
                </form>

                <div hidden={true}>
                    <Link id="clickAddQuiz" to={`/systemmanager?topic=course&subtopic=quiz&id=${this.state.addedQuizId}&code=${this.state.quizCode}`} />
                </div>
            </div>
        
        )
    }
}

const mapStateToProps = (state) => {
  return {
      addQuizError: state.courses.addQuizError,
      addQuizSuccess: state.courses.addQuizSuccess,
  }
}

const mapDispatchToProps = (dispatch) => {
    return {
      addQuiz: (quiz) => dispatch(addQuiz(quiz))
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Quiz);