import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import shortid from 'shortid';
import { connect } from 'react-redux'
import { storage } from '../../../config/fbConfig'

import { addTopic } from '../../../stores/actions/courseAction';

class Topic extends Component{
    
    constructor(props){
        super(props)
        this.state = {
            topicName:'',
            topicCode:'',
            subtopics:[],
            selectedNote: null,
            selectedVideo: null,
    
            getSubtopic:'',
    
            note: null,
            progressNote: '',
    
            videoDisplay: null,
            video: null,
            progressVideo: '',

            videoName: '',
            videoUrl: '',
            videoType: '',

            isLoading: null,
            message: null,
            addedTopicId:''
        }
        this.baseState = this.state 
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    componentDidUpdate = (prevProps, prevState) => {

        const { addTopicError, addTopicSuccess } = this.props;

        if(prevProps.addTopicError !== addTopicError) {
            this.setState({
                isLoading: false,
                message: {
                    msg: addTopicError.message,
                    type: addTopicError.type,
                }
            })
        }

        if(prevProps.addTopicSuccess !== addTopicSuccess) {
            this.setState({
                isLoading: false,
                message: {
                    msg: addTopicSuccess.message,
                    type: addTopicSuccess.type,
                },
                addedTopicId: addTopicSuccess.id
            }, () => {
                setTimeout(() => { 
                    this.props.hide();
                    document.getElementById("clickAddTopic").click();
                    setTimeout(() => { 
                        this.setState(this.baseState)
                    }, 3000);
                }, 2000);
            })
        }
    }

    addTopic = async () => {

        await this.updateSelectedVideo();

        var result = window.confirm("Confirm add?");
        if (result) {
            this.setState({
                isLoading: true
            })
            this.props.addTopic(this.state);
        }
    }

    addSubtopic = (e) => {
        let id = shortid.generate();

        this.setState({
            subtopics: [ 
                ...this.state.subtopics,
                {
                id: id,
                subtopic: this.state.getSubtopic, 
                },
            ],
        });
        this.refs.subtopic.value = '';
    }

    removeSubtopic = (id) => {
        let removedSubtopic = this.state.subtopics.filter(subtopic => {
            return subtopic.id !== id
        });
        this.setState({
            subtopics: removedSubtopic,
        })
    }

    handleSelectedNote = (e) => {
        if(e.target.files[0]) {
            this.setState({
                note: e.target.files[0],
                progressNote: ''
            });
        }
    }

    handleSelectedVideo = (e) => {
        if(e.target.files[0]) {
            this.setState({
                video: e.target.files[0],
                videoDisplay: URL.createObjectURL(e.target.files[0]), //ubah imgUrl to pic
                progressVideo: ''
            });
        }
    }

    executeSelectNote = () => {

        const { note } = this.state;

        if (this.state.note !== null ) {
            const uploadNote = storage.ref(`documents/${note.name}`).put(note);

            uploadNote.on('state_changed', 
                (snapshot) => {
                    const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    this.setState({
                        progressNote: progress
                    });
                }, 

                (error) => {
                    alert(error)
                }, 

                () => {
                    storage.ref('documents').child(note.name).getDownloadURL().then(fileUrl => {
                    
                        this.setState({
                            selectedNote: {
                                fileUrl: fileUrl,
                                fileName: note.name
                            }
                        });
                    })
                }
            );
        } 
    }

    updateSelectedVideo = async () => {

        let { videoType, videoName, videoUrl } = this.state;

        this.setState({
            selectedVideo: {
                videoType,
                fileName: videoType === "Youtube" ? "Youtube link" : videoName,
                fileUrl: videoUrl,
            }
        })
    }

    executeSelectVideo = () => {

        const { video } = this.state;

        if (this.state.video !== null) {
            const uploadVideo = storage.ref(`videos/${video.name}`).put(video);

            uploadVideo.on('state_changed', 
                (snapshot) => {
                    const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    this.setState({
                        progressVideo: progress
                    });
                }, 

                (error) => {
                    alert(error)
                }, 

                () => {
                    storage.ref('videos').child(video.name).getDownloadURL().then(fileUrl => {
                        this.setState({
                            videoName: video.name,
                            videoUrl: fileUrl
                        });
                    })
                }
            );
        }
    }

    render(){

        const { message, isLoading } = this.state;  

        return(

            <div>
                <div className="row">  
                    <p className="col col-md-3">
                        <b>Code:</b>
                        <input type="text" id="topicCode" onChange={this.handleChange} className="form-control" value={this.state.topicCode}  placeholder="Code"/>
                    </p>
                    <p className="col">
                        <b>Name:</b>
                        <input type="text" id="topicName" onChange={this.handleChange} className="form-control" value={this.state.topicName}  placeholder="Name"/>
                    </p>
                </div>
                
                <p className="mt-4">
                    <b>Subtopics: </b>

                    <textarea type="text" className="form-control" ref="subtopic" value={this.state.subtopic} onChange={event => this.setState({getSubtopic: event.target.value})}  placeholder="Subtopic title"/>
                    <span className="badge bg-blue text-white" onClick={this.addSubtopic.bind(this)}>Add Subtopic</span>

                    { this.state.subtopics && this.state.subtopics.map((subtopic, i) => {
                        return (
                            <li key={i} style={{margin:'20px'}}>
                                {subtopic.subtopic} {' '}
                                <span onClick={this.removeSubtopic.bind(this, subtopic.id)}> <i className="fas fa-trash text-red" /></span>
                            </li>
                        )
                    })}
                </p>

                <br/>

                <small className="alert alert-danger"><i className="fas fa-exclamation-triangle"/> {' '}After done upload for each below, please wait for a few seconds before proceed to "Add".</small>

                <br/><br/>

                <p className="mt-2">
                    <b>Note: </b><br />
                    <small><i>**PDF file only</i></small><br/>
                    <input className="mt-2" type="file" onChange={this.handleSelectedNote.bind(this)} />

                    { this.state.progressNote === 100 ?
                        <span className="badge text-white bg-green">Done Upload</span>
                    : this.state.progressNote > 1 && this.state.progressNote < 100 ?
                        <span className="badge text-white bg-yellow">Loading...</span>
                    :
                        <span type="button" className="badge text-white bg-blue"onClick={this.executeSelectNote.bind(this)}>Upload Note</span>
                    }
                </p>

                <div className="mt-4 mb-5">
                    <b>Video: </b><br/>
                    
                    <select id="videoType" onChange={this.handleChange} className="form-control">
                        <option defaultValue>Choose type</option>
                        <option>Youtube</option>
                        <option>Upload</option>
                    </select>

                    { this.state.videoType && this.state.videoType === "Upload" ? 
                        <div>
                             { this.state.videoDisplay ?
                                <div><video width="250" height="150" src={this.state.videoDisplay} type="video/mp4" controls /></div>
                            : null }
                            <input className="mt-2" type="file" onChange={this.handleSelectedVideo.bind(this)} />
                            
                            { this.state.progressVideo === 100 ?
                                <span className="badge text-white bg-green">Done Upload</span>
                            : this.state.progressVideo > 1 && this.state.progressVideo < 100 ?
                                <span className="badge text-white bg-yellow">Loading...</span>
                            :
                                <span type="button" className="badge text-white bg-blue"onClick={this.executeSelectVideo.bind(this)}>Upload Video</span>
                            }
                        </div>
                    :  this.state.videoType && this.state.videoType === "Youtube" ? 
                        <div>
                            <input className="form-control" type="text" id="fileUrl" placeholder='Youtube URL' onChange={e => this.setState({videoUrl: e.target.value})} />
                        </div>
                    : null }
                   
                </div>

                { isLoading === true ? 
                    <small>Loading...</small>
                : 
                    message  ?
                        <small className={message.type === 'addTopicError' ? "text-red" : message.type === 'addTopicSuccess'  ? "text-green" : null }>
                            {message.msg}
                        </small>
                    : null
                }

                <div className="mt-5 row justify-content-end">
                    <button type="button" className="btn m-2 btn-outline-black"onClick={this.addTopic.bind(this)}><i className="fas fa-plus-circle"/>{' '}add topic</button>
                </div>

                <div hidden={true}>
                    <Link id="clickAddTopic" to={`/systemmanager?topic=course&subtopic=topic&id=${this.state.addedTopicId}&code=${this.state.topicCode}`} />
                </div>
            </div>

        )
    }
}

const mapStateToProps = (state) => {
  return {
      addTopicError: state.courses.addTopicError,
      addTopicSuccess: state.courses.addTopicSuccess,
  }
}

const mapDispatchToProps = (dispatch) => {
    return {
      addTopic: (topic) => dispatch(addTopic(topic))
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Topic);