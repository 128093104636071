import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'

import { PDFDownloadLink } from '@react-pdf/renderer'
import { BatchPdf } from "../admin_admin/PhysicalClass/batchData/BatchGenerator";

class CourseTimestamp extends Component{

    state = {}

    render(){

        const { modules, examEnrollments, moduleEnrollments, users } = this.props;

        const query = new URLSearchParams(this.props.location.search);

        let query_userId = query && query.get('userId');
        let query_courseId = query && query.get('courseId');

        let moduleTimestamp = [], examTimestamp = [];

        moduleEnrollments && moduleEnrollments.map(enrolled => {
            if(query_courseId === enrolled.courseId && query_userId === enrolled.userId) {
                moduleTimestamp.push(
                    {
                        name: users && users.map(user => { return user.firstname + ' ' + user.lastname }),
                        email: users && users.map(user => { return user.email }),
                        loginTimestamp: enrolled.startDate ? enrolled.startDate : "",
                        logoutTimestamp: enrolled.finishDate ? enrolled.finishDate : "",
                        type: 'module',
                        moduleId: enrolled.moduleId,
                        indexKey: enrolled.indexKey
                    }
                )
            } return null
        })

        const groupedModule = moduleTimestamp.length > 0 && moduleTimestamp.reduce((acc, obj) => {
            const key = obj.moduleId;
            if (!acc[key]) {
              acc[key] = [];
            } else return null
            acc[key].push(obj);
            return acc;
        }, {});

        const mappedModule = Object.entries(groupedModule).map(([key, value]) => {
            return {
              category: key,
              items: value
            };
          });

       
        examEnrollments && examEnrollments.map(enrolled => {
            if(query_courseId === enrolled.courseId && query_userId === enrolled.userId) {
                examTimestamp.push(
                    {
                        name: users && users.map(user => { return user.firstname + ' ' + user.lastname }),
                        email: users && users.map(user => { return user.email }),
                        loginTimestamp: enrolled.startDate ? enrolled.startDate : "",
                        logoutTimestamp: enrolled.finishDate ? enrolled.finishDate : "",
                        type: 'exam',
                    }
                )
            } return null
        })



        return(

        <div className='min-height m-5'>

            <PDFDownloadLink 
                document={ 
                    modules && mappedModule && examTimestamp ?
                        <BatchPdf type='login-data' modules={modules} mappedModule={mappedModule} examTimestamp={examTimestamp} />
                    : null 
                } 
                fileName={`user_timestamp.pdf`}>
                {({ blob, url, loading, error }) => (loading ? <button className='btn btn-disable'>Loading PDF...</button> : <button className='btn all-btn bg-primary'><i className="fas fa-download"/>{' '}User Timestamp as PDF</button>)}
            </PDFDownloadLink>

            <div className='mt-5'>
                { mappedModule && mappedModule.map(mapped => {
                    return (
                        modules && modules.map(module => {
                            if(module.id === mapped.category) {
                                return (
                                    <>
                                        <label><b>Topic :</b> {module.moduleName}</label><br/>

                                        <table className='bg-white table'>
                                            <thead className='bg-white'>
                                                <tr>
                                                    <th>No.</th>
                                                    <th className='text-left'>Name</th>
                                                    <th className='text-left'>Email</th>
                                                    <th>Log In</th>
                                                    <th>Log Out</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                { mapped.items && mapped.items.map((item,key) => {
                                                    return (
                                                        <tr key={key}>
                                                            <td>{key+1}</td>
                                                            <td className='text-left'>{item.name}</td>
                                                            <td className='text-left'>{item.email}</td>
                                                            <td>{item.loginTimestamp !== "" ? item.loginTimestamp : <b className='text-red'>No data</b>}</td>
                                                            <td>{item.logoutTimestamp !== "" ? item.logoutTimestamp : <b className='text-red'>No data</b>}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                        <br/><br/><br/>
                                    </>
                                )
                            } else return null
                        })
                    )
                })}

                { examTimestamp.length > 0 ?
                    <>
                        <label><b>Topic :</b> Examination</label><br/>

                        <table className='bg-white table'>
                            <thead className='bg-white'>
                                <tr>
                                    <th>No.</th>
                                    <th className='text-left'>Name</th>
                                    <th className='text-left'>Email</th>
                                    <th>Log In</th>
                                    <th>Log Out</th>
                                </tr>
                            </thead>
                            <tbody>
                                {  examTimestamp.length > 0 && examTimestamp.map((exam,key) => {
                                    return (
                                        <tr key={key}>
                                            <td>{key+1}</td>
                                            <td className='text-left'>{exam.name}</td>
                                            <td className='text-left'>{exam.email}</td>
                                            <td>{exam.loginTimestamp !== "" ? exam.loginTimestamp : <b className='text-red'>No data</b>}</td>
                                            <td>{exam.logoutTimestamp !== "" ? exam.logoutTimestamp : <b className='text-red'>No data</b>}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        <br/><br/><br/>
                    </>
                : null }

                { mappedModule.length < 1 && examTimestamp.length < 1 ? 'Data loading...' : null }


            </div>
        </div>
        
        )
    }
}

const mapStateToProps = (state, ownProps) => {

    const query = new URLSearchParams(ownProps.location.search);

    return {

        users: state.firestore.ordered.users,
        modules: state.firestore.ordered.modules,
        moduleEnrollments: state.firestore.ordered.moduleEnrollments,
        examEnrollments: state.firestore.ordered.examEnrollments,

        query_courseType: query.get('courseType'),
        query_courseId: query.get('courseId'),
        query_userId: query.get('userId'),

    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect(props => [
        { collection: 'modules', orderBy:'moduleCode' },
        { collection: 'users', where: props.query_userId ? ['userId', '==', props.query_userId] : null },
        { collection: 'moduleEnrollments', where: props.query_courseId ? [ ['userId', '==', props.query_userId], ['courseId', '==', props.query_courseId] ] : null, orderBy:'indexKey' },
        { collection: 'examEnrollments', where: props.query_courseId ? [ ['userId', '==', props.query_userId], ['courseId', '==', props.query_courseId] ] : null },
    ])
)(CourseTimestamp);