import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import queryString from 'query-string';

import { updatePurchaseStatus } from '../../stores/actions/toyyibpayAction';
import { newEnrollment } from '../../stores/actions/enrollmentAction';

class ReturnPaymentStatus extends Component{

    state = {
        course_id: '',
        status_id: '',
        billcode: '',
        order_id: '',
        transaction_id: '',
        counter: null,
        redirectCounter: null,
    }
    
    componentDidUpdate = (prevProps, prevState) => { 
        const { updatePurchaseStatusError } = this.props;

        if(prevProps.updatePurchaseStatusError !== updatePurchaseStatusError) {
            alert("An error occured! Please contact admin.")
        }
    }

    startCountdownRedirect = (seconds) => {
        let redirectCounter = seconds;
        
        setInterval(async () => {
            redirectCounter--;
            this.setState({ redirectCounter })
            
            if (redirectCounter === 0 ) {
                // this.props.history.push('/enrollment')
                window.location.href = '/enrollment';
            }
        }, 1000);

    }

    startCountdown = (seconds) => {
        let counter = seconds;
        
        const interval = setInterval(async () => {
            counter--;
            this.setState({ counter })
            
            if (counter === 0 ) {
                clearInterval(interval);
                this.startCountdownRedirect(10);
                this.updatePurchase();
            }
        }, 1000);
    }

    componentDidMount = () => {
        let url = this.props.location.search;
        let params = queryString.parse(url);

        const { status_id, billcode, order_id, transaction_id} = params;
        const course_id = this.props.match.params.course_id;

        this.setState({
            course_id, status_id, billcode, order_id, transaction_id
        })

        this.startCountdown(5);
    }

    updatePurchase = async () => {
        const { purchases } = this.props;
        const { course_id, status_id, billcode, order_id, transaction_id } = this.state;

        purchases && purchases.map(purchase => {
            if(purchase.courseId === course_id && purchase.billCode === billcode) {
                let updatePurchaseStatus = {
                    paymentStatus: status_id,
                    orderId: order_id,
                    transactionId: transaction_id,
                }
                this.props.updatePurchaseStatus(updatePurchaseStatus, purchase.id)
            }
            return null
        })
    }

    render(){
        
        return(

            <div className="container col-md-12 justify-content-center middle min-height">
                <div className="page-bgstyle text-center col-md-6">
                    <div className="middle">
                        <div style={{marginBottom:"30px"}}> 
                            <img src="../../../image/HA_LOGO.png" alt="" width="20%"/><br />
                        </div>
                    </div>

                    <div className="middle" style={{margin:"40px 0px 40px 0px"}}>
                        { this.state.counter === 0 ?
                            <div>
                                <div>
                                    { this.state.status_id === '1' ?
                                        <h6 className="text-green">PAYMENT SUCCESS</h6>
                                    : this.state.status_id === '2' ?
                                        <h6 className="text-yellow">PAYMENT PENDING</h6>
                                    : this.state.status_id === '3' ?
                                        <h6 className="text-red">PAYMENT FAILED</h6>
                                    : 
                                        <h6 className="text-red">PAYMENT STATUS NOT FOUND. PLEASE CONTACT ADMIN</h6>
                                    }
                                </div>

                                <div style={{marginTop:"50px"}}>
                                    <a href="/enrollment" className="btn all-btn">Go to My Enrollments page {' '} <i className="fas fa-arrow-circle-right"/> </a><br/>
                                    <p> **Automatically redirect to page My Enrollments in <b>{this.state.redirectCounter}</b> second(s)</p>
                                    <br/><br/>
                                    <p className="text-left col-md-8" style={{textAlign:'justify'}}>
                                        <b>Note:</b><br/>
                                        It may take up to 3 minutes for the purchased course/module to appear on the My Enrollments page.
                                    </p>
                                </div>
                            </div>
                        : 
                            <div className="middle">
                                <div className="loader"></div>
                                <div style={{marginTop:"30px"}}>
                                    <p>
                                        <b className="text-red">DO NOT CLOSE THIS PAGE</b> <br/>
                                        Page is loading, please wait. This may take up to {this.state.counter} second(s)
                                    </p>
                                </div> 
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        updatePurchaseStatusError: state.toyyibpay.updatePurchaseStatusError,

        profile: state.firebase.profile,
        users: state.firestore.ordered.users,
        courses: state.firestore.ordered.courses,
        purchases: state.firestore.ordered.purchases,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
      updatePurchaseStatus: (data, purchaseId) => dispatch(updatePurchaseStatus(data, purchaseId)),
      newEnrollment: (enrollment) => dispatch(newEnrollment(enrollment)),
    }
}
  
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect([
        { collection: 'users'},
        { collection: 'purchases'},
        { collection: 'courses'},
    ])
)(ReturnPaymentStatus);