import React, { Component } from 'react';
import { connect } from 'react-redux'
import { storage } from '../../../config/fbConfig'
import { addHelpInfo } from '../../../stores/actions/infoAction';

class HelpInfo extends Component{
    
    constructor(props){
        super(props)
        this.state = {
            infoType:'HELP INFO',
            infoName:'',
    
            selectedHelpFile:'',
            helpFile: null,
            progress: '',
            videoDisplay: null,

            attachType:'',
    
            isLoading: null,
            message: null,
        }
        this.baseState = this.state 
    }
    
    componentDidUpdate = (prevProps, prevState) => { 
        const { addHelpError, addHelpSuccess } = this.props;

        if(prevProps.addHelpError !== addHelpError) {
            this.setState({
                isLoading: false,
                message: {
                    msg: addHelpError.message,
                    type: addHelpError.type,
                }
            })
        }

        if(prevProps.addHelpSuccess !== addHelpSuccess) {
            this.setState({
                isLoading: false,
                message: {
                    msg: addHelpSuccess.message,
                    type: addHelpSuccess.type,
                }
            })
            setTimeout(() => { 
                this.setState(this.baseState)
                this.props.hide();
            }, 2000);
        }
    } 

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    selectedAttachType = (e) => {
        this.setState({
            attachType:  e.target[e.target.selectedIndex].id,
        })
    }

    helpFile = (e) => {
        if(e.target.files[0]) {
            this.setState({
                helpFile: e.target.files[0],
            });
    
            if(this.state.attachType === "video") {
                this.setState({
                    videoDisplay: URL.createObjectURL(e.target.files[0]) //ubah imgUrl to pic
                });
            }
        }
    }

    addHelpInfo = () => {

        const { helpFile, attachType } = this.state;

        var result = window.confirm("Confirm add?");

        if (result) {
            this.setState({
                isLoading: true
            })
            if (helpFile !== null) {
                let ref = null, ref2 = null;

                if(attachType === "pdf") {
                    ref = `documents/${helpFile.name}`
                    ref2 = 'documents'
                } else if (attachType === "video") {
                    ref = `videos/${helpFile.name}`
                    ref2 = 'videos'
                }

                const uploadTask = storage.ref(ref).put(helpFile);
    
                uploadTask.on('state_changed', 
                    (snapshot) => {
                        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                        this.setState({
                            progress: progress
                        });
                    }, 
    
                    (error) => {
                        alert(error)
                    }, 
    
                    () => {
                        storage.ref(ref2).child(helpFile.name).getDownloadURL().then(fileUrl => {
                        
                            this.setState({
                                selectedHelpFile: {
                                    fileUrl: fileUrl,
                                    fileName: helpFile.name,
                                    fileType: attachType
                                }
                            });
    
                            this.props.addHelpInfo(this.state);
                        })
                    }
                );
            } else {
                this.props.addHelpInfo(this.state);
            }
        }
    }


    render(){
        
        const { message, isLoading } = this.state;

        return(

            <div>
                <b>TITLE:</b><br />
                <input type="text" id="infoName" onChange={this.handleChange.bind(this)} className="form-control" placeholder="Title"/>
                <br/>
                <b>ATTACHMENT:</b>
                <br />
                <select className="mb-2 col col-md-6 form-control" onChange={this.selectedAttachType.bind(this)}>
                    <option defaultValue>Choose attachment type</option>
                    <option id="video" >Video</option>
                    <option id="pdf">PDF</option>
                </select>
                <br />
                { this.state.helpFile && this.state.attachType === "video" ?
                    <div><video width="300" height="200" src={this.state.videoDisplay} type="video/mp4" controls /></div>
                : null }
                <input className="mt-2" type="file" onChange={this.helpFile.bind(this)} /> <br/>
                <progress value={this.state.progress} max="100" style={{width:"250px"}}/>

                <br/>
            
                { isLoading === true ? 
                    <small>Loading...</small>
                : 
                    message  ?
                        <small className={message.type === 'addHelpError' ? "text-red" : message.type === 'addHelpSuccess'  ? "text-green" : null }>
                            {message.msg}
                        </small>
                    : null
                }

                <div className="mt-5 row justify-content-end">
                    <button type="button" className="btn m-2 btn-outline-black" onClick={this.addHelpInfo.bind(this)}><i className="fas fa-plus-circle"/>{' '}add help info</button>
                </div>
            </div>
        
        )
    }
}

const mapStateToProps = (state) => {
  return {
      addHelpError: state.infos.addHelpError,
      addHelpSuccess: state.infos.addHelpSuccess,
  }
}

  
const mapDispatchToProps = (dispatch) => {
    return {
      addHelpInfo: (helpInfo) => dispatch(addHelpInfo(helpInfo))
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(HelpInfo);