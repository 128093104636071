import React, { Component } from "react";
import { connect } from "react-redux";
import { signUp } from "../../stores/actions/authAction";
import moment from "moment";

class SignUp extends Component {
  state = {
    email: "",
    identityCardNo: "",
    dob: "",
    password: "",
    fullname: "",
    firstname: "",
    lastname: "",
    confirm_password: "",
    company: "",

    message: null,
    isLoading: null,
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { signupError, signupSuccess } = this.props;

    if (prevProps.signupError !== signupError) {
      this.setState({
        isLoading: false,
        message: {
          msg: signupError.message,
          type: signupError.type,
        },
      });
    }

    if (prevProps.signupSuccess !== signupSuccess) {
      this.setState({
        isLoading: false,
        message: {
          msg: signupSuccess.message,
          type: signupSuccess.type,
        },
      });

      var result = window.confirm(signupSuccess.message);
      if (result) {
        this.props.hide("hideSignUp");
      }
    }
  };

  updateData = async () => {
    let formatted = moment(this.state.dob).format("YYYY-MM-DD");

    this.setState({
      email: this.state.email.toLowerCase(),
      dob: formatted,
      fullname: this.state.fullname.toUpperCase(),
      firstname: this.state.firstname.toUpperCase(),
      lastname: this.state.lastname.toUpperCase(),
    });
  };

  register = async () => {
    await this.updateData();

    if (this.state.password !== this.state.confirm_password) {
      this.setState({
        message: {
          msg: "Password did not match.",
          type: "signupError",
        },
      });
    } else {
      this.setState({
        isLoading: true,
      });
      this.props.signUp(this.state);
    }
  };

  showPassword = (type) => {
    var x =
      type === "pass1"
        ? document.getElementById("pass_signup1")
        : document.getElementById("pass_signup2");
    var y =
      type === "pass1"
        ? document.getElementById("eye1")
        : document.getElementById("eye2");

    if (x.type === "password") {
      x.type = "text";
      y.className = "fas fa-eye-slash";
    } else {
      x.type = "password";
      y.className = "fas fa-eye";
    }
  };

  render() {
    const { message, isLoading, password } = this.state;

    return (
      <div className="enter-form">
        <b className="middle">
          Account Registration
          <div className="row justify-content-center">
            <div className="title-border"></div>
          </div>
        </b>

        <div className="m-0 p-0 row col-md-12">
          <div className="col m-0 p-0 mr-2 input-group">
            <span className="middle">
              <i className="fas fa-user"></i>
            </span>
            <input
              type="text"
              name="fullname"
              onChange={this.handleChange}
              className="form-control"
              placeholder="Full Name"
            />
          </div>
        </div>
        <small style={{ marginLeft: "3em" }}>
          <b className="text-red">Ex: ALI BIN ABU (Full name is for certification purposes)</b>
        </small>

        <div className="m-0 mt-2 p-0 row col-md-12">
          <div className="col m-0 p-0 mr-2 input-group">
            <span className="middle">
              <i className="fas fa-user"></i>
            </span>
            <input
              type="text"
              name="firstname"
              onChange={this.handleChange}
              className="form-control"
              placeholder="First Name"
            />
          </div>

          <div className="col m-0 p-0  input-group">
            <span className="middle">
              <i className="fas fa-user"></i>
            </span>
            <input
              type="text"
              name="lastname"
              onChange={this.handleChange}
              className="form-control"
              placeholder="Last Name"
            />
          </div>
        </div>
        <small style={{ marginLeft: "3em" }}>
          <b className="text-red">Please input name as per IC/Passport</b>
        </small>

        <div className="mt-2 input-group">
          <span className="middle">
            <i className="fas fa-envelope"></i>
          </span>
          <input
            type="email"
            name="email"
            onChange={this.handleChange}
            className="form-control"
            placeholder="Email"
          />
        </div>

        <div className="input-group">
          <span className="middle">
            <i className="fas fa-building"></i>
          </span>
          <input
            type="text"
            name="company"
            onChange={this.handleChange}
            className="form-control"
            placeholder="Organization / Institution"
          />
        </div>

        <div className="input-group" title="Date of Birth">
          <span className="middle">
            <i className="fas fa-birthday-cake"></i>
          </span>
          <input
            type="date"
            name="dob"
            onChange={this.handleChange}
            className="form-control"
          />
        </div>

        <div className="input-group">
          <span className="middle">
            <i className="fas fa-id-card"></i>
          </span>
          <input
            type="text"
            name="identityCardNo"
            onChange={this.handleChange}
            className="form-control"
            placeholder="Identity Card Number"
          />
        </div>
        <small style={{ marginLeft: "3em" }}>
          <b className="text-red">Format: XXXXXX-XX-XXXX</b>
        </small>

        <div className="mt-2 input-eye input-group">
          <span className="middle">
            <i className="fas fa-lock"></i>
          </span>
          <input
            type="password"
            id="pass_signup1"
            name="password"
            onChange={this.handleChange}
            className="form-control"
            placeholder="Password"
          />
          <div
            onClick={this.showPassword.bind(this, "pass1")}
            className="icon-eye"
          >
            <i id="eye1" className="fas fa-eye"></i>
          </div>
        </div>
        {password && password.length > 0 && password.length < 5 ? (
          <small style={{ marginLeft: "3em" }} className="text-red">
            Weak password
          </small>
        ) : password &&
          password.length > 4 &&
          password &&
          password.length < 8 ? (
          <small style={{ marginLeft: "3em" }} className="text-warning">
            Password OK
          </small>
        ) : password && password.length > 7 ? (
          <small style={{ marginLeft: "3em" }} className="text-green">
            Strong password
          </small>
        ) : null}

        <div className="mb-2 input-eye input-group">
          <span className="middle">
            <i className="fas fa-lock"></i>
          </span>
          <input
            type="password"
            id="pass_signup2"
            name="confirm_password"
            onChange={this.handleChange}
            className="form-control"
            placeholder="Confirm Password"
          />
          <div
            onClick={this.showPassword.bind(this, "pass2")}
            className="icon-eye"
          >
            <i id="eye2" className="fas fa-eye"></i>
          </div>
        </div>

        {isLoading === true ? (
          <small>Loading...</small>
        ) : message ? (
          <small
            className={
              message.type === "signupError"
                ? "text-red"
                : message.type === "signupSuccess"
                ? "text-green"
                : null
            }
          >
            {message.msg}
          </small>
        ) : null}

        <div className="mt-3 row justify-content-end">
          {isLoading === true ? (
            <button type="button" className="btn btn-disable">
              <i className="fas fa-user-plus"></i>
              {"  "}Register
            </button>
          ) : (
            <button
              type="button"
              onClick={this.register.bind(this)}
              className="btn all-btn"
            >
              <i className="fas fa-user-plus"></i>
              {"  "}Register
            </button>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    signupError: state.auth.signupError,
    signupSuccess: state.auth.signupSuccess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signUp: (newUser) => dispatch(signUp(newUser)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
