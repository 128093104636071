import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import { deleteProgram } from '../../../stores/actions/courseAction';

import EditProgram from './EditProgram';

class DisplayProgram extends Component{

    state = {
        hideDetails : false,
        hideEdit: true,
        id:'',
    }
    
    componentDidUpdate = (prevProps, prevState) => { 

        const { deleteProgramError, deleteProgramSuccess } = this.props;

        if(prevProps.deleteProgramError !== deleteProgramError) {
            alert(deleteProgramError.message);
        }
        
        if(prevProps.deleteProgramSuccess !== deleteProgramSuccess) {
            alert(deleteProgramSuccess.message);
            document.getElementById("clickProgram").click();
        }
    } 

    handleEdit = (program_id) => {
        this.setState({
          hideDetails : true,
          hideEdit: false,
          id: program_id
        })
    }

    cancel = () => {
        this.setState({
            id:''
        })
    }

    deleteProgram = (program_id) => {

        var result = window.confirm("Confirm delete?");
    
        if (result) {
            this.props.deleteProgram(program_id)
        }
    }


    render(){

        const { query, program, program_id, courses } = this.props;

        let query_id = query.get('id');
        let isLoading = true;

        const programDetails = program ? (
            <div key={program.id}>
                { isLoading = false }
                { this.state.id === program_id ?
                    <EditProgram courses={courses} cancel={this.cancel} program={program} program_id={program_id} />
                :
                    <div>
                        <div className="row">        
                            <p className="col col-md-3">
                                <b>Code: </b>
                                {program.programCode}
                            </p>

                            <p className="col col-md-6">
                                <b>Name: </b>
                                {program.programName}
                            </p>

                            <p className="col col-md-3">
                                <b>Status: </b>
                                {program.programStatus}
                            </p>
                        </div>

                        <br/>

                        <div><b>Courses: </b>
                            <div style={{marginLeft:"20px"}}>
                                {courses && courses.map((course,i) => {
                                    return (
                                        program.selectedCourses && program.selectedCourses.map(id => {
                                            if (course.id === id ) {
                                                return (
                                                    <li key={i}> {course.courseCode} - {course.courseName}</li>
                                                )
                                            }
                                            return null
                                        })
                                    )
                                })}
                            </div>
                        </div>

                        <div className="mt-4"><b>Content: </b><br/><p dangerouslySetInnerHTML={{__html: program.programContent}}></p></div>

                        <div className="mt-4">
                            <b>Featured Image: </b><br />
                            { program.programImage ?
                                <div>
                                    <img src={program.programImage.fileUrl} alt="program" width="30%" className="text-center"/><br/>
                                    <label>{program.programImage.fileName}</label>
                                </div>
                            : 
                                'No selected image.'
                            }
                        </div>

                        <div className="mt-5 row justify-content-end">
                            <button type="button" className="btn m-2 btn-outline-black" onClick={this.handleEdit.bind(this, program_id)}><i className="fas fa-edit"></i></button>
                            <button type="button" className="btn m-2 btn-outline-red" onClick={this.deleteProgram.bind(this, program_id)}><i className="fas fa-trash"></i></button>
                        </div>
                    </div>
                }
            </div>

        ) : (
            null
        )
        
        return(

            <div className="container col-md-12">

                { query_id ?
                    isLoading === true ? <p>Data loading... Please wait...</p> : programDetails
                : <p>Program data not available.</p> }

                <div hidden={true}>
                    <Link id="clickProgram" to={`/systemmanager?topic=course&subtopic=program`} />
                </div>
            </div>

        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const id = ownProps.selectedProgramId;
    const programs = state.firestore.data.programs;
    const program = programs ? programs[id] : null
    return {
        program: program,
        program_id: id,
        
        deleteProgramError: state.courses.deleteProgramError,
        deleteProgramSuccess: state.courses.deleteProgramSuccess,
    }
}
  
const mapDispatchToProps = (dispatch) => {
    return {
      deleteProgram: (program_id) => dispatch(deleteProgram(program_id))
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(DisplayProgram)