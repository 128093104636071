import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'

import { addNewPurchase } from '../../stores/actions/toyyibpayAction';
import { updateContactNo } from '../../stores/actions/userAction';


class ConfirmPurchase extends Component{

    state = {
        isLoading: false,
        contactNo:'',

        emp_price:'',
        paxNo:1,
    }
    
    componentDidUpdate = (prevProps, prevState) => { 
        const { newPurchaseError } = this.props;

        if(prevProps.newPurchaseError !== newPurchaseError) {
            alert("An error occured! Please contact admin.")
        }
    }

    checkExistContactNo = () => {
        if(this.props.profile.contactNo === "") {
            document.getElementById("modal-update-contact").style.display = "block";
        } else {
            this.newPurchase();
        }
    }

    updateContactNo = () => {
        this.props.updateContactNo(this.state.contactNo, this.props.profile.userId)
        document.getElementById("modal-update-contact").style.display = "none";
        alert('Phone number updated.')
    }

    closeModalPhone = () => {
        document.getElementById("modal-update-contact").style.display = "none";
    }

    getEnrolledCourseId = async (course_id, userId) => {
        const { courseEnrollments } = this.props;

        let enrolledCourseId = null;

        courseEnrollments && courseEnrollments.map(enrolled => {
            if(enrolled.courseId === course_id && enrolled.userId === userId) {
                enrolledCourseId = enrolled.id
            }
            return null
        })

        return enrolledCourseId;
    }

    newPurchase = async () => {
        
        const { profile, courses, employers } = this.props;
        const course_id = this.props.match.params.course_id;
        const payment_type = this.props.match.params.type;

        let purchaseData = null, courseName = "", coursePrice = "", enrollmentType = "", enrollmentCourseId = "", enrollmentModuleId = "";
        let enrolledCourseId = "";

        if(payment_type === "new-emp" && (employers && employers.length < 1) && (parseInt(this.state.paxNo) < 50 || this.state.paxNo === "")) { //xde data employer lagi ===  x pernah enroll lagi === kena 50 pax

                alert('Minimum of 50 pax');

        } else { //emp dah pernah enroll, so tak kisah minimum pax

            if(payment_type === "renew") {
                enrolledCourseId = await this.getEnrolledCourseId(course_id, profile.userId);
            }
    
            courses && courses.map(course => {
                if(course.id === course_id) {
                    enrollmentType = course.courseType;
                    courseName = course.courseName;
    
                    if(payment_type === "new") {
                        coursePrice = course.coursePrice; 
                    } else if (payment_type === "renew") {
                        coursePrice = course.extendPrice; 
                    } else if (payment_type === "new-emp") {
                        coursePrice = parseInt(course.coursePrice) * parseInt(this.state.paxNo);
                    }
                }
    
                if (course.id === course_id && course.courseType === "Professional") {
                    enrollmentCourseId = course.id;
                } 
    
                if (course.id === course_id && course.courseType === "Flexi") {
                    enrollmentCourseId = course.selectedCourse;
                    enrollmentModuleId = course.selectedModule;
                } 
                return null
            })
    
            if(payment_type === "new" || payment_type === "new-emp") {
                purchaseData = {
                    paymentType: payment_type,
                    userId: profile.userId,
                    userName: profile.firstname + " " + profile.lastname,
                    userPhone: profile.contactNo,
                    userEmail: profile.email,
                    courseId: course_id,
                    totalPrice: coursePrice,
                    //for renew account
                    enrolledCourseId: "",
                    //for enrollment purposes
                    enrollmentCourseName: courseName,
                    enrollmentCourseId,
                    enrollmentModuleId,
                    enrollmentType,
                    //update after success/pending/failed payment
                    billCode: "",
                    paymentStatus: "",
                    orderId:"",
                    transactionId:"",
                    emp_pax_no: this.state.paxNo
                }
            } else if (payment_type === "renew") {
                purchaseData = {
                    paymentType: payment_type,
                    userId: profile.userId,
                    userName: profile.firstname + " " + profile.lastname,
                    userPhone: profile.contactNo,
                    userEmail: profile.email,
                    courseId: course_id,
                    totalPrice: coursePrice,
                    //for renew account
                    enrolledCourseId: enrolledCourseId,
                    //for enrollment purposes
                    enrollmentCourseName: courseName,
                    enrollmentCourseId,
                    enrollmentModuleId: "",
                    enrollmentType: "",
                    //update after success/pending/failed payment
                    billCode: "",
                    paymentStatus: "",
                    orderId:"",
                    transactionId:"",
    
                    emp_pax_no: null,
                }
            }
    
            if(purchaseData) {
                this.props.addNewPurchase(purchaseData);
                this.setState({
                    isLoading: true
                })
            }
        }
    }

    handlePaxNo = (e, price) => {

        let course_price = parseInt(price) * parseInt(e.target.value);
        this.setState({
            [e.target.id]: e.target.value,
            emp_price: course_price
        })
    }

    render(){

        const { courses, profile } = this.props;
        const { isLoading } = this.state;

        const course_id = this.props.match.params.course_id;
        const payment_type = this.props.match.params.type;

        let courseName = "", coursePrice = "";

        courses && courses.map(course => {
            if(course.id === course_id) {
                if(payment_type === "new" || payment_type === "new-emp") {
                    courseName = course.courseName;
                    coursePrice = course.coursePrice;
                } else if (payment_type === "renew") {
                    courseName = `Renewal (${course.extendPeriod} months) - ${course.courseName}`;
                    coursePrice = course.extendPrice;
                }
            }
            return null
        })

        return(

            <div className="container col-md-12 justify-content-center middle min-height">
                <div id="modal-update-contact" className="normalmodal">
                    <div className="normalmodal-content col-md-5 text-left">
                    <span className="close" onClick={this.closeModalPhone.bind(this)}>&times;</span>
                        <div className="mt-5">
                            <small className="text-red">**Phone number is required. Please update.</small>
                            <input placeholder="Phone number" style={{margin:"10px 0px 20px 0px"}} className="form-control" type="text" onChange={event => this.setState({contactNo: event.target.value})} />
                            <div className="row justify-content-end">
                                <button className="btn all-btn" onClick={this.updateContactNo.bind(this)}>Update {' '}<i className="fas fa-check-circle"/></button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-bgstyle text-left col-md-5">
                    <div className="middle">
                        <div style={{marginBottom:"30px"}}> 
                            <img src="../../../image/HA_LOGO.png" alt="" width="30%"/><br />
                        </div>
                    </div>

                    { profile.isLoaded === true ?
                        <div>
                            <b className="mb-3 badge bg-green text-white"><i className="fas fa-user"/>{' '}Participant detail</b>
                            <br/>
                            <p>
                                <i className="fas fa-user"/>{' '}{profile.firstname}<br/>
                                <i className="fas fa-envelope-open"/>{' '}{profile.email} <br/>
                                <i className="fas fa-phone"/>{' '}{profile.contactNo === "" ? <small className="text-red"><i>Contact number not updated</i></small> : profile.contactNo}
                            </p>

                            <br/>

                            { payment_type === "new-emp" ?
                                <>
                                    <div className="row">
                                        <label className="col col-md-2">Number of pax:</label> 
                                        <input type="number" id="paxNo" onChange={e => this.handlePaxNo(e, coursePrice)} className="col col-md-2 form-control"/>
                                    </div>
                                    <small><b>Minimum</b> purchase of 50 pax</small>
                                </>
                            : null }

                            <br/>
                            <br/>

                            <b className="m-2 badge bg-green text-white"><i className="fas fa-dollar-sign"/>{' '}Item detail</b>
                            <table style={{marginTop:"3px"}} className="table">
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th className="text-left">Course Name</th>
                                        <th>Price</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr>
                                        <td>1.</td>
                                        <td className="text-left">{courseName}</td>
                                        <td>RM 
                                            { payment_type === "new-emp" ?
                                                this.state.emp_price > 0 ? this.state.emp_price : coursePrice
                                            : coursePrice }
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <div className="mt-5 row justify-content-end"> 

                                { isLoading === true ?
                                    <button className="btn btn-disable">Proceed to payment{' '}<i className="fas fa-arrow-circle-right"/></button>
                                : 
                                    <button style={{textTransform:"none"}} onClick={this.checkExistContactNo.bind(this)} className="btn all-btn">Proceed to payment{' '}<i className="fas fa-arrow-circle-right"/></button>
                                }

                                <br/>

                                { isLoading === true ? <p className="text-red">Please wait... you will be redirected to payment page.</p> : null }
                            </div>
                        </div>
                    : 
                        <div className="mt-5 row col-md-12 middle">
                            <div className="loader"></div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        newPurchaseError: state.toyyibpay.newPurchaseError,

        profile: state.firebase.profile,
        auth: state.firebase.auth,
        courses: state.firestore.ordered.courses,
        courseEnrollments: state.firestore.ordered.courseEnrollments,
        employers: state.firestore.ordered.employers,

        course_idd: ownProps.match.params.course_id
        
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
      addNewPurchase: (data) => dispatch(addNewPurchase(data)),
      updateContactNo: (contactNo, userId) => dispatch(updateContactNo(contactNo, userId)),
    }
}
  
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect(props => [
        { collection: 'employers', where: [['userId', '==', props.auth.uid ], ['courseId', '==', props.course_idd]] },
        { collection: 'courses'},
        { collection: 'courseEnrollments'},
    ])
)(ConfirmPurchase);