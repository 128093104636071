export const addFaq = (faq) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {

        const firestore = getFirestore();
        const { faqType, faqQuestion, faqAnswer, faqLink } = faq;

        if(!faqQuestion || !faqAnswer) {
            dispatch({ type:'ADDED_FAQ_ERROR', err: { message: 'Input field required.' }});
        } else {
            firestore.collection('faqs').add({
                faqType: faqType,
                faqQuestion: faqQuestion,
                faqAnswer: faqAnswer,
                faqLink: faqLink,
    
            }).then(() => {
                dispatch({ type:'ADDED_FAQ', message: 'FAQ added successfully.'});
            }).catch((err) => {
                dispatch({ type:'ADDED_FAQ_ERROR', err});
            })
        }
    }
}

export const updateFaq = (updatedFaq, faqId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => { 

        const firestore = getFirestore();
        const { faqType, faqQuestion, faqAnswer, faqLink } = updatedFaq;

        if(!faqQuestion || !faqAnswer) {
            dispatch({ type:'UPDATED_FAQ_ERROR', err: { message: 'Input field required.' }});
        } else {
            firestore.collection("faqs").doc(faqId).update({
                faqType: faqType,
                faqQuestion: faqQuestion,
                faqAnswer: faqAnswer,
                faqLink: faqLink,
            })
            .then(() => {
                dispatch({ type:'UPDATED_FAQ', message: 'FAQ updated successfully.'});
            }).catch((err) => {
                dispatch({ type:'UPDATED_FAQ_ERROR', err});
            })
        }
    }
}

export const deleteFaq = (faqId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {

        const firestore = getFirestore();

        firestore.collection("faqs").doc(faqId).delete()
        .then(() => {
            dispatch({ type:'DELETED_FAQ', message: 'FAQ deleted successfully.'});
        }).catch((err) => {
            dispatch({ type:'DELETED_FAQ_ERROR', err});
        })
    }
}