import React, { Component } from 'react';
import { Link } from 'react-router-dom'

import ManageEmail from '../EmailManager/ManageEmail'

class EmailManager extends Component{

    render(){

        const { query, users, supportEmail } = this.props;

        let query_type = query.get('type');

        return(

        <div className="admin">
            <h3  className="text-left">Email Manager
                <div className="title-border"></div>
            </h3>

            <div className="tab">
               <Link className={query_type === 'ts' ? "tablinks active" : "tablinks"} to="/systemmanager?topic=supportEmail&type=ts&filter=">Technical Support</Link>
               <Link className={query_type === 'qna' ? "tablinks active" : "tablinks"} to="/systemmanager?topic=supportEmail&type=qna&filter=">Q&A</Link>
            </div>

            <div className="tabcontent">
                <div style={{margin:"20px"}}>
                    <ManageEmail query={query} type={query_type} supportEmail={supportEmail} users={users} />
                </div>
            </div> 
        </div>

        )
    }
}
  
export default EmailManager;