const initState = {
  subtopics: [],
};

const eventsReducer = (state = initState, action) => {
  switch (action.type) {
    case "ADDED_EVENTS":
      return {
        ...state,
        addEventsSuccess: {
          message: action.message,
          type: "addEventsSuccess",
          id: action.id,
        },
      };
    case "ADDED_EVENTS_ERROR":
      return {
        ...state,
        addEventsError: {
          message: action.err.message,
          type: "addEventsError",
        },
      };

    case "REQUESTED_CERT":
      return {
        ...state,
        addEventsSuccess: {
          message: action.message,
          type: "requestCertSuccess",
          id: action.id,
        },
      };
    case "REQUESTED_CERT_ERROR":
      return {
        ...state,
        addEventsError: {
          message: action.err.message,
          type: "requestCertError",
        },
      };

    case "UPDATED_EVENTS":
      return {
        ...state,
        updateEventsSuccess: {
          message: action.message,
          type: "updateEventsSuccess",
        },
      };
    case "UPDATED_EVENTS_ERROR":
      return {
        ...state,
        updateEventsError: {
          message: action.err.message,
          type: "updateEventsError",
        },
      };

    case "UPDATED_EVENTS_ATTENDEES":
        return {
          ...state,
          updateEventsSuccess: {
            message: action.message,
            type: "updateEventsAttendeesSuccess",
          },
        };
      case "UPDATED_EVENTS_ATTENDEES_ERROR":
        return {
          ...state,
          updateEventsError: {
            message: action.err.message,
            type: "updateEventsAttendeesError",
          },
        };

    case "DELETED_EVENTS":
      return {
        ...state,
        deleteEventsSuccess: {
          message: action.message,
          type: "deleteEventsSuccess",
        },
      };
    case "DELETED_EVENTS_ERROR":
      return {
        ...state,
        deleteEventsError: {
          message: action.err.message,
          type: "deleteEventsError",
        },
      };

      case "DELETED_EVENTS_ATTENDEES":
      return {
        ...state,
        deleteEventsSuccess: {
          message: action.message,
          type: "deleteEventsAttendeesSuccess",
        },
      };
    case "DELETED_EVENTS_ATTENDEES_ERROR":
      return {
        ...state,
        deleteEventsError: {
          message: action.err.message,
          type: "deleteEventsAttendeesError",
        },
      };

    default:
      return state;
  }
};

export default eventsReducer;
