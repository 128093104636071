export const addQuestion = (question) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => { 

        const firestore = getFirestore();
        const { questionCode, questionCourse, questionModule, questionMark, questionContent1, questionContent2, answers} = question;

        if(!questionCode || !questionMark || !questionContent1 || answers.length < 2) {
            dispatch({ type:'ADDED_QUESTION_ERROR', err: { message: 'Input field required.' }});
        } else {
            firestore.collection('questions').add({
                questionCode: questionCode,
                questionCourse: questionCourse,
                questionModule: questionModule,
                questionMark: questionMark,
                questionContent1: questionContent1,
                questionContent2: questionContent2,
                answers: answers
                
            }).then(() => { 
                dispatch({ type:'ADDED_QUESTION', message: 'Question added successfully.'}); 
            }).catch((err) => {
                dispatch({ type:'ADDED_QUESTION_ERROR', err}); 
            })
        }
    }
}

export const deleteQuestion = (question_id) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => { 

        const firestore = getFirestore();

        firestore.collection("questions").doc(question_id).delete()
        .then(() => { 
            dispatch({ type:'DELETED_QUESTION', message: 'Question deleted successfully.'}); 
        }).catch((err) => {
            dispatch({ type:'DELETED_QUESTION_ERROR', err}); 
        })

    }
}

export const updateQuestion = (updatedQuestion, question_id) => {

    return (dispatch, getState, {getFirebase, getFirestore}) => {

        const firestore = getFirestore();
        const { questionCode, questionCourse, questionModule, questionMark, questionContent1, questionContent2, answers} = updatedQuestion;

        if(!questionCode || !questionMark || !questionContent1 || answers.length < 2) {
            dispatch({ type:'UPDATED_QUESTION_ERROR', err: { message: 'Input field required.' }});
        } else {
            firestore.collection("questions").doc(question_id).update({
                questionCode: questionCode,
                questionCourse: questionCourse,
                questionModule: questionModule,
                questionMark: questionMark,
                questionContent1: questionContent1,
                questionContent2: questionContent2,
                answers: answers
            })
            .then(() => { 
                dispatch({ type:'UPDATED_QUESTION', message: 'Question updated successfully.'});
            }).catch((err) => {
                dispatch({ type:'UPDATED_QUESTION_ERROR', err});
            })
        }
    }
}