import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { storage } from "../../../config/fbConfig";

import { updateEvents } from "../../../stores/actions/eventsAction";

class EditEvents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventsListId: this.props.events.eventsListId,
      eventsName: this.props.events.eventsName,
      eventsTopic: this.props.events.eventsTopic,
      eventsCode: this.props.events.eventsCode,
      eventsStatus: this.props.events.eventsStatus,
      eventsContent: this.props.events.eventsContent,
      eventsImage: this.props.events.eventsImage,
      eventsDate: this.props.events.eventsDate,

      imageNew: null,
      progress: "",
      imageDisplay: null,
      oldImageHide: false,
      newImageHide: true,

      isNewList: false,
      isLoading: null,
      message: null,
    };
    this.baseState = this.state;
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { updateEventsError, updateEventsSuccess } = this.props;

    if (prevProps.updateEventsError !== updateEventsError) {
      this.setState({
        isLoading: false,
        message: {
          msg: updateEventsError.message,
          type: updateEventsError.type,
        },
      });
    }

    if (prevProps.updateEventsSuccess !== updateEventsSuccess) {
      this.setState({
        isLoading: false,
        message: {
          msg: updateEventsSuccess.message,
          type: updateEventsSuccess.type,
        },
      });
      setTimeout(() => {
        this.props.cancel();
      }, 2000);
    }
  };

  handleChange = (e) => {
    switch (e.target.id) {
      case "eventsListId":
        const index = e.target.selectedIndex;
        const optionElement = e.target.childNodes[index];
        const optionElementId = optionElement.getAttribute("id");
        
        this.setState({
          [e.target.id]: e.target.value,
          eventsName: optionElementId,
        });
        break;
      case "eventsName":
        this.setState({
          [e.target.id]: e.target.value,
          eventsListId : "",
        });
        break;
      default:
        this.setState({
          [e.target.id]: e.target.value,
        });
        break;
    }
  };

  updateEvents = async (e) => {
    var result = window.confirm("Confirm update?");

    if (result) {
      this.setState({
        isLoading: true,
      });
      if (this.state.imageNew !== null) {
        const { imageNew } = this.state;
        const uploadTask = storage.ref(`images/${imageNew.name}`).put(imageNew);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            this.setState({
              progress,
            });
          },

          (error) => {
            console.log(error);
          },

          () => {
            storage
              .ref("images")
              .child(imageNew.name)
              .getDownloadURL()
              .then((imageUrl) => {
                this.setState({
                  eventsImage: {
                    fileUrl: imageUrl,
                    fileName: imageNew.name,
                  },
                });

                this.props.updateEvents(this.state, this.props.events_id);
              });
          }
        );
        //save vid lama je
      } else {
        this.props.updateEvents(this.state, this.props.events_id);
      }
    }
  };

  imageSelectHandler = (event) => {
    this.setState({
      imageNew: event.target.files[0],
      imageDisplay: URL.createObjectURL(event.target.files[0]),
      oldImageHide: true,
      newImageHide: false,
    });
  };

  showNewList = (status) => {
    this.setState({
      isNewList: status,
    });
  };

  render() {
    const { events, eventsList } = this.props;
    const { message, isLoading } = this.state;
    return (
      <div>
        <div className="row">
          <p className="col col-md-4">
            <b>Code:</b>
            <input
              type="text"
              id="eventsCode"
              onChange={this.handleChange}
              defaultValue={this.state.eventsCode}
              className="form-control"
            />
          </p>

          <p className="col col-md-8">
            <b>Name:</b>
            {this.state.isNewList ? (
              <>
                <input
                  type="text"
                  id="eventsName"
                  onChange={this.handleChange}
                  className="form-control"
                  value={this.state.eventsName}
                  placeholder="Name"
                />
                <span
                  className="badge bg-blue text-white"
                  onClick={this.showNewList.bind(this, false)}
                >
                  <i className="fas fa-list-ul"></i> List
                </span>
              </>
            ) : (
              <>
                <select
                  id="eventsListId"
                  onChange={this.handleChange}
                  className="form-control"
                  value={this.state.eventsListId}
                >
                  <option initialValues >Choose events name / add new </option>
                  {eventsList &&
                    eventsList.map((el, i) => {
                      return (
                        <option key={i} id={el.name} value={el.id}>
                          {el.name}
                        </option>
                      );
                    })}
                </select>
                <span
                  className="badge bg-blue text-white"
                  onClick={this.showNewList.bind(this, true)}
                >
                  <i className="fas fa-plus-circle"></i> New
                </span>
              </>
            )}
          </p>
        </div>

        <div className="row">
          <p className="col col-md-12">
            <b>Topic:</b>
            <input
              type="text"
              id="eventsTopic"
              onChange={this.handleChange}
              defaultValue={this.state.eventsTopic}
              className="form-control"
            />
          </p>
        </div>

        <div className="row">
          <p className="col col-md-4">
            <b>Status:</b>
            <select
              id="eventsStatus"
              onChange={this.handleChange}
              className="form-control"
            >
              <option defaultValue>{this.state.eventsStatus}</option>
              <option>Active</option>
              <option>Not Active</option>
            </select>
          </p>

          <p className="col col-md-8">
            <b>Event date:</b>
            <input
              onChange={(e) => this.setState({ eventsDate: e.target.value })}
              value={this.state.eventsDate}
              className="col col-md-8 form-control"
              type="date"
            />
          </p>
        </div>

        <p>
          <b>Content:</b>
          <textarea
            id="eventsContent"
            type="text"
            className="form-control"
            onChange={this.handleChange}
            defaultValue={this.state.eventsContent}
          />
        </p>

        <div className="mt-4 mb-5">
          <b>Featured Image:</b>
          <br />
          <div hidden={this.state.oldImageHide}>
            {events.eventsImage ? (
              <div>
                <img
                  src={events.eventsImage.fileUrl}
                  alt="events"
                  width="30%"
                  className="text-center"
                />
                <br />
                <label>{events.eventsImage.fileName}</label>
              </div>
            ) : (
              "No selected image."
            )}
          </div>

          <img
            hidden={this.state.newImageHide}
            src={this.state.imageDisplay}
            alt="events"
            width="30%"
            className="text-center"
          />
          <br />

          <input
            className="mt-2"
            type="file"
            onChange={this.imageSelectHandler.bind(this)}
          />
          <br />
          <progress
            value={this.state.progress}
            max="100"
            style={{ width: "250px" }}
          />
        </div>

        {isLoading === true ? (
          <small>Loading...</small>
        ) : message ? (
          <small
            className={
              message.type === "updateEventsError"
                ? "text-red"
                : message.type === "updateEventsSuccess"
                ? "text-green"
                : null
            }
          >
            {message.msg}
          </small>
        ) : null}

        <div className="mt-5 row justify-content-end">
          <button
            type="button"
            className="btn m-2 btn-outline-black"
            onClick={this.updateEvents.bind(this)}
          >
            <i className="fas fa-check-circle" /> update
          </button>
          <button
            type="button"
            className="btn m-2 btn-outline-black"
            onClick={this.props.cancel.bind(this)}
          >
            <i className="fas fa-times" /> cancel
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    updateEventsError: state.events.updateEventsError,
    updateEventsSuccess: state.events.updateEventsSuccess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateEvents: (updatedEvents, events_id) =>
      dispatch(updateEvents(updatedEvents, events_id)),
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  EditEvents
);
