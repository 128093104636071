import React, { Component } from 'react';
import { connect } from 'react-redux'
import { storage } from '../../../config/fbConfig'
import { updateHalalInfo } from '../../../stores/actions/infoAction';

class EditHalalInfo extends Component{

    constructor(props){
        super(props)
        this.state = {
            infoName: this.props.info.infoName,
            infoType: this.props.info.infoType,
            selectedHalalFile: this.props.info.selectedHalalFile,
            attachType: this.props.info.selectedHalalFile.fileType,
    
            newHalalFile: null,
            progress:'',
            videoDisplay: null,
        
            isLoading: null,
            message: null,
        }
        this.baseState = this.state 
    }
    
    componentDidUpdate = (prevProps, prevState) => { 
        const { updateHalalError, updateHalalSuccess } = this.props;

        if(prevProps.updateHalalError !== updateHalalError) {
            this.setState({
                isLoading: false,
                message: {
                    msg: updateHalalError.message,
                    type: updateHalalError.type,
                }
            })
        }

        if(prevProps.updateHalalSuccess !== updateHalalSuccess) {
            this.setState({
                isLoading: false,
                message: {
                    msg: updateHalalSuccess.message,
                    type: updateHalalSuccess.type,
                }
            })
            setTimeout(() => { 
                this.setState(this.baseState)
                this.props.hideAndResetState();
            }, 2000);
        }
    } 

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    selectedAttachType = (e) => {
        this.setState({
            attachType:  e.target[e.target.selectedIndex].id,
        })
    }

    newHalalFile = (e) => {

        if(e.target.files[0]) {
            this.setState({
                newHalalFile:  e.target.files[0]
            });
    
            if(this.state.attachType === "video") {
                this.setState({
                    videoDisplay: URL.createObjectURL(e.target.files[0]) //ubah imgUrl to pic
                });
            }
        }
    }

    updateHalalInfo = () => {

        const { newHalalFile, attachType } = this.state;

        var result = window.confirm("Confirm update?");

        if (result) {
            this.setState({
                isLoading: true
            })
            if (newHalalFile !== null) {

                let ref = null, ref2 = null;

                if(attachType === "pdf") {
                    ref = `documents/${newHalalFile.name}`
                    ref2 = 'documents'
                } else if (attachType === "video") {
                    ref = `videos/${newHalalFile.name}`
                    ref2 = 'videos'
                }

                const uploadTask = storage.ref(ref).put(newHalalFile);
        
                    uploadTask.on('state_changed', 
                        (snapshot) => {
                            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                            this.setState({
                                progress});
                        }, 
        
                        (error) => {
                            alert(error)
                        }, 
        
                        () => {
                            storage.ref(ref2).child(newHalalFile.name).getDownloadURL().then(fileUrl => {
                                this.setState({
                                    selectedHalalFile: {
                                        fileUrl: fileUrl,
                                        fileName: newHalalFile.name,
                                        fileType: attachType
                                    }
                                });
                                
                                this.props.updateHalalInfo(this.state, this.props.infoId);
                            })
                        }
                    );
            } else {
                this.props.updateHalalInfo(this.state, this.props.infoId);
            }
        }
    }

    displayModalHalalFile_Edit = () => { //display current file in popup
        document.getElementById("display_halal_file_edit").style.display = "block";
        this.props.hideModalHalalFile_Display()
    }

    hideModalHalalFile_Edit = () => { //hide current file in popup
        document.getElementById("display_halal_file_edit").style.display = "none";
    }


    render(){

        const { message, isLoading } = this.state;

        return(

        <div>
            <div id="display_halal_file_edit" className="mymodal">
                <div className="mymodal-content col-md-6">
                    <span className="close" onClick={this.hideModalHalalFile_Edit}>&times;</span>
                    <h3 className="middle">{this.state.selectedHalalFile.fileName}
                        <div className="row justify-content-center"><div className="title-border"></div></div>
                    </h3>
                    
                    <object aria-label="display-pdf" className="display-pdf" data={this.state.selectedHalalFile.fileUrl} type="application/pdf"></object>
                </div>
            </div>
                    
            <b className="uppercase middle">edit {this.props.info.infoName}
                <div className="title-border"></div>
            </b>

            <b>TITLE:</b><br />
            <input type="text" id="infoName" onChange={this.handleChange.bind(this)} className="form-control" defaultValue={this.state.infoName} />
            <br/>
            <b>ATTACHMENT:</b>
            <br />
            <select className="mb-2 col col-md-6 form-control" onChange={this.selectedAttachType.bind(this)}>
                <option defaultValue>{this.state.attachType ? this.state.attachType : 'Choose attachment type'}</option>
                <option id="video" >Video</option>
                <option id="pdf">PDF</option>
            </select>
            { this.state.newHalalFile ?
                this.state.attachType === "video" ?
                    <video width="300" height="200" src={this.state.videoDisplay} type="video/mp4" controls />
                : null 
            : 
                this.state.selectedHalalFile ?
                    this.state.attachType === "video" ?
                        <video width="300" height="200" src={this.state.selectedHalalFile.fileUrl} type="video/mp4" controls />
                    : this.state.attachType === "pdf" ?
                        <span onClick={this.displayModalHalalFile_Edit}>{this.state.selectedHalalFile.fileName}</span>
                    : null
                : null
            }

            <br />

            <input className="mt-2" type="file" onChange={this.newHalalFile.bind(this)} /><br/>
            <progress value={this.state.progress} max="100" style={{width:"250px"}}/>

            <br/>

            { isLoading === true ? 
                <small>Loading...</small>
            : 
                message  ?
                    <small className={message.type === 'updateHalalError' ? "text-red" : message.type === 'updateHalalSuccess'  ? "text-green" : null }>
                        {message.msg}
                    </small>
                : null
            }

            <div className="mt-5 row justify-content-end">
                <button type="button" className="btn m-2 btn-outline-black" onClick={this.updateHalalInfo.bind(this)}><i className="fas fa-check-circle"/>{' '}update</button>
            </div>
        </div>

        
        )
    }
}


const mapStateToProps = (state, ownProps) => {
    const id = ownProps.info_id;
    const infosList = state.firestore.data.infos;
    const info = infosList ? infosList[id] : null
    return {
        infoId: id,
        infosList: infosList,
        info: info,

        updateHalalError: state.infos.updateHalalError,
        updateHalalSuccess: state.infos.updateHalalSuccess,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
      updateHalalInfo: (helpInfo, infoId) => dispatch(updateHalalInfo(helpInfo, infoId))
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(EditHalalInfo)