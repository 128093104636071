import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'

import { deleteCourse, employerMode } from '../../../stores/actions/courseAction';

import EditCourse from './EditCourse'

class DisplayCourse extends Component{

    state = {
        empMode: false,
        hideDetails : false,
        hideEdit: true,
        id:'',
    }
    
    componentDidUpdate = (prevProps, prevState) => { 
        const { deleteCourseError, deleteCourseSuccess } = this.props;

        if(prevProps.deleteCourseError !== deleteCourseError) {
            alert(deleteCourseError.message);
        }
        
        if(prevProps.deleteCourseSuccess !== deleteCourseSuccess) {
            alert(deleteCourseSuccess.message);
            document.getElementById("clickCourse").click();
        }
    } 

    handleEdit = (course_id) => {
        this.setState({
          hideDetails : true,
          hideEdit: false,
          id: course_id
        })
    }

    cancel = () => {
        this.setState({
            id:''
        })
    }

    deleteCourse = (course_id) => {
        var result = window.confirm("Confirm delete?");
    
        if (result) {
            this.props.deleteCourse(course_id)
        }
    }

    isEmployerMode = () => {
        let empMode = !this.state.empMode;
        this.setState({ empMode })
        this.props.employerMode(empMode, this.props.course_id)
    }

    render(){

        const { query, modules, exams, courses, course, course_id } = this.props;
        
        let query_id = query.get('id');
        let isLoading = true;

        let courseDetails = course ? (
            <div key={course.id}>
                { isLoading = false }
                { this.state.id === course_id ?
                    <EditCourse modules={modules} exams={exams} courses={courses} cancel={this.cancel} course={course} course_id={course_id} />
                :
                    <div>

                        <div className='mb-2'>
                            <input checked={course && course.employerMode ? course.employerMode : this.state.empMode} onChange={this.isEmployerMode.bind(this)} type="checkbox" id="hide" value="hide"/>
                            {' '}<label htmlFor="hide">Emloyer mode (To purchase as bulk)</label> 
                        </div>

                        <div className="row">   
                            <div className="col col-md-4">
                                <b>Course Type: </b>
                                {course.courseType}
                            </div>
                        </div>

                        <br/>

                        <div className="row">        
                            <div className="col col-md-4">
                                <b>Code: </b>
                                {course.courseCode}
                            </div>

                            <div className="col col-md-4">
                                <b>Name: </b>
                                {course.courseName}
                            </div>

                            <div className="col col-md-4">
                                <b>Status: </b>
                                {course.courseStatus}
                            </div>
                        </div>

                        <br/>
                        
                        <div>
                            <b>Course Price:</b>
                            <div style={{marginLeft:"20px"}}>
                                <li>
                                    <b>Price: </b>
                                    RM {course.coursePrice}
                                </li>   

                                <li>
                                    <b>Period: </b>
                                    {course.coursePeriod} days(s)
                                </li> 

                                <li>
                                    <b>ToyyibPay Category Code: </b>
                                    {course.toyyibCatCode}
                                </li> 
                            </div>
                        </div>

                        <br/>

                        {course.courseType === "Professional" ?
                        
                            <div>
                                <div>
                                    <b>Course Expired Extension:</b>
                                    <div style={{marginLeft:"20px"}}>
                                        <li>
                                            <b>Price: </b>
                                            RM {course.extendPrice}
                                        </li>   

                                        <li>
                                            <b>Period: </b>
                                            {course.extendPeriod} month(s)
                                        </li> 

                                        <li>
                                            <b>ToyyibPay Extension Category Code: </b>
                                            {course.toyyibCatCode_Extend}
                                        </li> 
                                    </div>
                                </div>

                                <br/>

                                <div><b>Modules: </b>
                                    <div  style={{marginLeft:"20px"}}>
                                        { modules && modules.map(module => {
                                            return (
                                                course.selectedModules.map((selected, i) => { 
                                                    if(module.id === selected) {
                                                        return (
                                                            <li key={i}>{module.moduleCode} - {module.moduleName}</li>
                                                        )
                                                    }
                                                    return null
                                                })
                                            )
                                        })}
                                    </div>
                                </div>
                                
                                <br/>

                                <div>
                                    <b>Exam: </b>
                                    <div style={{marginLeft:"20px"}}>
                                        <li><b>Has Exam:</b> {course.exam.hasExam}</li>
                                        {course.exam && course.exam.hasExam === "true" ?
                                            <div>
                                                    {exams && exams.map((exam,i) => {
                                                    if (exam.id === course.exam.selectedExamSet) {
                                                        return (
                                                            <li key={i}><b>Selected Exam Set:</b> {exam.examCode} - {exam.examName}</li>
                                                        )
                                                    }
                                                    return null
                                                })}
                                                <br/>
                                                <small>EXAM SETING</small>
                                                <div className="row">   
                                                    <div className="col col-md-4">
                                                        <small><b>Timer: </b></small>
                                                        {course.exam.examTimer} second(s)
                                                    </div>

                                                    <div className="col col-md-4">
                                                        <small><b>Question Count: </b></small>
                                                        {course.exam.questionCount}
                                                    </div>  

                                                    <div className="col col-md-4">
                                                        <small><b>Mark: </b></small>
                                                        {course.exam.examMark}%
                                                    </div>  
                                                </div>

                                                <div className="row">   
                                                    <div className="col col-md-4">
                                                        <small><b>Suspend Count: </b></small>
                                                        {course.exam.examSuspendNumber}
                                                    </div>  

                                                    <div className="col col-md-4">
                                                        <small><b>Suspend Day: </b></small>
                                                        {course.exam.examSuspendDay} day(s)
                                                    </div>
                                                </div>
                                            </div>
                                        : null }
                                    </div>
                                </div>
                                
                                <br/>

                                <div className="row">
                                    <div className="col col-md-4">
                                        <b>Has Practical Session: </b> {course.hasPhysicalClass}
                                    </div>

                                    <div className="col col-md-4">
                                        <b>Has E-Cert: </b>
                                        {course.hasEcert}
                                    </div>

                                    <div className="col col-md-4">
                                        <b>Has Flexi: </b>
                                        {course.installmentAvailable}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col col-md-4">
                                        <b>Enable Exam: </b> {course.enableExam}
                                    </div>
                                    <div className="col col-md-4">
                                        <b>Remove Button Quiz: </b> {course.removeQuizBtn}
                                    </div>
                                </div>
                            </div>
                        : 
                            <div>
                                <div><b>Course: </b>
                                    {courses && courses.map((cs,i) => {
                                        if (cs.id === course.selectedCourse) {
                                            return (
                                                <span key={i}> {cs.courseName}</span>
                                            )
                                        }
                                        return null
                                    })}
                                </div>

                                <div><b>Module: </b>
                                    {modules && modules.map((module,i) => {
                                        if (module.id === course.selectedModule) {
                                            return (
                                                <li style={{marginLeft:"20px"}} key={i}>{module.moduleCode} - {module.moduleName}</li>
                                            )
                                        }
                                        return null
                                    })}
                                </div>
                            </div>
                        }

                        <br/>

                        <div className="mt-1"><b>Info: </b><br/>
                            { course.courseInfo && course.courseInfo.map((info, i) => { 
                                return (
                                    <div key={i} style={{margin:"0px"}} className="listing"> 
                                        <div className="list-icon"><li/></div>
                                        <div dangerouslySetInnerHTML={{__html: info}} style={{margin:"0px"}}></div>
                                    </div>
                                )
                            })}
                        </div>

                        <br/>

                        <div className="mt-1"><b>Overview: </b><br/>
                            { course.courseOverview && course.courseOverview.map((overview, i) => { 
                                return (
                                    <div key={i} style={{margin:"0px"}} className="listing"> 
                                        <div className="list-icon"><li/></div>
                                        <label dangerouslySetInnerHTML={{__html: overview}} style={{margin:"0px"}}></label>
                                    </div>
                                )
                            })}
                        </div>

                        <br/>
                                
                        <div className="mt-1"><b>What Will Learn: </b><br/>
                            { course.courseLearn && course.courseLearn.map((learn, i) => { 
                                return (
                                    <div key={i} style={{margin:"0px"}} className="listing"> 
                                        <div className="list-icon"><li/></div>
                                        <label dangerouslySetInnerHTML={{__html: learn}} style={{margin:"0px"}}></label>
                                    </div>
                                )
                            })}
                        </div>

                        <br/>

                        <div className="mt-1"><b>Requirement: </b><br/>
                            { course.courseRequirement && course.courseRequirement.map((requirement, i) => { 
                                return (
                                    <div key={i} style={{margin:"0px"}} className="listing"> 
                                        <div className="list-icon"><li/></div>
                                        <label dangerouslySetInnerHTML={{__html: requirement}} style={{margin:"0px"}}></label>
                                    </div>
                                )
                            })}
                        </div>

                        <br/>

                        <div className="mt-4">
                            <b>Attachment: </b><br />
                            {courses && courses.map((course,i) => {
                                if (course.id === course_id && course.courseAttach !== null) { //sbb nnti dia akan display je video even kalau data tu takde data video
                                    return (
                                        <div key={i}>
                                            { course.courseAttach.fileType === "image" ?
                                                <div><img alt="img" width="200" src={course.courseAttach.fileUrl} /></div>
                                            : course.courseAttach.fileType === "video" ?
                                                <div><video width="250" height="150" src={course.courseAttach.fileUrl} type="video/mp4" controls /></div>
                                            : null }
                                            <br/>
                                            <label>{course.courseAttach.fileName}</label>
                                        </div>
                                    )
                                } else if (course.id === course_id && course.courseAttach === null) {
                                    return (
                                        <em>No selected attachment.</em>
                                    )
                                }
                                return null
                            })}
                        </div>

                        <div className="mt-5 row justify-content-end">
                            <button type="button" className="btn m-2 btn-outline-black" onClick={this.handleEdit.bind(this, course_id)}><i className="fas fa-edit"></i></button>
                            <button type="button" className="btn m-2 btn-outline-red" onClick={this.deleteCourse.bind(this, course_id)}><i className="fas fa-trash"></i></button>
                        </div>
                    </div>
                }
            </div>
        ) : ( 
           null
        )

        return (

            <div className="system-setting container col-md-12">
                { query_id ?
                    isLoading === true ? <div>Data loading... Please wait...</div> : courseDetails
                : <div>Course data not available.</div> }

                <div hidden={true}>
                    <Link id="clickCourse" to={`/systemmanager?topic=course&subtopic=course`} />
                </div>
            </div>
        
        )
    } 
}

const mapStateToProps = (state, ownProps) => {
    const id = ownProps.selectedCourseId;
    const courses = state.firestore.data.courses;
    const course = courses ? courses[id] : null
    return {
        course: course,
        course_id: id,
        
        deleteCourseError: state.courses.deleteCourseError,
        deleteCourseSuccess: state.courses.deleteCourseSuccess,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        employerMode: (data, courseId) => dispatch(employerMode(data, courseId)),
        deleteCourse: (course_id) => dispatch(deleteCourse(course_id))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect([
        { collection: 'courses'},
    ])
)(DisplayCourse);