import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { deleteEvents } from "../../../stores/actions/eventsAction";

import EditEvents from "./EditEvents";

class DisplayEvents extends Component {
  state = {
    hideDetails: false,
    hideEdit: true,
    id: "",
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { deleteEventsError, deleteEventsSuccess } = this.props;

    if (prevProps.deleteEventsError !== deleteEventsError) {
      alert(deleteEventsError.message);
    }

    if (prevProps.deleteEventsSuccess !== deleteEventsSuccess) {
      alert(deleteEventsSuccess.message);
      document.getElementById("clickEvents").click();
    }
  };

  handleEdit = (events_id) => {
    this.setState({
      hideDetails: true,
      hideEdit: false,
      id: events_id,
    });
  };

  cancel = () => {
    this.setState({
      id: "",
    });
  };

  deleteEvents = (events_id) => {
    var result = window.confirm("Confirm delete?");

    if (result) {
      this.props.deleteEvents(events_id);
    }
  };

  render() {
    const { query, events, events_id, eventsList, eventsAttendees } = this.props;

    let query_id = query.get("id");
    let isLoading = true;

    const hasAttendees = eventsAttendees.filter((selected) => {
      return selected.eventsId === events_id;
  });
  
    const eventsDetails = events ? (
      <div key={events.id}>
        {(isLoading = false)}
        {this.state.id === events_id ? (
          <EditEvents
            cancel={this.cancel}
            events={events}
            eventsList={eventsList}
            events_id={events_id}
          />
        ) : (
          <div>
            <div className="row">
              <p className="col col-md-3">
                <b>Code: </b>
                {events.eventsCode}
              </p>

              <p className="col col-md-9">
                <b>Name: </b>
                {events.eventsName}
              </p>
            </div>
            <div className="row">
              <p className="col col-md-12">
                <b>Topic: </b>
                {events.eventsTopic}
              </p>
            </div>
            <div className="row">
              <p className="col col-md-3">
                <b>Status: </b>
                {events.eventsStatus}
              </p>

              <p className="col col-md-9">
                <b>Date: </b>
                {events.eventsDate}
              </p>
            </div>

            <br />

            <div className="mt-4">
              <b>Content: </b>
              <br />
              <p dangerouslySetInnerHTML={{ __html: events.eventsContent }}></p>
            </div>

            <div className="mt-4">
              <b>Featured Image: </b>
              <br />
              {events.eventsImage ? (
                <div>
                  <img
                    src={events.eventsImage.fileUrl}
                    alt="events"
                    width="30%"
                    className="text-center"
                  />
                  <br />
                  <label>{events.eventsImage.fileName}</label>
                </div>
              ) : (
                "No selected image."
              )}
            </div>

            <div className="mt-5 row justify-content-end">
              <button
                type="button"
                className="btn m-2 btn-outline-black"
                onClick={this.handleEdit.bind(this, events_id)}
              >
                <i className="fas fa-edit"></i>
              </button>
              { hasAttendees.length === 0 ?(
                <button
                type="button"
                className="btn m-2 btn-outline-red"
                onClick={this.deleteEvents.bind(this, events_id)}
              >
                <i className="fas fa-trash"></i>
              </button>
              )
              :null
              }
              
            </div>
          </div>
        )}
      </div>
    ) : null;

    return (
      <div className="container col-md-12">
        {query_id ? (
          isLoading === true ? (
            <p>Data loading... Please wait...</p>
          ) : (
            eventsDetails
          )
        ) : (
          <p>Event data not available.</p>
        )}

        <div hidden={true}>
          <Link
            id="clickEvents"
            to={`/systemmanager?topic=events&subtopic=module`}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.selectedEventsId;
  const eventData = state.firestore.data.events;
  const eventSelect = eventData ? eventData[id] : null;

  return {
    events: eventSelect,
    events_id: id,

    deleteEventsError: state.events.deleteEventsError,
    deleteEventsSuccess: state.events.deleteEventsSuccess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteEvents: (events_id) => dispatch(deleteEvents(events_id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DisplayEvents);
