const initState = {
    chatMessage: []
}

const toyyibPayReducer = (state = initState, action ) => {

    switch (action.type) {

        case 'ADD_NEW_PURCHASE':
            return state;
        case 'ADD_NEW_PURCHASE_ERROR':
            return {
                ...state,
                newPurchaseError: {
                    message: action.err.message,
                    type: 'newPurchaseError'
                }
            }

        case 'UPDATE_PURCHASE_AFTER_PAYMENT':
            return state;
        case 'UPDATE_PURCHASE_AFTER_PAYMENT_ERROR':
            return {
                ...state,
                updatePurchaseStatusError: {
                    message: action.err.message,
                    type: 'updatePurchaseStatusError'
                }
            }
        default:
            return state;
    }
}

export default toyyibPayReducer