export const updateUser = (updatedUser, userId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    if (!updatedUser.fullName) {
      dispatch({
        type: "UPDATED_USER_ERROR",
        message: "Full name required." ,
      });
    } else {
      firestore
        .collection("users")
        .doc(userId)
        .update({
          fullname: updatedUser.fullName,
          firstname: updatedUser.firstName,
          lastname: updatedUser.lastName,
          identityCardNo: updatedUser.identityCardNo,
          contactNo: updatedUser.contactNo,
          address1: updatedUser.address1,
          address2: updatedUser.address2,
          postcode: updatedUser.postcode,
          city: updatedUser.city,
          dob: updatedUser.dob,
          company: updatedUser.company,
          state: updatedUser.state,
          country: updatedUser.country,
        })
        .then(() => {
          dispatch({ type: "UPDATED_USER", message: "Account info updated." });
        })
        .catch((err) => {
          dispatch({ type: "UPDATED_USER_ERROR", err });
        });
    }
  };
};

export const changePassword = (data) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();

    var user = firebase.auth().currentUser;

    var credential = firebase.auth.EmailAuthProvider.credential(
      firebase.auth().currentUser.email,
      data.oldPassword
    );

    user
      .reauthenticateWithCredential(credential)
      .then(() => {
        user
          .updatePassword(data.newPassword)
          .then(() => {
            dispatch({
              type: "CHANGE_PASSWORD_SUCCESS",
              message: "Password changed successfully. Please login.",
            });
          })
          .catch((err) => {
            dispatch({ type: "CHANGE_PASSWORD_ERROR", err });
          });
      })
      .catch((err) => {
        dispatch({ type: "CHANGE_PASSWORD_ERROR", err });
      });
  };
};

export const updateContactNo = (contactNo, userId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    firestore
      .collection("users")
      .doc(userId)
      .update({
        contactNo,
      })
      .then(() => {
        dispatch({ type: "UPDATED_USER_CONTACT_NO", contactNo: contactNo });
      })
      .catch((err) => {
        dispatch({ type: "UPDATED_USER_CONTACT_NO_ERROR", err });
      });
  };
};

export const updateUserTranscript = (data, userId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const { fname, lname, ic } = data;

    firestore
      .collection("users")
      .doc(userId)
      .update({
        firstname: fname,
        lastname: lname,
        identityCardNo: ic,
      })
      .then(() => {
        dispatch({ type: "UPDATED_USER", message: "Account info updated." });
      })
      .catch((err) => {
        dispatch({ type: "UPDATED_USER_ERROR", err });
      });
  };
};
