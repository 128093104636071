import React, { Component } from 'react';
import moment from 'moment'

class EnrollmentCount extends Component{

    state = {
        hideCourseCount: true,
        selectedCourseId:''
    }

    handleSelectedCourse = (e) => {
        this.setState({
            selectedCourseId: e.target[e.target.selectedIndex].id,
            hideCourseCount: false,
        })

    }

    render(){

        const { courses, courseEnrollments, physicalClassParticipants, courseType, courseId } = this.props;

        let enrolledCount = [], expiredCount = [], activeCount = [],

        activeOnGoingCount = [], activeExamDoneCount = [], activeListedCount = [], activeCompletedCount = [], activeSubmittedCount = [],
        expiredOnGoingCount = [], expiredExamDoneCount = [], expiredListedCount = [], expiredCompletedCount = [], expiredSubmittedCount = [];
              
        courseEnrollments && courseEnrollments.map(enrolled => {
            let enrollExpired = moment(enrolled.enrollExpired).format('YYYY-MM-DD');
            let dateToday = moment().format('YYYY-MM-DD');
                                    
            let enrollExpiredSame = moment(dateToday).isSame(enrollExpired);
            let enrollExpiredAfter = moment(dateToday).isAfter(enrollExpired);

            if(courseId === enrolled.courseId && enrolled.type === courseType) {

                enrolledCount.push(courseEnrollments);

                //ACTIVE
                if(enrollExpiredSame === false && enrollExpiredAfter === false) {

                    activeCount.push(courseEnrollments);

                    physicalClassParticipants && physicalClassParticipants.map(pcp => {
                        if(pcp.userId === enrolled.userId && pcp.courseId === enrolled.courseId) {
                            if (pcp.status === "listed") {
                                activeListedCount.push(courseEnrollments);
                            } else if (pcp.status === "completed") {
                                activeCompletedCount.push(courseEnrollments);
                            } else if (pcp.status === "submitted") {
                                activeSubmittedCount.push(courseEnrollments);
                            }
                        }
                        return null
                    })
                    
                    if (enrolled.isOnGoing === true && enrolled.isDoneExam === false) { 
                        activeOnGoingCount.push(courseEnrollments);
                    }

                    if(enrolled.isOnGoing === true && enrolled.isOnGoing === true && enrolled.isDoneExam === true) { 
                        activeExamDoneCount.push(courseEnrollments);
                    }
                //EXPIRED           
                } else { 
                    expiredCount.push(courseEnrollments); 

                    physicalClassParticipants && physicalClassParticipants.map(pcp => {
                        if(pcp.userId === enrolled.userId && pcp.courseId === enrolled.courseId) {
                            if (pcp.status === "listed") {
                                expiredListedCount.push(courseEnrollments);
                            } else if (pcp.status === "completed") {
                                expiredCompletedCount.push(courseEnrollments);
                            } else if (pcp.status === "submitted") {
                                expiredSubmittedCount.push(courseEnrollments);
                            }
                        }
                        return null
                    })
                    
                    if (enrolled.isOnGoing === true && enrolled.isDoneExam === false) { 
                        expiredOnGoingCount.push(courseEnrollments);
                    }

                    if(enrolled.isOnGoing === true && enrolled.isDoneExam === true) { 
                        expiredExamDoneCount.push(courseEnrollments);
                    }
                }
            }
            return null
        })

        let courseName = "", hasClass = null;
        
        courses && courses.map(course => { 
            if(course.id === courseId) { 
                courseName = course.courseName 
                hasClass = course.hasPhysicalClass
            } 
            return null 
        })

        return(

        <div className="ecount">
            <div className="row justify-content-center">
                <div className="col cat">
                    <h1>{enrolledCount.length}</h1>enrollment(s)
                    <br/>
                    <b>{courseType} - {courseName} </b>

                    <div className="row justify-content-center mt-5">
                        <div className="col">
                            <label className="badge bg-green text-white">Active: {activeCount.length} user(s)</label>
                            <br/>
                            <table className="text-left mt-2 table">
                                <thead>
                                    <tr>
                                        <th scope="col">Status</th>
                                        <th className="text-left" scope="col">Total (user)</th>
                                    </tr>
                                </thead>
                                
                                <tbody>
                                    <tr>
                                        <td>On Going</td>
                                        <td>{activeOnGoingCount.length}</td>
                                    </tr>

                                    <tr>
                                        <td>Done Exam</td>
                                        <td>{activeExamDoneCount.length}</td>
                                    </tr>

                                     { hasClass === true || hasClass === "true" ?
                                        <tr>
                                            <td>Practical - Listed</td>
                                            <td>{activeListedCount.length}</td>
                                        </tr>
                                    : null }

                                    { hasClass === true || hasClass === "true" ?
                                        <tr>
                                            <td>Practical - Submitted</td>
                                            <td>{activeSubmittedCount.length}</td>
                                        </tr>
                                    : null }
                                    
                                    { hasClass === true || hasClass === "true" ?
                                        <tr>
                                            <td>Practical - Completed</td>
                                            <td>{activeCompletedCount.length}</td>
                                        </tr>
                                    : null }
                                      
                                </tbody>
                            </table>
                        </div>

                        <div className="col">
                            <label className="badge bg-red text-white">Expired: {expiredCount.length} user(s)</label>
                            <br/>
                            <table className="text-left mt-2 table">
                                <thead>
                                    <tr>
                                        <th scope="col">Status</th>
                                        <th className="text-left" scope="col">Total (user)</th>
                                    </tr>
                                </thead>
                                
                                <tbody>
                                    <tr>
                                        <td>On Going</td>
                                        <td>{expiredOnGoingCount.length}</td>
                                    </tr>

                                    <tr>
                                        <td>Done Exam</td>
                                        <td>{expiredExamDoneCount.length}</td>
                                    </tr>

                                     { hasClass === true || hasClass === "true" ?
                                        <tr>
                                            <td>Practical - Listed</td>
                                            <td>{expiredListedCount.length}</td>
                                        </tr>
                                    : null }

                                    { hasClass === true || hasClass === "true" ?
                                        <tr>
                                            <td>Practical - Submitted</td>
                                            <td>{expiredSubmittedCount.length}</td>
                                        </tr>
                                    : null }
                                    
                                    { hasClass === true || hasClass === "true" ?
                                        <tr>
                                            <td>Practical - Completed</td>
                                            <td>{expiredCompletedCount.length}</td>
                                        </tr>
                                    : null }
                                      
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        )
    }
}
  
export default EnrollmentCount;