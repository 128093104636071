import React, { Component } from 'react';
import { storage } from '../../../config/fbConfig'
import { connect } from 'react-redux';
import { sendEmail, updateTicketCounter } from '../../../stores/actions/formAction';

class EmailUser extends Component{
    
    constructor(props){
        super(props)
        this.state = {
            userId: '',
            type: '',
            subject:'',
            message: '',
            
            attachment:'',
    
            qnaCourse: '',
            qnaModule: '',
            qnaTopic: '',
    
            selectedCourseId:'',
            selectedModuleId:'',

            file: null,
            progress:'',
            imageDisplay:null,
        
            isLoading: null,
            mesej: null,
        }
        this.baseState = this.state 
    }
    
    componentDidUpdate = (prevProps, prevState) => { 

        const { sendEmailError, sendEmailSuccess } = this.props;

        if(prevProps.sendEmailError !== sendEmailError) {
            this.setState({
                isLoading: false,
                mesej: {
                    msg: sendEmailError.message,
                    type: sendEmailError.type,
                }
            })
        }

        if(prevProps.sendEmailSuccess !== sendEmailSuccess) {
            this.setState({
                isLoading: false,
                mesej: {
                    msg: sendEmailSuccess.message,
                    type: sendEmailSuccess.type,
                }
            })
            setTimeout(() => { 
                this.setState(this.baseState)
            }, 5000);
        }
    } 

    openModal = (id) => {
        document.getElementById("modal-detail").style.display = "block";
        this.setState({
            clickedEmailId: id,
        })
    }

    closeModal = () => {
        document.getElementById("modal-detail").style.display = "none";
    }

    fileSelectHandler = event => {
        this.setState({ 
          file: event.target.files[0],
          imageDisplay: URL.createObjectURL(event.target.files[0])
        });
    }

    ticketDeduct = () => {

        const { users, profile, type } = this.props;

        let ticketType = null, deducted = 0;

        users && users.map(user => {
            if(user.id === profile.userId) {
                if(type === "ts") {
                    ticketType = 'ts';
                    deducted = user.supportTicket - 1;
                } else if (type === "qna") {
                   ticketType = 'qna';
                   deducted = user.qnaTicket - 1;
                }
            }
            return null
        })

        return { ticketType, deducted }
    }

    updateType = async () => {
        this.setState({
            type: this.props.type
        })
    }

    setUserId = async () => {
        this.setState({
            userId: this.props.profile.userId
        })
    }

    sendEmail = async () => {

        const { profile } = this.props;

        await this.updateType();
        await this.setUserId();

        var data = this.ticketDeduct();

        this.setState({
            isLoading: true
        })
          
        const { file } = this.state;

        if (file) {
            const uploadTask = storage.ref(`faqs/${file.name}`).put(file);
            uploadTask.on('state_changed', 
                (snapshot) => {
                    const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    this.setState({
                    progress
                    });
                }, 
        
                (error) => {
                    console.log(error);
                }, 
        
                () => {
                    storage.ref('faqs').child(file.name).getDownloadURL().then(url => {

                        this.setState({attachment: url});
                        this.props.sendEmail(this.state);
                        this.props.updateTicketCounter(data.ticketType, data.deducted, profile.userId);
            
                    })
                }
            );
        } else {
            this.props.sendEmail(this.state);
            this.props.updateTicketCounter(data.ticketType, data.deducted, profile.userId);
        }
    }

    render(){

        const { auth, users, profile, courses, modules, topics, supportEmail, type } = this.props;
        const { mesej, isLoading } = this.state;

        let isLoadingHistory = true, emailCount = [];

        let courseList =  courses && courses.map((course,i) => {
            if(course.courseType === "Professional" && course.courseStatus === "Active") {
                return (
                    <option key={i} id={course.id}>{course.courseName}</option>
                )
            }
            return null
        })

        let moduleList = courses && courses.map((course,i) => {
            if (this.state.selectedCourseId === course.id) {
                return (
                    course && course.selectedModules.map(mod => {
                        return (
                            modules && modules.map(module => {
                                if(mod === module.id) {
                                    return(
                                        <option key={i} id={module.id}>{module.moduleName}</option>
                                    )
                                }
                                return null
                            })
                        )
                    })
                )
            }
            return null
        })

        let topicList = modules && modules.map((module,i) => {
            if (this.state.selectedModuleId === module.id) {
                return (
                    module && module.selectedTopics.map(top => {
                        return(
                            topics && topics.map(topic => {
                                if(topic.id === top) {
                                    return(
                                        <option key={i} id={topic.id}>{topic.topicName}</option>
                                    )
                                }
                                return null
                            })
                        )
                    })
                )
            }
            return null
        })

        let historyList = supportEmail && supportEmail.map((emel,i) => {
            if(emel.type === type && auth.uid === emel.userId) {
                emailCount.push(emel)
                isLoadingHistory = false;
                return (
                    <tr key={i}>
                        <td>{emel.date} <br/> {emel.time}</td>
                        <td>{emel.subject}</td>
                        <td><span onClick={this.openModal.bind(this, emel.id)}><i className="far fa-eye"/></span></td>
                        <td>
                            <span className=
                                {emel.status === "Sent" ? "badge bg-yellow" 
                                : emel.status === "Received" ? "badge bg-blue text-white"
                                : emel.status === "Responded" ? "badge bg-purple text-white"
                                : emel.status === "Solved" ? "badge bg-green text-white"
                                : null }
                            >{emel.status}</span>
                        </td>
                    </tr>
                )
            }
            return null
        })

        if(emailCount.length < 1) {
            isLoadingHistory = false;
        }
       
        let emailDetail = supportEmail && supportEmail.map((emel,i) => {
            if(this.state.clickedEmailId === emel.id){
                return( 
                    <div key={i} className="text-left">
                        { emel.type === "qna" ?
                            <div className="row">
                                <div className="col col-md-4"><b>Course</b><br/>{emel.qnaCourse}</div>
                                <div className="col col-md-4"><b>Module</b><br/>{emel.qnaModule}</div>
                                <div className="col col-md-4"><b>Topic</b><br/>{emel.qnaModule}</div>
                            </div>
                        : null }
                        <br/>
                        <div><b>Date / Time:{' '}</b>{emel.date} || {emel.time}</div>
                        <br/>
                        <div><b>Subject:{' '}</b>{emel.subject}</div>
                        <br/>
                        <div><b>Message:{' '}</b>{emel.message}</div>
                        <br/>
                        {emel.type === "ts" ?
                            <div><b>Attachment</b> <br/>
                                { emel.type === "ts" ?
                                    emel.attachment ?
                                        <img style={{paddingTop:"10px", paddingBottom:"10px"}} src={emel.attachment} alt="" width="20%"/>
                                    :
                                        <p>No attachment</p>
                                : null }
                            </div>
                        : null }
                    </div>
                )
            }
            return null
        });

        let ticketCounter = users && users.map(user => {
            if(profile.userId === user.id) {
                if(type === "qna") {
                    return user.qnaTicket
                } else if(type === "ts") {
                    return user.supportTicket
                }
            }
            return null
        })

        return(

        <div className="mt-5 middle">

            <div id="modal-detail" className="normalmodal">
                <div className="normalmodal-content col-md-6">
                    <span className="close" onClick={this.closeModal.bind(this)}>&times;</span>
                    <br/><br/>
                    {emailDetail}
                </div>
            </div>
            
            <i className="far fa-envelope fa-3x text-green"></i>
            <b>{type === "qna" ? "Question and Answer" : type === "ts" ? "Technical Support" : null}</b>
            <small>Your remaining tickets is: {ticketCounter}</small>
            
            <div className="row justify-content-center col-md-12" style={{marginTop: "20px"}}>
                <div className="text-left col col-md-6">
                    <b style={{marginBottom:"5px"}}>Send Email:</b>

                    { type === "qna" ? 
                        ticketCounter < 1 ?
                            <label className="text-red">You have reached your ticket limit. You will no longer be able to use this feature. Please contact for futher info.</label>
                        :
                            <div>
                                <select id="qnaCourse" className="form-control" value={this.state.qnaCourse} onChange={e => this.setState({selectedCourseId: e.target[e.target.selectedIndex].id, qnaCourse: e.target.value})}>
                                    <option defaultValue>Choose Course</option>
                                    {courseList}
                                </select>
                            
                                { this.state.qnaCourse ?
                                    <select id="qnaModule" className="form-control" value={this.state.qnaModule} onChange={e => this.setState({selectedModuleId: e.target[e.target.selectedIndex].id, qnaModule: e.target.value})}>
                                        <option defaultValue>Choose Module</option>
                                        {moduleList}
                                    </select>
                                :   null }
                                
                                { this.state.qnaModule ?
                                    <select id="qnaTopic" className="form-control" value={this.state.qnaTopic} onChange={e => this.setState({qnaTopic: e.target.value})}>
                                        <option defaultValue>Choose Topic</option>
                                        {topicList}
                                    </select>
                                : null }

                                { this.state.qnaTopic ?
                                    <div>
                                        <input id="subject" type="text" className="form-control"  value={this.state.subject} placeholder="Subject" onChange={e => this.setState({subject: e.target.value})} />
                                    <textarea id="message" type="text" className="form-control"  value={this.state.message} placeholder="Your question..." onChange={e => this.setState({message: e.target.value})} />
                                    </div>
                                : null }
                            </div>
                    : type === "ts" ?
                        ticketCounter < 1 ?
                            <label className="text-red">You have reached your ticket limit. You will no longer be able to use this feature. Please contact for futher info.</label>
                        :
                            <div>
                                <input id="subject" type="text" className="form-control" placeholder="Subject" value={this.state.subject} onChange={e => this.setState({subject: e.target.value})}/>
                                <textarea id="message" type="text" className="form-control" placeholder="Your question.." value={this.state.message} onChange={e => this.setState({message: e.target.value})}/>
                                
                                <div className="text-left card-body">
                                    <img style={{paddingTop:"10px", paddingBottom:"10px"}} src={this.state.imageDisplay} alt="" width="20%"/>
                                    <br/>
                                    <input type="file" onChange={this.fileSelectHandler} />
                                    <br/>
                                    <progress className="mt-2" value={this.state.progress} max="100" style={{width:"200px"}}/>
                                </div>
                            </div>
                    : null }
                  
                   <div className="text-left">
                        { isLoading === true ? 
                            <small>Loading...</small>
                        : 
                            mesej  ?
                                <small className={mesej.type === 'sendEmailError' ? "text-red" : mesej.type === 'sendEmailSuccess'  ? "text-green" : null }>
                                    {mesej.msg}
                                </small>
                            : null
                        }
                   </div>

                    <br/>
                     
                    <button onClick={this.sendEmail.bind(this)} className="btn all-btn"><i className="fas fa-envelope"/>{' '}Send Question</button>
                </div>

                <div className="text-left col col-md-6">
                    <b>Email history/status:</b>
                    
                    <table className="mt-2 table">
                        <thead>
                            <tr>
                                <th scope="col">Date</th>
                                <th scope="col">Subject</th>
                                <th scope="col">Details</th>
                                <th scope="col">Status</th>
                            </tr>
                        </thead>

                        <tbody>
                            { isLoadingHistory === true ? <div className="middle"><div className="loader "></div></div> : historyList }

                            { emailCount.length < 1 ?
                                <i>Currently no sent email.</i>
                            : null }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        
        )
    }   
}

const mapStateToProps = (state) => {
    return {
        sendEmailError: state.forms.sendEmailError,
        sendEmailSuccess: state.forms.sendEmailSuccess,
    }
  }

const mapDispatchToProps = (dispatch) => {
    return {
      sendEmail: (data) => dispatch(sendEmail(data)),
      updateTicketCounter: (ticketType, ticketCounter, userId) => dispatch(updateTicketCounter(ticketType, ticketCounter, userId)),
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(EmailUser);