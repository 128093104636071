import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { storage } from "../../../config/fbConfig";

import { addEvents } from "../../../stores/actions/eventsAction";

class Events extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventsListId: "",
      eventsName: "",
      eventsTopic: "",
      eventsStatus: "",
      eventsCode: "",
      eventsContent: "",
      eventsDate: "",

      eventsImage: null,
      imageDisplay: "",
      imageProgress: "",

      isNewList: false,
      isLoading: null,
      message: null,
      addedEventsId: null,
    };
    this.baseState = this.state;
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { addEventsError, addEventsSuccess } = this.props;

    if (prevProps.addEventsError !== addEventsError) {
      this.setState({
        isLoading: false,
        message: {
          msg: addEventsError.message,
          type: addEventsError.type,
        },
      });
    }

    if (prevProps.addEventsSuccess !== addEventsSuccess) {
      this.setState(
        {
          isLoading: false,
          message: {
            msg: addEventsSuccess.message,
            type: addEventsSuccess.type,
          },
          addedEventsId: addEventsSuccess.id,
        },
        () => {
          setTimeout(() => {
            this.props.hide();
            document.getElementById("clickAddEvents").click();
            setTimeout(() => {
              this.setState(this.baseState);
            }, 3000);
          }, 2000);
        }
      );
    }
  };

  handleChange = (e) => {
    switch (e.target.id) {
      case "eventsListId":
        const index = e.target.selectedIndex;
        const optionElement = e.target.childNodes[index];
        const optionElementId = optionElement.getAttribute("id");
        
        this.setState({
          [e.target.id]: e.target.value,
          eventsName: optionElementId,
        });
        break;
      case "eventsName":
        this.setState({
          [e.target.id]: e.target.value,
          eventsListId : "",
        });
        break;
      default:
        this.setState({
          [e.target.id]: e.target.value,
        });
        break;
    }
  };

  imageSelectHandler = (event) => {
    this.setState({
      eventsImage: event.target.files[0],
      imageDisplay: URL.createObjectURL(event.target.files[0]), //ubah imgUrl to pic
    });
  };

  addEvents = async (e) => {
    const { eventsImage } = this.state;

    var result = window.confirm("Confirm add?");

    if (result) {
      
      this.setState({
        isLoading: true,
      });
      if (eventsImage !== null) {
        const uploadTask = storage
          .ref(`images/${eventsImage.name}`)
          .put(eventsImage);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const imageProgress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            this.setState({
              imageProgress: imageProgress,
            });
          },

          (error) => {
            alert(error);
          },

          () => {
            storage
              .ref("images")
              .child(eventsImage.name)
              .getDownloadURL()
              .then((imgUrl) => {
                this.setState({
                  eventsImage: {
                    fileUrl: imgUrl,
                    fileName: eventsImage.name,
                  },
                });
                this.props.addEvents(this.state);
              });
          }
        );
      } else {
        this.props.addEvents(this.state);
      }
    }
  };

  showNewList = (status) => {
    this.setState({
      isNewList: status,
    });
  };

  render() {
    const { eventsList } = this.props;
    const { message, isLoading } = this.state;

    return (
      <div>
        <div className="row">
          <p className="col col-md-4">
            <b>Code:</b>
            <input
              type="text"
              id="eventsCode"
              onChange={this.handleChange}
              className="form-control"
              value={this.state.eventsCode}
              placeholder="Code"
            />
          </p>

          <p className="col col-md-8">
            <b>Name:</b>
            {this.state.isNewList ? (
              <>
                <input
                  type="text"
                  id="eventsName"
                  onChange={this.handleChange}
                  className="form-control"
                  value={this.state.eventsName}
                  placeholder="Name"
                />
                <span
                  className="badge bg-blue text-white"
                  onClick={this.showNewList.bind(this, false)}
                >
                  <i className="fas fa-list-ul"></i> List
                </span>
              </>
            ) : (
              <>
                <select
                  id="eventsListId"
                  onChange={this.handleChange}
                  className="form-control"
                >
                  <option initialValues>Choose events name / add new </option>
                  {eventsList &&
                    eventsList.map((el, i) => {
                      return (
                        <option key={i} id={el.name} value={el.id}>
                          {el.name}
                        </option>
                      );
                    })}
                </select>
                <span
                  className="badge bg-blue text-white"
                  onClick={this.showNewList.bind(this, true)}
                >
                  <i className="fas fa-plus-circle"></i> New
                </span>
              </>
            )}
          </p>
        </div>

        <div className="row">
          <p className="col col-md-12">
            <b>Topic:</b>
            <input
              type="text"
              id="eventsTopic"
              onChange={this.handleChange}
              className="form-control"
              value={this.state.eventsTopic}
              placeholder="Topic"
            />
          </p>
        </div>

        <div className="row">
          <p className="col col-md-4">
            <b>Status: </b>
            <select
              id="eventsStatus"
              onChange={this.handleChange}
              className="form-control"
            >
              <option defaultValue>Choose status</option>
              <option>Active</option>
              <option>Not Active</option>
            </select>
          </p>

          <p className="col col-md-4">
            <b>Date:</b>
            <input
              onChange={(e) => this.setState({ eventsDate: e.target.value })}
              value={this.state.eventsDate}
              className="col col-md-8 form-control"
              type="date"
            />
          </p>
        </div>

        <p>
          <b>Content:</b>
          <textarea
            id="eventsContent"
            type="text"
            className="form-control"
            onChange={this.handleChange}
            value={this.state.eventsContent}
            placeholder="Content"
          />
        </p>

        <div className="mt-4 mb-5">
          <b>Featured Image:</b>
          <br />
          {this.state.imageDisplay ? (
            <div>
              <img
                alt="events2"
                src={this.state.imageDisplay}
                width="30%"
                className="text-center"
              />
            </div>
          ) : null}
          <input
            className="mt-2"
            type="file"
            onChange={this.imageSelectHandler.bind(this)}
          />
          <br />
          <progress
            value={this.state.imageProgress}
            max="100"
            style={{ width: "250px" }}
          />
        </div>

        {isLoading === true ? (
          <small>Loading...</small>
        ) : message ? (
          <small
            className={
              message.type === "addEventsError"
                ? "text-red"
                : message.type === "addEventsSuccess"
                ? "text-green"
                : null
            }
          >
            {message.msg}
          </small>
        ) : null}

        <div className="mt-5 row justify-content-end">
          <button
            type="button"
            className="btn m-2 btn-outline-black"
            onClick={this.addEvents.bind(this)}
          >
            <i className="fas fa-plus-circle" /> add events
          </button>
        </div>

        <div hidden={true}>
          <Link
            id="clickAddEvents"
            to={`/systemmanager?topic=events&subtopic=module&id=${this.state.addedEventsId}&code=${this.state.eventsCode}`}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    addEventsError: state.events.addEventsError,
    addEventsSuccess: state.events.addEventsSuccess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addEvents: (events) => dispatch(addEvents(events)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Events);
