const initState = {
    subtopics: []
}

const adReducer = (state = initState, action ) => {

    switch (action.type) {

        case 'ADDED_AD':
            return {
                ...state,
                addAdSuccess: {
                    message: action.message,
                    type: 'addAdSuccess'
                }
            }
        case 'ADDED_AD_ERROR':
            return {
                ...state,
                addAdError: {
                    message: action.err.message,
                    type: 'addAdError'
                }
            }

        case 'DELETED_AD':
            return {
                ...state,
                deleteAdSuccess: {
                    message: action.message,
                    type: 'deleteAdSuccess'
                }
            }
        case 'DELETED_AD_ERROR':
            return {
                ...state,
                deleteAdError: {
                    message: action.err.message,
                    type: 'deleteAdError'
                }
            }

        default:
            return state;
    }
}

export default adReducer