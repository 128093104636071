export const addEvents = (events) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    
    if (
      !events.eventsCode || !events.eventsName ||
      !events.eventsTopic ||
      !events.eventsStatus ||
      !events.eventsContent
    ) {
      dispatch({
        type: "ADDED_EVENTS_ERROR",
        err: { message: "Input field required." },
      });
    } else {
        
        let id = events.eventsListId;

      if (events.isNewList) {
        id = await firestore
          .collection("eventsList")
          .add({
            status: events.eventsStatus,
            name: events.eventsName,
          })
          .then((resp) => {
            return resp.id;
          });
      }
      else if (events.eventsStatus === "Active"){
        firestore
        .collection("eventsList")
        .doc(id)
        .update({
            status: "Active",
        })
      }
      
      firestore
        .collection("events")
        .add({
          eventsStatus: events.eventsStatus,
          eventsListId: id,
          eventsName: events.eventsName,
          eventsTopic: events.eventsTopic,
          eventsCode: events.eventsCode,
          eventsDate: events.eventsDate,
          eventsContent: events.eventsContent,
          eventsImage: events.eventsImage,
        })
        .then((resp) => {
          dispatch({
            type: "ADDED_EVENTS",
            message: "Events added successfully.",
            id: resp.id,
          });
        })
        .catch((err) => {
          dispatch({ type: "ADDED_EVENTS_ERROR", err });
        });
    }
  };
};

export const requestCert = (eventsAttendees) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    if (
      !eventsAttendees.eventsAttendeesStatus ||
      !eventsAttendees.eventsId ||
      !eventsAttendees.userId ||
      !eventsAttendees.requestedDate
    ) {
      dispatch({
        type: "REQUESTED_CERT_ERROR",
        err: { message: "Input field required." },
      });
    } else {
      firestore
        .collection("eventsAttendees")
        .add({
          eventsAttendeesStatus: eventsAttendees.eventsAttendeesStatus,
          eventsId: eventsAttendees.eventsId,
          userId: eventsAttendees.userId,
          requestedDate: eventsAttendees.requestedDate,
        })
        .then((resp) => {
          dispatch({
            type: "REQUESTED_CERT",
            message: "Request cert successfully.",
            id: resp.id,
          });
        })
        .catch((err) => {
          dispatch({ type: "REQUESTED_CERT_ERROR", err });
        });
    }
  };
};

export const updateEvents = (updatedEvents, eventsId) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    if (
      !updatedEvents.eventsCode ||
      !updatedEvents.eventsName ||
      !updatedEvents.eventsTopic ||
      !updatedEvents.eventsStatus ||
      !updatedEvents.eventsContent ||
      !updatedEvents.eventsDate
    ) {
      dispatch({
        type: "UPDATED_EVENTS_ERROR",
        err: { message: "Input field required." },
      });
    } else {
      let id = updatedEvents.eventsListId;

      if (updatedEvents.isNewList) {
        id = await firestore
          .collection("eventsList")
          .add({
            status: updatedEvents.eventsStatus,
            name: updatedEvents.eventsName,
          })
          .then((resp) => {
            return resp.id;
          });
      }
      else if (updatedEvents.eventsStatus === "Active"){
        firestore
        .collection("eventsList")
        .doc(id)
        .update({
            status: "Active",
        })
      }

      firestore
        .collection("events")
        .doc(eventsId)
        .update({
          eventsStatus: updatedEvents.eventsStatus,
          eventsListId: id,
          eventsName: updatedEvents.eventsName,
          eventsTopic: updatedEvents.eventsTopic,
          eventsCode: updatedEvents.eventsCode,
          eventsDate: updatedEvents.eventsDate,
          eventsContent: updatedEvents.eventsContent,
          eventsImage: updatedEvents.eventsImage,
        })
        .then(() => {
          dispatch({
            type: "UPDATED_EVENTS",
            message: "Events updated successfully.",
          });
        })
        .catch((err) => {
          dispatch({ type: "UPDATED_EVENTS_ERROR", err });
        });
    }
  };
};

export const updateEventsAttendees = (eventsAttendees, eventsAttendeesId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    if (!eventsAttendees.eventsAttendeesStatus) {
      dispatch({
        type: "REQUESTED_CERT_ERROR",
        err: { message: "Input field required." },
      });
    } else {
      firestore
        .collection("eventsAttendees")
        .doc(eventsAttendeesId)
        .update({
          eventsAttendeesStatus: eventsAttendees.eventsAttendeesStatus,
        })
        .then((resp) => {
          dispatch({
            type: "UPDATED_EVENTS_ATTENDEES",
            message: "event attendees updated successfully.",
            id: resp.id,
          });
        })
        .catch((err) => {
          dispatch({ type: "UPDATED_EVENTS_ATTENDEES_ERROR", err });
        });
    }
  };
};

export const deleteEvents = (eventsId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    firestore
      .collection("events")
      .doc(eventsId)
      .delete()
      .then(() => {
        dispatch({
          type: "DELETED_EVENTS",
          message: "Events deleted successfully.",
        });
      })
      .catch((err) => {
        dispatch({ type: "DELETED_EVENTS_ERROR", err });
      });
  };
};

export const deleteEventsAttendees = (eventsAttendeesId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    firestore
      .collection("eventsAttendees")
      .doc(eventsAttendeesId)
      .delete()
      .then(() => {
        dispatch({
          type: "DELETED_EVENTS_ATTENDEES",
          message: "Events Attendees deleted successfully.",
        });
      })
      .catch((err) => {
        dispatch({ type: "DELETED_EVENTS_ATTENDEES_ERROR", err });
      });
  };
};

export const employerMode = (isEmployer, eventsId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    firestore
      .collection("events")
      .doc(eventsId)
      .update({
        employerMode: isEmployer,
      })
      .then(() => {
        dispatch({
          type: "UPDATE_EMPLOYER_MODE",
          message: "isEmployer mode updated successfully.",
        });
      })
      .catch((err) => {
        dispatch({ type: "UPDATE_EMPLOYER_MODE_ERROR", err });
      });
  };
};
