import React from "react";
import { Document, Page, Text, View, Image, StyleSheet, Font } from "@react-pdf/renderer";
import logoInsightX from './ecert2_InsightX_2.png';
import logo from './ecert2.png';
import font from './Roboto-Medium.ttf';
import fontRegular from './Roboto-Regular.ttf';
import moment from 'moment';

Font.register({ family: 'Roboto-Medium', src: font });
Font.register({ family: 'Roboto-Regular', src: fontRegular });

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Roboto-Medium',
    },

    backgroundImage: {
        position: 'relative',
        height: '100%',
        width: '100%',
    },

    name: {
        position: 'absolute',
        textAlign: 'center',
        top: '34.5vh',
        width: '100%',
        fontSize: '18px',

    },

    ic: {
        position: 'absolute',
        textAlign: 'center',
        top: '37.5vh',
        width: '100%',
        fontSize: '18px',
    },

    courseName: {
        position: 'absolute',
        textAlign: 'center',
        top: '45.5vh',
        width: '100%',
        fontSize: '18px'
    },


    date: {
        position: 'absolute',
        textAlign: 'center',
        top: '53.5vh',
        width: '100%',
        fontSize: '18px',
    }
});

export function HalalInsightEcertPdf(props) {
    let IC = props.profile.identityCardNo;
    var regExp = /[a-zA-Z]/;
    const charToInsert = '-';
    const indexToInsertAt = [6, 8];

    function FilterIC() {
        if (IC.includes("-") || IC.match(regExp)) {
            return IC
        } else {
            return IC.substring(0, indexToInsertAt[0]) + charToInsert + IC.substring(indexToInsertAt[0], indexToInsertAt[1]) + charToInsert + IC.substring(indexToInsertAt[1]);
        }
    }
    return (
        <Document>
            <Page orientation="potrait" style={styles.page}>

                <View style={styles.backgroundImage}>
                            {props.courseName.toLowerCase().includes('halal insightx')  ?
                                <Image src={logoInsightX} />
                                    : <Image src={logo} />}

                    <Text style={styles.name}>{props.profile.fullname}</Text>
                    <Text style={styles.ic}>{FilterIC()}</Text>
                    <Text style={styles.courseName}>{props.courseName}</Text>
                    <Text style={styles.date}>21 March 2024 - 03 April 2024</Text>
                </View>
            </Page>
        </Document>
    );
}