import React, { Component } from 'react';
import { connect } from 'react-redux'

import CreateGroup from './CreateGroup';

import { deleteGroup } from '../../../../stores/actions/classAction';

class CreatedGroup extends Component {

    state = {
        selectedGroupId: '',
        edit_groupData:'',
    }

    // componentDidUpdate = (prevProps, prevState) => { 
    //     const { deleteGroupError, deleteGroupSuccess } = this.props;

    //     if(prevProps.deleteGroupError !== deleteGroupError) {
    //         alert(deleteGroupError.message)
    //     }

    //     if(prevProps.deleteGroupSuccess !== deleteGroupSuccess) {
    //         alert(deleteGroupSuccess.message)
    //     }
    // } 

    openGroup = (groupId) => {
        this.setState({
            selectedGroupId: groupId
        })
    }

    closeGroup = () => {
        this.setState({
            selectedGroupId:''
        })
    }

    setGroupId = async (groupId) => {
        this.setState({
            selectedGroupId: groupId,
        })
    }

    openEdit = async (groupId) => {

        await this.setGroupId(groupId);

        const groupData = this.props.pcpGroup2 ? this.props.pcpGroup2[groupId] : null

        if (groupData) {
            this.setState({
                edit_groupData: groupData
            })
        }

        document.getElementById("modal-edit-group").style.display = "block";
    }

    closeEdit = () =>{
        document.getElementById("modal-edit-group").style.display = "none";
        // this.setState({ selectedGroupId:'' })
    }

    deleteGroup = (groupId) => {
        var result = window.confirm("Confirm delete?");
        if (result) {
            this.props.deleteGroup(groupId);
        }
    }

    render () {

        const { selectedBatchId, pcpGroup, pcpList, groupedPcp } = this.props;
        const { selectedGroupId, edit_groupData } = this.state;

        let groupList;

        groupList = pcpGroup && pcpGroup.map((group,i) => {
            if(group.batchId === selectedBatchId) {
                return (
                    <div key={i} className="detail-btn">
                        <b>{group.groupName}</b>
                        <span className='float-right'>
                            <span className='m-1' 
                                onClick={this.openEdit.bind(this, group.id)}>
                                <i className='fas fa-edit' />
                            </span>
    
                            <span className='m-1' 
                                onClick={this.deleteGroup.bind(this, group.id)}>
                                <i className='fas fa-trash text-red' />
                            </span>
    
                            <span className='m-1'
                                onClick={!selectedGroupId ? this.openGroup.bind(this, group.id) : this.closeGroup.bind(this)}>
                                <i className={group.id !== selectedGroupId ? "fas fa-angle-down" : "fas fa-minus"} />
                            </span>
    
                        </span>
    
                        { group.id === selectedGroupId ?
                            <table className="table bg-white">
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th className="text-left">Name</th>
                                        <th className='text-left'>Email</th>
                                    </tr>
                                </thead>
    
                                <tbody>
                                    { group.addedPcp && group.addedPcp.map((data,i) => {
                                        let no = 1;
                                        no = no+i;
                                        return (
                                            pcpList && pcpList.map(pcp => {
                                                if(data.userId === pcp.userId) {
                                                    return (
                                                        <tr key={i} className="content">
                                                            <td>{no}.</td>
                                                            <td className='text-left'>{pcp.fname} {pcp.lname}</td>
                                                            <td className='text-left'>{pcp.email}</td>
                                                        </tr>
                                                    )
                                                } return null
                                            })
                                        )
                                    })}
                                </tbody>
                            </table>
    
                        : null }
                    </div>
                )
                
            } return null
        })

        return (

            <div>

                { selectedGroupId ?
                    <div id="modal-edit-group" className="mymodal">
                        <div className="mymodal-content col-md-8">
                            <span className="close" onClick={this.closeEdit.bind(this)}>&times;</span>
                            <CreateGroup pcpList={pcpList} groupedPcp={groupedPcp} groupData={edit_groupData} selectedGroupId={selectedGroupId} actionType="edit" />
                        </div>
                    </div>
                : null }

                <div className='col col-md-10'>
                    {groupList}
                </div>
            </div>

        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        pcpGroup2: state.firestore.data.pcpGroup,

        deleteGroupError: state.classes.deleteGroupError,
        deleteGroupSuccess: state.classes.deleteGroupSuccess,
    }
}
  
const mapDispatchToProps = (dispatch) => {
    return {
      deleteGroup: (groupId) => dispatch(deleteGroup(groupId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreatedGroup);