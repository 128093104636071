import React, { Component } from 'react';
import { connect } from 'react-redux'
import { storage } from '../../config/fbConfig'

import { addAd, deleteAd } from '../../stores/actions/adAction';

class AdsManager extends Component {
    
    constructor(props){
        super(props)
        this.state = {
            modalType: '',
    
            selectedAds: null,
            displayAds: null,
            fileProgress: 0,
        
            isLoading: null,
            message: null,
        }
        this.baseState = this.state 
    }
    
    componentDidUpdate = (prevProps, prevState) => { 

        const { addAdError, addAdSuccess, deleteAdError, deleteAdSuccess } = this.props;

        if(prevProps.addAdError !== addAdError) {
            this.setState({
                isLoading: false,
                message: {
                    msg: addAdError.message,
                    type: addAdError.type,
                }
            })
        }

        if(prevProps.addAdSuccess !== addAdSuccess) {
            this.setState({
                isLoading: false,
                message: {
                    msg: addAdSuccess.message,
                    type: addAdSuccess.type,
                }
            })
            setTimeout(() => { 
                this.setState(this.baseState)
                this.hideModal();
            }, 2000);
        }
        
        if(prevProps.deleteAdSuccess !== deleteAdSuccess) {
            alert(deleteAdSuccess.message)
            setTimeout(() => { 
                this.setState(this.baseState)
            }, 2000);
        }
        
        if(prevProps.deleteAdError !== deleteAdError) {
            alert(deleteAdError.message)
        }
    } 

    fileSelectHandler = (e) => {
        if(e.target.files[0]) {
            this.setState({ 
                selectedAds: e.target.files[0],
                displayAds: URL.createObjectURL(e.target.files[0]) //ubah imgUrl to pic
            });
        }
    }

    hideModal = () => {
        document.getElementById("modal-add-ads").style.display = "none";
    }

    showModal = (type) => {
        this.setState({
            modalType: type
        })
        document.getElementById("modal-add-ads").style.display = "block";
    }

    addAd = () => {

        const { selectedAds } = this.state;

        var result = window.confirm("Confirm add?");
    
        if (result) {

            this.setState({
                isLoading: true
            })

            if ( selectedAds !== null ) {

                const uploadTask = storage.ref(`ads/${selectedAds.name}`).put(selectedAds);
    
                uploadTask.on('state_changed', 
                    (snapshot) => {
                        const fileProgress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                        this.setState({
                            fileProgress: fileProgress
                        });
                    }, 
    
                    (error) => {
                        alert(error)
                    }, 
    
                    () => {
                        storage.ref('ads').child(selectedAds.name).getDownloadURL().then(url => {

                            let data = {
                                fileName: selectedAds.name,
                                fileUrl: url
                            }

                            this.props.addAd(data)
                        })
                    }
                );
            } else {
                alert('Please select file!')
            }
        }
    }

    deleteAd = (id) => {

        var result = window.confirm("Confirm delete?");
    
        if (result) {
            this.props.deleteAd(id);
        }
    }

    render(){

        const { ads } = this.props;
        const { isLoading, message } = this.state;

        let hasData = [], isLoadingData= true;

        let no = 0;

        let adList = ads && ads.map((ad, i) => {
            hasData.push(ad)
            isLoadingData = false;

            no = no+1;
            return (
                <tr key={i}>
                    <th scope="row">{no}</th>
                    <td className="text-left">{ad.fileName}</td>
                    <td className="text-left">
                        <div><img alt="" width="150px" src={ad.fileUrl} /></div>
                    </td>
            
                    <td>
                        <button type="button" className="m-1 btn btn-outline-red" onClick={this.deleteAd.bind(this, ad.id)}><i className="fas fa-trash"></i></button>
                    </td>
                </tr>
            )
        })

        return(

        <div>

            <div id="modal-add-ads" className="mymodal">
                <div className="mymodal-content col-md-6">
                    <span className="close" onClick={this.hideModal.bind(this)}>&times;</span>
                    { this.state.modalType === "add" ?
                        <div>
                            <b className="uppercase">Add new ad
                                <div className="title-border"></div>
                            </b>
        
                            { this.state.displayAds ? 
                                <div><img alt="" width="200px" src={this.state.displayAds} /></div>
                            : null  }

                            <input className="mt-2" type="file" onChange={this.fileSelectHandler.bind(this)} />
                            <br />
                            <progress value={this.state.fileProgress} max="100" style={{width:"250px"}}/>
                            <br/>

                            { isLoading === true ? 
                                <small>Loading...</small>
                            : 
                                message  ?
                                    <small className={message.type === 'addAdError' ? "text-red" : message.type === 'uccess'  ? "text-green" : null }>
                                        {message.msg}
                                    </small>
                                : null
                            }

                            <div className="mt-5 row justify-content-end">
                                <button type="button"
                                    className="btn m-2 btn-outline-black" onClick={this.addAd.bind(this)}><i className="fas fa-plus-circle"/>{' '}add ads
                                </button>
                            </div>
                        </div>
                    : null }
                </div>
            </div>

            <h3 className="text-left">Ad Manager
                <div className="title-border"></div>
            </h3>

            <button className="btn btn-outline-black" onClick={this.showModal.bind(this, 'add')}>
                <i className="fas fa-plus-circle"/>{' '}New Ad
            </button>

            { isLoadingData === true ? 
                <p>Please wait... Data loading...</p> 
            : 
                hasData.length > 0 ? 
                    <table className="mt-5 table">
                        <thead>
                            <tr>
                                <th scope="col">No.</th>
                                <th scope="col">File Name</th>
                                <th scope="col">Attachment</th>
                                <th scope="col">Delete</th>
                            </tr>
                        </thead>
        
                        <tbody>
                            {adList}
                        </tbody>
                    </table>
                : 
                    <p>No faq available</p>
            }
        </div>
        )
    }
        
}

const mapStateToProps = (state) => {
    return {
        addAdError: state.ads.addAdError,
        addAdSuccess: state.ads.addAdSuccess,
        deleteAdSuccess: state.ads.deleteAdSuccess,
        deleteAdError: state.ads.deleteAdError,
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
      return {
        addAd: (ads) => dispatch(addAd(ads)),
        deleteAd: (id) => dispatch(deleteAd(id))
      }
  }
    
export default connect(mapStateToProps, mapDispatchToProps)(AdsManager);
  