import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import ExtendEnrollment from './ExtendEnrollment';
import NewEnrollment from './NewEnrollment';
import DeleteEnrollment from './DeleteEnrollment';

class EnrollmentManager extends Component{

    render(){

        const { query, show, hide, users, courses } = this.props;

        let query_filter = query.get('filter')

        return(

            <div className="admin">
                <h3  className="text-left">Enrollment Manager
                    <div className="title-border"></div>
                </h3>

                <div className="tab">
                    <Link className={query_filter === 'new-enrollment' ? "tablinks active" : "tablinks"} to="/adminmanager?topic=enrollment&filter=new-enrollment">New Enrollment</Link>
                    <Link className={query_filter === 'extend-enrollment' ? "tablinks active" : "tablinks"} to="/adminmanager?topic=enrollment&filter=extend-enrollment">Extend Enrollment</Link>
                    <Link className={query_filter === 'delete-enrollment' ? "tablinks active" : "tablinks"} to="/adminmanager?topic=enrollment&filter=delete-enrollment">Delete Enrollment</Link>
                </div>

                <div className="tabcontent">
                    <div style={{margin:"20px"}}>
                        { query_filter === 'new-enrollment'?
                            <NewEnrollment history={this.props.history} query={query} show={show} hide={hide} users={users} courses={courses} />
                        : query_filter === 'extend-enrollment'?
                            <ExtendEnrollment history={this.props.history} query={query} users={users} courses={courses} />
                        : query_filter === 'delete-enrollment'?
                            <DeleteEnrollment history={this.props.history} query={query} users={users} courses={courses} />
                        :
                            <p>Please choose action first.</p>
                        }
                    </div>
                </div>   
            </div>
        )
    }
}

export default EnrollmentManager;