import moment from 'moment';

export const contactUs = (data) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => { 

        const firestore = getFirestore();

        const { name, email, contactNo, message } = data;

        if (!name || !email || !contactNo || !message) {
            dispatch({ type:'ADDED_CONTACT_US_ERROR', err: { message: 'Input field required.' }});
        } else {
            firestore.collection('contactUs').add({
                name: data.name,
                email: data.email,
                contactNo: data.contactNo,
                message: data.message,
            }).then(() => {
                dispatch({ type:'ADDED_CONTACT_US', message: 'Thank you. Your message has been sent.'});
            }).catch((err) => {
                dispatch({ type:'ADDED_CONTACT_US_ERROR', err});
            })
        }
    }
}

export const sendEmail = (data) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => { 

        const firestore = getFirestore();

        if(!data.subject || !data.message) {
            dispatch({ type:'SEND_EMAIL_ERROR', err: { message: 'Input field required.' }});
        } else {
            firestore.collection('supportEmail').add({
                userId:data.userId,
                message:data.message,
                status:'Sent',
                type:data.type,
                
                attachment:data.attachment ? data.attachment : null,
                subject:data.subject,
    
                qnaCourse: data.qnaCourse,
                qnaModule: data.qnaModule,
                qnaTopic: data.qnaTopic,
    
                date: moment().format('YYYY-MM-DD'),
                time: moment().format('h:mm:ss a')
    
            }).then(() => {
                dispatch({ type:'SEND_EMAIL', message: 'Thank you. Your message has been sent.'});
            }).catch((err) => {
                dispatch({ type:'SEND_EMAIL_ERROR', err});
            })
        }

    }
}

export const updateEmailStatus = (status, emailId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => { 

        const firestore = getFirestore();

        firestore.collection("supportEmail").doc(emailId).update({
            status: status,
        })
        .then(() => {
            dispatch({ type:'UPDATED_EMAIL_STATUS', message: 'Status updated.'});
        }).catch((err) => {
            dispatch({ type:'UPDATED_EMAIL_STATUS_ERROR', err});
        })
    }
}

export const updateTicketCounter = (type, ticketCount, userId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => { 

        const firestore = getFirestore();

        firestore.collection("users").doc(userId).update(
            
            type === "ts" ?
                { supportTicket: ticketCount }
            : type === "qna" ?
                { qnaTicket : ticketCount }
            : null
        )
        .then(() => {
            dispatch({ type:'UPDATED_TICKET_COUNTER', message: 'Ticket counter updated.'});
        }).catch((err) => {
            dispatch({ type:'UPDATED_TICKET_COUNTER_ERROR', err});
        })
    }
}
