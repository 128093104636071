import React, { Component } from 'react';
import { connect } from 'react-redux'

import { updatePCB_selectedParticipants, updatePCP_status_batchId } from '../../../../stores/actions/classAction';

// wajib ada props userId, selectedCourseId, pcb, pcp, courseE
class MoveToBatch extends Component {

    getPcpData = async (userId, selectedCourseId) => {

        const { physicalClassParticipants, courseEnrollments } = this.props;
        let data = {}

        physicalClassParticipants && physicalClassParticipants.map(part => {
            if (userId === part.userId &&  part.courseId === selectedCourseId) {
                return (
                    courseEnrollments && courseEnrollments.map(enrolled => {
                        if(userId === enrolled.userId && part.userId === enrolled.userId && part.courseId === enrolled.courseId) {
                            return (
                                data = {
                                    pcpId: part.id, 
                                    enrolledCourseId: enrolled.id,
                                    pcpStatus: part.status
                                }
                            )
                        }
                        return null
                    })
                )
            }
            return null
        })
        return data
    }

    moveUser = async (newBatchId, newStatus) => {

        const { userId, selectedParticipants, selectedCourseId, selectedBatchId } = this.props;

        let getData = await this.getPcpData(userId, selectedCourseId);

        let pcpId = getData.pcpId, 
        enrolledCourseId = getData.enrolledCourseId, 
        pcpStatus = (getData.pcpStatus === "requested" || getData.pcpStatus === "listed") && newStatus === "" ? "listed" : newStatus; //if move batch from "requested", pcp auto update as listed (to get emel notify)

        var result = window.confirm("Are you sure?");

        if (result) {
            //to update new moved batch data
            await this.updateNewBatch(newBatchId, userId, pcpId, enrolledCourseId, pcpStatus);


            // update oldBatch selectedParticipants -- ONLY IF ada old data in PCB -- NEW REQ tak perlu
            if(selectedBatchId && selectedBatchId !== "") {

                let pcb_data = null;
    
                let removed = selectedParticipants.filter(selected => {
                    return selected.userId !== userId
                })
    
                pcb_data = {
                    selectedParticipants: [
                        ...removed,
                    ]
                }
                this.props.updatePCB_selectedParticipants(pcb_data, selectedBatchId)
            }
        }
    }

    updateNewBatch = async (newBatchId, userId, pcpId, enrolledCourseId, pcpStatus) => {

        const { physicalClassBatches } = this.props;

        let new_pcb_data = null, pcp_data = null;

        physicalClassBatches && physicalClassBatches.map(batch => {
            if(batch.id === newBatchId) {
                if(batch.selectedParticipants && batch.selectedParticipants.length >= 1) {
                    new_pcb_data = {
                        selectedParticipants: [
                            ...batch.selectedParticipants,
                            {
                                enrolledCourseId,
                                pcpId, 
                                userId
                            }
                        ]
                    }
                } else {
                    new_pcb_data = {
                        selectedParticipants: [
                            {
                                enrolledCourseId,
                                pcpId, 
                                userId
                            }
                        ]
                    }
                }
            }
            return null
        })

        pcp_data = {
            selectedBatchId: newBatchId,
            status: pcpStatus
        }

        this.props.updatePCP_status_batchId(pcp_data, pcpId);
        this.props.updatePCB_selectedParticipants(new_pcb_data, newBatchId);

        setTimeout(() => { 
            this.props.hideModal('modal-choose-batch');
        }, 1000);
    }

    render(){

        const { physicalClassBatches, selectedCourseId } = this.props;

        return (

            <div>
                <table className="mt-5 table ">
                    <thead>
                        <tr>
                            <th scope="col">No.</th>
                            <th scope="col text-left">Batch Name</th>
                            <th scope="col col-md-2">Choose</th>
                        </tr>
                    </thead>

                    <tbody>
                        { physicalClassBatches && physicalClassBatches.map((batch,i) => {
                            if(batch.courseId === selectedCourseId) {
                                let no = 1;
                                no = no + i
                                return (
                                    <tr key={i}>
                                        <td>{no}</td>
                                        <td className="text-left">{batch.batchName}</td>
                                        <td><span  onClick={this.moveUser.bind(this, batch.id, batch.status)} className="badge bg-blue text-white"><i className="fas fa-arrow-right"/></span></td>
                                    </tr>
                                )
                            } return null
                        })}
                    </tbody>
                </table>            
            </div>
        )
    }
}
  
const mapDispatchToProps = (dispatch) => {
    return {
        updatePCB_selectedParticipants: (data, pcbId) => dispatch(updatePCB_selectedParticipants(data, pcbId)),
        updatePCP_status_batchId: (data, pcpId) => dispatch(updatePCP_status_batchId(data, pcpId)),
    }
}
  
export default connect(null, mapDispatchToProps)(MoveToBatch);