import React, { Component } from 'react';
import { connect } from'react-redux'
import { signIn, forgotPassword } from '../../stores/actions/authAction'

class SignIn extends Component {

  state ={
    email:'',
    password:'',

    emailForgot:'',

    isLoadingLogin: null,
    isLoadingReset: null,
    message:null,
  }

  handleChange = (e) => {
    this.setState({
        [e.target.name]: e.target.value
    })
  }

  showPassword = () => {
    var x = document.getElementById("pass_login");

    if (x.type === "password") {
      x.type = "text";
      document.getElementById("eye").className = "fas fa-eye-slash"
    } else {
      x.type = "password";
      document.getElementById("eye").className = "fas fa-eye"
    }
  }

  hideForgot = () => {
    document.getElementById("modal-forgot-password").style.display = "none";
  }

  showForgot = () => {
    document.getElementById("modal-forgot-password").style.display = "block";
  }

  componentDidUpdate = (prevProps, prevState) => { 

    const { loginError, loginSuccess, resetPasswordError, resetPasswordSuccess } = this.props;

    if(prevProps.loginError !== loginError) {
      this.setState({
        isLoadingLogin: false,
        message: {
          msg: loginError.message,
          type: loginError.type,
        }
      })
    }

    if(prevProps.loginSuccess !== loginSuccess) {
      this.setState({
        isLoadingLogin: false,
        message: {
          msg: loginSuccess.message,
          type: loginSuccess.type,
        }
      })
    }

    if(prevProps.resetPasswordError !== resetPasswordError) {
      this.setState({
        isLoadingReset: false,
        message: {
          msg: resetPasswordError.message,
          type: resetPasswordError.type,
        }
      })
      setTimeout(() => {  
        this.setState({ message: null }) 
      }, 10000);
    }

    if(prevProps.resetPasswordSuccess !== resetPasswordSuccess) {
      this.setState({
        isLoadingReset: false,
      })

      var result = window.confirm(resetPasswordSuccess.message);
      if (result) {
        this.hideForgot();
        this.props.hide('hideSignIn');
        this.setState({ message: null })
      }
    }
  }

  login = (e) => {
    e.preventDefault();
    this.setState({
      isLoadingLogin: true
    })
    this.props.signIn(this.state)
  }

  reset = async () => {
    this.setState({
      isLoadingReset: true
    })
    await this.props.forgotPassword(this.state.emailForgot)
  }

  render() {

    const { message, isLoadingReset, isLoadingLogin } = this.state;

    return (

      <div className="enter-form">

          <div id="modal-forgot-password" className="normalmodal">
            <div className="normalmodal-content col-md-4">
              <span className="close" onClick={this.hideForgot.bind(this)}>&times;</span>
              <div className="mt-5">
                <p>Please insert your registered email:</p>
            
                <div className="input-group">
                  <span className="middle"><i className="fas fa-envelope"></i></span>
                  <input onChange={e => this.setState({emailForgot: e.target.value})} className="form-control" placeholder="Email"/>
                </div>
                

                { isLoadingReset === true ? 
                    <small>Loading...</small>
                : 
                    message  ?
                        <small className={message.type === 'resetPasswordError' ? "text-red" : message.type === 'resetPasswordSuccess'  ? "text-green" : null }>
                            {message.msg}
                        </small>
                    : null
                }
                
                <div className="mt-5 row justify-content-end">
                    <button onClick={this.reset.bind(this)}  type="button" className="btn all-btn"><i className="fas fa-sync-alt"/>{' '} Reset Password</button>
                </div>
              </div>
            </div>
          </div>

          <b className="middle">Login
            <div className="row justify-content-center"><div className="title-border"></div></div>
          </b>

          <form onSubmit={this.login.bind(this)}>
            
            <div className="input-group">
                <span className="middle"><i className="fas fa-envelope"></i></span>
                <input type="text" name="email" onChange={this.handleChange} className="form-control" placeholder="Email"/>
            </div>
            
            <div className="input-eye input-group">
                <span className="middle"><i className="fas fa-lock"></i></span>
                <input type="password" id="pass_login" name="password" onChange={this.handleChange} className="form-control" placeholder="Password"/>
                <div onClick={this.showPassword.bind(this)} className="icon-eye"><i id="eye" className="fas fa-eye"></i></div>
            </div>

            <span onClick={this.showForgot.bind(this)}><small><i>Forgot password?</i></small></span>
            
            <br/>

            { isLoadingLogin === true ? 
                <small>Loading...</small>
            : 
                message  ?
                    <small className={message.type === 'loginError' ? "text-red" : message.type === 'loginSuccess'  ? "text-green" : null }>
                        {message.msg}
                    </small>
                : null
            }

            <div className="row justify-content-end">
                <button type="submit" className="btn all-btn"><i className="fas fa-sign-in-alt"></i>{'  '}Login</button>
            </div>
          </form>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
      loginError: state.auth.loginError,
      loginSuccess: state.auth.loginSuccess,
      resetPasswordError: state.auth.resetPasswordError,
      resetPasswordSuccess: state.auth.resetPasswordSuccess,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
      signIn: (creds) => dispatch(signIn(creds)),
      forgotPassword: (email) => dispatch(forgotPassword(email))
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
