import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage';

// var config = {
//   apiKey: "AIzaSyAOEUoZiGL8j2D44pViSLQgu3QQF3N4ThM",
//   authDomain: "halal-dev-academy.firebaseapp.com",
//   databaseURL: "https://halal-dev-academy.firebaseio.com",
//   projectId: "halal-dev-academy",
//   storageBucket: "halal-dev-academy.appspot.com",
//   measurementId: "G-XZ5L9P8QHY"
//   // messagingSenderId: "188914445378",
//   //appId: "1:188914445378:web:6a84ffe673dc4cdd8b91db",
// };

var config = {
  apiKey: "AIzaSyD8XEWriA3vIIgz4SfS5vO92ENO6qGTjX4",
  authDomain: "halal-e-learning.firebaseapp.com",
  databaseURL: "https://halal-e-learning.firebaseio.com",
  projectId: "halal-e-learning",
  storageBucket: "halal-e-learning.appspot.com",
  measurementId: "G-9VP01NDSXJ"
};


firebase.initializeApp(config);

const storage = firebase.storage();
const auth = firebase.auth();

export {
  auth, storage, firebase as default
}