const initState = {}

const authReducer = (state = initState, action) => {
    switch(action.type) {
        case 'LOGIN_ERROR':
            return {
                ...state,
                loginError: {
                    message: action.err.message,
                    type: 'loginError'
                }
            }
        case 'LOGIN_SUCCESS':
            return {
                ...state,
                loginSuccess: {
                    message: action.message,
                    type: 'loginSuccess'
                }
            }

        case 'SIGNOUT_SUCCESS':
            return {
                ...state,
                logoutSuccess: {
                    message: action.message,
                    type: 'logoutSuccess'
                }
            }

        case 'SIGNOUT_ERROR':
            return {
                ...state,
                logoutError: {
                    message: action.err.message,
                    type: 'logoutError'
                }
            }


        case 'SIGNUP_SUCCESS':
            return {
                ...state,
                signupSuccess: {
                    message: action.message,
                    type: 'signupSuccess'
                }
            }
        case 'SIGNUP_ERROR':
            return {
                ...state,
                signupError: {
                    message: action.err.message,
                    type: 'signupError'
                }
            }
        
            
        case 'NEW_USER_ADDED_SUCCESS':
            return {
                ...state,
                newUserAddedSuccess: {
                    message: action.message,
                    type: 'newUserAddedSuccess'
                }
            }
        case 'NEW_USER_ADDED_ERROR':
            return {
                ...state,
                newUserAddedError: {
                    message: action.err.message,
                    type: 'newUserAddedError'
                }
            }
            

        case 'VERIFIED_SUCCESS':
            return {
                ...state,
                verifiedSuccess: action.message
            }
        case 'VERIFIED_ERROR':
            return {
                ...state,
                verifiedError: action.err.message
            }


         case 'RESET_PASSWORD_SUCCESS':
            return {
                ...state,
                resetPasswordSuccess: {
                    message: action.message,
                    type: 'resetPasswordSuccess'
                }
            }
        case 'RESET_PASSWORD_ERROR':
            return {
                ...state,
                resetPasswordError: {
                    message: action.err.message,
                    type: 'resetPasswordError'
                }
            }

        default:
            return state
    }
}

export default authReducer