import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { storage } from '../../../config/fbConfig'

import { addModule } from '../../../stores/actions/courseAction';

class Module extends Component{
    
    constructor(props){
        super(props)
        this.state = {
            moduleName:'',
            moduleCode:'',
            selectedTopics:[],
            //selectedPracticalAssessment:null,
            //selectedQuizSet:[],
    
            quiz: [],
            hasQuiz: 'true',
            quizTimer: '',
            quizMark: '',
            quizSuspendDay: '',
            quizSuspendNumber: '',
            selectedQuizSet: '',
            questionCount:'',
    
            practicalAssessment: [],
            hasPracticalAssessment:'true',
    
            assessmentFile: null,
            progress:'',
    
            searchTopic:'',
            selectedTopicCode:'',
    
            isLoading: null,
            message: null,
            addedModuleId:''
        }
        this.baseState = this.state 
    }
    
    componentDidUpdate = (prevProps, prevState) => { 
        const { addModuleError, addModuleSuccess } = this.props;

        if(prevProps.addModuleError !== addModuleError) {
            this.setState({
                isLoading: false,
                message: {
                    msg: addModuleError.message,
                    type: addModuleError.type,
                }
            })
        }

        if(prevProps.addModuleSuccess !== addModuleSuccess) {
            this.setState({
                isLoading: false,
                message: {
                    msg: addModuleSuccess.message,
                    type: addModuleSuccess.type,
                },
                addedModuleId: addModuleSuccess.id
            }, () => {
                setTimeout(() => { 
                    this.props.hide();
                    document.getElementById("clickLink").click();
                    setTimeout(() => { 
                        this.setState(this.baseState)
                    }, 3000);
                }, 2000);
            })
        }
    } 

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.valueAsNumber || e.target.value
        })
    }

    updateState = async () => {
        this.setState({
            quiz:{
                hasQuiz: this.state.hasQuiz,
                selectedQuizSet: this.state.selectedQuizSet,
                quizTimer: this.state.quizTimer,
                quizMark: this.state.quizMark,
                quizSuspendDay: this.state.quizSuspendDay,
                quizSuspendNumber: this.state.quizSuspendNumber,
                questionCount: this.state.questionCount
            },
            practicalAssessment: {
                hasPracticalAssessment: this.state.hasPracticalAssessment,
                fileUrl: '',
                fileName: ''
            }
        })
        return;
    }

    assessmentFile = (e) => {
        if(e.target.files[0]) {
            this.setState({
                assessmentFile: e.target.files[0],
            });
        }
    }

    addModule = async (e) => {

        const { assessmentFile } = this.state;

        await this.updateState();

        var result = window.confirm("Confirm add?");
    
        if (result) {
            this.setState({
                isLoading: true
            })
            if (assessmentFile !== null) {
                const uploadTask = storage.ref(`documents/${assessmentFile.name}`).put(assessmentFile);
    
                uploadTask.on('state_changed', 
                    (snapshot) => {
                        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                        this.setState({
                            progress: progress
                        });
                    }, 
    
                    (error) => {
                        alert(error)
                    }, 
    
                    () => {
                        storage.ref('documents').child(assessmentFile.name).getDownloadURL().then(fileUrl => {
                            this.setState({
                                practicalAssessment: {
                                    hasPracticalAssessment: this.state.hasPracticalAssessment,
                                    fileUrl: fileUrl,
                                    fileName: assessmentFile.name
                                }
                            });
                            this.props.addModule(this.state)
                        })
                    }
                );
            } else {
                this.props.addModule(this.state)
            }
        }
    }

    handleSelectedTopic(id, code) {
        this.setState({
            selectedTopics: [ 
                ...this.state.selectedTopics, 
                id //get selected option ID
            ],
            selectedTopicCode: code,
        });
    }

    removeSelectedTopic = (id) => {
        let removeSelectedTopic = this.state.selectedTopics.filter(selected => {
          return selected !== id
        })
    
        this.setState({
            selectedTopics: removeSelectedTopic
        })
    }

    render(){

        const { quizzes, topics } = this.props;
        const { message, isLoading } = this.state;

        let filteredTopics = null;

        if(this.state.searchTopic.length > 0) { //filter user: search user by email
            filteredTopics = topics && topics.filter(topic => {
                return topic.topicCode.toLowerCase().includes(this.state.searchTopic.toLowerCase()) || 
                topic.topicName.toLowerCase().includes(this.state.searchTopic.toLowerCase())
            })
        } else {
            filteredTopics = topics;
        }
        
        return(

            <div>             
                <div className="row">          
                    <p className="col col-md-3">
                        <b>Code:</b>
                        <input type="text" id="moduleCode" onChange={this.handleChange} value={this.state.moduleCode} className="form-control"  placeholder="Code"/>
                    </p>
                    <p className="col">
                        <b>Name:</b>
                        <input type="text" id="moduleName" onChange={this.handleChange} value={this.state.moduleName} className="form-control"  placeholder="Name"/>
                    </p>
                </div>
                
                <div className="mt-4">
                    <b>Topics: </b>
                    <div type="text" className="border-black">

                        { topics && topics.map(topic => {
                            return (
                                this.state.selectedTopics && this.state.selectedTopics.map((selected, i) => { 
                                    if(topic.id === selected) {
                                        return (
                                            <span className="badge bg-green text-white" key={i}>
                                                {topic.topicCode} {' '}
                                                    
                                                <span onClick={this.removeSelectedTopic.bind(this, selected)}>
                                                    <i className="fas fa-times"></i>
                                                </span>
                                            </span>
                                        )
                                    }
                                    return null
                                })
                            )
                        })}
                    </div>
                    
                    <small>Choose topics here:</small>

                    <br/>

                    <div className="row col-md-5 btn-group dropdown">
                        <button type="button" className="btn border-black dropdown-toggle" data-toggle="dropdown">
                            { this.state.selectedTopicCode ? this.state.selectedTopicCode : 'Choose Topic'}
                        </button>

                        <div className="dropdown-menu" >
                            <span className="dropdown-item" onClick={this.handleSelectedTopic.bind(this, '', '')} disabled>TOPIC</span>
                            <span className="dropdown-item"><input placeholder="Search code..." className="form-control"  onChange={event => this.setState({searchTopic: event.target.value})}/></span>
                            { filteredTopics && filteredTopics.map((topic,i) => {
                                return (
                                    <span key={i} className="dropdown-item" onClick={this.handleSelectedTopic.bind(this, topic.id, topic.topicCode)}>{topic.topicCode} - {topic.topicName}</span>
                                )
                            })}
                        </div>
                    </div>
                </div>

                <div className="mt-4">
                    <b>Quiz:</b>
                    <select className="form-control" onChange={event => this.setState({hasQuiz: event.target.value})}>
                        <option defaultValue>true</option>
                        <option>false</option>
                    </select>

                    { this.state.hasQuiz === "true" ?
                        <div>
                            <select className="form-control" onChange={e => this.setState({selectedQuizSet: e.target[e.target.selectedIndex].id})} >
                                <option defaultValue>Select Quiz's Set</option>
                                { quizzes && quizzes.map(quiz => { 
                                    return (
                                        <option key={quiz.id} id={quiz.id}>{quiz.quizCode} - {quiz.quizName}</option>
                                    )
                                })}
                            </select>
                            <br/>
                            <small>QUIZ SETING</small>
                            <br/>
                            <div className="row">    
                                <p className="col col-md-4">
                                    <small style={{fontWeight:'800'}}>Timer (seconds):</small>
                                    <input type="number" id="quizTimer" onChange={this.handleChange} className="form-control" value={this.state.quizTimer} placeholder="Timer"/>
                                </p>    
            
                                <p className="col col-md-4">
                                    <small style={{fontWeight:'800'}}>Q.Count:</small>
                                    <input type="number" id="questionCount" onChange={this.handleChange} className="form-control" value={this.state.questionCount}  placeholder="Question Count"/>
                                </p>

                                <p className="col col-md-4">
                                    <small style={{fontWeight:'800'}}>Pass Rate (%):</small>
                                    <input type="number" id="quizMark" onChange={this.handleChange} className="form-control" value={this.state.quizMark}  placeholder="Mark"/>
                                </p>
                            </div>

                            <div className="row">    
                                <p className="col col-md-4">
                                    <small style={{fontWeight:'800'}}>S.Count:</small>
                                    <input type="number" id="quizSuspendNumber" onChange={this.handleChange} className="form-control" value={this.state.quizSuspendNumber}  placeholder="Suspend Count"/>
                                </p>
            
                                <p className="col col-md-4">
                                    <small style={{fontWeight:'800'}}>S.Day (days):</small>
                                    <input type="number" id="quizSuspendDay" onChange={this.handleChange} className="form-control" value={this.state.quizSuspendDay}  placeholder="Suspend Day"/>
                                </p>
                            </div>
                        </div>
                    : null }
                </div>

                <div className="mt-4 mb-5">
                    <b>Practical Assessment:</b>
                    <select className="form-control" onChange={event => this.setState({hasPracticalAssessment: event.target.value})}>
                        <option defaultValue>true</option>
                        <option>false</option>
                    </select>
                    <br />
                    { this.state.hasPracticalAssessment === "true" ?
                        <div>
                            <small>UPLOAD PRACTICAL ASSESSMENT</small>
                            <br />
                            <input className="mt-2" type="file" onChange={this.assessmentFile.bind(this)} /> <br/>
                            <progress value={this.state.progress} max="100" style={{width:"250px"}}/>
                        </div>
                    : null }
                </div>

                { isLoading === true ? 
                    <small>Loading...</small>
                : 
                    message  ?
                        <small className={message.type === 'addModuleError' ? "text-red" : message.type === 'addModuleSuccess'  ? "text-green" : null }>
                            {message.msg}
                        </small>
                    : null
                }

                <div className="mt-5 row justify-content-end">
                    <button type="button"
                        className="btn m-2 btn-outline-black" onClick={this.addModule.bind(this)}><i className="fas fa-plus-circle"/>{' '}add module
                    </button>
                </div>

                <div hidden={true}>
                    <Link id="clickLink" to={`/systemmanager?topic=course&subtopic=module&id=${this.state.addedModuleId}&code=${this.state.moduleCode}`} />
                </div>
            </div>

        )
    }
}

const mapStateToProps = (state) => {
  return {
      addModuleError: state.courses.addModuleError,
      addModuleSuccess: state.courses.addModuleSuccess,
  }
}

const mapDispatchToProps = (dispatch) => {
    return {
      addModule: (module) => dispatch(addModule(module))
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Module);