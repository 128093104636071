import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { storage } from '../../../config/fbConfig'

import { addCourse } from '../../../stores/actions/courseAction';

class Course extends Component{
    
    constructor(props){
        super(props)
        this.state = {
            attachType:'',

            courseName:'',
            courseCode:'',
            courseType:'Professional',
            coursePeriod:'',
            coursePrice: '',
            toyyibCatCode: '',
            toyyibCatCode_Extend: '',
            courseStatus: 'Active',
            courseAttach: null,

            installmentAvailable:'',
            hasPhysicalClass:null,
            hasEcert:null,
            removeQuizBtn:null,
            enableExam:null,
    
            //PROF DATA
            exam: [],
            hasExam: "true",
            examSuspendNumber: '',
            examSuspendDay: '',
            examTimer: '',
            examMark: '',
            questionCount:'',
    
            selectedModules:[],
            selectedExamSet:'',

            //COURSE EXTENSION
            extendPrice: '',
            extendPeriod: '',
    
            //FLEXI DATA
            selectedCourse: '',
            selectedModule: '',
    
            courseInfo:'',
            getCourseInfo:'',
    
            courseOverview:'',
            getCourseOverview:'',
    
            courseLearn:'',
            getCourseLearn:'',
    
            courseRequirement:'',
            getCourseRequirement:'',
    
            videoProgress: 0,
            videoUrl:'',
            displayAttach:null,
    
            searchModule:'',
            selectedModuleCode:'',
    
            isLoading: null,
            message: null,
            addedCourseId:''
        }
        this.baseState = this.state 
    }
    
    componentDidUpdate = (prevProps, prevState) => {  

        const { addCourseError, addCourseSuccess } = this.props;

        if(prevProps.addCourseError !== addCourseError) {
            this.setState({
                isLoading: false,
                message: {
                    msg: addCourseError.message,
                    type: addCourseError.type,
                }
            })
        }

        if(prevProps.addCourseSuccess !== addCourseSuccess) {
            this.setState({
                isLoading: false,
                message: {
                    msg: addCourseSuccess.message,
                    type: addCourseSuccess.type,
                },
                addedCourseId: addCourseSuccess.id
            }, () => {
                setTimeout(() => { 
                    this.props.hide();
                    document.getElementById("clickAddCourse").click();
                    setTimeout(() => { 
                        this.setState(this.baseState)
                    }, 3000);
                }, 2000);
            })
        }
    } 

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.valueAsNumber || e.target.value
        })
    }

    selectedAttachType = (e) => {
        this.setState({
            attachType:  e.target[e.target.selectedIndex].id,
        })
    }

    attachSelectHandler = (e) => {
        if(e.target.files[0]) {
            this.setState({ 
                courseAttach: e.target.files[0],
                displayAttach: URL.createObjectURL(e.target.files[0]) //ubah imgUrl to pic
            });
        }
    }

    updateState = async () => {
        this.setState({
            exam:{
                hasExam: this.state.hasExam,
                selectedExamSet: this.state.selectedExamSet,
                examTimer: this.state.examTimer,
                examMark: this.state.examMark,
                examSuspendDay: this.state.examSuspendDay,
                examSuspendNumber: this.state.examSuspendNumber,
                questionCount: this.state.questionCount
            },
        })
        return;
    }

    addCourse = async (e) => {

        const {courseAttach, attachType } = this.state;

        await this.updateState();

        var result = window.confirm("Confirm add?");
    
        if (result) {
            this.setState({
                isLoading: true
            })
            if ( courseAttach !== null ) {

                let ref = null, ref2 = null;

                if(attachType === "image") {
                    ref = `images/${courseAttach.name}`
                    ref2 = 'images'
                } else if (attachType === "video") {
                    ref = `videos/${courseAttach.name}`
                    ref2 = 'videos'
                }

                const uploadTask = storage.ref(ref).put(courseAttach);
    
                uploadTask.on('state_changed', 
                    (snapshot) => {
                        const videoProgress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                        this.setState({
                            videoProgress: videoProgress
                        });
                    }, 
    
                    (error) => {
                        alert(error)
                    }, 
    
                    () => {
                        storage.ref(ref2).child(courseAttach.name).getDownloadURL().then(url => {
                        
                            this.setState({
                                courseAttach: {
                                    fileUrl: url,
                                    fileName: courseAttach.name,
                                    fileType: attachType
                                }
                            });
    
                            this.props.addCourse(this.state)
    
                        })
                    }
                );
            } else {
                this.props.addCourse(this.state)
            }
        }
    }

    selectedProfModule(id, code) {
        this.setState({
            selectedModules: [ 
                ...this.state.selectedModules, 
                id //get selected option ID
            ],
            selectedModuleCode: code
        });
    }

    removeSelectedModule = (id) => {
        let removeSelectedModule = this.state.selectedModules.filter(selected => {
          return selected !== id
        })
    
        this.setState({
            selectedModules: removeSelectedModule
        })
    }

    saveData = (saveType) => {

        if(saveType === 'info') {
            this.setState({
                courseInfo: [ 
                    ...this.state.courseInfo,
                    this.state.getCourseInfo, 
                ],
            });
            this.refs.courseInfo.value = '';
        } else if(saveType === 'overview') {
            this.setState({
                courseOverview: [ 
                    ...this.state.courseOverview,
                    this.state.getCourseOverview, 
                ],
            });
            this.refs.courseOverview.value = '';
        } else if(saveType === 'learn') {
            this.setState({
                courseLearn: [ 
                    ...this.state.courseLearn,
                    this.state.getCourseLearn, 
                ],
            });
            this.refs.courseLearn.value = '';
        }else if(saveType === 'requirement') {
            this.setState({
                courseRequirement: [ 
                    ...this.state.courseRequirement,
                    this.state.getCourseRequirement, 
                ],
            });
            this.refs.courseRequirement.value = '';
        }
    }

    removeData = (removeType, data) => {
        if(removeType === 'info') {
            let removed = this.state.courseInfo.filter(inf => {
                return inf !== data
            });
            this.setState({
                courseInfo: removed,
            })
        } else if(removeType === 'overview') {
            let removed = this.state.courseOverview.filter(overview => {
                return overview !== data
            });
            this.setState({
                courseOverview: removed,
            })
        } else if(removeType === 'learn') {
            let removed = this.state.courseLearn.filter(learn => {
                return learn !== data
            });
            this.setState({
                courseLearn: removed,
            })
        } else if(removeType === 'requirement') {
            let removed = this.state.courseRequirement.filter(requirement => {
                return requirement !== data
            });
            this.setState({
                courseRequirement: removed,
            })
        }
    }

    render(){

        const { courses, modules, exams } = this.props;
        const { message, isLoading } = this.state;

        let filteredModules = null;

        if(this.state.searchModule.length > 0) { //filter user: search user by email
            filteredModules = modules && modules.filter(module => {
                return module.moduleCode.toLowerCase().includes(this.state.searchModule.toLowerCase()) || 
                module.moduleName.toLowerCase().includes(this.state.searchModule.toLowerCase())
            })
        } else {
            filteredModules = modules;
        }
        
        return(

            <div>   

                <div className="row">   
                    <p className="col-md-4">
                        <b>Course Type: </b>
                        <select id="courseType" onChange={this.handleChange} className="form-control">
                            <option defaultValue>Professional</option>
                            <option>Flexi</option>
                        </select>
                    </p> 
                </div>
                                
                <div className="row">        
                    <p className="col col-md-4">
                        <b>Code:</b>
                        <input type="text" id="courseCode" onChange={this.handleChange} className="form-control" value={this.state.courseCode} placeholder="Code" />
                    </p>

                    <p className="col col-md-4">
                        <b>Name:</b>
                        <input type="text" id="courseName" onChange={this.handleChange} className="form-control" value={this.state.courseName} placeholder="Name" />
                    </p>

                    <p className="col col-md-4">
                        <b>Status: </b>
                        <select id="courseStatus" onChange={this.handleChange} value={this.state.courseStatus} className="form-control">
                            <option defaultValue>Active</option>
                            <option>Not Active</option>
                        </select>
                    </p>
                </div>

                <div className="mt-4">
                    <b>Course Price:</b>
                    <div className="row">    
                        <p className="col col-md-4">
                            <small style={{fontWeight:'800'}}>Price (RM):</small>
                            <input type="number" id="coursePrice" onChange={this.handleChange} className="form-control" value={this.state.coursePrice} placeholder="Price" />
                        </p>   

                        { this.state.courseType === "Professional" ?
                            <p className="col col-md-4">
                                <small style={{fontWeight:'800'}}>Period (days):</small>
                                <input type="number" id="coursePeriod" onChange={this.handleChange} className="form-control" value={this.state.coursePeriod} placeholder="Period" />
                            </p>
                        :''}

                        <p className="col col-md-4">
                            <small style={{fontWeight:'800'}}>ToyyibPay Category Code:</small>
                            <input type="text" id="toyyibCatCode" onChange={this.handleChange} className="form-control" value={this.state.toyyibCatCode} placeholder="ToyyibPay category code" title="Login to ToyyibPay site > Category" />
                        </p> 
                    </div>
                </div>

                { this.state.courseType === "Professional" ?
                    <div>
                        <div className="mt-4">
                            <b>Course Expired Extension:</b>
                            <div className="row">    
                                <p className="col col-md-4">
                                    <small style={{fontWeight:'800'}}>Price (RM):</small>
                                    <input type="number" id="extendPrice" onChange={this.handleChange} className="form-control" value={this.state.extendPrice} placeholder="Extend price"/>
                                </p>    
            
                                <p className="col col-md-4">
                                    <small style={{fontWeight:'800'}}>Period (month):</small>
                                    <input type="number" id="extendPeriod" onChange={this.handleChange} className="form-control"  value={this.state.extendPeriod} placeholder="Extend period"/>
                                </p>

                                <p className="col col-md-4">
                                    <small style={{fontWeight:'800'}}>ToyyibPay Category Code:</small>
                                    <input type="text" id="toyyibCatCode_Extend" onChange={this.handleChange} className="form-control" value={this.state.toyyibCatCode_Extend} placeholder="ToyyibPay extension category code" title="Login to ToyyibPay site > Category" />
                                </p> 
                            </div>
                        </div>

                        <div className="mt-4">
                            <b>Modules: </b>
                            <div  className="border-black">
                                { modules && modules.map(module => {
                                    return (
                                        this.state.selectedModules && this.state.selectedModules.map((selected, i) => {
                                            if (module.id === selected) {
                                                return (
                                                    <span className="badge bg-green text-white" key={i}>
                                                        {module.moduleCode} {' '}
                                                        <span onClick={this.removeSelectedModule.bind(this, selected)}>
                                                            <i className="fas fa-times"></i>
                                                        </span>
                                                    </span>
                                                )
                                            } 
                                            return null
                                        })
                                    )
                                })}
                            </div>
                            
                            <small>Choose module here:</small>

                            <br/>

                            <div className="row col-md-5 btn-group dropdown">
                                <button type="button" className="btn border-black dropdown-toggle" data-toggle="dropdown">
                                    { this.state.selectedModuleCode ? this.state.selectedModuleCode : 'Choose Module'}
                                </button>

                                <div className="dropdown-menu" >
                                    <span className="dropdown-item" onClick={this.selectedProfModule.bind(this, '', '')} disabled>MODULE</span>
                                    <span className="dropdown-item"><input placeholder="Search code..." className="form-control"  onChange={event => this.setState({searchModule: event.target.value})}/></span>
                                    { filteredModules && filteredModules.map((module,i) => {
                                        return (
                                            <span key={i} className="dropdown-item" onClick={this.selectedProfModule.bind(this, module.id, module.moduleCode)}>{module.moduleCode} - {module.moduleName}</span>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>

                        <div className="mt-4">
                            <b>Exam:</b>
                            <select className="form-control" onChange={event => this.setState({hasExam: event.target.value})}>
                                <option defaultValue>true</option>
                                <option>false</option>
                            </select>

                            { this.state.hasExam === "true" ?
                                <div>
                                    <select className="form-control" onChange={e => this.setState({selectedExamSet: e.target[e.target.selectedIndex].id})}>
                                        <option defaultValue>Select Exam's Set</option>
                                        { exams && exams.map(exam => { 
                                            return (
                                                <option key={exam.id} id={exam.id}>{exam.examCode} - {exam.examName}</option>
                                            )
                                        })}
                                    </select>
                                    <br/>
                                    <small>EXAM SETING</small>
                                    <br/>
                                    <div className="row">    
                                        <p className="col col-md-4">
                                            <small style={{fontWeight:'800'}}>Time (seconds):</small>
                                            <input type="number" id="examTimer" onChange={this.handleChange} className="form-control" value={this.state.examTimer} placeholder="Timer"/>
                                        </p>    
                    
                                        <p className="col col-md-4">
                                            <small style={{fontWeight:'800'}}>Q.Count:</small>
                                            <input type="number" id="questionCount" onChange={this.handleChange} className="form-control"  value={this.state.questionCount} placeholder="Question Count" title="Total question to be display on examination page" />
                                        </p>

                                        <p className="col col-md-4">
                                            <small style={{fontWeight:'800'}}>Pass Rate (%):</small>
                                            <input type="number" id="examMark" onChange={this.handleChange} className="form-control"  value={this.state.examMark} placeholder="Exam Mark"/>
                                        </p>
                                    </div>

                                    <div className="row">  
                                        <p className="col col-md-4">
                                            <small style={{fontWeight:'800'}}>S.Count:</small>
                                            <input type="number" id="examSuspendNumber" onChange={this.handleChange} className="form-control" value={this.state.examSuspendNumber}  placeholder="Suspend Count" title="Total user failed count in order to be suspended" />
                                        </p>
                    
                                        <p className="col col-md-4">
                                            <small style={{fontWeight:'800'}}>S.Day (days):</small>
                                            <input type="number" id="examSuspendDay" onChange={this.handleChange} className="form-control"  value={this.state.examSuspendDay} placeholder="Suspend Day"/>
                                        </p>
                                    </div>
                                </div>
                            : null }
                        </div>

                        <div className="mt-4 row">
                            <p className="col col-md-4">
                                <b>Has Practical Session:</b>
                                <select className="form-control" onChange={event => this.setState({hasPhysicalClass: event.target.value})}>
                                    <option defaultValue>Select Class Available</option>
                                    <option>true</option>
                                    <option>false</option>
                                </select>
                            </p>

                            <p className="col col-md-4">
                                <b>Has E-Cert</b>
                                <select className="form-control" onChange={event => this.setState({hasEcert: event.target.value})} title="To display E-Cert button when finish course" >
                                    <option defaultValue>Select E-Cert Available</option>
                                    <option>true</option>
                                    <option>false</option>
                                </select>
                            </p>

                            <p className="col col-md-4">
                                <b>Has Flexi:</b>
                                <select className="form-control" onChange={event => this.setState({installmentAvailable: event.target.value})}  title="To display Flexi button at the Courses page" >
                                    <option defaultValue>Select Flexi Available</option>
                                    <option>true</option>
                                    <option>false</option>
                                </select>
                            </p>
                        </div>

                        <div className="mt-4 row">
                            <p className="col col-md-4">
                                <b>Enable Exam:</b>
                                <select className="form-control" onChange={event => this.setState({enableExam: event.target.value})} title="To unlocked all of the module and enable exam button" >
                                    <option defaultValue>Select Enable Exam</option>
                                    <option>true</option>
                                    <option>false</option>
                                </select>
                            </p>

                            <p className="col col-md-4">
                                <b>Remove Quiz Button:</b>
                                <select className="form-control" onChange={event => this.setState({removeQuizBtn: event.target.value})} title="If 'Yes' > Hide all quiz button at the Modules List page">
                                    <option defaultValue>Select Remove Quiz Button</option>
                                    <option>true</option>
                                    <option>false</option>
                                </select>
                            </p>
                        </div>

                    </div>

                :
                    <div>
                        <p>
                            <b>Course: </b>
                            <select className="form-control" onChange={e => this.setState({selectedCourse: e.target[e.target.selectedIndex].id})}>
                                <option defaultValue>Select Course</option>
                                {courses && courses.map(course => {
                                    if (course.courseType === "Professional") {
                                        return (
                                            <option 
                                                key={course.id}
                                                id={course.id}
                                                >{course.courseName}
                                            </option>
                                        )
                                    }
                                    return null
                                })}
                            </select>
                        </p>   

                        <p>
                            <b>Module: </b>
                            <select className="form-control" onChange={e => this.setState({selectedModule: e.target[e.target.selectedIndex].id})}>
                                <option defaultValue>Select module</option>

                                {courses && courses.map(course => {
                                    if (course.id === this.state.selectedCourse) {
                                        return (
                                            modules && modules.map(module => {
                                                return (
                                                    course.selectedModules && course.selectedModules.map(mod => {
                                                        if (module.id === mod) {
                                                            return (
                                                                <option 
                                                                    key={module.id}
                                                                    id={module.id}
                                                                    >{module.moduleName}
                                                                </option>
                                                            )
                                                        }
                                                        return null
                                                    })
                                                )
                                            })
                                        )
                                    }
                                    return null
                                })}
                            </select>
                        </p>   
                    </div>
                }

                <div className="mt-4">
                    <b>Info:</b><br/>
                    <textarea type="text" className="form-control" ref="courseInfo" onChange={event => this.setState({getCourseInfo: event.target.value})}  placeholder="Course info"/>
                    <span className="badge bg-blue text-white" onClick={this.saveData.bind(this, 'info')}>Save and Add New</span>
                    <br/>
                    { this.state.courseInfo && this.state.courseInfo.map((info,i) => {
                        return (
                            <div key={i} style={{margin:"0px"}} className="listing"> 
                                <span onClick={this.removeData.bind(this, 'info', info)}> <i className="list-icon fas fa-trash text-red" /></span>
                                <label style={{margin:"0px"}}>{info}</label>
                            </div>
                        )
                    })}
                </div>

                <div className="mt-4">
                    <b>Overview:</b><br/>
                    <textarea type="text" className="form-control" ref="courseOverview" onChange={event => this.setState({getCourseOverview: event.target.value})}  placeholder="Course Overview"/>
                    <span className="badge bg-blue text-white" onClick={this.saveData.bind(this, 'overview')}>Save and Add New</span>
                    <br/>
                    { this.state.courseOverview && this.state.courseOverview.map((overview,i) => {
                        return (
                            <div key={i} style={{margin:"0px"}} className="listing"> 
                                <span onClick={this.removeData.bind(this, 'overview', overview)}> <i className="list-icon fas fa-trash text-red" /></span>
                                <label style={{margin:"0px"}}>{overview}</label>
                            </div>
                        )
                    })}
                </div>

                <div className="mt-4">
                    <b>What Will Learn:</b><br/>
                    <textarea type="text" className="form-control" ref="courseLearn" onChange={event => this.setState({getCourseLearn: event.target.value})}  placeholder="Course Learn"/>
                    <span className="badge bg-blue text-white" onClick={this.saveData.bind(this, 'learn')}>Save and Add New</span>
                    <br/>
                    { this.state.courseLearn && this.state.courseLearn.map((learn,i) => {
                        return (
                            <div key={i} style={{margin:"0px"}} className="listing"> 
                                <span onClick={this.removeData.bind(this, 'learn', learn)}> <i className="list-icon fas fa-trash text-red" /></span>
                                <label style={{margin:"0px"}}>{learn}</label>
                            </div>
                        )
                    })}
                </div>

                <div className="mt-4">
                    <b>Requirement:</b><br/>
                    <textarea type="text" className="form-control" ref="courseRequirement" onChange={event => this.setState({getCourseRequirement: event.target.value})}  placeholder="Course Requirement"/>
                    <span className="badge bg-blue text-white" onClick={this.saveData.bind(this, 'requirement')}>Save and Add New</span>
                    <br/>
                    { this.state.courseRequirement && this.state.courseRequirement.map((requirement,i) => {
                        return (
                            <div key={i} style={{margin:"0px"}} className="listing"> 
                                <span onClick={this.removeData.bind(this, 'requirement', requirement)}> <i className="list-icon fas fa-trash text-red" /></span>
                                <label style={{margin:"0px"}}>{requirement}</label>
                            </div>
                        )
                    })}
                </div>

                <div className="mt-4 mb-5">
                    <b>Attachment:</b>
                    <br />
                    <select className="mb-2 col col-md-6 form-control" onChange={this.selectedAttachType.bind(this)}>
                        <option defaultValue>Choose attachment type</option>
                        <option id="video" >Video</option>
                        <option id="image">Image</option>
                    </select>
                    <br />
                    { this.state.displayAttach ? 
                        this.state.attachType === "image" ?
                            <div><img alt="" width="200" src={this.state.displayAttach} /></div>
                        : this.state.attachType === "video" ?
                            <div><video width="250" height="150" src={this.state.displayAttach} type="video/mp4" controls /></div>
                        : null
                    : null  }

                    <br/>

                    { this.state.displayAttach ?
                        <small className="text-red"><i>**Attachment preview not appear? Please choose correct attachment type</i></small>
                    : null }

                    <br/>

                    { this.state.attachType ?
                        <input className="mt-2" type="file" onChange={this.attachSelectHandler.bind(this)} />
                    : null }

                    <br />

                    <progress value={this.state.videoProgress} max="100" style={{width:"250px"}}/>
                </div>

                { isLoading === true ? 
                    <small>Loading...</small>
                : 
                    message  ?
                        <small className={message.type === 'addCourseError' ? "text-red" : message.type === 'addCourseSuccess'  ? "text-green" : null }>
                            {message.msg}
                        </small>
                    : null
                }

                <div className="mt-5 row justify-content-end">
                    <button type="button"
                        className="btn m-2 btn-outline-black" onClick={this.addCourse}><i className="fas fa-plus-circle"/>{' '}add course
                    </button>
                </div>

                <div hidden={true}>
                    <Link id="clickAddCourse" to={`/systemmanager?topic=course&subtopic=course&id=${this.state.addedCourseId}&code=${this.state.courseCode}`} />
                </div>
            </div>

        )
    }
}

const mapStateToProps = (state) => {
  return {
      addCourseError: state.courses.addCourseError,
      addCourseSuccess: state.courses.addCourseSuccess,
  }
}

const mapDispatchToProps = (dispatch) => {
    return {
      addCourse: (course) => dispatch(addCourse(course))
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Course);