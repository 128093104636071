import React, { Component } from 'react';
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import { connect } from 'react-redux'

import SignedInLinks from '../auth/SignedInLinks';
import SignedOutLinks from '../auth/SignedOutLinks';

import { NavLink } from 'react-router-dom';

class Navbar extends Component {

    render() {

        const { programs, auth, profile, infos, events, eventsList } = this.props;   

        const links = auth.uid ? <SignedInLinks profile={profile}/> : <SignedOutLinks />;

        let halalLists = infos && infos.map((info ,i) => {
            if(info.infoType === "HALAL INFO"){
                return (
                    <li key={i} className="dropdown-item"> 
                        <a href={info.selectedHalalFile.fileUrl} target="_blank" rel="noopener noreferrer" download>
                            {info.infoName}
                        </a>
                    </li>
                )
            }
            return null
        });

        return(

            <div>
                <nav className="navbar navbar-expand-lg navbar-light bg-transparent">
                    <NavLink className="navbar-brand mb-0 border-0 btn " to="/">
                        <img src="/image/HA_LOGO.png" width="120px"alt=""/>
                    </NavLink>

                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <span className="navbar-nav mr-auto">

                            <span className="nav-item dropdown">
                                <span className="btn" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    ABOUT
                                </span>

                                <span className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                    <NavLink className="dropdown-item" to="/abouthalalacademy">Halal Academy</NavLink>

                                    <NavLink to="/halalindustry" className="dropdown-item">Halal Industry</NavLink>

                                    <li><span className="dropdown-item dropright"> Halal Info </span>
                                        <ul className="submenu dropdown-menu">
                                            {halalLists}
                                        </ul>
                                    </li>
                                </span>
                            </span>

                            <span className="nav-item dropdown">
                                <span className="btn" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    PROGRAMS
                                </span>

                                <span className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                    { programs && programs.map((program,i) => {
                                        if(program.programStatus === "Active") {
                                            return (
                                                <NavLink key={i} to={"/program/" + program.id} className="dropdown-item">{program.programName}</NavLink>
                                            )
                                        }
                                        return null
                                    })}
                                    { eventsList && eventsList.map((data,i) => {
                                        if(data.status === "Active") {
                                            return (
                                                <NavLink key={i} to={"/events/" + data.id} className="dropdown-item">{data.name}</NavLink>
                                            )
                                        }
                                        return null
                                    })}
                                </span>
                            </span>

                            <span className="nav-item">
                                <NavLink className="btn" to="/career">CAREER</NavLink>
                            </span>

                            <span className="nav-item dropdown">
                                <span className="btn" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    HELP
                                </span>

                                <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                    <NavLink className="dropdown-item" to="/howitworks">How it Works</NavLink>
                                    <NavLink className="dropdown-item" to="/help">User Manual</NavLink>
                                </div>
                            </span>
                        </span>
                        
                        <div className="row justify-content-end">
                            {/* { profile.type === "admin" ? 
                                <span className="nav-item dropdown">
                                    <span className="btn dropdown-toggle"role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        For Migrate
                                    </span>

                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                        <NavLink className="dropdown-item" to="/map-enrollment-data"><i className="fas fa-user-shield"></i>{'  '}Map Enrollment Data</NavLink>
                                        <NavLink className="dropdown-item" to="/review-questions"><i className="fas fa-info-circle"></i>{'  '}Review Questions</NavLink>
                                    </div>
                                </span>
                            : null } */}
                            
                            {links}
                        </div>
                    </div>
                </nav>
            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile,
        programs: state.firestore.ordered.programs, 
        infos: state.firestore.ordered.infos, 
        events: state.firestore.ordered.events,
        eventsList: state.firestore.ordered.eventsList,
    }
  }

export default compose(
    connect(mapStateToProps),
    firestoreConnect([
        { collection: 'programs'},
        { collection: 'infos'},
        { collection: 'events'},
        { collection: 'eventsList'},
    ])
)(Navbar);