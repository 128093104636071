import React, { Component } from 'react';
import { connect } from 'react-redux'
import { submitFormReqClass, updatePCB_selectedParticipants } from '../../stores/actions/classAction';
import { updateContactNo } from '../../stores/actions/userAction';

class RequestClass extends Component {
    
    constructor(props){
        super(props)
        this.state = {
            req_name: '',
            req_email: '',
            req_phone: '',
            req_course: '',
            req_date: this.props.req_date,
    
            selectedBatchId:'',
            selectedBatchName:'',
            selectedBatchDate:'',
            selectedBatch_selectedParticipants: null,
    
            isLoading: null,
            message: null,
        }
        this.baseState = this.state 
    }
    
    componentDidUpdate = async (prevProps, prevState) => { 
        const { submitFormClassError, submitFormClassSuccess } = this.props;

        if(prevProps.submitFormClassError !== submitFormClassError) {
            this.setState({
                isLoading: false,
                message: {
                    msg: submitFormClassError.message,
                    type: submitFormClassError.type,
                }
            })
        }

        if(prevProps.submitFormClassSuccess !== submitFormClassSuccess) {
            this.setState({
                isLoading: false,
                message: {
                    msg: submitFormClassSuccess.message,
                    type: submitFormClassSuccess.type,
                }
            })

            var result = window.confirm(submitFormClassSuccess.message);
            if (result) {
                this.props.hideModalReqClass();
                this.setState(this.baseState)
            }
        }
    } 

    handleChange = (e) => {
        this.setState({
            req_phone: e.target.value
        })
    }

    removedOldData = async () => {
        const { physicalClassBatches, reqClass_userId,req_selectedBatchId } = this.props;

        if(req_selectedBatchId) {
            let removedOld = null;
            physicalClassBatches && physicalClassBatches.map(batch => {
                if(batch.id === req_selectedBatchId) {
                    removedOld = batch.selectedParticipants && batch.selectedParticipants.filter(selected => {
                        return selected.userId !== reqClass_userId
                    })
                }
                return null
            })

            let removed_selectedParticipants = {
                selectedParticipants: [
                    ...removedOld
                ]
            }
            this.props.updatePCB_selectedParticipants(removed_selectedParticipants, req_selectedBatchId)
        }
    }

    insertNewData = async () => {

        await this.removedOldData();

        if(this.state.selectedBatchId) {
            let new_selectedParticipants = {
                selectedParticipants: [
                    ...this.state.selectedBatch_selectedParticipants,
                    {
                        userId: this.props.reqClass_userId,
                        pcpId:'',
                        enrolledCourseId: '',
                    }
                ]
            }
            this.props.updatePCB_selectedParticipants(new_selectedParticipants, this.state.selectedBatchId)
        }
    }

    submitFormReqClass = () => {

        const { physicalClassParticipants, reqClass_userId, reqClass_courseId, req_selectedBatchId } = this.props;

        var result = window.confirm("Confirm submit?");
        let name = document.getElementById("req_name").value, email = document.getElementById("req_email").value;

        if (result) {
            this.setState({
                isLoading: true
            })

            if(req_selectedBatchId !== "") { //for date changes
                let getPcpId = null;

                physicalClassParticipants && physicalClassParticipants.map(part => {
                    if(part.userId === reqClass_userId && part.courseId === reqClass_courseId) {
                        getPcpId =  part.id
                    }
                    return null
                })

                let data = {
                    phone: this.state.req_phone,
                    newBatchId: this.state.selectedBatchId,
                    oldPcpId: getPcpId,
                    isChange_batchId: this.props.req_selectedBatchId //from old selectedBatchId
                }
                this.props.submitFormReqClass(data);
                this.insertNewData();
            } 
            
            if (req_selectedBatchId === "") {
                let data = {
                    name: name,
                    email: email,
                    phone: this.state.req_phone,
                    date: this.props.req_date,
                    userId: this.props.reqClass_userId,
                    courseId: this.props.reqClass_courseId,
                    selectedBatchId: this.state.selectedBatchId,
                    isChange_batchId: this.props.req_selectedBatchId //from old selectedBatchId
                }
                this.props.submitFormReqClass(data);
                this.insertNewData();
            }
            this.props.updateContactNo(this.state.req_phone, this.props.reqClass_userId)
        }
    }

    chooseBatch = (id, date, name, selectedParticipants) => {
        this.setState({
            selectedBatchId: id,
            selectedBatchName: name,
            selectedBatchDate: date,
            selectedBatch_selectedParticipants: selectedParticipants
        })
    }

    cancelBatch = () => {
        this.setState({
            selectedBatchId: '',
            selectedBatchName: '',
            selectedBatchDate: '',
        })
    }

    render(){

        const { physicalClassBatches, profile, courses, reqClass_userId, reqClass_courseId } = this.props;
        const { message, isLoading } = this.state;
        
        let newBatchTotal = [];

        return(

        <div>

            <b className="middle">Request for practical session
                <div className="row"><div className="title-border"></div></div>
            </b>

            <div className="alert alert-warning">
                <div className="listing"> 
                    <div className="list-icon"><li></li></div>
                    <span>Submit this form only if you <b>have not attend the 4 days practical session</b> with Halal Academy, else your request will be declined.</span>
                </div>
                <div className="listing"> 
                    <div className="list-icon"><li></li></div>
                    <span>Your request will only be approved and listed to the batch when you have completed all online theory session including exam.</span>
                </div>
                <div className="listing"> 
                    <div className="list-icon"><li></li></div>
                    <span>You will be notified and added to the WhatsApp group once your request has been approved.</span>
                </div>
                <div className="listing"> 
                    <div className="list-icon"><li></li></div>
                    <span>Once your requested dates had been <b>approved and listed</b>, no further changes of dates will be entertained. </span>
                </div>
            </div>

            <div className="alert alert-danger">
                <div className="listing"> 
                    <div className="list-icon"><li></li></div>
                    <span>Please be informed that the practical session will be held <b>physically</b>.</span>
                </div>
                <div className="listing"> 
                    <div className="list-icon"><li></li></div>
                    <span>The details of the practical session (when, where, etc.) will be sent by e-mail once our administrator has approved your class request.</span>
                </div>
            </div>

            <br/>

            {profile.userId === reqClass_userId ?
                <div>
                    <div className="row">
                        <div className="col">
                            <label htmlFor="req_name">Name:</label>
                            <input type="text" id="req_name" disabled className="form-control" value={profile.firstname + ' ' + profile.lastname}/>
                        </div>
                        <div className="col">
                            <label htmlFor="req_course">Course Name:</label>

                            {courses && courses.map((course,i) => {
                                if (course.id === reqClass_courseId) {
                                    return (
                                        <input key={i} type="text" id="req_course" disabled className="form-control" value={course.courseName}/>
                                    )
                                }
                                return null
                            })}
                        </div>
                    </div>
                    
                    <br/>

                    <div className="row">
                        <div className="col">
                            <label htmlFor="req_email">Email:</label>
                            <input type="text" id="req_email" disabled className="form-control" value={profile.email}/>
                        </div>
                        <div className="col">
                            <label htmlFor="req_phone">Phone Number:</label>
                            <input type="text" id="req_phone" onChange={this.handleChange} className="form-control" value={this.state.req_phone} placeholder="Contactable phone number"/>
                        </div>
                    </div>

                    <br/>

                    { this.state.selectedBatchId === "" ?
                        <div>
                            <label>Choose your preferred practical session date:</label>
                            <table className="table mt-1">
                                <thead>
                                    <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col">Date (4 days class)</th>
                                        <th scope="col">Choose</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    { physicalClassBatches && physicalClassBatches.map((batch,i) => {
                                        if(batch.courseId === reqClass_courseId && batch.status === "") {

                                            let hasRequest = null;

                                            batch.selectedParticipants && batch.selectedParticipants.map(part => {
                                                if(profile.userId === part.userId) {
                                                    hasRequest = true
                                                }
                                                return null
                                            })

                                            newBatchTotal.push(batch)

                                            if(!batch.isHide || batch.isHide === false || batch.isHide === "" || batch.isHide === null) {
                                                return (
                                                    <tr key={i}>
                                                        <td className="text-left">{batch.batchName}</td>
                                                        <td className="text-left">
                                                            <li>{batch.date.day1}</li>
                                                            <li>{batch.date.day2}</li>
                                                            <li>{batch.date.day3}</li>
                                                            <li>{batch.date.day4}</li>
                                                        </td>
                                                        <td>
                                                            { hasRequest === true ?
                                                                    <i>Requested</i>
                                                                :
                                                                    parseInt(batch.batchAmount) <=  batch.selectedParticipants.length ? 
                                                                        <i>Full</i>
                                                                    : 
                                                                        <span onClick={this.chooseBatch.bind(this, batch.id, batch.date, batch.batchName, batch.selectedParticipants)} className="badge bg-blue text-white">Choose</span>
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            } else return null
                                        }
                                        return null
                                    })}
                                </tbody>
                            </table>
                            { newBatchTotal.length < 1 ?
                                <small>
                                    <i>**Currently, no practical session is available.</i><br/>
                                    <i>**Please contact <b>017-3301261</b> for updates on practical sessions.</i><br/>
                                </small>
                            : null }
                        </div>
                    : 
                        <div>
                            <label>Selected dates:</label> <br/>
                            <label  style={{marginLeft:"20px"}}>{this.state.selectedBatchName}</label>
                            <span style={{marginLeft:"20px"}} className="text-red" onClick={this.cancelBatch.bind(this)}><i className="fas fa-times"/>{' '}Cancel</span><br/>
                            <div style={{marginLeft:"40px"}}>
                                <li>{this.state.selectedBatchDate.day1}</li>
                                <li>{this.state.selectedBatchDate.day2}</li>
                                <li>{this.state.selectedBatchDate.day3}</li>
                                <li>{this.state.selectedBatchDate.day4}</li>
                            </div>
                        </div>
                    }

                    <br/>
                    
                    { isLoading === true ? 
                        <small>Loading...</small>
                    : 
                        message  ?
                            <small className={message.type === 'submitFormClassError' ? "text-red" : message.type === 'submitFormClassSuccess'  ? "text-green" : null }>
                                {message.msg}
                            </small>
                        : null
                    }

                    <div className="row justify-content-end">
                        { message && message.type === 'submitFormClassSuccess' ?
                            <button className="btn m-2 btn-disable">submit{' '}<i className="fas fa-arrow-circle-right"/></button>
                        :
                            this.state.selectedBatchId ?
                                <button className="btn m-2 all-btn" onClick={this.submitFormReqClass.bind(this)}>submit{' '}<i className="fas fa-arrow-circle-right"/></button>
                            :
                                <button className="btn m-2 btn-disable">submit{' '}<i className="fas fa-arrow-circle-right"/></button>
                        }
                    </div>
                </div>
            :''}
        </div>
       
        )
    }
}

const mapStateToProps = (state) => {
    return {
        submitFormClassError: state.classes.submitFormClassError,
        submitFormClassSuccess: state.classes.submitFormClassSuccess,
    }
  }

const mapDispatchToProps = (dispatch) => {
    return {
      submitFormReqClass: (participant) => dispatch(submitFormReqClass(participant)),
      updatePCB_selectedParticipants: (data, pcbId) => dispatch(updatePCB_selectedParticipants(data, pcbId)),
      updateContactNo: (contactNo, userId) => dispatch(updateContactNo(contactNo, userId)),
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(RequestClass);