import React, { Component } from 'react';
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import { newEnrollment } from '../../../stores/actions/enrollmentAction';

class NewEnrollment extends Component{
    
    constructor(props){
        super(props)
        this.state = {
            userId: '',
            enrollmentEmail:'',
            enrollmentType:'',
            enrollmentCourseName:'',
            enrollmentCourseId:'',
            enrollmentModuleId:'',
            enrollmentCustomLabel:'',
    
            hideDropdown: true,
            hideBtnCheckEmail: false,
    
            isLoading: null,
            message: null,

            employerId: '',
        }
        this.baseState = this.state 
    }
    
    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }
    
    componentDidUpdate = (prevProps, prevState) => { 
        const { newEnrollmentError, newEnrollmentSuccess } = this.props;

        if(prevProps.newEnrollmentError !== newEnrollmentError) {
            this.setState({
                isLoading: false,
                message: {
                    msg: newEnrollmentError.message,
                    type: newEnrollmentError.type,
                }
            })
        }

        if(prevProps.newEnrollmentSuccess !== newEnrollmentSuccess) {
            this.setState({
                isLoading: false,
                message: {
                    msg: newEnrollmentSuccess.message,
                    type: newEnrollmentSuccess.type,
                }
            })
            setTimeout(() => { 
                this.setState(this.baseState)
                this.props.hide();
            }, 5000);
        }
    }

    selectedCourse = (e) => {
        this.setState({
            enrollmentCourseId: e.target[e.target.selectedIndex].id,
            enrollmentCourseName: e.target.value
        })
    }

    handleEmail = (e) => {
        const { users, emp_enroll, auth } = this.props;

        let userId = '';
        let email = e.target.value;

        users && users.map(user => {
            if(user.email === email.toLowerCase()) {
                return userId =  user.userId
            }
            return null
        })

        this.setState({ enrollmentEmail: email })

        if(emp_enroll === true) {
            this.props.history.push(`/userdashboard?type=enrollment&tab=new&userId=${userId}`);
            this.setState({ employerId: auth.uid })
        } else {
            this.props.history.push(`/adminmanager?topic=enrollment&filter=new-enrollment&userId=${userId}`);
        }
    }

    checkEmail = (e) => {
        e.preventDefault();

        const { users } = this.props;
        let emailLists = null, email = null;

        emailLists = users && users.map(user => {
            return user.email
        })

        email = [...emailLists]

        if (email.indexOf(this.state.enrollmentEmail.toLowerCase()) >=0) { //cari persamaan email
            this.setState({
                hideDropdown: !this.state.hideDropdown,
                hideBtnCheckEmail: !this.state.hideBtnCheckEmail
            })
        } else {
            this.setState({enrollmentEmail: ''})
            alert('email not registered')
        } return null
    }

    enrollmentData = async () => {
        const { users, courses, emp_enroll } = this.props; 

        if (this.state.enrollmentEmail !== null) {
            users && users.map(user => {
                if ( this.state.enrollmentEmail.toLowerCase() === user.email ) {

                    let enrollmentCourseId = '', enrollmentModuleId = '';

                    courses && courses.map(course => {
                        if ((course.id === this.state.enrollmentCourseId && this.state.enrollmentType === "Professional") || (course.id === this.state.enrollmentCourseId && emp_enroll === true)) {
                            enrollmentCourseId = course.id
                        } 

                        if (course.id === this.state.enrollmentCourseId && this.state.enrollmentType === "Flexi") {
                            enrollmentCourseId = course.selectedCourse;
                            enrollmentModuleId =  course.selectedModule;
                        } 
                        return null
                    });
            
                    this.setState({
                        userId: user.userId,
                        enrollmentCourseId: enrollmentCourseId,
                        enrollmentModuleId: enrollmentModuleId,
                    })

                    if(emp_enroll === true) {
                        this.setState({ enrollmentType: "Professional"})
                    }

                }
                return null
            })
        }
    }

    requestEnrollment = async () => {
        await this.enrollmentData();

        const { emp_enroll, empEnrollments, courseEnrollments, moduleEnrollments, employers } = this.props; 
        const { enrollmentType, enrollmentCourseId, enrollmentModuleId } = this.state;

        let hasEnrolledCourse = false, hasEnrolledModule = false;

        if(enrollmentType === "Professional") {
            courseEnrollments && courseEnrollments.map(enrolled => {
                if(enrolled.courseId === enrollmentCourseId) {
                    hasEnrolledCourse = true
                }
                return null
            })

            let emp_nopax = 0, empEnrollments_count = []; 

            empEnrollments && empEnrollments.map(emp => {
                if(emp.courseId === enrollmentCourseId) {
                    return empEnrollments_count.push(emp)
                } return null
            })

            employers && employers.map(emp => { return emp.courseId === enrollmentCourseId ?  emp_nopax = emp.noPax : null} );
            
            if(emp_enroll === true && (emp_nopax === (empEnrollments_count && empEnrollments_count.length))) {
                alert('Limit reached! You can purchase for additional enrollments.')
            } else {
                if (hasEnrolledCourse === false) {
                    this.props.show();
                } else {
                    alert(`${this.state.enrollmentEmail} has already enrolled this course.`)
                }
            }
        }

        if(enrollmentType === "Flexi") {
            moduleEnrollments && moduleEnrollments.map(enrolled => {
                if(enrolled.courseId === enrollmentCourseId && enrolled.moduleId === enrollmentModuleId) {
                    hasEnrolledModule = true
                }
                return null
            })
        
            if (hasEnrolledModule === false) {
                this.props.show();
            } else {
                alert(`${this.state.enrollmentEmail} has already enrolled this module.`)
            }
        }
    }

    newEnrollment = (e) => {
        var result = window.confirm("Confirm enroll?");

        if (result) {
            this.setState({
                isLoading: true
            })
            this.props.newEnrollment(this.state)
        }
    }

    render(){

        const { courses, emp_enroll, employers, auth } = this.props;
        const { enrollmentType, enrollmentCourseName, message, isLoading } = this.state;

        let courseList =  courses && courses.map((course,i) => {
            if (enrollmentType === "Professional" && course.courseType === "Professional") {
                return (
                    <option key={i} id={course.id}>{course.courseName}</option>
                )
            } else if (enrollmentType === "Flexi" && course.courseType === "Flexi") {
                return (
                    <option key={i} id={course.id}>{course.courseName}</option>
                )
            }
            return null
        })

        let emp_courseList = courses && courses.map((course,i) => {
            return (
                employers && employers.map(emp => {
                    if (course.id === emp.courseId && emp.userId === auth.uid) {
                        return (
                            <option key={i} id={course.id}>{course.courseName}</option>
                        )
                    } else return null
                })
            )
        })

        return(

        <div>

            <div id="myModal" className="mymodal">
                <div className="mymodal-content col-md-6">
                    <span className="close" onClick={this.props.hide}>&times;</span>
                    <h5 className="middle">enrollment confirmation
                        <div className="row"><div className="title-border"></div></div>
                    </h5>
                    
                    <table className="table text-center">
                        <thead>
                            <tr>
                                <th scope="col">User Email</th>
                                <th scope="col">Mode</th>
                                <th scope="col">Course</th>
                            </tr>
                        </thead>
                         <tbody>
                             <tr>
                                <td>{this.state.enrollmentEmail.toLowerCase()}</td>
                                <td>{this.state.enrollmentType}</td>
                                <td>{this.state.enrollmentCourseName}</td>
                            </tr>
                        </tbody>
                    </table>

                    { isLoading === true ? 
                        <small>Loading...</small>
                    : 
                        message  ?
                            <small className={message.type === 'newEnrollmentError' ? "text-red" : message.type === 'newEnrollmentSuccess'  ? "text-green" : null }>
                                {message.msg}
                            </small>
                        : null
                    }

                    <div className="mt-5 row justify-content-end"> 
                        <button className="btn btn-outline-black" onClick={this.newEnrollment.bind(this)}><i className="fas fa-check-circle"/>{' '}enroll user</button>
                    </div>
                </div>
            </div>

            <input type="email" id="enrollmentEmail" className="mt-3 form-control" onChange={this.handleEmail} value={this.state.enrollmentEmail} placeholder="Email"/>

            { emp_enroll === true ? null :
                <div hidden={this.state.hideDropdown}>
                    <select id="enrollmentType" onChange={this.handleChange} className="form-control" value={enrollmentType}>
                        <option defaultValue>Course Mode</option>
                        <option>Professional</option>
                        <option>Flexi</option>
                    </select>
                </div>
            }

            <div hidden={this.state.hideDropdown}>
                {this.state.enrollmentType || emp_enroll === true ?
                    <select id="enrollmentCourseName" onChange={this.selectedCourse.bind(this)} value={enrollmentCourseName} className="form-control">
                        <option defaultValue>Course Name</option>
                        {emp_enroll === true ? emp_courseList : courseList}
                    </select>
                : null }
            </div>

            { emp_enroll === true ? null :
                <div hidden={this.state.hideDropdown}>
                    {this.state.enrollmentCourseId ? 
                        <p className="mt-2">
                            Enrollment Custom Label : <small><i>**For custom enrollment only</i></small>
                            <input className="form-control" id="enrollmentCustomLabel" onChange={this.handleChange} value={this.state.enrollmentCustomLabel} placeholder="Enrollment Custom Label" />
                        </p>
                    : null }
                </div>
            }

            <div hidden={this.state.hideBtnCheckEmail} className="mt-5 row justify-content-end">
                <button className="btn m-2 btn-outline-black" onClick={this.checkEmail.bind(this)}><i className="fas fa-search"/>{' '}check email</button>
            </div>

            { this.state.enrollmentCourseId ?
                <div className="mt-5 row justify-content-end">
                    <button className="btn m-2 btn-outline-black" onClick={this.requestEnrollment.bind(this)}><i className="fas fa-plus-circle"/>{' '}request enrollment</button>
                </div>
            : null }
        </div>

        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.firebase.auth,

        newEnrollmentError: state.enrollments.newEnrollmentError,
        newEnrollmentSuccess: state.enrollments.newEnrollmentSuccess,

        courseEnrollments: state.firestore.ordered.courseEnrollments,
        moduleEnrollments: state.firestore.ordered.moduleEnrollments,

        userId: ownProps.query.get('userId'),
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
      newEnrollment: (enrollment) => dispatch(newEnrollment(enrollment)),
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect(props => [
        { collection: 'courseEnrollments', where: ['userId', '==', props.userId ] },
        { collection: 'moduleEnrollments', where: ['userId', '==', props.userId] },
    ])
)(NewEnrollment);