import React, { Component } from 'react';
import { connect } from 'react-redux'
import { emailVerified } from '../stores/actions/authAction';

const jwt = require('jsonwebtoken');

class EmailVerified extends Component {

    state = {
        message:'',
        type:'',
    }
    
    componentDidUpdate = (prevProps, prevState) => { 

        const { verifiedError, verifiedSuccess } = this.props;

        if(prevProps.verifiedError !== verifiedError) {
            setTimeout(() => { 
                alert(verifiedError)
                this.props.history.push('/')
            }, 2000);
        }

        if(prevProps.verifiedSuccess !== verifiedSuccess) {
            setTimeout(() => { 
                alert(verifiedSuccess)
                this.props.history.push('/enrollment')
            }, 2000);
        }
    } 

    componentDidMount = async () => {
        let token = this.props.match.params.token;
        let key = this.props.match.params.activekey;

        let decodedActivationKey = jwt.verify(key, 'hashedmyaccactivationkey');

        try {
            if (token && key) {
                if(decodedActivationKey) {
                    let decoded = jwt.verify(token, decodedActivationKey);
                    const { wn, fn, ln, em, pw, hic, dob, cpny } = decoded;

                    let newUser = { 
                        fullname: wn,
                        firstname: fn, 
                        lastname: ln, 
                        email: em, 
                        password: pw,
                        ic: hic,
                        dob: dob,
                        company: cpny,
                    };

                    if(newUser) {
                        this.props.emailVerified(newUser);
                    } else {
                        alert('User data invalid!')
                        this.props.history.push('/')
                    }

                }
            } 
        } catch(err) {
            alert(err)
            this.props.history.push('/')
        }
    }

    render () {
        return (
            <div className="min-height middle">
               <div className="page-bgstyle middle">
                    <div className="loader"/>
                    <b className="text-red mt-5">DO NOT CLOSE THIS PAGE UNTIL A POPUP APPEAR!</b>
               </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        verifiedError: state.auth.verifiedError,
        verifiedSuccess: state.auth.verifiedSuccess,
        auth: state.firebase.auth
    }
  }

const mapDispatchToProps = (dispatch) => {
    return {
        emailVerified: (newUser) => dispatch(emailVerified(newUser))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(EmailVerified);