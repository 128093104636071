import React, { Component } from 'react';
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'

class TermsAndConditions extends Component{


    render(){

        const { infos } = this.props;

        let isLoading = true;
        let no = 0;

        let termList = infos && infos.map((info ,i) => {
            if( info.infoType === "TERM AND CONDITION") {
                no = no + 1;
                isLoading = false;
                return (
                    <div key={i} className="hiw-box" style={{margin:"40px 0px 0px 0px"}}>
                        <b className="title">{no}.{' '}{info.infoName}</b>
                        <div className="title-border"></div>
                        <p style={{textAlign:"justify"}} dangerouslySetInnerHTML={{__html: info.infoContent}}></p>
                    </div>
                )
            }
            return null
        });

        return(

            <div className="container col-md-12 min-height justify-content-center middle">
                <div className="col-md-9 page-bgstyle">
                    <div className="middle">
                        <div className="col col-md-4" style={{marginBottom:"30px"}}> 
                            <img src="../../../image/HA_LOGO.png" alt="" width="45%"/><br />
                        </div>
                        
                        <h4>TERMS AND CONDITIONS
                            <div className="title-border"></div>
                        </h4>
                    </div>

                    <div className="text-left mt-3">
                        { isLoading === true ? <div className="middle"><div className="loader"></div></div> : termList }
                    </div>
                </div>
            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return {
      infos: state.firestore.ordered.infos,
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect([
        { collection: 'infos'},
    ])
)(TermsAndConditions);