import React, { Component } from 'react';
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import ContactUsForm from './ContactUsForm';

import EmailUser from './EmailUser';

class Faq extends Component {

    state = {
        changeState:'ts',
        
        clickedFaqId: '',
        modalType: ''
    }

    handleChange = (type) => {
        this.setState({
            changeState: type
        })
    }

    showModal = (type) => {
        this.setState({
            modalType: type
        })

        document.getElementById("modal-form").style.display = "block";
    }

    hideModal = () => {
        document.getElementById("modal-form").style.display = "none";
    }

    openDetail = (faqId) => {
        this.setState({
            clickedFaqId: faqId,
        })
    }

    closeDetail = () => {
        this.setState({
            clickedFaqId:'',
        })
    }

    render() {

        const { auth, profile, courses, modules, topics, users, supportEmail, faqs } = this.props;
        const { modalType } = this.state;

        let isLoadingQna = true, isLoadingSupport = true, qnaLength = [], tsLength =  [];

        let qnaList = faqs && faqs.map((faq,i) => {
            if(faq.faqType === "qna") {
                qnaLength.push(faq)
                isLoadingQna = false
                return (
                    <div key={i} className="detail-btn">
                        <b className="text-green">{faq.faqQuestion}</b>
                        <span 
                            onClick={!this.state.clickedFaqId ? this.openDetail.bind(this, faq.id) : this.closeDetail.bind(this)}>
                            <i className={faq.id !== this.state.clickedFaqId ? "fas fa-angle-down float-right" : "fas fa-minus float-right"} />
                        </span>
                        {faq.id === this.state.clickedFaqId ? 
                            <div className="content">
                                <label>
                                    <p dangerouslySetInnerHTML={{__html: faq.faqAnswer}}></p>
                                    { faq.faqLink.faqLink_link ?
                                        <a className="text-green" href={faq.faqLink.faqLink_link}>
                                            <i><i className="fas fa-link"/>{' '}{faq.faqLink.faqLink_name}</i>
                                        </a>
                                    : null }
                                </label>
                            </div>
                        :''}
                    </div>
                )
            } else {
                isLoadingQna = false
            }
            return null
        })

        let tsList = faqs && faqs.map((faq,i) => {
            if(faq.faqType === "ts") {
                tsLength.push(faq)
                isLoadingSupport = false
                return (
                    <div key={i} className="detail-btn">
                        <b className="text-green">{faq.faqQuestion}</b>
                        <span 
                            onClick={!this.state.clickedFaqId ? this.openDetail.bind(this, faq.id) : this.closeDetail.bind(this)}>
                            <i className={faq.id !== this.state.clickedFaqId ? "fas fa-angle-down float-right" : "fas fa-minus float-right"} />
                        </span>
                        {faq.id === this.state.clickedFaqId ? 
                            <div className="content">
                                <label>
                                    <p dangerouslySetInnerHTML={{__html: faq.faqAnswer}}></p>
                                    { faq.faqLink.faqLink_link ?
                                        <i>Link attachment: <br/>
                                            <a target="_blank" rel="noopener noreferrer" href={faq.faqLink.faqLink_link}> <i><b><i className="fas fa-link"/>{' '}{faq.faqLink.faqLink_name}</b></i> </a>
                                        </i>
                                    : null }
                                </label>
                            </div>
                        :''}
                    </div>
                )
            } else {
                isLoadingSupport = false
            }
            return null
        })

        // if (!auth.uid) {
        //     return null
        // } else {
            return (
                <div className="faq">
                    {/* ---------------------SHOW MODAL EMAIL--------------------- */}
                    <div id="modal-form" className="mymodal">
                        <div className={ modalType === "contact" ? "mymodal-content col-md-6" : 'mymodal-content col-md-12' }>
                            <span className="close" onClick={this.hideModal.bind(this)}>&times;</span>

                            { modalType === "email" ?
                                /* ---------- EMAIL ----------  */
                                <div>
                                    <div className="row col-md-12" style={{marginTop:"5px"}}>
                                        <button className={this.state.changeState === 'qna' ? "btn all-btn btn-active" : " btn all-btn"}  onClick={this.handleChange.bind(this, 'qna')}><i className="fas fa-book-reader"/>{' '}Ask related to e-learning</button>
                                        <button className={this.state.changeState === 'ts' ? "btn all-btn btn-active" : " btn all-btn"}  onClick={this.handleChange.bind(this, 'ts')}><i className="fas fa-tools"/>{' '}Technical Support</button>
                                    </div>

                                    <div>
                                        { this.state.changeState ?
                                            <EmailUser type={this.state.changeState} auth={auth} profile={profile} users={users} supportEmail={supportEmail} courses={courses} modules={modules} topics={topics} />
                                        : <small className="mt-5"><i>Please choose Q&A or Technical Support</i></small>}
                                    </div>
                                </div> 
                            
                            : modalType === "faq" ?
                                /* ---------- FAQ ----------  */
                                <div className="middle">
                                    <img src="/image/faq.png" alt="" width="8%"/>
                                    <br/>
                                    <div className="row col-md-12 justify-content-center">
                                        <div className="mt-5 col col-md-6">
                                            <b>Question & Answers</b>
                                            
                                            <div className="mt-3">
                                                { isLoadingQna === true ? <div className="middle"><div className="loader "></div></div> : qnaList }
                                                { isLoadingQna === false && qnaLength.length < 1 ? <label className="text-red"><i>Theres no current QnA. Please submit yours if theres any.</i></label> : null }
                                            </div>
                                        </div>
                                        
                                        <div className="mt-5 col col-md-6">
                                            <b>Technical Support</b>
                                            
                                            <div className="mt-3">
                                                { isLoadingSupport === true ? <div className="middle"><div className="loader "></div></div> : tsList }
                                                { isLoadingSupport === false && tsLength.length < 1 ? <label className="text-red"><i>Theres no current technical support questions. Please submit yours if theres any.</i></label> : null }
                                            </div>
                                        </div>
                                    </div>

                                    <br/><br/>

                                    <label>Did not find your problem? Click <button onClick={this.showModal.bind(this, 'email')} className="btn all-btn">HERE</button> to submit your questions!</label>
                                </div>
                            :
                                /* ---------- BASIC ----------  */
                                <div className="middle">
                                    <img src="/image/doodle.png" alt="" width="30%"/>
                                    <br/>
                                    <ContactUsForm />
                                </div>
                            }

                        </div>
                    </div>

                    {/* <a href="https://forms.gle/cAL71K17PPQqXyyo6" target="_blank" rel="noopener noreferrer" className="button-float-chat2" title="Having an issue with new updates? Report to us by click here!">
                        <img src="/image/bug-report.png" alt="" width="100%"/>
                    </a> */}

                    <span onClick={this.showModal.bind(this, auth.uid ? 'faq' : 'contact')} className="button-float-chat" title="Contact Us!">
                        <img src="/image/doodle.png" alt="" width="100%"/>
                    </span>
                </div>
            )
        // }
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile,

        courses: state.firestore.ordered.courses,
        modules: state.firestore.ordered.modules,
        topics: state.firestore.ordered.topics,
        supportEmail: state.firestore.ordered.supportEmail,
        users: state.firestore.ordered.users,
        faqs: state.firestore.ordered.faqs,
    }
}

  
export default compose(
    connect(mapStateToProps),
    firestoreConnect([
        { collection: 'faqs'},
        { collection: 'courses'},
        { collection: 'modules'},
        { collection: 'topics'},
        { collection: 'supportEmail', orderBy:["date", 'desc']},
        { collection: 'users'},
    ])
)(Faq);