import React from "react";
import { Document, Page, Text, View, StyleSheet, Image, Font } from "@react-pdf/renderer";
import sign from './signkaknoi.JPG';

// const styles = StyleSheet.create({
//     page: {
//         textTransform:'uppercase',
//         padding:30
//     },
//     title: {
//         fontSize:10,
//         fontFamily:'Helvetica-Bold',
//         margin:"0px 10px",
//         marginBottom:20,
//         textTransform:'capitalize',
//         textAlign:'left',
//         paddingBottom:5,
//         borderBottom:'2px solid #b9cec3',
//     },
//     table: {
//         marginBottom:'20px',
//         width: '100%',
//         textAlign: 'left'
//     },
//     row:{
//         flexDirection: "row" ,
//         justifyContent:'center',
//     },
//     colTitle: {
//         fontFamily:'Helvetica-Bold',
//         borderStyle: "solid", 
//         borderWidth: 1, 
//         borderColor: '#b9cec3',
//         backgroundColor: '#b9cec3',
//         marginRight: 2,
//         marginBottom: 2,
//     },
//     title1:{ //no
//         width: "3%",
//     },
//     title2:{ //name
//         width: "33%",
//     },
//     title3:{ //ic
//         width: "12%",
//     },
//     title4:{ //company
//         width: "35%",
//     },
//     title5:{ //email
//         width: "25%",
//     },
//     title6:{ //contact
//         width: "13%",
//     },
//     colSubtitle: { 
//         borderColor: '#b9cec3',
//         borderStyle: "solid", 
//         borderWidth: 1,
//         marginRight: 2,
//         marginBottom: 2,
//     }, 
//     subtitle1: { 
//         width: "3%",
//         textAlign:'center'
//     }, 
//     subtitle2: { 
//         width: "33%",
//     }, 
//     subtitle3: { 
//         width: "12%",
//     }, 
//     subtitle4: { 
//         width: "35%",
//     }, 
//     subtitle5: { 
//         width: "25%",
//         textTransform:'lowercase'
//     }, 
//     subtitle6: { 
//         width: "13%",
//     }, 
//     tableCell:{
//         fontSize: 8,
//         padding:5,
//     }, 
//     colTitleGroup:{
//         borderColor: '#cacaca',
//         backgroundColor: '#cacaca',
//     },
//     colSubtitleGroup:{
//         borderColor: '#cacaca',
//     },
//     groupTitle:{
//         fontSize:10,
//         fontFamily:'Helvetica-Bold',
//         textTransform:'capitalize',
//         margin:10,
//         borderBottom:'1px dotted #cacaca',
        
//     },
//     notUpdated: {
//         fontSize:8,
//         textTransform:'capitalize',
//         color:'#888888'
//     },

//     //LOGIN DATA
//     sign: { width: '30%'},
//     page2: { padding:'30px'},
//     loginTitle1: { width:"5%", textAlign:'center' },
//     loginTitle2: { width:"30%", textAlign:'center' },
//     loginTitle3: { width:"25%", textAlign:'center'},
//     loginTitle4: { width:"20%", textAlign:'center' },
//     loginTitle5: { width:"20%", textAlign:'center' },

//     loginSubtitle1: { width:"5%", textAlign:'center'},
//     loginSubtitle2: { width:"30%"},
//     loginSubtitle3: { width:"25%"},
//     loginSubtitle4: { width:"20%", textAlign:'center'},
//     loginSubtitle5: { width:"20%", textAlign:'center'},

//     colTitle2: {
//         fontFamily:'Helvetica-Bold',
//         borderStyle: "solid", 
//         borderWidth: 1, 
//         borderColor: '#aaaaaa',
//         marginRight: 2,
//         marginBottom: 2,
//     },
//     colSubtitle2: { 
//         borderColor: '#aaaaaa',
//         borderStyle: "solid", 
//         borderWidth: 1,
//         marginRight: 2,
//         marginBottom: 2,
//     }, 

//     loginTitle: { fontSize:8 }
// });

const styles = StyleSheet.create({
    page: {
        textTransform:'uppercase',
        padding:30
    },
    title: {
        fontSize:10,
        fontFamily:'Helvetica-Bold',
        margin:"0px 10px",
        marginBottom:20,
        textTransform:'capitalize',
        textAlign:'left',
        paddingBottom:5,
        borderBottom:'2px solid #b9cec3',
    },
    table: {
        marginBottom:'20px',
        width: '100%',
        textAlign: 'left'
    },
    row:{
        flexDirection: "row" ,
        justifyContent:'center',
    },
    colTitle: {
        fontFamily:'Helvetica-Bold',
        borderStyle: "solid", 
        borderWidth: 1, 
        borderColor: '#b9cec3',
        backgroundColor: '#b9cec3',
        marginRight: 1,
        marginBottom: 1,
    },
    title1:{ //no
        width: "5%",
        textAlign:'center',
    },
    title2:{ //name
        width: "21%",
        textAlign:'center',
    },
    title3:{ //ic
        width: "9%",
        textAlign:'center',
    },
    title4:{ //company
        width: "13%",
        textAlign:'center',
    },
    title5:{ //email
        width: "14%",
        textAlign:'center',
    },
    title6:{ //contact
        width: "9%",
        textAlign:'center',
    },
    title7:{ //score exam
        width: "4%",
        textAlign:'center',
    },
    title8:{ //test
        width: "4%",
        textAlign:'center',
    },
    colSubtitle: { 
        borderColor: '#b9cec3',
        borderStyle: "solid", 
        borderWidth: 1,
        marginRight: 1,
        marginBottom: 1,
    }, 
    subtitle1: { 
        width: "5%",
        textAlign:'center'
    }, 
    subtitle2: { 
        width: "21%",
    }, 
    subtitle3: { 
        width: "9%",
    }, 
    subtitle4: { 
        width: "13%",
    }, 
    subtitle5: { 
        width: "14%",
        textTransform: 'lowercase',
    }, 
    subtitle6: { 
        width: "9%",
    }, 
    subtitle7: { 
        width: "4%",
        textAlign:'center',
    }, 
    subtitle8: { 
        width: "4%",
        textAlign:'center',
    }, 
    tableCell:{
        fontSize: 8,
        padding:2,
    }, 
    colTitleGroup:{
        borderColor: '#cacaca',
        backgroundColor: '#cacaca',
    },
    colSubtitleGroup:{
        borderColor: '#cacaca',
    },
    groupTitle:{
        fontSize:10,
        fontFamily:'Helvetica-Bold',
        textTransform:'capitalize',
        margin:10,
        borderBottom:'1px dotted #cacaca',
        
    },
    notUpdated: {
        fontSize:8,
        textTransform:'capitalize',
        color:'#888888'
    },

    //LOGIN DATA
    sign: { width: '30%'},
    page2: { padding:'30px'},
    loginTitle1: { width:"5%", textAlign:'center' },
    loginTitle2: { width:"30%", textAlign:'center' },
    loginTitle3: { width:"25%", textAlign:'center'},
    loginTitle4: { width:"20%", textAlign:'center' },
    loginTitle5: { width:"20%", textAlign:'center' },

    loginSubtitle1: { width:"5%", textAlign:'center'},
    loginSubtitle2: { width:"30%"},
    loginSubtitle3: { width:"25%"},
    loginSubtitle4: { width:"20%", textAlign:'center'},
    loginSubtitle5: { width:"20%", textAlign:'center'},

    colTitle2: {
        fontFamily:'Helvetica-Bold',
        borderStyle: "solid", 
        borderWidth: 1, 
        borderColor: '#aaaaaa',
        marginRight: 2,
        marginBottom: 2,
    },
    colSubtitle2: { 
        borderColor: '#aaaaaa',
        borderStyle: "solid", 
        borderWidth: 1,
        marginRight: 2,
        marginBottom: 2,
    }, 

    loginTitle: { fontSize:8 }
});

export function BatchPdf(props) {

    var pdfType = props.type;
    var batchId = props.batchId;
    var batchCode = props.batchData && props.batchData.batchCode;
    var participants =  props.batchData && props.batchData.selectedParticipants;
    var fullTestingstamp =  props.fullTestingstamp;

    //user login data
    var modules = props.modules;
    var mappedModule = props.mappedModule;
    var examTimestamp = props.examTimestamp;

    var users = props.users;
    var groups = props.pcpGroup;

    let fullPcpData = [];
        
    // Font.registerHyphenationCallback(word => {
    //     // Return entire word as unique part
    //     return [word];
    //   });

    users && users.map(user => {
        return (
            participants && participants.map(part => {
                if(user.userId === part.userId) {
                    fullPcpData.push(
                        {
                            name:user.firstname + ' ' + user.lastname,
                            email: user.email,
                            ic: user.identityCardNo ? user.identityCardNo : <Text style={styles.notUpdated}> Not updated</Text> ,
                            phone: user.contactNo ? user.contactNo : <Text style={styles.notUpdated}> Not updated</Text> ,
                            company: user.company ? user.company : <Text style={styles.notUpdated}> Not updated</Text> ,
                            userId: user.id,
                        }
                    )
                } return null
            })
        )
    })

        return (
            <Document>

                { pdfType === "batch-data" ?

                    <Page size="A4" orientation="landscape" style={styles.page}>

                        <View style={styles.title}> 
                            <Text>BATCH : {batchCode}</Text> 
                        </View> 

                        <View style={styles.table}> 
                            <View style={styles.row}> 
                                <View style={[styles.colTitle, styles.title1]}> 
                                    <Text style={styles.tableCell}>No.</Text> 
                                </View> 

                                <View style={[styles.colTitle, styles.title2]}> 
                                    <Text style={styles.tableCell}>Name</Text> 
                                </View> 

                                <View style={[styles.colTitle, styles.title3]}> 
                                    <Text style={styles.tableCell}>IC</Text> 
                                </View> 

                                <View style={[styles.colTitle, styles.title4]}> 
                                    <Text style={styles.tableCell}>Company</Text> 
                                </View> 

                                <View style={[styles.colTitle, styles.title5]}> 
                                    <Text style={styles.tableCell}>Email</Text> 
                                </View> 

                                <View style={[styles.colTitle, styles.title6]}> 
                                    <Text style={styles.tableCell}>Contact</Text> 
                                </View> 

                                <View style={[styles.colTitle, styles.title7]}> 
                                    <Text style={styles.tableCell}>Exam</Text> 
                                </View> 

                                <View style={[styles.colTitle, styles.title8]}> 
                                    <Text style={styles.tableCell}>Test 1</Text> 
                                </View> 

                                <View style={[styles.colTitle, styles.title8]}> 
                                    <Text style={styles.tableCell}>Test 2</Text> 
                                </View> 

                                <View style={[styles.colTitle, styles.title8]}> 
                                    <Text style={styles.tableCell}>Test 3</Text> 
                                </View> 

                                <View style={[styles.colTitle, styles.title8]}> 
                                    <Text style={styles.tableCell}>Test 4</Text> 
                                </View> 

                                <View style={[styles.colTitle, styles.title8]}> 
                                    <Text style={styles.tableCell}>Test 5</Text> 
                                </View> 

                                <View style={[styles.colTitle, styles.title8]}> 
                                    <Text style={styles.tableCell}>Test 6</Text> 
                                </View> 
                            </View> 

                            { fullTestingstamp && fullTestingstamp.map((data,i) => {
                                let no = i+1;
                                return (
                                    <View key={i} style={styles.row}> 
                                        <View style={[styles.colSubtitle, styles.subtitle1]}> 
                                            <Text style={styles.tableCell}>{no}.</Text> 
                                        </View> 

                                        <View style={[styles.colSubtitle, styles.subtitle2]}> 
                                            <Text style={styles.tableCell}>{data.name}</Text> 
                                        </View> 

                                        <View style={[styles.colSubtitle, styles.subtitle3]}> 
                                            <Text style={styles.tableCell}>{data.ic !== "Not updated" ? data.ic:<Text style={styles.notUpdated}> Not updated</Text>}</Text> 
                                        </View>

                                        <View style={[styles.colSubtitle, styles.subtitle4]}> 
                                            <Text style={styles.tableCell}>{data.company !== "Not updated" ? data.company:<Text style={styles.notUpdated}> Not updated</Text>}</Text> 
                                        </View> 

                                        <View style={[styles.colSubtitle, styles.subtitle5]}> 
                                            <Text style={styles.tableCell}>{data.email}</Text> 
                                        </View>  

                                        <View style={[styles.colSubtitle, styles.subtitle6]}> 
                                            <Text style={styles.tableCell}>{data.phone !== "not updated" ? data.phone:<Text style={styles.notUpdated}> Not updated</Text>}</Text> 
                                        </View> 

                                        <View style={[styles.colSubtitle, styles.subtitle7]}> 
                                            <Text style={styles.tableCell}>{data.examScore}</Text> 
                                        </View> 

                                        <View style={[styles.colSubtitle, styles.subtitle8]}> 
                                            <Text style={styles.tableCell}>{data.module[0] ? data.module[0].score: "-"}</Text> 
                                        </View> 

                                        <View style={[styles.colSubtitle, styles.subtitle8]}> 
                                            <Text style={styles.tableCell}>{data.module[1] ? data.module[1].score: "-"}</Text> 
                                        </View> 

                                        <View style={[styles.colSubtitle, styles.subtitle8]}> 
                                            <Text style={styles.tableCell}>{data.module[2] ? data.module[2].score: "-"}</Text> 
                                        </View> 

                                        <View style={[styles.colSubtitle, styles.subtitle8]}> 
                                            <Text style={styles.tableCell}>{data.module[3] ? data.module[3].score: "-"}</Text> 
                                        </View> 

                                        <View style={[styles.colSubtitle, styles.subtitle8]}> 
                                            <Text style={styles.tableCell}>{data.module[4] ? data.module[4].score: "-"}</Text> 
                                        </View> 

                                        <View style={[styles.colSubtitle, styles.subtitle8]}> 
                                            <Text style={styles.tableCell}>{data.module[5] ? data.module[5].score: "-"}</Text> 
                                        </View> 
                                    </View>
                                )
                            })}
                        </View>

                        { groups && groups.map((group,i) => {
                            if(group.batchId === batchId) {
                                return (
                                    <View key={i}>
                                        <Text style={styles.groupTitle}>{group.groupName}</Text>

                                        <View style={styles.table}> 
                                            <View style={styles.row}> 
                                                <View style={[styles.colTitle, styles.title1, styles.colTitleGroup]}> 
                                                    <Text style={styles.tableCell}>No.</Text> 
                                                </View> 

                                                <View style={[styles.colTitle, styles.title2, styles.colTitleGroup]}> 
                                                    <Text style={styles.tableCell}>Name</Text> 
                                                </View>  

                                                <View style={[styles.colTitle, styles.title3, styles.colTitleGroup]}> 
                                                    <Text style={styles.tableCell}>IC</Text> 
                                                </View> 

                                                <View style={[styles.colTitle, styles.title4, styles.colTitleGroup]}> 
                                                    <Text style={styles.tableCell}>Company</Text> 
                                                </View> 

                                                <View style={[styles.colTitle, styles.title5, styles.colTitleGroup]}> 
                                                    <Text style={styles.tableCell}>Email</Text> 
                                                </View>

                                                <View style={[styles.colTitle, styles.title6, styles.colTitleGroup]}> 
                                                    <Text style={styles.tableCell}>Contact</Text> 
                                                </View> 

                                                <View style={[styles.colTitle, styles.title7, styles.colTitleGroup]}> 
                                                    <Text style={styles.tableCell}>Exam</Text> 
                                                </View> 

                                                <View style={[styles.colTitle, styles.title8, styles.colTitleGroup]}> 
                                                    <Text style={styles.tableCell}>Test 1</Text> 
                                                </View> 

                                                <View style={[styles.colTitle, styles.title8, styles.colTitleGroup]}> 
                                                    <Text style={styles.tableCell}>Test 2</Text> 
                                                </View> 

                                                <View style={[styles.colTitle, styles.title8, styles.colTitleGroup]}> 
                                                    <Text style={styles.tableCell}>Test 3</Text> 
                                                </View> 

                                                <View style={[styles.colTitle, styles.title8, styles.colTitleGroup]}> 
                                                    <Text style={styles.tableCell}>Test 4</Text> 
                                                </View> 

                                                <View style={[styles.colTitle, styles.title8, styles.colTitleGroup]}> 
                                                    <Text style={styles.tableCell}>Test 5</Text> 
                                                </View> 

                                                <View style={[styles.colTitle, styles.title8, styles.colTitleGroup]}> 
                                                    <Text style={styles.tableCell}>Test 6</Text> 
                                                </View> 
                                            </View> 

                                            { group.addedPcp && group.addedPcp.map((pcp,i) => {
                                                let no = i+1
                                                return (
                                                    fullTestingstamp && fullTestingstamp.map(data => {
                                                    if(data.userId === pcp.userId) {
                                                            return (
                                                                <View key={i} style={styles.row}> 
                                                                    <View style={[styles.colSubtitle, styles.subtitle1, styles.colSubtitleGroup]}> 
                                                                        <Text style={styles.tableCell}>{no}.</Text> 
                                                                    </View> 

                                                                    <View style={[styles.colSubtitle, styles.subtitle2, styles.colSubtitleGroup]}> 
                                                                        <Text style={styles.tableCell}>{data.name}</Text> 
                                                                    </View> 

                                                                    <View style={[styles.colSubtitle, styles.subtitle3, styles.colSubtitleGroup]}> 
                                                                        <Text style={styles.tableCell}>{data.ic !== "Not updated" ? data.ic:<Text style={styles.notUpdated}> Not updated</Text>}</Text> 
                                                                    </View> 

                                                                    <View style={[styles.colSubtitle, styles.subtitle4, styles.colSubtitleGroup]}> 
                                                                        <Text style={styles.tableCell}>{data.company !== "Not updated" ? data.company:<Text style={styles.notUpdated}> Not updated</Text>}</Text> 
                                                                    </View> 

                                                                    <View style={[styles.colSubtitle, styles.subtitle5, styles.colSubtitleGroup]}> 
                                                                        <Text style={styles.tableCell}>{data.email}</Text> 
                                                                    </View> 

                                                                    <View style={[styles.colSubtitle, styles.subtitle6, styles.colSubtitleGroup]}> 
                                                                        <Text style={styles.tableCell}>{data.phone !== "Not updated" ? data.phone:<Text style={styles.notUpdated}> Not updated</Text>}</Text> 
                                                                    </View> 

                                                                    <View style={[styles.colSubtitle, styles.subtitle7, styles.colSubtitleGroup]}> 
                                                                        <Text style={styles.tableCell}>{data.examScore}</Text> 
                                                                    </View> 

                                                                    <View style={[styles.colSubtitle, styles.subtitle8, styles.colSubtitleGroup]}> 
                                                                        <Text style={styles.tableCell}>{data.module[0] ? data.module[0].score: "-"}</Text> 
                                                                    </View> 

                                                                    <View style={[styles.colSubtitle, styles.subtitle8, styles.colSubtitleGroup]}> 
                                                                        <Text style={styles.tableCell}>{data.module[1] ? data.module[1].score: "-"}</Text> 
                                                                    </View> 

                                                                    <View style={[styles.colSubtitle, styles.subtitle8, styles.colSubtitleGroup]}> 
                                                                        <Text style={styles.tableCell}>{data.module[2] ? data.module[2].score: "-"}</Text> 
                                                                    </View> 

                                                                    <View style={[styles.colSubtitle, styles.subtitle8, styles.colSubtitleGroup]}> 
                                                                        <Text style={styles.tableCell}>{data.module[3] ? data.module[3].score: "-"}</Text> 
                                                                    </View> 

                                                                    <View style={[styles.colSubtitle, styles.subtitle8, styles.colSubtitleGroup]}> 
                                                                        <Text style={styles.tableCell}>{data.module[4] ? data.module[4].score: "-"}</Text> 
                                                                    </View> 

                                                                    <View style={[styles.colSubtitle, styles.subtitle8, styles.colSubtitleGroup]}> 
                                                                        <Text style={styles.tableCell}>{data.module[5] ? data.module[5].score: "-"}</Text> 
                                                                    </View> 
                                                                </View>
                                                            )
                                                    } return null
                                                    })
                                                )
                                            })}

                                        </View>
                                    </View>
                                )
                            } return null
                        })}
                    </Page>
                : 
                    <>
                        {  mappedModule && mappedModule.map(mapped => {
                            return (
                                modules && modules.map(module => {
                                    if(module.id === mapped.category) {
                                        return (
                                            <Page size="A4" orientation="landscape" style={styles.page2}>
                                                 <View style={styles.table}> 
                                                    <View style={styles.row}> 
                                                        <View style={[styles.colSubtitle2, styles.loginSubtitle1]}></View>

                                                        <View style={[styles.colSubtitle2, styles.loginSubtitle2]}></View>

                                                        <View style={[styles.colTitle2, styles.loginTitle3]}>
                                                            <Text style={styles.tableCell}>Topic</Text>
                                                        </View>

                                                        <View style={[styles.colTitle2, styles.loginTitle4]}>
                                                            <Text style={styles.tableCell}>Start Date</Text>
                                                        </View>

                                                        <View style={[styles.colTitle2, styles.loginTitle5]}>
                                                            <Text style={styles.tableCell}>End Date</Text>
                                                        </View>
                                                    </View>

                                                    <View style={styles.row}> 
                                                        <View style={[styles.colSubtitle2, styles.loginSubtitle1]}></View>

                                                        <View style={[styles.colSubtitle2, styles.loginSubtitle2]}></View>

                                                        <View style={[styles.colSubtitle2, styles.loginSubtitle3]}>
                                                            <Text style={styles.tableCell}>{module.moduleName}</Text>
                                                        </View>

                                                        <View style={[styles.colSubtitle2, styles.loginSubtitle4]}></View>

                                                        <View style={[styles.colSubtitle2, styles.loginSubtitle5]}></View>
                                                    </View>
                                                </View>

                                                <View style={styles.table}> 
                                                    <View style={styles.row}> 
                                                        <View style={[styles.colTitle2, styles.loginTitle1]}> 
                                                            <Text style={styles.tableCell}>No.</Text> 
                                                        </View> 

                                                        <View style={[styles.colTitle2, styles.loginTitle2]}> 
                                                            <Text style={styles.tableCell}>Name</Text> 
                                                        </View> 

                                                        <View style={[styles.colTitle2, styles.loginTitle3]}> 
                                                            <Text style={styles.tableCell}>Email</Text> 
                                                        </View>

                                                        <View style={[styles.colTitle2, styles.loginTitle4]}> 
                                                            <Text style={styles.tableCell}>Login</Text> 
                                                        </View> 

                                                        <View style={[styles.colTitle2, styles.loginTitle5]}> 
                                                            <Text style={styles.tableCell}>Logout</Text> 
                                                        </View> 
                                                    </View>

                                                    { mapped.items && mapped.items.map((item,i) => {
                                                        let no = i+1;
                                                        return (
                                                            <View key={i} style={styles.row}> 
                                                                <View style={[styles.colSubtitle2, styles.loginSubtitle1]}> 
                                                                    <Text style={styles.tableCell}>{no}.</Text> 
                                                                </View> 

                                                                <View style={[styles.colSubtitle2, styles.loginSubtitle2]}> 
                                                                    <Text style={styles.tableCell}>{item.name}</Text>
                                                                </View> 

                                                                <View style={[styles.colSubtitle2, styles.loginSubtitle3]}> 
                                                                    <Text style={styles.tableCell}>{item.email}</Text>
                                                                </View>

                                                                <View style={[styles.colSubtitle2, styles.loginSubtitle4]}> 
                                                                    <Text style={styles.tableCell}>{item.loginTimestamp}</Text>
                                                                </View>

                                                                <View style={[styles.colSubtitle2, styles.loginSubtitle5]}> 
                                                                    <Text style={styles.tableCell}>{item.logoutTimestamp}</Text>
                                                                </View>
                                                            </View>
                                                        )
                                                    })}
                                                </View>
                                                <View> 
                                                    <Image src={sign} style={styles.sign}></Image>
                                                </View>     
                                            </Page>
                                        )
                                    } return null
                                })
                            )
                        })}

                        
                        <Page size="A4" orientation="landscape" style={styles.page2}>
                            <View style={styles.table}> 
                                <View style={styles.row}> 
                                    <View style={[styles.colSubtitle2, styles.loginSubtitle1]}></View>

                                    <View style={[styles.colSubtitle2, styles.loginSubtitle2]}></View>

                                    <View style={[styles.colTitle2, styles.loginTitle3]}>
                                        <Text style={styles.tableCell}>Topic</Text>
                                    </View>

                                    <View style={[styles.colTitle2, styles.loginTitle4]}>
                                        <Text style={styles.tableCell}>Start Date</Text>
                                    </View>

                                    <View style={[styles.colTitle2, styles.loginTitle5]}>
                                        <Text style={styles.tableCell}>End Date</Text>
                                    </View>
                                </View>

                                <View style={styles.row}> 
                                    <View style={[styles.colSubtitle2, styles.loginSubtitle1]}></View>

                                    <View style={[styles.colSubtitle2, styles.loginSubtitle2]}></View>

                                    <View style={[styles.colSubtitle2, styles.loginSubtitle3]}>
                                        <Text style={styles.tableCell}>Examination</Text>
                                    </View>

                                    <View style={[styles.colSubtitle2, styles.loginSubtitle4]}></View>

                                    <View style={[styles.colSubtitle2, styles.loginSubtitle5]}></View>
                                </View>
                            </View>

                            <View style={styles.table}> 
                                <View style={styles.row}> 
                                    <View style={[styles.colTitle2, styles.loginTitle1]}> 
                                        <Text style={styles.tableCell}>No.</Text> 
                                    </View> 

                                    <View style={[styles.colTitle2, styles.loginTitle2]}> 
                                        <Text style={styles.tableCell}>Name</Text> 
                                    </View> 

                                    <View style={[styles.colTitle2, styles.loginTitle3]}> 
                                        <Text style={styles.tableCell}>Email</Text> 
                                    </View>

                                    <View style={[styles.colTitle2, styles.loginTitle4]}> 
                                        <Text style={styles.tableCell}>Login</Text> 
                                    </View> 

                                    <View style={[styles.colTitle2, styles.loginTitle5]}> 
                                        <Text style={styles.tableCell}>Logout</Text> 
                                    </View> 
                                </View>

                                {  examTimestamp.length > 0 && examTimestamp.map((exam,i) => {
                                    let no = i+1;
                                    return (
                                        <View key={i} style={styles.row}> 
                                            <View style={[styles.colSubtitle2, styles.loginSubtitle1]}> 
                                                <Text style={styles.tableCell}>{no}.</Text> 
                                            </View> 

                                            <View style={[styles.colSubtitle2, styles.loginSubtitle2]}> 
                                                <Text style={styles.tableCell}>{exam.name}</Text>
                                            </View> 

                                            <View style={[styles.colSubtitle2, styles.loginSubtitle3]}> 
                                                <Text style={styles.tableCell}>{exam.email}</Text>
                                            </View>

                                            <View style={[styles.colSubtitle2, styles.loginSubtitle4]}> 
                                                <Text style={styles.tableCell}>{exam.loginTimestamp}</Text>
                                            </View>

                                            <View style={[styles.colSubtitle2, styles.loginSubtitle5]}> 
                                                <Text style={styles.tableCell}>{exam.logoutTimestamp}</Text>
                                            </View>
                                        </View>
                                    )
                                })}
                            </View>
                            <View> 
                                <Image src={sign} style={styles.sign}></Image>
                            </View>  
                        </Page>
                    </>
                }
            </Document>
        );
    }