import React, { Component } from 'react';
import { connect } from 'react-redux'
import moment from 'moment'

import { deletePCP } from '../../../stores/actions/classAction';

import MoveToBatch from './batch/MoveToBatch';

class Requested extends Component {

    state = {
        changeState:'',
        
        name:'',
        email: '',
        phone: '',
        courseName:'',

        move_userId:'',
    }

    deletePcp = (pcpId) => {
        var result = window.confirm("Confirm delete?");

        if(result) {
            this.props.deletePCP(pcpId);
        }
    }

    showModal =  (userId) => {
        document.getElementById(`modal-choose-batch`).style.display = "block";

        this.setState({
            move_userId: userId
        })
    }

    hideModal =  () => {
        document.getElementById(`modal-choose-batch`).style.display = "none";
    }

    render(){

        const { physicalClassParticipants, physicalClassBatches, courseEnrollments, selectedCourseId } = this.props;

        let isLoadingReq = true;
        let requestedParticipant = [];
        
        let displayNotListed = physicalClassParticipants && physicalClassParticipants.map((part,i) => {
            if(part.courseId === selectedCourseId && part.status === "requested" && part.selectedBatchId === '') {
                isLoadingReq = false;
                requestedParticipant.push(part)
                let dateReq = moment(part.date).format('YYYY-MM-DD');

                return (
                    <tr key={i}>
                        <td>{dateReq}</td>
                        <td className="text-left">
                            {part.email} | {part.phone}<br/>
                            <small>{part.name}</small>
                        </td>
                        <td>
                            <span onClick={this.showModal.bind(this, part.userId)} className="badge bg-blue" title="Move to another batch"><i className="text-white fas fa-arrow-right"/></span>
                            <span onClick={this.deletePcp.bind(this, part.id)} title="Remove from practical session data" className="badge bg-red text-white"><i className="fas fa-trash"/></span>
                        </td>
                    </tr>
                )
            } else {
                return isLoadingReq = false;
            }
        })

        return(

            <div>

                <div id="modal-choose-batch" className="mymodal">
                    <div className="mymodal-content col-md-6">
                        <span className="close" onClick={this.hideModal.bind(this)}>&times;</span>
                        
                        <MoveToBatch hideModal={this.hideModal.bind()} physicalClassParticipants={physicalClassParticipants} physicalClassBatches={physicalClassBatches} courseEnrollments={courseEnrollments} userId={this.state.move_userId} selectedCourseId={selectedCourseId} />
                    </div>
                </div>

                { isLoadingReq === true ? 
                    <p>Please wait... Data loading...</p> 
                : 
                    requestedParticipant.length < 1 ?
                        <p>Currently no new request / not listed to class.</p>
                    :
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Req Date</th>
                                    <th scope="col">Detail</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>

                            <tbody className="bg-white">
                                {displayNotListed}
                            </tbody>
                        </table>
                }
            </div>
            
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
      deletePCP: (pcpId) => dispatch(deletePCP(pcpId)),
    }
}
  
export default connect(null, mapDispatchToProps)(Requested);