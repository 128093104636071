const initState = {
    subtopics: []
}

const courseReducer = (state = initState, action ) => {

    switch (action.type) {
        case 'ADDED_SUBTOPIC':
            return state;
        case 'ADDED_SUBTOPIC_ERROR':
            return state;

        case 'ADDED_TOPIC':
            return {
                ...state,
                addTopicSuccess: {
                    message: action.message,
                    type: 'addTopicSuccess',
                    id: action.id
                }
            }
        case 'ADDED_TOPIC_ERROR':
            return {
                ...state,
                addTopicError: {
                    message: action.err.message,
                    type: 'addTopicError'
                }
            }

        case 'ADDED_QUIZ':
            return {
                ...state,
                addQuizSuccess: {
                    message: action.message,
                    type: 'addQuizSuccess',
                    id: action.id
                }
            }
        case 'ADDED_QUIZ_ERROR':
            return {
                ...state,
                addQuizError: {
                    message: action.err.message,
                    type: 'addQuizError'
                }
            }

        case 'ADDED_MODULE':
            return {
                ...state,
                addModuleSuccess: {
                    message: action.message,
                    type: 'addModuleSuccess',
                    id: action.id
                }
            }
        case 'ADDED_MODULE_ERROR':
            return {
                ...state,
                addModuleError: {
                    message: action.err.message,
                    type: 'addModuleError'
                }
            }

        case 'ADDED_EXAM':
            return {
                ...state,
                addExamSuccess: {
                    message: action.message,
                    type: 'addExamSuccess',
                    id: action.id
                }
            }
        case 'ADDED_EXAM_ERROR':
            return {
                ...state,
                addExamError: {
                    message: action.err.message,
                    type: 'addExamError'
                }
            }

        case 'ADDED_COURSE':
            return {
                ...state,
                addCourseSuccess: {
                    message: action.message,
                    type: 'addCourseSuccess',
                    id: action.id
                }
            }
        case 'ADDED_COURSE_ERROR':
            return {
                ...state,
                addCourseError: {
                    message: action.err.message,
                    type: 'addCourseError'
                }
            }

        case 'ADDED_PROGRAM':
            return {
                ...state,
                addProgramSuccess: {
                    message: action.message,
                    type: 'addProgramSuccess',
                    id: action.id
                }
            }
        case 'ADDED_PROGRAM_ERROR':
            return {
                ...state,
                addProgramError: {
                    message: action.err.message,
                    type: 'addProgramError'
                }
            }

        case 'UPDATED_SUBTOPIC':
            return state;
        case 'UPDATED_SUBTOPIC_ERROR':
            return state;

        case 'UPDATED_TOPIC':
            return {
                ...state,
                updateTopicSuccess: {
                    message: action.message,
                    type: 'updateTopicSuccess'
                }
            }
        case 'UPDATED_TOPIC_ERROR':
            return {
                ...state,
                updateTopicError: {
                    message: action.err.message,
                    type: 'updateTopicError'
                }
            }

        case 'UPDATED_QUIZ':
            return {
                ...state,
                updateQuizSuccess: {
                    message: action.message,
                    type: 'updateQuizSuccess'
                }
            }
        case 'UPDATED_QUIZ_ERROR':
            return {
                ...state,
                updateQuizError: {
                    message: action.err.message,
                    type: 'updateQuizError'
                }
            }

        case 'UPDATED_MODULE':
            return {
                ...state,
                updateModuleSuccess: {
                    message: action.message,
                    type: 'updateModuleSuccess'
                }
            }
        case 'UPDATED_MODULE_ERROR':
            return {
                ...state,
                updateModuleError: {
                    message: action.err.message,
                    type: 'updateModuleError'
                }
            }

        case 'UPDATED_EXAM':
            return {
                ...state,
                updateExamSuccess: {
                    message: action.message,
                    type: 'updateExamSuccess'
                }
            }
        case 'UPDATED_EXAM_ERROR':
            return {
                ...state,
                updateExamError: {
                    message: action.err.message,
                    type: 'updateExamError'
                }
            }

        case 'UPDATED_COURSE':
            return {
                ...state,
                updateCourseSuccess: {
                    message: action.message,
                    type: 'updateCourseSuccess'
                }
            }
        case 'UPDATED_COURSE_ERROR':
            return {
                ...state,
                updateCourseError: {
                    message: action.err.message,
                    type: 'updateCourseError'
                }
            }

        case 'UPDATED_PROGRAM':
            return {
                ...state,
                updateProgramSuccess: {
                    message: action.message,
                    type: 'updateProgramSuccess'
                }
            }
        case 'UPDATED_PROGRAM_ERROR':
            return {
                ...state,
                updateProgramError: {
                    message: action.err.message,
                    type: 'updateProgramError'
                }
            }

        // case 'UPDATED_PROGRAM_FLEXI':
        //     console.log('program flexi updated', action.updatedProgram);
        //     return state;
        // case 'UPDATED_PROGRAM_FLEXI_ERROR':
        //     console.log('program flexi updated error', action.err)
        //     return state;

        case 'DELETED_SUBTOPIC':
            return state;
        case 'DELETED_SUBTOPIC_ERROR':
            return state;

        case 'DELETED_TOPIC':
            return {
                ...state,
                deleteTopicSuccess: {
                    message: action.message,
                    type: 'deleteTopicSuccess'
                }
            }
        case 'DELETED_TOPIC_ERROR':
            return {
                ...state,
                deleteTopicSuccess: {
                    message: action.err.message,
                    type: 'deleteTopicSuccess'
                }
            }

        case 'DELETED_QUIZ':
            return {
                ...state,
                deleteQuizSuccess: {
                    message: action.message,
                    type: 'deleteQuizSuccess'
                }
            }
        case 'DELETED_QUIZ_ERROR':
            return {
                ...state,
                deleteQuizError: {
                    message: action.message,
                    type: 'deleteQuizError'
                }
            }

        case 'DELETED_MODULE':
            return {
                ...state,
                deleteModuleSuccess: {
                    message: action.message,
                    type: 'deleteModuleSuccess'
                }
            }
        case 'DELETED_MODULE_ERROR':
            return {
                ...state,
                deleteModuleError: {
                    message: action.message,
                    type: 'deleteModuleError'
                }
            }

        case 'DELETED_EXAM':
            return {
                ...state,
                deleteExamSuccess: {
                    message: action.message,
                    type: 'deleteExamSuccess'
                }
            }
        case 'DELETED_EXAM_ERROR':
            return {
                ...state,
                deleteExamError: {
                    message: action.message,
                    type: 'deleteExamError'
                }
            }

        case 'DELETED_COURSE':
            return {
                ...state,
                deleteCourseSuccess: {
                    message: action.message,
                    type: 'deleteCourseSuccess'
                }
            }
        case 'DELETED_COURSE_ERROR':
            return {
                ...state,
                deleteCourseError: {
                    message: action.err.message,
                    type: 'deleteCourseError'
                }
            }

         case 'DELETED_PROGRAM':
            return {
                ...state,
                deleteProgramSuccess: {
                    message: action.message,
                    type: 'deleteProgramSuccess'
                }
            }
        case 'DELETED_PROGRAM_ERROR':
            return {
                ...state,
                deleteProgramError: {
                    message: action.err.message,
                    type: 'deleteProgramError'
                }
            }


        default:
            return state;
    }
}

export default courseReducer