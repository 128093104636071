const initState = {
    faq: []
}

const faqReducer = (state = initState, action ) => {

    switch (action.type) {
        case 'ADDED_FAQ':
            return {
                ...state,
                addFaqSuccess: {
                    message: action.message,
                    type: 'addFaqSuccess'
                }
            }
        case 'ADDED_FAQ_ERROR':
            return {
                ...state,
                addFaqError: {
                    message: action.err.message,
                    type: 'addFaqError'
                }
            }

        case 'UPDATED_FAQ':
            return {
                ...state,
                updateFaqSuccess: {
                    message: action.message,
                    type: 'updateFaqSuccess'
                }
            }
        case 'UPDATED_FAQ_ERROR':
            return {
                ...state,
                updateFaqError: {
                    message: action.err.message,
                    type: 'updateFaqError'
                }
            }

        case 'DELETED_FAQ':
            return {
                ...state,
                deleteFaqSuccess: {
                    message: action.message,
                    type: 'deleteFaqSuccess'
                }
            }
        case 'DELETED_FAQ_ERROR':
            return {
                ...state,
                deleteFaqError: {
                    message: action.err.message,
                    type: 'deleteFaqError'
                }
            }

        default:
            return state;
    }
}

export default faqReducer