
import formReducer from './formReducer'
import adReducer from './adReducer'
import courseReducer from './courseReducer'
import questionReducer from './questionReducer'
import infoReducer from './infoReducer'
import enrollmentReducer from './enrollmentReducer'
import userReducer from './userReducer'
import authReducer from './authReducer'
import progressReducer from './progressReducer'
import classReducer from './classReducer'
import chatReducer from './chatReducer'
import toyyibpayReducer from './toyyibpayReducer'
import faqReducer from './faqReducer'
import mapReducer from './mapReducer'

import eventsReducer from './eventsReducer'

import { combineReducers } from 'redux'
import { firestoreReducer } from 'redux-firestore'
import { firebaseReducer } from 'react-redux-firebase'

const rootReducer = combineReducers({
    auth: authReducer,
    ads: adReducer,
    classes: classReducer,
    faqs: faqReducer,
    chatMessage: chatReducer,
    users: userReducer,
    enrollments: enrollmentReducer,
    forms: formReducer,
    courses: courseReducer,
    infos: infoReducer,
    progress: progressReducer,
    questions: questionReducer,
    toyyibpay: toyyibpayReducer,
    maps: mapReducer,
    firestore: firestoreReducer, 
    firebase: firebaseReducer,
    events: eventsReducer
});

export default rootReducer

