import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";

import {
  updateEventsAttendees,
  deleteEventsAttendees,
} from "../../../stores/actions/eventsAction";

import MoveToBatch from "../PhysicalClass/batch/MoveToBatch";

class EventRequest extends Component {
  state = {
    changeState: "",

    name: "",
    email: "",
    phone: "",
    courseName: "",

    move_userId: "",
  };

  deleteEventsAttendees = (eventsAttendeesId) => {
    var result = window.confirm("Confirm delete?");

    if (result) {
      this.props.deleteEventsAttendees(eventsAttendeesId);
    }
  };

  approveEventAttendees = (eventsAttendeesId) => {
    var result = window.confirm("Confirm approve?");

    let data = { eventsAttendeesStatus: "Approved" };
    if (result) {
      this.props.updateEventsAttendees(data, eventsAttendeesId);
    }
  };

  rejectEventAttendees = (eventsAttendeesId) => {
    var result = window.confirm("Confirm reject?");

    let data = { eventsAttendeesStatus: "Rejected" };
    if (result) {
      this.props.updateEventsAttendees(data, eventsAttendeesId);
    }
  };

  showModal = (userId) => {
    document.getElementById(`modal-choose-batch`).style.display = "block";

    this.setState({
      move_userId: userId,
    });
  };

  hideModal = () => {
    document.getElementById(`modal-choose-batch`).style.display = "none";
  };

  testingParam = (ea, u, ev) => {
    const usersData = {}, eventsData = {};

    let eventsAttendeesData = [];//dump semua data dari eventAttendees
    if (ea)
    for (const doc of ea) {
        let eventAttendee = doc;
        if (eventAttendee.userId) {
          
          if (usersData[eventAttendee.userId]) {
            eventAttendee.user = usersData[eventAttendee.userId];
          } else {

            let filteredUser = u.filter((selected) => {
              return selected.id === eventAttendee.userId;
            });

            eventAttendee.user = filteredUser[0];

            usersData[eventAttendee.userId] = filteredUser[0];
          }
        } 
        
        if (eventAttendee.eventsId) {
          
            if (eventsData[eventAttendee.eventsId]) {
              eventAttendee.events = eventsData[eventAttendee.eventsId];
            } else {
  
              let filteredEvent = ev.filter((selected) => {
                return selected.id === eventAttendee.eventsId;
              });
  
              eventAttendee.events = filteredEvent[0];
  
              eventsData[eventAttendee.eventsId] = filteredEvent[0];
            }
          } 
        eventsAttendeesData.push(eventAttendee);
      }
    //   for (const doc of ea) {
    //     let eventAttendee = {
    //       id: doc.id,
    //       eventsId: doc.eventsId,
    //       eventsAttendeesStatus: doc.eventsAttendeesStatus,
    //       userId: doc.userId,
    //     };
    //     if (eventAttendee.userId) {
          
    //       if (usersData[eventAttendee.userId]) {
    //         eventAttendee.user = {
    //           id: usersData[eventAttendee.userId].id,
    //           firstname: usersData[eventAttendee.userId].firstname,
    //           email: usersData[eventAttendee.userId].email,
    //         };
    //       } else {

    //         let filteredUser = u.filter((selected) => {
    //           return selected.id === eventAttendee.userId;
    //         });

    //         eventAttendee.user = {
    //           id: filteredUser[0].id,
    //           firstname: filteredUser[0].firstname,
    //           email: filteredUser[0].email,
    //         };

    //         usersData[eventAttendee.userId] = {
    //           id: filteredUser[0].id,
    //           firstname: filteredUser[0].firstname,
    //           email: filteredUser[0].email,
    //         };
    //       }
    //     } 
        
    //     if (eventAttendee.eventsId) {
          
    //         if (eventsData[eventAttendee.eventsId]) {
    //           eventAttendee.events = {
    //             id: eventsData[eventAttendee.eventsId].id,
    //             eventsName: eventsData[eventAttendee.eventsId].eventsName,
    //           };
    //         } else {
  
    //           let filteredEvent = ev.filter((selected) => {
    //             return selected.id === eventAttendee.eventsId;
    //           });
  
    //           eventAttendee.events = {
    //             id: filteredEvent[0].id,
    //             eventsName: filteredEvent[0].eventsName,
    //           };
  
    //           eventsData[eventAttendee.eventsId] = {
    //             id: filteredEvent[0].id,
    //             eventsName: filteredEvent[0].eventsName,
    //           };
    //         }
    //       } 
    //     eventsAttendeesData.push(eventAttendee);
    //   }

    return eventsAttendeesData;
  };

  render() {
    const { users, selectedEventId, events, eventsAttendees } = this.props; //from AdminManager.jsx
    let isLoadingReq = true;
    let requestedAttendees = [];
    let no = 0;

    let testParam = this.testingParam(eventsAttendees, users, events);
    let displayNotListed =
      testParam &&
      testParam.map((data, i) => {
        if (
          data.eventsId === selectedEventId &&
          data.eventsAttendeesStatus === "Requested"
        ) {
          isLoadingReq = false;
          requestedAttendees.push(data);
          no = no + 1;
        return(
          <tr key={i}>
            <td>{no}</td>
            <td className="text-left">
              {data.user.firstname} {data.user.lastname} | {data.user.contactNo}
              <br />
              <small>{data.user.email}</small>
            </td>
            <td>
              <span
                onClick={this.approveEventAttendees.bind(this, data.id)}
                className="badge bg-blue"
                title="Approve e-cert request"
              >
                <i className="text-white fas fa-check-square" />
              </span>
              <span
                onClick={this.rejectEventAttendees.bind(this, data.id)}
                title="Decline e-cert request"
                className="badge bg-red text-white"
              >
                <i className="fas fa-times" />
              </span>
              <span
                onClick={this.deleteEventsAttendees.bind(this, data.id)}
                title="Remove from e-cert request"
                className="badge bg-red text-white"
              >
                <i className="fas fa-trash" />
              </span>
            </td>
          </tr>
          )
        } else {
          return (isLoadingReq = false);
        }
      });

    // let displayNotListed = eventsAttendees && eventsAttendees.map((data,i) => {
    //     if(data.eventsId === selectedEventId && data.eventsAttendeesStatus === "Requested") {
    //         isLoadingReq = false;
    //         requestedAttendees.push(data);
    //         return(
    //                 users && users.map((userData,j) =>{

    //                     if(userData.userId === data.userId){

    //                         return (
    //                             <tr key={i}>

    //                             <td key={j} className="text-left">
    //                                 {userData.firstname} | {userData.email}<br/>
    //                                 <small>{userData.firstname}</small>
    //                             </td>
    //                             <td>
    //                                 <span onClick={this.approveEventAttendees.bind(this, data.id)} className="badge bg-blue" title="Approve e-cert request"><i className="text-white fas fa-check-square"/></span>
    //                                 <span onClick={this.rejectEventAttendees.bind(this, data.id)} title="Decline e-cert request" className="badge bg-red text-white"><i className="fas fa-times"/></span>
    //                                 <span onClick={this.deleteEventsAttendees.bind(this, data.id)} title="Remove from practical session data" className="badge bg-red text-white"><i className="fas fa-trash"/></span>
    //                             </td>
    //                             </tr>
    //                         )
    //                     }
    //                 })

    //         )
    //     } else {
    //         return isLoadingReq = false;
    //     }
    // })

    return (
      <div>
        {/* <div id="modal-choose-batch" className="mymodal">
                    <div className="mymodal-content col-md-6">
                        <span className="close" onClick={this.hideModal.bind(this)}>&times;</span>
                        
                        <MoveToBatch hideModal={this.hideModal.bind()} physicalClassParticipants={physicalClassParticipants} physicalClassBatches={physicalClassBatches} courseEnrollments={courseEnrollments} userId={this.state.move_userId} selectedCourseId={selectedCourseId} />
                    </div>
                </div> */}

        {isLoadingReq === true ? (
          <p>Please wait... Data loading...</p>
        ) : requestedAttendees.length < 1 ? (
          <p>Currently no new request / not listed to class.</p>
        ) : (
          <table className="table">
            <thead>
              <tr>
                <th scope="col">No</th>
                <th scope="col">Detail</th>
                <th scope="col">Action</th>
              </tr>
            </thead>

            <tbody className="bg-white">{displayNotListed}</tbody>
          </table>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    deleteEventsAttendees: (eventsAttendeesId) =>
      dispatch(deleteEventsAttendees(eventsAttendeesId)),
    updateEventsAttendees: (data, eventsAttendeesId) =>
      dispatch(updateEventsAttendees(data, eventsAttendeesId)),
  };
};

export default connect(null, mapDispatchToProps)(EventRequest);
