import React, { Component } from 'react';
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import moment from 'moment'
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom'
// import Carousel, { consts } from 'react-elastic-carousel';

import { updateEnrolledQuiz_renewSuspend } from '../../stores/actions/enrollmentAction';

class EnrollmentModule extends Component {

    // myArrow = ({ type, onClick, isEdge }) => {
    //     const pointer = type === consts.PREV ? <i class="fas fa-arrow-circle-left"></i> : <i class="fas fa-arrow-circle-right"></i>
    //     return (
    //         <span className="middle carousel-arrow"onClick={onClick} disabled={isEdge}>
    //             {pointer}
    //         </span>
    //     )
    // }

    render(){

        const { auth, course_id, modules, courseEnrollments, moduleEnrollments, quizEnrollments, programs, course } = this.props;

        let isLoading = true;
        
        if (!auth.uid) return <Redirect to="/" />

        // to check if course is expired = to disable knowledge assessment & enable learning page
        let isExpired = null;
        let enrollExpiredSame = null, enrollExpiredAfter = null;

        courseEnrollments && courseEnrollments.map((enrolled,i) => {

            if (course_id === enrolled.courseId && enrolled.userId === auth.uid) {
                let enrollExpired = enrolled.enrollExpired;

                enrollExpiredSame = moment(new Date()).isSame(new Date(enrollExpired));
                enrollExpiredAfter = moment(new Date()).isAfter(new Date(enrollExpired));
            } return null
        })

        if (enrollExpiredSame === true || enrollExpiredAfter === true) {
            isExpired = true;
        }
        // -----------------------------------------------------------------------------------

        let enrolledModule = moduleEnrollments && moduleEnrollments.map((enrolled,i) => {

            return (
                modules && modules.map((module,i) => {
                    if(course_id === enrolled.courseId && auth.uid === enrolled.userId && module.id === enrolled.moduleId) {
                        
                        let fileUrl = module.practicalAssessment.fileUrl;
                        let enrolledTopicsId = [];

                        enrolled.topics && enrolled.topics.map(topic => {
                            enrolledTopicsId.push(topic.id)
                            return null
                        })

                        let topicFirst = enrolledTopicsId[0];

                        return (
                            quizEnrollments && quizEnrollments.map(quiz => {

                                let quizStatus = "";

                                let suspendExpired = quiz.quizAvailableDate;
                                
                                let suspendExpiredSame = moment(new Date()).isSame(new Date(suspendExpired));
                                let suspendExpiredAfter = moment(new Date()).isAfter(new Date(suspendExpired));

                                if(quiz.quizAvailableDate !== "") {
                                    if (suspendExpiredSame === true || suspendExpiredAfter === true) { //lepas
                                        this.props.updateEnrolledQuiz_renewSuspend(quiz.id)
                                    }
                                }

                                if(course_id === quiz.courseId && auth.uid === quiz.userId && quiz.moduleId === enrolled.moduleId) {
                                    isLoading = false;

                                    if(module.quiz.hasQuiz === true || module.quiz.hasQuiz === "true") {
                                        if(quiz.quizScore >= module.quiz.quizMark) {
                                            quizStatus = "passed"
                                        } else {
                                            quizStatus = "failed"
                                        }
                                    }
                                    return (
                                        <div key={i} className=" mb-5 card border-0 col-md-6">
                                            <div className="module">
                                                <div className="enroll-title">
                                                    <b>MODULE {' '} {parseInt(enrolled.indexKey) + 1}</b>
                                                    <p>{module.moduleName}</p>
                                                </div>
                                                { enrolled.isAvailable === true && enrolled.isDone === false ? 
                                                     course.courseName.toLowerCase().includes('halal insightx') && quiz.quizAvailableDate === "" || suspendExpiredSame === true || suspendExpiredAfter === true   ?
                                                        <>
                                                            <div  className="enroll-module">
                                                                <label><i className="fas fa-info-circle"></i>{'  '}Available</label><br/>
                                                                { (module.quiz.hasQuiz === true || module.quiz.hasQuiz === "true")  && (course.removeQuizBtn === "false" || course.removeQuizBtn === false) ?
                                                                    <label><i className="fas fa-info-circle"></i>{'  '}Knowledge assessment score: <span className={quizStatus === "passed" ? "text-green" : "text-red"}>{quiz.quizScore}%</span></label>
                                                                : <label></label> }
                                                            </div>
                                                            
                                                            <div className="module-btn" >
                                                                { module.practicalAssessment.hasPracticalAssessment === "true" ?
                                                                    <a className="btn all-btn" href={fileUrl} target="_blank" rel="noopener noreferrer" download>
                                                                        <i className="fas fa-download"/>{' '}Assessment
                                                                    </a>
                                                                : null }

                                                                { isExpired === true ?
                                                                    <button className="btn btn-disable">Knowledge Assessment</button>
                                                                :
                                                                    (module.quiz.hasQuiz === true || module.quiz.hasQuiz === "true")  && (course.removeQuizBtn === "false" || course.removeQuizBtn === false) ?
                                                                        <Link to={"/enrollment/quiz/" + this.props.course_id + "/" + enrolled.indexKey + "/" + enrolled.moduleId + "/" + quiz.id}>
                                                                            <button className="btn all-btn">Knowledge Assessment</button>
                                                                        </Link>
                                                                    : null
                                                                }   

                                                                {/* { (module.quiz.hasQuiz === true || module.quiz.hasQuiz === "true")  && (course.removeQuizBtn === "false" || course.removeQuizBtn === false) ?
                                                                    <Link to={"/enrollment/quiz/" + this.props.course_id + "/" + enrolled.indexKey + "/" + enrolled.moduleId + "/" + quiz.id}>
                                                                        <button className="btn all-btn">Knowledge Assessment</button>
                                                                    </Link>
                                                                : null } */}
                                                                <Link to={`/enrollment/resource?courseId=${this.props.course_id}&moduleIndex=${enrolled.indexKey}&moduleId=${enrolled.moduleId}&topicId=${topicFirst}&topicIndex=0`}>
                                                                    <button className="btn all-btn">Start {' '}<i className="fas fa-arrow-right"></i></button>
                                                                </Link>
                                                            </div>
                                                        </>
                                                    :quiz.quizAvailableDate === "" || suspendExpiredSame === true || suspendExpiredAfter === true   ?
                                                    <>{console.log('goes here')}
                                                        <div  className="enroll-module">
                                                            <label><i className="fas fa-info-circle"></i>{'  '}Available</label><br/>
                                                            { (module.quiz.hasQuiz === true || module.quiz.hasQuiz === "true")  && (course.removeQuizBtn === "false" || course.removeQuizBtn === false) ?
                                                                <label><i className="fas fa-info-circle"></i>{'  '}Knowledge assessment score: <span className={quizStatus === "passed" ? "text-green" : "text-red"}>{quiz.quizScore}%</span></label>
                                                            : <label></label> }
                                                        </div>
                                                        
                                                        <div className="module-btn" >
                                                            { module.practicalAssessment.hasPracticalAssessment === "true" ?
                                                                <a className="btn all-btn" href={fileUrl} target="_blank" rel="noopener noreferrer" download>
                                                                    <i className="fas fa-download"/>{' '}Assessment
                                                                </a>
                                                            : null }
    
                                                            { isExpired === true ?
                                                                <button className="btn btn-disable">Knowledge Assessment</button>
                                                            :
                                                                (module.quiz.hasQuiz === true || module.quiz.hasQuiz === "true")  && (course.removeQuizBtn === "false" || course.removeQuizBtn === false) ?
                                                                    <Link to={"/enrollment/quiz/" + this.props.course_id + "/" + enrolled.indexKey + "/" + enrolled.moduleId + "/" + quiz.id}>
                                                                        <button className="btn all-btn">Knowledge Assessment</button>
                                                                    </Link>
                                                                : null
                                                            }   
    
                                                            {/* { (module.quiz.hasQuiz === true || module.quiz.hasQuiz === "true")  && (course.removeQuizBtn === "false" || course.removeQuizBtn === false) ?
                                                                <Link to={"/enrollment/quiz/" + this.props.course_id + "/" + enrolled.indexKey + "/" + enrolled.moduleId + "/" + quiz.id}>
                                                                    <button className="btn all-btn">Knowledge Assessment</button>
                                                                </Link>
                                                            : null } */}
                                                            <Link to={`/enrollment/learning?courseId=${this.props.course_id}&moduleIndex=${enrolled.indexKey}&moduleId=${enrolled.moduleId}&topicId=${topicFirst}&topicIndex=0`}>
                                                                <button className="btn all-btn">Start {' '}<i className="fas fa-arrow-right"></i></button>
                                                            </Link>
                                                        </div>
                                                    </>
                                                    :<> 
                                                    <div  className="enroll-module">
                                                        <label className="text-red"><i className="fas fa-info-circle "></i>{'  '}Oops! You have lost 3 chances. Knowledge assessment will be available again on {quiz.quizAvailableDate}</label><br/>
                                                        { module.quiz.hasQuiz === true || module.quiz.hasQuiz === "true" ?
                                                            <label><i className="fas fa-info-circle"></i>{'  '}Knowledge assessment Score: <span className={quizStatus === "passed" ? "text-green" : "text-red"}>{quiz.quizScore}%</span></label>
                                                        : <label></label> }
                                                    </div>
                                                    <div className="module-btn">
                                                        { module.practicalAssessment.hasPracticalAssessment === "true" ?
                                                            <a className="btn all-btn" href={fileUrl} target="_blank" rel="noopener noreferrer" download>
                                                                <i className="fas fa-download"/>{' '}Assessment
                                                            </a>
                                                        : null }  
                                                        { module.quiz.hasQuiz === "true" && course.removeQuizBtn === "false" ?
                                                            <button className="btn btn-disable">Knowledge Assessment</button>
                                                        : null }  
                                                        <Link to={`/enrollment/learning?courseId=${this.props.course_id}&moduleIndex=${enrolled.indexKey}&moduleId=${enrolled.moduleId}&topicId=${topicFirst}&topicIndex=0`}>
                                                            <button className="btn all-btn">Start {' '}<i className="fas fa-arrow-right"></i></button>
                                                        </Link>
                                                    </div>
                                                    </>
                                                : enrolled.isAvailable === false && enrolled.isDone === false ?
                                                    <>
                                                        <div className="enroll-module">
                                                            <label className="text-grey"><i className="fas fa-lock"></i>{'  '}Locked</label><br/>
                                                            <label></label>
                                                        </div>
                                                        <div className="module-btn">
                                                            { module.practicalAssessment.hasPracticalAssessment === "true" ?
                                                                <button className="btn btn-disable"><i className="fas fa-download"/>{' '}Assessment</button>
                                                            : null }  
                                                            { module.quiz.hasQuiz === "true" && course.removeQuizBtn === "false" ?
                                                                <button className="btn btn-disable">Knowledge Assessment</button>
                                                            : null }
                                                            <button className="btn btn-disable">Start {' '}<i className="fas fa-arrow-right"></i></button>
                                                        </div>
                                                    </>  
                                                : enrolled.isAvailable === true && enrolled.isDone === true ?
                                                    <>
                                                        <div  className="enroll-module">
                                                            <label className="text-green"><i className="fas fa-check-circle"></i>{'  '}Completed</label><br/>
                                                            { module.quiz.hasQuiz === true || module.quiz.hasQuiz === "true" ?
                                                                <label><i className="fas fa-info-circle"></i>{'  '}Knowledge assessment score: <span className={quizStatus === "passed" ? "text-green" : "text-red"}>{quiz.quizScore}%</span></label>
                                                            : <label></label> }
                                                        </div>

                                                        <div className="module-btn">
                                                            { module.practicalAssessment.hasPracticalAssessment === "true" ?
                                                                <a className="btn all-btn" href={fileUrl} target="_blank" rel="noopener noreferrer" download>
                                                                    <i className="fas fa-download"/>{' '}Assessment
                                                                </a>
                                                            : null }
                                                            { module.quiz.hasQuiz === "true" && course.removeQuizBtn === "false" ?
                                                                <button className="btn btn-disable">Knowledge Assessment</button>
                                                            : null }
                                                            <Link to={`/enrollment/learning?courseId=${this.props.course_id}&moduleIndex=${enrolled.indexKey}&moduleId=${enrolled.moduleId}&topicId=${topicFirst}&topicIndex=0`}>
                                                                <button className="btn all-btn">Start {' '}<i className="fas fa-arrow-right"></i></button>
                                                            </Link>
                                                        </div>
                                                    </>  
                                                :''}
                                            </div>
                                        </div>
                                    )
                                } else {
                                    isLoading = false;
                                }
                                return null
                            })
                        )
                    }
                    return null
                })
            )
        })

        let programName, courseName;

        programs && programs.map(program => {
            return (
                program.selectedCourses && program.selectedCourses.map(cs => {
                    if(cs === course_id) {
                        programName = program.programName
                    }
                    return null
                })
            )
        })

        if(course) {
            courseName = course.courseName
        }

        return(

        <div className="learning min-height">

            <div className="page-header middle">
                <b>Modules List </b>
                <p>{programName} - {courseName}</p>
                <div className="title-border"></div>
            </div>

            <div className="page-content">

                { isLoading === true ?
                        <div className="middle loader"></div>
                : null }

                <div className='middle'>
                    <div className="row col-md-10">
                        { enrolledModule }
                    </div>
                </div>

                {/* <div className="course row col-md-12 justify-content-center">
                    <div className="card bg-transparent border-0 col-md-7">
                        { enrolledModule ? enrolledModule : null }
                    </div>
                    
                    <div style={{marginLeft:"20px", marginRight:"20px"}} className="ard bg-transparent border-0 col carousel-enroll">
                        <Carousel itemsToShow={1} renderArrow={this.myArrow}>
                            { ads && ads.map((ad,i) => {
                                return (
                                    <img key={i} className="middle" width="100%" height="100%" src={ad.fileUrl} />
                                )
                            })}
                        </Carousel>
                    </div>
                </div>  */}
                
            </div>
        </div>
               
        )
    }
}

const mapStateToProps = (state, ownProps) => {

    const id = ownProps.match.params.course_id;

    const courses = state.firestore.data.courses;
    const course = courses ? courses[id] : null

    return {
        profile: state.firebase.profile,
        auth: state.firebase.auth,

        course_id: id,
        course:course,

        modules: state.firestore.ordered.modules,
        courseEnrollments: state.firestore.ordered.courseEnrollments,
        moduleEnrollments: state.firestore.ordered.moduleEnrollments,
        quizEnrollments: state.firestore.ordered.quizEnrollments,
        programs: state.firestore.ordered.programs,
        courses: state.firestore.ordered.courses,
        ads: state.firestore.ordered.ads,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
      updateEnrolledQuiz_renewSuspend: (enrolledQuizId) => dispatch(updateEnrolledQuiz_renewSuspend(enrolledQuizId)),
    }
}
  
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect(props => [
        { collection: 'modules'},
        { collection: 'programs'},
        { collection: 'courses'},
        { collection: 'ads'},
        { collection: 'courseEnrollments', where: props.auth.uid ? ['userId', '==', props.auth.uid] : null },
        { collection: 'moduleEnrollments', where: props.auth.uid ? ['userId', '==', props.auth.uid] : null , orderBy: ['indexKey','asc'] },
        { collection: 'quizEnrollments', where: props.auth.uid ? ['userId', '==', props.auth.uid] : null  },
      ])
)(EnrollmentModule);