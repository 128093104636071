import React, { Component } from 'react';
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { Redirect } from 'react-router-dom'
import { compose } from 'redux'
import moment from 'moment'
import ReactCountdownClock from 'react-countdown-clock';

import { module_finishDate, updateEnrolledModule_isAvailable, updateEnrolledModule_isDone, updateEnrolledExam_isAvailable, updateEnrolledQuiz_quizFailCounter, updateEnrolledQuiz_suspend, updateEnrolledQuiz_quizScore, updateEnrolledQuiz_quizAttend } from '../../stores/actions/enrollmentAction';

class Quiz extends Component {

    state = {

        startBtnCounter: null,
        showStartBtn: false,

        hideMarks: true,
        hideAfterSubmit: false,

        wrongQuestions: [],
        
        quizStatus:'',

        enrollmentType:'',

        questionCount:0,
        nextQuestionIndex:0,
        currentQuestionId:'',
        nextQuestionId:'',
        questionIds:[],
        questions:[],
        idQuestionIndex0: '',

        answerStatus:'',
        tempAnswerStatus:'', //to validate user pick jwpn bfr next
        marks:null,

        enrolledModuleIds:'',
        enrolledCurrentModuleId: '',
        enrolledExamId: '',
        moduleLength:0,

        quizSuspendNumber: 0,
        quizSuspendDay: 0,
        quizTimer: 0,
        quizMark: 0,
        updateQuizFailCounter: 0,

        quizFailCounter: 0,
        quizSuspendCounter: 0,
        quizScore: 0,

        isPaused: true,
        hideBtnStart: false,
        hideTimer: true,

        totalMark: 0,
        totalTrueMark: 0
    }

    componentDidMount = () => {
        this.loadStartBtn(10);
    }

    loadStartBtn = (seconds) => {
        
        let counter = seconds;

        const interval = setInterval(async () => {
            counter--;
            this.setState({ startBtnCounter: counter })
            if (counter === 0 ) {
                this.setState({ showStartBtn: true })
                clearInterval(interval);
            }
        }, 1000);
    }

    hideModal = () => {

        let module_index = this.props.module_index;

        document.getElementById("modal-submit").style.display = "none";
        
        let quizFailCounter = this.state.updateQuizFailCounter; //new counter
        let quizSuspendNumber = this.state.quizSuspendNumber; //yg dag define dlm settiing Course
        let quizSuspendDay = this.state.quizSuspendDay; //yg dag define dlm settiing Course

        let quizSuspendDate = moment().format('YYYY-MM-DD');
        let suspendDayCount = moment(quizSuspendDate).add(quizSuspendDay, 'days');
        const quizAvailableDate = moment(suspendDayCount).format('YYYY-MM-DD');

        let quizSuspendCount =  this.state.quizSuspendCounter + 1;

        if (this.state.moduleLength === module_index && this.state.quizStatus === 'passed') { //kalay last moudle lulus

            this.props.history.push('/enrollment')

        } else if (this.state.moduleLength !== module_index && this.state.quizStatus === 'passed'){ //bukan last module yg lulus

            this.props.history.push('/enrollment/module/' + this.props.course_id)

        } else if (this.state.quizStatus === 'failed' && quizFailCounter === quizSuspendNumber) { //failed and suspend

            this.props.updateEnrolledQuiz_suspend(this.props.enrolledQuiz_id, quizAvailableDate, quizSuspendCount); //suspend
            this.props.history.push('/enrollment/module/' + this.props.course_id)

        } else if (this.state.quizStatus === 'failed' && !(quizFailCounter === quizSuspendNumber)){ //failed but not suspend
            
            this.props.history.push('/enrollment/module/' + this.props.course_id)

        }
    }

    selectAnswer = (index, answerStatus) => { //try delete index, tapi nanti dia baca second question answerStatus value as index, bukan true/false
        this.setState({
            answerStatus: answerStatus,
            tempAnswerStatus: answerStatus
        })
    }

    setQuizData = async () => {
        const { enrolledQuiz } = this.props;

        if(enrolledQuiz) {
            this.setState({
                quizFailCounter: enrolledQuiz.quizFailCounter,
                quizSuspendCounter: enrolledQuiz.quizSuspendCounter,
                quizScore: enrolledQuiz.quizScore,
            })
        }
    }

    startQuestion = async () => {

        const { auth, module_id, course_id, courses, quizzes, questions, modules, courseEnrollments, moduleEnrollments, examEnrollments } = this.props;

        await this.setQuizData();
        
        //supposed defined dlm componentDidMoount, tapi dia tak load all props (sbb CDM load after render), tak sempat nak render all props, dia dah baca CDM :(
        let enrolledExamId = '', enrollmentType = '',
        quests = [], questionCount = 0, 
        enrolledModuleIds = [], enrolledCurrentModuleId = '', moduleLength=0,
        quizSuspendNumber = 0, quizSuspendDay = 0, quizTimer=0, quizMark=0;

        moduleEnrollments && moduleEnrollments.map(enrolled => {
            if (auth.uid === enrolled.userId && enrolled.courseId === course_id) { //verify same with user id
                enrolledModuleIds.push(enrolled.id); //push into array
                if (enrolled.moduleId === this.props.module_id) {
                    enrolledCurrentModuleId = enrolled.id; //push into array
                    modules && modules.map(module => {
                        if (module.id === module_id) {
                            quizSuspendDay = module.quiz.quizSuspendDay;
                            quizSuspendNumber = module.quiz.quizSuspendNumber;
                            quizTimer = module.quiz.quizTimer;
                            quizMark = module.quiz.quizMark;
                            return (
                                quizzes && quizzes.map(quiz => {
                                    if (module.quiz.selectedQuizSet === quiz.id) {
                                        questionCount = module.quiz.questionCount;
                                        quiz.selectedQuestions && quiz.selectedQuestions.map(selectedQuestion => {
                                            questions && questions.map(question => {
                                                if (question.id === selectedQuestion) {
                                                    quests.push(question);
                                                }
                                                return null
                                            })
                                            return null
                                        })
                                    }
                                    return null
                                })
                            )
                        }
                        return null
                    })
                }
                return null
            }
            return null
        })

        courseEnrollments && courseEnrollments.map(enrolled => {
           if (auth.uid === enrolled.userId && enrolled.courseId === course_id) {
                enrollmentType = enrolled.type;
            } 
            return null
        })

        moduleEnrollments && moduleEnrollments.map(enrolled => {
            courses && courses.map(course => {
                if (auth.uid === enrolled.userId && enrolled.courseId ===  course.id && enrolled.courseId === course_id) {
                    moduleLength = course.selectedModules.length;
                } 
                return null
            })
            return null
        })

        examEnrollments && examEnrollments.map(enrolled => {
            if (auth.uid === enrolled.userId && enrolled.courseId === course_id) {
                enrolledExamId = enrolled.id //amik exam id
            } 
            return null
        })
        
        //shuffle soalan---------------------------------------------------------
        
        let shuffledQuestions = [];
        let shuffledIds = [];
        let totalMark = 0;
        
        const arr = this.generateShuffledArray(quests.length);
        let randomIndex = arr.splice(0, questionCount);

        for(let x=0; x<questionCount; x++){
            if(quests[randomIndex[x]] === undefined){
                  continue;
            } else {
                shuffledQuestions.push(quests[randomIndex[x]]);
            }
        }

        shuffledQuestions && shuffledQuestions.map(shuffled => { //get ids for shuffled questions
            shuffledIds.push(shuffled.id);
            totalMark = totalMark + shuffled.questionMark //get TOTAL MARK
            return null
        })

        let idQuestionIndex0 = shuffledIds[0];

        let attended = {
            quizAttend: true,
            quizFailCounter:  this.state.quizFailCounter + 1
        }

        //check if valid to be suspend---------------------------------------------------------
        let quizSuspendDate = moment().format('YYYY-MM-DD');
        let suspendDayCount = moment(quizSuspendDate).add(quizSuspendDay, 'days');
        const quizAvailableDate = moment(suspendDayCount).format('YYYY-MM-DD');

        let quizSuspendCount =  this.state.quizSuspendCounter + 1;

        if (attended.quizFailCounter === quizSuspendNumber) { //suspend
            this.props.updateEnrolledQuiz_suspend(this.props.enrolledQuiz_id, quizAvailableDate, quizSuspendCount);
        }

        this.props.updateEnrolledQuiz_quizAttend(attended, this.props.enrolledQuiz_id) //update attended and failCounter+1
        
        this.setState({
            idQuestionIndex0: idQuestionIndex0,
            isPaused: false,
            hideBtnStart: true,
            hideTimer: false,
            updateQuizFailCounter: attended.quizFailCounter,
            
            enrolledExamId: enrolledExamId,
            questions: shuffledQuestions,
            questionIds: shuffledIds,
            questionCount: questionCount,
            enrolledModuleIds: enrolledModuleIds,
            enrolledCurrentModuleId: enrolledCurrentModuleId,
            quizSuspendDay: quizSuspendDay,
            quizSuspendNumber: quizSuspendNumber,
            quizTimer: quizTimer,
            quizMark: quizMark,
            moduleLength: parseInt(moduleLength) - 1,
            enrollmentType: enrollmentType,

            totalMark
        })
    }

    generateShuffledArray(n){
        return this.shuffleArray(this.generateArray(n))
    }

    shuffleArray(arr){
        let temp = null;
        const arrLength = arr.length
        for (let i = 0; i < arrLength - 1; i++){
            let j = this.getRandomInt(i, arrLength)
            temp = arr[i]
            arr[i] = arr[j]
            arr[j] = temp
        }
        return arr
    }

    getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min)) + min;
    }

    generateArray(n) {
        let arr = []
        for (let i = 0; i < n; i++){
            arr.push(i)
        }
        return arr
    }

    nextQuestion = (index, questionId, questionMark) => {

        let nextQuestionIndex =  index+1;
        let questionIds = this.state.questionIds;
        let nextQuestionId = questionIds[nextQuestionIndex];

        let answerStatus = this.state.answerStatus;
        let tempAnswerStatus = this.state.tempAnswerStatus;

        if (tempAnswerStatus === "" || null) {
            alert("choose answer!")
        } else {
            if(answerStatus === "true" || answerStatus === true) {
                this.setState({
                    totalTrueMark: this.state.totalTrueMark + questionMark
                })
            } else {
                this.setState({
                    wrongQuestions: [
                        questionId, 
                        ...this.state.wrongQuestions
                    ],
                })
            }

            this.setState({
                tempAnswerStatus: "", //utk validate user pick jawapan
                currentQuestionId: questionId,
                nextQuestionId: nextQuestionId,
                nextQuestionIndex: nextQuestionIndex,
            })
        }
    }

    finishQuestion = (questionId, questionMark, timeout) => {
        let answerStatus = this.state.answerStatus;
        let tempAnswerStatus = this.state.tempAnswerStatus;

        if (timeout === true) {
            document.getElementById("title-modal").innerHTML = "Time Out!";
            document.getElementById("modal-submit").style.display = "block";
        } else {
            if (tempAnswerStatus === "" || null) {
                alert("choose answer!")
            } else {
                if(answerStatus === "true" || answerStatus === true) {
                    this.setState({
                        totalTrueMark: this.state.totalTrueMark + questionMark
                    })
                } else {
                    this.setState({
                        wrongQuestions: [
                            questionId, 
                            ...this.state.wrongQuestions
                        ],
                    })
                }
                this.setState({
                    tempAnswerStatus: "", //utk validate user pick jawapan
                    isPaused: true,
                })
                document.getElementById("modal-submit").style.display = "block";
            }
        }
    }

    submit = () => {

        let updateQuizFailCounter = 0;
        let quizStatus = '';
        let marks = Math.round((this.state.totalTrueMark/this.state.totalMark)*100);

        if (marks >= this.state.quizMark) { //lulus
            let moduleIds = this.state.enrolledModuleIds;
            let module_index = this.props.module_index;
    
            let enrolledNextModuleIndex = module_index + 1;
            let enrolledNextModuleId = moduleIds[enrolledNextModuleIndex];

            let deleteFailed = {
                quizAttend: true,
                quizFailCounter: this.state.updateQuizFailCounter - 1
            }

            if (this.state.updateQuizFailCounter === this.state.quizSuspendNumber) {
                this.props.updateEnrolledQuiz_suspend(this.props.enrolledQuiz_id, "", this.state.quizSuspendCounter); //-1 suspendCounter @ startBtn
                this.props.updateEnrolledQuiz_quizAttend(deleteFailed, this.props.enrolledQuiz_id) //-1 failCounter @ startBtn
            }

            quizStatus = 'passed';

            // if (this.state.enrollmentType === "Professional") {
                if (this.state.moduleLength === module_index) { 
                    this.props.updateEnrolledModule_isDone(this.state.enrolledCurrentModuleId); //isDone === true
                    this.props.updateEnrolledExam_isAvailable(this.state.enrolledExamId); //enable exam isAvailable === true
                } else {
                    this.props.updateEnrolledModule_isAvailable(enrolledNextModuleId); //enable next module isAvailable === true
                    this.props.updateEnrolledModule_isDone(this.state.enrolledCurrentModuleId); //isDone === true
                }
            // } else if (this.state.enrollmentType === "Flexi") {
            //     if (this.state.moduleLength === module_index) { 
            //         this.props.updateEnrolledModule_isDone(this.state.enrolledCurrentModuleId); //isDone === true
            //         this.props.updateEnrolledExam_isAvailable(this.state.enrolledExamId); //enable exam isAvailable === true
            //     } else {
            //         this.props.updateEnrolledModule_isDone(this.state.enrolledCurrentModuleId); //isDone === true
            //     }
            // }

            this.props.module_finishDate(this.state.enrolledCurrentModuleId);
            
        } else {
            quizStatus = 'failed';

            updateQuizFailCounter = this.state.updateQuizFailCounter - 1 + 1; //-1 from added at START tadi, and +1 sbb fail
            this.props.updateEnrolledQuiz_quizFailCounter(this.props.enrolledQuiz_id, updateQuizFailCounter); //update fail counter

             //check if valid to be suspend
            let quizSuspendNumber = this.state.quizSuspendNumber; //yg dag define dlm settiing Course
            let quizSuspendDay = this.state.quizSuspendDay; //yg dag define dlm settiing Course

            let quizSuspendDate = moment().format('YYYY-MM-DD');
            let suspendDayCount = moment(quizSuspendDate).add(quizSuspendDay, 'days');
            const quizAvailableDate = moment(suspendDayCount).format('YYYY-MM-DD');

            let quizSuspendCount =  this.state.quizSuspendCounter + 1;

            if (updateQuizFailCounter === quizSuspendNumber) { //suspend
                this.props.updateEnrolledQuiz_suspend(this.props.enrolledQuiz_id, quizAvailableDate, quizSuspendCount);
            }
        }

        this.props.updateEnrolledQuiz_quizScore(this.props.enrolledQuiz_id, marks); //update markah dlm db

        this.setState({
            updateQuizFailCounter: updateQuizFailCounter,
            marks,
            hideMarks: false,
            hideAfterSubmit: true,
            quizStatus: quizStatus
        })
    }

    render(){

       const { auth, modules, module_id, courses, course_id } = this.props;
        
       if (!auth.uid) return <Redirect to="/" />

        let questionCount = this.state.questionCount - 1;
        let questions = this.state.questions;

        let displayQuizMark = null, displayQuestionCount = null, displayQuizTimer = null, displaySuspendDay = null, displaySuspedNumber = null;

        modules && modules.map(module => {
            if(module_id === module.id) {
                displayQuizMark = module.quiz.quizMark
                displayQuestionCount = module.quiz.questionCount
                displayQuizTimer = module.quiz.quizTimer
                displaySuspendDay = module.quiz.quizSuspendDay
                displaySuspedNumber = module.quiz.quizSuspendNumber
            }
            return null
        })

        let question = questions && questions.map((question,i) => {
            let no = i + 1;
            if(question.id === this.state.idQuestionIndex0) {
                return (
                    <div id="question1" key={question.id}>
                        <div className="listing ka-question"> 
                            <div className="list-icon">{no}.</div>
                            <label>
                                {question.questionContent1}
                            </label>
                        </div>
                        {/* <p className="ka-question">{no}. {'  '} {question.questionContent1}</p>  */}
                        <p className="ka-option" dangerouslySetInnerHTML={{__html: question.questionContent2}}></p>
                        <div className="ka-answer">
                            
                            <ul>
                            {question.answers && question.answers.map((answer,index) => {
                                return (
                                    <div key={index} className="listing"> 
                                        <div className="list-icon"><input id={answer.id} onChange={this.selectAnswer.bind(this, index, answer.answerStatus)} type="radio" name="options"/></div>
                                        <label htmlFor={answer.id}>
                                            {answer.answerContent} {' '}
                                            {/* <label className={answer.answerStatus === true ? "text-green" : "text-red"}>{answer.answerStatus.toString()}</label> */}
                                        </label>
                                    </div>
                                )
                            })}
                            </ul>
                        </div>
                        <div className="col text-right">
                            <button className="justify-content-center btn all-btn" onClick={this.nextQuestion.bind(this, i, question.id, question.questionMark)}>
                                Next Questions {'  '}<i className="fas fa-arrow-alt-circle-right"></i>
                            </button>
                        </div>
                    </div>
                )
            } else if (question.id === this.state.nextQuestionId) {
                document.getElementById('question1').style.display = "none";
                return (
                    <div key={question.id}>
                        <div className="listing ka-question"> 
                            <div className="list-icon">{no}.</div>
                            <label>
                                {question.questionContent1}
                            </label>
                        </div>
                        {/* <p className="ka-question">{no}. {'  '} {question.questionContent1}</p>  */}
                        <p className="ka-option" dangerouslySetInnerHTML={{__html: question.questionContent2}}></p>
                        <div className="ka-answer">
                            <ul>
                            {question.answers && question.answers.map((answer,index) => {
                                return (
                                    <div key={index} className="listing"> 
                                        <div className="list-icon"><input id={answer.id} onChange={this.selectAnswer.bind(this, index, answer.answerStatus)} type="radio" name="options"/></div>
                                        <label htmlFor={answer.id}>
                                            {answer.answerContent} 
                                            {/* {' '} <label className={answer.answerStatus === true ? "text-green" : "text-red"}>{answer.answerStatus.toString()}</label> */}
                                        </label>
                                    </div>
                                )
                            })}
                            </ul>
                        </div>
                        <div className="col text-right">

                        { this.state.nextQuestionIndex === questionCount ?
                            <button className="justify-content-center btn all-btn" onClick={this.finishQuestion.bind(this, question.id, question.questionMark, null)}> 
                                Finish{'  '}<i className="fas fa-check-circle"></i>
                            </button>
                        :
                            <button className="justify-content-center btn all-btn" onClick={this.nextQuestion.bind(this, i, question.id, question.questionMark)}>
                                Next Question {'  '}<i className="fas fa-arrow-alt-circle-right"></i>
                            </button>
                        }
                        </div>
                    </div>
                )
            } else return null
        })

        //for review wrong questions
        let isPassed = null;
        if (parseInt(this.state.marks) >= 0) {
            if (this.state.marks >= this.state.quizMark) {
                isPassed = true
            } else {
                isPassed = false
            }
        }

        let wrongQuestionNo = 0;

        let courseName, moduleName;

        courses && courses.map(course => {
            if(course.id === course_id) {
                courseName = course.courseName
            }
            return null
        })

        modules && modules.map(module => {
            if(module.id === module_id) {
                moduleName = module.moduleName
            }
            return null
        })

        return(

            <div className="page-learning learning min-height" >
                
                <div id="modal-submit" className="middle normalmodal">
                    <div className={isPassed === false ? "mymodal-content col-md-6" : isPassed === true ? "normalmodal-content col-md-4" : "normalmodal-content col-md-4" }>
                    <span className="close" onClick={this.hideModal}>&times;</span>

                        <div hidden={this.state.hideAfterSubmit}>
                            <h5 id="title-modal">You're finished!</h5>
                            <p>Click submit to get your marks.</p>
                        </div>

                        {this.state.marks >= this.state.quizMark ?
                            <div className="middle" hidden={this.state.hideMarks}>

                                <body className='notranslate'>
                                    <p className="marks-success middle"> {this.state.marks}% </p>
                                </body>

                                <h5>Congratulations!</h5>

                                { this.state.moduleLength === this.props.module_index ?
                                    <small>Please continue to Examination on the 'My Enrollments' page.</small>
                                : 
                                    <small>{ this.state.enrollmentType === "Professional" ?
                                        <label>Next module unlocked.</label>
                                    :
                                        <label>Please proceed to next module.</label>
                                    }</small>
                                }
                               
                            </div>
                        :
                            <div hidden={this.state.hideMarks}>
                                <div className="middle">
                                    <body className='notranslate'>
                                        <p className="marks-failed middle"> {this.state.marks}% </p>
                                    </body>

                                    { this.state.quizSuspendNumber === this.state.updateQuizFailCounter ?
                                        <h5>You have been suspended!</h5>
                                    :
                                        <h5>Try harder!</h5>
                                    }
                                </div>

                                <div className="wrong-questions-review">
                                    <h1>Review wrong answer(s)</h1>

                                    <div className="text-left alert alert-danger">
                                        <b><i className="fas fa-exclamation-triangle text-red"/>{'  '}This data will not be saved into the system.</b>
                                        <br/>
                                        <b><i className="fas fa-exclamation-triangle text-red"/>{'  '}Once close, you will not be able to review again.</b>
                                    </div>

                                    { questions && questions.map(question => {
                                        return (
                                            this.state.wrongQuestions && this.state.wrongQuestions.map((wrong, i) => {
                                                if(wrong === question.id) {
                                                    wrongQuestionNo = wrongQuestionNo + 1;
                                                    return (
                                                        <div key={i} className="question text-left">
                                                            <div className="listing"> 
                                                                <div className="list-icon">{wrongQuestionNo}.</div>
                                                                <label>{question.questionContent1}</label>
                                                            </div>
                                                             <p style= {{marginLeft:"20px"}} dangerouslySetInnerHTML={{__html: question.questionContent2}}></p>
                                                        </div>
                                                    )
                                                } else return null
                                            })
                                        )
                                    })}
                                </div>

                            </div>
                        }

                        <button hidden={this.state.hideAfterSubmit} className="justify-content-center btn all-btn" onClick={this.submit.bind(this)}>
                            Submit Answers
                        </button>
                    </div>
                </div>

                <div className="page-header middle">
                    <b>Knowledge Assessment</b>
                    <p>{courseName} - {moduleName}</p>
                    <div className="title-border"></div>
                </div>
                
                <div className='page-content'>
                    <div className='middle'>
                        <div className='text-center col-md-8'>
                            <div hidden={this.state.hideBtnStart} >
                                <div className="alert alert-warning text-left" style={{margin:"0px 0px 40px 0px"}}>
                                    <div className="listing"> 
                                        <div className="list-icon"><li></li></div>
                                        <span>Consists <b>{displayQuestionCount}</b> questions for <b>{displayQuizTimer/60}</b> minutes duration.</span>
                                    </div>
                                    <div className="listing"> 
                                        <div className="list-icon"><li></li></div>
                                        <span>Pass rate is <b>{displayQuizMark}%</b></span>
                                    </div>
                                    <div className="listing"> 
                                        <div className="list-icon"><li></li></div>
                                        <span>You will be suspended for <b>{displaySuspendDay} day(s)</b> if you failed <b>{displaySuspedNumber} consecutive attempts</b>.</span>
                                    </div>
                                    <div className="listing"> 
                                        <div className="list-icon"><li></li></div>
                                        <span>You will be <b>counted as failed 1 time</b>, even if you are just <b>entering the knowledge assessment without answering</b>.</span>
                                    </div>
                                </div>

                                { this.state.showStartBtn === true ?
                                    <button className="btn all-btn" onClick={this.startQuestion.bind(this)}> Start Knowledge Assessment {'  '}<i className="fas fa-arrow-alt-circle-right"></i> </button>

                                    // enrolledQuiz && enrolledQuiz.quizAvailableDate === "" ?
                                    //     <button className="btn all-btn" onClick={this.startQuestion.bind(this)}> Start Quiz {'  '}<i className="fas fa-arrow-alt-circle-right"></i> </button>
                                    // : 
                                    //     <button className="btn btn-disable"> Start Quiz {'  '}<i className="fas fa-arrow-alt-circle-right"></i> </button>
                                :
                                    <p>Please wait for <b>{this.state.startBtnCounter}</b> second(s)</p>
                                } 
                            </div>

                            <div className="row justify-content-center col-md-12">
                                <div className="timer" hidden={this.state.hideTimer}>
                                    <ReactCountdownClock 
                                        paused={this.state.isPaused}
                                        seconds={displayQuizTimer}
                                        color="#ffcb63"
                                        alpha={0.9}
                                        size={120}
                                        weight={'5'}
                                        onComplete={this.finishQuestion.bind(this, null, null, true)}
                                    />  
                                </div>
                            </div>

                            <div className="text-left mt-5">
                                {question}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state, ownProps) => {

    const course_id = ownProps.match.params.course_id;
    const enrolledQuiz_id = ownProps.match.params.enrolledQuiz_id;
    const module_index = ownProps.match.params.module_index;

    const id = ownProps.match.params.module_id;
    const modules = state.firestore.data.modules;
    const module = modules ? modules[id] : null

    const quizEnrollments = state.firestore.data.quizEnrollments;
    const enrolledQuiz = quizEnrollments ? quizEnrollments[enrolledQuiz_id] : null

    return {
        auth: state.firebase.auth,
        course_id: course_id,

        module_index: parseInt(module_index),
        module_id: id,
        module: module,

        enrolledQuiz:enrolledQuiz,
        enrolledQuiz_id:enrolledQuiz_id,

        modules: state.firestore.ordered.modules,
        courses: state.firestore.ordered.courses,
        quizzes: state.firestore.ordered.quizzes,
        questions: state.firestore.ordered.questions,
        moduleEnrollments: state.firestore.ordered.moduleEnrollments,
        examEnrollments: state.firestore.ordered.examEnrollments,
        courseEnrollments: state.firestore.ordered.courseEnrollments,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
      updateEnrolledModule_isAvailable: (enrolledNextModuleId) => dispatch(updateEnrolledModule_isAvailable(enrolledNextModuleId)),
      updateEnrolledModule_isDone: (enrolledCurrentModuleId) => dispatch(updateEnrolledModule_isDone(enrolledCurrentModuleId)),
      updateEnrolledExam_isAvailable: (enrolledExamId) => dispatch(updateEnrolledExam_isAvailable(enrolledExamId)),
      updateEnrolledQuiz_quizFailCounter: (enrolledQuizId, counter) => dispatch(updateEnrolledQuiz_quizFailCounter(enrolledQuizId, counter)),
      updateEnrolledQuiz_quizScore: (enrolledQuizId, marks) => dispatch(updateEnrolledQuiz_quizScore(enrolledQuizId, marks)),
      updateEnrolledQuiz_suspend: (enrolledQuizId, date, quizSuspendCount) => dispatch(updateEnrolledQuiz_suspend(enrolledQuizId, date, quizSuspendCount)),
      updateEnrolledQuiz_quizAttend: (attended, enrolledQuizId) => dispatch(updateEnrolledQuiz_quizAttend(attended, enrolledQuizId)),
      module_finishDate: (enrolledId) => dispatch(module_finishDate(enrolledId)),
      
    }
}
  
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect(props => [
        { collection: 'modules'},
        { collection: 'courses'},
        { collection: 'quizzes'},
        { collection: 'questions'},
        { collection: 'moduleEnrollments', where: props.auth.uid ? ['userId', '==', props.auth.uid] : null, orderBy: ['indexKey','asc']},
        { collection: 'examEnrollments', where: props.auth.uid ? ['userId', '==', props.auth.uid] : null},
        { collection: 'courseEnrollments', where: props.auth.uid ? ['userId', '==', props.auth.uid] : null},
    ])
)(Quiz);