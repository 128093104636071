import React, { Component } from 'react';
import { connect } from 'react-redux'

import { updateExam } from '../../../stores/actions/courseAction';

class EditExam extends Component{

    constructor(props){
        super(props)
        this.state = {
            examName: this.props.exam.examName,
            examCode: this.props.exam.examCode,
            selectedQuestions: this.props.exam.selectedQuestions,
            
            searchExam_Question: '',
            selectedQuestionCode: '',
    
            isLoading: null,
            message: null,
        }
        this.baseState = this.state 
    }
    
    componentDidUpdate = (prevProps, prevState) => { 
        const { updateExamError, updateExamSuccess } = this.props;

        if(prevProps.updateExamError !== updateExamError) {
            this.setState({
                isLoading: false,
                message: {
                    msg: updateExamError.message,
                    type: updateExamError.type,
                }
            })
        }

        if(prevProps.updateExamSuccess !== updateExamSuccess) {
            this.setState({
                isLoading: false,
                message: {
                    msg: updateExamSuccess.message,
                    type: updateExamSuccess.type,
                }
            })
            setTimeout(() => { 
                this.props.cancel();
            }, 2000);
        }
    } 

    handleSelectedQuestion(id, code) {
        this.setState({
            selectedQuestions: [ 
                ...this.state.selectedQuestions, 
                id //get selected option ID
            ],
            selectedQuestionCode: code
        });
    }

    removeSelectedQuestion = (id, e) => {

        let removeSelectedQuestion = this.state.selectedQuestions.filter(selected => {
          return selected !== id
        })
    
        this.setState({
            selectedQuestions: removeSelectedQuestion
        })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    updateExam = (e) => {

        var result = window.confirm("Confirm update?");
    
        if (result) {
            this.setState({
                isLoading: true
            })
            this.props.updateExam(this.state, this.props.examId);
        }
    }

    render(){

        const { questions } = this.props; 
        const { message, isLoading } = this.state;
        
        let filteredQuestions = null;

        if(this.state.searchExam_Question.length > 0) {
            filteredQuestions = questions && questions.filter(question => {
                return question.questionCode.toLowerCase().includes(this.state.searchExam_Question.toLowerCase())
            })
        } else {
            filteredQuestions = questions;
        }
        
        return(

            <div>
                <form className="text-left">
                    <div className="row">  
                        <p className="col col-md-3">
                            <b>Code:</b>
                            <input type="text" id="examCode" onChange={this.handleChange} defaultValue={this.state.examCode} className="form-control"/>
                        </p>
                        <p className="col">
                            <b>Name:</b>
                            <input type="text" id="examName" onChange={this.handleChange} defaultValue={this.state.examName} className="form-control"/>
                        </p>
                    </div>
                
                
                    <div className="mb-5">
                        <b>Questions: </b>
                        <div type="text" className="border-black">

                            { questions && questions.map((question,i) => {
                                return (
                                    this.state.selectedQuestions && this.state.selectedQuestions.map((selected, i) => {
                                        if(question.id === selected) {
                                            return (
                                                <span key={i} className="text-white badge bg-green">
                                                    {question.questionCode} {' '}
                                                    <span onClick={this.removeSelectedQuestion.bind(this, selected)}>
                                                        <i className="fas fa-times"></i>
                                                    </span>
                                                </span>
                                            )
                                        }
                                        return null
                                    })
                                )
                            })}
                        </div>
                    
                        <br/>
                    
                        <small>Choose question here:</small>

                        <br/>

                        <div className="row col-md-5 btn-group dropdown">
                            <button type="button" className="text-left btn border-black dropdown-toggle" data-toggle="dropdown">
                                { this.state.selectedQuestionCode ? this.state.selectedQuestionCode : 'Choose Question'}
                            </button>

                            <div className="dropdown-menu" >
                                <span className="dropdown-item" onClick={this.handleSelectedQuestion.bind(this, '', '')} disabled>QUESTIONS</span>
                                <span className="dropdown-item"><input placeholder="Search code..." className="form-control"  onChange={event => this.setState({searchExam_Question: event.target.value})}/></span>
                                { filteredQuestions && filteredQuestions.map((question,i) => {
                                    return (
                                        <span key={i} className="dropdown-item" onClick={this.handleSelectedQuestion.bind(this, question.id, question.questionCode)}>{question.questionCode}</span>
                                    )
                                })}
                            </div>
                        </div>
                    </div>

                    { isLoading === true ? 
                        <small>Loading...</small>
                    : 
                        message  ?
                            <small className={message.type === 'updateExamError' ? "text-red" : message.type === 'updateExamSuccess'  ? "text-green" : null }>
                                {message.msg}
                            </small>
                        : null
                    }

                    <div className="mt-5 row justify-content-end">
                        <button type="button" className="btn m-2 btn-outline-black" onClick={this.updateExam.bind(this)}><i className="fas fa-check-circle"/>{' '}update</button>
                        <button type="button" className="btn m-2 btn-outline-black" onClick={this.props.cancel.bind(this)}><i className="fas fa-times"/>{' '}cancel</button>
                    </div>
                            
                </form>
            </div>
        
        )
    }
}

const mapStateToProps = (state) => {
  return {
      updateExamError: state.courses.updateExamError,
      updateExamSuccess: state.courses.updateExamSuccess,
  }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateExam: (updatedExam, examId) => {dispatch(updateExam(updatedExam, examId))}
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(EditExam);