import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import { Link } from 'react-router-dom'
import moment from 'moment';

import { submitFormReqClass } from '../../stores/actions/classAction';
import EnrollmentCount from './EnrollmentCount';

class CourseProgress extends Component{

    state = {
        searchUser: '',
        searchCustomLabel:'',

        isHideDetails: null,
        isHideAction: null,

        selectedEnrolledCourseId:'',
        selectedEnrolledUserId:'',
        selectedModal_CourseId:'',

        firstIndexData: 0,
        lastIndexData:100
    }

    loadMore = () => {
        this.setState({
            lastIndexData: this.state.lastIndexData + 100
        })
    }

    showModal = (type, courseId, enrolledCourseId, userId) => {

        if(type === "modal-course-detail") {
            const { query } = this.props;

            let query_courseType = query.get('courseType');
            let query_courseName = query.get('courseName');
            let query_courseId = query.get('courseId');
    
            this.props.history.push(`/adminmanager?topic=progress&courseType=${query_courseType}&courseId=${query_courseId}&courseName=${query_courseName}&userId=${userId}`);
    
            this.setState({
                selectedEnrolledCourseId: enrolledCourseId,
                selectedEnrolledUserId: userId,
                selectedModal_CourseId: courseId
            })
        }
        
        document.getElementById(`${type}`).style.display = "block";
    }
    
    hideModal = (type) => {
        document.getElementById(`${type}`).style.display = "none";
    }

    handleSelectedStatus = (e) => {
        this.setState({
            pcpStatus: e.target.value,
            pcpId: e.target[e.target.selectedIndex].id,
        })
    }

    addPCP = (courseId, userName, userEmail, userPhone, userId) => {
        var result = window.confirm(`Confirm add ${userEmail} to practical class data?`);

        let pcpData = {
            name: userName,
            email: userEmail,
            phone: userPhone ? userPhone : 'Profile not updated',
            date: moment().format('YYYY-MM-DD'),
            courseId: courseId,
            userId: userId,
            status:'',
            selectedBatchId: '',
            isChange_batchId: '' //to tally data with user request class/change class date
        }
    
        if (result) {
            this.props.submitFormReqClass(pcpData)
        }
    }


    render(){

        const { query, physicalClassBatches, physicalClassParticipants, courseEnrollments, courses, users, examEnrollments, quizEnrollments, modules } = this.props;

        let query_courseType = query.get('courseType');
        let query_courseName = query.get('courseName');
        let query_courseId = query.get('courseId');

        let courseNo = 0, courseList = null, enrollmentList = null;
        let filteredUsers = null, filteredEnrollments1 = null, filteredEnrollments = [];
        let enrolledUsers = [], requestedParticipants = [];
        let noSearchData = false;

        // 1ST: filter user and get USERID
        if(this.state.searchUser.length > 0) { 
            filteredUsers = users && users.filter(user => {

                let username = user.firstname + ' ' + user.lastname;

                return user.email && (user.email.toLowerCase().includes(this.state.searchUser.toLowerCase()) ||
                username.toLowerCase().includes(this.state.searchUser.toLowerCase()))
            })
        } else {
            filteredUsers = users;
        }

        if(this.state.searchCustomLabel.length > 0) { 
            filteredEnrollments1 = courseEnrollments && courseEnrollments.filter(enrolled => {
                return enrolled.customLabel.toLowerCase().includes(this.state.searchCustomLabel.toLowerCase())
            })
        } else {
            filteredEnrollments1 = courseEnrollments;
        }

        //2ND: map USERID with enrollment
        filteredEnrollments1 && filteredEnrollments1.map(enrolled => {
            if(query_courseType === enrolled.type && enrolled.courseId === query_courseId){
                return (
                    filteredUsers && filteredUsers.map(user => {
                        if(enrolled.userId === user.id) {
                            filteredEnrollments.push(enrolled)
                        } else {
                            noSearchData = true;
                        }
                        return null
                    })
                )
            }
            return null
        })

        courseList = courses && courses.map((course,i) => {
            if (course.courseType === "Professional") {
                return(
                    <span key={i} className={query_courseId === course.id ? "bg-white dropdown-item" : 'dropdown-item'}>
                        <Link to={`/adminmanager?topic=progress&courseType=${query_courseType}&courseId=${course.id}&courseName=${course.courseName}`}>{course.courseName}</Link>
                    </span>
                )
            } else {}
            return null
        })

        let hasPhysicalClass = false;

        if(query_courseId) {
            courses && courses.map(course => {
                if(course.id === query_courseId && (course.hasPhysicalClass === "true" ||  course.hasPhysicalClass === true)) {
                    hasPhysicalClass = true
                }
                return null
            })
        }

        let hasData = [], isLoadingData = true;

        physicalClassParticipants && physicalClassParticipants.map(part => {
            if(part.courseId === query_courseId) {
                requestedParticipants.push(part.userId)
            }
            return null
        })

        let sliced = filteredEnrollments && filteredEnrollments.slice(this.state.firstIndexData, this.state.lastIndexData);

        enrollmentList = sliced && sliced.map((enrolled,i) => {

            let userName = '', userEmail = '', userPhone = '';

            users && users.map(user => {
                if (user.id === enrolled.userId) {
                    userName = user.firstname + ' ' + user.lastname
                    userEmail = user.email
                    userPhone = user.contactNo
                }
                return null
            })

            let enrollExpired = moment(enrolled.enrollExpired).format('YYYY-MM-DD');
            let dateToday = moment().format('YYYY-MM-DD');
                                    
            let enrollExpiredSame = moment(dateToday).isSame(enrollExpired);
            let enrollExpiredAfter = moment(dateToday).isAfter(enrollExpired);

            if(query_courseType === enrolled.type) {
                if(enrolled.courseId === query_courseId) {

                    enrolledUsers.push(enrolled.userId)
            
                    let remainingParticipants = enrolledUsers.filter(val => !requestedParticipants.includes(val));

                    hasData.push(enrolled)
                    isLoadingData = false;

                    courseNo = courseNo + 1;
                    return (
                        <tr key={i}>
                            <th scope="row">{courseNo}</th>

                            <td className="text-left">
                                <b>{userName}</b><br/>{userEmail} <br/>
                                <Link target="_blank" rel="noopener noreferrer" className='text-green' to={`/user_timestamp?courseType=${query_courseType}&courseId=${query_courseId}&userId=${enrolled.userId}`}><i className='fas fa-eye'/>{' '}<b>Timestamp</b></Link>
                            </td>

                            {courses && courses.map((course,i) => {
                                if (course.id === enrolled.courseId) {
                                    return (
                                        <td key={i}>
                                            <span onClick={this.showModal.bind(this, 'modal-course-detail', course.id, enrolled.id, enrolled.userId)}>
                                                {enrolled.customLabel ? <b style={{textTransform:"uppercase"}} className="text-green">{enrolled.customLabel}</b> : null }
                                                {' '}
                                                {course.courseCode}
                                            </span>
                                        </td>
                                    )
                                }
                                return null
                            })}

                            {enrollExpiredSame === false && enrollExpiredAfter === false ? 
                                <td>
                                    {enrollExpired} <br/>
                                    <small><b>Ext.Count : {enrolled.extendCount ? enrolled.extendCount : '0'}</b></small>
                                </td>
                            : //expired
                                <td>
                                    <b className='text-red'>{enrollExpired}</b> <br/>
                                    <small><b>Ext.Count : {enrolled.extendCount ? enrolled.extendCount : '0'}</b></small>
                                </td>
                            }

                            <td>
                                {examEnrollments && examEnrollments.map((exam,i) => {
                                    if (exam.courseId === query_courseId && exam.userId === enrolled.userId) {
                                        if (enrolled.isOnGoing === true && exam.isDone === false) {
                                            return <span key={i} style={{fontSize:"10px"}} className="badge bg-yellow text-black" title="On going - Not done exam">ONG</span>
                                        } else if(enrolled.isOnGoing === true && exam.isDone === true) {
                                            return <span key={i} style={{fontSize:"10px"}} className="badge bg-green text-white" title="Done exam">DEX</span>
                                        }
                                    }
                                    return null
                                })}
                            </td>

                            { hasPhysicalClass === true ?
                                <td className="middle">
                                    { requestedParticipants && requestedParticipants.map((id,i) => {
                                        if(id === enrolled.userId) {
                                            return (
                                                physicalClassParticipants && physicalClassParticipants.map((part,i) => {
                                                    if(part.userId === enrolled.userId && part.courseId === enrolled.courseId) {
                                                        if(part.status === "listed") {
                                                            return <span key={i} style={{fontSize:"10px"}} className="badge bg-blue text-white" title="Listed to practical session">LIS</span>
                                                        } else if(part.status === "active") {
                                                            return <span key={i} style={{fontSize:"10px"}} className="badge bg-orange text-white" title="On going practical session">ACT</span>
                                                        } else if(part.status === "submitted") {
                                                            return <span key={i} style={{fontSize:"10px"}} className="badge bg-green text-white" title="Assignment submitted">SUB</span>
                                                        } else if(part.status === "completed") {
                                                            return <span key={i} style={{fontSize:"10px"}} className="badge bg-black text-white" title="Complete practical session">COM</span>
                                                        } else if(part.status === "requested") {
                                                            return <span key={i} style={{fontSize:"10px"}} className="badge bg-purple text-white" title="Requested for practical session">REQ</span>
                                                        } else return null
                                                    } return null
                                                })
                                            )
                                        } return null
                                    })}

                                    { remainingParticipants && remainingParticipants.map((id,i) => {
                                        if(id === enrolled.userId) {
                                            return (
                                                <span key={i} title ="Add user to class data" className="badge bg-blue text-white" onClick={this.addPCP.bind(this, enrolled.courseId, userName, userEmail, userPhone, enrolled.userId)}><i className="fas fa-plus" /></span> 
                                            )
                                        }
                                        return null
                                    })}
                                </td>
                            : null }
                            
                        </tr>
                    )
                } else {
                    isLoadingData = false;
                }
            } else {
                isLoadingData = false;
            }
            return null
        })

        return(

        <div className="course-progress">

            <div id="modal-course-detail" className="mymodal">
                <div className="mymodal-content col-md-6">
                    <span className="close" onClick={this.hideModal.bind(this, 'modal-course-detail')}>&times;</span>

                { courseEnrollments && courseEnrollments.map((enrolled,i) => {
                    if (enrolled.id === this.state.selectedEnrolledCourseId && enrolled.userId === this.state.selectedEnrolledUserId) {
                        return (
                            <div key={i}>
                                <div hidden={this.state.isHideDetails}>
                                    <h5 className="middle">User Course Details
                                        <div className="row"><div className="title-border"></div></div>
                                    </h5>

                                    { users && users.map ((user,i) => {
                                        if (user.id === this.state.selectedEnrolledUserId) {
                                            return (
                                                <div key={i}>
                                                    <i className="fas fa-user"/>{' '}{user.firstname} {user.lastname} <br/>
                                                    <i className="fas fa-envelope"/>{' '}{user.email} <br/>
                                                    <i className="fas fa-phone"/>{' '}{user.contactNo ? user.contactNo : 'Not updated'} <br/>
                                                    <i className="fas fa-briefcase"/>{' '}{user.company ? user.company : 'Not updated'}
                                                </div>
                                            )
                                        }
                                        return null
                                    })}

                                    <br/>

                                    <b>Quizzes</b>

                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Module</th>
                                                <th scope="col">Fail Counter</th>
                                                <th scope="col">Suspend Counter</th>
                                                <th scope="col">Score</th>
                                                <th scope="col">Status</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            { modules && modules.map(module => {
                                                return (
                                                    quizEnrollments && quizEnrollments.map((quiz,i) => {
                                                        if(module.id === quiz.moduleId) {
                                                            return (
                                                                <tr key={i}>
                                                                    <td className="text-left">{module.moduleName}</td>
                                                                    <td>{quiz.quizFailCounter}</td>
                                                                    <td>{quiz.quizSuspendCounter}</td>
                                                                    <td>{quiz.quizScore}</td>

                                                                    {quiz.quizAttend === true ?
                                                                        <td>Attend</td>
                                                                    : 
                                                                        <td>Not Attend</td>
                                                                    }
                                                                </tr>
                                                            )
                                                        } return null
                                                    })
                                                )
                                            })}
                                        </tbody>

                                    </table>

                                    <br/>

                                    <b>Exam</b>
                                    
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Exam</th>
                                                <th scope="col">Fail Counter</th>
                                                <th scope="col">Suspend Counter</th>
                                                <th scope="col">Score</th>
                                                <th scope="col">Status</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            { examEnrollments && examEnrollments.map((exam,i) => {
                                                if (exam.userId === this.state.selectedEnrolledUserId) {
                                                    return (
                                                        <tr key={i}>
                                                            <td>Exam</td>
                                                            <td>{exam.examFailCounter}</td>
                                                            <td>{exam.examSuspendCounter}</td>
                                                            <td>{exam.examScore}</td>

                                                            {exam.examAttend === true ?
                                                                <td>Attend</td>
                                                            : 
                                                                <td>Not Attend</td>
                                                            }
                                                            
                                                        </tr>
                                                    )
                                                }
                                                return null
                                            })}
                                        </tbody>
                                    </table>

                                    <br/>

                                    <b>Practical session batch code: {' '}</b>
                                    { physicalClassBatches && physicalClassBatches.map((batch,i) => {
                                        return (
                                            batch.selectedParticipants && batch.selectedParticipants.map(part => {
                                                if(part.userId === this.state.selectedEnrolledUserId && part.enrolledCourseId === this.state.selectedEnrolledCourseId) {
                                                    return <i key={i}>{batch.batchName}</i>
                                                }
                                                return null
                                            })
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    }
                    return null
                })}
                
                </div>
            </div>

            <div id="modal-enroll-count" className="mymodal">
                <div className="mymodal-content col-md-12">
                    <span className="close" onClick={this.hideModal.bind(this, 'modal-enroll-count')}>&times;</span>
                    <EnrollmentCount courses={courses} physicalClassParticipants={physicalClassParticipants} courseEnrollments={courseEnrollments} courseId={query_courseId} courseType={query_courseType} />
                </div>
            </div>

            <h3 className="text-left">User Course Progress
                <div className="title-border"></div>
            </h3>

            <div className="row col-md-12">

                <div className="btn-group dropdown">
                    <button type="button" className="btn border-black dropdown-toggle" data-toggle="dropdown">
                        { query_courseType ? query_courseType  : 'Choose Type' }
                    </button>

                    <div className="dropdown-menu" >
                        <b style={{marginLeft:"20px"}}>TYPE</b>
                        <span className={query_courseType === "Professional" ? "bg-white dropdown-item" : 'dropdown-item'}>
                            <Link to={`/adminmanager?topic=progress&courseType=Professional`}>Professional</Link>
                        </span>
                        <span className={query_courseType === "Flexi" ? "bg-white dropdown-item" : 'dropdown-item'}>
                            <Link to={`/adminmanager?topic=progress&courseType=Flexi`}>Flexi</Link>
                        </span>
                    </div>
                </div>

                { query_courseType !== null ?
                    <div className="btn-group dropdown">
                        <button type="button" className="btn border-black dropdown-toggle" data-toggle="dropdown">
                            { query_courseName ? query_courseName  : 'Choose Course' }
                        </button>

                        <div className="dropdown-menu" >
                            <b style={{marginLeft:"20px"}}>COURSE</b>
                            {courseList}
                        </div>
                    </div>
                : null }

                { query_courseId ?
                    <span onClick={this.showModal.bind(this, 'modal-enroll-count', null, null, null)} className="m-2 badge bg-green text-white">
                       <i className="fas fa-eye" />{' '}Enrollment Count
                    </span>
                : null  }
            </div>

            <div className="mt-5"></div>

            { query_courseType === "Professional" ? 
                <b className="uppercase">professional - {query_courseName}
                    <div className="title-border"></div>
                </b>
            : query_courseType === "Flexi" ? 
                <b className="uppercase">flexi - {query_courseName}
                    <div className="title-border"></div>
                </b>
            :''}

            { query_courseId ?
                <div>
                    <div className='row col-md-10'>
                        <input placeholder="Search user by email or name" className="mb-3 col form-control"  onChange={event => this.setState({searchUser: event.target.value})}/>
                        <input placeholder="Search user by custom label (custom course/promo)" className="mb-3 col form-control"  onChange={event => this.setState({searchCustomLabel: event.target.value})}/>
                    </div>
                    
                    { isLoadingData === true ? 
                        noSearchData === true ?
                            <p className="mt-5">No search data</p> 
                        :
                            <p className="mt-5">Please wait... Data loading...</p> 
                    : 
                        hasData.length > 0 ? 
                            <table className="mt-5 table">
                                <thead>
                                    <tr>
                                        <th scope="col">No.</th>
                                        <th className="text-left" scope="col">User</th>
                                        <th scope="col">Enrolled Course</th>
                                        <th scope="col">Expired</th>
                                        <th scope="col">Online Status</th>

                                        {hasPhysicalClass === true ?
                                            <th scope="col">Class Status</th>
                                        : null }
                                    </tr>
                                </thead>
                                
                                <tbody>
                                    {enrollmentList}
                                </tbody>
                            </table>
                        : 
                            <p>No data available</p>
                    }

                </div>
            :
                <p>Please choose option above</p>
            }
            

            { query_courseId  && filteredEnrollments.length > 0 ?
                this.state.lastIndexData >= filteredEnrollments.length ?
                    <button className="btn btn-disable" onClick={this.loadMore.bind(this)}>Load More</button>
                :
                    <button className="btn all-btn" onClick={this.loadMore.bind(this)}>Load More</button>
            : null }

        </div>
        
        )
    }
}

const mapStateToProps = (state, ownProps) => {

    return {

        users: state.firestore.ordered.users,
        courseEnrollments: state.firestore.ordered.courseEnrollments,
        examEnrollments: state.firestore.ordered.examEnrollments,
        quizEnrollments: state.firestore.ordered.quizEnrollments,
        physicalClassParticipants: state.firestore.ordered.physicalClassParticipants,

        query_courseType: ownProps.query.get('courseType'),
        query_courseId: ownProps.query.get('courseId'),
        query_userId: ownProps.query.get('userId'),

    }
}
  
const mapDispatchToProps = (dispatch) => {
    return {
        submitFormReqClass: (participant) => dispatch(submitFormReqClass(participant)),
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect(props => [
        { collection: 'users'},
        { collection: 'physicalClassParticipants', where: ['courseId', '==', props.query_courseId] },
        { collection: 'courseEnrollments', where: props.query_courseId ? [ ['type', '==', props.query_courseType], ['courseId', '==', props.query_courseId] ] : null },
        { collection: 'examEnrollments', where: props.query_courseId ? [ ['type', '==', props.query_courseType], ['courseId', '==', props.query_courseId] ] : null },
        { collection: 'quizEnrollments', where: [ ['userId', '==', props.query_userId], ['courseId', '==', props.query_courseId] ] },
    ])
)(CourseProgress);