export const addHelpInfo = (helpInfo) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {

        const firestore = getFirestore();
        const { infoType, infoName, selectedHelpFile } = helpInfo;

        if(!infoName || !selectedHelpFile) {
            dispatch({ type:'ADDED_HELPINFO_ERROR', err: { message: 'Input field required.' }});
        } else {
            firestore.collection('infos').add({
                infoType: infoType,
                infoName: infoName,
                selectedHelpFile: selectedHelpFile,
    
            }).then(() => {
                dispatch({ type:'ADDED_HELPINFO', message: 'Help info added successfully.'});
            }).catch((err) => {
                dispatch({ type:'ADDED_HELPINFO_ERROR', err});
            })
        }
    }
}


export const updateHelpInfo = (updatedHelpInfo, helpInfoId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => { 

        const firestore = getFirestore();
        const { infoType, infoName, selectedHelpFile } = updatedHelpInfo;

        if(!infoName || !selectedHelpFile) {
            dispatch({ type:'UPDATED_HELPINFO_ERROR', err: { message: 'Input field required.' }});
        } else {
            firestore.collection("infos").doc(helpInfoId).update({
                infoName: infoName,
                infoType: infoType,
                selectedHelpFile: selectedHelpFile,
            })
            .then(() => {
                dispatch({ type:'UPDATED_HELPINFO', message: 'Help info updated successfully.'});
            }).catch((err) => {
                dispatch({ type:'UPDATED_HELPINFO_ERROR', err});
            })
        }

       
    }
}

export const deleteHelpInfo = (helpInfoId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {

        const firestore = getFirestore();

        firestore.collection("infos").doc(helpInfoId).delete()
        .then(() => {
            dispatch({ type:'DELETED_HELPINFO', message: 'Help info deleted successfully.'});
        }).catch((err) => {
            dispatch({ type:'DELETED_HELPINFO_ERROR', err});
        })
    }
}


export const addHalalInfo = (halalInfo) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {

        const firestore = getFirestore();
        const { infoType, infoName, selectedHalalFile } = halalInfo;

        if(!infoName || !selectedHalalFile) {
            dispatch({ type:'ADDED_HALALINFO_ERROR', err: { message: 'Input field required.' }});
        } else {
            firestore.collection('infos').add({
                infoType: infoType,
                infoName: infoName,
                selectedHalalFile: selectedHalalFile,

            }).then(() => {
                dispatch({ type:'ADDED_HALALINFO', message: 'Halal info added successfully.'});
            }).catch((err) => {
                dispatch({ type:'ADDED_HALALINFO_ERROR', err});
            })
        }
    }
}

export const updateHalalInfo = (updatedHalalInfo, HalalInfoId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => { 

        const firestore = getFirestore();
        const { infoType, infoName, selectedHalalFile } = updatedHalalInfo;

        if(!infoName || !selectedHalalFile) {
            dispatch({ type:'UPDATED_HALALINFO_ERROR', err: { message: 'Input field required.' }});
        } else {
            firestore.collection("infos").doc(HalalInfoId).update({
                infoName: infoName,
                infoType: infoType,
                selectedHalalFile: selectedHalalFile,
            })
            .then(() => {
                dispatch({ type:'UPDATED_HALALINFO', message: 'Halal info updated successfully.'});
            }).catch((err) => {
                dispatch({ type:'UPDATED_HALALINFO_ERROR', err});
            })
        }
    }
}

export const deleteHalalInfo = (halalInfoId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {

        const firestore = getFirestore();

        firestore.collection("infos").doc(halalInfoId).delete()
        .then(() => {
            dispatch({ type:'DELETED_HALALINFO', message: 'Halal info deleted successfully.'});
        }).catch((err) => {
            dispatch({ type:'DELETED_HALALINFO_ERROR', err});
        })
    }
}



export const addTerm = (term) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {

        const firestore = getFirestore();
        const { infoType, infoName, infoContent } = term;

        if(!infoName || !infoContent) {
            dispatch({ type:'ADDED_TERM_ERROR', err: { message: 'Input field required.' }});
        } else {
            firestore.collection('infos').add({
                infoType: infoType,
                infoName: infoName,
                infoContent: infoContent,

            }).then(() => {
                dispatch({ type:'ADDED_TERM', message: 'T&C added successfully.'});
            }).catch((err) => {
                dispatch({ type:'ADDED_TERM_ERROR', err});
            })
        }
    }
}

export const updateTerm = (updatedTerm, termId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => { 

        const firestore = getFirestore();
        const { infoType, infoName, infoContent } = updatedTerm;

        if(!infoName || !infoContent) {
            dispatch({ type:'UPDATED_TERM_ERROR', err: { message: 'Input field required.' }});
        } else {
            firestore.collection("infos").doc(termId).update({
                infoName: infoName,
                infoType: infoType,
                infoContent: infoContent,
            })
            .then(() => {
                dispatch({ type:'UPDATED_TERM', message: 'T&C updated successfully.'});
            }).catch((err) => {
                dispatch({ type:'UPDATED_TERM_ERROR', err});
            })
        }
    }
}

export const deleteTerm = (termId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {

        const firestore = getFirestore();

        firestore.collection("infos").doc(termId).delete()
        .then(() => {
            dispatch({ type:'DELETED_TERM', message: 'T&C deleted successfully.'});
        }).catch((err) => {
            dispatch({ type:'DELETED_TERM_ERROR', err});
        })
    }
}



export const addPrivacy = (privacy) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {

        const firestore = getFirestore();
        const { infoType, infoName, infoContent } = privacy;

        if(!infoName || !infoContent) {
            dispatch({ type:'ADDED_PRIVACY_ERROR', err: { message: 'Input field required.' }});
        } else {
            firestore.collection('infos').add({
                infoType: infoType,
                infoName: infoName,
                infoContent: infoContent,

            }).then(() => {
                dispatch({ type:'ADDED_PRIVACY', message: 'Privacy policy added successfully.'});
            }).catch((err) => {
                dispatch({ type:'ADDED_PRIVACY_ERROR', err});
            })
        }
    }
}

export const updatePrivacy = (updatedPrivacy, privacyId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => { 

        const firestore = getFirestore();
        const { infoType, infoName, infoContent } = updatedPrivacy;

        if(!infoName || !infoContent) {
            dispatch({ type:'UPDATED_PRIVACY_ERROR', err: { message: 'Input field required.' }});
        } else {
            firestore.collection("infos").doc(privacyId).update({
                infoName: infoName,
                infoType: infoType,
                infoContent: infoContent,
            })
            .then(() => {
                dispatch({ type:'UPDATED_PRIVACY', message: 'Privacy policy updated successfully.'});
            }).catch((err) => {
                dispatch({ type:'UPDATED_PRIVACY_ERROR', err});
            })
        }
    }
}

export const deletePrivacy = (privacyId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {

        const firestore = getFirestore();

        firestore.collection("infos").doc(privacyId).delete()
        .then(() => {
            dispatch({ type:'DELETED_PRIVACY', message: 'Privacy policy deleted successfully.'});
        }).catch((err) => {
            dispatch({ type:'DELETED_PRIVACY_ERROR', err});
        })
    }
}


export const addHiw = (hiw) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {

        const firestore = getFirestore();
        const { infoName, infoType, infoContent, infoNumber } = hiw;

        if(!infoName || !infoContent || !infoNumber) {
            dispatch({ type:'ADDED_HIW_ERROR', err: { message: 'Input field required.' }});
        } else {
            firestore.collection('infos').add({
                infoName: infoName,
                infoNumber: infoNumber,
                infoType: infoType,
                infoContent: infoContent,
    
            }).then(() => {
                dispatch({ type:'ADDED_HIW', message: 'How it Work  added successfully.'});
            }).catch((err) => {
                dispatch({ type:'ADDED_HIW_ERROR', err});
            })
        }
    }
}

export const updateHiw = (updated, hiwId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => { 

        const firestore = getFirestore();
        const { infoName, infoType, infoContent, infoNumber } = updated;

        if(!infoName || !infoContent || !infoNumber) {
            dispatch({ type:'UPDATED_HIW_ERROR', err: { message: 'Input field required.' }});
        } else {
            firestore.collection("infos").doc(hiwId).update({
                infoType: infoType,
                infoNumber: infoNumber,
                infoName: infoName,
                infoContent: infoContent,
            })
            .then(() => {
                dispatch({ type:'UPDATED_HIW', message: 'How it Work updated successfully.'});
            }).catch((err) => {
                dispatch({ type:'UPDATED_HIW_ERROR', err});
            })
        }
    }
}

export const deleteHiw = (hiwId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {

        const firestore = getFirestore();

        firestore.collection("infos").doc(hiwId).delete()
        .then(() => {
            dispatch({ type:'DELETED_HIW', message: 'How it Work  deleted successfully.'});
        }).catch((err) => {
            dispatch({ type:'DELETED_HIW_ERROR', err});
        })
    }
}





