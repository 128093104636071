import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'

import { addNewBatch, updatePCP_status } from '../../../stores/actions/classAction';


import EventRequest from './EventRequest';
import EventBatch from './EventCompleted';
import Batch from '../PhysicalClass/batch/Batch';
import EventCompleted from './EventCompleted';
//import Requested from '../PhysicalClass/Requested';
//import NewBatch from './NewBatch';
//import SubmittedBatch from './SubmittedBatch';

class Events extends Component{
    
    constructor(props){
        super(props)
        this.state = {
            /*batchCode:'',
            batchName:'',
            batchAmount: '',
            selectedParticipants:[],
            selectedCourseId_NewBatch:'',
    
            day1: "",
            day2: "",
            day3: "",
            day4: "",*/
    
            date:{},
    
            /*isLoading: null,
            message: null,

            isHide: false*/
        }
        this.baseState = this.state 
    }
    

    render(){

        const { query, users, events, eventsAttendees } = this.props; //from AdminManager.jsx
        

        let query_courseId = query.get('courseId')//id course dalam firestore
        let query_courseName = query.get('courseName')//dropdown bila pilih event
        let query_filter = query.get('filter')//tab requested@completed
        
        return(

        <div className="physical-class">
            

            <h3 className="text-left">Event Session
                <div className="title-border"></div>
            </h3>

            <div className="row col-md-12">
                <div className="btn-group dropdown">
                    <button type="button" className="btn border-black dropdown-toggle" data-toggle="dropdown">
                        { query_courseName ? query_courseName  : 'Choose Event' } {/*nnti update event punya database*/}
                    </button>

                    <div className="dropdown-menu" >{/*just the content of the dropdown menu */}
                        <b style={{marginLeft:"20px"}}>MODULE</b>
                        { events && events.map((event,i) => {
                                return (
                                    <span key={i}
                                        className={query_courseId ? query_courseId === event.id ? "bg-white dropdown-item" : 'dropdown-item' : 'dropdown-item'}>
                                        <Link to={`/adminmanager?topic=event&courseId=${event.id}&courseName=${event.eventsCode}&filter=requested`}>
                                            {event.eventsCode} - {event.eventsName}
                                        </Link>
                                    </span>
                                )
                            
                        })}
                    </div>
                </div>

            </div>

            {/* ----------------------------------------------------------TRY TAB---------------------------------------------------------- */}
            { query_courseId ?
                <div>
                    <div className="tab">
                        <Link className={query_filter === 'requested' ? "tablinks active" : "tablinks"} to={`/adminmanager?topic=event&courseId=${query_courseId}&courseName=${query_courseName}&filter=requested`}>Requested</Link>
                        <Link className={query_filter === 'completed' ? "tablinks active" : "tablinks"} to={`/adminmanager?topic=event&courseId=${query_courseId}&courseName=${query_courseName}&filter=completed`}>Completed</Link>
                    </div>

                    <div className="tabcontent" id={query_filter}>
                        <div style={{margin:"20px"}}>
                            
                            { query_filter === "requested" ? 
                                <EventRequest selectedEventId={query_courseId} events={events} eventsAttendees={eventsAttendees} users={users}/>         
                            :
                                <EventCompleted selectedEventId={query_courseId} events={events} users={users}  eventsAttendees={eventsAttendees} />
                            }
                        </div>
                    </div>   
                </div>
            : 
                <p className="mt-5">Please choose event.</p> }   
        </div>
        
        )
    }
}

const mapStateToProps = (state) => {
    return {
        addBatchError: state.classes.addBatchError,
        addBatchSuccess: state.classes.addBatchSuccess,
        
        courseEnrollments: state.firestore.ordered.courseEnrollments,
    }
}
  
const mapDispatchToProps = (dispatch) => {
    return {
      addNewBatch: (batch) => dispatch(addNewBatch(batch)),
      updatePCP_status: (data, pcpId) => dispatch(updatePCP_status(data, pcpId)),
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect([
        { collection: 'courseEnrollments'},
    ])
)(Events);

