import React, { Component } from 'react';
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'

class HalalInfo extends Component{

    render(){

        const { infos } = this.props;

        let  halalLists = null, isLoading = true;

        halalLists = infos && infos.map((info ,i) => {
            if(info.infoType === "HALAL INFO"){
                isLoading = false;
                return (
                    <span key={i} className="middle card border-0"> 
                        <a href={info.selectedHalalFile.fileUrl} target="_blank" rel="noopener noreferrer" download>
                            <div className="col middle">
                                <i className="fas fa-book-reader"/>
                                <br/>
                                <p>{info.infoName}</p>
                            </div>
                        </a>
                    </span>
                )
            }
            return null
        });

        return(

        <div className="halal-info container col-md-12 justify-content-center middle min-height">
        
            <div className="page-bgstyle col-md-9">
                <div className="middle">
                    <div className="col col-md-4" style={{marginBottom:"30px"}}> 
                        <img src="../../../image/HA_LOGO.png" alt="" width="45%"/><br />
                    </div>
                     
                    <h4>halal info
                        <div className="title-border"></div>
                    </h4>
                </div>

                { isLoading === false ? 
                    <i>Click any of the below to view or download the pdf file.</i>
                : null }
               
               <div className="mt-5 container col-md-12 middle">
                    { isLoading === true ? 
                        <div className="middle">
                            <div className="loader"></div> 
                        </div> 
                    : 
                        <div className="row col-md-12 justify-content-center">
                            {halalLists}
                        </div> 
                    }
                </div>

            </div>
        </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
      infos: state.firestore.ordered.infos,
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect([
        { collection: 'infos'},
    ])
)(HalalInfo);