import React, { Component } from 'react';
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import moment from  'moment';
import { extendEnrollment } from '../../../stores/actions/enrollmentAction';

class ExtendEnrollment extends Component {
    
    constructor(props){
        super(props)
        this.state = {
            extendEmail: '',
            selectedEnrolledId:'',
    
            month: '',
    
            hideCheck: false,
            hideExtend: true,
    
            isLoading: null,
            message: null,
        }
        this.baseState = this.state 
    }
    
    componentDidUpdate = (prevProps, prevState) => { 
        const { extendEnrollmentError, extendEnrollmentSuccess } = this.props;

        if(prevProps.extendEnrollmentError !== extendEnrollmentError) {
            this.setState({
                isLoading: false,
                message: {
                    msg: extendEnrollmentError.message,
                    type: extendEnrollmentError.type,
                }
            })
        }

        if(prevProps.extendEnrollmentSuccess !== extendEnrollmentSuccess) {
            this.setState({
                isLoading: false,
                message: {
                    msg: extendEnrollmentSuccess.message,
                    type: extendEnrollmentSuccess.type,
                }
            })

            setTimeout(() => { 
                this.setState(this.baseState)
            }, 5000);
        }
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    checkEmail = (e) => {
        e.preventDefault();

        const { users } = this.props;
        let emailLists = null, email = null;

        emailLists = users && users.map(user => {
            return user.email
        })

        email = [...emailLists]

        if (email.indexOf(this.state.extendEmail.toLowerCase()) >=0) { //cari persamaan email
            users && users.map(user => {
                if(user.email === this.state.extendEmail.toLowerCase()) {
                    this.setState({
                        hideCheck: true,
                        hideExtend: false,
                    })

                    this.props.history.push(`/adminmanager?topic=enrollment&filter=extend-enrollment&userId=${user.id}`);
                }
                return null
            })
        } else {
            this.setState({extendEmail: ''})
            alert('email not registered')
        }
    }

    selectedEnrolled = (e) => {
        this.setState({
            selectedEnrolledId: e.target[e.target.selectedIndex].id,
        })
    }

    getNewExpiredDate = async () => {
        const { selectedEnrolledId, month } = this.state;

        let expired_date = await this.getExpiredDate();
        let newEnrolledExpired = null;

        if(selectedEnrolledId) {
            let addEnrolledMonth = moment(expired_date).add(parseInt(month), 'months');
            newEnrolledExpired = moment(addEnrolledMonth).format('YYYY-MM-DD');
        }

        return newEnrolledExpired;
    }

    getExpiredDate = async () => {
        const { courseEnrollments } = this.props;
        const { selectedEnrolledId } = this.state;

        let expired_date = null;
        
        let dateToday = moment(new Date()).format('YYYY-MM-DD');

        if(selectedEnrolledId) {
            courseEnrollments && courseEnrollments.map(enrolled => {
                if(enrolled.id === selectedEnrolledId) {
                    if(dateToday >= enrolled.enrollExpired) {
                        expired_date = dateToday //belum expired, tapi nak extend
                    } else {
                        expired_date = enrolled.enrollExpired
                    }
                } return null
            })
        }
        return expired_date;
    }

    getExtendCount = async () => {
        const { courseEnrollments } = this.props;
        const { selectedEnrolledId } = this.state;

        let extendCount = 0;

        if(selectedEnrolledId) {
            courseEnrollments && courseEnrollments.map(enrolled => {
                if(enrolled.id === selectedEnrolledId) {
                    extendCount = enrolled.extendCount ? enrolled.extendCount + 1 : 1;
                } return null
            })
        }
        return extendCount;
    }

    extendEnrollment = async () => {
        let newEnrolledExpired = await this.getNewExpiredDate(); 
        let extendCount = await this.getExtendCount();

        if(extendCount > 1) {
            alert('User already extend once!')
        } else {
            var result = window.confirm("Confirm extend?");

            if (result) {
                this.setState({
                    isLoading: true
                })
                this.props.extendEnrollment(extendCount, newEnrolledExpired, this.state.selectedEnrolledId)
            }
        }
    }

    render(){

        const { courses, courseEnrollments } = this.props;
        const { message, isLoading } = this.state;

        let courseList = courseEnrollments && courseEnrollments.map(enrolled => {
            return (
                courses && courses.map((course, i) => {
                    if(enrolled.courseId === course.id) {
                        return (
                            <option key={i} id={enrolled.id}>{course.courseName}</option>
                        )
                    }
                    return null
                })
            )
        })

        return(

        <div>
            <input type="email" id="extendEmail" className="form-control" onChange={this.handleChange} value={this.state.extendEmail} placeholder="Email"/>

            <div  hidden={this.state.hideCheck}  className="mt-5 row justify-content-end">
                <button className="btn m-2 btn-outline-black" onClick={this.checkEmail.bind(this)}><i className="fas fa-search"/>{' '}Check email</button>
            </div>

            <div hidden={this.state.hideExtend}>
                <div>
                    <select className="form-control" onChange={this.selectedEnrolled.bind(this)}>
                        <option defaultValue>Course Name</option>
                        {courseList}
                    </select>
                </div>

                { this.state.selectedEnrolledId ?
                    <div>
                        <label><b>Extend for (in months):</b></label>
                        <input type="number" id="month" onChange={this.handleChange}  className="col-md-4 form-control justify-content-end" placeholder="Number only"/>
                    </div>
                : null }

                <br/>

                { isLoading === true ? 
                    <small>Loading...</small>
                : 
                    message  ?
                        <small className={message.type === 'extendEnrollmentError' ? "text-red" : message.type === 'extendEnrollmentSuccess'  ? "text-green" : null }>
                            {message.msg}
                        </small>
                    : null
                }

                { this.state.month ?
                    <div className="mt-5 row justify-content-end">
                        <button className="btn m-2 btn-outline-black" onClick={this.extendEnrollment.bind(this)}><i className="fas fa-check-circle"/>{' '}Extend Enrollment</button>
                    </div>
                : null }
            </div>
        </div>
        
        )
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        extendEnrollmentError: state.enrollments.extendEnrollmentError,
        extendEnrollmentSuccess: state.enrollments.extendEnrollmentSuccess,

        courseEnrollments: state.firestore.ordered.courseEnrollments,

        userId: ownProps.query.get('userId')
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
      extendEnrollment: (extendCount, date, enrolledCourseId) => dispatch(extendEnrollment(extendCount, date, enrolledCourseId)),
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect( props => [
        { collection: 'courseEnrollments', where: ['userId', '==', props.userId] },
    ])
)(ExtendEnrollment);