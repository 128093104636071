import React, { Component } from 'react';
import { connect } from 'react-redux'
import { updatePrivacy } from '../../../stores/actions/infoAction';

class EditPrivacyPolicy extends Component{

    constructor(props){
        super(props)
        this.state = {
            infoName: this.props.info.infoName,
            infoType: this.props.info.infoType,
            infoContent: this.props.info.infoContent,
        
            isLoading: null,
            message: null,
        }
        this.baseState = this.state 
    }

    componentDidUpdate = (prevProps, prevState) => { 
        const { updatePrivacyError, updatePrivacySuccess } = this.props;

        if(prevProps.updatePrivacyError !== updatePrivacyError) {
            this.setState({
                isLoading: false,
                message: {
                    msg: updatePrivacyError.message,
                    type: updatePrivacyError.type,
                }
            })
        }

        if(prevProps.updatePrivacySuccess !== updatePrivacySuccess) {
            this.setState({
                isLoading: false,
                message: {
                    msg: updatePrivacySuccess.message,
                    type: updatePrivacySuccess.type,
                }
            })
            setTimeout(() => { 
                this.setState(this.baseState)
                this.props.hideAndResetState();
            }, 2000);
        }
    } 

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    updatePrivacy = (e) => {
        var result = window.confirm("Confirm update?");

        if (result) {
            this.setState({
                isLoading: true
            })
            this.props.updatePrivacy(this.state, this.props.infoId);
        }
    }

    render(){

        const { message, isLoading } = this.state;

        return(

        <div>
                    
            <b className="uppercase middle">edit privacy policy
                <div className="title-border"></div>
            </b>

            <div className="row">
                <input type="text" id="infoName" onChange={this.handleChange.bind(this)} className="form-control" defaultValue={this.state.infoName} />
                <textarea type="text" id="infoContent" onChange={this.handleChange.bind(this)} className="form-control" defaultValue={this.state.infoContent} />
            </div>

            { isLoading === true ? 
                <small>Loading...</small>
            : 
                message  ?
                    <small className={message.type === 'updatePrivacyError' ? "text-red" : message.type === 'updatePrivacySuccess'  ? "text-green" : null }>
                        {message.msg}
                    </small>
                : null
            }

            <div className="mt-5 row justify-content-end">
                <button className="btn m-2 btn-outline-black" onClick={this.updatePrivacy.bind(this)}><i className="fas fa-check-circle"/>{' '}update</button>
            </div>
        </div>

        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const id = ownProps.info_id;
    const infosList = state.firestore.data.infos;
    const info = infosList ? infosList[id] : null
    return {
        infoId: id,
        infosList: infosList,
        info: info,

        updatePrivacyError: state.infos.updatePrivacyError,
        updatePrivacySuccess: state.infos.updatePrivacySuccess,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
      updatePrivacy: (privacy, infoId) => dispatch(updatePrivacy(privacy, infoId))
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(EditPrivacyPolicy)