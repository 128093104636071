import React from "react";
import { Image, Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import logo from './logo.png';

const styles = StyleSheet.create({
    page: {
        fontFamily:'Helvetica',
        fontSize:8
    },
    logo: {
        width:'30%',
    },
    middle: {
        justifyContent: 'center',
        textAlign: 'center',
    },
    row: { 
        width: '100%',
        flexDirection: "row" ,
        justifyContent:'center',
    }, 
    row2: { 
        width: '100%',
        flexDirection: "row" ,
        justifyContent:'flex-start',
    },
    text: {
        marginBottom:2
    },
    textWhite: {
        color:"#fff"
    },
    textCenter: {
        textAlign:'center'
    },
    textBold: {
        fontFamily:'Helvetica-Bold',
    },
    textTop: {
        textAlign:'right',
        margin:20,
        color:"#252525",
    },
    textLogo:{
        color:"#6e6e6e"
    },
    textTranscript: {
        fontSize:20,
        color:"#6e6e6e",
        textTransform:'uppercase',
        fontFamily:'Helvetica-Bold',
        marginTop:30,
        marginBottom:30
    },
    textOverall: {
        marginTop:20
    },



    table: {
        width: '100%',
    },
    tableCell:{
        padding:5,
    },
    colInfo1:{
        width: "20%",
    },
    colInfo2:{
        width: "65%",
    },


    table1:{
        marginTop:20
    },
    table1_cell: {
        borderStyle: "solid", 
        borderWidth: 1, 
        borderColor: '#3b3b3b',
        margin:0.5,
    },
    t1c1:{
        width: "35%",
    },
    t1c2:{
        width: "25%",
    },
    t1c3:{
        width: "25%",
    },
    t1c2_exam:{
        width: "50%",
        textAlign:'center',
    },
    

    
    // MANUAL MERGE C01,C02,C03
    cell_c01: {
        borderBottomColor:"#ffffff"
    },
    cell_c02: {
        borderTopColor:"#ffffff",
        borderBottomColor:"#ffffff"
    },
    cell_c03: {
        borderTopColor:"#ffffff",
    },


    // ---------------------- PAGE 2 ---------------------- 
    page2: {
        fontFamily:'Helvetica',
        fontSize:8
    },
    textPage2: {
        borderBottomWidth:1,
        borderBottomColor:"#000",
        marginLeft:65,
        marginTop:60,
        marginBottom:30,
        marginRight:62
    },
    textBottom: {
        fontFamily:'Helvetica-Bold',
        marginLeft:65,
        marginRight:62
    },
    marginTop: {
        marginTop:20,
    },
    removeBorderBottom: {
        borderBottomColor:"#ffffff"
    },


    // ---------------------- TABLE 2 ---------------------- 
    table2_cell: {
        borderStyle: "solid", 
        borderWidth: 1, 
        borderColor: '#3b3b3b',
        margin:0.5,
        justifyContent: 'center',
        textAlign: 'center',
    },
    table2_title: {
        fontSize:8,
    },
    t2c1: {
        width: "10%",
    },
    t2c2: {
        width: "20%",
    },
    t2c4: {
        width: "10%",
    },
    t2c5: {
        width: "10%",
    },
    t2c6: {
        width: "10%",
    },
    t2c7: {
        width: "10%",
    },
    t2c8: {
        width: "14%",
    },


    table2_cell_inner: {
        margin:0
    },
    t2c2_inner1: {
        width:"50%",
        borderTopColor:"#ffffff",
        borderLeftColor:"#ffffff",
    },
    t2c2_inner2: {
        width:"50%",
        borderTopColor:"#ffffff",
        borderLeftColor:"#ffffff",
        borderRightColor:"#ffffff",
    },
    t2c2_inner3: {
        width:"100%",
        borderTopColor:"#ffffff",
        borderLeftColor:"#ffffff",
        borderRightColor:"#ffffff",
    },
    t2c2_inner3_bottom: {
        width:"100%",
        borderWidth:"#ffffff",
    },
    tableCell_innner: {
        padding:0
    },
    table2_cell_passing1: {
        borderRightColor:"#ffffff",
    },
    table2_cell_passing2: {
        borderLeftColor:"#ffffff",
    },



    // ---------------------- TABLE 3 (small table) ---------------------- 
    table3_cell: {
        borderStyle: "solid", 
        borderWidth: 1, 
        borderColor: '#3b3b3b',
        margin:0.5,
        justifyContent: 'center',
        textAlign: 'center',
    },
    t3c1: {
        width:'30%',
    },
    t3c2: {
        width:'25%',
    },

    colInfo7:{
        width: "5%",
    },
    colInfo8:{
        width: "75%",
    },


});

export function TranscriptPdf({ selectedModules, userData, batchData, tsData, quizArr, examArr }) {

    let quizScore = 0, examScore = 0, paScore = 0, totalScore = 0;
    let quizMark = 0, paMark = 0, examMark = 0, attendanceMark = 0;
    let quizGrading = "", paGrading = "", examGrading = "", attendaceGrading = "";

    let quizMarkArr = [];

    selectedModules && selectedModules.map((sel,i) => {
        return (
            quizArr && quizArr.map(enrolled => {
                if(sel === enrolled.moduleId) {
                    quizMarkArr.push( (enrolled.quizScore * 3)/100 )
                } return null
            })
        )
    })

    quizScore = quizMarkArr[1] + quizMarkArr[2] + quizMarkArr[3] + quizMarkArr[4] + quizMarkArr[5];

    examArr && examArr.map(exam => {
        return examScore = ((exam.examScore * 30)/100)
    })

    paScore = parseFloat(tsData.pa.pa1) + parseFloat(tsData.pa.pa2) + parseFloat(tsData.pa.pa3);

    totalScore = quizScore + paScore + examScore + parseFloat(tsData.attendance);

    quizMark = ((quizScore / 15) * 100);
    paMark = ((paScore / 50) * 100);
    examMark = ((examScore / 30) * 100);
    attendanceMark = ((parseFloat(tsData.attendance) / 5) * 100);

    quizGrading = quizMark >= 70 ? "COMPETENT" : "NOT COMPETENCE";
    paGrading = paMark >= 70 ? "COMPETENT" : "NOT COMPETENCE";
    examGrading = examScore >= 21 ? "COMPETENT" : "NOT COMPETENCE";
    attendaceGrading = parseFloat(tsData.attendance) >= 3.5 ? "COMPETENT" : "NOT COMPETENCE";

    let overallGrading = 
    totalScore >= 90 ? 
        "DISTINCTION" 
    : totalScore >= 80 && totalScore <= 89.99 ? 
        "MERIT" 
    : totalScore >= 70 && totalScore <= 79.99 ? 
        "PASS" 
    : "FAILED" ;

        return (
            <Document style={styles.document}>

                <Page size="A4" orientation="potrait" style={styles.page}>
                    <Text style={[styles.text, styles.textTop]}>{tsData.regno}</Text>


                    <View style={styles.row}> 
                        <Image src={logo} style={styles.logo}></Image>
                    </View> 
                    <View style={styles.row}> 
                        <Text style={styles.textLogo}>Managed by GAE Resources Sdn Bhd</Text>
                    </View> 

                    

                    <View style={styles.row}> 
                        <Text style={styles.textTranscript}>OFFICIAL TRANSCRIPT</Text>
                    </View>



                     <View style={styles.table}> 
                        <View style={styles.row}> 
                            <View style={[styles.colInfo1, styles.textBold]}> 
                                <Text style={styles.tableCell}>NAME</Text> 
                            </View> 

                            <View style={[styles.colInfo2, styles.textBold]}> 
                                <Text style={styles.tableCell}>: {userData.firstname} {userData.lastname}</Text> 
                            </View>  
                        </View>
                        <View style={styles.row}> 
                            <View style={[styles.colInfo1, styles.textBold]}> 
                                <Text style={styles.tableCell}>I/C NO</Text> 
                            </View> 

                            <View style={[styles.colInfo2, styles.textBold]}> 
                                <Text style={styles.tableCell}>: {userData.identityCardNo}</Text> 
                            </View>  
                        </View>
                        <View style={styles.row}> 
                            <View style={[styles.colInfo1, styles.textBold]}> 
                                <Text style={styles.tableCell}>REGISTRATION NO.</Text> 
                            </View> 

                            <View style={[styles.colInfo2, styles.textBold]}> 
                                <Text style={styles.tableCell}>: {tsData.regno}</Text> 
                            </View>  
                        </View>
                        <View style={styles.row}> 
                            <View style={[styles.colInfo1, styles.textBold]}> 
                                <Text style={styles.tableCell}>BATCH NO.</Text> 
                            </View> 

                            <View style={[styles.colInfo2, styles.textBold]}> 
                                <Text style={styles.tableCell}>: {batchData.batchCode}</Text> 
                            </View>  
                        </View>
                    </View>


                    {/* ------------------------- TABLE 1 ------------------------- */}
                    <View style={[styles.table, styles.table1]}> 
                        <View style={styles.row}> 
                            <View style={[styles.t1c1, styles.table1_cell]}> 
                                <View style={styles.tableCell}></View> 
                            </View> 
                            <View style={[styles.t1c2, styles.table1_cell]}> 
                                <View style={[styles.tableCell, styles.textCenter]}>
                                    <Text style={styles.textBold}>PERFORMANCE</Text>
                                    <Text style={styles.textBold}>ASSESSMENT (50%)</Text>
                                </View> 
                            </View>  
                            <View style={[styles.t1c3, styles.table1_cell]}> 
                                <View style={[styles.tableCell, styles.textCenter]}>
                                    <Text style={styles.textBold}>KNOWLEDGE</Text>
                                    <Text style={styles.textBold}>ASSESSMENT (15%)</Text>
                                </View> 
                            </View> 
                        </View>

                        <View style={styles.row}> 
                            <View style={[styles.t1c1, styles.table1_cell]}> 
                                <View style={styles.tableCell}>
                                    <Text style={styles.text}>MODULE C01:</Text>
                                    <Text style={styles.textBold}>HALAL MANUAL AND RECORDS</Text>
                                    <Text style={styles.textBold}>ADMINISTRATION</Text>
                                </View> 
                            </View> 
                            <View style={[styles.t1c2, styles.table1_cell, styles.cell_c01]}> 
                            </View>  
                            <View style={[styles.t1c3, styles.table1_cell, styles.middle]}> 
                                <Text style={[styles.tableCell, styles.textBold]}>{quizMarkArr[1].toFixed(2)}</Text> 
                            </View> 
                        </View>

                        <View style={styles.row}> 
                            <View style={[styles.t1c1, styles.table1_cell]}> 
                                <View style={styles.tableCell}>
                                    <Text style={styles.text}>MODULE C02:</Text>
                                    <Text style={styles.textBold}>INTERNAL HALAL COMMITTEE (IHC) PROGRAM ADMINISTRATION</Text>
                                </View> 
                            </View> 
                            <View style={[styles.t1c2, styles.table1_cell, styles.cell_c02, styles.middle]}> 
                                <Text style={[styles.tableCell, styles.textBold]}>{tsData.pa.pa1}</Text> 
                            </View>  
                            <View style={[styles.t1c3, styles.table1_cell, styles.middle]}> 
                                <Text style={[styles.tableCell, styles.textBold]}>{quizMarkArr[2].toFixed(2)}</Text> 
                            </View> 
                        </View>
                        
                        <View style={styles.row}> 
                            <View style={[styles.t1c1, styles.table1_cell]}> 
                                <View style={styles.tableCell}>
                                    <Text style={styles.text}>MODULE C03:</Text>
                                    <Text style={styles.textBold}>HALAL QUALITY ASSURANCE</Text>
                                </View> 
                            </View> 
                            <View style={[styles.t1c2, styles.table1_cell, styles.cell_c03]}> 
                                <Text style={[styles.tableCell]}></Text> 
                            </View>  
                            <View style={[styles.t1c3, styles.table1_cell, styles.middle]}> 
                                <Text style={[styles.tableCell, styles.textBold]}>{quizMarkArr[3].toFixed(2)}</Text> 
                            </View> 
                        </View>
                        
                        <View style={styles.row}> 
                            <View style={[styles.t1c1, styles.table1_cell]}> 
                                <View style={styles.tableCell}>
                                    <Text style={styles.text}>MODULE C04:</Text>
                                    <Text style={styles.textBold}>HALAL CERTIFICATION PROCESS ADMINISTRATION</Text>
                                </View> 
                            </View> 
                            <View style={[styles.t1c2, styles.table1_cell, styles.middle]}> 
                                <Text style={[styles.tableCell, styles.textBold]}>{tsData.pa.pa2}</Text> 
                            </View>  
                            <View style={[styles.t1c3, styles.table1_cell, styles.middle]}> 
                                <Text style={[styles.tableCell, styles.textBold]}>{quizMarkArr[4].toFixed(2)}</Text> 
                            </View> 
                        </View>
                        
                        <View style={styles.row}> 
                            <View style={[styles.t1c1, styles.table1_cell]}> 
                                <View style={styles.tableCell}>
                                    <Text style={styles.text}>MODULE C05:</Text>
                                    <Text style={styles.textBold}>INTERNAL HALAL AUDIT</Text>
                                    <Text style={styles.textBold}>FACILITATION</Text>
                                </View> 
                            </View> 
                            <View style={[styles.t1c2, styles.table1_cell, styles.middle]}> 
                                <Text style={[styles.tableCell, styles.textBold]}>{tsData.pa.pa3}</Text> 
                            </View>  
                            <View style={[styles.t1c3, styles.table1_cell, styles.middle]}> 
                                <Text style={[styles.tableCell, styles.textBold]}>{quizMarkArr[5].toFixed(2)}</Text> 
                            </View> 
                        </View>
                        
                        <View style={styles.row}> 
                            <View style={[styles.t1c1, styles.table1_cell]}> 
                                <View style={styles.tableCell}>
                                    <Text style={styles.textBold}>EXAMINATION (30%)</Text>
                                </View> 
                            </View> 
                            <View style={[styles.t1c2_exam, styles.table1_cell]}> 
                                <Text style={[styles.tableCell, styles.textBold]}>{examScore.toFixed(2)}</Text> 
                            </View>  
                        </View>
                    </View>



                    <View style={[styles.row, styles.textOverall]}> 
                        <View style={[styles.colInfo1, styles.textBold]}> 
                            <Text style={styles.tableCell}>OVERALL</Text> 
                        </View> 
                        <View style={[styles.colInfo2, styles.textBold]}> 
                            <Text style={styles.tableCell}>: {totalScore.toFixed(2)}% - The candidate meets the {overallGrading} marks.</Text> 
                        </View>  
                    </View>
                </Page>



                <Page orientation="landscape" style={styles.page2}>
                    <Text style={[styles.text, styles.textPage2]}>
                        MARKING AND GRADING SYSTEM FOR HALAL ACADEMY - HALAL EXCUTIVE PROGRAM BY HPB
                    </Text>

                    <View style={[styles.table]}> 
                    {/* ------------------------------- ROW 1 ------------------------------- */}
                        <View style={styles.row}> 
                            <View style={[styles.t2c1, styles.table2_cell]}> 
                                <View style={styles.tableCell}>
                                    <Text style={[styles.textBold, styles.table2_title]}>WEIGHTAGE</Text>
                                </View> 
                            </View>  
                            <View style={[styles.t2c2, styles.table2_cell]}> 
                                <View style={styles.tableCell}>
                                    <Text style={[styles.textBold, styles.table2_title]}>ASSESSMENT COMPONENT</Text>
                                </View> 
                            </View>     
                            <View style={[styles.t2c4, styles.table2_cell]}> 
                                <View style={styles.tableCell}>
                                    <Text style={[styles.textBold, styles.table2_title]}>PERCENTAGE (%)</Text>
                                </View> 
                            </View>   
                            <View style={[styles.t2c5, styles.table2_cell]}> 
                                <View style={styles.tableCell}>
                                    <Text style={[styles.textBold, styles.table2_title]}>MINIMUM</Text>
                                    <Text style={[styles.textBold, styles.table2_title]}>PERCENTAGE (%)</Text>
                                </View> 
                            </View>   
                            <View style={[styles.t2c6, styles.table2_cell]}> 
                                <View style={styles.tableCell}>
                                    <Text style={[styles.textBold, styles.table2_title]}>MARKS OBTAINED (100%)</Text>
                                </View> 
                            </View>   
                            <View style={[styles.t2c7, styles.table2_cell]}> 
                                <View style={styles.tableCell}>
                                    <Text style={[styles.textBold, styles.table2_title]}>SCORE (%)</Text>
                                </View> 
                            </View>   
                            <View style={[styles.t2c8, styles.table2_cell]}> 
                                <View style={styles.tableCell}>
                                    <Text style={[styles.textBold, styles.table2_title]}>GRADING SYSTEM</Text>
                                </View> 
                            </View> 
                        </View>

                    {/* ------------------------------- ROW 2 ------------------------------- */}
                        <View style={styles.row}> 
                            <View style={[styles.t2c1, styles.table2_cell]}> 
                                <View style={styles.tableCell}>
                                    <Text>65</Text>
                                </View> 
                            </View>  
                            <View style={[styles.t2c2, styles.table2_cell]}> 

                                <View style={[styles.tableCell, styles.tableCell_innner]}>
                                    <View style={styles.row}> 
                                        <View style={[styles.table2_cell, styles.table2_cell_inner, styles.t2c2_inner1]}> 
                                            <View style={styles.tableCell}>
                                                <Text>Coursework</Text>
                                            </View> 
                                        </View>  
                                        <View style={[styles.table2_cell, styles.table2_cell_inner, styles.t2c2_inner2]}> 
                                            <View style={styles.tableCell}>
                                                <Text>KA</Text>
                                            </View> 
                                        </View>  
                                    </View>
                                    <View style={styles.row}> 
                                        <View style={[styles.table2_cell, styles.table2_cell_inner, styles.t2c2_inner1, styles.removeBorderBottom]}> 
                                            <View style={styles.tableCell}>
                                                <Text>Coursework</Text>
                                            </View> 
                                        </View>  
                                        <View style={[styles.table2_cell, styles.table2_cell_inner, styles.t2c2_inner2, styles.removeBorderBottom]}> 
                                            <View style={styles.tableCell}>
                                                <Text>PA</Text>
                                            </View> 
                                        </View>  
                                    </View>
                                </View> 
                            </View>    

                            <View style={[styles.t2c4, styles.table2_cell]}> 
                                <View style={[styles.tableCell, styles.tableCell_innner]}>
                                    <View style={styles.row}> 
                                        <View style={[styles.table2_cell, styles.table2_cell_inner, styles.t2c2_inner3]}> 
                                            <View style={styles.tableCell}>
                                                <Text>15</Text>
                                            </View> 
                                        </View>  
                                    </View>
                                    <View style={styles.row}> 
                                        <View style={[styles.table2_cell, styles.table2_cell_inner, styles.t2c2_inner3, styles.removeBorderBottom]}> 
                                            <View style={styles.tableCell}>
                                                <Text>50</Text>
                                            </View> 
                                        </View>  
                                    </View>
                                </View> 
                            </View>   

                            <View style={[styles.t2c5, styles.table2_cell]}> 
                                <View style={[styles.tableCell, styles.tableCell_innner]}>
                                    <View style={styles.row}> 
                                        <View style={[styles.table2_cell, styles.table2_cell_inner,styles.t2c2_inner3]}> 
                                            <View style={styles.tableCell}>
                                                <Text>10.5</Text>
                                            </View> 
                                        </View>  
                                    </View>
                                    <View style={styles.row}> 
                                        <View style={[styles.table2_cell, styles.table2_cell_inner,styles.t2c2_inner3, styles.removeBorderBottom]}> 
                                            <View style={styles.tableCell}>
                                                <Text>35</Text>
                                            </View> 
                                        </View>  
                                    </View>
                                </View>  
                            </View>   

                            <View style={[styles.t2c6, styles.table2_cell]}> 
                                <View style={[styles.tableCell, styles.tableCell_innner]}>
                                    <View style={styles.row}> 
                                        <View style={[styles.table2_cell, styles.table2_cell_inner,styles.t2c2_inner3]}> 
                                            <View style={styles.tableCell}>
                                                <Text>{quizMark.toFixed(2)}</Text>
                                            </View> 
                                        </View>  
                                    </View>
                                    <View style={styles.row}> 
                                        <View style={[styles.table2_cell, styles.table2_cell_inner,styles.t2c2_inner3, styles.removeBorderBottom]}> 
                                            <View style={styles.tableCell}>
                                                <Text>{paMark.toFixed(2)}</Text>
                                            </View> 
                                        </View>  
                                    </View>
                                </View>  
                            </View>   

                            <View style={[styles.t2c7, styles.table2_cell]}> 
                                <View style={[styles.tableCell, styles.tableCell_innner]}>
                                    <View style={styles.row}> 
                                        <View style={[styles.table2_cell, styles.table2_cell_inner,styles.t2c2_inner3]}> 
                                            <View style={styles.tableCell}>
                                                <Text>{quizScore.toFixed(2)}</Text>
                                            </View> 
                                        </View>  
                                    </View>
                                    <View style={styles.row}> 
                                        <View style={[styles.table2_cell, styles.table2_cell_inner,styles.t2c2_inner3, styles.removeBorderBottom]}> 
                                            <View style={styles.tableCell}>
                                                <Text>{paScore.toFixed(2)}</Text>
                                            </View> 
                                        </View>  
                                    </View>
                                </View>                          
                            </View> 

                            <View style={[styles.t2c8, styles.table2_cell]}> 
                                <View style={[styles.tableCell, styles.tableCell_innner]}>
                                    <View style={styles.row}> 
                                        <View style={[styles.table2_cell, styles.table2_cell_inner,styles.t2c2_inner3]}> 
                                            <View style={styles.tableCell}>
                                                <Text style={styles.textBold}>{quizGrading}</Text>
                                            </View> 
                                        </View>  
                                    </View>
                                    <View style={styles.row}> 
                                        <View style={[styles.table2_cell, styles.table2_cell_inner,styles.t2c2_inner3, styles.removeBorderBottom]}> 
                                            <View style={styles.tableCell}>
                                                <Text style={styles.textBold}>{paGrading}</Text>
                                            </View> 
                                        </View>  
                                    </View>
                                </View>  
                            </View> 
                        </View>
                        
                    {/* ------------------------------- ROW 3 ------------------------------- */}
                        <View style={styles.row}> 
                            <View style={[styles.t2c1, styles.table2_cell]}> 
                                <View style={styles.tableCell}>
                                    <Text>30</Text>
                                </View> 
                            </View>  
                            <View style={[styles.t2c2, styles.table2_cell]}>  
                                <View style={[styles.tableCell, styles.tableCell_innner]}>
                                    <View style={styles.row}> 
                                        <View style={[styles.table2_cell, styles.table2_cell_inner, styles.t2c2_inner1, styles.removeBorderBottom]}> 
                                            <View style={styles.tableCell}>
                                                <Text>Examination</Text>
                                            </View> 
                                        </View>  
                                        <View style={[styles.table2_cell, styles.table2_cell_inner, styles.t2c2_inner2, styles.removeBorderBottom]}> 
                                            <View style={styles.tableCell}>
                                                <Text>MCQ</Text>
                                            </View> 
                                        </View>  
                                    </View>
                                </View>
                            </View>     
                            <View style={[styles.t2c4, styles.table2_cell]}> 
                                <View style={styles.tableCell}>
                                    <Text>30</Text>
                                </View> 
                            </View>   
                            <View style={[styles.t2c5, styles.table2_cell]}> 
                                <View style={styles.tableCell}>
                                    <Text>21</Text>
                                </View> 
                            </View>   
                            <View style={[styles.t2c6, styles.table2_cell]}> 
                                <View style={styles.tableCell}>
                                    <Text>{examMark.toFixed(2)}</Text>
                                </View> 
                            </View>   
                            <View style={[styles.t2c7, styles.table2_cell]}> 
                                <View style={styles.tableCell}>
                                    <Text>{examScore.toFixed(2)}</Text>
                                </View> 
                            </View>   
                            <View style={[styles.t2c8, styles.table2_cell]}> 
                                <View style={styles.tableCell}>
                                    <Text style={styles.textBold}>{examGrading}</Text>
                                </View> 
                            </View> 
                        </View>
                        
                    {/* ------------------------------- ROW 4 ------------------------------- */}
                        <View style={styles.row}> 
                            <View style={[styles.t2c1, styles.table2_cell]}> 
                                <View style={styles.tableCell}>
                                    <Text>5</Text>
                                </View> 
                            </View>  
                            <View style={[styles.t2c2, styles.table2_cell]}>  
                                <View style={[styles.tableCell, styles.tableCell_innner]}>
                                    <View style={styles.row}> 
                                        <View style={[styles.table2_cell, styles.table2_cell_inner, styles.t2c2_inner1, styles.removeBorderBottom]}> 
                                            <View style={styles.tableCell}>
                                                <Text>Attendance</Text>
                                            </View> 
                                        </View>  
                                        <View style={[styles.table2_cell, styles.table2_cell_inner, styles.t2c2_inner2, styles.removeBorderBottom]}> 
                                            <View style={styles.tableCell}>
                                                <Text>Other</Text>
                                            </View> 
                                        </View>  
                                    </View>
                                </View>
                            </View>     
                            <View style={[styles.t2c4, styles.table2_cell]}> 
                                <View style={styles.tableCell}>
                                    <Text>5</Text>
                                </View> 
                            </View>   
                            <View style={[styles.t2c5, styles.table2_cell]}> 
                                <View style={styles.tableCell}>
                                    <Text>3.5</Text>
                                </View> 
                            </View>   
                            <View style={[styles.t2c6, styles.table2_cell]}> 
                                <View style={styles.tableCell}>
                                    <Text>{attendanceMark.toFixed(2)}</Text>
                                </View> 
                            </View>   
                            <View style={[styles.t2c7, styles.table2_cell]}> 
                                <View style={styles.tableCell}>
                                    <Text>{tsData.attendance}</Text>
                                </View> 
                            </View>   
                            <View style={[styles.t2c8, styles.table2_cell]}> 
                                <View style={styles.tableCell}>
                                    <Text style={styles.textBold}>{attendaceGrading}</Text>
                                </View> 
                            </View> 
                        </View>

                    {/* ------------------------------- ROW 5 ------------------------------- */}
                        <View style={styles.row}> 
                            <View style={[styles.t2c1, styles.table2_cell, styles.table2_cell_passing1]}></View>  
                            <View style={[styles.t2c2, styles.table2_cell, styles.table2_cell_passing2]}>
                                <View style={styles.tableCell}>
                                    <Text style={styles.textBold}>Passing marks</Text>
                                </View> 
                            </View>     
                            <View style={[styles.t2c4, styles.table2_cell]}> 
                                <View style={styles.tableCell}>
                                    <Text>100</Text>
                                </View> 
                            </View>   
                            <View style={[styles.t2c5, styles.table2_cell]}> 
                                <View style={styles.tableCell}>
                                    <Text>70</Text>
                                </View> 
                            </View>   
                            <View style={[styles.t2c6, styles.table2_cell]}> 
                                <View style={styles.tableCell}>
                                    <Text></Text>
                                </View> 
                            </View>   
                            <View style={[styles.t2c7, styles.table2_cell]}> 
                                <View style={styles.tableCell}>
                                    <Text>{totalScore.toFixed(2)}</Text>
                                </View> 
                            </View>   
                            <View style={[styles.t2c8, styles.table2_cell]}> 
                                <View style={styles.tableCell}>
                                    <Text style={styles.textBold}>{overallGrading}</Text>
                                </View> 
                            </View> 
                        </View>
                    </View>



                    {/* -------------------------------SMALL TABLE 1 ------------------------------- */}

                    
                    <View style={[styles.row, styles.marginTop]}>

                        <View style={[styles.table]}>
                            <View style={styles.row}> 
                                <View style={[styles.t3c1, styles.table3_cell]}> 
                                    <View style={styles.tableCell}>
                                        <Text style={styles.textBold}>GRADING SYSTEM</Text>
                                    </View> 
                                </View> 
                                <View style={[styles.t3c2, styles.table3_cell]}> 
                                    <View style={styles.tableCell}>
                                        <Text style={styles.textBold}>MARKS</Text>
                                    </View> 
                                </View> 
                            </View>
                            <View style={styles.row}> 
                                <View style={[styles.t3c1, styles.table3_cell]}> 
                                    <View style={styles.tableCell}>
                                        <Text>DISTINCTION</Text>
                                    </View> 
                                </View> 
                                <View style={[styles.t3c2, styles.table3_cell]}> 
                                    <View style={styles.tableCell}>
                                        <Text>90% - 100%</Text>
                                    </View> 
                                </View> 
                            </View>
                            <View style={styles.row}> 
                                <View style={[styles.t3c1, styles.table3_cell]}> 
                                    <View style={styles.tableCell}>
                                        <Text>MERIT</Text>
                                    </View> 
                                </View> 
                                <View style={[styles.t3c2, styles.table3_cell]}> 
                                    <View style={styles.tableCell}>
                                        <Text>80% - 89.99%</Text>
                                    </View> 
                                </View> 
                            </View>
                            <View style={styles.row}> 
                                <View style={[styles.t3c1, styles.table3_cell]}> 
                                    <View style={styles.tableCell}>
                                        <Text>PASS</Text>
                                    </View> 
                                </View> 
                                <View style={[styles.t3c2, styles.table3_cell]}> 
                                    <View style={styles.tableCell}>
                                        <Text>70% - 79.99%</Text>
                                    </View> 
                                </View> 
                            </View>
                        </View>

                        <View style={[styles.table]}>
                            <View style={[styles.row2]}> 
                                <View style={[styles.t3c1, styles.table3_cell]}> 
                                    <View style={styles.tableCell}>
                                        <Text style={styles.textBold}>COMPETENCY</Text>
                                    </View> 
                                </View> 
                                <View style={[styles.t3c2, styles.table3_cell]}> 
                                    <View style={styles.tableCell}>
                                        <Text>COURSEWORDK</Text>
                                    </View> 
                                </View> 
                                <View style={[styles.t3c2, styles.table3_cell]}> 
                                    <View style={styles.tableCell}>
                                        <Text>EXAM</Text>
                                    </View> 
                                </View>
                            </View>

                            <View style={styles.row2}> 
                                <View style={[styles.t3c1, styles.table3_cell]}> 
                                    <View style={styles.tableCell}>
                                        <Text>COMPETENT</Text>
                                    </View> 
                                </View> 
                                <View style={[styles.t3c2, styles.table3_cell]}> 
                                    <View style={styles.tableCell}>
                                        <Text>{'>'}= 70%</Text>
                                    </View> 
                                </View> 
                                <View style={[styles.t3c2, styles.table3_cell]}> 
                                    <View style={styles.tableCell}>
                                        <Text>{'>'}= 21%</Text>
                                    </View> 
                                </View>
                            </View>

                            <View style={styles.row2}> 
                                <View style={[styles.t3c1, styles.table3_cell]}> 
                                    <View style={styles.tableCell}>
                                        <Text>NOT COMPETENT</Text>
                                    </View> 
                                </View> 
                                <View style={[styles.t3c2, styles.table3_cell]}> 
                                    <View style={styles.tableCell}>
                                        <Text>{'<'} 70%</Text>
                                    </View> 
                                </View> 
                                <View style={[styles.t3c2, styles.table3_cell]}> 
                                    <View style={styles.tableCell}>
                                        <Text>{'<'} 21%</Text>
                                    </View> 
                                </View>
                            </View>
                        </View>
                    </View>



                    <View style={[styles.table, styles.marginTop]}> 
                        <Text style={styles.textBottom}>Abbreviation:</Text>
                        <View style={styles.row}> 
                            <View style={[styles.colInfo7]}> 
                                <Text style={styles.tableCell}>KA</Text> 
                            </View> 

                            <View style={[styles.colInfo8]}> 
                                <Text style={styles.tableCell}>: Knowledge Assessment (individual assignment, quiz, report, written exam, oral exam)</Text> 
                            </View>  
                        </View> 
                        <View style={styles.row}> 
                            <View style={[styles.colInfo7]}> 
                                <Text style={styles.tableCell}>PA</Text> 
                            </View> 

                            <View style={[styles.colInfo8]}> 
                                <Text style={styles.tableCell}>: Performance Assessment (group assignment; project; workshop task)</Text> 
                            </View>  
                        </View> 
                        <View style={styles.row}> 
                            <View style={[styles.colInfo7]}> 
                                <Text style={styles.tableCell}>MCQ</Text> 
                            </View> 

                            <View style={[styles.colInfo8]}> 
                                <Text style={styles.tableCell}>: Multiple Choice Question (Theory)</Text> 
                            </View>  
                        </View> 
                        <View style={styles.row}> 
                            <View style={[styles.colInfo7]}> 
                                <Text style={styles.tableCell}>SEQ</Text> 
                            </View> 

                            <View style={[styles.colInfo8]}> 
                                <Text style={styles.tableCell}>: Short Essay Question (Practical</Text> 
                            </View>  
                        </View>
                    </View>

                    <Text style={[styles.textBottom, styles.marginTop]}>
                        Based on MQA Program Standard, for practical session courses must achieve at least 70% of the practical component in the specific trades or skill.
                    </Text>

                    <Text style={[styles.textBottom, styles.marginTop]}>
                        This is a computer-generated document. No signature is required.
                    </Text>

                </Page>

            </Document>
        );
    }